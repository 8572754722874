import { FormikConfig } from "formik";
import { FC, useState } from "react";
import { locationsApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { LocationType } from "src/components/forms/formData/location";
import { LocationForm } from "src/components/forms/LocationForm";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch, AuthUserDTO, LatLngType, LocationFileDTO } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { LocationUpdateFormWrapperType } from "./LocationCreateFormWrapper.types";
import { allRegionsValue } from "../../../utils/consts";

type LocationCreateMutationTrigger = ReturnType<typeof locationsApi.useAddLocationMutation>[0]
type SetLocationFileMutationTrigger = ReturnType<typeof locationsApi.useAddFileToLocationMutation>[0]

const handleSubmit = (
  trigger: LocationCreateMutationTrigger,
  dispatch: AppDispatch,
  setLocationFile: SetLocationFileMutationTrigger,
  authInfo: AuthUserDTO,
  handleClose: () => void,
  mark: LatLngType,
  files?: File[] | null
): FormikConfig<LocationType>['onSubmit'] => (async (values) => {
  const data = {
    address: {
      region: values.region === allRegionsValue ? undefined : values.region,
      city: values.city,
      country: values.country,
      street: values.street,
      zipCode: values.zipCode,
      houseNumber: values.houseNumber,
    },
    businessId: authInfo.business.id,
    name: values.name,
    latitude: mark.lat,
    longitude: mark.lng,
    availableAmperes: values.availableAmperes,
    parkingLots: values.parkingLots
  }
  try {
    dispatch(preloaderChangeStatus(true))
    const response = await trigger(data).unwrap()
    let status: Promise<LocationFileDTO>[] = [];
    if(files) {
      status = files.map((file) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.set('businessId', String(authInfo.business.id))
        return setLocationFile({
          locationId: response.id,
          formData
        }).unwrap()
      })
    }
    await Promise.allSettled([...status, response])
    handleClose()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
});

export const LocationCreateFormWrapper: FC<LocationUpdateFormWrapperType> = ({ handleClose, authInfo, initialValue }) => {

  const [trigger, response] = locationsApi.useAddLocationMutation()
  const [setLocationFile] = locationsApi.useAddFileToLocationMutation()
  const dispatch = useAppDispatch()
  const [file, setFile] = useState<File[] | null>()
  const [mark, setMark] = useState<LatLngType>({
    lat: 0,
    lng: 0
  })
  
  const handleAddFile = (draggedFile: File[] | null)=> {
    setFile(draggedFile)
  }

  const placeMark = (data: LatLngType) => {
    setMark(data)
  }

  return (
    <LocationForm
      handleClose={handleClose}
      handleSubmit={handleSubmit(trigger, dispatch, setLocationFile, authInfo, handleClose, mark, file)}
      handleAddFile={handleAddFile}
      setMark={placeMark}
    />
  )
}
