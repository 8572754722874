import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { axiosBaseQuery } from "src/axiosBaseQuery"
import {
  BusinessCostUpdateRequest,
  BusinessDTO,
  BusinessesSearchRequest,
  BusinessFileRequest,
  BusinessUpdateRequest,
  ContractTerms,
  ContractTermsRequest,
  PageBusinessDTO, RfidTerms,
  SetBusinessSignatureRequest,
  SetContractTermsRequest,
  SetRfidTermsRequest,
  UpdateRfidCostRequest
} from "src/types"
import { getBaseURL } from "src/utils/helper"

export const businessesApi = createApi({
  reducerPath: 'businessesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL() + '/businesses'),
  }),
  tagTypes: ['Business', 'Businesses', 'ContractTerms', 'Sign', 'RfidTerms'],
  endpoints:(build) => {
    return {
      getBusinesses: build.query<PageBusinessDTO, BusinessesSearchRequest>({
        query: ({ params }) => ({ url: '', method: 'get', params }),
        providesTags: (result) => {
          if (result?.content) {
            return [
              { type: 'Business' as const, id: 'LIST' },
              ...result.content.map(business => ({
                type: 'Business' as const,
                id: business?.id
              }))
            ]
          }
          return [
            { type: 'Business' as const, id: 'LIST' }, 'Business' as const
          ]
        }
      }),
      updateRfidCost: build.mutation<BusinessDTO, UpdateRfidCostRequest>({
        query: ({businessId, data}) => ({ url: `/${businessId}/rfidCost`, method: 'put', data }),
        invalidatesTags: (result, error, arg) => [
          { type: 'Business' as const, id: arg.businessId},
          { type: 'Business' as const, id: 'LIST' }
        ]
      }),
      updateBusiness: build.mutation<BusinessDTO, BusinessUpdateRequest>({
        query: ({businessId, data}) => ({ url: `/${businessId}`, method: 'put', data }),
        invalidatesTags: (result, error, arg) => [
          { type: 'Business' as const, id: arg.businessId},
          { type: 'Business' as const, id: 'LIST' }
        ]
      }),
      updateBusinessCost: build.mutation<BusinessDTO, BusinessCostUpdateRequest>({
        query: ({businessId, data}) => ({ url: `/${businessId}/cost`, method: 'put', data }),
        invalidatesTags: (result, error, arg) => [
          { type: 'Business' as const, id: arg.businessId},
          { type: 'Business' as const, id: 'LIST' }
        ]
      }),
      getBusinessById: build.query<BusinessDTO, {businessId: number | string}>({
        query: ({businessId}) => ({ url: `/${businessId}`, method: 'get' }),
        providesTags: (result, error, arg) => [{ type: 'Business' as const, id: arg.businessId}]
  
      }),
      getContractTerms: build.query<ContractTerms, ContractTermsRequest>({
        query: (params) => ({
          url: `/${params.businessId}/contractTerms`,
          method: 'get',
          params: { language: params.language } }),
        providesTags: (result, error, arg) => [{
          type: 'ContractTerms',
          id: arg.businessId + arg.language
        }]
      }),
      getRfidTerms: build.query<RfidTerms, ContractTermsRequest>({
        query: (params) => ({
          url: `/${params.businessId}/rfidTerms`,
          method: 'get',
          params: { language: params.language } }),
        providesTags: (result, error, arg) => [{
          type: 'RfidTerms',
          id: arg.businessId + arg.language
        }]
      }),
      setContractTerms: build.mutation<ContractTerms, SetContractTermsRequest>({
        query: (data) => ({
          url: `/${data.businessId}/contractTerms`,
          method: 'post',
          data: data.params
        }),
        invalidatesTags: (result, error, arg) => [{
          type: 'ContractTerms',
          id: arg.businessId + arg.params.language
        }]
      }),
      setRfidTerms: build.mutation<RfidTerms, SetRfidTermsRequest>({
        query: (data) => ({
          url: `/${data.businessId}/rfidTerms`,
          method: 'post',
          data: data.params
        }
        ),
        invalidatesTags: (result, error, arg) => [{
          type: 'RfidTerms',
          id: arg.businessId + arg.params.language
        }]
      }),
      getBusinessSignature: build.query<string, number>({
        query: (businessId) => ({
          url: `/${businessId}/signature`,
          method: 'get',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob',
        }),
        providesTags: (result, error, arg) => [{ type: 'Sign', id: arg}],
        transformResponse(baseQueryData) {
          if(baseQueryData instanceof Blob){
            return URL.createObjectURL(baseQueryData)
          }
          return ''
        }
      }),
      setBusinessSignature: build.mutation<BusinessDTO, SetBusinessSignatureRequest>({
        query: ({ formData, businessId }) => ({
          url: `/${businessId}/signature`,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          method: 'post',
          data: formData
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Sign', id: arg.businessId }]
      }),
      addBusinessLogo: build.mutation<BusinessDTO, BusinessFileRequest>({
        query: ({ businessId, formData }) => ({
          url: `/${businessId}/logo`,
          headers: { 
            'Content-Type': 'multipart/form-data'
          },
          method: 'post',
          data: formData,
        }),
        invalidatesTags: ['Business']
      }),
    }
  },
})
