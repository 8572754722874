import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { chargersApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import AMPModal from "src/components/common/AMPModal";
import { ConformationModal } from "src/components/common/ConformationModal";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { NotifyError } from "src/utils/notification";
import { DeleteScheduleType } from "./DeleteSchedule.type";

type DeleteScheduleMutationTrigger = ReturnType<typeof chargersApi.useDeleteScheduleMutation>[0]

const handleDeleteSchedule = (trigger: DeleteScheduleMutationTrigger, dispatch: AppDispatch, scheduleChargingId: number, chargerId: number, businessId: number, handleClose: () => void) => async () => {
  dispatch(preloaderChangeStatus(true))
  try{
    await trigger({ scheduleChargingId, chargerId, businessId }).unwrap()
    handleClose()
  } catch (error: any) {
    NotifyError(error.data.message);
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
}


export const DeleteSchedule: FC<DeleteScheduleType> = ({
  chargerId,
  scheduleChargingId,
  businessId
}) => {
  const [trigger, response] = chargersApi.useDeleteScheduleMutation()
  const dispatch = useAppDispatch()
  
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false)
  const dialogTitle = intl.formatMessage({ id: "deleteSchedule" })

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }
  return (
    <>
      <IconButton 
        onClick={handleOpenModal}
      >
        <Delete />
      </IconButton>
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <ConformationModal
          handleClose={handleCloseModal}
          handleSubmit={handleDeleteSchedule(trigger, dispatch, scheduleChargingId, chargerId, businessId, handleCloseModal)}
          text={'deleteScheduleDescription'}
        />
      </AMPModal>
    </>
  )
}
