import { Box, Grid, Typography } from "@mui/material";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { AMPFormSelect } from "src/components/common/AMPFormSelect";
import { AMPInput } from "src/components/common/AMPInput";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { getHelperText } from "src/utils/forms";
import {
  managersInitialValue,
} from "../formData/common/initialData";
import { actionsWrapperSx, formSx } from "./ManagersForm.sx";
import { ManagersFormProps } from "./ManagersForm.types";
import { managersFormSchema, ManagerFormProps } from "../formData/managers";
import { RoleEnum } from "src/enums";
import { usersApi } from "src/api";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { isErrorObject, processedLanguages } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type AddManagerMutationTrigger = ReturnType<
  typeof usersApi.useCreateManagerMutation
>[0];

const handleSubmit =
  (
    trigger: AddManagerMutationTrigger,
    dispatch: AppDispatch,
    handleClose: () => void,
    businessId: number,
  ): FormikConfig<ManagerFormProps>["onSubmit"] =>
    async (values) => {
      try {
        dispatch(preloaderChangeStatus(true));
        await trigger({data: values, businessId}).unwrap()
        handleClose()
      } catch (error) {
        if(isErrorObject(error)){
          NotifyError(error.data.message)
        }
      } finally {
        dispatch(preloaderChangeStatus(false));
      }
    };
    
const roles = [
  {
    id: 0,
    value: RoleEnum.SALES_MANAGER,
    name: RoleEnum.SALES_MANAGER,
  },
  {
    id: 1,
    value: RoleEnum.CLIENT_MANAGER,
    name: RoleEnum.CLIENT_MANAGER,
  },
  {
    id: 2,
    value: RoleEnum.TECHNICAL_MANAGER,
    name: RoleEnum.TECHNICAL_MANAGER,
  },
]
export const ManagersForm: FC<ManagersFormProps> = ({ businessId, handleClose }) => {
  const intl = useIntl();
  const {authInfo} = useAppSelector(state => state.common)
  const [trigger, response] = usersApi.useCreateManagerMutation()
  const dispatch = useAppDispatch()


  return (
    <Formik
      initialValues={managersInitialValue}
      validationSchema={managersFormSchema}
      onSubmit={handleSubmit(trigger, dispatch, handleClose, businessId)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }: FormikProps<ManagerFormProps>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box
            sx={formSx}
          >
            <Box>
              <Typography variant="subtitle1"
                color="textPrimary">
                <FormattedMessage id="generalInfo" />
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["firstName"]}
                    type={"firstName"}
                    label={"firstName"}
                    input={"firstName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["firstName"] && Boolean(errors["firstName"])
                    }
                    helperText={getHelperText(
                      "firstName",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["lastName"]}
                    type={"lastName"}
                    label={"lastName"}
                    input={"lastName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["lastName"] && Boolean(errors["lastName"])
                    }
                    helperText={getHelperText(
                      "lastName",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["email"]}
                    type={"email"}
                    label={"email"}
                    input={"email"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["email"] && Boolean(errors["email"])
                    }
                    helperText={getHelperText(
                      "email",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={roles}
                    value={values["role"] as RoleEnum}
                    label={"role"}
                    input={"role"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["role"] && Boolean(errors["role"])}
                    helperText={getHelperText("role", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <AMPFormSelect
                data={processedLanguages(intl)}
                value={values["language"] as string}
                label={"language"}
                input={"language"}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={touched["language"] && Boolean(errors["language"])}
                helperText={getHelperText("language", touched, errors, intl)}
              />
            </Box>
          </Box>
          <Box sx={actionsWrapperSx}>
            <CancelButton
              text="cancel"
              handler={handleClose}
            />
            <SubmitButton
              text={"createManager"}
              color="primary"
              isSubmitting={isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};
