import { alpha, SxProps, Theme } from "@mui/material"
import theme from "src/theme"

export const toolbarSx: SxProps<Theme> = {
  '&.MuiToolbar-root': {
    marginTop: theme.spacing(50/8),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(10/8),
    alignItems: 'flex-start',
    paddingLeft: 0,
    paddingRight: 0
  }
}
export const linkSx = (withoutHightlight = false) => ({
  display: 'flex',
  width: '100%',
  padding: 0,
  '& a' : {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(10/8),
    color: theme.palette.neutral[500],
    fill: theme.palette.neutral[500],
    padding: theme.spacing(12/8, 14/8),
    width: '100%',
    borderRadius: theme.spacing(4/8),
    position: "relative",
    overflow: "hidden",
    '&:hover': {
      background: alpha('#000', 0.24),
    },
    '&.active': {
      color: !withoutHightlight ? theme.palette.brand.main : theme.palette.neutral[500],
      background: !withoutHightlight ? alpha('#1000', 0.24) : 'transparent' 
    },
    '&.active svg': {
      fill: !withoutHightlight ? theme.palette.brand.main : theme.palette.neutral[500],
    },
    "& .MuiBox-root": {
      position: "absolute",
      left: "20px",
      top: "15px",
      transform: "translateY(-50%)",
      transition: "0.3s ease-in"
    }
  }
})
