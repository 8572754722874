import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';


export const header: SxProps<Theme> = {
  minHeight: theme.spacing(600/8),
  height: '100%',
  width: '100%',
  pb: theme.spacing(4/8)
}
export const tableFooter: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(10/8, 0),
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center'
  }
}
