import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const tariffPageHeaderWrapperSx: SxProps<Theme> = {
  marginTop: theme.spacing(20/8),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(10/8),
  [theme.breakpoints.down('lg')]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    mb: 4
  }
}
export const actionsWrapperSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(10/8)
}
