import { CheckCircle } from "@mui/icons-material";
import { FC } from "react";

interface CheckIconType {
  active: boolean,
  completed: boolean
}

export const CheckIcon: FC<CheckIconType> = ({ active, completed }) => {
  return (
    <>
      { !active && 
        <CheckCircle
          fontSize="large"
          color={completed ? 'brand' : 'off'}
        />
      }
      { active && 
        <svg
          width="33"
          height="33"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30 18C30 24.6274 24.6274 30 18 30C11.3726 30 6 24.6274 6 18C6 11.3726 11.3726 6 18 6C24.6274 6 30 11.3726 30 18Z"
            stroke="#D92156"
            strokeWidth="12"
          />
        </svg>
      }
    </>
  )
}
