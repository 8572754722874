import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'src/axiosBaseQuery'
import { getBaseURL } from 'src/utils/helper'
import {
  BusinessRevenueDTO,
  BusinessRevenueRequest,
  ChargingReportDTO,
  ChargingReportRequest,
  ConsumedPowerReportDTO,
  ConsumedPowerReportRequest,
  RevenueReportDTO,
  RevenueReportRequest,
  UsersReportDTO,
  UsersReportRequest
} from "../../types/reports";

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL() + '/reports'),
  }),
  endpoints: (build) => ({
    getChargingSessionsReport: build.query<ChargingReportDTO, ChargingReportRequest>({
      query: (params) => ({
        url: '/chargingSessions',
        method: 'post',
        params: params?.pageParams,
        data: params?.data
      }),
    }),
    getConsumedPowerReport: build.query<ConsumedPowerReportDTO, ConsumedPowerReportRequest>({
      query: (params) => ({
        url: '/consumedPower', method: 'post',
        data: params
      }),
    }),
    getBusinessRevenue: build.query<BusinessRevenueDTO, BusinessRevenueRequest>({
      query: (params) => ({
        url: '/businessRevenue', method: 'post',
        data: params
      }),
    }),
    getRevenueReport: build.query<RevenueReportDTO, RevenueReportRequest>({
      query: (params) => ({
        url: '/revenue', method: 'post',
        data: params
      }),
    }),
    getUsersReport: build.query<UsersReportDTO, UsersReportRequest>({
      query: (params) => ({
        url: '/users', method: 'post',
        data: params
      }),
    })
  }),
})
