import { FC, useState } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { CancelButton } from "../CancelButton";
import { businessesApi, usersApi } from "../../../api";
import { AppDispatch } from "../../../app/store";
import { FeedbackTypeEnum } from "../../../types";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { NotifyError, NotifySuccess } from "../../../utils/notification";
import { isErrorObject } from "../../../utils/helper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CurrencyEnum, LanguageEnum, LanguageShortEnum } from "../../../enums";
import { RequestRfidProps } from "./RequestRfid.types";
import { actionsWrapperSx, iconBtnSx, numbersBoxSx, numbersSx, rootSx, submitButtonSx } from "./RequestRfid.sx";

type PostFeedbackMutationType = ReturnType<typeof usersApi.usePostFeedbackMutation>[0];

export const handleSendRequest = (
  trigger: PostFeedbackMutationType,
  dispatch: AppDispatch,
  userId: number,
  number: number,
  handleClose: () => void,
  messageSent: string,
  handleIsSubmitting: (status: boolean) => void,
  messageText: string
) => async () => {
  try {
    dispatch(preloaderChangeStatus(true));
    handleIsSubmitting(true)
    const result = await trigger({
      userId,
      data: {
        feedbackType: FeedbackTypeEnum.NEW_RFID,
        message: messageText
      }
    }).unwrap().then(() => {
      NotifySuccess(messageSent)
    })
    return result
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message ?? error.data?.error)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
    handleClose()
    handleIsSubmitting(false)
  }
}

export const RequestRfid:FC<RequestRfidProps> = ({
  closeModal
}) => {
  const intl = useIntl();
  const [sendRequest] = usersApi.usePostFeedbackMutation()
  const dispatch = useAppDispatch();
  const { authInfo } = useAppSelector((state) => state.common);
  const [number, setNumber] = useState(1)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { lang } = useAppSelector(state => state.common)

  const rfidCost = authInfo?.business?.rfidCost ?? 0
  const rfidCurrency = authInfo?.business?.rfidCostCurrency ?? CurrencyEnum.EUR
  const requiredLanguage = {
    [LanguageShortEnum.EN]: LanguageEnum.ENGLISH,
    [LanguageShortEnum.FR]: LanguageEnum.FRENCH,
    [LanguageShortEnum.DE]: LanguageEnum.DEUTSCH,
  }
  const rfid = businessesApi.useGetRfidTermsQuery({
    businessId: authInfo?.business?.id,
    language: requiredLanguage[lang as LanguageShortEnum?? LanguageShortEnum.EN]
  })

  const handleIsSubmitting = (status: boolean) => {
    setIsSubmitting(status)
  }
  
  const handleIncrement = () => {
    setNumber(number + 1)
  }
  
  const handleDecrement = () => {
    setNumber(number - 1)
  }
  
  return (
    <Box>
      <Box sx={rootSx}>
        <Typography variant="body1">
          {rfid?.data?.rfidTerms}
        </Typography>
        <Typography
          variant="h4"
          pt={3}
        >
          <FormattedMessage id="quantities"/>
        </Typography>
        <Stack sx={numbersBoxSx}>
          <Typography
            variant="h4"
          >
            {`${(rfidCost / 100 * number).toFixed(4)} ${rfidCurrency}`}
          </Typography>
          <Stack sx={numbersSx}>
            <IconButton
              onClick={handleDecrement}
              disabled={number <= 1}
              sx={iconBtnSx}
            >
              -
            </IconButton>
            <Typography
              variant="h4"
              lineHeight={1}
            >
              {number}
            </Typography>
            <IconButton
              onClick={handleIncrement}
              sx={iconBtnSx}
            >
              +
            </IconButton>
          </Stack>
        </Stack>
      </Box>
      <Box sx={actionsWrapperSx}>
        <CancelButton
          text="cancel"
          handler={closeModal}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleSendRequest(
            sendRequest,
            dispatch,
            authInfo.id,
            number,
            closeModal,
            intl.formatMessage({id: 'requestSent'}),
            handleIsSubmitting,
            intl.formatMessage({id: 'requestedQuantity'}, {count: number})
          )}
          disabled={isSubmitting}
          sx={submitButtonSx}
        >
          <Typography
            variant="button"
            color="textSecondary"
          >
            <FormattedMessage id="sendRequest" />
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}
