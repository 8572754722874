import { FC, useState } from "react";
import { Edit } from "@mui/icons-material";
import { Box, Grid, IconButton, Link, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { usersApi } from "src/api";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { AppDispatch } from "src/app/store";
import { Can } from "src/permissions/Can";
import AMPModal from "src/components/common/AMPModal";
import { ColorField } from "src/components/common/ColorField";
import { DropZoneModal } from "src/components/common/DropZoneModal";
import { UserForm } from "src/components/forms/UserForm";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { EditUserForm } from "../../forms/EditUserForm";
import { CancelButton } from "../../common/CancelButton";
import { SubmitButton } from "../../common/SubmitButton";
import { EditIcon } from "../../icons/EditIcon";
import { ErrorIcon } from "../../icons/ErrorIcon";
import { EditUserNameForm } from "../../forms/EditUserNameForm";
import { UserDetailsType } from "./UserDetails.types";
import {
  bexioCell,
  buttonImageWrapper,
  buttonImageWrapperSx,
  deleteModalContentSx,
  detailItemSx,
  detailsInnerSx,
  detailsTitleSx,
  detailsWrapperSx,
  imageButton,
  imageButtonSx,
  imageInnerSx,
  imageWrapperSx
} from "./UserDetails.sx";
import { AppRouteSpace, AppRouteEnum, isErrorObject } from "src/utils/helper";
import ability from "src/permissions/ability";
import { blankPhoto } from "src/utils/consts";
import { NotifyError } from "src/utils/notification";
import { UserResetWrapper } from "../UserResetWrapper";
import { EditBexioIdForm } from "src/components/forms/EditBexioIdForm";

type AddUserAvatarMutationTrigger = ReturnType<
  typeof usersApi.useSetUserAvatarMutation
>[0];

const handleTrigger = async (
  file: File[],
  userId: number,
  trigger: AddUserAvatarMutationTrigger,
  dispatch: AppDispatch,
  handleCloseImageModal: () => void,
  handleBlockSubmit: (status: boolean) => void,
  businessId?: number,
) => {
  try {
    handleBlockSubmit(true)
    dispatch(preloaderChangeStatus(true));
    const formData = new FormData()
    formData.append('file', file[0])
    await trigger({ data: formData, userId }).unwrap()
    handleCloseImageModal()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
    handleBlockSubmit(false)
  }
}

export const UserDetails: FC<UserDetailsType> = ({ data }) => {
  const intl = useIntl();
  const { authInfo } = useAppSelector((state) => state.common);

  const [showEditModal, setShowEditModal] = useState(false)
  const [showEditNameModal, setShowEditNameModal] = useState(false)
  const [open, setOpen] = useState<boolean>(false)
  const [openDropZone, setOpenDropZone] = useState<boolean>(false)
  const [userImage, setUserImage] = useState<File[]>()
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const canReadResetPassword = ability.can('read', 'resetPassword')

  const dialogTitle = intl.formatMessage({id: 'editUser'})
  const editBexioTitle = intl.formatMessage({id: 'editBexioTitle'})
  const uploadDialogTitle = intl.formatMessage({id: 'addNewImage'})

  const readLocation = ability.can('read', 'locations')
	
  const dispatch = useAppDispatch();
  const [trigger] = usersApi.useSetUserAvatarMutation();

  const handleShowEditModal = (status: boolean) => () => setShowEditModal(status)
  const handleShowEditNameModal = (status: boolean) => () => setShowEditNameModal(status)
  const [blockSubmit,setBlockSubmit] = useState(false)
  
  const handleBlockSubmit = (status: boolean) => {
    setBlockSubmit(status)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleCloseImageModal = () => {
    setOpenDropZone(false)
  }

  const handleSetImage = (file: File[] | null) => {
    file && setUserImage(file)
  }

  const handleSubmit = () => {
    userImage &&
      handleTrigger(userImage, data.id, trigger, dispatch, handleCloseImageModal, handleBlockSubmit, data.business.id)
  }

  const handleShowDeleteModal = (status: boolean) => {
    return () => {
      setShowDeleteModal(status);
    }
  }

  const [openBexioModal, setOpenBexioModal] = useState<boolean>(false)

  const handleOpenBexioModal = () => {
    setOpenBexioModal(true)
  }

  const handleCloseBexioModal = () => {
    setOpenBexioModal(false)
  }

  return (
    <>
      <Box sx={detailsWrapperSx}>
        <Grid
          container
          spacing={2}
          sx={detailsInnerSx}
        >
          <Can
            I="read"
            a="editUser"
          >
            <Box sx={buttonImageWrapper}>
              <IconButton
                onClick={handleShowEditModal(true)}
                sx={imageButton}
              >
                <EditIcon/>
              </IconButton>
            </Box>
          </Can>
          <Can
            I="read"
            a="editName"
          >
            <Box sx={buttonImageWrapper}>
              <IconButton
                onClick={handleShowEditNameModal(true)}
                sx={imageButton}
              >
                <EditIcon/>
              </IconButton>
            </Box>
          </Can>
          <AMPModal
            handleClose={handleShowEditModal(false)}
            open={showEditModal}
            title={dialogTitle}
            type="md"
            fullWidth
            withoutPaddings
          >
            <EditUserForm
              handleClose={handleShowEditModal(false)}
              userData={data}
              handleShowDeleteModal={handleShowDeleteModal}
            />
          </AMPModal>
          <AMPModal
            handleClose={handleShowEditNameModal(false)}
            open={showEditNameModal}
            title={dialogTitle}
            type="md"
            fullWidth
            withoutPaddings
          >
            <EditUserNameForm
              handleClose={handleShowEditNameModal(false)}
              userData={data}
              handleShowDeleteModal={handleShowDeleteModal}
            />
          </AMPModal>
          <AMPModal
            handleClose={handleShowDeleteModal(false)}
            open={showDeleteModal}
            type="sm"
            fullWidth
            withoutPaddings
            title=""
          >
            <Stack sx={deleteModalContentSx.root}>
              <ErrorIcon />
              <Typography sx={deleteModalContentSx.text}>
                {intl.formatMessage({id: "cannotDeleteAccountMsg"})}
              </Typography>
            </Stack>
            <Box sx={deleteModalContentSx.actions}>
              <CancelButton
                text="cancel"
                handler={handleShowDeleteModal(false)}
                rounding={false}
              />
              <SubmitButton
                text="ok"
                handler={handleShowDeleteModal(false)}
                rounding={false}
              />
            </Box>
          </AMPModal>
          <Grid
            item
            xs={12}
            md={4}
            sx={imageWrapperSx}
          >
            <Box sx={imageInnerSx}>
              <Can
                I="manage"
                a="userPhoto"
              >
                <Box sx={buttonImageWrapperSx}>
                  <IconButton
                    onClick={() => setOpenDropZone(true)}
                    sx={imageButtonSx}
                  >
                    <Edit />
                  </IconButton>
                </Box>
              </Can>
              <DropZoneModal
                submitIsBlocked={blockSubmit}
                handleClose={handleCloseImageModal}
                open={openDropZone}
                title={uploadDialogTitle}
                handleFile={handleSetImage}
                handleSubmit={handleSubmit}
                isImage
              />
              <img
                src={data?.avatar?.url ?? blankPhoto}
                alt={`${data?.firstName} ${data?.lastName}`}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
          >
            <Box sx={detailsTitleSx}>
              <Typography variant="subtitle1">
                <FormattedMessage id="userDetails" />
              </Typography>
            </Box>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                md={7}
                sx={detailItemSx}
              >
                <Typography
                  variant="caption"
                >
                  <FormattedMessage id="email" />
                </Typography>
                <Typography
                  variant="subtitle2"
                >
                  {data.emailAddress && data.emailAddress}
                </Typography>
              </Grid>
              {data.phoneNumber && <Grid
                item
                xs={12}
                md={5}
                sx={detailItemSx}
              >
                <Typography
                  variant="caption"
                >
                  <FormattedMessage id="phone" />
                </Typography>
                <Typography
                  variant="subtitle2"
                >
                  <Link href={`tel: ${data.phoneNumber}`}>
                    {data.phoneNumber}
                  </Link>
                </Typography>
              </Grid>}
              {data.location && <Grid
                item
                xs={12}
                md={7}
                sx={detailItemSx}
              >
                <Typography
                  variant="caption"
                >
                  <FormattedMessage id="location" />
                </Typography>
                { readLocation
                  ?
                  data.location && <NavLink to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.locations, String(data.location.id)])}>
                    <Typography
                      variant="subtitle2"
                      color="primary.main"
                    >
                      {data.location.name}
                    </Typography>
                  </NavLink>
                  : <Typography
                    variant="subtitle2"
                  >
                    {data.location.name}
                  </Typography>
                }
              </Grid>}
              <Grid
                item
                xs={12}
                md={5}
                sx={detailItemSx}
              >
                <Typography
                  variant="caption"
                >
                  <FormattedMessage id="userRole" />
                </Typography>
                <ColorField label={String(data.roles[0].role)} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              pt={2}
            >
              {data.parkingAddress && <Grid
                item
                xs={12}
                md={7}
                sx={detailItemSx}
              >
                <Typography
                  variant="caption"
                >
                  <FormattedMessage id="parkingAddress" />
                </Typography>
                <Typography
                  variant="subtitle2"
                >
                  {`${data.parkingAddress.zipCode}, ${data.parkingAddress.country}, ${data.parkingAddress.city}, ${data.parkingAddress.street}, ${data.parkingAddress.houseNumber}, ${data.parkingAddress.parkingPlace}`}
                </Typography>
              </Grid>}
              {data.liveAddress && <Grid
                item
                xs={12}
                md={5}
                sx={detailItemSx}
              >
                <Typography
                  variant="caption"
                >
                  <FormattedMessage id="homeAddress" />
                </Typography>
                <Typography
                  variant="subtitle2"
                >
                  {`${data.liveAddress.zipCode}, ${data.liveAddress.country}, ${data.liveAddress.city}, ${data.liveAddress.street}, ${data.liveAddress.houseNumber}`}
                </Typography>
              </Grid>}
              <Grid
                item
                xs={12}
                md={5}
                sx={detailItemSx}
              >
                <Typography
                  variant="caption"
                >
                  <FormattedMessage id="bexioId" />
                </Typography>
                <Box sx={bexioCell}>
                  <Typography
                    variant="subtitle2"
                  >
                    {data.bexioId ? data.bexioId : "-"}
                  </Typography>
                  <Can
                    I="manage"
                    a="bexioId"
                  >
                    <IconButton
                      onClick={handleOpenBexioModal}
                      sx={imageButton}
                    >
                      <EditIcon />
                    </IconButton>
                    <AMPModal
                      handleClose={handleCloseBexioModal}
                      open={openBexioModal}
                      title={editBexioTitle}
                      type="md"
                      fullWidth
                      withoutPaddings
                    >
                      <EditBexioIdForm
                        handleClose={handleCloseBexioModal}
                        userId={data.id}
                        businessId={data.business.id}
                        bexioId={data.bexioId}
                      />
                    </AMPModal>
                  </Can>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {(canReadResetPassword || data.id === authInfo.id) && <UserResetWrapper data={data} />}
      </Box>
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
      >
        <UserForm
          handleClose={handleCloseModal}
          businessId={data.business.id}
        />
      </AMPModal>
    </>
  );
}
