import * as Yup from "yup";
import { CurrencyEnum } from "../../../../enums";

export interface LocationCostItemDataType {
  item: string,
  date: number,
  cost: number,
  serviceLife: number
}
export interface LocationCostItemType extends LocationCostItemDataType{
  id: number
}

export type EditLocationCostFormType = {
  currency: CurrencyEnum,
  itemsArray: LocationCostItemType[]
}

export const editLocationCostSchema = Yup.object().shape({
  currency: Yup.string(),
  itemsArray: Yup.array().of(
    Yup.object().shape({
      item: Yup.string()
        .trim()
        .required('schemaRequiredItem'),
      date: Yup.number()
        .required('schemaRequiredInstallationDate'),
      cost: Yup.number()
        .required('schemaRequiredCost')
        .moreThan(0, 'schemaRequiredServiceLife'),
      serviceLife: Yup.number()
        .required('schemaRequiredServiceLife')
        .moreThan(0, 'schemaRequiredServiceLife')
    }))
});
