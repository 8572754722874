import { Box, Button, Divider, SelectChangeEvent, Typography } from "@mui/material";
import { FC, FormEvent, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SubmitButton } from "src/components/common/SubmitButton";
import { fieldsWrapperSx, actionsWrapperSx, buttonSx } from "./TariffFilterForm.sx";
import { AMPFormSelect } from "src/components/common/AMPFormSelect";
import { TariffFilterFormType } from "./TariffFilterForm.types";
import { systemApi } from "../../../api/system";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { handleGetCountries } from "../../../hooks/useGetCountries";
import { SelectValue } from "../../../types";
import { allRegionsValue } from "../../../utils/consts";

export const TariffFilterForm: FC<TariffFilterFormType> = ({
  handleFilter,
  isFilterActive,
  handleClose,
  activeBusiness,
  setActiveBusiness,
  businesses,
  activeCountry,
  setActiveCountry,
  activeRegion,
  setActiveRegion
}) => {
  const intl = useIntl()
  const { lang } = useAppSelector((state) => state.common)
  const [getCountries, countries] = systemApi.useLazyGetCountriesQuery()
  const dispatch = useAppDispatch()
  
  useEffect(() => {
    handleGetCountries(getCountries, dispatch)
  }, [])
  
  const processedCountries = countries?.data?.map(country => ({
    id: country.id,
    name: intl.formatMessage({id: country.name.toLowerCase()}),
    value: country.name
  })) ?? []
  
  const processedRegions = useMemo(() => {
    const initialArray: SelectValue[] = [{
      id: 0,
      name: activeCountry.length ? intl.formatMessage({id: activeCountry.toLowerCase()}) : '',
      value: allRegionsValue
    }]
    if (activeCountry.length) {
      const mappedRegions: SelectValue[] | undefined = countries.data?.find(country => country.name === activeCountry)?.
        regions?.map(region => ({
          id: region.id,
          name: intl.formatMessage({id: region.name}),
          value: region.name
        }))
      return  mappedRegions?.concat(initialArray[0])
        ?? initialArray
    }
    return initialArray
  }, [activeCountry, lang, countries?.data])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleFilter({ businessId: activeBusiness, country: activeCountry, region: activeRegion === allRegionsValue ? undefined : activeRegion })
    isFilterActive(true)
    handleClose()
  }

  const handleClear = () => {
    setActiveBusiness(0)
    setActiveCountry('')
    setActiveRegion('')
    handleFilter({ businessId: 0, country: '', region: '' })
    isFilterActive(false)
  }
  
  const handleChangeCountry = (event: SelectChangeEvent<string>) => {
    setActiveCountry(event.target.value)
    setActiveRegion('')
  }
  const handleChangeRegion = (event: SelectChangeEvent<string>) => {
    setActiveRegion(event.target.value)
  }
  
  return (
    <form
      onSubmit={handleSubmit}
      noValidate
    >
      <Box sx={fieldsWrapperSx}>
        {Boolean(businesses.length) && <AMPFormSelect
          data={businesses}
          value={activeBusiness}
          label={"business"}
          input={"business"}
          onChange={(e: SelectChangeEvent<number>) => setActiveBusiness(+e.target.value)}
        />}
        <AMPFormSelect
          data={processedCountries}
          value={activeCountry}
          label={'country'}
          input={'country'}
          onChange={handleChangeCountry}
        />
        <AMPFormSelect
          data={processedRegions}
          value={activeRegion}
          label={'region'}
          input={'region'}
          disabled={!activeCountry.length}
          onChange={handleChangeRegion}
        />
      </Box>
      <Divider />
      <Box sx={actionsWrapperSx}>
        <Button
          variant="outlined"
          sx={buttonSx}
          onClick={handleClear}
        >
          <Typography
            variant="button"
            color="neutral.700"
          >
            <FormattedMessage id="clear" />
          </Typography>
        </Button>
        <SubmitButton
          text="apply"
        />
      </Box>
    </form>
  )
}
