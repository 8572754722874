import { GridColumns } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { ClientCell } from 'src/components/common/ClientCell';
import { TableIdCell } from 'src/components/common/TableIdCell';
import { AppRouteEnum, AppRouteSpace } from 'src/utils/helper';
import { TariffStatusButton } from '../TariffStatusButton';
import { LanguageEnum, TariffSortValuesEnum } from "../../../enums";
import { fixedDigits } from 'src/utils/fixedDigits';

export const columnConfigTariffs = (language: LanguageEnum) => (): GridColumns =>  [
  {
    field: TariffSortValuesEnum.NAME,
    headerName: 'TARIFF NAME',
    renderHeader: (params) => <FormattedMessage id={'tariffNameColumnHeader'} />,
    flex: 2,
    minWidth: 140,
    sortable: false,
    cellClassName: 'ampTableCell',
    renderCell: (params) => {
      const languageMap = {
        [LanguageEnum.ENGLISH]: params.row.name,
        [LanguageEnum.FRENCH]: params.row.nameFrench,
        [LanguageEnum.DEUTSCH]: params.row.nameDeutsch,
      }
      return (
        <TableIdCell
          businessId={params.row.business.id}
          label={languageMap[language] ?? params.row.name}
          withoutCopy
          link={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.tariffs, params.row.id])}
        />
      )
    }
  },
  {
    field: "business",
    renderHeader: (params) => <FormattedMessage id={'businessColumnHeader'} />,
    flex: 2,
    minWidth: 100,
    sortable: false,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.business.name
  },
  {
    field: TariffSortValuesEnum.COUNTRY,
    renderHeader: (params) => <FormattedMessage id={'countryColumnHeader'} />,
    flex: 2,
    minWidth: 140,
    sortable: true,
    cellClassName: 'ampTableCell',
    renderCell: (params) => params.row.country ?? '-'
  },
  {
    field: TariffSortValuesEnum.REGION,
    renderHeader: (params) => <FormattedMessage id={'regionColumnHeader'} />,
    flex: 2,
    minWidth: 140,
    sortable: true,
    cellClassName: 'ampTableCell',
    renderCell: (params) => params.row.region ?? '-'
  },
  {
    field: "activeClients",
    renderHeader: (params) => <FormattedMessage id={'activeClientsColumnHeader'} />,
    flex: 2,
    minWidth: 140,
    sortable: false,
    cellClassName: 'ampTableCell',
    renderCell: (params) => <ClientCell text={params.row.contractsSize} />
  },
  {
    field: "annualWhLimit",
    renderHeader: (params) => <FormattedMessage id={'annualLimitColumnHeader'} />,
    flex: 2,
    minWidth: 160,
    sortable: false,
    cellClassName: 'ampTableCell',
    renderCell: (params) => (params.row.annualWhLimit / 1000).toFixed(0)
  },
  {
    field: "pricePerKWh",
    renderHeader: (params) => <FormattedMessage id={'pricePerColumnHeader'} />,
    flex: 2,
    minWidth: 150,
    sortable: false,
    cellClassName: 'ampTableCell',
    renderCell: (params) => fixedDigits(params.row.pricePerKWh, 10000)
  },
  {
    field: "monthlyFee",
    renderHeader: (params) => <FormattedMessage id={'pricePerMonthColumnHeader'} />,
    flex: 2,
    minWidth: 140,
    sortable: false,
    cellClassName: 'ampTableCell',
    renderCell: (params) => params.row.monthlyFee / 100
  },
  {
    field: 'actions',
    headerName: '',
    flex: 2,
    minWidth: 130,
    sortable: false,
    cellClassName: 'ampTableCell',
    renderCell: (params) => {
      return (
        <TariffStatusButton tariff={params.row} />
      )
    }
  },
  // {
  //   field: 'delete',
  //   headerName: '',
  //   flex: 1,
  //   sortable: false,
  //   cellClassName: 'ampTableCell hoverableCell',
  //   renderCell: (params) => {
  //     return (
  //       <DeleteTariff row={params.row} />
  //     )
  //   }
  // }
]
