import { Box, Popover, Typography, TypographyProps } from "@mui/material";
import { FC, useState } from "react";
import { PopoverTypographyType } from "./PopoverTypography.types";

export const PopoverTypography: FC<PopoverTypographyType> = ({
  text,
  popoverText
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handlePopoverOpen: TypographyProps['onMouseEnter'] = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Box>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {text}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{popoverText}</Typography>
      </Popover>
    </Box>
  )
}