import { FC } from "react";
import { Box, FormControl, } from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import { useIntl } from "react-intl";
import { FormHelper } from "../FormHelper";
import { CustomFormLabel } from "../CustomFormLabel";
import { phoneAvailableCountries, phonePlaceholder } from "../../../utils/consts";
import { AMPPhoneNumberProps } from "./AMPPhoneNumber.types";
import { inputBoxSx, inputSx } from "./AMPPhoneNumber.sx";
import 'react-phone-input-2/lib/style.css'

export const AMPPhoneNumber:FC<AMPPhoneNumberProps> = ({
  hasError,
  helperText,
  label,
  value,
  onChange,
  ...rest
}) => {
  const intl = useIntl();
  
  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={!!hasError}
      margin="normal"
    >
      <CustomFormLabel
        label={intl.formatMessage({ id: label })}
        required={rest.required}
      />
      <Box
        sx={inputBoxSx(!!hasError)}
      >
        <PhoneInput
          placeholder={phonePlaceholder}
          searchPlaceholder="phone"
          inputStyle={inputSx}
          value={value}
          onlyCountries={phoneAvailableCountries}
          onChange={onChange}
          {...rest}
        />
      </Box>
      {helperText && (
        <FormHelper
          helperText={helperText}
        />
      )}
    </FormControl>
  )
}
