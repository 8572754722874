import { FC, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { notificationApi } from "../../../api";
import { NotificationTypeEnum } from "../../../types";
import { store } from "../../../app/store";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { isErrorObject } from "../../../utils/helper";
import { NotifyError } from "../../../utils/notification";
import { useAppSelector } from "../../../app/hooks";
import { RoleEnum } from "../../../enums";
import { moreThenNinetyNine, roleStatusRequest } from "../../../utils/consts";
import { notificationsNumberSx } from "./NotificationMarker.sx";

type GetNotificationTriggerType = ReturnType<typeof notificationApi.useLazyGetNotificationsQuery>[0];

const handleGetNotifications = (
  trigger: GetNotificationTriggerType,
  businessId: number,
  notificationTypes: NotificationTypeEnum[],
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    trigger({
      params: {
        size: 10,
        page: 0
      },
      data: {
        businessId,
        notificationTypes,
        read: false
      }
    })
  } catch (error) {
    if (isErrorObject(error)) {
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
}

export const NotificationMarker:FC = () => {
  const location = useLocation();
  const { authInfo } = useAppSelector((state) => state.common)
  const [getNotifications, notificationsData] = notificationApi.useLazyGetNotificationsQuery()
  const role = authInfo?.roles?.[0]?.role as RoleEnum
  const totalNotifications = notificationsData?.data?.totalElements ?? 0
  const formattedNotifications = totalNotifications > 99 ? moreThenNinetyNine : totalNotifications
  
  useEffect(() => {
    role !== RoleEnum.SUPER_ADMIN && handleGetNotifications(
      getNotifications,
      authInfo?.business?.id,
      roleStatusRequest[role],
    )
  }, [location])
  
  if (totalNotifications === 0) {
    return null
  }
  
  return (
    <Box sx={notificationsNumberSx}>
      <Typography variant="caption">
        {formattedNotifications}
      </Typography>
    </Box>
  )
}
