import { GridColumns } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { UserCell } from 'src/components/users/UserCell';

export const usersOfTariffColumnConfig: GridColumns = [
  {
    field: 'name',
    renderHeader: (params) => <FormattedMessage id={"client"} />,
    flex: 3,
    cellClassName: 'ampTableCell',
    renderCell: (params) => (
      <UserCell data={params.row} />
    )
  },
  {
    field: 'parkingPlace',
    renderHeader: (params) => <FormattedMessage id={"parkingPlaceColumnHeader"} />,
    flex: 3,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.parkingAddress ? params.row.parkingAddress.parkingPlace : '0'
  },
  {
    field: 'chargePointId',
    renderHeader: (params) => <FormattedMessage id={"chargePointIdColumnHeader"} />,
    flex: 3,
    cellClassName: 'ampTableCell'
  }

]
