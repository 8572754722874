import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const rootSx: SxProps<Theme> = {
  width: '100%',
  padding: theme.spacing(24/8),
  background: theme.palette.text.secondary,
  borderRadius: theme.spacing(8/8),
  overflow: 'hidden'
}
