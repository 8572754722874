import { Settings } from "@mui/icons-material";
import { Grid, Divider, Box, Button } from "@mui/material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { authApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError, NotifySuccess } from "src/utils/notification";
import {
  configWrapperSx,
  resetButtonsWrapperSx,
  configButtonSx,
  btnTextSx
} from "./UserResetWrapper.sx";
import { UserResetWrapperProps } from "./UserResetWrapper.types";

type ResetUserPasswordMutation = ReturnType<typeof authApi.useLazyForgotPasswordQuery>[0]

const handleResetUserPassword = (trigger: ResetUserPasswordMutation, dispatch: AppDispatch, emailAddress: string, successMessage: string) => async () => {
  try {
    dispatch(preloaderChangeStatus(true))
    await trigger({ emailAddress })
    NotifySuccess(successMessage)
  } catch (error) {
    if (isErrorObject(error)) {
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false))
  }
}

export const UserResetWrapper: FC<UserResetWrapperProps> = ({ data }) => {

  const [trigger] = authApi.useLazyForgotPasswordQuery();
  const dispatch = useAppDispatch()

  const intl = useIntl()
  const successMessage = intl.formatMessage({ id: 'emailWithInstructions' })
  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Box sx={configWrapperSx}>
          <Box sx={resetButtonsWrapperSx}>
            <Button
              sx={configButtonSx}
              onClick={handleResetUserPassword(trigger, dispatch, data.emailAddress, successMessage)}
            >
              <Settings />
              <Box
                component="span"
                sx={btnTextSx}
              >
                <FormattedMessage id="resetPassword" />
              </Box>
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  )
}
