import { FC } from "react";
import { FormControl, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useIntl } from "react-intl";
import { FormHelper } from "../FormHelper";
import { CustomFormLabel } from "../CustomFormLabel";
import { AMPDatePickerProps } from "./AMPDatePicker.types";

export const AMPDatePicker:FC<AMPDatePickerProps> = ({
  hasError,
  helperText,
  label,
  value,
  onChange,
  ...rest
}) => {
  const intl = useIntl();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={!!hasError}
      margin="normal"
    >
      <CustomFormLabel
        label={intl.formatMessage({ id: label })}
        required={rest.required}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="DD/MM/YYYY"
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} />}
          {...rest}
        />
      </LocalizationProvider>
      {helperText && (
        <FormHelper
          helperText={helperText}
        />
      )}
    </FormControl>
  )
}
