import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const pageWrapperSx: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: theme.spacing(25/8),
  background: theme.palette.background.paper,
  [theme.breakpoints.down('md')]:{
    gap: 0
  }
}
export const notificationsBoxSx: SxProps<Theme> = {
  position: "absolute",
  top: theme.spacing(20/8),
  right: theme.spacing(50/8),
  [theme.breakpoints.down('md')]: {
    top: theme.spacing(16/8),
    right: theme.spacing(16/8)
  }
}
export const notificationsNumberSx: SxProps<Theme> = {
  position: "absolute",
  top: theme.spacing(-5/8),
  right: theme.spacing(-5/8),
}
export const notificationsSx: SxProps<Theme> = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.neutral[100],
  borderRadius: '100%',
  width: theme.spacing(40/8),
  height: theme.spacing(40/8),
  "&:hover": {
    background: theme.palette.neutral[200],
    transition: "background ease-in-out 0.4s",
    '& svg': {
      fill: theme.palette.neutral[500],
      transition: "fill ease-in-out 0.4s"
    },
  },
  '& svg': {
    fill: theme.palette.neutral[400]
  }
}
export const pageContentSx: SxProps<Theme> = {
  padding: theme.spacing(50/8, 30/8),
  width: '100%',
  position: 'relative',
  height: '100%',
  [theme.breakpoints.down('md')]:{
    padding: theme.spacing(20/8, 15/8),
  }
}
export const preloaderWrapperSx: SxProps<Theme> = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2,
  background: 'rgb(255,255,255,0.5)'
}
export const contentSx: SxProps<Theme> = {
  '&.MuiContainer-root': {
    paddingLeft: 0,
    paddingRight: 0
  }
}
