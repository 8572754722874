import { SxProps, Theme } from "@mui/material"
import theme from "src/theme"

// const drawerWidthLg = 280 / 8;
// const drawerWidthMd = 240 / 8;
// const drawerWidthSm = 200 / 8;

export const appBarSx: SxProps<Theme> = {
  maxWidth: theme.spacing(225/8),
  left: 0,
  top: 0,
  [theme.breakpoints.down('md')]:{
    width: 'auto'
  }
}

export const drawerHeaderSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
}

export const drawerSx: SxProps<Theme> = {
  '& .MuiPaper-root': {
    width: theme.spacing(250/8),
    background: theme.palette.neutral[900],
    padding: theme.spacing(21/8, 12/8, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]:{
      width: '100%',
      padding: 0
    }
  }
}

export const logoWrapperSx: SxProps<Theme> = {
  padding: theme.spacing(0, 12/8),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(5/8),
  [theme.breakpoints.down('md')]:{
    display: 'none'
  }
}
export const logoWrapperMobileSx: SxProps<Theme> = {
  display: 'none',
  [theme.breakpoints.down('md')]:{
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(10/8),
    gap: theme.spacing(5/8),
    justifyContent: 'space-between',
    background: theme.palette.background.default
  }
}
export const toolbarSx: SxProps<Theme> = {
  '&.MuiToolbar-root': {
    marginTop: theme.spacing(50/8),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(10/8),
    alignItems: 'flex-start',
    paddingLeft: 0,
    paddingRight: 0
  }
}
export const linkSx: SxProps<Theme> = {
  display: 'flex',
  width: '100%',
  padding: 0,
  '& a' : {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(10/8),
    color: theme.palette.neutral[500],
    fill: theme.palette.neutral[500],
    padding: theme.spacing(12/8, 14/8),
    width: '100%',
    borderRadius: theme.spacing(4/8),
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.24)'
    },
    '&.active': {
      color: theme.palette.brand.main,
      background: 'rgba(0, 0, 0, 0.24)'
    },
    '&.active svg': {
      fill: theme.palette.brand.main,
    }
  }
}
export const profileWrapperSx: SxProps<Theme> = {
  padding: theme.spacing(18/8, 4/8, 18/8, 8/8),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.neutral[500],
}
export const profileLogoSx: SxProps<Theme> = {
  color: theme.palette.text.secondary,
  padding: theme.spacing(6/8),
  background: 'linear-gradient(135deg, #821F9B 0%, #28419D 28.03%, #007FDC 54.28%, #DEF0FC 85.42%)',
  borderRadius: theme.spacing(24/8),
  minWidth: theme.spacing(36/8),
  height: theme.spacing(36/8),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}
export const profileNameSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(10/8),
  color: theme.palette.neutral[500]
}
export const settingsButtonSx: SxProps<Theme> = {
  background: 'transparent'
}

export const imageWrapperSx: SxProps<Theme> = {
  width: theme.spacing(32/8),
  height: theme.spacing(32/8),
  overflow: 'hidden',
  borderRadius: '50%',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}

export const hideDrawerButtonSx: SxProps<Theme> = {
  [theme.breakpoints.down('md')]:{
    background: 'transparent',
    fill: theme.palette.background.default,
    '&:hover': {
      background: 'transparent'
    }
  }
}
