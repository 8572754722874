import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { reportsApi } from "../../../../api";
import dayjs from "dayjs";
import { getConsumedPowerData } from "../ConsumptionReports.helpers";
import { ReportBarChart } from "../../ReportBarChart";

export const ConsumptionBusiness:FC = () => {
  const intl = useIntl()
  const [getConsumedPowerReportsBusinesses, consumedPowerReportsBusinesses] = reportsApi.useLazyGetConsumedPowerReportQuery()
  const [dates, setDates] = useState<{from?: number, to?: number}>({
    from: undefined,
    to: dayjs().valueOf()
  })
  
  const handleChangeDates = (
    newFrom: number,
    newTo: number,
  ) => {
    return () => {
      setDates({
        from: newFrom,
        to: newTo
      })
    }
  }
  
  useEffect(() => {
    getConsumedPowerData(
      getConsumedPowerReportsBusinesses,
      undefined,
      dates?.from,
      dates?.to
    )
  }, [dates]);
  
  const businessData = consumedPowerReportsBusinesses?.data?.consumedPowerByBusiness?.map(item => ({
    name: item.businessName,
    [intl.formatMessage({id: "consumedPower"})]: item.consumedPower / 1000
  })) ?? []
  
  return (
    <ReportBarChart
      title={intl.formatMessage({ id: "powerConsumptionBusiness" })}
      data={businessData}
      dataKey={intl.formatMessage({id: "consumedPower"})}
      handleChangeDates={handleChangeDates}
      field="businesses"
    />
  )
}