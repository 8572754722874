import { FC } from "react";
import { termsOfUseSx } from "./TermsGerman.sx";
import { Box, Typography } from "@mui/material";

export const TermsGerman:FC = () => {
  return (
    <Box sx={termsOfUseSx.root}>
      <Typography variant="h2">
        Allgemeine Geschäftsbedingungen der AMP IT SA
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Einführung
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Diese Allgemeinen Geschäftsbedingungen, die auf dieser Webseite niedergeschrieben sind, regeln Ihre Nutzung unserer Website und mobilen Anwendung, die Eigentum von AMP IT SA ist und unter amp-it.com zugänglich ist.
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Diese Bedingungen werden vollständig angewendet und wirken sich auf Ihre Nutzung dieser Website aus. Durch die Nutzung dieser Website erklären Sie sich damit einverstanden, alle hier aufgeführten Bedingungen zu akzeptieren. Sie dürfen diese Website nicht nutzen, wenn Sie mit den Allgemeinen Geschäftsbedingungen dieser Website nicht einverstanden sind.
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Minderjährigen oder Personen unter 18 Jahren ist die Nutzung dieser Website nicht gestattet.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Rechte an geistigem Eigentum
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Abgesehen von den Inhalten, die Sie im Rahmen dieser Bedingungen besitzen, sind AMP IT SA und/oder seine Lizenzgeber Eigentümer aller geistigen Eigentumsrechte und Materialien, die auf dieser Website enthalten sind. Unsere Allgemeinen Geschäftsbedingungen wurden mit dem Template Generator für Allgemeine Geschäftsbedingungen erstellt.
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Ihnen wird eine eingeschränkte Lizenz nur für die Zwecke der Anzeige des auf dieser Website enthaltenen Materials gewährt.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Beschränkungen
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Es ist Ihnen ausdrücklich untersagt, die folgenden Handlungen auszuführen:
      </Typography>
      <ul>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            Die Veröffentlichung von Website-Material in anderen Medien;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            der Verkauf, die Unterlizenzierung und/oder anderweitige Kommerzialisierung von Website-Material;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            das öffentliche Aufführen und/oder Zeigen von Website-Material;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            die Nutzung dieser Website in einer Weise, die dieser Website schadet oder schaden könnte;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            die Nutzung dieser Website in einer Weise, die den Zugang der Nutzer zu dieser Website beeinträchtigt;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            die Nutzung dieser Website in einer Weise, die gegen geltende Gesetze und Vorschriften verstößt oder in irgendeiner Weise der Website, einer Person oder einem Unternehmen Schaden zufügen kann;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            die Durchführung von Data-Mining, Datensammlung, Datenextraktion oder anderen ähnlichen Aktivitäten in Bezug auf diese Website;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            die Nutzung dieser Website zu Werbe- oder Marketingzwecken.
          </Typography>
        </li>
      </ul>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Der Zugriff auf bestimmte Bereiche dieser Website ist für Sie eingeschränkt, und AMP IT SA kann nach eigenem Ermessen jederzeit den Zugriff auf beliebige Bereiche dieser Website weiter einschränken. Jede Benutzer-ID und jedes Passwort, das Sie für diese Website haben, sind vertraulich und Sie müssen die Vertraulichkeit ebenfalls wahren.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Ihre Inhalte
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        In diesen Allgemeinen Geschäftsbedingungen für die Website bezeichnet der Begriff &quot;Ihre Inhalte&quot; alle Audio-, Video-, Text-, Bild- oder sonstigen Materialien, die Sie auf dieser Website anzeigen möchten. Durch die Anzeige Ihrer Inhalte gewähren Sie AMP IT SA eine nicht-exklusive, weltweite, unwiderrufliche und unterlizenzierbare Lizenz zur Nutzung, Reproduktion, Anpassung, Veröffentlichung, Übersetzung und Verbreitung in allen Medien.
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Ihre Inhalte müssen Ihre eigenen sein und dürfen nicht die Rechte Dritter verletzen. AMP IT SA behält sich das Recht vor, Ihre Inhalte jederzeit ohne Vorankündigung von dieser Website zu entfernen.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Keine Garantie
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Diese Website wird &quot;im Ist-Zustand&quot; mit allen Fehlern bereitgestellt, und AMP IT SA gibt keinerlei Zusicherungen oder Gewährleistungen in Bezug auf diese Website oder die auf dieser Website enthaltenen Materialien. Auch ist nichts auf dieser Website als Beratung zu interpretieren.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Haftungsbeschränkung
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        In keinem Fall haften AMP IT SA oder ihre leitenden Angestellten, Direktoren und Mitarbeiter für irgendetwas, das sich aus Ihrer Nutzung dieser Website ergibt oder in irgendeiner Weise damit zusammenhängt, unabhängig davon, ob eine solche Haftung vertraglich vereinbart ist. AMP IT SA, einschließlich seiner leitenden Angestellten, Direktoren und Mitarbeiter, haftet nicht für indirekte, Folge- oder besondere Haftungen, die sich aus Ihrer Nutzung dieser Website ergeben oder in irgendeiner Weise damit zusammenhängen.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Entschädigung
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Sie stellen AMP IT SA hiermit in vollem Umfang von allen Verbindlichkeiten, Kosten, Forderungen, Klagegründen, Schäden und Ausgaben frei, die in irgendeiner Weise im Zusammenhang mit Ihrer Verletzung einer der Bestimmungen dieser Bedingungen entstehen.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Salvatorische Klausel
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Sollte sich eine Bestimmung dieser Bedingungen nach geltendem Recht als ungültig erweisen, werden diese Bestimmungen gestrichen, ohne dass die übrigen Bestimmungen hiervon berührt werden.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Änderung der Bedingungen
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        AMP IT SA ist berechtigt, diese Bedingungen jederzeit nach eigenem Ermessen zu ändern, und durch die Nutzung dieser Website wird von Ihnen erwartet, dass Sie diese Bedingungen regelmäßig überprüfen.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Abtretung
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Die AMP IT SA ist berechtigt, ihre Rechte und/oder Pflichten aus diesen Bedingungen ohne jegliche Benachrichtigung abzutreten, zu übertragen und als Unterauftrag zu vergeben. Es ist Ihnen jedoch nicht gestattet, Ihre Rechte und/oder Pflichten aus diesen Bedingungen abzutreten, zu übertragen oder als Unterauftrag zu vergeben.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Gesamte Vereinbarung
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Diese Bedingungen stellen die gesamte Vereinbarung zwischen AMP IT SA und Ihnen in Bezug auf Ihre Nutzung dieser Website dar und ersetzen alle vorherigen Vereinbarungen und Absprachen.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Anwendbares Recht und Gerichtsstand
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Diese Bedingungen werden in Übereinstimmung mit den in der Schweiz geltenden Gesetzen geregelt und ausgelegt, und Sie unterwerfen sich der nicht ausschließlichen Zuständigkeit der Bundes- und Kantonsgerichte mit Sitz in Genf für die Beilegung von Streitigkeiten.
      </Typography>
    </Box>
  )
}