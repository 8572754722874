import { alpha } from "@mui/material";
import theme from "../../../theme";

export const rfidTableSx = {
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: "10px",
    pt: 3
  },
  title: {
    fontSize: '1em',
    fontWeight: 600,
    pl: 3,
    pb: 3
  },
  addBox: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.palette.neutral[100],
    px: 3,
    py: 2,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8
  },
  table: {
    border: 'none',
    minHeight: "300px",
    mx: 3,
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
  },
  addBtn: {
    width: 24,
    height: 24,
    backgroundColor: theme.palette.neutral[200],
    color: theme.palette.neutral[700],
    "&:hover": {
      backgroundColor: theme.palette.neutral[300],
    }
  },
  addText: {
    color: theme.palette.neutral[700],
    fontSize: '0.75rem',
    fontWeight: 600,
    px: 1
  },
  colTitle: {
    textTransform: "uppercase"
  },
  tag: {
    py: 1,
    px: 2,
    borderRadius: 1,
    display: 'flex',
    alignItems: 'center'
  },
  tagActive: {
    color: theme.palette.success.dark,
    backgroundColor: alpha(theme.palette.success.dark, 0.1)
  },
  tagBlocked: {
    color: theme.palette.error.dark,
    backgroundColor: alpha(theme.palette.error.dark, 0.1)
  }
}
