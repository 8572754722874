import * as Yup from 'yup';
import { LanguageEnum, TitleEnum } from "../../../../enums";

export interface EditUserFormType {
		phoneNumber: string,
		language: LanguageEnum,
		title: TitleEnum
}

export interface EditUserNameFormType {
	firstName: string,
	lastName: string
}

export const editUserNameFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(255)
    .required('schemaRequiredFirstName'),
  lastName: Yup.string()
    .max(255)
    .required('schemaRequiredLastName'),
});

export const editUserFormSchema = Yup.object().shape({
  language: Yup.string(),
  title: Yup.string(),
  phoneNumber: Yup.string()
});
