import { Button, Divider, SelectChangeEvent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, FormEvent } from "react";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "src/app/hooks";
import { AMPFormSelect } from "src/components/common/AMPFormSelect";
import { SubmitButton } from "src/components/common/SubmitButton";
import { RoleEnum } from "src/enums";
import { useStyles } from "./UsersFilterForm.styles";
import { UsersFilterFormType } from "./UsersFilterForm.types";

export const UsersFilterForm: FC<UsersFilterFormType>= ({
  handleFilter,
  isFilterActive,
  handleClose,
  activeRole,
  setActiveRole,
  activeBusiness,
  setActiveBusiness,
  businesses,
  isManagersRoles
}) => {
  const classes = useStyles()
  const { authInfo } = useAppSelector(state => state.common)
  
  const processedRoles = () => {
    const arr = isManagersRoles ? [RoleEnum.TECHNICAL_MANAGER, RoleEnum.SALES_MANAGER, RoleEnum.CLIENT_MANAGER] : [RoleEnum.BUSINESS, RoleEnum.DRIVER, RoleEnum.SUPER_ADMIN]
    const data = arr.map((role, index) => (RoleEnum.CLIENT_MANAGER &&{
      id: index,
      name: role,
      value: role
    }))

    if(authInfo.roles[0].role !== RoleEnum.SUPER_ADMIN){
      return data.filter(item => item.value !== RoleEnum.SUPER_ADMIN)
    }
    return data
  }

  const handleChangeRole = (event: SelectChangeEvent<string>) => {
    setActiveRole(event.target.value)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleFilter({ role: activeRole, businessId: activeBusiness })
    isFilterActive(true)
    handleClose()
  }

  const handleClear = () => {
    setActiveRole('')
    setActiveBusiness(0)
    handleFilter({ role: '', businessId: 0 })
    isFilterActive(false)
  }
  return (

    <form
      onSubmit={handleSubmit}
      noValidate
    >
      <Box className={classes.fieldsWrapper}>
        {Boolean(businesses.length) && <AMPFormSelect
          data={businesses}
          value={activeBusiness}
          label={"business"}
          input={"business"}
          onChange={(e: SelectChangeEvent<number>) => setActiveBusiness(+e.target.value)}
        />}
        <AMPFormSelect
          data={processedRoles()}
          value={activeRole}
          label={'role'}
          input={'role'}
          onChange={handleChangeRole}
        />
      </Box>
      <Divider />
      <Box className={classes.actionsWrapper}>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleClear}
        >
          <Typography
            variant="button"
            color="neutral.700"
          >
            <FormattedMessage id="clear" />
          </Typography>
        </Button>
        <SubmitButton
          text="apply"
        />
      </Box>
    </form>
  )
}
