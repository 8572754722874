import { FC, useContext, useState } from "react";
import { Box, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { Bar, CartesianGrid, Legend, ResponsiveContainer, BarChart, Tooltip, XAxis, YAxis } from "recharts";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import theme from "../../../theme";
import { TimeSelect } from "../../common/TimeSelect";
import { getNumberFromPx } from "../../../utils/helper";
import { timeMap } from "src/components/forms/formData/common/initialData";
import { PickCustomPeriodFormReturnType } from "../../forms/PickCustomPeriodForm/PickCustomPeriodForm.types";
import { ReportBarChartProps } from "./ReportBarChart.types";
import { reportBarChartSx } from "./ReportBarChart.sx";
import { longestChartNaming } from "../../../utils/consts";
import DashboardLayoutContext from "../../../layouts/DashboardLayout/DashboardLayout.context";

export const ReportBarChart:FC<ReportBarChartProps> = ({
  data,
  title,
  dataKey,
  handleChangeDates,
  field
}) => {
  const intl = useIntl()
  const [currentTime, setCurrentTime] = useState(4)
  const [showCalendar, setShowCalendar] = useState(false)
  const responsive = useContext(DashboardLayoutContext)
  
  const handleShowCalendar = () => {
    setShowCalendar(true)
  }
  const handleHideCalendar = () => {
    setShowCalendar(false)
  }
  
  const handleCustomClick = () => {
    handleShowCalendar()
  }
  
  const onSelectDate = (e: SelectChangeEvent) => {
    const value = Number(e.target.value)
    setCurrentTime(value)
    if (value !== 5 && field) {
      handleChangeDates
      && handleChangeDates(timeMap(intl, handleCustomClick)[value]?.dateFrom, dayjs().valueOf(), field)()
    }
    if (value === 5) {
      setShowCalendar(true)
    }
  }
  
  const onSubmitRange = () => {
    return (values: PickCustomPeriodFormReturnType) => {
      handleChangeDates && field &&
      handleChangeDates(
        dayjs(values.range.startDate).valueOf(),
        dayjs(values.range.endDate).valueOf(),
        field
      )()
      handleHideCalendar()
    }
  }
  
  return (
    <Box sx={reportBarChartSx.root}>
      <Stack sx={reportBarChartSx.top}>
        <Typography variant="subtitle1">
          {title}
        </Typography>
        <Box sx={reportBarChartSx.select}>
          <TimeSelect
            currentTime={currentTime}
            onSubmitRange={onSubmitRange}
            onChange={onSelectDate}
            handleCustomClick={handleCustomClick}
            showCalendar={showCalendar}
            handleShowCalendar={handleShowCalendar}
            handleHideCalendar={handleHideCalendar}
          />
        </Box>
      </Stack>
      <ResponsiveContainer
        width="100%"
        aspect={responsive ? 1 : 3}
      >
        <BarChart
          data={data}
          margin={{
            top: getNumberFromPx(5),
            right: getNumberFromPx(0),
            left: getNumberFromPx(0),
            bottom: getNumberFromPx(15),
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            height={responsive
              ? getNumberFromPx(80)
              : getNumberFromPx(50)
            }
            fontSize={getNumberFromPx(12)}
            dataKey="name"
            tickFormatter={(item) => {
              if (!item.length) {
                return
              }
              if (item.length < longestChartNaming) {
                return item
              }
              return item.slice(0, longestChartNaming) + '...'
            }}
            angle={responsive ? 90 : 0}
            tick={{ textAnchor: 'start', 'dominantBaseline': 'ideographic' }}
          />
          <YAxis
            fontSize={12}
            unit={intl.formatMessage({ id: "kwh" })}
          />
          {!responsive && <Legend/>}
          <Tooltip />
          <Bar
            maxBarSize={responsive
              ? getNumberFromPx(12)
              : getNumberFromPx(28)
            }
            radius={[getNumberFromPx(10), getNumberFromPx(10), 0, 0]}
            dataKey={dataKey}
            fill={theme.palette.primary.main}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
