import { Box } from "@mui/material";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { TablePagination } from "src/components/tables/TablePagination";
import { TableRowCounter } from "src/components/tables/TableRowCounter";
import { Table } from "../../tables/Table";
import { ContractsTableProps } from "./ContractsTable.types";
import { columnConfigContracts } from "./ContractsTable.tableConfig";
import { ContractFilterValue } from "src/types";
import { ContractSortFieldEnum } from "src/enums";
import { ContractsTableActions } from "../ContractsTableActions";
import { useAppSelector } from "src/app/hooks";
import { ContractItemCard } from "../ContractItemCard";
import { header, tableFooter } from "./ContractsTable.sx";
import { ResponsiveTable } from "../../common/ResponsiveTable";

export const ContractsTable: FC<ContractsTableProps> = ({ data, size, pageNumber, handleUpdateTableData, handleChangePage, handleChangeRowsNumber, businessId }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<ContractFilterValue>({ locationId: undefined, status: undefined, businessId: undefined });
  const [sortValue, setSortValue] = useState<GridSortItem>({ field: '', sort: 'desc' });

  const { authInfo } = useAppSelector(state => state.common)  

  const handleSearch = ( value: string ) => {
    setSearchValue(value)
    handleUpdateTableData(updateData(value, sortValue, filterValue))
  }

  const handleFilter = ( value: ContractFilterValue ) => {
    setFilterValue(value)
    handleUpdateTableData(updateData(searchValue, sortValue, value))
  }

  const handleSort = ( value: GridSortModel ) => {
    setSortValue(value[0])
    handleUpdateTableData(updateData(searchValue, value[0], filterValue))
  }
  
  const updateData = (search: string, sort: GridSortItem, filter: ContractFilterValue) => {
    return {
      businessId: filter.businessId,
      locationId: Number(filter?.locationId),
      status: filter.status,
      keyword: search,
      sortAsc: sort ? sort.sort === 'asc' : true,
      sortField: (sort && sort.field) || ContractSortFieldEnum.CREATED
    }
  }

  
  return (
    <Box sx={header} >
      <ContractsTableActions
        businessId={businessId}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
      />
      <ResponsiveTable
        responsiveElement={
          <Box>
            {data?.content?.map(item => <ContractItemCard
              key={item?.id}
              {...item}
            />)}
          </Box>
        }
        desktopElement={
          <Table
            rows={data.content}
            hideFooter
            checkboxSelection={false}
            columns={columnConfigContracts(authInfo.roles[0].role)}
            handleSort={handleSort}
          />
        }
      />
      <Box sx={tableFooter}>
        <TableRowCounter
          defaultValue={size}
          handleChangeRowsNumber={handleChangeRowsNumber}
        />
        <TablePagination
          totalPages={data.totalPages}
          page={pageNumber && pageNumber === 0 ? pageNumber : data.number}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Box>
  )
}
