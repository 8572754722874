import { FC } from "react";
import { DateRangePicker } from 'react-date-range';
import { FormControl, Stack } from "@mui/material";
import { AMPDateRangePickerProps } from "./AMPDateRangePicker.types";
import { CustomFormLabel } from "../CustomFormLabel";
import { useIntl } from "react-intl";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { AMPDateRangePickerSx } from "./AMPDateRangePicker.sx";

export const AMPDateRangePicker:FC<AMPDateRangePickerProps> = ({
  hasError,
  helperText,
  label,
  value,
  onChange,
  ...rest
}) => {
  const intl = useIntl()
  
  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={!!hasError}
      margin="normal"
    >
      {!!label?.length && <CustomFormLabel
        label={intl.formatMessage({ id: label })}
        required={rest.required}
      />}
      <Stack sx={AMPDateRangePickerSx.calendar}>
        <DateRangePicker
          ranges={[value]}
          onChange={onChange}
        />
      </Stack>
    </FormControl>
  )
}
