import { alpha, SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const wrapperSx: SxProps<Theme> = {
  height: 'auto',
  position: 'sticky',
  overflow: 'hidden',
  top: 0,
  [theme.breakpoints.up('md')]:{
    height: '100vh',
  }
}

export const linksSx: SxProps<Theme> = {
  pb: 6
}

export const linksSmSx: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.up('md')]:{
    display: "none",
  }
}

export const linksBoxSx: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: 1,
  py: 2,
  "& a": {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
    height: theme.spacing(38/8),
    width: theme.spacing(160/8),
    borderRadius: theme.spacing(8/8),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(60/8)
    }
  }
}

export const titleSx: SxProps<Theme> = {
  lineHeight: '1.2',
  marginBottom: theme.spacing(20/8)
}

export const containerSx: SxProps<Theme> = {
  height: '100%',
  padding: theme.spacing(90 / 8, 40 / 8),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]:{
    padding: theme.spacing(60 / 8, 30 / 8),
  },
  [theme.breakpoints.down('md')]:{
    padding: theme.spacing(40 / 8, 20 / 8, 60/8),
  }
}

export const headerSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}

export const drawerInnerSx: SxProps<Theme> = {
  marginBottom: theme.spacing(100/8)
}
export const drawerActionsSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  '& span': {
    margin: theme.spacing(0, 5 / 8),
    color: theme.palette.secondary.light
  }
}

export const langSelectWrapperSx: SxProps<Theme> = {
  [theme.breakpoints.up('md')]:{
    display: 'none'
  }
}

export const secondaryTextSx: SxProps<Theme> = {
  color: theme.palette.neutral[400]
}
export const drawerContentSx: SxProps<Theme> = {
  display: "flex",
  height: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
  pt: 12,
  [theme.breakpoints.down('md')]:{
    display: 'none'
  }
}
