import { alpha, SxProps, Theme } from '@mui/material';
import theme from 'src/theme';
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";

export const rootSx: SxProps<Theme> = {
  width: '100%',
  padding: theme.spacing(24/8),
  background: theme.palette.text.secondary,
  borderRadius: `${theme.spacing(8/8)} ${theme.spacing(8/8)} 0 0`,
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(15/8),
  },
  ".recharts-tooltip-wrapper": {
    outline: "none"
  }
}
export const errorButtonSx: SystemStyleObject<Theme> = {
  backgroundColor: alpha(theme.palette.error.main, 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.error.main, 0.2)
  }
}
export const errorButtonMenuSx: SystemStyleObject<Theme> = {
  maxWidth: theme.spacing(300/8),
  height: theme.spacing(32/8),
  width: "100%",
  mt: 2,
  mb: 1,
  [theme.breakpoints.down('lg')]: {
    m: 0
  }
}

export const emptyStackSx: SxProps<Theme> = {
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  py: 3
}
export const titleSx: SxProps<Theme> = {
  marginBottom: theme.spacing(10/8),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: "column",
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  }
}

export const selectItemSx: SxProps<Theme> = {
  maxWidth: theme.spacing(150/8),
  width: "100%",
}
export const selectWrapperSx: SxProps<Theme> = {
  maxWidth: theme.spacing(380/8),
  width: "100%",
  display: "flex",
  gap: 1,
  alignItems: "center",
  justifyContent: 'flex-end',
  [theme.breakpoints.down('lg')]: {
    maxWidth: theme.spacing(150/8),
    flexDirection: "column",
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    mb: 2
  }
}

export const tooltipWrapperSx: SxProps<Theme> = {
  padding: theme.spacing(8/8),
  background: theme.palette.background.default,
  borderRadius: theme.spacing(8/8),
  position: 'relative',
  left: '-25px',
  boxShadow: '0 0 5px rgb(0, 0, 0, 0.25)',
  outline: 'none',
  border: 'none',
  '&:after': {
    position: 'absolute',
    content: '""',
    borderWidth: theme.spacing(8/8),
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderTopColor: theme.palette.background.default,
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
  }
}
