import { FC } from "react";
import { Form, Formik, FormikProps } from "formik";
import { Box } from "@mui/material";
import { AMPDateRangePicker } from "../../common/AMPDateRangePicker";
import { Range, RangeKeyDict } from "react-date-range";
import { CancelButton } from "../../common/CancelButton";
import { SubmitButton } from "../../common/SubmitButton";
import { PickCustomPeriodFormProps, PickCustomPeriodFormReturnType } from "./PickCustomPeriodForm.types";
import { actionsWrapperSx, formContentSx, formSx } from "./PickCustopmPeriodForm.sx";

export const PickCustomPeriodForm:FC<PickCustomPeriodFormProps> = ({
  handleClose,
  initialValues,
  handleSubmit
}) => {
  const handleRangeChange = (setFieldValue: (field: string, value: Range) => void ) => {
    return (value: RangeKeyDict) => {
      setFieldValue('range', value?.selection)
    }
  }
  
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<PickCustomPeriodFormReturnType>) => (
        <Form
          onSubmit={handleSubmit}
          onError={console.log}
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <AMPDateRangePicker
                label=''
                value={values['range']}
                onChange={handleRangeChange(setFieldValue)}
              />
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={handleClose}
              />
              <SubmitButton
                text="showResults"
                color="primary"
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
