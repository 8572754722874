import { FC } from "react";
import { Box, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { businessesApi } from "../../../../api";
import { EmptyTable } from "../../../tables/EmptyTable";
import { RfidTabProps } from "./RfidTab.types";
import { MarkdownSx } from "../../BusinessTerms/TermsTab/TermsTab.sx";
import { useIntl } from "react-intl";

export const RfidTab:FC<RfidTabProps> = ({
  businessId,
  language,
  price,
  currency
}) => {
  const intl = useIntl()
  const documentData = businessesApi.useGetRfidTermsQuery({
    businessId: businessId,
    language: language
  })
  
  return (
    <Box>
      {!documentData?.data && <EmptyTable/>}
      {documentData?.data && <Typography
        variant="body1"
        component="div"
        sx={MarkdownSx}
      >
        <ReactMarkdown>
          {documentData?.data?.rfidTerms ?? ''}
        </ReactMarkdown>
      </Typography>
      }
      <Typography
        fontWeight={600}
        variant="subtitle2"
      >
        {`${intl.formatMessage({ id: 'rfidPrice' })}: ${price / 100} ${currency}`}
      </Typography>
    </Box>
  )
}