import { store } from "../../../app/store";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { reportsApi } from "../../../api";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type ConsumedPowerReportQueryTrigger = ReturnType<typeof reportsApi.useLazyGetConsumedPowerReportQuery>[0];

export const getConsumedPowerData = async (
  getConsumedPowerReports: ConsumedPowerReportQueryTrigger,
  businessId: number | undefined,
  dateFrom: number | undefined,
  dateTo: number | undefined
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    return getConsumedPowerReports({
      businessId,
      dateFrom,
      dateTo
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}