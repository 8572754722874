import { Box, Grid, SvgIcon, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { locationsApi, usersApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { CustomChart } from "src/components/common/CustomChart";
import { LocationsIcon } from "src/components/icons/ToolbarIcons";
import { UserIcon } from "src/components/icons/UserIcon";
import { RoleEnum } from "src/enums";
import theme from "src/theme";
import { ChartSimpleData, LocationSearchRequestData, UsersSearchRequestData } from "src/types";
import { chartWrapperSx, statWrapperSx } from "./LocationStats.sx";
import { LocationStatsType } from "./LocationStats.types";

const countData: any = (currentData: any) => {
  const data = currentData.data.content.map((item: any) => {
    return (new Date(item.created)).toLocaleDateString('fr-CH')
  }).filter((x: any, i: any, a: any) => a.indexOf(x) === i)
  const newData = data.map((item: any) => ({
    count: currentData.data ? currentData.data.content.filter((el: any) => (new Date(el.created)).toLocaleDateString('fr-CH') === item).length : 0,
    date: item
  }))
  return newData
}

export const LocationStats: FC<LocationStatsType> = ({ totalLocations, data }) => {
  
  const [getUsers, users] = usersApi.useLazyGetUsersQuery()
  const [getLocations, locations] = locationsApi.useLazyGetLocationsQuery()
  const [usersChartData, setUsersSetChartData] = useState<ChartSimpleData[]>()
  const [locationChartData, setLocationSetChartData] = useState<ChartSimpleData[]>()
  const { authInfo } = useAppSelector(state => state.common)

  useEffect(() => {
    if(users.data){
      setUsersSetChartData(countData(users))
    }
    if(locations.data){
      setLocationSetChartData(countData(locations))
    }
  }, [users])

  useEffect(() => {
    const data: LocationSearchRequestData | UsersSearchRequestData = {}
    if(authInfo.business){
      data.businessId = authInfo.business.id
    }
    getLocations({data, params: {size: 2000}})
    getUsers({data: {...data, roles: [RoleEnum.DRIVER]}, params: {size: 2000}})
  }, [])
  
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Box
          sx={statWrapperSx}
        >
          <Box>
            <UserIcon size={32}
              color={theme.palette.primaryColor[300]}/>
            <Typography variant="h3"
              lineHeight="1.2">
              {users.data?.totalElements}
            </Typography>
            <Typography variant="caption"
              fontSize={14}>
              <FormattedMessage id="users" />
            </Typography>
          </Box>
          <Box sx={chartWrapperSx}>
            {usersChartData?.length && <CustomChart
              data={usersChartData}
              color={theme.palette.primary.main}
            />}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Box
          sx={statWrapperSx}
        >
          <Box>
            <SvgIcon
              component={LocationsIcon}
              viewBox="0 0 20 20"
              color="success"
              fontSize="large"
            />
            <Typography variant="h3"
              lineHeight="1.2">
              {locations.data?.totalElements}
            </Typography>
            <Typography variant="caption"
              fontSize={14}>
              <FormattedMessage id="locations" />
            </Typography>
          </Box>
          <Box sx={chartWrapperSx}>
            {locationChartData?.length && <CustomChart
              data={locationChartData}
              color={theme.palette.success.main}
            />}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
