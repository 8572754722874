import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'src/axiosBaseQuery'
import { getBaseURL } from 'src/utils/helper'
import { CountryDTO } from "../../types/system";

export const systemApi = createApi({
  reducerPath: 'systemApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL() + '/system'),
  }),
  endpoints: (build) => ({
    getCountries: build.query<CountryDTO[], null>({
      query: () => ({
        url: '/countries',
        method: 'get'
      }),
    })
  }),
})
