import { FC } from "react";

export const EmptyRfidIcon:FC = () => {
  return (
    <svg width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M70.7824 118.26C96.2333 118.26 116.867 97.627 116.867 72.0853C116.867 46.5435 96.1424 25.9102 70.7824 25.9102C45.3316 25.9102 24.6982 46.5435 24.6982 72.0853C24.6982 97.627 45.3316 118.26 70.7824 118.26Z"
        fill="#F5F5F7"
        stroke="#DBDBE6"
        strokeWidth="2"
        strokeMiterlimit="10"/>
      <path d="M108.714 112.828C110.772 112.828 112.441 111.16 112.441 109.102C112.441 107.044 110.772 105.375 108.714 105.375C106.656 105.375 104.987 107.044 104.987 109.102C104.987 111.16 106.656 112.828 108.714 112.828Z"
        fill="#F5F5F7"/>
      <path d="M118.47 53.4574C119.876 53.4574 121.015 52.3179 121.015 50.9123C121.015 49.5067 119.876 48.3672 118.47 48.3672C117.065 48.3672 115.925 49.5067 115.925 50.9123C115.925 52.3179 117.065 53.4574 118.47 53.4574Z"
        fill="#F5F5F7"/>
      <path d="M27.4899 41.9398C28.8955 41.9398 30.035 40.8003 30.035 39.3947C30.035 37.9891 28.8955 36.8496 27.4899 36.8496C26.0843 36.8496 24.9448 37.9891 24.9448 39.3947C24.9448 40.8003 26.0843 41.9398 27.4899 41.9398Z"
        fill="#F5F5F7"/>
      <path d="M25.0652 109.07C27.6756 109.07 29.7918 106.954 29.7918 104.344C29.7918 101.733 27.6756 99.6172 25.0652 99.6172C22.4548 99.6172 20.3386 101.733 20.3386 104.344C20.3386 106.954 22.4548 109.07 25.0652 109.07Z"
        fill="#F5F5F7"/>
      <path d="M70.7768 118.26C96.2277 118.26 116.861 97.627 116.861 72.0853C116.861 46.5435 96.1368 25.9102 70.7768 25.9102C45.326 25.9102 24.6926 46.5435 24.6926 72.0853C24.6926 97.627 45.326 118.26 70.7768 118.26Z"
        stroke="#DBDBE6"
        strokeWidth="2"
        strokeMiterlimit="10"/>
      <path d="M103.645 56.6905L99.1985 72.2537L97.928 76.5945L86.8114 115.661C85.9644 118.838 82.5765 120.637 79.4003 119.79L60.555 114.391L41.1804 108.78C38.0042 107.933 36.2044 104.545 37.0513 101.369L51.6617 50.0205L53.8851 42.3977C54.6262 39.8568 56.426 37.7393 58.7552 36.5747L80.459 26.1992C83.7411 24.6111 87.6584 25.7757 89.67 28.846L102.586 49.0677C103.963 51.291 104.386 54.0437 103.645 56.6905Z"
        fill="#F5F5F7"
        stroke="#DBDBE6"
        strokeWidth="2"
        strokeMiterlimit="10"/>
      <path opacity="0.4"
        d="M99.1985 72.2531C99.1985 71.9354 99.1985 71.7237 99.1985 71.4061L97.7163 47.4789C97.5045 43.8792 94.646 40.9148 90.9404 40.7031L66.9074 39.2208C64.2606 39.115 61.6137 40.0678 59.8139 41.9735L51.6617 50.1257L37.0513 101.474C36.2044 104.65 38.0042 107.932 41.1804 108.885L60.6609 114.496L96.5517 78.6054C97.1869 77.9702 97.6104 77.3349 98.0339 76.6997"
        fill="#DBDBE6"/>
      <path d="M48.8031 111.743L20.7469 83.687C18.4177 81.3578 18.4177 77.5464 20.7469 75.2172L64.1546 31.8095C66.0603 29.9038 68.6013 28.9509 71.2481 29.0568L95.1752 30.539C98.7749 30.7507 101.739 33.6093 101.951 37.3148L103.433 61.242C103.645 63.8888 102.586 66.5356 100.681 68.3355L57.2729 111.743C54.9437 114.072 51.1323 114.072 48.8031 111.743Z"
        fill="white"
        stroke="#B3B3C9"
        strokeWidth="2"
        strokeMiterlimit="10"/>
      <path d="M92.2678 50.4676C95.0793 47.6561 95.0793 43.0978 92.2678 40.2863C89.4563 37.4748 84.898 37.4748 82.0865 40.2863C79.275 43.0978 79.275 47.6561 82.0865 50.4676C84.898 53.2791 89.4563 53.2791 92.2678 50.4676Z"
        fill="white"
        stroke="#B3B3C9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path d="M34.7229 80.8281C34.1936 80.8281 33.6642 80.6164 33.2407 80.1929C32.3937 79.3459 32.3937 78.0755 33.2407 77.2285L60.7675 49.7016C61.6145 48.8547 62.885 48.8547 63.732 49.7016C64.579 50.5486 64.579 51.8191 63.732 52.6661L36.311 80.1929C35.8875 80.6164 35.3582 80.8281 34.7229 80.8281Z"
        fill="#DBDBE6"/>
      <path d="M42.1321 88.1344C41.6027 88.1344 41.0734 87.9226 40.6499 87.4991C39.8029 86.6522 39.8029 85.3817 40.6499 84.5347L68.0709 57.1138C68.9178 56.2668 70.1883 56.2668 71.0353 57.1138C71.8823 57.9607 71.8823 59.2312 71.0353 60.0782L43.6143 87.4991C43.1908 87.9226 42.6615 88.1344 42.1321 88.1344Z"
        fill="#DBDBE6"/>
      <path d="M52.4016 98.4044C51.8723 98.4044 51.3429 98.1926 50.9194 97.7692C50.0724 96.9222 50.0724 95.6517 50.9194 94.8047L60.0244 85.6997C60.8714 84.8527 62.1419 84.8527 62.9889 85.6997C63.8359 86.5467 63.8359 87.8171 62.9889 88.6641L53.8838 97.7692C53.4604 98.1926 52.931 98.4044 52.4016 98.4044Z"
        fill="#DBDBE6"/>
      <path d="M112.22 34.5633C112.22 40.7039 108.62 46.3151 102.903 48.7502L102.479 47.6914V46.5268C107.032 44.3035 109.996 39.6451 109.996 34.5633C109.996 27.2581 104.067 21.2233 96.6563 21.2233C89.3511 21.2233 83.3163 27.1522 83.3163 34.5633C83.3163 36.0455 83.5281 37.4218 84.0574 38.7982C85.5397 43.1389 89.1393 46.5268 93.6918 47.5856L93.4801 48.6443L92.8449 49.703C87.8689 48.4326 83.9516 44.8329 82.1517 40.1745C81.5165 38.4805 81.093 36.5748 81.093 34.6691C81.1989 25.9876 88.0806 19 96.6563 19C105.338 19 112.22 25.9876 112.22 34.5633Z"
        fill="#B3B3C9"
        stroke="#B3B3C9"
        strokeWidth="2"
        strokeMiterlimit="10"/>
    </svg>
  )
}
