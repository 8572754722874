import { FC } from "react";
import { useIntl } from "react-intl";
import { Stack, Typography } from "@mui/material";
import { TableRowTypes } from "./TableRow.types";
import { colSx, iconStackSx, idTextSx, rowSx, titleSx, valueSx } from "./TableRow.sx";
import { AppRouteEnum, AppRouteSpace } from "../../../../utils/helper";
import { chargerListCardSx } from "../../../chargers/ChargerListCard/ChargerListCard.sx";
import { Link } from "react-router-dom";

export const TableRow:FC<TableRowTypes> = ({
  titleId,
  value,
  icon,
  customValue,
  isColumn,
  link,
  isId
}) => {
  const intl = useIntl()
  
  return (
    <Stack sx={isColumn ? colSx : rowSx}>
      <Typography sx={titleSx}>
        {intl.formatMessage({id: titleId})}
      </Typography>
      {icon &&
        <Stack sx={iconStackSx}>
          {icon}
          <Typography sx={valueSx}>
            {value}
          </Typography>
        </Stack>
      }
      {customValue && customValue}
      {link && <Link to={link}>
        <Typography sx={[valueSx, idTextSx]}>
          {value}
        </Typography>
      </Link>}
      {!(icon || customValue || link) &&
        <Typography sx={[valueSx, isId ? idTextSx : {}]}>
          {value}
        </Typography>
      }
    </Stack>
  )
}
