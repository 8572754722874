import { FC } from "react";

export const ErrorIcon:FC = () => {
  return (
    <svg
      width="71"
      height="65"
      viewBox="0 0 71 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.46806 30.9636C4.04285 13.792 18.187 0 35.497 0C52.807 0 66.9541 13.792 67.5319 30.9636H70.3818V33.3711H67.5229C66.8277 50.4313 52.7318 64.1 35.5 64.1C18.2682 64.1 4.17225 50.4344 3.47708 33.3711H0.618164V30.9636H3.46806ZM5.8545 32.053C5.8545 48.397 19.15 61.6955 35.497 61.6955V61.6925C51.841 61.6925 65.1395 48.394 65.1395 32.05C65.1395 15.706 51.841 2.40751 35.497 2.40751C19.153 2.40751 5.8545 15.709 5.8545 32.053ZM17.1341 39.5423C20.8008 33.2037 27.6527 28.9413 35.4993 28.9413C43.346 28.9413 50.1978 33.2037 53.8645 39.5423L54.9032 38.9414C51.0309 32.2473 43.7917 27.7413 35.4993 27.7413C27.207 27.7413 19.9678 32.2473 16.0954 38.9414L17.1341 39.5423Z"
        fill="#E62930"
      />
    </svg>
  )
}
