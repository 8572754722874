
export const useStyles = ({
  preloaderWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    background: 'rgb(255,255,255,0.5)'
  },
});
