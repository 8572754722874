import { Box, Typography, Grid, FormControlLabel, Checkbox } from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import { FC } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { AMPFormSelect } from "src/components/common/AMPFormSelect";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { ScheduleTypeEnum, WeekEnum } from "src/enums";
import theme from "src/theme";
import { hoursArray, minutesArray } from "src/utils/consts";
import { getHelperText } from "src/utils/forms";
import { formatTime } from "src/utils/helper";
import { scheduleInitialValue } from "../formData/common/initialData";
import { scheduleFormSchema, ScheduleFormType } from "../formData/schedule";
import { actionsWrapperSx, formContentSx, formSx } from "./ScheduleForm.sx";
import { ScheduleFormPropsType } from "./ScheduleForm.types";

export const ScheduleForm: FC<ScheduleFormPropsType> = ({handleClose, initialValue, handleSubmit }) => {
  const intl = useIntl();

  const processedWeekDays = () => {
    return Object.values(WeekEnum).map((day, index) => ({
      id: index,
      name: intl.formatMessage({id: day}),
      value: day
    }))
  }

  const processedHours = () => {
    return hoursArray().map((hour, index) => ({
      id: index,
      name: String(hour),
      value: hour
    }))
  }

  const processedMinutes = () => {
    return minutesArray().map((minute, index) => ({
      id: index,
      name: String(minute),
      value: minute
    }))
  }

  const getInitialValue = (): ScheduleFormType => {
    if(!initialValue) {
      return scheduleInitialValue
    }
    return {
      startDay: initialValue.startDay,
      startHour: formatTime(initialValue.startHour),
      startMinute: formatTime(initialValue.startMinute),
      stopDay: initialValue.stopDay,
      stopHour: formatTime(initialValue.stopHour),
      stopMinute: formatTime(initialValue.stopMinute),
      type: ScheduleTypeEnum.CYCLE === initialValue.type
    }
  }

  return (
    <Formik
      initialValues={getInitialValue()}
      validationSchema={scheduleFormSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }: FormikProps<ScheduleFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="generalInfo" />
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={4}
                  item
                >
                  <AMPFormSelect
                    data={processedWeekDays()}
                    value={values['startDay']}
                    label={'startDay'}
                    input={'startDay'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['startDay'] && Boolean(errors['startDay'])}
                    helperText={getHelperText('startDay', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={4}
                  item
                >
                  <AMPFormSelect
                    data={processedHours()}
                    value={values['startHour']}
                    label={'startHour'}
                    input={'startHour'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['startHour'] && Boolean(errors['startHour'])}
                    helperText={getHelperText('startHour', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={4}
                  item
                >
                  <AMPFormSelect
                    data={processedMinutes()}
                    value={values['startMinute']}
                    label={'startMinute'}
                    input={'startMinute'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['startMinute'] && Boolean(errors['startMinute'])}
                    helperText={getHelperText('startMinute', touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={4}
                  item
                >
                  <AMPFormSelect
                    data={processedWeekDays()}
                    value={values['stopDay']}
                    label={'stopDay'}
                    input={'stopDay'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={Boolean(!values['startDay'])}
                    hasError={touched['stopDay'] && Boolean(errors['stopDay'])}
                    helperText={getHelperText('stopDay', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={4}
                  item
                >
                  <AMPFormSelect
                    data={processedHours()}
                    value={values['stopHour']}
                    label={'stopHour'}
                    input={'stopHour'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={Boolean(!values['startHour'])}
                    hasError={touched['stopHour'] && Boolean(errors['stopHour'])}
                    helperText={getHelperText('stopHour', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={4}
                  item
                >
                  <AMPFormSelect
                    data={processedMinutes()}
                    value={values['stopMinute']}
                    label={'stopMinute'}
                    input={'stopMinute'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={Boolean(!values['startMinute'])}
                    hasError={touched['stopMinute'] && Boolean(errors['stopMinute'])}
                    helperText={getHelperText('stopMinute', touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <FormControlLabel
                label={intl.formatMessage({id: 'repeatEveryWeek'})}
                sx={{marginTop: theme.spacing(10/8)}}
                control={
                  <Checkbox
                    checked={values["type"]}
                    color="secondary"
                    value={values["type"]}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setFieldValue('type', checked)}
                  />
                }
              />
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={handleClose}
              />
              <SubmitButton
                text="save"
                color="primary"
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
