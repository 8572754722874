import { Typography, useTheme } from "@mui/material";
import { FC, useCallback } from "react";
import { ChargePointStatus } from "src/enums";
import { StatusFieldType } from "./StatusField.types";

export const StatusField: FC<StatusFieldType> = ({label}) => {
  const theme = useTheme()
  const sxValue = useCallback(() => {
    switch(label){
      case ChargePointStatus.ONLINE: 
        return {
          paddingLeft: theme.spacing(14/8),
          color: theme.palette.success.main,
          fontWeight: 500,
          position: 'relative',
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: theme.spacing(6/8),
            height: theme.spacing(6/8),
            top: '50%',
            left: theme.spacing(2/8),
            transform: 'translateY(-50%)',
            background: theme.palette.success.main,
            borderRadius: '50%'
          }
        }
      case ChargePointStatus.OFFLINE:
        return {
          paddingLeft: theme.spacing(14/8),
          color: theme.palette.neutral[400],
          fontWeight: 500,
          position: 'relative',
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: theme.spacing(6/8),
            height: theme.spacing(6/8),
            top: '50%',
            left: theme.spacing(2/8),
            transform: 'translateY(-50%)',
            background: theme.palette.neutral[400],
            borderRadius: '50%'
          }
        }
    }
  }, [label])
  return (
    <Typography
      sx={sxValue()}
    >
      {label}
    </Typography>
  )
}
