import { Info } from "@mui/icons-material";
import { Box, Checkbox, FormControlLabel, Grid, IconButton, IconButtonProps, Popover, SelectChangeEvent, Typography } from "@mui/material";
import { Form, Formik, FormikConfig, FormikHandlers, FormikProps } from "formik";
import { FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { registerApi } from "src/api";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { AppDispatch } from "src/app/store";
import { AMPFormSelect } from "src/components/common/AMPFormSelect";
import { AMPInput } from "src/components/common/AMPInput";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { CurrencyEnum } from "src/enums";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { getHelperText } from "src/utils/forms";
import { isErrorObject, processedLanguages, valuesTrimmer } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { businessFormSchema, BusinessFormType } from "../formData/business";
import {
  AddBusinessInitialValue,
} from "../formData/common/initialData";
import { actionsWrapperSx, formSx, popoverSx, popoverTextSx } from "./BusinessForm.sx";
import { BusinessFormTypes } from "./BusinessForm.types";
import { systemApi } from "../../../api/system";
import { handleGetCountries } from "../../../hooks/useGetCountries";
import { SelectValue } from "../../../types";
import { TariffType } from "../formData/tariff";
import { allRegionsValue } from "../../../utils/consts";

type AddBusinessMutationTrigger = ReturnType<
  typeof registerApi.useAddNewBusinessMutation
>[0];

const handleSubmit =
  (
    trigger: AddBusinessMutationTrigger,
    dispatch: AppDispatch,
    handleClose: () => void,
  ): FormikConfig<BusinessFormType>["onSubmit"] =>
    async (value) => {
      try {
        dispatch(preloaderChangeStatus(true));
        const values = valuesTrimmer(value)
        const {
          city,
          country,
          region,
          houseNumber,
          street,
          zipCode,
          emailAddress,
          language,
          name,
          shareClients,
          userPerMonthCost,
          userPerMonthCurrency
        } = values;
        const data = {
          address: {
            city,
            region: region === allRegionsValue ? undefined : region,
            country,
            street,
            zipCode,
            houseNumber,
          },
          emailAddress,
          name,
          language,
          shareClients,
          userPerMonthCost,
          userPerMonthCurrency
        };
        await trigger(data)
          .unwrap()
        handleClose()
      } catch (error) {
        if(isErrorObject(error)){
          NotifyError(error.data.message)
        }
      } finally {
        dispatch(preloaderChangeStatus(false));
      }
    };

export const BusinessForm: FC<BusinessFormTypes> = ({ handleClose }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [trigger] = registerApi.useAddNewBusinessMutation();
  const { lang } = useAppSelector((state) => state.common)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl)
  
  const [getCountries, countries] = systemApi.useLazyGetCountriesQuery()
  const [activeCountry, setActiveCountry] = useState<string>('')
  
  useEffect(() => {
    handleGetCountries(getCountries, dispatch)
  }, [])
  
  const processedRegions = useMemo(() => {
    const initialArray: SelectValue[] = [{
      id: 0,
      name: activeCountry.length ? intl.formatMessage({id: activeCountry.toLowerCase()}) : '',
      value: allRegionsValue
    }]
    if (activeCountry.length) {
      const mappedRegions: SelectValue[] | undefined = countries.data?.find(country => country.name === activeCountry)?.
        regions?.map(region => ({
          id: region.id,
          name: intl.formatMessage({id: region.name}),
          value: region.name
        }))
      return  mappedRegions?.concat(initialArray[0])
        ?? initialArray
    }
    return initialArray
  }, [activeCountry, lang])
  
  
  const processedCountries = countries?.data?.map(country => ({
    id: country.id,
    name: intl.formatMessage({id: country.name.toLowerCase()}),
    value: country.name
  })) ?? []

  const processedCurrencies = () => {
    return Object.values(CurrencyEnum).map((value, index) => ({
      id: index,
      name: intl.formatMessage({id: value}),
      value: value
    }))
  }

  const handleChangeCountry = (
    handleChange: FormikHandlers["handleChange"],
    setFieldValue: FormikProps<TariffType>['setFieldValue']
  ) => (event: SelectChangeEvent) => {
    setActiveCountry(event.target.value);
    setFieldValue('region', '')
    return handleChange(event);
  };

  const iconButtonEnterHandler: IconButtonProps['onMouseEnter'] = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const iconButtonLeaveHandler: IconButtonProps['onMouseLeave'] = () => {
    setAnchorEl(null)
  }

  return (
    <Formik
      initialValues={AddBusinessInitialValue}
      validationSchema={businessFormSchema}
      onSubmit={handleSubmit(trigger, dispatch, handleClose)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }: FormikProps<BusinessFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box
            sx={formSx}
          >
            <Box>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="generalInfo" />
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["name"] as string}
                    type={"name"}
                    label={"name"}
                    input={"name"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["name"] && Boolean(errors["name"])
                    }
                    helperText={getHelperText("name", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["emailAddress"]}
                    type={"emailAddress"}
                    label={"emailAddress"}
                    input={"emailAddress"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["emailAddress"] && Boolean(errors["emailAddress"])
                    }
                    helperText={getHelperText(
                      "emailAddress",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["userPerMonthCost"]}
                    type={"number"}
                    label={"userPerMonthCost"}
                    input={"userPerMonthCost"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["userPerMonthCost"] && Boolean(errors["userPerMonthCost"])
                    }
                    helperText={getHelperText(
                      "userPerMonthCost",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={processedCurrencies()}
                    value={values["userPerMonthCurrency"] as string}
                    label={"userPerMonthCurrency"}
                    input={"userPerMonthCurrency"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["userPerMonthCurrency"] && Boolean(errors["userPerMonthCurrency"])}
                    helperText={getHelperText("userPerMonthCurrency", touched, errors, intl)}
                  />
                </Grid>
              
              </Grid>
            </Box>
            <Box>
              <Typography variant="subtitle1"
                color="textPrimary">
                <FormattedMessage id="yourAddress" />
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={processedCountries}
                    value={values["country"] as string}
                    label={"country"}
                    input={"country"}
                    onChange={handleChangeCountry(handleChange, setFieldValue)}
                    onBlur={handleBlur}
                    hasError={touched["country"] && Boolean(errors["country"])}
                    helperText={getHelperText("country", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={processedRegions}
                    value={values["region"] as string}
                    label={"region"}
                    input={"region"}
                    disabled={!activeCountry.length}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["region"] && Boolean(errors["region"])}
                    helperText={getHelperText("region", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    type="text"
                    value={values['city']}
                    label={'city'}
                    input={'city'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['city'] && Boolean(errors['city'])}
                    helperText={getHelperText('city', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["street"] as string}
                    type={"street"}
                    label={"street"}
                    input={"street"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["street"] && Boolean(errors["street"])}
                    helperText={getHelperText("street", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["houseNumber"] as string}
                    type={"text"}
                    label={"houseNumber"}
                    input={"houseNumber"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["houseNumber"] && Boolean(errors["houseNumber"])
                    }
                    helperText={getHelperText(
                      "houseNumber",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["zipCode"] as string}
                    type={"number"}
                    label={"zipCode"}
                    input={"zipCode"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["zipCode"] && Boolean(errors["zipCode"])}
                    helperText={getHelperText("zipCode", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <AMPFormSelect
                data={processedLanguages(intl)}
                value={values["language"] as string}
                label={"language"}
                input={"language"}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={touched["language"] && Boolean(errors["language"])}
                helperText={getHelperText("language", touched, errors, intl)}
              />
            </Box>
            <FormControlLabel
              label={
                <>
                  <FormattedMessage id='shareClients'/>
                  <IconButton
                    onMouseEnter={iconButtonEnterHandler}
                    onMouseLeave={iconButtonLeaveHandler}            
                  >
                    <Info />
                  </IconButton>
                  <Popover
                    id="mouse-over-popover"
                    sx={popoverSx}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={() => setAnchorEl(null)}
                    disableRestoreFocus
                  >
                    <Typography sx={popoverTextSx}>
                      <FormattedMessage id='shareClientsDescription'/>
                    </Typography>
                  </Popover>
                </>
              }
              control={
                <Checkbox
                  color="secondary"
                  value={values["shareClients"]}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setFieldValue('shareClients', checked)}
                />
              }
            />
          </Box>
          <Box sx={actionsWrapperSx}>
            <CancelButton
              text="cancel"
              handler={handleClose}
            />
            <SubmitButton
              text={"createUser"}
              color="primary"
              isSubmitting={isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};
