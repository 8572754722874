import { FC } from "react";


export const MailIcon: FC = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="44"
      height="44"
      rx="22"
      fill="white"
    />
    <path
      d="M12 15.5V13.993C12.0018 13.7304 12.1069 13.479 12.2925 13.2932C12.4781 13.1074 12.7294 13.0021 12.992 13H31.008C31.556 13 32 13.445 32 13.993V30.007C31.9982 30.2696 31.8931 30.521 31.7075 30.7068C31.5219 30.8926 31.2706 30.9979 31.008 31H12.992C12.7288 30.9997 12.4765 30.895 12.2905 30.7088C12.1045 30.5226 12 30.2702 12 30.007V29H30V17.3L22 24.5L12 15.5ZM10 20H15V22H10V20ZM10 25H18V27H10V25Z"
      fill="#666686"
    />
  </svg>

)

