import theme from "../../../theme";

export const termsOfUseSx = {
  paragraph: {
    my: 1,
    fontSize: theme.spacing(14/8),
    lineHeight: 1.3
  },
  header: {
    lineHeight: 1.5,
    my: 4
  },
  root: {
    '& ol li': {
      listStyle: "auto"
    },
    '& ul li': {
      listStyle: 'disc'
    },
    'h6': {
      fontWeight: 800
    }
  },
  link: {
    color: theme.palette.primary.main
  }
}
