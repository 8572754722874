import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { EmptyIcon } from "../../icons/EmptyIcon";
import { FormattedMessage } from "react-intl";
import { containerSx } from "./EmptyStatus.sx";

export const EmptyStatus:FC = () => {
  return (
    <Stack sx={containerSx}>
      <EmptyIcon/>
      <Typography>
        <FormattedMessage id="noNotifications" />
      </Typography>
    </Stack>
  )
}
