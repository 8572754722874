/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useMemo, useState } from "react"
import { Box, Checkbox, FormControlLabel, Grid, SelectChangeEvent, Typography } from "@mui/material"
import { Form, Formik, FormikConfig, FormikHandlers, FormikProps } from "formik"
import { FormattedMessage, useIntl } from "react-intl"
import { getHelperText } from "../../../utils/forms"
import { AMPInput } from "../../common/AMPInput"
import { signUpInitialValues } from "../formData/common/initialData"
import { signUpSchema, SignUpType } from "../formData/signUp"
import { registerApi } from "src/api"
import { formSx, requiredFieldSx, actionsWrapperSx } from "./SignUpForm.sx"
import { AppDispatch } from "src/app/store"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { SubmitButton } from "src/components/common/SubmitButton"
import { preloaderChangeStatus } from "src/stores/common/commonSlice"
import { AMPFormSelect } from "src/components/common/AMPFormSelect"
import { AppRouteEnum, AppRouteSpace, isErrorObject, valuesTrimmer } from "src/utils/helper"
import { TermsPrivacyAgreementLabel } from "src/components/common/TermsPrivacyAgreementLabel"
import { NotifyError } from "src/utils/notification"
import { systemApi } from "../../../api/system";
import { SelectValue } from "../../../types";
import { handleGetCountries } from "../../../hooks/useGetCountries";
import { TariffType } from "../formData/tariff";
import { allRegionsValue } from "../../../utils/consts";
import { LanguageEnum, NormalizeLanguageEnum } from "../../../enums";

type SignUpMutationTrigger = ReturnType<typeof registerApi.useRegisterDriversMutation>[0]

const handleSubmit = (trigger: SignUpMutationTrigger, dispatch: AppDispatch, navigate: NavigateFunction): FormikConfig<SignUpType>['onSubmit'] => (async (values) => {
  try {
    const value = valuesTrimmer(values)
    dispatch(preloaderChangeStatus(true))
    const {
      city,
      country,
      region,
      street,
      zipCode,
      houseNumber,
      emailAddress,
      password,
      parkingLot,
      firstName,
      lastName,
      language
    } = value
    const data = {
      address: {
        city,
        country,
        region: region === allRegionsValue ? undefined : region,
        street,
        zipCode,
        houseNumber,
        parkingPlace: parkingLot.toString() ?? ''
      },
      businessId: 1, // TODO: replace on business id parametr
      emailAddress,
      password,
      firstName,
      lastName,
      language
    }
    const result = await trigger(data).unwrap()
    navigate(AppRouteSpace([AppRouteEnum.verifyEmail]))
  } catch (error) {

    if (isErrorObject(error)) {
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
});

export const SignUpForm: FC = () => {
  const intl = useIntl();
  const { lang } = useAppSelector((state) => state.common)
  const [trigger] = registerApi.useRegisterDriversMutation()
  const navigate = useNavigate();
  const [getCountries, countries] = systemApi.useLazyGetCountriesQuery()
  const dispatch = useAppDispatch()
  const [activeCountry, setActiveCountry] = useState<string>('')

  useEffect(() => {
    handleGetCountries(getCountries, dispatch)
  }, [])

  const processedRegions = useMemo(() => {
    const initialArray: SelectValue[] = [{
      id: 0,
      name: activeCountry.length ? intl.formatMessage({ id: activeCountry.toLowerCase() }) : '',
      value: allRegionsValue
    }]
    if (activeCountry.length) {
      const mappedRegions: SelectValue[] | undefined = countries.data?.find(country => country.name === activeCountry)?.
        regions?.map(region => ({
          id: region.id,
          name: intl.formatMessage({ id: region.name }),
          value: region.name
        }))
      return mappedRegions?.concat(initialArray[0])
        ?? initialArray
    }
    return initialArray
  }, [activeCountry, lang])

  const handleChangeCountry = (
    handleChange: FormikHandlers["handleChange"],
    setFieldValue: FormikProps<TariffType>['setFieldValue']
  ) => (event: SelectChangeEvent) => {
    setActiveCountry(event.target.value);
    setFieldValue('region', '')
    return handleChange(event);
  };

  const processedCountries = countries?.data?.map(country => ({
    id: country.id,
    name: intl.formatMessage({ id: country.name.toLowerCase() }),
    value: country.name
  })) ?? []

  const processedLanguages = () => {
    return Object.values(LanguageEnum).map((item, index) => ({
      id: index,
      name: NormalizeLanguageEnum[item],
      value: item
    }))
  }

  return (
    <Formik
      initialValues={signUpInitialValues}
      validationSchema={signUpSchema}
      onSubmit={handleSubmit(trigger, dispatch, navigate)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }: FormikProps<SignUpType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box
            sx={formSx}
          >
            <Typography
              variant="caption"
              color="textPrimary"
              sx={requiredFieldSx}
            >
              <span>*</span>
              {intl.formatMessage({ id: "requiredFields" })}
            </Typography>
            <Box>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="generalInfo" />
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  md={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values['firstName']}
                    type={'firstName'}
                    label={'firstName'}
                    input={'firstName'}
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                    hasError={touched['firstName'] && Boolean(errors['firstName'])}
                    helperText={getHelperText('firstName', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  md={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values['lastName']}
                    type={'lastName'}
                    label={'lastName'}
                    input={'lastName'}
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                    hasError={touched['lastName'] && Boolean(errors['lastName'])}
                    helperText={getHelperText('lastName', touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <AMPInput
                value={values['emailAddress']}
                type={'emailAddress'}
                label={'emailAddress'}
                input={'emailAddress'}
                onChange={handleChange}
                required
                onBlur={handleBlur}

                hasError={touched['emailAddress'] && Boolean(errors['emailAddress'])}
                helperText={getHelperText('emailAddress', touched, errors, intl)}
              />
              <AMPInput
                value={values['password'] as string}
                type={'password'}
                label={'password'}
                input={'password'}
                onChange={handleChange}
                required
                onBlur={handleBlur}
                hasError={touched['password'] && Boolean(errors['password'])}
                helperText={getHelperText('password', touched, errors, intl)}
              />
              <AMPInput
                value={values['confirmPassword'] as string}
                type={'confirmPassword'}
                label={'confirmPassword'}
                input={'confirmPassword'}
                onChange={handleChange}
                required
                onBlur={handleBlur}
                hasError={touched['confirmPassword'] && Boolean(errors['confirmPassword'])}
                helperText={getHelperText('confirmPassword', touched, errors, intl)}
              />
              <AMPFormSelect
                data={processedLanguages() ?? []}
                value={values['language']}
                label={'language'}
                input={'language'}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="parkingAddress" />
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  md={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={processedCountries}
                    value={values['country'] as string}
                    label={'country'}
                    input={'country'}
                    required
                    onChange={handleChangeCountry(handleChange, setFieldValue)}
                    onBlur={handleBlur}
                    hasError={touched['country'] && Boolean(errors['country'])}
                    helperText={getHelperText('country', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  md={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={processedRegions}
                    value={values['region'] as string}
                    label={'region'}
                    input={'region'}
                    disabled={!activeCountry.length}
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['region'] && Boolean(errors['region'])}
                    helperText={getHelperText('region', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  md={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    type='text'
                    disabled={Boolean(!activeCountry)}
                    value={values['city']}
                    label={'city'}
                    input={'city'}
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                    hasError={touched['city'] && Boolean(errors['city'])}
                    helperText={getHelperText('city', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  md={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values['street'] as string}
                    type={'street'}
                    label={'street'}
                    input={'street'}
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                    hasError={touched['street'] && Boolean(errors['street'])}
                    helperText={getHelperText('street', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  md={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values['houseNumber'] as string}
                    type={'houseNumber'}
                    label={'houseNumber'}
                    input={'houseNumber'}
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                    hasError={touched['houseNumber'] && Boolean(errors['houseNumber'])}
                    helperText={getHelperText('houseNumber', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  md={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values['zipCode'] as string}
                    type={'zipCode'}
                    label={'zipCode'}
                    input={'zipCode'}
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                    hasError={touched['zipCode'] && Boolean(errors['zipCode'])}
                    helperText={getHelperText('zipCode', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  md={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values['parkingLot'] as string}
                    type={'text'}
                    label={'parkingLot'}
                    input={'parkingLot'}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={actionsWrapperSx}>
              <FormControlLabel
                label={<TermsPrivacyAgreementLabel />}
                control={
                  <Checkbox
                    color="secondary"
                    name="agreements"
                    value={values['agreements']}
                    onChange={handleChange}
                    required
                  />
                }
              />
              <SubmitButton
                text="signUp"
                isSubmitting={isSubmitting || !values['agreements']}
                rounding
                color="brand"
              />
            </Box>
          </Box>
        </Form>
      )
      }

    </Formik>
  );
}
