import { Box, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { DeleteTariff } from "src/components/tariffs/DeleteTariff";
import { TariffStatusButton } from "src/components/tariffs/TariffStatusButton";
import {
  actionsWrapperSx,
  tariffPageHeaderWrapperSx,
} from "./TariffPageHeader.sx";
import { TariffPageHeaderProps } from "./TariffPageHeader.types";
import { LanguageEnum } from "../../../enums";
import { Can } from "src/permissions/Can";

export const TariffPageHeader: FC<TariffPageHeaderProps> = ({
  data,
  language,
}) => {
  const [pageTitle, setPageTitle] = useState<string>("");
  useEffect(() => {
    if (data.name !== "undefined undefined" && data.name !== "undefined") {
      // TODO: fix temporary condition
      const titleMap = {
        [LanguageEnum.ENGLISH]: data.name,
        [LanguageEnum.FRENCH]: data.nameFrench,
        [LanguageEnum.DEUTSCH]: data.nameDeutsch,
      };
      setPageTitle(titleMap[language ?? LanguageEnum.ENGLISH]);
    }
  }, [data]);

  return (
    <Box sx={tariffPageHeaderWrapperSx}>
      <Typography variant="h2">{pageTitle}</Typography>
      <Can I="manage" a="tariff">
        <Box sx={actionsWrapperSx}>
          <TariffStatusButton tariff={data} />
          <DeleteTariff row={data} />
        </Box>
      </Can>
    </Box>
  );
};
