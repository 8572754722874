export enum StatusEnum {
  INSTALLATION = "INSTALLATION",
  OPERATION = "OPERATION",
  REJECTED = "REJECTED",
  VALIDATION = "VALIDATION",
  WAIT_FOR_SIGNATURE = "WAIT_FOR_SIGNATURE"
}

export enum RoleEnum {
  BUSINESS = "BUSINESS",
  DRIVER = "DRIVER",
  SUPER_ADMIN = "SUPER_ADMIN",
  CLIENT_MANAGER = "CLIENT_MANAGER",
  SALES_MANAGER = "SALES_MANAGER",
  TECHNICAL_MANAGER = "TECHNICAL_MANAGER"
}
