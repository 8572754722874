import { AddCircle } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { chargersApi } from "src/api";
import AMPModal from "src/components/common/AMPModal";
import { ScheduleForm } from "src/components/forms/ScheduleForm";
import { TimeIcon } from "src/components/icons/TimeIcon";
import { Can } from "src/permissions/Can";
import { ChargerScheduleCreateWrapper } from "../ChargerScheduleCreateWrapper";
import { ScheduleList } from "../ScheduleList";
import { actionButtonWrapperSx, buttonSx, emptyTitleWrapperSx, emptyWrapperSx, titleSx, wrapperSx } from "./ChargerSchedule.sx";
import { ChargerScheduleType } from "./ChargerSchedule.types";

export const ChargerSchedule: FC<ChargerScheduleType> = ({data}) => {
  const [getSchedule, schedule] = chargersApi.useLazyGetScheduleQuery()
  const intl = useIntl()

  const [open, setOpen] = useState<boolean>(false)
  const dialogTitle = intl.formatMessage({id: 'addSchedule'})

  const handleGetSchedule = async () => {
    await getSchedule({businessId: data.business.id, chargerId: data.id})
  }

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  useEffect(() => {
    handleGetSchedule()
  }, [])
  return (
    <Box
      sx={wrapperSx}
    >
      <Box sx={titleSx}>
        <Typography variant="subtitle1">
          <FormattedMessage id="chargingSchedule"/>
        </Typography>
      </Box>
      {schedule.data?.length
        ? (
          <ScheduleList
            data={schedule.data}
            businessId={data.business.id}
            chargerId={data.id}
          />
        )
        : (
          <Box sx={emptyWrapperSx}>
            <Box sx={emptyTitleWrapperSx}>
              <Typography variant="h5">
                <FormattedMessage id="noScheduleYet"/>
              </Typography>
              <TimeIcon />
            </Box>
            <Button
              sx={buttonSx}
              onClick={handleOpenModal}
            >
              <FormattedMessage id="addSchedule"/>
            </Button>
          </Box>
        )
      }
      <Can
        I="manage"
        a="schedule"
      >
        <Box sx={actionButtonWrapperSx}>
          <Button onClick={handleOpenModal}>
            <AddCircle />
            <FormattedMessage id="addSchedule" />
          </Button>
        </Box>
      </Can>
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <ChargerScheduleCreateWrapper
          handleClose={handleCloseModal}
          businessId={data.business.id}
          chargerId={data.id}
        />
      </AMPModal>
    </Box>
  )
}
