import { Box
} from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { locationsApi } from "src/api/locations";
import { useAppSelector } from "src/app/hooks";
import { store } from "src/app/store";
import AMPModal from "src/components/common/AMPModal";
import { PageHeader } from "src/components/common/PageHeader";
import { LocationCreateFormWrapper } from "src/components/locations/LocationCreateFormWrapper";
import { LocationStatistic } from "src/components/locations/LocationStatistic";
import { LocationTable } from "src/components/locations/LocationTable";
import { LocationSortFieldEnum } from "src/enums/locations";
import ability from "src/permissions/ability";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { DataType, ListParamsType, ReactLocationStateType } from "src/types";
import { LocationSearchRequestData } from "src/types/locations";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type LocationMutationTrigger = ReturnType<typeof locationsApi.useLazyGetLocationsQuery>[0];

export const handleGetLocations = (trigger: LocationMutationTrigger, businessId?: number) =>
  (async (tableData: LocationSearchRequestData, params: ListParamsType) => {
    store.dispatch(preloaderChangeStatus(true))

    const newData: DataType = {
      ...tableData,
      businessId: tableData.businessId ? tableData.businessId : businessId
    }

    Object.keys(tableData).map((item: string) => {
      !newData[item] && delete newData[item]
    })

    const data: LocationSearchRequestData = {
      ...newData,
    }

    try {
      const response = await trigger({ data, params }).unwrap()
      store.dispatch(preloaderChangeStatus(false))

      return response
    } catch (error) {
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    } finally {
      store.dispatch(preloaderChangeStatus(false));
    }
  });

export const Locations: FC = () => {
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false)
  const dialogTitle = intl.formatMessage({ id: "createNewLocation" })
  const { authInfo } = useAppSelector((state) => state.common);
  const [trigger, response] = locationsApi.useLazyGetLocationsQuery()

  const readLocations = ability.can('read', 'locations')
  const canCreateDeleteLocation = ability.can('manage', 'location')
  
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate()

  const [tableParams, setTableParams] = useState<ListParamsType>({
    page: 0,
    size: 100
  })
  const [filterParams, setFilterParams] = useState<LocationSearchRequestData>({
    businessId: 0,
    city: '',
    country: '',
    keyword: '',
    sortAsc: true,
    sortField: LocationSortFieldEnum.ID,
  })
  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleChange = (value: LocationSearchRequestData) => {
    setFilterParams(value)
    setTableParams({
      page: 0,
      size: tableParams.size
    })
    setSearchParams({})
    Object.keys(authInfo) && callHandler(value, {
      page: 0,
      size: tableParams.size
    })
  }

  const handleChangePage = (page: number) => {
    Object.keys(authInfo) && callHandler(filterParams, {
      page,
      size: tableParams.size
    })
    setTableParams({
      page,
      size: tableParams.size
    })
    setSearchParams({
      page: String(page)
    })
  }

  const handleChangeRowsNumber = (e: ChangeEvent<HTMLInputElement>) => {
    Object.keys(authInfo) && callHandler(filterParams, {
      page: 0,
      size: +e.target.value || 100
    })
    setTableParams({
      page: 0,
      size: +e.target.value || 100
    })
  }

  const callHandler = handleGetLocations(trigger, authInfo.business && +authInfo.business.id)

  useEffect(() => {
    if(!readLocations){
      return navigate(`/404`);
    }
    const currentState = location.state as ReactLocationStateType | undefined;
    const currentSearch = location.search
    if(currentState){
      setSearchParams({
        page: currentState?.page ?? currentSearch?.replace(/(\D+)(\d+)$/, "$2") ?? String(tableParams.page)
      })
      setTableParams({
        ...tableParams,
        page: +currentState?.page ?? +currentSearch?.replace(/(\D+)(\d+)$/, "$2") ?? tableParams.page
      })
      callHandler(filterParams, {
        ...tableParams,
        page: +currentState.page
      })
      return
    }
    Object.keys(authInfo) && callHandler(filterParams, tableParams)
  }, []);
  
  return (
    <Box>
      <PageHeader
        title={intl.formatMessage({ id: 'locations' })}
        onClick={handleOpenModal}
        buttonText="addLocation"
        onlyTitle={!canCreateDeleteLocation}
      />
      {response.data && (
        <>
          <LocationStatistic
            filterParams={filterParams}
          />
          <LocationTable
            data={response.data}
            size={tableParams.size}
            pageNumber={tableParams.page}
            handleUpdateTableData={handleChange}
            handleChangePage={handleChangePage}
            handleChangeRowsNumber={handleChangeRowsNumber}
          />
        </>
      )}
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <LocationCreateFormWrapper
          handleClose={handleCloseModal}
          authInfo={authInfo}
        />
      </AMPModal>
    </Box>
  )
};
