import { alpha, SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const wrapperSx: SxProps<Theme> = {
  borderRadius: theme.spacing(4/8),
  background: theme.palette.background.default,
  overflow: 'hidden',
  padding: theme.spacing(24/8),
  m: 0,
  width: "100%",
  [theme.breakpoints.down('lg')]: {
    pl: 0
  }
}
export const imageWrapperSx: SxProps<Theme> = {
  maxWidth: theme.spacing(215/8),
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  '& img':{
    width: '100%'
  }
}
export const titleSx: SxProps<Theme> ={
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(16/8)
}
export const rowItemSx: SxProps<Theme> = {
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(8/8),
  [theme.breakpoints.down('lg')]: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
}
export const itemSx: SxProps<Theme> = {
  '&.MuiGrid-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(16/8)
  }
}
export const buttonImageWrapperSx: SxProps<Theme> = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  padding: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  background: alpha(theme.palette.bg.dark, 0.2), 
  transition: '0.3s ease',
  '&:hover': {
    opacity: 1
  }
}
export const imageButtonSx: SxProps<Theme> = {
  '&:hover': {
    background: theme.palette.neutral[400]
  }
}
