import { Stop } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FC } from "react";
import { ocppApi } from "src/api";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { buttonWrapperSx } from "./DeactivateButton.sx";
import { DeactivateButtonType } from "./DeactivateButton.types";

type StopChargingMutationTrigger = ReturnType<typeof ocppApi.useStopChargingMutation>[0]

const handleStopCharging = (trigger: StopChargingMutationTrigger, chargerId: number, connectorId: number, businessId: number) => async () => {
  try{
    await trigger({ chargerId, connectorId, businessId }).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  }
}

export const DeactivateButton: FC<DeactivateButtonType> = ({chargerId, connectorId, businessId}) => {
  
  const [stopCharging, message] = ocppApi.useStopChargingMutation()

  return (
    <IconButton
      sx={buttonWrapperSx}
      onClick={handleStopCharging(stopCharging, chargerId, connectorId, businessId)}
    >
      <Stop fontSize="large"/>
    </IconButton>
  )
}
