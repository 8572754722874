import { Button, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./CancelButton.styles";
import { CancelButtonType } from "./CancelButton.types";

export const CancelButton: FC<CancelButtonType> = ({ text, handler, rounding, color = "primary" }) => {
  const classes = useStyles()
  return (
    <Button
      variant="outlined"
      className={classes.button}
      onClick={handler}
      color={color}
      sx={{ borderRadius: rounding ? 50 : 2 }}
    >
      <Typography
        variant="button"
        color="neutral.700"
      >
        <FormattedMessage id={text} />
      </Typography>
    </Button>
  );
}
