import { Box, Button, Typography } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { btnSx, wrapperSx } from "./TermsPrivacyAgreementLabel.sx";
import { useAppSelector } from "src/app/hooks";
import { LanguageShortEnum } from "../../../enums";
import AMPModal from "../AMPModal";
import { Policy } from "../../termsAndPolicy/Policy";
import { Terms } from "../../termsAndPolicy/Terms";
import { PolicyFrench } from "../../termsAndPolicy/PolicyFrench";
import { TermsFrench } from "../../termsAndPolicy/TermsFrench";
import { TermsGerman } from "../../termsAndPolicy/TermsGerman";
import { PolicyDeutsch } from "../../termsAndPolicy/PolicyDeutsch";

export const TermsPrivacyAgreementLabel: FC = () => {
  const intl = useIntl();
  const { lang } = useAppSelector(state => state.common)
  const [showTerms, setShowTerms] = useState(false)
  const [showPolicy, setShowPolicy] = useState(false)
  
  const handleShowTerms = (status: boolean) => () => {
    setShowTerms(status)
  }
  
  const handleShowPolicy = (status: boolean) => () => {
    setShowPolicy(status)
  }
  
  const policyMap:Record<string, ReactNode> = {
    [LanguageShortEnum.EN]: <Policy/>,
    [LanguageShortEnum.FR]: <PolicyFrench/>,
    [LanguageShortEnum.DE]: <PolicyDeutsch/>,
  }
  
  const termsMap:Record<string, ReactNode> = {
    [LanguageShortEnum.EN]: <Terms/>,
    [LanguageShortEnum.FR]: <TermsFrench/>,
    [LanguageShortEnum.DE]: <TermsGerman/>,
  }
  
  return (
    <Box sx={wrapperSx}>
      <Typography
        variant="subtitle2"
      >
        <FormattedMessage id={'agreeToThe'} />
      </Typography>
      <Button
        onClick={handleShowTerms(true)}
        style={btnSx}
      >
        <Typography
          variant="subtitle2"
          color="primary"
        >
          <FormattedMessage id={'termsOfService'} />
        </Typography>
      </Button>
      <Typography
        variant="subtitle2"
      >
        <FormattedMessage id={'and'} />
      </Typography>
      <Button
        onClick={handleShowPolicy(true)}
        style={btnSx}
      >
        <Typography
          variant="subtitle2"
          color="primary"
        >
          <FormattedMessage id={'privacyPolicy'} />
        </Typography>
      </Button>
      <AMPModal
        handleClose={handleShowPolicy(false)}
        open={showPolicy}
        title={intl.formatMessage({id: 'privacyPolicy'})}
        type="md"
        fullWidth
      >
        {policyMap[lang]}
      </AMPModal>
      <AMPModal
        handleClose={handleShowTerms(false)}
        open={showTerms}
        title={intl.formatMessage({id: 'termsOfService'})}
        type="md"
        fullWidth
      >
        {termsMap[lang]}
      </AMPModal>
    </Box>
  )
}
