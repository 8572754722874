import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const wrapperSx: SxProps<Theme> = {
  paddingLeft: theme.spacing(40/8)
}

export const stepperSx: SxProps<Theme> = {
  marginTop: theme.spacing(50/8),
  '& .MuiStepConnector-root': {
    margin: '-5px 16px',
    zIndex: -1,
    '& span': {
      borderLeftWidth: theme.spacing(2/8),
    },
    '&.Mui-completed span, &.Mui-active span':{
      borderColor: theme.palette.brand.main,
    }
  }
}
export const logoWrapperSx: SxProps<Theme> = {
  marginBottom: theme.spacing(40/8)
}
export const welcomeTitleSx: SxProps<Theme> = {
  lineHeight: '1.4'
}
export const welcomeSubtitleSx: SxProps<Theme> = {}
export const stepSx: SxProps<Theme> = {}
export const stepLabelSx: SxProps<Theme> =  {
  padding: 0,
  lineHeight: '1'
}
export const welcomeContentSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
export const langSx: SxProps<Theme> = {
  position: 'absolute',
  top: '5%',
  right: '5%'
}
export const modalButtonSx: SxProps<Theme> = {
  '&.MuiIconButton-root': {
    position: 'absolute',
  },
  bottom: '5%',
  right: '5%'
}
export const modalLogOutButtonSx: SxProps<Theme> = {
  '&.MuiIconButton-root': {
    position: 'absolute',
  },
  bottom: '5%',
  left: '5%'
}
