import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { locationsApi, usersApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import AMPModal from "src/components/common/AMPModal";
import { ConformationModal } from "src/components/common/ConformationModal";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch, BaseUserInfo } from "src/types";
import { NotifyError } from "src/utils/notification";

type DeleteUserMutationTrigger = ReturnType<typeof usersApi.useDeleteUserMutation>[0]

const handleDeleteUser = (trigger: DeleteUserMutationTrigger, dispatch: AppDispatch, userId: number, businessId: number, handleClose: () => void, errorMessage: string) => async () => {
  try{
    dispatch(preloaderChangeStatus(true))
    await trigger({ userId, businessId }).unwrap().finally(() => {
      dispatch(preloaderChangeStatus(false))
    })
  } catch (error: any) {
    error.status === 400 && NotifyError(errorMessage)
  } finally {
    dispatch(preloaderChangeStatus(false));
    handleClose()
  }
}


export const DeleteUser: FC<{row: BaseUserInfo}> = ({row}) => {
  const [trigger, response] = usersApi.useDeleteUserMutation()
  const dispatch = useAppDispatch()
  
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false)
  const dialogTitle = intl.formatMessage({ id: "deleteUser" })

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }
  return (
    <>
      <IconButton 
        onClick={handleOpenModal}
      >
        <Delete />
      </IconButton>
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <ConformationModal
          handleClose={handleCloseModal}
          handleSubmit={handleDeleteUser(trigger, dispatch, +row.id, +row.business.id, handleCloseModal, intl.formatMessage({id: 'cannotDeleteUser'}))}
          text={'deletionUser'}
          caption={`${row.firstName} ${row.lastName}`}
        />
      </AMPModal>
    </>
  )
}
