import { FC, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { businessesApi } from "src/api";
import { Can } from "src/permissions/Can";
import { useAppDispatch } from "src/app/hooks";
import { Signature } from "src/components/common/Signature";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { EditIcon } from "../../icons/EditIcon";
import AMPModal from "../../common/AMPModal";
import { BusinessSignatureType } from "./BusinessSignature.types";
import { canvasWrapperSx, titleSx, editBtnSx, rootSx } from "./BusinessSignature.sx";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type BusinessSignatureTrigger = ReturnType<typeof businessesApi.useSetBusinessSignatureMutation>[0]

const handleSubmitSignature = (
  trigger: BusinessSignatureTrigger,
  dispatch: AppDispatch,
  businessId: number,
  handleClose: () => void
) => {
  return async (file: File) => {
    try {
      dispatch(preloaderChangeStatus(true))
      const formData = new FormData()
      formData.append('file', file)
      formData.set('businessId', String(businessId))
      await trigger({
        businessId,
        formData
      }).unwrap()
    } catch (error) {
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    } finally {
      dispatch(preloaderChangeStatus(false));
      handleClose()
    }
  }
}

export const BusinessSignature: FC<BusinessSignatureType> = ({ data }) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [showSignatureModal, setShowSignatureModal] = useState(false)
  const businessSignature = businessesApi.useGetBusinessSignatureQuery(data.id)
  const [setBusinessSignature] = businessesApi.useSetBusinessSignatureMutation()
  
  const handleSignatureModal = (status: boolean) => () => {
    setShowSignatureModal(status)
  }
  
  return (
    <Box sx={rootSx}>
      <Box sx={titleSx}>
        <Typography variant="subtitle1">
          <FormattedMessage id="signature" />
        </Typography>
      </Box>
      <Can
        I="manage"
        a="contract"
      >
        <IconButton
          sx={editBtnSx}
          onClick={handleSignatureModal(true)}
        >
          <EditIcon/>
        </IconButton>
      </Can>
      <Box sx={canvasWrapperSx}>
        {businessSignature?.data && (
          <img src={businessSignature.data} />
        )}
      </Box>
      <AMPModal
        handleClose={handleSignatureModal(false)}
        open={showSignatureModal}
        title={intl.formatMessage({ id: "putSignature" })}
        type="md"
        fullWidth
        withoutPaddings
      >
        <Signature
          handleSubmit={
            handleSubmitSignature(
              setBusinessSignature,
              dispatch,
              data.id,
              handleSignatureModal(false)
            )}
          modal
          handleClose={handleSignatureModal(false)}
        />
      </AMPModal>
    </Box>
  )
}
