import { FC } from "react";
import { useAppSelector } from "src/app/hooks";
import { RevenueInvoices } from "src/components/invoices/RevenueInvoices";
import { UserInvoices } from "src/components/invoices/UserInvoices";
import { RoleEnum } from "src/enums";

export const Invoices: FC = () => {

  const { authInfo } = useAppSelector((state) => state.common);

  return (
    <>
      {authInfo.roles[0].role === RoleEnum.SUPER_ADMIN
        ? <RevenueInvoices />
        : <UserInvoices />}
    </>
  )
};
