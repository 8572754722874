import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { ContractsIcon } from "../../../icons/ToolbarIcons";
import theme from "../../../../theme";
import { FormattedMessage } from "react-intl";
import { ArrowForwardIos } from "@mui/icons-material";
import { linkSx, linkWrapperSx } from "./ContractTitle.sx";

export const ContractTitle:FC<{canReadContract: boolean}> = ({canReadContract}) => {
  return (
    <Box sx={linkSx}>
      <Box sx={linkWrapperSx}>
        <ContractsIcon sx={{fill: theme.palette.success.main}}/>
        <Typography variant="subtitle1">
          <FormattedMessage id="contract" />
        </Typography>
      </Box>
      {canReadContract && <ArrowForwardIos sx={{fill: theme.palette.text.primary}}/>}
    </Box>
  )
}
