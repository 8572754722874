import { Box, Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { UserCell } from "src/components/users/UserCell";
import { wrapperSx, titleSx, itemSx, rowItemSx } from "./ContractDetails.sx";
import { ContractDetailsType } from "./ContractDetails.types";

export const ContractDetails: FC<ContractDetailsType> = ({ data }) => {

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={wrapperSx}
      >
        <Grid
          item
          xs={12}
        >
          <Box sx={titleSx}>
            <Typography variant="subtitle1">
              <FormattedMessage id="contractDetails" />
            </Typography>
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={12}
          rowSpacing={2}
          columnSpacing={2}
        >
          <Grid
            item
            xs={12}
            lg={7}
            sx={itemSx}
          >
            <Stack sx={rowItemSx}>
              <Typography
                variant="caption"
              >
                <FormattedMessage id="userName" />
              </Typography>
              <Typography
                variant="subtitle2"
              >
                {data.user && <UserCell data={data.user} />}
              </Typography>
            </Stack>
            <Stack sx={rowItemSx}>
              <Typography
                variant="caption"
              >
                <FormattedMessage id="phoneNumber" />
              </Typography>
              <Typography
                variant="subtitle2"
              >
                {data.user && data.user.phoneNumber}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            sx={itemSx}
          >
            <Stack sx={rowItemSx}>
              <Typography
                variant="caption"
              >
                <FormattedMessage id="location" />
              </Typography>
              <Typography
                variant="subtitle2"
              >
                {data.user?.parkingAddress && `${data.user.parkingAddress.country}, ${data.user.parkingAddress.city}, ${data.user.parkingAddress.street}, ${data.user.parkingAddress.houseNumber}`}
              </Typography>
            </Stack>
            <Stack sx={rowItemSx}>
              <Typography
                variant="caption"
              >
                <FormattedMessage id="parkingLot" />
              </Typography>
              <Typography
                variant="subtitle2"
              >
                {(data.user && data.user.parkingAddress.parkingPlace) ?? '-'}
              </Typography>
            </Stack>
            <Stack sx={rowItemSx}>
              <Typography
                variant="caption"
              >
                <FormattedMessage id="signatureDate" />
              </Typography>
              <Typography
                variant="subtitle2"
              >
                {data.created && (new Date(data.created).toLocaleDateString("fr-CH"))}
              </Typography>
            </Stack>
            {data.operationalAt && 
            <Stack sx={rowItemSx}>
              <Typography
                variant="caption"
              >
                <FormattedMessage id="operationChange" />
              </Typography>
              <Typography
                variant="subtitle2"
              >
                {new Date(data.operationalAt).toLocaleDateString("fr-CH")}
              </Typography>
            </Stack>
            }
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
