import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { axiosBaseQuery } from "src/axiosBaseQuery"
import {
  CreateLocationCostRequest,
  DefaultResponse,
  DeleteLocationCostRequest,
  UpdateLocationCostRequest
} from "src/types"
import {
  AddUserToLocationType,
  CreateLocationRequest,
  DeleteLocationFileRequest,
  DeleteUserFromLocationType,
  DownloadFileRequest,
  LocationDTO,
  LocationFileDTO,
  LocationFileRequest,
  LocationImageDTO,
  LocationSearchRequest,
  PageBaseLocationInfo,
  UpdateLocationRequest
} from "src/types/locations"
import { getBaseURL } from "src/utils/helper"

export const locationsApi = createApi({
  reducerPath: 'locationsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL()),
  }),
  tagTypes: ['Locations', 'Location'],
  endpoints: (build) => {
    return {
      addLocation: build.mutation<LocationDTO, CreateLocationRequest>({
        query: (data) => ({ url: '/locations', method: 'post', data }),
        invalidatesTags: ['Locations']
      }),
      getLocations: build.query<PageBaseLocationInfo, LocationSearchRequest>({
        query: ({ data, params }) => ({ url: '/locations/search', method: 'post', data, params }),
        providesTags: ['Locations']
      }),
      getLocationById: build.query<LocationDTO, { businessId: number, locationId: number | string }>({
        query: (params) => ({ url: `/locations/${params.locationId}`, method: 'get', params }),
        providesTags: ['Location']
      }),
      addFileToLocation: build.mutation<LocationFileDTO, LocationFileRequest>({
        query: ({ locationId, formData }) => ({
          url: `/locations/${locationId}/file`,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          method: 'post',
          data: formData,
        }),
        invalidatesTags: ['Location']
      }),
      updateLocationCost: build.mutation<LocationDTO, UpdateLocationCostRequest>({
        query: ({ data, params }) => ({ url: `/locations/${params.locationId}/cost/${params.itemId}`, method: 'put', data }),
        invalidatesTags: ['Location']
      }),
      createLocationCost: build.mutation<LocationDTO, CreateLocationCostRequest>({
        query: ({ data, locationId }) => ({ url: `/locations/${locationId}/cost`, method: 'post', data }),
        invalidatesTags: ['Location']
      }),
      deleteLocationCost: build.mutation<LocationDTO, DeleteLocationCostRequest>({
        query: ({ businessId, params }) => ({
          url: `/locations/${params.locationId}/cost/${params.itemId}`,
          method: 'delete',
          params: { businessId }
        }),
        invalidatesTags: ['Location']
      }),
      addLocationImage: build.mutation<LocationImageDTO, LocationFileRequest>({
        query: ({ locationId, formData }) => ({
          url: `/locations/${locationId}/image`,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          method: 'post',
          data: formData,
        }),
        invalidatesTags: ['Location']
      }),
      downloadLocationFile: build.query<string, DownloadFileRequest>({
        query: ({ params, locationId }) => ({
          url: `/locations/${locationId}/file`,
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob',
          method: 'get',
          params,
        }),
        transformResponse(baseQueryData) {
          if (baseQueryData instanceof Blob) {
            return URL.createObjectURL(baseQueryData)
          }
          return ''
        }
      }),
      deleteLocationFile: build.mutation<number, DeleteLocationFileRequest>({
        query: ({ locationId, params }) => ({
          url: `/locations/${locationId}/file`,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          method: 'delete',
          params,
        }),
        invalidatesTags: ['Location']
      }),
      addUserToLocation: build.mutation<LocationDTO, AddUserToLocationType>({
        query: ({ locationId, ...params }) => ({ url: `/locations/${locationId}/users`, method: 'post', params }),
        invalidatesTags: ['Location']
      }),
      deleteUserFromLocation: build.mutation<LocationDTO, DeleteUserFromLocationType>({
        query: ({ locationId, ...params }) => ({ url: `/locations/${locationId}/users`, method: 'delete', params }),
        invalidatesTags: ['Location']
      }),
      updateLocation: build.mutation<LocationDTO, UpdateLocationRequest>({
        query: ({ locationId, data }) => ({ url: `/locations/${locationId}`, method: 'put', data }),
        invalidatesTags: ['Location']
      }),
      deleteLocation: build.mutation<DefaultResponse, { businessId: number, locationId: number | string }>({
        query: ({ locationId, businessId }) => ({ url: `/locations/${locationId}`, method: 'delete', params: { businessId } }),
        invalidatesTags: ['Locations']
      }),
    }
  },
})
