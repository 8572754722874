import { FC } from "react";
import { Avatar, Box, Button, ButtonProps, Divider, Stack, Typography } from "@mui/material";
import {
  avatarBoxSx,
  avatarSx,
  btnWrapperSx,
  cardSx,
  cardTextSx,
  colSx,
  dateSx,
  detailsBoxSx,
  newMarkSx,
  readBoxSx,
  roleTextSx,
  textBoxSx,
  titleTextSx,
  unreadSx,
  visibilitySx
} from "./NotificationCard.sx";
import { FormattedMessage, useIntl } from "react-intl";
import dayjs from "dayjs";
import { Link, useLocation } from "react-router-dom";
import { AppRouteEnum, AppRouteSpace, isErrorObject } from "../../../utils/helper";
import { notificationTypeTranslation, notificationTypeTranslationMessage } from "../../../utils/consts";
import { NotificationDTO, NotificationTypeEnum } from "../../../types";
import { Visibility } from "@mui/icons-material";
import { notificationApi } from "../../../api";
import { store } from "../../../app/store";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { NotifyError } from "../../../utils/notification";
import { useAppSelector } from "../../../app/hooks";
import { NotificationCardProps } from "./NotificationCard.types";

type MarkAsUnreadType = ReturnType<typeof notificationApi.useMarkNotificationAsUnreadMutation>[0];

export const handleMarkAsUnread = async (
  trigger: MarkAsUnreadType,
  notificationId: number,
  businessId: number,
  notificationType: NotificationTypeEnum
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    await trigger({
      notificationId,
      data: {
        businessId,
        notificationType: notificationType
      }
    }).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data?.message ?? error.data?.error)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
}

export const NotificationCard:FC<NotificationCardProps> = ({
  id,
  read,
  user,
  created,
  type,
  message,
  readAt,
  readByManager,
  businessId,
  page,
  location
}) => {
  const intl = useIntl()
  const [setAsUnread] = notificationApi.useMarkNotificationAsUnreadMutation()
  const { authInfo } = useAppSelector((state) => state.common);
  const userName = user ? `${user?.firstName} ${user?.lastName}` : (!user && location) ? location?.name : ''
  
  const handleUnreadClick: ButtonProps['onClick'] = (e) => {
    e.preventDefault();
    handleMarkAsUnread(
      setAsUnread,
      Number(id),
      authInfo.business.id,
      type
    )
  }
  
  return (
    <Box>
      <Link
        to={{
          pathname: AppRouteSpace([AppRouteEnum.app, AppRouteEnum.notifications, String(id)]),
          search: `?type=${type}`,
        }}
        state={{
          page: page
        }}
      >
        <Box sx={cardSx(read)}>
          <Box sx={avatarBoxSx}>
            {!read && <Box sx={newMarkSx}/>}
            <Avatar
              src={user?.avatar?.url}
              sx={avatarSx}
            />
          </Box>
          <Stack sx={textBoxSx}>
            <Box sx={colSx}>
              <Typography
                color="black"
                variant="h4"
                component="span"
              >
                {userName}
              </Typography>
              <Typography
                sx={roleTextSx}
                component="span"
              >
                {notificationTypeTranslation(intl)[type]}
              </Typography>
              {
                (type === NotificationTypeEnum.CHANGE_PLAN_REQUEST ||
               type === NotificationTypeEnum.USER_FEEDBACK) &&
              <Typography
                component="span"
                sx={cardTextSx}
              >
                {notificationTypeTranslationMessage(intl, {
                  id,
                  read,
                  user,
                  created,
                  type,
                  message,
                  readAt,
                  readByManager,
                  businessId,
                  location
                })[type]}
              </Typography>
              }
              {
                type !== NotificationTypeEnum.CHANGE_PLAN_REQUEST &&
              <>
                <Typography
                  sx={titleTextSx}
                  component="span"
                >
                  <FormattedMessage id="address" />
                </Typography>
                <Typography
                  component="span"
                  sx={cardTextSx}
                >
                  {user && `${user?.parkingAddress?.country}, ${user?.parkingAddress?.city}, ${user?.parkingAddress?.street}, ${user?.parkingAddress?.houseNumber}`}
                  {!user && location && `${location?.address?.country}, ${location?.address?.city}, ${location?.address?.street}, ${location?.address?.houseNumber}`}
                </Typography>
              </>
              }
            </Box>
            <Stack sx={detailsBoxSx}>
              <Box>
                <Typography
                  sx={dateSx}
                >
                  {dayjs(created).format('DD.MM.YYYY hh:mm')}
                </Typography>
                {readByManager && readAt && read &&
                <Box sx={readBoxSx}>
                  <Visibility sx={visibilitySx}/>
                  <Typography
                    sx={dateSx}
                  >
                    <FormattedMessage
                      id="readByOn"
                      values={{
                        name: `${readByManager?.firstName} ${readByManager?.lastName}`,
                        date: dayjs(readAt).format('DD.MM.YYYY hh:mm')
                      }}
                    />
                  </Typography>
                </Box>
                }
              </Box>
              <Box sx={btnWrapperSx}>
                <Button
                  sx={unreadSx}
                  onClick={handleUnreadClick}
                >
                  <FormattedMessage id="markAsUnread" />
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Link>
      <Divider/>
    </Box>
  )
}
