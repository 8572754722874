import { FC, useCallback, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { Delete, HttpsOutlined } from "@mui/icons-material";
import { EditIcon } from "../../../icons/EditIcon";
import { useAppDispatch } from "../../../../app/hooks";
import { usersApi } from "../../../../api";
import { preloaderChangeStatus } from "../../../../stores/common/commonSlice";
import { UpdateRfidDTO } from "../../../../types";
import { RfidActionsProps } from "./RfidActions.types";
import { rfidActionsSx } from "./RfidActions.sx";
import AMPModal from "../../AMPModal";
import { useIntl } from "react-intl";
import { UpdateRfidForm } from "../../../forms/UpdateRfidForm";
import { BlockStatusEnum } from "../RfidTable.types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

export const RfidActions:FC<RfidActionsProps> = ({
  expiry,
  businessId,
  userId,
  ocppTagId,
  blocked,
  idTag
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [triggerUpdate] = usersApi.useUpdateRfidMutation();
  const [triggerDelete] = usersApi.useDeleteRfidMutation();
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const handleShowUpdateModal = (status: boolean) => {
    return () => {
      setShowUpdateModal(status)
    }
  }

  const handleRfidDelete = async () => {
    try {
      dispatch(preloaderChangeStatus(true));
      if (userId && businessId) {
        const params = {
          businessId
        }
        const path = {
          userId: userId,
          ocppTagId
        }
        await triggerDelete({ params, path }).unwrap()
      }
    } catch (error) {
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    } finally {
      dispatch(preloaderChangeStatus(false));
    }
  }

  const handleRfidUpdate = async (updatedObject: UpdateRfidDTO ) => {
    try {
      dispatch(preloaderChangeStatus(true));
      const body: UpdateRfidDTO = {
        blocked: updatedObject.blocked,
        expiry: updatedObject.expiry,
        idTag: updatedObject.idTag
      };
      if (userId && businessId) {
        const params = {
          businessId
        }
        const path = {
          userId: userId,
          ocppTagId
        }
        await triggerUpdate({body, params, path }).unwrap()
      }
    } catch (error) {
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    } finally {
      dispatch(preloaderChangeStatus(false));
    }
  }

  const handleBlockClick = useCallback(() => {
    handleRfidUpdate({
      blocked: !blocked,
      expiry,
      idTag
    })
  }, [handleRfidUpdate])

  const handleDeleteClick = useCallback(() => {
    handleRfidDelete()
  }, [handleRfidUpdate])

  return (
    <>
      <Stack sx={rfidActionsSx.root}>
        <IconButton
          sx={rfidActionsSx.button}
          onClick={handleBlockClick}
        >
          <HttpsOutlined sx={rfidActionsSx.icon} />
        </IconButton>
        <IconButton
          sx={rfidActionsSx.button}
          onClick={handleShowUpdateModal(true)}
        >
          <EditIcon/>
        </IconButton>
        <IconButton
          sx={rfidActionsSx.button}
          onClick={handleDeleteClick}
        >
          <Delete sx={rfidActionsSx.icon}/>
        </IconButton>
      </Stack>
      <AMPModal
        handleClose={handleShowUpdateModal(false)}
        open={showUpdateModal}
        title={intl.formatMessage({id: "addRfid"})}
        type="md"
        fullWidth
        withoutPaddings
      >
        <UpdateRfidForm
          handleClose={handleShowUpdateModal(false)}
          initialValues={{
            blocked: blocked ? BlockStatusEnum.blocked : BlockStatusEnum.accepted,
            expiry,
            idTag
          }}
          handleSubmit={handleRfidUpdate}
        />
      </AMPModal>
    </>
  )
}
