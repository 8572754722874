import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { policySx } from "./Policy.sx";

export const Policy: FC = () => {
  return (
    <Box>
      <Box sx={policySx.root}>
        <Typography variant="h2">
          Privacy Policy
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          Last updated: Oct 18, 2022 4:08 AM
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          We value your privacy very highly. Please read this Privacy Policy carefully before using the AMP IT SA Application (the &quot;Application&quot;) operated by AMP IT SA, a(n) Private Company Limited By Shares formed in Switzerland (&quot;us,&quot; &quot;we,&quot; &quot;our&quot;) as this Privacy Policy contains important information regarding your privacy and how we may use the information we collect about you.
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          Your access to and use of the Application is conditional upon your acceptance of and compliance with this Privacy Policy. This Privacy Policy applies to everyone, including, but not limited to: visitors, users, and others, who wish to access or use the Application.
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          By accessing or using the Application, you agree to be bound by this Privacy Policy. If you disagree with any part of the Privacy Policy, then you do not have our permission to access or use the Application.
        </Typography>
        <Typography
          variant="h3"
          sx={policySx.header}
        >
          What information we collect, how we use it, what happens if we don&apos;t have it, and the legal basis for processing this information
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          We collect any and all information that you enter on this Application. We collect the following information about you:
        </Typography>
        <Typography
          variant="h5"
          sx={policySx.header}
          fontWeight="800"
        >
          Identifying information - Name
        </Typography>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          Specific piece of information
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          Name
        </Typography>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          Legal basis for processing this information
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
            The user has provided consent to the processing of their information;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
            Processing is necessary to perform a contract with the user;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
            Processing is necessary in order to take steps that the user has requested prior to entering into a contract;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
            Processing is necessary for compliance with a legal obligation
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          How we use this information
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Enforcing our Terms of Service;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Performing services;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Providing customer service;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Providing financing;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Resolving disputes;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Verifying customer information.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          What happens if we don&apos;t have this information
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for enforcing our Terms of Service will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for performing services will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for providing customer service will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for providing financing will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for resolving disputes will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for verifying customer information will not be possible.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policySx.header}
          fontWeight="800"
        >
          Identifying information - Phone number
        </Typography>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          Specific piece of information
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          Phone number
        </Typography>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          Legal basis for processing this information
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              The user has provided consent to the processing of their information;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Processing is necessary to perform a contract with the user;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Processing is necessary in order to take steps that the user has requested prior to entering into a contract;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Processing is necessary for compliance with a legal obligation.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          How we use this information
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Enforcing our Terms of Service;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Performing services;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Providing customer service;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Providing financing;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Resolving disputes;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Verifying customer information.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          What happens if we don&apos;t have this information
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for enforcing our Terms of Service will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for performing services will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for providing customer service will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for providing financing will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for resolving disputes will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for verifying customer information will not be possible.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          fontWeight="800"
          sx={policySx.header}
        >
          Identifying information - Email address
        </Typography>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          Specific piece of information
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          Email address
        </Typography>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          Legal basis for processing this information
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              The user has provided consent to the processing of their information;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Processing is necessary in order to take steps that the user has requested prior to entering into a contract;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Processing is necessary for compliance with a legal obligation.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          How we use this information
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Performing services;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Providing customer service;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Providing financing;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Resolving disputes;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Verifying customer information.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          What happens if we don&apos;t have this information
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for performing services will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for providing customer service will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for providing financing will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for resolving disputes will not be possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Our use of your data for verifying customer information will not be possible.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policySx.header}
          fontWeight="800"
        >
          Identifying information - Physical Address
        </Typography>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          Specific piece of information
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          Physical Address
        </Typography>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          Legal basis for processing this information
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              The user has provided consent to the processing of their information;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Processing is necessary to perform a contract with the user;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Processing is necessary in order to take steps that the user has requested prior to entering into a contract;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Processing is necessary for compliance with a legal obligation.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policySx.header}
          fontWeight="800"
        >
          Identifying information - Photos
        </Typography>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          Specific piece of information
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          Photos
        </Typography>
        <Typography
          variant="h5"
          sx={policySx.header}
        >
          Legal basis for processing this information
        </Typography>
        <Typography
          variant="body2"
          sx={policySx.paragraph}
        >
          The user has provided consent to the processing of their information;
        </Typography>
        <Typography
          variant="body2"
          sx={policySx.paragraph}
        >
          It`s only for use in the application as an avatar.

        </Typography>
        <Typography
          variant="h3"
          sx={policySx.header}
        >
          With whom we share your personal information
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          We do not share your personal information with any third parties.
        </Typography>
        <Typography
          variant="h3"
          sx={policySx.header}
        >
          Children&apos;s privacy
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          This Application is intended for use by a general audience and does not offer services to children. Should a child whom we know to be under 18 send personal information to us, we will use that information only to respond to that child to inform him or her that they cannot use this Application.
        </Typography>
        <Typography
          variant="h3"
          sx={policySx.header}
        >
          Information retention
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          We retain all of the information that we collect untill user request a account deletion
        </Typography>
        <Typography
          variant="h3"
          sx={policySx.header}
        >
          The rights of European Union and/or the European Economic Area residents
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          Residents of the European Union and the European Economic Area have the following rights with regard to their personal information:
        </Typography>
        <ol>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              The right to access your information. You have the right to access any information that we may have collected on you. You can request copies of the information that we have on you in a structured, commonly used, machine-readable format.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Information transmission. You have the right to ask us to transmit your personal information that we have collected on you to another provider, where technically feasible.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              Rectification of information. You have the right to request that we amend any of the information that we have collected on you.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              You have a right to withdraw your consent to the processing of your data.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              You have a right to request that we restrict the processing of your data.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              You have a right to request that we delete all or some of the information that we have collected on you.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policySx.paragraph}
            >
              You have the right to lodge a complaint regarding our collection, sharing and processing of data with competent authorities in the proper jurisdiction.
            </Typography>
          </li>
        </ol>
        <Typography
          variant="h3"
          sx={policySx.header}
        >
          Exercising your rights
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          Residents of the European Union and the European Economic Area may exercise the rights specified above by submitting a consumer request to:
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          AMP IT SA
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          info@amp-it.ch
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          022 525 77 22
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          amp-it.com
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          Rue des Sablieres 1-3, 1242 Satigny, Switzerland
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          We will need to verify your identity prior to effectuating your request and may ask you to provide personal information to do so. We will respond to most consumer requests within 30 days of receipt. However, some requests may take longer. We will notify you in writing if we need more time to respond. We have the ability to deny your request(s) if certain exceptions in the law apply. If we do deny your request, we will provide you with the reasons for such denial.
        </Typography>
        <Typography
          variant="h3"
          sx={policySx.header}
        >
          Location of data processing
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          All data processing activities undertaken by us take place in Switzerland.
        </Typography>
        <Typography
          variant="h3"
          sx={policySx.header}
        >
          Questions
        </Typography>
        <Typography
          variant='body2'
          sx={policySx.paragraph}
        >
          If you have any questions about this Privacy Policy, please contact us at info@amp-it.ch.
        </Typography>
      </Box>
    </Box>
  )
}