import {
  BusinessRevenueStatistic,
  RevenueByCity,
  RevenueByLocation,
  RevenueStatisticByCurrency
} from "../../../types/reports";
import { RevenueType } from "../../../pages/RevenueReport/RevenueReports.types";
import { IntlShape } from "react-intl";
import { reportsApi } from "../../../api";
import { store } from "../../../app/store";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type RevenueReportQueryTrigger = ReturnType<typeof reportsApi.useLazyGetRevenueReportQuery>[0];
type BusinessRevenueReportQueryTrigger = ReturnType<typeof reportsApi.useLazyGetBusinessRevenueQuery>[0];

export const getRevenueData = async (
  getRevenueReports: RevenueReportQueryTrigger,
  businessId: number,
  dateFrom: number | undefined,
  dateTo: number | undefined
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    return await getRevenueReports({
      businessId,
      dateFrom,
      dateTo
    }).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

export const getBusinessRevenueData = (
  getRevenueReports: BusinessRevenueReportQueryTrigger,
  dateFrom: number | undefined,
  dateTo: number | undefined
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    return getRevenueReports({
      dateFrom,
      dateTo
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

type FieldsAllowed = Omit<RevenueStatisticByCurrency, 'currency' | 'totalInvoiceSize' | 'totalRevenueAmount'>;
type KeysAllowed = keyof FieldsAllowed;
type MappingCallback<T> = (value: T) => RevenueType;
type CallbacksAllowed = {
  [Key in KeysAllowed]: MappingCallback<FieldsAllowed[Key][number]>;
}

const getRevenueStr = (intl: IntlShape) => intl.formatMessage({id: 'revenue'})

const citiesMappingCallback = (currency: string, intl: IntlShape): MappingCallback<RevenueByCity> => (value): RevenueType => {
  return {
    name: value.city,
    [`${getRevenueStr(intl)} (${currency})`]: value.revenue / 100,
  }
}

const locationsMappingCallback = (currency: string, intl: IntlShape): MappingCallback<RevenueByLocation> => (value): RevenueType => {
  return {
    name: value.locationName,
    [`${getRevenueStr(intl)} (${currency})`]: value.revenue / 100,
  }
}

const mappingCallbacks = (currency: string, intl: IntlShape): CallbacksAllowed => ({
  revenueByCities: citiesMappingCallback(currency, intl),
  revenueByLocations: locationsMappingCallback(currency, intl),
});

const dataMapper = <Key extends KeysAllowed>(
  key: Key,
  data: FieldsAllowed[Key],
  currency: string,
  intl: IntlShape
) => {
  return data.map(mappingCallbacks(currency, intl)[key]);
}

const formatObjectsWithAllCurrencies = (currencies: Array<string>, intl: IntlShape, collection: RevenueType[]) => {
  const itemsMap = new Map<string, RevenueType>;
  const itemsSet = new Set<string>;
  const initialRevenueType = currencies.reduce((result ,currency) => {
    return {
      ...result,
      [`${getRevenueStr(intl)} (${currency})`]: 0
    }
  }, {} as RevenueType)
  
  collection.forEach((item) => {
    if (itemsSet.has(item.name as string)) {
      const tempItem = itemsMap.has(item.name as string) ? itemsMap.get(item.name as string) : {} as RevenueType;
      itemsMap.set(item.name as string, {...tempItem, ...item});
    } else {
      itemsMap.set(item.name as string, {...initialRevenueType, ...item});
      itemsSet.add(item.name as string);
    }
  })
  return Array.from(itemsMap.values())
}

const formatBusinessItems = (
  input: RevenueStatisticByCurrency[] = [],
  key: KeysAllowed,
  currencies: Array<string>,
  intl: IntlShape
): RevenueType[] => {
  return input?.reduce((result, value) => {
    !currencies.includes(value.currency) && currencies.push(value.currency);
    const remappedData = dataMapper<typeof key>(
      key,
      value[key],
      value.currency,
      intl
    );
    return [...result, ...remappedData];
    
  }, [] as RevenueType[]) ?? [] as RevenueType[];
}

export const formatAdminItems = (data: Array<BusinessRevenueStatistic>, currencies: Array<string>, intl: IntlShape): Array<RevenueType> => {
  return data?.map(item => {
    const amounts = item?.businessRevenueStatisticByCurrencies?.reduce((result, current) => {
      if (!currencies?.includes(current?.currency)) {
        currencies.push(current.currency);
      }
      return {
        ...result,
        [`${getRevenueStr(intl)} (${current?.currency})`]: current?.totalRevenueAmount / 100
      }
    }, {} as Record<string, number>) ?? {} as Record<string, number>
    
    return {
      name: item.businessName,
      ...amounts
    }
  }) ?? [] as Array<RevenueType>
}

export const getBarChartData = (
  input: RevenueStatisticByCurrency[] = [],
  key: KeysAllowed,
  currencies: Array<string>,
  intl: IntlShape
) => {
  const collection = formatBusinessItems(input, key, currencies, intl)
  return formatObjectsWithAllCurrencies(currencies, intl, collection)
}

export const formatBusinessData = (
  data: Array<BusinessRevenueStatistic>,
  currencies: Array<string>,
  intl: IntlShape) => {
  const collection = formatAdminItems(data, currencies, intl)
  return formatObjectsWithAllCurrencies(currencies, intl, collection)
}
