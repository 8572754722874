import theme from "../../../theme";

export const AMPDateRangePickerSx = {
  calendar: {
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      '.rdrCalendarWrapper': {
        fontSize: theme.spacing(10/8)
      }
    },
    '.rdrDefinedRangesWrapper': {
      display: "none"
    }
  }
}