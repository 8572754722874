import { FC } from "react";
import { Box, Typography } from '@mui/material';
import { langSelectWrapperSx, signInSx, welcomeBlockSx, wrapperSx } from "./SignUp.sx";
import { LangSelect } from "../../components/common/LangSelect";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { SignUpForm } from "src/components/forms/SignUpForm";
import { AppRouteSpace, AppRouteEnum } from "src/utils/helper";

export const SignUp: FC = () => {
  return (
    <Box sx={wrapperSx}>
      <Box sx={langSelectWrapperSx}>
        <LangSelect />
      </Box>
      <Box sx={welcomeBlockSx}>
        <Typography variant="h1"
          color="textPrimary"
        >
          <FormattedMessage id="signUpToAMP" />
        </Typography>
        <Box sx={signInSx}>
          <Typography variant="subtitle1"
            color="grey.500"
          >
            <FormattedMessage id="aMember" />
          </Typography>
          <Link
            to={AppRouteSpace([AppRouteEnum.login])}
          >
            <Typography
              variant="subtitle1"
              color="primary"
            >
              <FormattedMessage id="signIn" />
            </Typography>
          </Link>
        </Box>
      </Box>
      <SignUpForm />
    </Box>
  )
}
