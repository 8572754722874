import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { locationsApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import AMPModal from "src/components/common/AMPModal";
import { ConformationModal } from "src/components/common/ConformationModal";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch, LocationDTO } from "src/types";
import { NotifyError } from "src/utils/notification";

type DeleteLocationMutationTrigger = ReturnType<typeof locationsApi.useDeleteLocationMutation>[0]

const handleDeleteLocation = (trigger: DeleteLocationMutationTrigger, dispatch: AppDispatch, locationId: number, businessId: number, handleClose: () => void) => async () => {
  dispatch(preloaderChangeStatus(true))
  try{
    await trigger({ locationId, businessId }).unwrap()
    handleClose()
  } catch (error: any) {
    NotifyError(error.data.message);
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
}


export const DeleteLocation: FC<{row: LocationDTO}> = ({row}) => {
  const [trigger, response] = locationsApi.useDeleteLocationMutation()
  const dispatch = useAppDispatch()
  
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false)
  const dialogTitle = intl.formatMessage({ id: "deleteLocation" })

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }
  return (
    <>
      <IconButton 
        onClick={handleOpenModal}
      >
        <Delete />
      </IconButton>
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <ConformationModal
          handleClose={handleCloseModal}
          handleSubmit={handleDeleteLocation(trigger, dispatch, +row.id, +row.business.id, handleCloseModal)}
          text={'deletionLocation'}
        />
      </AMPModal>
    </>
  )
}
