import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const buttonSx: SxProps<Theme> = {
  background: 'transparent',
  cursor: 'default',
  '&:hover': {
    background: 'transparent'
  },
}

export const popoverTextSx: SxProps<Theme> = {
  background: theme.palette.neutral[900],
  color: theme.palette.text.secondary,
  p: theme.spacing(8/8),
  maxWidth: theme.spacing(210/8)
}

export const errorIconSx: SxProps<Theme> = {
  cursor: 'pointer',
  '& path': {
    fill: theme.palette.error.main
  }
}