import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import { FC, useContext } from "react";
import { useParams } from "react-router-dom";
import { locationsApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { AppDispatch, store } from "src/app/store";
import LocationInfoContext from "src/pages/LocationInfo/LocationInfo.context";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type DeleteLocationFileMutationTrigger = ReturnType<typeof locationsApi.useDeleteLocationFileMutation>[0]

const handleDeleteFile = (trigger: DeleteLocationFileMutationTrigger, dispatch: AppDispatch, locationId: number, fileId: number, context: any) => async () => {
  const params = {
    fileId,
    businessId: context.businessId,
  }
  dispatch(preloaderChangeStatus(true))
  try{
    await trigger({ locationId, params }).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
}

export const DeleteFileFromLocationAction: FC<{row: GridRowParams}> = ({row}) => {
  const { id: locationId } = useParams()
  const [trigger, response] = locationsApi.useDeleteLocationFileMutation()
  const dispatch = useAppDispatch()
  const context = useContext(LocationInfoContext)

  return (
    <>
      {locationId && <IconButton onClick={handleDeleteFile(trigger, dispatch, +locationId, +row.id, context)}>
        <Delete />
      </IconButton>}
    </>
  )
}
