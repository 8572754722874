import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { policyFrenchSx } from "./PolicyFrench.sx";

export const PolicyFrench:FC = () => {
  return (
    <Box>
      <Box sx={policyFrenchSx.root}>
        <Typography variant="h2">
          Politique de confidentialité
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Dernière mise à jour: 18 octobre 2022 04:08
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Nous accordons une grande importance à votre vie privée. Veuillez lire attentivement cette politique
          de confidentialité avant d&#39;utiliser l&#39;application AMP IT (&quot;Application&quot;) exploitée par AMP IT SA, une
          société privée à responsabilité limitée par actions constituée en Suisse (&quot;nous&quot;, &quot;nous&quot;, &quot;nos&quot; ) car
          cette politique de confidentialité contient des informations importantes concernant votre vie privée
          et la manière dont nous pouvons utiliser les informations que nous recueillons à votre sujet.
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Votre accès et votre utilisation de l&apos;Application sont subordonnés à votre acceptation et à votre respect de la présente Politique de confidentialité. Cette politique de confidentialité s&apos;applique à tous, y compris, mais sans s&apos;y limiter: les visiteurs, les utilisateurs et les autres personnes qui souhaitent accéder ou utiliser l&apos;application.
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          En accédant ou en utilisant l&apos;Application, vous acceptez d&apos;être lié par cette Politique de confidentialité. Si vous n&apos;êtes pas d&apos;accord avec une partie de la politique de confidentialité, vous n&apos;avez pas notre permission d&apos;accéder ou d&apos;utiliser l&apos;application.
        </Typography>
        <Typography
          variant="h3"
          sx={policyFrenchSx.header}
        >
          Quelles informations nous recueillons, comment nous les utilisons, que se passe-t-il si nous ne les avons pas et la base juridique pour le traitement de ces informations
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Nous recueillons toutes les informations que vous saisissez sur cette Application. Nous collectons les informations suivantes vous concernant:      </Typography>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
          fontWeight="800"
        >
          Informations d&apos;identification - Nom
        </Typography>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Une information spécifique
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Nom
        </Typography>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Base juridique du traitement de ces informations
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              L&apos;utilisateur a donné son consentement au traitement de ses informations;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Le traitement est nécessaire pour exécuter un contrat avec l&apos;utilisateur;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Le traitement est nécessaire afin de prendre les mesures que l&apos;utilisateur a demandées avant de conclure un contrat;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Le traitement est nécessaire au respect d&apos;une obligation légale.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Comment nous utilisons ces informations
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Appliquer nos conditions d&apos;utilisation;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Prestation de services;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Fournir un service à la clientèle;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Fournir du financement;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Résolution des litiges;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Vérification des informations client.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Que se passe-t-il si nous n&apos;avons pas ces informations?
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour faire respecter nos Conditions d&apos;utilisation ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour effectuer des services ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour fournir un service client ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour fournir un financement ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour résoudre les litiges ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour vérifier les informations client ne sera pas possible.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
          fontWeight="800"
        >
          Informations d&apos;identification - Numéro de téléphone
        </Typography>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Une information spécifique
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Numéro de téléphone
        </Typography>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Base juridique du traitement de ces informations
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              L&apos;utilisateur a donné son consentement au traitement de ses informations;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Le traitement est nécessaire pour exécuter un contrat avec l&apos;utilisateur;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Le traitement est nécessaire afin de prendre les mesures que l&apos;utilisateur a demandées avant de conclure un contrat;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Le traitement est nécessaire au respect d&apos;une obligation légale.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Comment nous utilisons ces informations
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Appliquer nos conditions d&apos;utilisation;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Prestation de services;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Fournir un service à la clientèle;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Fournir du financement;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Résolution des litiges;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Vérification des informations client.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Que se passe-t-il si nous n&apos;avons pas ces informations?
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour faire respecter nos Conditions d&apos;utilisation ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour effectuer des services ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour fournir un service client ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour fournir un financement ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour résoudre les litiges ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour vérifier les informations client ne sera pas possible.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          fontWeight="800"
          sx={policyFrenchSx.header}
        >
          Informations d&apos;identification - Adresse e-mail
        </Typography>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Une information spécifique
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Adresse e-mail
        </Typography>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Base juridique du traitement de ces informations
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              L&apos;utilisateur a donné son consentement au traitement de ses informations;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Le traitement est nécessaire afin de prendre les mesures que l&apos;utilisateur a demandées avant de conclure un contrat;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Le traitement est nécessaire au respect d&apos;une obligation légale.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Comment nous utilisons ces informations
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Prestation de services;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Fournir un service à la clientèle;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Fournir du financement;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Résolution des litiges;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Vérification des informations client.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Que se passe-t-il si nous n&apos;avons pas ces informations?
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour effectuer des services ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour fournir un service client ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour fournir un financement ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour résoudre les litiges ne sera pas possible;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Notre utilisation de vos données pour vérifier les informations client ne sera pas possible.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
          fontWeight="800"
        >
          Informations d`identification - Adresse physique
        </Typography>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Une information spécifique
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Adresse physique
        </Typography>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Base juridique du traitement de ces informations
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              L`utilisateur a donné son consentement au traitement de ses informations;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Le traitement est nécessaire pour exécuter un contrat avec l`utilisateur;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Le traitement est nécessaire afin de prendre les mesures que l`utilisateur a demandées avant de conclure un contrat;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Le traitement est nécessaire au respect d`une obligation légale.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
          fontWeight="800"
        >
          Informations d`identification - Photos
        </Typography>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Une information spécifique
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Photos
        </Typography>
        <Typography
          variant="h5"
          sx={policyFrenchSx.header}
        >
          Base juridique du traitement de ces informations
        </Typography>
        <Typography
          variant="body2"
          sx={policyFrenchSx.paragraph}
        >
          L`utilisateur a donné son consentement au traitement de ses informations;
        </Typography>
        <Typography
          variant="body2"
          sx={policyFrenchSx.paragraph}
        >
          C`est uniquement pour une utilisation dans l`application en tant qu`avatar
        </Typography>
        <Typography
          variant="h3"
          sx={policyFrenchSx.header}
        >
          Avec qui nous partageons vos informations personnelles
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Nous ne partageons pas vos informations personnelles avec des tiers.
        </Typography>
        <Typography
          variant="h3"
          sx={policyFrenchSx.header}
        >
          Confidentialité des enfants
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Cette Application est destinée à être utilisée par un public général et n&apos;offre pas de services aux enfants. Si un enfant dont nous savons qu&apos;il a moins de 18 ans nous envoie des informations personnelles, nous n&apos;utiliserons ces informations que pour répondre à cet enfant afin de l&apos;informer qu&apos;il ne peut pas utiliser cette Application.
        </Typography>
        <Typography
          variant="h3"
          sx={policyFrenchSx.header}
        >
          Conservation des informations
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Nous conservons toutes les informations que nous collectons jusqu&apos;à ce que l&apos;utilisateur demande une suppression de compte
        </Typography>
        <Typography
          variant="h3"
          sx={policyFrenchSx.header}
        >
          Les droits des résidents de l&apos;Union européenne et/ou de l&apos;Espace économique européen
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Les résidents de l&apos;Union européenne et de l&apos;Espace économique européen ont les droits suivants concernant leurs informations personnelles:
        </Typography>
        <ol>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Le droit d&apos;accéder à vos informations. Vous avez le droit d&apos;accéder à toutes les informations que nous avons pu collecter sur vous. Vous pouvez demander des copies des informations que nous avons sur vous dans un format structuré, couramment utilisé et lisible par machine.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Transmission d&apos;informations. Vous avez le droit de nous demander de transmettre vos informations personnelles que nous avons collectées sur vous à un autre fournisseur, lorsque cela est techniquement possible.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Rectification des informations. Vous avez le droit de nous demander de modifier toute information que nous avons collectée sur vous.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Vous avez le droit de retirer votre consentement au traitement de vos données.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Vous avez le droit de demander que nous limitions le traitement de vos données.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Vous avez le droit de nous demander de supprimer tout ou partie des informations que nous avons collectées sur vous.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyFrenchSx.paragraph}
            >
              Vous avez le droit de déposer une plainte concernant notre collecte, partage et traitement des données auprès des autorités compétentes de la juridiction appropriée.
            </Typography>
          </li>
        </ol>
        <Typography
          variant="h3"
          sx={policyFrenchSx.header}
        >
          Exercice de vos droits
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Les résidents de l&apos;Union Européenne et de l&apos;Espace Economique Européen peuvent exercer les droits précisés ci-dessus en adressant une demande de consommateur à :
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          AMP IT SA
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          info@amp-it.ch
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          022 525 77 22
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          amp-it.com
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Rue des Sablières 1-3, 1242 Satigny, Suisse
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Nous aurons besoin de vérifier votre identité avant d&apos;effectuer votre demande et pouvons vous demander de fournir des informations personnelles pour ce faire. Nous répondrons à la plupart des demandes des consommateurs dans les 30 jours suivant leur réception. Cependant, certaines demandes peuvent prendre plus de temps. Nous vous informerons par écrit si nous avons besoin de plus de temps pour répondre. Nous avons la possibilité de refuser votre/vos demande(s) si certaines exceptions à la loi s&apos;appliquent. Si nous refusons votre demande, nous vous fournirons les raisons de ce refus.
        </Typography>
        <Typography
          variant="h3"
          sx={policyFrenchSx.header}
        >
          Lieu de traitement des données
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Toutes les activités de traitement de données que nous entreprenons ont lieu en Suisse.
        </Typography>
        <Typography
          variant="h3"
          sx={policyFrenchSx.header}
        >
          Des questions
        </Typography>
        <Typography
          variant='body2'
          sx={policyFrenchSx.paragraph}
        >
          Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à info@amp-it.ch.
        </Typography>
      </Box>
    </Box>
  )
}