import * as Yup from 'yup';

export interface LoginType extends Record<string, unknown>{
  emailAddress: string,
  password: string,
}

export const loginSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email('schemaValidEmail')
    .max(255)
    .required('schemaRequiredEmail'),
  password: Yup.string()
    .max(255)
    .required('schemaRequiredPassword')
});
