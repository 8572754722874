import { ArrowForward, MoreVert, Repeat } from "@mui/icons-material";
import { Box, IconButton, Switch, Typography } from "@mui/material";
import { ChangeEvent, FC, useState } from "react";
import { useIntl } from "react-intl";
import { chargersApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import AMPModal from "src/components/common/AMPModal";
import { ScheduleTypeEnum, WeekEnum, WeekEnumShortValue } from "src/enums";
import { Can } from "src/permissions/Can";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { compileScheduleDate } from "src/utils/consts";
import { formatTime, isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { ChargerScheduleCreateWrapper } from "../ChargerScheduleCreateWrapper";
import { ChargerScheduleUpdateWrapper } from "../ChargerScheduleUpdateWrapper";
import { DeleteSchedule } from "../DeleteSchedule";
import { wrapperSx, timeWrapperSx, arrowSx, typographySx, switchSx, actionsWrapperSx, editButtonSx } from "./ScheduleItem.sx";
import { ScheduleItemType } from "./ScheduleItem.types";

type UpdateScheduleStatusMutationTrigger = ReturnType<
  typeof chargersApi.useUpdateScheduleStatusMutation
>[0];

const handleSwitch = (trigger: UpdateScheduleStatusMutationTrigger, dispatch: AppDispatch, businessId: number, chargerId: number, scheduleChargingId: number ) => async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
  dispatch(preloaderChangeStatus(true))
  try{
    await trigger({ businessId, chargerId, active: checked, scheduleChargingId}).unwrap()
  } catch(error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false))
  }
}

export const ScheduleItem: FC<ScheduleItemType> = ({data, businessId, chargerId}) => {
  const [trigger, schedule] = chargersApi.useUpdateScheduleStatusMutation()
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const [open, setOpen] = useState<boolean>(false)
  const dialogTitle = intl.formatMessage({id: 'editSchedule'})

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }
  return (
    <Box sx={wrapperSx}>
      <Box sx={timeWrapperSx}>
        <Typography
          variant="body1"
          sx={typographySx}
        >
          {compileScheduleDate(WeekEnumShortValue[data.startDay as WeekEnum], [formatTime(data.startHour), formatTime(data.startMinute)])}
        </Typography>
        {data.type === ScheduleTypeEnum.ONE_TIME 
          ? <ArrowForward sx={arrowSx} />
          : <Repeat sx={arrowSx} />
        }
        <Typography
          variant="body1"
          sx={typographySx}
        >
          {compileScheduleDate(WeekEnumShortValue[data.stopDay as WeekEnum], [formatTime(data.stopHour), formatTime(data.stopMinute)])}
        </Typography>
      </Box>
      <Can
        I="manage"
        a="schedule"
      >
        <Box sx={actionsWrapperSx}>
          <Switch
            checked={data.active}
            onChange={handleSwitch(trigger, dispatch, businessId, chargerId, data.id)}
            sx={switchSx}
          />
          <Box>
            <DeleteSchedule 
              businessId={businessId}
              chargerId={chargerId}
              scheduleChargingId={data.id}
            />
            <IconButton
              sx={editButtonSx}
              onClick={handleOpenModal}
            >
              <MoreVert />
            </IconButton>
          </Box>
        </Box>
      </Can>
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <ChargerScheduleUpdateWrapper
          handleClose={handleCloseModal}
          initialValue={data}
          businessId={businessId}
          chargerId={chargerId}
        />
      </AMPModal>
    </Box>
  )
}
