import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { Box, Link, Stack, Typography } from "@mui/material";
import { Launch as LinkIcon } from "@mui/icons-material";
import { useIntl } from "react-intl";
import { EmptyTable } from "../../../tables/EmptyTable";
import { TermsTabProps } from "./TermsTab.types";
import { linkStackSx, MarkdownSx } from "./TermsTab.sx";
import { businessesApi } from "../../../../api";

export const TermsTab:FC<TermsTabProps> = ({ businessId, language }) => {
  const intl = useIntl()
  const documentData = businessesApi.useGetContractTermsQuery({
    businessId: businessId,
    language: language
  })
  
  return (
    <Box>
      {!documentData?.data &&
        <EmptyTable/>
      }
      <Typography
        variant="body1"
        component="div"
        sx={MarkdownSx}
      >
        <ReactMarkdown>
          {documentData?.data?.contractTerms ?? ''}
        </ReactMarkdown>
      </Typography>
      {documentData?.data?.termsLink &&
        <Link
          target="_blank"
          href={documentData?.data?.termsLink}
          underline="none"
        >
          <Stack sx={linkStackSx}>
            <Typography fontSize={12}>
              {intl.formatMessage({ id: "reedFullDocument" })}
            </Typography>
            <LinkIcon sx={{ fontSize: '1em' }} />
          </Stack>
        </Link>
      }
    </Box>
  )
}
