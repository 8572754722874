import * as Yup from "yup";
import { CurrencyEnum } from "../../../../enums";

export type CreateLocationCostType = {
  item: string,
  date: number,
  cost: number,
  currency: CurrencyEnum,
  serviceLife: number
}

export const createLocationCostSchema = Yup.object().shape({
  currency: Yup.string(),
  item: Yup.string()
    .trim()
    .required('schemaRequiredItem'),
  date: Yup.number()
    .required('schemaRequiredInstallationDate'),
  cost: Yup.number()
    .required('schemaRequiredCost')
    .moreThan(0, 'schemaRequiredServiceLife'),
  serviceLife: Yup.number()
    .required('schemaRequiredServiceLife')
    .moreThan(0, 'schemaRequiredServiceLife')
});
