import { FC } from "react";
import { termsOfUseSx } from "./TermsFrench.sx";
import { Box, Typography } from "@mui/material";

export const TermsFrench:FC = () => {
  return (
    <Box sx={termsOfUseSx.root}>
      <Typography variant="h2">
        Conditions Générales d’utilisation du site AMP IT SA
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Introduction
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Les présentes Conditions Générales d’Utilisation (CGU), rédigées sur cette page Web, régissent votre utilisation de notre site Internet accessible à l&apos;adresse https://amp-it.com ainsi que de notre application mobile AMP IT Hub, propriété de AMP IT SA.
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Ces conditions seront pleinement appliquées et affecteront votre utilisation de présent site Web. En naviguant sur ce site Web, vous acceptez tous les termes ou conditions y décrits. Vous ne devez pas utiliser ce site Web si vous n&apos;êtes pas d&apos;accord avec l&apos;une des Conditions Générales d’Utilisation de ce site Web.
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Les mineurs ou les personnes de moins de 18 ans ne sont pas autorisés à utiliser ce site Web.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Droits de propriété intellectuelle
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        À l&apos;exception du contenu dont vous êtes le propriétaire, et en vertu des présentes Conditions Générales d’Utilisation, AMP IT SA et/ou ses concédants détiennent tous les droits et les éléments de propriété intellectuelle contenus dans ce site Web. Nos termes et Conditions Générales d’Utilisation ont été générés sur le générateur de modèles de termes et conditions.
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Une licence limitée vous est accordée uniquement à des fins de visualisation du matériel contenu sur ce site Web.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Obligations de l&apos;utilisateur
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        En utilisant ce site Web ou l’application mobile AMP IT Hub, vous n’avez pas le droit de:
      </Typography>
      <ul>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            publier tout matériel du site Web dans tout autre média;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            vendre, accorder des sous-licences et/ou commercialiser de quelque manière que ce soit le matériel du site Web;          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            représenter et/ou afficher publiquement tout matériel du site Web;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            utiliser ce site Web ou son contenu d&apos;une manière qui est ou puisse être préjudiciable à ce site Web;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            utiliser ce site Web d&apos;une manière pouvant affecter sont accès aux autres utilisateurs;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            utiliser ce site Web en violation des lois et réglementations en vigueur ou pouvant de quelque manière que ce soit causer un préjudice au site Web, à toute personne ou toute entité commerciale;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            s’engager dans des activités d’exploration de données, de récolte de données, d’extraction de données ou de toute autre activité similaire en relation avec ce site Web;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            utiliser ce site Web à des fins publicitaires ou marketing.
          </Typography>
        </li>
      </ul>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        L&apos;accès à certaines zones du présent site Web est restreint. AMP IT SA peut décider de restreindre davantage votre accès à toute zone de ce site Web, à tout moment et à sa discrétion absolue. Tout nom d&apos;utilisateur et mot de passe que vous pourriez détenir pour ce site Web sont confidentiels et il est de votre responsabilité d’en préserver la confidentialité.      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Votre contenu
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Dans les présentes Conditions Générales d’Utilisation du site Web, &quot;Votre contenu&quot; désigne tout texte audio, vidéo, image ou autre matériel que vous choisissez de publier sur ce site Web. En publiant votre contenu sur ce site Web, vous accordez à AMP IT SA une licence non exclusive, mondiale, irrévocable et sous-licenciable pour l&apos;utiliser, le reproduire, l&apos;adapter, le publier, le traduire et le distribuer sur tous les supports.
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Votre contenu doit vous appartenir et ne doit pas porter atteinte aux droits d&apos;un tiers. AMP IT SA se réserve le droit de supprimer tout élément de «Votre contenu» de présent site Web à tout moment et sans préavis.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Exclusion de garantie
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Ce site Web est fourni &quot;en l&apos;état&quot; et AMP IT SA ne manifeste aucune déclaration ou garantie, de quelque nature que ce soit, liée à ce site Web ou aux éléments qu’il contient. De plus, aucun élément contenu sur ce site Web ne doit être interprété comme un conseil.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Exclusion de responsabilité
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        En aucun cas AMP IT SA, ni aucun de ses dirigeants, administrateurs et employés, ne pourra être tenu responsable de quoi que ce soit découlant de votre utilisation du présent site Web, ou y être lié de quelque manière que ce soit, que cette responsabilité soit contractuelle ou non. AMP IT SA, y compris ses dirigeants, administrateurs et employés ne peuvent être tenus responsables de toute responsabilité indirecte, consécutive ou spéciale découlant de l’utilisation de ce site WEB ou liée de quelque manière que ce soit à cette utilisation.      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Indemnisation
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Par la présente, vous dégagez dans toute la mesure du possible AMP IT SA de et/ou de toutes les responsabilités, coûts, demandes, causes d&apos;action, dommages et dépenses découlant de quelque manière que ce soit de votre violation de l&apos;une des dispositions des présentes Conditions Générales d’Utilisation.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Dissociabilité
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Si une disposition de présentes Conditions est jugée invalide en vertu de toute loi applicable, cette disposition sera supprimée sans affecter les autres dispositions des présentes.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Modification des conditions
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        AMP IT SA est autorisée à réviser les présentes conditions à tout moment comme bon lui semble, et en utilisant ce site Web, vous êtes tenu de consulter régulièrement ces Conditions.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Cession
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        AMP IT SA est autorisée à céder, transférer et sous-traiter ses droits et/ou obligations en vertu des présentes Conditions sans aucune notification. Toutefois, vous n&apos;êtes pas autorisé à céder, transférer ou sous-traiter l&apos;un de vos droits et/ou obligations en vertu des présentes Conditions.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Accord intégral
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Les présentes Conditions constituent l&apos;intégralité de l&apos;accord entre AMP IT SA et vous-même en ce qui concerne votre utilisation de ce site Web, et remplacent tous les accords et arrangements antérieurs.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Droit applicable et for
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Les présentes CGU seront régies et interprétées conformément aux lois applicables dans le canton de Genève et vous vous soumettez à la juridiction non exclusive des tribunaux fédéraux et cantonaux situés à Genève pour la résolution de tout litige.
      </Typography>
    </Box>
  )
}