import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const submitButtonSx = (rounding: boolean, align: string, fullWidth: boolean): SxProps => ({
  maxWidth: fullWidth ? '100%' : theme.spacing(175/8),
  alignSelf: align,
  borderRadius: rounding ? theme.spacing(50/8) : theme.spacing(5/8),
  '& span': {
    textTransform: 'none'
  }
})
