import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { uploadContractSx } from "./UploadContract.sx";
import { useIntl } from "react-intl";
import { TypographyWithCaption } from "../../../components/common/TypographyWithCaption";
import DropZone from "../../../components/common/DropZone";
import { CancelButton } from "../../../components/common/CancelButton";
import { SubmitButton } from "../../../components/common/SubmitButton";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { store } from "../../../app/store";
import { contractsApi } from "../../../api";
import { useAppDispatch } from "../../../app/hooks";
import { AppDispatch } from "../../../types";
import { UploadContractProps } from "./UploadContract.types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";


const handleSubmit = (
  dispatch: AppDispatch,
  handleClose: () => void,
  businessId: number | undefined,
  contractId: number | undefined,
  file?: File[] | null
) => async () => {
  try {
    dispatch(preloaderChangeStatus(true))
    if(file && contractId && businessId) {
      const formData = new FormData()
      formData.append('file', file[0])
      await store.dispatch(contractsApi.endpoints.uploadContract.initiate({
        contractId,
        businessId,
        file: formData
      }))
    }
    handleClose()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
};

export const UploadContract:FC<UploadContractProps> = ({ handleClose, handleBack, contractId, businessId }) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [file, setFile] = useState<File[] | null>(null)
  
  const handleAddFile = (draggedFile: File[] | null)=> {
    setFile(draggedFile)
  }
  
  return (
    <Box>
      <Box sx={uploadContractSx.root}>
        <TypographyWithCaption
          title={"uploadContract"}
          caption={"optionalField"}
        />
        <Box sx={uploadContractSx.subtitleWrapper}>
          <Typography variant="body1">
            {intl.formatMessage({ id: "skipUploadContract" })}
          </Typography>
        </Box>
        <Typography
          variant="subtitle2"
          pb={1}
        >
          {intl.formatMessage({ id: "attachedFile" })}
        </Typography>
        <Box>
          <DropZone
            handleFile={handleAddFile}
            isImage={false}
          />
        </Box>
      </Box>
      <Box sx={uploadContractSx.actions}>
        <CancelButton
          text="cancel"
          handler={handleClose}
        />
        <Box sx={uploadContractSx.activeActions}>
          <CancelButton
            text="back"
            color="primary"
            handler={handleBack}
          />
          <SubmitButton
            text="finish"
            color="primary"
            isSubmitting={!file && !businessId && !contractId}
            handler={handleSubmit(dispatch, handleClose, businessId, contractId, file )}
          />
        </Box>
      </Box>
    </Box>
  )
}
