import theme from "../../../theme";
import { SxProps } from "@mui/material";
import { CSSProperties } from "react";

export const inputSx: CSSProperties = {
  height: theme.spacing(50/8),
  width: "100%",
}
export const inputBoxSx = (hasError: boolean): SxProps => ({
  border: hasError ? `${theme.spacing(1/8)} solid ${theme.palette.error.light}` : "none",
  borderRadius: theme.spacing(6/8)
})
