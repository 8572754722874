import { Box } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { chargersApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { store } from "src/app/store";
import { ChargerCreateFormWrapper } from "src/components/chargers/ChargerCreateFormWrapper";
import { ChargerTable } from "src/components/chargers/ChargerTable";
import AMPModal from "src/components/common/AMPModal";
import { PageHeader } from "src/components/common/PageHeader";
import { ChargerSortFieldEnum } from "src/enums";
import ability from "src/permissions/ability";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { ChargePointSearchRequestData, ListParamsType, ReactLocationStateType } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type ChargersQueryTrigger = ReturnType<
  typeof chargersApi.useLazyGetChargersQuery
>[0];

interface DataType {
  [index: string]: unknown;
}

const handleGetChargers = (trigger: ChargersQueryTrigger, businessId: number) => (async (tableData: ChargePointSearchRequestData, params: ListParamsType) => {
  store.dispatch(preloaderChangeStatus(true))
  
  const newData: DataType = {
    ...tableData,
    businessId: tableData.businessId ? tableData.businessId : businessId
  }
  Object.keys(tableData).map((item: string) => {
    !newData[item] && delete newData[item]
  })
  
  const data: ChargePointSearchRequestData = {
    ...newData,
  }
  try {
    const response = await trigger({ data, params }).unwrap()
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
});

export const Chargers: FC = () => {
  const intl = useIntl();
  const defaultPageSize = 100
  const [open, setOpen] = useState<boolean>(false);
  const dialogTitle = intl.formatMessage({ id: "createNewCharger" });
  const { authInfo } = useAppSelector((state) => state.common);
  const [trigger, response] = chargersApi.useLazyGetChargersQuery();
  const [tableParams, setTableParams] = useState<ListParamsType>({
    page: 0,
    size: defaultPageSize,
  });
  const [filterParams, setFilterParams] =
    useState<ChargePointSearchRequestData>({
      businessId: 0,
      chargePointStatus: undefined,
      city: '',
      connectorStatus: undefined,
      country: '',
      locationId: undefined,
      street: '',
      withoutDriver: false,
      withoutLocation: false,
      model: '', 
      vendor: '',
      sortField: ChargerSortFieldEnum.CREATED_AT,
      sortAsc: false
    });

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate()

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    callHandler(filterParams, tableParams);
  };

  const handleChange = (value: ChargePointSearchRequestData) => {
    setFilterParams(value);
    setTableParams({
      page: 0,
      size: tableParams.size
    })
    setSearchParams({})
    Object.keys(authInfo) && callHandler(value, {
      page: 0,
      size: tableParams.size
    })
  };

  const handleChangePage = (page: number) => {
    Object.keys(authInfo).length &&
      callHandler(filterParams, {
        page,
        size: tableParams.size,
      });
    setTableParams({
      page,
      size: tableParams.size,
    });
    setSearchParams({
      page: String(page)
    })
  };

  const handleChangeRowsNumber = (e: ChangeEvent<HTMLInputElement>) => {
    Object.keys(authInfo).length &&
      callHandler(filterParams, {
        page: 0,
        size: +e.target.value || defaultPageSize,
      });
    setTableParams({
      page: 0,
      size: +e.target.value || defaultPageSize,
    });
  };

  const callHandler = handleGetChargers(trigger, authInfo.business && +authInfo.business.id);

  const manageChargers = ability.can('manage', 'charger') 
  const readChargers = ability.can('read', 'chargers') 

  useEffect(() => {
    if(!readChargers){
      return navigate(`/404`);
    }
    const currentState = location.state as ReactLocationStateType | undefined;
    const currentSearch = location.search
    if(currentState){
      setSearchParams({
        page: currentState?.page ?? currentSearch?.replace(/(\D+)(\d+)$/, "$2") ?? String(tableParams.page)
      })
      setTableParams({
        ...tableParams,
        page: +currentState?.page ?? +currentSearch?.replace(/(\D+)(\d+)$/, "$2") ?? tableParams.page
      })
      callHandler(filterParams, {
        ...tableParams,
        page: +currentState.page
      })
      return
    }
    Object.keys(authInfo).length && callHandler(filterParams, tableParams);
  }, []);

  return (
    <Box>
      <PageHeader
        title={intl.formatMessage({ id: "chargers" })}
        onClick={handleOpenModal}
        buttonText="addCharger"
        onlyTitle={!manageChargers}
      />
      {response.data && (
        <ChargerTable
          data={response.data}
          pageSize={tableParams.size}
          pageNumber={tableParams.page}
          handleUpdateTableData={handleChange}
          handleChangePage={handleChangePage}
          handleChangeRowsNumber={handleChangeRowsNumber}
        />
      )}
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <ChargerCreateFormWrapper
          handleClose={handleCloseModal}
          authInfo={authInfo}
        />
      </AMPModal>
    </Box>
  );
};
