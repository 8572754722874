import { CheckCircleOutline } from "@mui/icons-material";
import { Divider, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { columnSx, containerSx, nameSx, unitSx } from "./AvailableParam.sx";
import { AvailableParamType } from "./AvailableParam.types";

export const AvailableParam: FC<AvailableParamType> = ({label, value}) => {
  return (
    <>
      <Grid 
        container
        spacing={{xs: 1, lg: 2}}
        sx={containerSx}
      >
        <Grid
          item
          xs={2}
          lg={1}
          sx={columnSx}
        >
          <CheckCircleOutline color="success" />
        </Grid>
        <Grid
          item
          xs={6}
          lg={7}
          sx={columnSx}
        >
          <Typography
            sx={nameSx}
            variant="body2"
          >
            {label}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={columnSx}
        >
          <Typography
            sx={unitSx}
            variant="button"
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
      <Divider/>
    </>
  )
}
