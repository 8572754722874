export enum InvoiceSortEnum {
  AMOUNT = "AMOUNT",
  CREATED = "CREATED",
  INVOICE_NUMBER = "INVOICE_NUMBER",
  STATUS = "STATUS"
}

export enum InvoiceStatusEnum {
  CANCELED = "CANCELED",
  DRAFT = "DRAFT",
  PAID = "PAID",
  PARTIAL = "PARTIAL",
  PENDING = "PENDING",
  UNPAID = "UNPAID"
}
