import { Box } from "@mui/material";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { FC, Fragment, useEffect, useState } from "react";
import { TablePagination } from "src/components/tables/TablePagination";
import { TableRowCounter } from "src/components/tables/TableRowCounter";
import { Table } from "../../tables/Table";
import { InvoicesTableProps } from "./InvoicesTable.types";
import { columnConfigInvoices } from "./InvoicesTable.tableConfig";
import { InvoiceSortEnum } from "src/enums";
import { InvoicesTableActions } from "../InvoicesTableActions";
import { InvoiceDTO, InvoiceSearchRequestData, RevenueInvoiceDTO } from "src/types";
import { useAppSelector } from "src/app/hooks";
import { InvoiceTableCard } from "./InvoiceTableCard";
import { InvoicesFilterValue } from "../InvoicesTableActions/InvoicesTableActions.types";
import { tableFooterSx } from "./InvoicesTable.sx";
import { ResponsiveTable } from "../../common/ResponsiveTable";

export const InvoicesTable: FC<InvoicesTableProps> = ({ size, data, handleUpdateTableData, handleChangePage, handleChangeRowsNumber }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<InvoicesFilterValue>({ status: '', businessId: 0 });
  const [sortValue, setSortValue] = useState<GridSortItem>({ field: '', sort: 'desc' });
  
  const { authInfo } = useAppSelector(state => state.common)
  
  useEffect(() => {
    const data: InvoiceSearchRequestData = {
      ...filterValue,
      keyword: searchValue,
      sortAsc: sortValue ? sortValue.sort === 'asc' : true,
      sortField: (sortValue && sortValue.field) || InvoiceSortEnum.CREATED
    }
    handleUpdateTableData(data)
  }, [searchValue, filterValue, sortValue])
  
  const isRevenueInvoice = (item: InvoiceDTO | RevenueInvoiceDTO): item is RevenueInvoiceDTO => {
    return !!(item as RevenueInvoiceDTO)?.business
  }

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <InvoicesTableActions
        handleSearch={( value: string ) =>setSearchValue(value)}
        handleFilter={( value: InvoicesFilterValue ) => setFilterValue(value)}
      />
      <ResponsiveTable
        desktopElement={
          <Table
            rows={data.content}
            hideFooter
            columns={columnConfigInvoices(authInfo.roles[0].role)}
            handleSort={( value: GridSortModel ) => setSortValue(value[0])}
          />
        }
        responsiveElement={
          <>
            {data?.content?.map(item =>
              <Fragment key={item?.id}>
                {isRevenueInvoice(item)
                  && <InvoiceTableCard
                    key={item?.id}
                    role={authInfo.roles[0].role}
                    adminData={item}
                  />
                }
                {!isRevenueInvoice(item)
                  && <InvoiceTableCard
                    key={item?.id}
                    role={authInfo.roles[0].role}
                    businessData={item}
                  />
                }
              </Fragment>
            )}
          </>
        }
      />
      <Box sx={tableFooterSx}>
        <TableRowCounter
          defaultValue={size}
          handleChangeRowsNumber={handleChangeRowsNumber}
        />
        <TablePagination
          totalPages={data.totalPages}
          page={data.number}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Box>
  )
}
