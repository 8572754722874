import { SxProps, Theme } from "@mui/material";
import theme from "../../../theme";

export const containerSx:SxProps<Theme> = {
  p: 3,
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(8/8),
  border: `1px solid ${theme.palette.neutral[300]}`,
  [theme.breakpoints.down('md')]: {
    p: 2
  }
}
export const unreadLineSx:SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: 1
  }
}
export const readBoxSx:SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: 1
}
export const unreadSx = {
  p: 0
}
export const visibilitySx:SxProps<Theme> = {
  color: theme.palette.neutral[400],
  width: theme.spacing(20/8)
}
export const dateSx:SxProps<Theme> = {
  fontSize: theme.spacing(12/8),
  fontWeight: 400,
  color: theme.palette.neutral[400],
  textAlign: 'right',
  [theme.breakpoints.down('lg')]: {
    textAlign: 'left'
  }
}
export const dateBoxLgSx:SxProps<Theme> = {
  display: "block",
  [theme.breakpoints.down('lg')]: {
    display: "none"
  }
}
export const dateBoxSmSx:SxProps<Theme> = {
  display: "none",
  [theme.breakpoints.down('lg')]: {
    display: "block"
  }
}
export const headerSx:SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  mb: 6,
  [theme.breakpoints.down('md')]: {
    mb: 2
  }
}
export const avatarBoxSx:SxProps<Theme> = {
  display: 'flex'
}
export const avatarGapSx:SxProps<Theme> = {
  width: theme.spacing(60/8)
}

export const avatarSx:SxProps<Theme> = {
  width: theme.spacing(48/8),
  height: theme.spacing(48/8)
}

export const gridContainerSx:SxProps<Theme> = {
  pl: theme.spacing(60/8)
}
export const titleTextSx:SxProps<Theme> = {
  color: theme.palette.neutral[400],
  fontSize: theme.spacing(10/8)
}
export const cardTextSx:SxProps<Theme> = {
  fontSize: theme.spacing(12/8),
  fontWeight: 500
}
export const infoBlockSx:SxProps<Theme> = {
  my: 2
}
