import { ChangeEvent, FC } from "react";
import { FieldArray, Form, Formik, FormikProps, getIn } from "formik";
import { Box, Button, Grid, Typography } from "@mui/material";
import { actionsWrapperSx, formContentSx, formSx } from "../CreateRfidForm/CreateRfidForm.sx";
import { AMPInput } from "../../common/AMPInput";
import { getHelperTextWithArray } from "../../../utils/forms";
import { CancelButton } from "../../common/CancelButton";
import { CurrencyEnum } from "../../../enums";
import dayjs from "dayjs";
import { AMPDatePicker } from "../../common/AMPDatePicker";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { EditLocationFormProps } from "./EditLocationForm.types";
import { AMPSwitcher } from "../../common/AMPSwitcher";
import {
  EditLocationCostFormType,
  editLocationCostSchema,
  LocationCostItemDataType,
  LocationCostItemType
} from "../formData/EditLocationCost";
import { adornmentSx, buttonsSx } from "./EditLocationCostForm.sx";
import { AppDispatch } from "../../../types";
import { locationsApi } from "../../../api";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { isErrorObject } from "../../../utils/helper";
import { NotifyError, NotifySuccess } from "../../../utils/notification";
import { useAppDispatch } from "../../../app/hooks";
import { TableFilterFormTypes } from "../../reports/TableFilter/TableFilter.types";
import { EmptyTable } from "../../tables/EmptyTable";
import ability from "../../../permissions/ability";
import { Can } from "../../../permissions/Can";

type EditCostRequestType = ReturnType<
  typeof locationsApi.useUpdateLocationCostMutation
  >[0];
type DeleteCostRequestType = ReturnType<
  typeof locationsApi.useDeleteLocationCostMutation
  >[0];

const handleDeleteCost = async (
  intl: IntlShape,
  deleteTrigger: DeleteCostRequestType,
  businessId: number,
  locationId: number,
  itemId: number,
  dispatch: AppDispatch
) => {
  try {
    dispatch(preloaderChangeStatus(true))
    const result = await deleteTrigger({
      businessId,
      params: {
        itemId,
        locationId
      }
    }).unwrap()
    NotifySuccess(intl.formatMessage({id: 'deletedSuccessfully'}))
    return result
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error?.data?.message ?? error?.data?.error)
    }
  } finally {
    dispatch(preloaderChangeStatus(false))
  }
}

const handleEditCost = (
  intl: IntlShape,
  editTrigger: EditCostRequestType,
  dispatch: AppDispatch,
  businessId: number,
  locationId: number,
  itemId: number,
  currency: CurrencyEnum,
  values: LocationCostItemDataType
) => async () => {
  try {
    dispatch(preloaderChangeStatus(true))
    const result = await editTrigger({
      data: {
        businessId,
        currency,
        name: values.item,
        currentCost: values.cost,
        installationDate: values.date,
        serviceLife: values.serviceLife
      },
      params: {
        locationId,
        itemId
      }
    }).unwrap()
    NotifySuccess(intl.formatMessage({id: 'updatedSuccessfully'}))
    return result
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message ?? error.data.error)
    }
  } finally {
    dispatch(preloaderChangeStatus(false))
  }
}

export const EditLocationCostForm:FC<EditLocationFormProps> = ({
  onClose,
  data,
  locationId,
  businessId,
  chargersCosts
}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [deleteCost] = locationsApi.useDeleteLocationCostMutation()
  const [editCost] = locationsApi.useUpdateLocationCostMutation()
  const canManageCost = ability.can('manage', 'costLocation')

  const initialValue = {
    currency: data?.[0]?.currency ?? CurrencyEnum.EUR,
    itemsArray: data?.map(item => ({
      id: item.id,
      item: item.name,
      cost: item.currentCost,
      date: item.installationDate,
      serviceLife: item.serviceLife
    }))
  }
  
  const processedCurrencies = () => {
    return Object.values(CurrencyEnum).map((value, index) => ({
      id: index,
      name: intl.formatMessage({id: value}),
      value: value
    }))
  }
  
  return (
    <Formik
      initialValues={initialValue}
      validationSchema={editLocationCostSchema}
      onSubmit={console.log}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        setFieldValue,
        values,
      }: FormikProps<EditLocationCostFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <Grid
                container
                rowSpacing={{xs: 2}}
                columnSpacing={{xs: 0, md: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  {!!values.itemsArray.length &&
                    <AMPSwitcher
                      value={values["currency"]}
                      input={"currency"}
                      label={"currency"}
                      disabled={values.itemsArray.length > 1 || !canManageCost}
                      data={processedCurrencies()}
                      onChange={handleChange}
                    />
                  }
                </Grid>
              </Grid>
              {!values.itemsArray.length && <EmptyTable/>}
              <FieldArray name="itemsArray">
                {({ remove }) => {
                  const deleteItem = (index: number, currentItem: LocationCostItemType) => () => {
                    remove(index)
                    handleDeleteCost(
                      intl,
                      deleteCost,
                      businessId,
                      locationId,
                      currentItem.id,
                      dispatch
                    )
                  }
  
                  const handleChangeValue = (
                    setFieldValue: FormikProps<TableFilterFormTypes>['setFieldValue'], fieldName: string) =>
                    (e: ChangeEvent) =>
                    {
                      const target = e.target as HTMLInputElement
                      if(target.value){
                        setFieldValue(fieldName, target.value)
                      }
                    }
  
                  const handleChangeDateValue = (
                    setFieldValue: FormikProps<TableFilterFormTypes>['setFieldValue'], fieldName: string) =>
                    (val:  string | number | null) =>
                    {
                      if(val){
                        setFieldValue(fieldName, val)
                      }
                    }
                    
                  return (
                    <>
                      {values.itemsArray.map((it, index) => {
                        return (
                          <Grid
                            key={index}
                            container
                            rowSpacing={{xs: 2}}
                            columnSpacing={{xs: 0, md: 2}}
                          >
                            <Grid
                              xs={12}
                              lg={6}
                              item
                            >
                              <AMPInput
                                type='text'
                                value={values.itemsArray[index].item}
                                label={'item'}
                                input={`itemsArray[${index}].item`}
                                placeholder='item'
                                disabled={!canManageCost}
                                onChange={handleChangeValue(setFieldValue, `itemsArray[${index}].item`)}
                                onBlur={handleBlur}
                                hasError={getIn(touched, `itemsArray[${index}].item`) && Boolean(getIn(errors, `itemsArray[${index}].item`))}
                                helperText={getHelperTextWithArray('itemsArray', index, 'item', touched, errors, intl)}
                              />
                            </Grid>
                            <Grid
                              xs={12}
                              lg={6}
                              item
                            >
                              <AMPDatePicker
                                label="dateOfInstallation"
                                disabled={!canManageCost}
                                value={dayjs( values.itemsArray[index].date).valueOf()}
                                onChange={handleChangeDateValue(setFieldValue, `itemsArray[${index}].date`)}
                              />
                            </Grid>
                            <Grid
                              xs={12}
                              lg={6}
                              item
                            >
                              <AMPInput
                                type='number'
                                value={values.itemsArray[index].cost}
                                label={'cost'}
                                placeholder="cost"
                                input={`itemsArray[${index}].cost`}
                                disabled={!canManageCost}
                                onChange={handleChangeValue(setFieldValue, `itemsArray[${index}].cost`)}
                                onBlur={handleBlur}
                                hasError={getIn(touched, `itemsArray[${index}].cost`) && Boolean(getIn(errors, `itemsArray[${index}].cost`))}
                                helperText={getHelperTextWithArray('itemsArray', index, 'cost', touched, errors, intl)}
                              />
                            </Grid>
                            <Grid
                              xs={12}
                              lg={6}
                              item
                            >
                              <AMPInput
                                type='number'
                                value={values.itemsArray[index].serviceLife}
                                label={'serviceLife'}
                                placeholder='serviceLife'
                                endAdornment={
                                  <Typography sx={adornmentSx}>
                                    <FormattedMessage id="years" />
                                  </Typography>
                                }
                                input={`itemsArray[${index}].serviceLife`}
                                disabled={!canManageCost}
                                onChange={handleChangeValue(setFieldValue, `itemsArray[${index}].serviceLife`)}
                                onBlur={handleBlur}
                                hasError={getIn(touched, `itemsArray[${index}].serviceLife`) && Boolean(getIn(errors, `itemsArray[${index}].serviceLife`))}
                                helperText={getHelperTextWithArray('itemsArray', index, 'serviceLife', touched, errors, intl)}
                              />
                            </Grid>
                            <Grid
                              xs={12}
                              item
                            >
                              <Box sx={buttonsSx}>
                                <Can
                                  I="manage"
                                  a="costLocation"
                                >
                                  <Button
                                    color="error"
                                    variant="outlined"
                                    onClick={deleteItem(index, it)}
                                  >
                                    <FormattedMessage id="deleteItem" />
                                  </Button>
                                </Can>
                                <Can
                                  I="manage"
                                  a="costLocation"
                                >
                                  <Button
                                    variant="outlined"
                                    onClick={handleEditCost(
                                      intl,
                                      editCost,
                                      dispatch,
                                      businessId,
                                      locationId,
                                      it.id,
                                      values['currency'],
                                      {
                                        item: values.itemsArray[index].item,
                                        cost: values.itemsArray[index].cost,
                                        date: values.itemsArray[index].date,
                                        serviceLife: values.itemsArray[index].serviceLife
                                      }
                                    )}
                                  >
                                    <FormattedMessage id="update" />
                                  </Button>
                                </Can>
                              </Box>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </>
                  )
                }}
              </FieldArray>
              <FieldArray name="chargersArray">
                {() => {
                  return (
                    <>
                      {chargersCosts.map((it, index) => {
                        return (
                          <Grid
                            key={index}
                            container
                            rowSpacing={{xs: 2}}
                            columnSpacing={{xs: 0, md: 2}}
                          >
                            <Grid
                              xs={12}
                              item
                            >
                              <Typography
                                variant='h4'
                                pt={4}
                              >
                                <FormattedMessage id="chargers"/>
                              </Typography>
                            </Grid>
                            <Grid
                              xs={12}
                              lg={6}
                              item
                            >
                              <AMPInput
                                type='text'
                                value={it.name}
                                input='item'
                                label='item'
                                placeholder='item'
                                disabled
                              />
                            </Grid>
                            <Grid
                              xs={12}
                              lg={6}
                              item
                            >
                              <AMPDatePicker
                                label="dateOfInstallation"
                                value={dayjs( it.installationDate).valueOf()}
                                onChange={console.log}
                                disabled
                              />
                            </Grid>
                            <Grid
                              xs={12}
                              lg={6}
                              item
                            >
                              <AMPInput
                                type='number'
                                value={it.currentCost}
                                label={'cost'}
                                placeholder="cost"
                                input={`cost`}
                                disabled
                              />
                            </Grid>
                            <Grid
                              xs={12}
                              lg={6}
                              item
                            >
                              <AMPInput
                                type='number'
                                value={it.serviceLife}
                                label={'serviceLife'}
                                placeholder='serviceLife'
                                disabled
                                endAdornment={
                                  <Typography sx={adornmentSx}>
                                    <FormattedMessage id="years" />
                                  </Typography>
                                }
                                input={`serviceLife`}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    </>
                  )
                }}
              </FieldArray>
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={onClose}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
