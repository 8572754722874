import theme from "../../theme";

export const reportsSx = {
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 4,
    [theme.breakpoints.down('lg')]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start"
    },
  },
  selectBox: {
    width: theme.spacing(200/8)
  }
}
