import { createTheme } from "@mui/material";
import { createBreakpoints, lineHeight } from "@mui/system";

interface ShortColorsList {
  50?: string;
  100?: string;
  200?: string;
  300?: string;
  400?: string;
  500?: string;
  600?: string;
  700?: string;
  800?: string;
  900?: string;
  A50?: string;
  A100?: string;
  A300?: string;
}

declare module '@mui/material' {
  interface Palette {
    brand: Palette['primary'];
    bg: Palette['primary'];
    off: Palette['primary'];
    neutral: Palette['grey'];
    alternative: Partial<ShortColorsList>;
    primaryColor: Partial<ShortColorsList>;
    secondaryColor: Partial<ShortColorsList>;
  }
  interface PaletteOptions {
    brand: PaletteOptions['primary'];
    bg: PaletteOptions['primary'];
    off: PaletteOptions['primary'];
    neutral: PaletteOptions['grey'];
    alternative: ShortColorsList;
    primaryColor: ShortColorsList;
    secondaryColor: ShortColorsList;
  }
}
declare module '@mui/material/Box' {
  interface ButtonPropsColorOverrides {
    primaryColor: true;
    neutral: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    brand: true;
    text: true;
  }
}
declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    brand: true;
    off: true;
    neutral: true;
    success: true;
  }
}

const breakpoints = createBreakpoints({});

const theme = createTheme({
  palette: {
    primaryColor: {
      50: '#DEF0FC',
      100: '#AFDAF8',
      300: '#4CAEF1',
      600: '#006DC7',
      700: '#271FE0',
      A50: '#F0F9FF',
      A100: '#D9F0FF',
      A300: '#52BAFF',
    },
    primary: {
      main: '#007fdc',
      light: '#4caef1',
      dark: '#006dc7',
    },
    secondary: {
      main: '#28419d',
      light: '#697cc0',
      dark: '#203990',
    },
    error: {
      main: '#e62930',
      dark: '#d2212b',
      light: '#e56468',
    },
    warning: {
      main: '#ff7d22',
      light: '#FFDBAD',
      dark: '#f96d1e',
    },
    info: {
      main: '#2196f3',
    },
    success: {
      main: '#399646',
      light: '#C0E3C4',
      dark: '#2F833C'
    },
    text: {
      primary: '#32324D',
      secondary: '#fff',
    },
    grey: {
      500: "#79798D"
    },
    off: {
      main:"#DBDBE6",
      light:"#a7a7a7",
    },
    brand: {
      main: '#D92156',
      light: '#FCB3C7',
      dark: '#BD1949',
    },
    bg: {
      main: '#f7f7f7',
      dark: '#212134'
    },
    background: {
      default: '#fff',
      paper: '#F5F5F7'
    },
    secondaryColor: {
      50: '#E4E7F4',
      100: '#BCC3E4',
      300: '#697CC0',
      600: '#28419D',
      700: '#203990',
      A50: '#F0F0FF',
      A100: '#D9D8FF',
      A300: '#8784F0',
    },
    alternative: {
      50: '#F1E1F2',
      100: '#DDB5E1',
      300: '#B25CBC',
      600: '#821F9B',
      700: '#6F1C93',
      A50: '#FEF0FF',
      A100: '#FCD9FF',
      A300: '#DE75EB',
    },
    neutral: {
      50: '#F5F5F7',
      100: '#EDEDF2',
      200: '#DFDFE8',
      300: '#DBDBE6',
      400: '#B3B3C9',
      500: '#9090B0',
      600: '#666686',
      700: '#525272',
      800: '#363656',
      900: '#1C1C3B',
      A100: '#1C1C3B',
      A200: '#1C1C3B',
      A400: '#1C1C3B',
      A700: '#1C1C3B'
    }
  },
  typography: {
    fontFamily: ["Poppins", 'sans-serif'].join(','),
    h1: {
      fontSize: '2.625rem',
      fontWeight: 700,
      lineHeight: 2.5,
      [breakpoints.down('lg')]: {
        fontSize: '2.225rem', 
        lineHeight: 1.8
      },
      [breakpoints.down('md')]: {
        fontSize: '1.875rem', 
        lineHeight: 1.8
      },
      [breakpoints.down('sm')]: {
        fontSize: '1.5rem', 
        lineHeight: 1.8
      },
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 2.5,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: 2.5,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHesubtitleWrapperight: 1.375,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.25,
    },
    subtitle1: {
      fontWeight: 700,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '0.75rem',
      lineHeight: 1.5,
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1,
    },
    button: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1,
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1,
    }
  }
});

theme.components= {
  MuiOutlinedInput:{
    styleOverrides: {
      root:{
        borderRadius: 8,
        width: '100%'
      }
    }
  },
  MuiButton:{
    styleOverrides:{
      root:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px 20px',
        textTransform: 'none'
      }
    }
  },
  MuiFormLabel:{
    styleOverrides:{
      root:{
        color: theme.palette.text.primary,
        fontWeight: 700,
        fontSize: '1rem',
        marginBottom: 5,
        display: 'block'
      }
    }
  },
  MuiCheckbox:{
    styleOverrides:{
      root:{
        color: theme.palette.grey[500]
      }
    }
  },
  MuiFormHelperText: {
    styleOverrides:{
      root:{
        color: theme.palette.error.main,
        marginLeft: 0,
        marginTop: 5
      }
    }
  },
  MuiStepConnector: {
    styleOverrides: {
      line: {
        minHeight: 60
      },
    }
  },
  MuiStepLabel: {
    styleOverrides: {
      root:{
        padding: 0,
      }
    }
  },
  MuiIconButton:{
    styleOverrides:{
      root: {
        background: theme.palette.neutral[100],
        borderRadius: '50%',
        '& .MuiSvgIcon-root' :{
          fill: theme.palette.neutral[500]
        }
      },
    }
  },
  MuiDivider: {
    styleOverrides: {
      light: {
        borderColor: theme.palette.neutral[800]
      }
    }
  },
  MuiTypography: {
    styleOverrides: {
      h2: {
        color: theme.palette.neutral[800]
      },
    }
  },
  // MuiGrid: {
  //   styleOverrides: {
  //     root: {

  //       '&>.MuiGrid-item': {
  //         paddingLeft: 0,
  //         paddingTop: 0
  //       }
  //     }
  //   }
  // }
}

export default theme
