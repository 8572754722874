import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const connectorWrapperSx: SxProps<Theme> = {
  padding: theme.spacing(5/8),
  borderRadius: theme.spacing(8/8),
  borderWidth: theme.spacing(1/8),
  borderColor: theme.palette.neutral[100],
  borderStyle: 'solid',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(10/8),
  width: '100%'
}

export const connectorLineSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
}

export const infoWrapperSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(8/8)
}

export const connectorInnerSx: SxProps<Theme> = {
  width: '100%',
  padding: theme.spacing(10/8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: theme.spacing(8/8)
}

export const dotSx: SxProps<Theme> = {
  color: theme.palette.neutral[100]
}

export const bodySx: SxProps<Theme> = {
  color: theme.palette.neutral[700]
}
