import * as Yup from "yup";
import { CurrencyEnum, LanguageEnum } from "../../../../enums";

export interface RfidTermsFormType {
  rfidTerms: string,
  language: LanguageEnum,
  currency: CurrencyEnum,
  price: number
}

export const rfidTermsFormSchema = Yup.object().shape({
  rfidTerms: Yup.string()
    .required('schemaRequiredRfidTerms'),
  language: Yup.string().required(),
  currency: Yup.string(),
  price: Yup.number().required('schemaRequiredPrice')
});
