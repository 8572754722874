import { Box, Button, Divider, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { FC, FormEvent } from "react";
import { FormattedMessage } from "react-intl";
import { AMPInput } from "src/components/common/AMPInput";
import { CancelButton } from "src/components/common/CancelButton";
import { fieldsWrapperSx, actionsWrapperSx, buttonSx, closeButtonSx } from "./ChangeIntValue.sx";
import { ChangeIntValueType } from "./ChangeIntValue.types";

export const ChangeIntValue: FC<ChangeIntValueType> = ({ name, handleClose, ...rest }) => {
  const [newValue, setNewValue] = useState<number>(0)

  useEffect(() => {
    setNewValue(rest.value)
  }, [])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    rest.onChange(newValue)
  }

  const handleChange = (e: ChangeEvent<any>) => {
    setNewValue(e.target.value)
  }

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
    >
      <Box sx={fieldsWrapperSx}>
        <AMPInput 
          value={newValue}
          type={'number'}
          input={'value'}
          onChange={handleChange}
        />
      </Box>
      <Divider />
      <Box sx={actionsWrapperSx}>
        <Button
          variant="outlined"
          sx={closeButtonSx}
          onClick={handleClose}
        >
          <Typography
            variant="button"
            color="neutral.700"
          >
            <FormattedMessage id="cancel" />
          </Typography>
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={buttonSx}
        >
          <Typography
            variant="button"
          >
            <FormattedMessage id="save" />
          </Typography>
        </Button>
      </Box>
    </form>
  )
}
