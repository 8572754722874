import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { SubmitButton } from "src/components/common/SubmitButton";
import theme from "src/theme";
import { AppRouteSpace, AppRouteEnum } from "src/utils/helper";
import { wrapperSx } from "./404.sx";
import { page404 } from "../../utils/consts";

export const ErrorPage: FC = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  return (
    <Box sx={wrapperSx}>
      <img
        src={page404}
        alt={intl.formatMessage({ id: "error404" })}
      />
      <Typography variant="h4">
        <FormattedMessage id="somethingWrong" />
      </Typography>
      <Typography variant="subtitle2">
        <FormattedMessage id="alreadyWorking" />
      </Typography>
      <Box
        sx={{
          marginTop: theme.spacing(10/8)
        }}
      >
        <SubmitButton
          text="goBack"
          align="center"
          handler={() => navigate('/')}
        />
      </Box>
    </Box>
  )
}
