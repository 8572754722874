import { FC } from "react";
import theme from "src/theme";

export const UserIcon: FC<{size?: number, color?: string}> = ({size = 17, color = theme.palette.neutral[400]}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3658 15.1663H3.20703V13.8965C3.20703 13.0545 3.5415 12.2471 4.13685 11.6517C4.7322 11.0564 5.53968 10.7219 6.38163 10.7219H10.1912C11.0331 10.7219 11.8406 11.0564 12.4359 11.6517C13.0313 12.2471 13.3658 13.0545 13.3658 13.8965V15.1663ZM8.2864 9.45206C7.78612 9.45206 7.29075 9.35352 6.82855 9.16207C6.36636 8.97063 5.9464 8.69002 5.59266 8.33627C5.23891 7.98252 4.9583 7.56257 4.76686 7.10037C4.57541 6.63818 4.47687 6.14281 4.47687 5.64253C4.47687 5.14226 4.57541 4.64688 4.76686 4.18469C4.9583 3.7225 5.23891 3.30254 5.59266 2.94879C5.9464 2.59504 6.36636 2.31444 6.82855 2.12299C7.29075 1.93154 7.78612 1.83301 8.2864 1.83301C9.29675 1.83301 10.2657 2.23437 10.9801 2.94879C11.6946 3.66322 12.0959 4.63218 12.0959 5.64253C12.0959 6.65288 11.6946 7.62185 10.9801 8.33627C10.2657 9.0507 9.29675 9.45206 8.2864 9.45206Z"
      fill={color}
    />
  </svg>
)
