export enum ChargePointStatus {
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE"
}

export enum ConnectorStatusEnum {
  AVAILABLE = "AVAILABLE",
  CHARGING = "CHARGING",
  FAULTED = "FAULTED",
  FINISHING = "FINISHING",
  PREPARING = "PREPARING",
  RESERVED = "RESERVED",
  SUSPENDED_EV = "SUSPENDED_EV",
  SUSPENDED_EVSE = "SUSPENDED_EVSE", 
  UNAVAILABLE = "UNAVAILABLE",
  UNDEFINED = "UNDEFINED"
}

export enum ChargerSortFieldEnum {
  BUSINESS_ID = "BUSINESS_ID",
  CHARGE_POINT_STATUS = "CHARGE_POINT_STATUS",
  CONNECTOR_POINT_STATUS = "CONNECTOR_POINT_STATUS",
  LOCATION_ID = "LOCATION_ID",
  CREATED_AT = "CREATED_AT"
}

export enum ChargingStatusEnum {
  STARTED = "STARTED",
  PROCESSING = "PROCESSING",
  STOPPED = "STOPPED",
}

export enum ConfigurationStatusEnum {
  ACCEPTED = "ACCEPTED",
  NOT_SUPPORTED = "NOT_SUPPORTED",
  REBOOT_REQUIRED = "REBOOT_REQUIRED",
  REJECTED = "REJECTED",
}

export enum WeekEnum {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY"
}
export enum WeekEnumValue {
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
  SUNDAY = "Sunday"
}

export enum WeekEnumShortValue {
  MONDAY = "Mon",
  TUESDAY = "Tue",
  WEDNESDAY = "Wed",
  THURSDAY = "Thu",
  FRIDAY = "Fri",
  SATURDAY = "Sat",
  SUNDAY = "Sun"
}

export enum ScheduleTypeEnum {
  CYCLE = "CYCLE",
  ONE_TIME = "ONE_TIME"
}

export enum ConfigurationsGroupEnum {
  CORE_PROFILE = "CORE_PROFILEs",
  LOCAL_AUTH_LIST_MANAGEMENT_PROFILE = "LOCAL_AUTH_LIST_MANAGEMENT_PROFILEs",
  RESERVATION_PROFILE = "RESERVATION_PROFILEs",
  SMART_CHARGING_PROFILE = "SMART_CHARGING_PROFILEs",
  VENDOR_CONFIGURATION = "VENDOR_CONFIGURATIONs"
}
