import { SxProps } from "@mui/material";
import theme from "src/theme";

export const placeholderWrapperSx: SxProps = {
  position: 'relative',
  width: '100%'
}

export const containerSx: SxProps = {
  mt: 2,
  [theme.breakpoints.down('lg')]: {
    mt: 0.5
  }
}

export const placeholderSx: SxProps = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  padding: theme.spacing(12/8, 14/8),
  color: theme.palette.neutral[300],
  display: 'flex',
  alignItems: 'center'
}

export const descriptionSx: SxProps = {
  fontSize: '0.75rem',
  color: theme.palette.neutral[600],
  py: 1
}

export const optionBtnSx:SxProps = {
  width: '100%',
  p: 0,
  justifyContent: "flex-start"
}
