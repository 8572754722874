import { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { FormattedMessage } from "react-intl";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useStyles } from "../../common/CancelButton/CancelButton.styles";
import { businessesApi, locationsApi, usersApi } from "../../../api";
import { BaseLocationInfo, BaseUserInfo, BusinessDTO } from "../../../types";
import { AMPAutocomplete } from "../../common/AMPAutocomplete";
import { SubmitButton } from "../../common/SubmitButton";
import { Can } from "../../../permissions/Can";
import { TableFilterFormTypes, TableFilterProps } from "./TableFilter.types";
import { tableFilterSx } from "./TableFilter.sx";
import ability from "src/permissions/ability";
import { AMPAutocompleteProps } from "src/components/common/AMPAutocomplete/AMPAutocomplete.types";
import { findAutocompleteValueById, handleScroll } from "src/utils/helper";
import { useAppSelector } from "../../../app/hooks";
import { RoleEnum } from "../../../enums";
import { getBusinessesData, handleGetLocations, handleGetUsers } from "../../forms/GenerateReportForm";

export const TableFilter:FC<TableFilterProps> = ({
  businessId,
  handleFilter,
  closePopup,
  clearFilter,
  initData,
  filterStatus
}) => {
  const classes = useStyles()
  const { authInfo } = useAppSelector(state => state.common)
  const pageSizeBusiness = 10
  const pageSizeLocations = 10
  const pageSizeClients = 10
  const [pageBusiness, setPageBusiness] = useState(0)
  const [pageLocations, setPageLocations] = useState(0)
  const [pageClients, setPageClients] = useState(0)
  const [keyLocations, setKeyLocations] = useState('')
  const [keyClient, setKeyClient] = useState('')
  const [getBusinesses, businesses] = businessesApi.useLazyGetBusinessesQuery()
  const [getLocations, locations] = locationsApi.useLazyGetLocationsQuery()
  const [getUsers, users] = usersApi.useLazyGetUsersQuery()
  const [businessData, setBusinessData] = useState<Array<BusinessDTO>>([])
  const [locationsData, setLocationsData] = useState<Array<BaseLocationInfo>>([])
  const [clientsData, setClientsData] = useState<Array<BaseUserInfo>>([])
  const [activeBusiness, setActiveBusiness] = useState<number | undefined>(initData?.businessId)
  const [activeLocation, setActiveLocation] = useState<number | undefined>(initData?.location)
  
  useEffect(() => {
    if (authInfo.roles[0].role === RoleEnum.SUPER_ADMIN) {
      getBusinessesData(
        getBusinesses,
        pageBusiness,
        pageSizeBusiness
      )
    }
  }, [])
  
  useEffect(() => {
    if (activeBusiness) {
      handleGetLocations(
        getLocations,
        activeBusiness,
        pageLocations,
        pageSizeLocations,
        keyLocations
      )
    }
  }, [activeBusiness])
  
  useEffect(() => {
    if (activeLocation && activeBusiness) {
      handleGetUsers(
        getUsers,
        activeBusiness,
        activeLocation,
        {
          page: pageClients,
          size: pageSizeClients
        },
        keyClient
      )
    }
  }, [activeLocation])
  
  const processedBusinesses  = useMemo(() => {
    if (businessData && ability.can('read', 'businessReports')) {
      return businessData?.map(item => ({
        id: item?.id,
        label: item?.name,
        image: item?.logo?.url ?? ""
      }))
    }
    return []
  }, [businessData])
  
  const processedLocations = useMemo(() => {
    if(!locationsData.length){
      return []
    }
    return locationsData.map((location: BaseLocationInfo) => ({
      id: location.id,
      label: location.name
    }))
  }, [locationsData])
  
  const processedUsers = useMemo(() => {
    if(!clientsData.length){
      return []
    }
    return clientsData.map((user: BaseUserInfo) => ({
      id: user.id,
      label: `${user.firstName} ${user.lastName}`
    }))
  }, [clientsData])
  
  useEffect(() => {
    const content = businesses?.data?.content ?? []
    const allData = [...businessData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setBusinessData(Array.from(Object.values(allData)))
  }, [businesses?.data?.content])
  
  useEffect(() => {
    const content = locations?.data?.content ?? []
    const allData = [...locationsData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setLocationsData(Array.from(Object.values(allData)))
  }, [locations?.data?.content])
  
  useEffect(() => {
    const content = users?.data?.content ?? []
    const allData = [...clientsData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setClientsData(Array.from(Object.values(allData)))
  }, [users?.data?.content])
  
  useEffect(() => {
    getBusinessesData(
      getBusinesses,
      pageBusiness,
      pageSizeBusiness
    )
  }, [pageBusiness])
  
  useEffect(() => {
    if (activeBusiness) {
      handleGetLocations(
        getLocations,
        activeBusiness,
        pageLocations,
        pageSizeLocations,
        keyLocations
      )
    }
  }, [activeBusiness, pageLocations])
  
  useEffect(() => {
    if (activeLocation && activeBusiness) {
      handleGetUsers(
        getUsers,
        activeBusiness,
        activeLocation,
        {
          page: pageClients,
          size: pageSizeClients
        },
        keyClient
      )
    }
  }, [activeLocation, pageClients])
  
  const onSubmit = (values: TableFilterFormTypes) => {
    handleFilter(values)
    closePopup()
    filterStatus(true)
  }
  
  const handleClearFilter = () => {
    clearFilter()
    closePopup()
    filterStatus(false)
  }

  const handleChangeAutocompleteValue = (setFieldValue: FormikProps<TableFilterFormTypes>['setFieldValue'], fieldName: string): AMPAutocompleteProps['onChange'] =>
    (e, newValue) => {
      setFieldValue(fieldName, newValue?.id ?? null)
      if (fieldName === 'location') {
        setActiveLocation(newValue?.id)
      }
      if (fieldName === 'businessId') {
        setActiveBusiness(newValue?.id)
      }
      if (fieldName === 'businessId') {
        setActiveBusiness(newValue?.id)
        setActiveLocation(undefined)
        setKeyLocations('')
        setKeyClient('')
        setLocationsData([])
        setClientsData([])
      }
      if (fieldName === 'location') {
        setActiveLocation(newValue?.id)
        setKeyClient('')
        setClientsData([])
      }
    }
  
  const handleScrollBusiness = () => {
    handleScroll(
      businesses?.data?.number ?? 0,
      businesses?.data?.totalPages ?? 0,
      pageBusiness,
      setPageBusiness
    )
  }
  
  const handleScrollLocations = () => {
    handleScroll(
      locations?.data?.number ?? 0,
      locations?.data?.totalPages ?? 0,
      pageLocations,
      setPageLocations
    )
  }
  
  const handleScrollClients = () => {
    handleScroll(
      users?.data?.number ?? 0,
      users?.data?.totalPages ?? 0,
      pageClients,
      setPageClients
    )
  }
  
  const handleInputBusiness = () =>
    (event: SyntheticEvent, value: string) => {
      setPageBusiness(0)
      setPageLocations(0)
      setPageClients(0)
      getBusinessesData(
        getBusinesses,
        pageBusiness,
        pageSizeBusiness
      )
    }
  
  const handleInputLocations = () =>
    (event: SyntheticEvent, value: string) => {
      setPageLocations(0)
      setPageClients(0)
      if (activeBusiness) {
        handleGetLocations(
          getLocations,
          activeBusiness,
          pageLocations,
          pageSizeLocations,
          keyLocations
        )
      }
      setKeyLocations(value)
    }
  
  const handleInputClients = () =>
    (event: SyntheticEvent, value: string) => {
      setPageClients(0)
      if (activeLocation && activeBusiness) {
        handleGetUsers(
          getUsers,
          activeBusiness,
          activeLocation,
          {
            page: pageClients,
            size: pageSizeClients
          },
          keyClient
        )
      }
      setKeyClient(value)
    }
  
  return (
    <Formik
      initialValues={initData}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        setFieldValue,
        values,
      }: FormikProps<TableFilterFormTypes>) => (
        <Form
          onSubmit={handleSubmit}
        >
          <Box sx={tableFilterSx.formSx}>
            <Box sx={tableFilterSx.formContentSx}>
              <Can
                I="read"
                a="businessReports"
              >
                <AMPAutocomplete
                  options={businessId
                    ? [{
                      id: businessId,
                      label: ''
                    }]
                    : processedBusinesses}
                  value={findAutocompleteValueById(processedBusinesses, values['businessId'])}
                  handleScroll={handleScrollBusiness}
                  onInputChange={handleInputBusiness()}
                  label={'business'}
                  input={'businessId'}
                  showAvatar
                  onChange={handleChangeAutocompleteValue(setFieldValue, 'businessId')}
                />
              </Can>
              <AMPAutocomplete
                options={processedLocations}
                value={findAutocompleteValueById(processedLocations, values['location'])}
                label={'location'}
                input={'location'}
                handleScroll={handleScrollLocations}
                onInputChange={handleInputLocations()}
                onChange={handleChangeAutocompleteValue(setFieldValue, 'location')}
              />
              <AMPAutocomplete
                options={processedUsers}
                value={findAutocompleteValueById(processedUsers, values['user'])}
                disabled={!activeLocation}
                handleScroll={handleScrollClients}
                onInputChange={handleInputClients()}
                label={'user'}
                input={'user'}
                onChange={handleChangeAutocompleteValue(setFieldValue, 'user')}
              />
            </Box>
            <Stack sx={tableFilterSx.actions}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={handleClearFilter}
              >
                <Typography
                  variant="button"
                  color="neutral.700"
                >
                  <FormattedMessage id="clear" />
                </Typography>
              </Button>
              <SubmitButton text='apply'/>
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
