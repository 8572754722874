import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import { reportsApi } from "src/api";
import { RevenueByCitiesProps } from "./RevenueByCities.types";
import { MultipleBarChart } from "../../MultipleBarChart";
import { getBarChartData, getRevenueData } from "../RevenueReports.helpers";

export const RevenueByCities:FC<RevenueByCitiesProps> = ({
  businessId
}) => {
  const intl = useIntl()
  const revenue = intl.formatMessage({id: "revenue"})
  const [getRevenueReports, revenueReports] = reportsApi.useLazyGetRevenueReportQuery()
  const currencies: Array<string> = []
  const [dates, setDates] = useState<{from?: number; to?: number}>({
    from: undefined,
    to: dayjs().valueOf()
  })

  const handleChangeDates = (
    newFrom: number,
    newTo: number
  ) => {
    return () => {
      setDates({
        from: newFrom,
        to: newTo
      })
    }
  }

  useEffect(() => {
    getRevenueData(getRevenueReports, businessId, dates.from, dates.to)
  }, [dates]);
  
  const citiesBarChartData = getBarChartData(
    revenueReports?.data?.revenueStatisticByCurrencies,
    'revenueByCities',
    currencies,
    intl
  );
  
  const dataKeyFirst = currencies?.[0] ? `${revenue} (${currencies?.[0]})` : revenue
  const dataKeySecond = currencies?.[1] ? `${revenue} (${currencies?.[1]})` : undefined
  
  return (
    <MultipleBarChart
      title={intl.formatMessage({id: "revenueCities"})}
      data={citiesBarChartData}
      dataKeyFirst={dataKeyFirst}
      dataKeySecond={dataKeySecond}
      handleChangeDates={handleChangeDates}
      field="cities"
    />
  )
}
