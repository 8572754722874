import { SxProps, Theme } from "@mui/material";
import theme from "../../theme";

export const wrapperSx:SxProps<Theme> = {
  p: 3,
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(8/8),
  border: `1px solid ${theme.palette.neutral[300]}`,
  [theme.breakpoints.down('md')]: {
    p: 2
  }
}
