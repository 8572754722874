import './App.css';
import { Box, CircularProgress, ThemeProvider } from '@mui/material';
import theme from './theme';
import AppIntlProvider from './hocs/AppIntlProvider';
import routes from './routes';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { ConnectionChanel } from './serviсes';
import { FC, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { useStyles } from './App.sx';
import CryptoJs from 'crypto-js'
import { cryptoKey } from "src/axiosBaseQuery/interceprtors"
import { AbilityContext } from './permissions/Can';
import ability from './permissions/ability';
import ConnectionProvider from './context/ConnectionContext';
import { webSocketMessage } from './stores/common/commonSlice';
import { AppRouteEnum, AppRouteSpace } from "./utils/helper";
import { RoleEnum } from './enums';

interface AppProps {
  connect: ConnectionChanel
}

const App: FC<AppProps> = ({ connect }) => {
  const routing = useRoutes(routes());
  const location = useLocation()

  const dispatch = useAppDispatch()
  const { isLoaded, authInfo } = useAppSelector((state) => state.common)
  const styles = useStyles
  const navigate = useNavigate()
  const conditionPath =
    location.pathname === `/${AppRouteEnum.login}`
    || location.pathname === `/${AppRouteEnum.forgotPassword}`
    || location.pathname === `/${AppRouteEnum.resetPassword}`
    || location.pathname === `/${AppRouteEnum.signUp}`
    || location.pathname === `/${AppRouteEnum.verifyEmail}`
    || location.pathname === `/${AppRouteEnum.confirmRegistration}`
  const services: Array<string> = [AppRouteEnum.privacyPolicyEn, AppRouteEnum.privacyPolicyFr, AppRouteEnum.privacyPolicyDe, AppRouteEnum.termsOfUse, AppRouteEnum.termsOfUseEn, AppRouteEnum.termsOfUseFr, AppRouteEnum.termsOfUseDe, AppRouteEnum.support, AppRouteEnum.privacyPolicy, AppRouteEnum.support, AppRouteEnum.supportEn, AppRouteEnum.supportFr, AppRouteEnum.supportDe]
  const pageIsService = services.includes(location.pathname.slice(1))

  useEffect(() => {
    const localStorageRemember = localStorage.getItem('remember') ?? ''
    const localRememberValue = localStorageRemember ? JSON.parse(CryptoJs.AES.decrypt(localStorageRemember, cryptoKey.toString()).toString(CryptoJs.enc.Utf8)) : '';
    const isDataUnavailable = !Object.keys(authInfo).length || !localRememberValue
    if(!pageIsService && isDataUnavailable && !sessionStorage.getItem('remember') && conditionPath){
      navigate('/')
    }
  }, [authInfo])

  useEffect(() => {
    if(Object.keys(authInfo).length){
      connect.onSubscribe(authInfo.uuid, (mes: string) => {
        dispatch(webSocketMessage(mes))
      })
    }
    if(location.pathname === '/') {
      if(!Object.keys(authInfo).length){
        navigate(AppRouteSpace([AppRouteEnum.login]))
        return
      }
      if(authInfo.roles[0].role === RoleEnum.DRIVER){
        navigate(AppRouteSpace([AppRouteEnum.app, AppRouteEnum.welcome]))
        return 
      }
      if(authInfo.roles[0].role === RoleEnum.CLIENT_MANAGER){
        navigate(AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users]))
        return 
      }
      navigate(AppRouteSpace([AppRouteEnum.app, AppRouteEnum.locations]))
    }
  }, [location])
  return (
    <AbilityContext.Provider value={ability}>
      <ThemeProvider theme={theme}>
        <AppIntlProvider>
          <ConnectionProvider connect={connect}>
            {routing}
            { 
              isLoaded &&
              <Box sx={styles.preloaderWrapper}>
                <CircularProgress size={80} />
              </Box>
            }
            <ToastContainer />
          </ConnectionProvider>
        </AppIntlProvider>
      </ThemeProvider>
    </AbilityContext.Provider>
  );
}

export default App;
