import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import AMPModal from "src/components/common/AMPModal";
import { FeedbackForm } from "src/components/forms/FeedbackForm";
import { LogoBlack } from "src/components/icons/LogoBlack";
import { MailIcon } from "src/components/icons/MailIcon";
import { VerifyEmailIcon } from "src/components/icons/VerifyEmailIcon";
import { AppRouteEnum, AppRouteSpace } from "src/utils/helper";
import { backButtonSx, innerSx, modalButtonSx, wrapperSx } from "./VerifyEmail.sx";
import { FeedbackTypeEnum } from "../../types";

export const VerifyEmail: FC = () => {
  const navigate = useNavigate()
  const navigateToLogin = () => {
    navigate(AppRouteSpace([AppRouteEnum.login]))
  }

  const [open, setOpen] = useState<boolean>(false)
  const handleClose = () => {
    setOpen(false);

  };
  const handleOpen = () => {
    setOpen(true);
  };

  const intl = useIntl()
  const dialogTitle = intl.formatMessage({ id: 'feedback' })

  return (
    <Box sx={wrapperSx}>
      <IconButton
        sx={backButtonSx}
        onClick={navigateToLogin}
      >
        <ArrowBack />
      </IconButton>
      <LogoBlack />
      <Box sx={innerSx}>
        <VerifyEmailIcon />
        <Typography variant="subtitle1">
          <FormattedMessage id="verifyEmail" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id="verifyEmailDescription" />
        </Typography>
      </Box>
      <IconButton
        onClick={handleOpen}
        sx={modalButtonSx}  
      >
        <MailIcon />
      </IconButton>
      <AMPModal 
        handleClose={handleClose}
        open={open}
        title={dialogTitle}
        type="xs"
        fullWidth
      >
        <FeedbackForm
          type={FeedbackTypeEnum.HELP}
          handleClose={handleClose}
        />
      </AMPModal>
    </Box>
  )
}
