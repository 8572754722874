import { FilterList, Search } from "@mui/icons-material";
import {
  Box,
  debounce,
  IconButton,
  OutlinedInput,
  Popover,
  Typography
} from "@mui/material";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { businessesApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { RoleEnum } from "src/enums";
import { UsersFilterForm } from "../UsersFilterForm";
import { actionsWrapperSx, filterButtonSx, filterIconActiveSx, filterWrapperSx, formWrapperSx, searchInputSx } from "./UsersTableActions.sx";
import { UsersTableActionsType } from "./UsersTableActions.types";

export const UsersTableActions: FC<UsersTableActionsType> = ({
  handleSearch,
  handleFilter,
  isManagersRoles
}) => {
  const intl = useIntl()
  const [activeRole, setActiveRole] = useState<string>('')
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const { roles } = useAppSelector(state => state.common.authInfo)
  const [activeBusiness, setActiveBusiness] = useState<number>(0)
  const isSuperAdmin = roles[0].role === RoleEnum.SUPER_ADMIN
  const [getBusinesses, businesses] = businessesApi.useLazyGetBusinessesQuery()

  const getData = async () => {
    await getBusinesses({})
  }
  
  useEffect(() => {
    isSuperAdmin && getData()
  }, [])

  const processedBusinesses = useMemo(() => {
    if(!isSuperAdmin){
      return []
    }
    return businesses.data?.content && businesses.data.content.map((business) => ({
      id: business.id,
      value: String(business.id),
      name: business.name
    }))
  }, [businesses])

  const filterStatus = (value: boolean) => {
    setIsFilterActive(value)
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };  
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSearchFieldChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    debounce(() => handleSearch(event.target.value), 1000)()
  }
  return (
    <Box sx={actionsWrapperSx}>
      <OutlinedInput
        sx={searchInputSx}
        color="secondary"
        type="text"
        placeholder={intl.formatMessage({ id: 'usersSearchFieldPlaceholder' })}
        name="search"
        onChange={handleSearchFieldChange}
        startAdornment={(<Search />)}
      />
      <Box sx={filterWrapperSx}>
        <IconButton
          sx={filterButtonSx}
          onClick={handleClick}
        >
          {!isFilterActive && <FilterList /> }
          {isFilterActive && <FilterList sx={filterIconActiveSx} />}
          <Typography variant='body1'>
            <FormattedMessage id='filter' />
          </Typography>
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={formWrapperSx}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <UsersFilterForm
            isManagersRoles={isManagersRoles}
            businesses={processedBusinesses ?? []}
            handleFilter={handleFilter}
            isFilterActive={filterStatus}
            handleClose={handleClose}
            activeBusiness={activeBusiness}
            setActiveBusiness={setActiveBusiness}
            activeRole={activeRole}
            setActiveRole={(value: string) => setActiveRole(value)}
          />
        </Popover>
      </Box>
    </Box>
  )
}
