import { ArrowBack, ArrowForwardIos, Menu } from "@mui/icons-material";
import { Box, Typography, IconButton } from "@mui/material";
import { FC, useEffect, useMemo } from "react";
import { breadcrumbsSx, textSx, breadcrumbsWrapperSx, headerWrapperSx, separateIconSx, currentPageSx, buttonWrapperSx } from "./AMPNavbar.sx";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ability from "src/permissions/ability";
import { AMPNavbarType } from "./AMPNavbar.types";


export const AMPNavbar: FC<AMPNavbarType> = ({ handleOpenDrawer }) => {
  const location = useLocation()
  const breadcrumbs = useBreadcrumbs().slice(2)
  const currentPage = useMemo(() => breadcrumbs.length && breadcrumbs[0].key.replace(/s$/, 'Details').replace(/(\/app\/)(\w+)/, '$2'), [breadcrumbs])

  return (
    <Box sx={headerWrapperSx}>
      {(breadcrumbs.length > 1 && ability.can('manage', 'all')) && 
        <Box sx={breadcrumbsWrapperSx}>
          <Box sx={breadcrumbsSx}>
            <Link
              to={breadcrumbs[0].key}
              state={location.state}
            >
              <ArrowBack />
              <Typography
                variant="subtitle1"
                sx={textSx}
              >
                <FormattedMessage id={breadcrumbs[0].key.replace(/(\/app\/)(\w+)/, '$2')} />
              </Typography>
            </Link>
          </Box>
          <ArrowForwardIos sx={separateIconSx}/>
          <Typography
            variant="subtitle1"
            sx={[textSx, currentPageSx]}
          >
            {currentPage && <FormattedMessage id={currentPage === 'technical-team' ? 'memberProfile' : currentPage} />}
          </Typography>
        </Box>
      }
      <Box sx={buttonWrapperSx}>
        <IconButton
          aria-label="open drawer"
          onClick={handleOpenDrawer}
          edge="start"
        >
          <Menu />
        </IconButton>
      </Box>
    </Box>
  );
};
