import * as Yup from 'yup';

export interface ContractType extends Record<string, unknown>{
  phoneNumber: string,
  language: string,
  city: string,
  country: string,
  region: string,
  houseNumber: string,
  street: string,
  zipCode: string,
  useParkingAddress: boolean
}

export const contractSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('schemaRequiredPhone')
    .max(255),
  useParkingAddress: Yup.boolean(),
  city: Yup.string()
    .when(['useParkingAddress'], {
      is: false,
      then: (contractSchema) =>contractSchema.required('schemaRequiredCity')
    }),
  country: Yup.string()
    .when(['useParkingAddress'], {
      is: false,
      then: (contractSchema) =>contractSchema.required('schemaRequiredCountry')
    }),
  region: Yup.string().required(),
  houseNumber: Yup.string()
    .when(['useParkingAddress'], {
      is: false,
      then: (contractSchema) =>contractSchema.required('schemaRequiredHouseNumber')
    }),
  zipCode: Yup.string()
    .when(['useParkingAddress'], {
      is: false,
      then: (contractSchema) =>contractSchema.required('schemaRequiredZip')
    }),
  street: Yup.string()
    .when(['useParkingAddress'], {
      is: false,
      then: (contractSchema) =>contractSchema.required('schemaRequiredStreet')
    })
});
