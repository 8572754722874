import { Box } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { usersApi } from "src/api";
import { Table } from "src/components/tables/Table";
import { usersOfTariffColumnConfig } from "./ClientListInTariffsInfo.tableConfig";
import { ClientListInTariffsInfoType } from "./ClientListInTariffsInfo.types";
import { ClientTableCard } from "../ClientTableCard";
import { EmptyTable } from "../../tables/EmptyTable";
import { TableRowCounter } from "../../tables/TableRowCounter";
import { TablePagination } from "../../tables/TablePagination";
import {
  rootSx,
  tableFooterSx
} from "./ClientListInTariffsInfo.sx";
import { RoleEnum } from "src/enums";
import { ResponsiveTable } from "../../common/ResponsiveTable";

export const ClientListInTariffsInfo: FC<ClientListInTariffsInfoType> = ({
  tariffId,
  businessId
}) => {
  const [getUsers, users] = usersApi.useLazyGetUsersQuery()
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  
  useEffect(() => {
    if(!tariffId || !businessId){
      return
    }
    getUsers({
      data: {
        tariffId,
        businessId,
        roles: [RoleEnum.DRIVER]
      },
      params: {size: perPage, page: currentPage}
    })
  }, [tariffId, businessId, perPage, currentPage])
  
  const handlePerPageChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = +e.target.value
    if (typeof val === 'number') {
      setPerPage(val)
      setCurrentPage(0)
    }
  }
  
  const handleChangePage = (e: number) => {
    setCurrentPage(e)
  }
  
  return (
    <Box sx={rootSx}>
      <ResponsiveTable
        responsiveElement={
          <>
            {users?.data?.content?.map(item =>
              <ClientTableCard
                key={item?.id}
                {...item}
              />
            )}
            {!users?.data?.content?.length && <EmptyTable/>}
          </>
        }
        desktopElement={
          <>
            {users?.data?.content &&
              <Table
                sx={{
                  border: "none"
                }}
                rows={users.data.content}
                columns={usersOfTariffColumnConfig}
                hideFooter
              />
            }
          </>
        }
      />
      <Box sx={tableFooterSx}>
        <TableRowCounter
          handleChangeRowsNumber={handlePerPageChange}
          defaultValue={perPage}
        />
        <TablePagination
          totalPages={users?.data?.totalPages ?? 0}
          page={currentPage}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Box>
  )
}
