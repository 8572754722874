import { FC } from "react";
import { usersApi } from "../../../api";
import { Form, Formik, FormikProps } from "formik";
import { Box, Grid, Typography } from "@mui/material";
import { actionsWrapperSx, formContentSx, formSx } from "../UpdateRfidForm/UpdateRfidForm.sx";
import { AMPInput } from "../../common/AMPInput";
import { getHelperText } from "../../../utils/forms";
import { CancelButton } from "../../common/CancelButton";
import { SubmitButton } from "../../common/SubmitButton";
import {
  uploadConsumptionHistoryFormSchema,
  UploadConsumptionHistoryFormType
} from "../formData/uploadConsumptionHistory";
import { useIntl } from "react-intl";
import { UploadConsumptionHistoryTypes } from "./UploadConsumptionHistory.types";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { AppDispatch, HistoricalData } from "../../../types";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { NotifyError } from "../../../utils/notification";
import { suffixSx } from "./UploadConsumptionHistory.sx";
import { isErrorObject } from "../../../utils/helper";

type UploadHistoryMutationTrigger = ReturnType<
  typeof usersApi.useUploadConsumptionHistoryMutation
  >[0];

const handleUploadHistory = (
  trigger: UploadHistoryMutationTrigger,
  businessId: number,
  dispatch: AppDispatch,
  handleClose: () => void,
  getTransactions: () => void,
  userId?: number,
) =>
  async (values: UploadConsumptionHistoryFormType) => {
    if(!userId){
      return
    }
    try {
      dispatch(preloaderChangeStatus(true))
      const data: HistoricalData = {
        lastInvoicedAmount: values.lastInvoicedAmount * 100,
        totalTariffPeriodWattsConsumed: values.totalTariffPeriodWattsConsumed * 1000,
        lastInvoicedWattsConsumed: values.lastInvoicedWattsConsumed * 1000,
        businessId: businessId
      }
      return  await trigger({userId, data}).unwrap()
    } catch (error) {
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    } finally {
      dispatch(preloaderChangeStatus(false))
      handleClose()
      getTransactions()
    }
  }

export const UploadConsumptionHistoryForm:FC<UploadConsumptionHistoryTypes> = ({
  handleClose,
  userId,
  getTransactions
}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [uploadHistoryTrigger] = usersApi.useUploadConsumptionHistoryMutation()
  const {authInfo} = useAppSelector(state => state.common)
  const initialValues: UploadConsumptionHistoryFormType = {
    lastInvoicedWattsConsumed: 0,
    lastInvoicedAmount: 0,
    totalTariffPeriodWattsConsumed: 0
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={uploadConsumptionHistoryFormSchema}
      onSubmit={handleUploadHistory(
        uploadHistoryTrigger,
        authInfo.business.id,
        dispatch,
        handleClose,
        getTransactions,
        userId,
      )}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }: FormikProps<UploadConsumptionHistoryFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <Grid
                spacing={2}
                container
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    value={values["totalTariffPeriodWattsConsumed"]}
                    type={"number"}
                    label={"powerConsumed"}
                    input={"totalTariffPeriodWattsConsumed"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <Typography
                        sx={suffixSx}
                      >
                        {intl.formatMessage({
                          id: "kWh"
                        })}
                      </Typography>
                    }
                    description="powerConsumedDescription"
                    hasError={
                      touched["totalTariffPeriodWattsConsumed"] && Boolean(errors["totalTariffPeriodWattsConsumed"])
                    }
                    helperText={getHelperText("totalTariffPeriodWattsConsumed", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                spacing={2}
                container
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    value={values["lastInvoicedWattsConsumed"]}
                    type={"number"}
                    label={"lastInvoicedAmountKilowatts"}
                    input={"lastInvoicedWattsConsumed"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <Typography
                        sx={suffixSx}
                      >
                        {intl.formatMessage({
                          id: "kWh"
                        })}
                      </Typography>
                    }
                    hasError={
                      touched["lastInvoicedWattsConsumed"] && Boolean(errors["lastInvoicedWattsConsumed"])
                    }
                    helperText={getHelperText("lastInvoicedWattsConsumed", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    value={values["lastInvoicedAmount"]}
                    type={"number"}
                    label={"lastAmount"}
                    input={"lastInvoicedAmount"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["lastInvoicedAmount"] && Boolean(errors["lastInvoicedAmount"])
                    }
                    helperText={getHelperText("lastInvoicedAmount", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={handleClose}
              />
              <SubmitButton
                text="save"
                color="primary"
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
