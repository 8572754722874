import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import {
  Formik,
  FormikProps,
  Form,
  FormikConfig,
  FormikHandlers,
} from "formik";
import { FC, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { systemApi, tariffsApi } from "src/api";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch, SelectValue } from "src/types";
import { TariffDescriptionFormProps } from "./TariffDescriptionForm.types";
import { getHelperText } from "src/utils/forms";
import { AMPMarkdownEditor } from "../../common/AMPMarkdownEditor";
import {
  tariffDescriptionSchema,
  TariffDescriptionType,
} from "../formData/tariffDescription";
import {
  formSx,
  actionsWrapperSx,
  sectionTitleSx,
} from "./TariffDescriptionForm.sx";
import { isErrorObject, valuesTrimmer } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { handleGetCountries } from "../../../hooks/useGetCountries";
import { allRegionsValue } from "../../../utils/consts";
import { TariffType } from "../formData/tariff";
import { AMPFormSelect } from "../../common/AMPFormSelect";
import { AMPInput } from "../../common/AMPInput";
import { fixedDigits } from "src/utils/fixedDigits";

type UpdateTariffMutationTrigger = ReturnType<
  typeof tariffsApi.useUpdateTariffMutation
>[0];

const handleSubmit =
  (
    trigger: UpdateTariffMutationTrigger,
    dispatch: AppDispatch,
    handleClose: () => void,
    tariffId: number,
    businessId: number
  ): FormikConfig<TariffDescriptionType>["onSubmit"] =>
    async (values) => {
      const value = valuesTrimmer(values);
      const data = {
        businessId,
        description: value.description,
        descriptionFrench: value.descriptionFrench,
        descriptionDeutsch: value.descriptionGerman,
        country: value.country,
        monthlyFee: +value.monthlyFee * 100,
        region: value.region === allRegionsValue ? undefined : value.region,
        pricePerKWh: Math.round(+value.pricePerKWh * 10000),
      };
      try {
        dispatch(preloaderChangeStatus(true));
        await trigger({ data, tariffId })
          .unwrap()
          .finally(() => {
            handleClose();
          });
      } catch (error) {
        if (isErrorObject(error)) {
          NotifyError(error.data.message);
        }
      } finally {
        dispatch(preloaderChangeStatus(false));
      }
    };

export const TariffDescriptionForm: FC<TariffDescriptionFormProps> = ({
  description,
  descriptionFrench,
  descriptionGerman,
  country,
  region,
  handleClose,
  tariffId,
  businessId,
  pricePerKWh,
  monthlyFee
}) => {
  const { lang } = useAppSelector((state) => state.common);
  const [trigger] = tariffsApi.useUpdateTariffMutation();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [activeCountry, setActiveCountry] = useState(country);
  const [getCountries, countries] = systemApi.useLazyGetCountriesQuery();

  useEffect(() => {
    handleGetCountries(getCountries, dispatch);
  }, []);

  const processedRegions = useMemo(() => {
    const initialArray: SelectValue[] = [
      {
        id: 0,
        name: activeCountry?.length
          ? intl.formatMessage({ id: activeCountry.toLowerCase() })
          : "",
        value: allRegionsValue,
      },
    ];
    if (activeCountry?.length) {
      const mappedRegions: SelectValue[] | undefined = countries.data
        ?.find((country) => country.name === activeCountry)
        ?.regions?.map((region) => ({
          id: region.id,
          name: intl.formatMessage({ id: region.name }),
          value: region.name,
        }));
      return mappedRegions?.concat(initialArray[0]) ?? initialArray;
    }
    return initialArray;
  }, [activeCountry, lang, countries?.data]);

  const handleChangeCountry =
    (
      handleChange: FormikHandlers["handleChange"],
      setFieldValue: FormikProps<TariffType>["setFieldValue"]
    ) =>
      (event: SelectChangeEvent) => {
        setActiveCountry(event.target.value);
        setFieldValue("region", "");
        return handleChange(event);
      };

  const processedCountries =
    countries?.data?.map((country) => ({
      id: country.id,
      name: intl.formatMessage({ id: country.name.toLowerCase() }),
      value: country.name,
    })) ?? [];

  const initialData = useMemo(() => {
    const init: TariffDescriptionType = {
      description,
      descriptionFrench,
      descriptionGerman,
      region,
      country,
      pricePerKWh: pricePerKWh ? fixedDigits(pricePerKWh, 10000) : 0,
      monthlyFee: monthlyFee ? fixedDigits(monthlyFee, 100) : 0
    };
    return init;
  }, [description, descriptionFrench, descriptionGerman]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={tariffDescriptionSchema}
      onSubmit={handleSubmit(
        trigger,
        dispatch,
        handleClose,
        tariffId,
        businessId
      )}
    >
      {({
        errors,
        handleBlur,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        handleChange,
        touched,
        values,
      }: FormikProps<TariffDescriptionType>) => (
        <Form onSubmit={handleSubmit}
          noValidate>
          <Box sx={formSx}>
            <Grid container
              rowSpacing={2}
              columnSpacing={{ xs: 0, lg: 2 }}>
              <Grid xs={12}
                lg={6}
                item>
                <AMPFormSelect
                  data={processedCountries}
                  value={values["country"] as string}
                  label={"country"}
                  input={"country"}
                  onChange={handleChangeCountry(handleChange, setFieldValue)}
                  onBlur={handleBlur}
                  hasError={touched["country"] && Boolean(errors["country"])}
                  helperText={getHelperText("country", touched, errors, intl)}
                />
              </Grid>
              <Grid xs={12}
                lg={6}
                item>
                <AMPFormSelect
                  data={processedRegions}
                  value={values["region"] as string}
                  label={"region"}
                  input={"region"}
                  disabled={!activeCountry?.length}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched["region"] && Boolean(errors["region"])}
                  helperText={getHelperText("region", touched, errors, intl)}
                />
              </Grid>
              <Grid xs={12}
                lg={6}
                item>
                <AMPInput
                  value={values["pricePerKWh"]}
                  type={"text"}
                  label={"pricePerKWh"}
                  input={"pricePerKWh"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={
                    touched["pricePerKWh"] && Boolean(errors["pricePerKWh"])
                  }
                  helperText={getHelperText(
                    "pricePerKWh",
                    touched,
                    errors,
                    intl
                  )}
                />
              </Grid>
              <Grid xs={12}
                lg={6}
                item>
                <AMPInput
                  value={values["monthlyFee"]}
                  type={"text"}
                  label={"monthlyFee"}
                  input={"monthlyFee"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={
                    touched["monthlyFee"] && Boolean(errors["monthlyFee"])
                  }
                  helperText={getHelperText(
                    "monthlyFee",
                    touched,
                    errors,
                    intl
                  )}
                />
              </Grid>
            </Grid>
            <Typography sx={sectionTitleSx}>
              {intl.formatMessage({ id: "englishVersion" })}
            </Typography>
            <AMPMarkdownEditor
              input="description"
              value={values["description"]}
              label="tariffDescription"
              height={200}
              setValue={setFieldValue}
              onBlur={handleBlur}
              hasError={
                touched["description"] && Boolean(errors["description"])
              }
              helperText={getHelperText("description", touched, errors, intl)}
            />
            <Typography sx={sectionTitleSx}>
              {intl.formatMessage({ id: "frenchVersion" })}
            </Typography>
            <AMPMarkdownEditor
              input="descriptionFrench"
              value={values["descriptionFrench"]}
              label="tariffDescription"
              height={200}
              setValue={setFieldValue}
              onBlur={handleBlur}
              hasError={
                touched["descriptionFrench"] &&
                Boolean(errors["descriptionFrench"])
              }
              helperText={getHelperText(
                "descriptionFrench",
                touched,
                errors,
                intl
              )}
            />
            <Typography sx={sectionTitleSx}>
              {intl.formatMessage({ id: "germanVersion" })}
            </Typography>
            <AMPMarkdownEditor
              input="descriptionGerman"
              value={values["descriptionGerman"]}
              label="tariffDescription"
              height={200}
              setValue={setFieldValue}
              onBlur={handleBlur}
              hasError={
                touched["descriptionGerman"] &&
                Boolean(errors["descriptionGerman"])
              }
              helperText={getHelperText(
                "descriptionGerman",
                touched,
                errors,
                intl
              )}
            />
          </Box>
          <Box sx={actionsWrapperSx}>
            <CancelButton text="cancel"
              handler={handleClose} />
            <SubmitButton
              text={"editTariff"}
              color="primary"
              isSubmitting={isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};
