import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const actionsWrapperSx: SxProps<Theme> = {
  borderRadius: theme.spacing(4/8),
  overflow: 'hidden',
  height: '100%'
}

export const wrapperSx: SxProps<Theme> = {
  padding: theme.spacing(20/8),
  background: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  gap: theme.spacing(20/8),
  width: '100%',
  height: '100%',
  maxHeight: "180px",
  '& a': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  [theme.breakpoints.down('lg')]: {
    height: "auto"
  }
}

export const linkWrapperSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(5/8),
  color: theme.palette.text.primary
}

export const innerSx: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  alignItems: 'center'
}

export const infoWrapperSx: SxProps<Theme> = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center'
}
export const infoSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5/8)
}
export const infoValueSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(5/8)
}
