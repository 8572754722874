import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'src/axiosBaseQuery'
import { getBaseURL } from 'src/utils/helper'
import {
  NotificationByIdRequest,
  NotificationDTO,
  NotificationMarkAsReadRequest,
  NotificationMarkAsUnreadRequest,
  NotificationsPageDTO,
  NotificationsSearchRequest,
} from "../../types";

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL() + '/notifications'),
  }),
  tagTypes: ['Notifications'],
  endpoints: (build) => ({
    getNotifications: build.query<NotificationsPageDTO, NotificationsSearchRequest>({
      query: ({ data, params }) => ({ url: '/search', method: 'post', data, params }),
      providesTags: ['Notifications']
    }),
    getNotificationById: build.query<NotificationDTO, NotificationByIdRequest>({
      query: (params) => ({
        url: `/${params.notificationId}`,
        method: 'get',
        params: params.data
      }),
      providesTags: ['Notifications']
    }),
    markNotificationAsRead: build.mutation<NotificationsPageDTO, NotificationMarkAsReadRequest>({
      query: ({ notificationId, data }) => ({
        url: `/${notificationId}/read`,
        method: 'post',
        params: data
      }),
      invalidatesTags: ['Notifications']
    }),
    markNotificationAsUnread: build.mutation<NotificationsPageDTO, NotificationMarkAsUnreadRequest>({
      query: ({ notificationId, data }) => ({
        url: `/${notificationId}/unread`,
        method: 'post',
        params: data
      }),
      invalidatesTags: ['Notifications']
    }),
  }),
})
