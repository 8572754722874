import { Box, Divider, Grid } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { businessesApi, chargersApi, contractsApi, locationsApi, usersApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { store } from "src/app/store";
import { BusinessDetails } from "src/components/businesses/BusinessDetails";
import { BusinessSignature } from "src/components/businesses/BusinessSignature";
import { BusinessStatistics } from "src/components/businesses/BusinessStatistics";
import { BusinessTables } from "src/components/businesses/BusinessTables";
import { BusinessTerms } from "src/components/businesses/BusinessTerms";
import { PageHeader } from "src/components/common/PageHeader";
import { ChargerSortFieldEnum, ContractSortFieldEnum, LocationSortFieldEnum } from "src/enums";
import ability from "src/permissions/ability";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { TableParamsType } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { rootSx } from "./BusinessInfo.sx";
import { BusinessTermsRfid } from "../../components/businesses/BusinessTermsRfid";

type BusinessByIdQueryTrigger = ReturnType<typeof businessesApi.useLazyGetBusinessByIdQuery>[0];

const handleGetBusinessInfo = (trigger: BusinessByIdQueryTrigger) => (async (businessId: string | number) => {
  store.dispatch(preloaderChangeStatus(true))
  try {
    const response = await trigger({businessId}).unwrap()
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
});

export const BusinessInfo: FC = () => {
  const { activeBusiness } = useAppSelector((state) => state.common);
  const [trigger, response] = businessesApi.useLazyGetBusinessByIdQuery()

  const [getLocations, { data: locations }] = locationsApi.useLazyGetLocationsQuery()
  const [getChargers, { data: chargers }] = chargersApi.useLazyGetChargersQuery()
  const [getContracts, { data: contracts }] = contractsApi.useLazyGetContractsQuery()
  const [getUsers, { data: users }] = usersApi.useLazyGetUsersQuery()
	
  const [locationsSortValue, setLocationsSortValue] = useState({
    sortAsc: true,
    sortField: ''
  })
  const [chargersSortValue, setChargersSortValue] = useState({
    sortAsc: true,
    sortField: ''
  })
  const [contractsSortValue, setContractsSortValue] = useState({
    sortAsc: true,
    sortField: ''
  })
  
  const defaultTableSize = 10;

  const [activeTab, setActiveTab] = useState<number>(0)
  const [tableParams, setTableParams] = useState<TableParamsType>({
    page: 0,
    size: defaultTableSize
  })

  const handleGetLocations = async (businessId: number, params: TableParamsType) => {
    const data = {
      businessId,
      sortAsc: locationsSortValue.sortAsc,
      sortField: locationsSortValue.sortField || LocationSortFieldEnum.ID
    }
    await getLocations({data, params}).unwrap()
  }
  const handleGetChargers = async (businessId: number, params: TableParamsType) => {
    const data = {
      businessId,
      sortAsc: chargersSortValue.sortAsc,
      sortField: chargersSortValue.sortField || ChargerSortFieldEnum.BUSINESS_ID
    }
    await getChargers({data, params}).unwrap()
  }
  const handleGetContracts = async (businessId: number, params: TableParamsType) => {
    const data = {
      businessId,
      sortAsc: contractsSortValue.sortAsc,
      sortField: contractsSortValue.sortField || ContractSortFieldEnum.CREATED
    }
    await getContracts({data, params}).unwrap() 
  }
  const handleGetUsers = async (businessId: number, params: TableParamsType) => {
    const data = {
      businessId
    }
    await getUsers({data, params}).unwrap()
  }

  useEffect(() => {
    if(response.data){
      handleGetLocations(response.data.id, tableParams)
    }
  }, [response, locationsSortValue])

  useEffect(() => {
    if(response.data){
      handleGetChargers(response.data.id, tableParams)
    }
  }, [response, chargersSortValue])

  useEffect(() => {
    if(response.data){
      handleGetContracts(response.data.id, tableParams)
    }
  }, [response, contractsSortValue])
  
  useEffect(() => {
    if(response.data){
      handleGetUsers(response.data.id, tableParams)
    }
  }, [response])

  const permissions = ability.can('read', 'singlePages')
  const navigate = useNavigate()

  useEffect(() => {
    if(!permissions){
      return navigate(`/404`);
    }
    handleGetBusinessInfo(trigger)(activeBusiness)	
  }, [])

  const handleChangePage = (page: number) => {
    setTableParams({
      page,
      size: tableParams.size || defaultTableSize
    })
  }

  const handleChangeRowsNumber = (e: ChangeEvent<HTMLInputElement>) => {
    setTableParams({
      page: 0,
      size: +e.target.value || defaultTableSize
    })
  }

  const handleSetActiveTab = (value: number) => {
    setActiveTab(value)
    handleChangePage(0)
  }

  const handleSort = ( value: GridSortModel ) => {
    if(activeTab === 0) {
      setLocationsSortValue({
        sortAsc: value ? value[0].sort === 'asc' : true,
        sortField: (value && value[0].field) || LocationSortFieldEnum.ID
      })
    }
    if(activeTab === 1) {
      setChargersSortValue({
        sortAsc: value ? value[0].sort === 'asc' : true,
        sortField: (value && value[0].field) || ChargerSortFieldEnum.BUSINESS_ID
      })
    }
    if(activeTab === 2) {
      setContractsSortValue({
        sortAsc: value ? value[0].sort === 'asc' : true,
        sortField: (value && value[0].field) || ContractSortFieldEnum.CREATED
      })
    }
  }

  useEffect(() => {
    if(activeTab === 0) {
      response.data && handleGetLocations(response.data.id, tableParams)
    }
    if(activeTab === 1) {
      response.data && handleGetChargers(response.data.id, tableParams)
    }
    if(activeTab === 2) {
      response.data && handleGetContracts(response.data.id, tableParams)
    }
  }, [tableParams])

  return (
    <Box>
      <PageHeader
        title={`${response.data?.name}` ?? ''}
        onlyTitle
      />
      {
        response.data && <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >	
            <BusinessDetails
              data={response.data}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >	
            <BusinessStatistics
              locations={locations?.totalElements ?? 0}
              chargers={chargers?.totalElements ?? 0}
              contracts={contracts?.totalElements ?? 0}
              users={users?.totalElements ?? 0}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >	
            <BusinessTables
              handleSort={handleSort}
              locations={locations?.content ?? []}
              chargers={chargers?.content ?? []}
              contracts={contracts?.content ?? []}
              locationsTotalPage={locations?.totalPages ?? 0}
              chargersTotalPage={chargers?.totalPages ?? 0}
              contractsTotalPage={contracts?.totalPages ?? 0}
              handleChangePage={handleChangePage}
              handleChangeRowsNumber={handleChangeRowsNumber}
              handleSetActiveTab={handleSetActiveTab}
              size={tableParams.size ?? defaultTableSize}
              tab={activeTab}
              page={tableParams.page ?? 0}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >	
            <Box sx={rootSx}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={7}
                >
                  <BusinessTerms 
                    data={response.data}
                  />
                </Grid>
                <Grid
                  item
                  xs={0}
                  md={1}
                >
                  <Divider orientation="vertical" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                >
                  <BusinessSignature 
                    data={response.data}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <BusinessTermsRfid
              data={response.data}
            />
          </Grid>
        </Grid>
      }
    </Box>
  );
};
