import { FC } from "react";

export const LightningIcon: FC = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.85266 7.16699V1.16699L4.33984 9.83366H8.20797L8.20797 15.8337L12.7208 7.16699L8.85266 7.16699Z"
      fill="#B3B3C9"
    />
  </svg>
)
