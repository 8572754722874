import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React from "react";
import { FC } from "react";
import { IntlShape, useIntl } from "react-intl";
import { formControlSx, radioGroupSx } from "./ChangeBooleanValue.sx";
import { ChangeBooleanValueType } from "./ChangeBooleanValue.types";

const dataCapitalizeValue = (intl: IntlShape) => (
  [
    {
      value: 'True',
      id: 0,
      name: intl.formatMessage({id: 'enable'})
    },
    {
      value: 'False',
      id: 1,
      name: intl.formatMessage({id: 'disable'})
    },
  ]
) 
const dataValue = (intl: IntlShape) => (
  [
    {
      value: 'true',
      id: 0,
      name: intl.formatMessage({id: 'enable'})
    },
    {
      value: 'false',
      id: 1,
      name: intl.formatMessage({id: 'disable'})
    },
  ]
) 
export const ChangeBooleanValue: FC<ChangeBooleanValueType> = ({ name, ...rest }) => {
  const intl = useIntl()
  return (
    <FormControl
      fullWidth
      variant="outlined"
      margin="normal"
      sx={formControlSx}
    >
      <RadioGroup
        name={name}
        sx={radioGroupSx}
        {...rest}
      >
        { rest.value === "true" || rest.value === "false" ?
          dataValue(intl).map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.value}
              control={<Radio />}
              label={item.name}
            />
          ))
          : dataCapitalizeValue(intl).map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.value}
              control={<Radio />}
              label={item.name}
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  )
}
