import { Box } from "@mui/material"
import { FC } from "react"
import AMPModal from "../AMPModal"
import { CancelButton } from "../CancelButton"
import DropZone from "../DropZone"
import { SubmitButton } from "../SubmitButton"
import { actionsWrapperSx, dropZoneWrapperSx } from "./DropZoneModal.sx"
import { DropZoneModalType } from "./DropZoneModal.types"

export const DropZoneModal: FC<DropZoneModalType> = ({
  handleClose,
  open,
  title,
  handleFile,
  handleSubmit,
  submitIsBlocked = false,
  isImage = false,
  submitButtonText = 'submit'
}) => {
  return (
    <AMPModal
      handleClose={handleClose}
      open={open}
      title={title}
      type="md"
      fullWidth
      withoutPaddings
    >
      <Box sx={dropZoneWrapperSx}>
        <DropZone
          handleFile={handleFile}
          isImage={isImage}
        />
      </Box>
      <Box sx={actionsWrapperSx}>
        <CancelButton
          text="cancel"
          handler={handleClose}
        />
        <SubmitButton
          text={submitButtonText}
          handler={handleSubmit}
          isSubmitting={submitIsBlocked}
        />
      </Box>
    </AMPModal>
  )
}
