import { Box, capitalize, IconButton, Popover, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { EditIcon } from "src/components/icons/EditIcon";
import { ConfigurationForm } from "../ConfigurationForm";
import { editButtonSx, headerSx, valueSx, wrapperSx, actionsSx, formWrapperSx, titleSx } from "./ConfigurationsItem.sx";
import { ConfigurationsItemType } from "./ConfigurationsItem.types";

export const ConfigurationsItem: FC<ConfigurationsItemType> = ({data}) => {

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = useState<string | boolean | number>('')
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };  
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if(+data.value){
      setValue(+data.value)
      return
    }
    setValue(data.value)
  }, [data])

  return (
    <Box sx={wrapperSx}>
      <Box sx={headerSx}>
        <Typography
          sx={titleSx}
          variant="subtitle1"
        >
          {data.key}
        </Typography>
        <Box sx={actionsSx}>
          <Typography
            variant="body1"
            sx={valueSx(capitalize(data.value))}
          >
            {capitalize(data.value) === 'True' && <FormattedMessage id='enable' />}
            {capitalize(data.value) === 'False' && <FormattedMessage id='disable' />}
            {capitalize(data.value) !== 'False' && capitalize(data.value) !== 'True' && value}
          </Typography>
          {!data.readonly && <IconButton
            sx={editButtonSx}
            onClick={handleOpen}
          >
            <EditIcon />
          </IconButton>}
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={formWrapperSx}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ConfigurationForm
              name={data.key}
              configValue={data.value}
              handleClose={handleClose}
              chargerId={data.chargerId}
            />
          </Popover>
        </Box>
      </Box>
      <Typography variant="body1">
        {data.description}
      </Typography>
    </Box>
  )
}
