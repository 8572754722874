import * as Yup from 'yup';

export interface TariffType extends Record<string, unknown>{
  name: string,
  country: string,
  region: string,
  nameFrench: string,
  nameDeutsch: string,
  description: string,
  descriptionFrench: string,
  descriptionDeutsch: string,
  currency: string,
  annualWhLimit: number,
  pricePerKWh: number,
  monthlyFee: number,
  contractPeriodLimit: number
  businessId: number
}

export const tariffSchema = Yup.object().shape({
  businessId: Yup.string()
    .required('schemaRequiredBusiness')
    .max(255),
  country: Yup.string().required('schemaRequiredCountry'),
  region: Yup.string(),
  name: Yup.string()
    .required('schemaRequiredTariffName')
    .max(255),
  nameFrench: Yup.string()
    .required('schemaRequiredTariffName')
    .max(255),
  nameDeutsch: Yup.string()
    .required('schemaRequiredTariffName')
    .max(255),
  descriptionFrench: Yup.string()
    .required('schemaRequiredTariffDescription'),
  descriptionDeutsch: Yup.string()
    .required('schemaRequiredTariffDescription'),
  description: Yup.string()
    .required('schemaRequiredTariffDescription'),
  annualWhLimit: Yup.string()
    .required('schemaRequiredannualWhLimit'),
  pricePerKWh: Yup.string()
    .required('schemaRequiredPricePerMonth'),
  monthlyFee: Yup.string()
    .required('schemaRequiredMonthlyFee'),
});
