import { Box, Button, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PageHeaderProps } from "./PageHeader.types";
import { actionButtonSx, pageHeaderWrapperSx, titleSx } from "./PageHeader.sx";

export const PageHeader: FC<PageHeaderProps> = ({ title, onClick, buttonText, onlyTitle, children }) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  useEffect(() => {
    if(title !== 'undefined undefined' && title !== 'undefined'){ // TODO: fix temporary condition
      setPageTitle(title)
    }
  }, [title])
  return (
    <Box sx={{
      ...pageHeaderWrapperSx,
      justifyContent: () => children ? 'flex-start' : 'space-between',
    }}>
      <Typography
        sx={titleSx}
        variant="h2"
      >
        {pageTitle}
      </Typography>
      {children}
      {!onlyTitle && <Button
        type="submit"
        color="brand"
        variant="contained"
        sx={actionButtonSx}
        onClick={onClick}
      >
        <Typography
          variant="button"
          color="textSecondary"
        >
          {buttonText && <FormattedMessage id={buttonText} />}
        </Typography>
      </Button> }
    </Box>
  )
}
