import { GridColumns } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { ColorField } from 'src/components/common/ColorField';
import { UsersSortFieldEnum } from 'src/enums';
import dayjs from "dayjs";
import { UserCell } from 'src/components/users/UserCell';
import { DeleteUser } from "../../users/DeleteUser";

export const columnConfigUsers: GridColumns = [
  {
    field: 'name',
    renderHeader: (params) => <FormattedMessage id={"memberNameColumnHeader"} />,
    flex: 2,
    minWidth: 200,
    sortable: false,
    cellClassName: 'ampTableCell',
    renderCell: (params) => (
      <UserCell data={params.row} />
    )
  },
  {
    field: UsersSortFieldEnum.ROLE,
    renderHeader: (params) => <FormattedMessage id={"memberRoleColumnHeader"} />,
    flex: 2,
    minWidth: 120,
    cellClassName: 'ampTableCell',
    renderCell: (params) => params.row.roles.length ? <ColorField label={params.row.roles[0].role} /> : ''
  },
  {
    field: UsersSortFieldEnum.EMAIL,
    renderHeader: (params) => <FormattedMessage id={"emailColumnHeader"} />,
    flex: 4,
    minWidth: 300,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.emailAddress
  },
  {
    field: UsersSortFieldEnum.CREATED,
    renderHeader: (params) => <FormattedMessage id={"createdDateColumnHeader"} />,
    flex: 2,
    minWidth: 100,
    cellClassName: 'ampTableCell',
    valueGetter: (params) =>  dayjs(params.row.created).format("DD/MM/YY")
  },
  {
    field: 'lastLogin',
    renderHeader: (params) => <FormattedMessage id={"lastLoginColumnHeader"} />,
    flex: 2,
    minWidth: 100,
    sortable: false,
    cellClassName: 'ampTableCell',
    valueGetter: (params) =>  params.row.lastLogin ? dayjs(params.row.lastLogin).format("DD/MM/YY") : '-'

  },
  {
    field: 'delete',
    flex: 1,
    headerName: '',
    sortable: false,
    cellClassName: 'ampTableCell hoverableCell',
    renderCell: (params) => (
      params.row.business && <DeleteUser row={params.row}/>
    )
  }
]
