import { UserDTO } from "../../../types";

export type RfidTableProps = {
		data?: UserDTO
}

export enum BlockStatusEnum {
		accepted = "accepted",
		blocked = "blocked"
}

export enum ValidityEnum {
		expired = "expired",
		actual = "actual"
}
