import { FC } from "react";
import { Box, Grid } from "@mui/material";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { TariffCard } from "../TariffCard";
import { ListOfTariffsType } from "./ListOfTariffs.types";
import { actionsWrapperSx, actionsActiveButtonsSx, showLgSx, wrapperSx } from "./ListOfTariffs.sx";
import { useGetUserLanguage } from "../../../hooks/useGetUserLanguage";

export const ListOfTariffs: FC<ListOfTariffsType> = ({
  content,
  activeItem = 0,
  handleSelectCard,
  disableSubmit,
  changeStep,
  handleClose,
  submitTitle,
  handleBack
}) => {
  const [currentLanguage] = useGetUserLanguage()
  
  const handleSelect = (id: number) => {
    handleSelectCard(+id)
  }
  
  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{xs: 0, md: 2}}
        sx={wrapperSx}
      >
        { content.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            lg={6}
          >
            <TariffCard
              language={currentLanguage}
              data={item}
              handleSelectCard={(id: number) => handleSelect(+id)}
              selected={activeItem === item.id}
              radioButton
            />
          </Grid>
        )) }
      </Grid>
      <Box sx={actionsWrapperSx}>
        <CancelButton
          text="cancel"
          handler={handleClose}
        />
        <Box sx={actionsActiveButtonsSx}>
          {handleBack &&
            <Box sx={showLgSx}>
              <CancelButton
                text="back"
                color="primary"
                handler={handleBack}
              />
            </Box>
          }
          <SubmitButton
            text={submitTitle}
            color="primary"
            handler={changeStep}
            isSubmitting={disableSubmit}
          />
        </Box>
      </Box>
    </>
  )
}
