import { Box } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { businessesApi, tariffsApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { store } from "src/app/store";
import AMPModal from "src/components/common/AMPModal";
import { PageHeader } from "src/components/common/PageHeader";
import { TariffForm } from "src/components/forms/TariffForm";
import { TariffsTable } from "src/components/tariffs/TariffsTable";
import { RoleEnum, TariffSortValuesEnum } from "src/enums";
import ability from "src/permissions/ability";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { DataType, ListParamsType, ReactLocationStateType, TariffSearchRequest } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type TariffsQueryTrigger = ReturnType<
  typeof tariffsApi.useLazyGetTariffsQuery
>[0];

const handleGetTariffs = (trigger: TariffsQueryTrigger, businessId?: number) => (async (tableData: TariffSearchRequest, params: ListParamsType) => {
  store.dispatch(preloaderChangeStatus(true))

  const newData: DataType = {
    ...tableData,
    businessId: tableData.businessId ? tableData.businessId : businessId
  }
  Object.keys(tableData).map((item: string) => {
    !newData[item] && delete newData[item]
  })

  if(!tableData.sortField){
    tableData.sortField = TariffSortValuesEnum.NAME
  }

  const data: TariffSearchRequest = {
    ...newData,
  }
  try {
    const response = await trigger({ data, params }).unwrap()
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
});

type BusinessesQueryTrigger = ReturnType<typeof businessesApi.useLazyGetBusinessesQuery>[0];

const getBusinessesData = async (trigger: BusinessesQueryTrigger) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    await trigger({
      params: {
        size: 2000
      }
    })
  } catch (e) {
    console.log(e)
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

export const Tariffs: FC = () => {
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const dialogTitle = intl.formatMessage({ id: "createNewTariff" });
  const { authInfo } = useAppSelector((state) => state.common);

  const [getBusinesses, businesses] = businessesApi.useLazyGetBusinessesQuery()
  const [trigger, response] = tariffsApi.useLazyGetTariffsQuery();
  const isSuperAdmin = authInfo.roles[0].role === RoleEnum.SUPER_ADMIN

  const [tableParams, setTableParams] = useState<ListParamsType>({
    page: 0,
    size: 100,
  });
  const [filterParams, setFilterParams] = useState<TariffSearchRequest>({
    businessId: 0,
    keyword: '',
    sortAsc: true,
    sortField: ''
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate()

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    callHandler(filterParams, tableParams);
  };

  const handleChange = (value: TariffSearchRequest) => {
    setFilterParams(value);
    setTableParams({
      page: 0,
      size: tableParams.size
    })
    setSearchParams({})
    Object.keys(authInfo) && callHandler(value, {
      page: 0,
      size: tableParams.size
    })
  };

  const handleChangePage = (page: number) => {
    Object.keys(authInfo).length &&
      callHandler(filterParams, {
        page,
        size: tableParams.size,
      });
    setTableParams({
      page,
      size: tableParams.size,
    });
    setSearchParams({
      page: String(page)
    })
  };

  const handleChangeRowsNumber = (e: ChangeEvent<HTMLInputElement>) => {
    Object.keys(authInfo).length &&
      callHandler(filterParams, {
        page: 0,
        size: +e.target.value || 100,
      });
    setTableParams({
      page: 0,
      size: +e.target.value || 100,
    });
  };

  const callHandler = handleGetTariffs(trigger, authInfo.business && +authInfo.business.id);

  const manageTariffs = ability.can('manage', 'tariff')
  const readTariffs = ability.can('read', 'tariffs')

  useEffect(() => {
    isSuperAdmin && getBusinessesData(getBusinesses)

    if(!readTariffs){
      return navigate(`/404`);
    }
    const currentState = location.state as ReactLocationStateType | undefined;
    const currentSearch = location.search
    if(currentState){
      setSearchParams({
        page: currentState?.page ?? currentSearch?.replace(/(\D+)(\d+)$/, "$2") ?? String(tableParams.page)
      })
      setTableParams({
        ...tableParams,
        page: +currentState?.page ?? +currentSearch?.replace(/(\D+)(\d+)$/, "$2") ?? tableParams.page
      })
      callHandler(filterParams, {
        ...tableParams,
        page: +currentState.page
      })
      return
    }
    Object.keys(authInfo).length && callHandler(filterParams, tableParams);
  }, []);

  return (
    <Box>
      <PageHeader
        title={intl.formatMessage({ id: "tariffs" })}
        onClick={handleOpenModal}
        buttonText="newTariff"
        onlyTitle={!manageTariffs}
      />
      {response.data && (
        <TariffsTable
          data={response.data}
          size={tableParams.size}
          pageNumber={tableParams.page}
          handleUpdateTableData={handleChange}
          handleChangePage={handleChangePage}
          handleChangeRowsNumber={handleChangeRowsNumber}
        />
      )}
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <TariffForm
          businesses={businesses?.data?.content ?? []}
          handleClose={handleCloseModal}
          authInfo={authInfo}
        />
      </AMPModal>
    </Box>
  );
};
