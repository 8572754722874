import { alpha, SxProps, Theme } from "@mui/material";
import theme from "../../theme";

export const historyNotification:SxProps<Theme> = {
  backgroundColor: alpha(theme.palette.error.main, 0.1),
  border: `${theme.spacing(1/8)} solid ${alpha(theme.palette.error.main, 0.3)}`,
  borderRadius: theme.spacing(8/8),
  p: 2,
  mb: 2,
  flexDirection: "row",
  gap: 2,
  alignItems: "center",
  '& .MuiTypography-root': {
    lineHeight: 1.5
  },
  '& .MuiBox-root': {
    width: theme.spacing(30/8),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}

export const gridElementSx: SxProps<Theme> = {
  overflow: 'hidden'
}