import { Error } from "@mui/icons-material";
import { Box, IconButton, Popover, Typography, TypographyProps } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { buttonSx, errorIconSx, popoverTextSx } from "./HistoryCell.sx";
import { HistoryCellType } from "./HistoryCell.types";

export const HistoryCell: FC<HistoryCellType> = ({
  value
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handlePopoverOpen: TypographyProps['onMouseEnter'] = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  
  if (value) {
    return null
  }
  
  return (
    <Box>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <IconButton
          sx={buttonSx}
        >
          <Error sx={errorIconSx}/>
        </IconButton>
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={popoverTextSx}>
          <FormattedMessage id='missingHistory' />
        </Typography>
      </Popover>
    </Box>
  )
}