import { ChangeEvent, FC, useEffect, useMemo, useState } from "react"
import { Box, FormLabel, Grid, SelectChangeEvent, Typography } from "@mui/material"
import { Form, Formik, FormikHandlers, FormikProps } from "formik"
import { FormattedMessage, useIntl } from "react-intl"
import { locationInitialValues } from "../formData/common/initialData"
import { AMPInput } from "src/components/common/AMPInput"
import { AMPFormSelect } from "src/components/common/AMPFormSelect"
import { getHelperText } from "src/utils/forms"
import { locationSchema, LocationType } from "../formData/location"
import { TypographyWithCaption } from "src/components/common/TypographyWithCaption"
import { LocationFormType } from "./LocationForm.types"
import { CancelButton } from "src/components/common/CancelButton"
import { SubmitButton } from "src/components/common/SubmitButton"
import DropZone from "src/components/common/DropZone"
import { formSx, actionsWrapperSx, mapWrapperSx } from "./LocationForm.sx"
import Geocode from "react-geocode";
import { LatLngType, SelectValue } from "src/types"
import { allRegionsValue, MapZoomEnum } from "src/utils/consts"
import { MapWrapper } from "src/components/map/MapWrapper"
import { getMapBaseAPI } from "src/utils/helper"
import { systemApi } from "../../../api/system";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { handleGetCountries } from "../../../hooks/useGetCountries";
import { TariffType } from "../formData/tariff";

export const LocationForm: FC<LocationFormType> = ({ handleClose, initialValue, handleSubmit, handleAddFile, setMark }) => {
  const intl = useIntl();
  const { lang } = useAppSelector((state) => state.common)
  const [activeCountry, setActiveCountry] = useState<string>("");
  const [activeCity, setActiveCity] = useState<string>("");
  const [activeRegion, setActiveRegion] = useState<string>("");
  const [activeStreet, setActiveStreet] = useState<string>("");
  const [activeHouseNumber, setActiveHouseNumber] = useState<string>("");
  const [latLng, setLatLng] = useState<LatLngType>()
  
  const [getCountries, countries] = systemApi.useLazyGetCountriesQuery()
  const dispatch = useAppDispatch()
  
  useEffect(() => {
    handleGetCountries(getCountries, dispatch)
  }, [])
  
  const processedRegions = useMemo(() => {
    const initialArray: SelectValue[] = [{
      id: 0,
      name: activeCountry.length ? intl.formatMessage({id: activeCountry.toLowerCase()}) : '',
      value: allRegionsValue
    }]
    if (activeCountry.length) {
      const mappedRegions: SelectValue[] | undefined = countries.data?.find(country => country.name === activeCountry)?.
        regions?.map(region => ({
          id: region.id,
          name: intl.formatMessage({id: region.name}),
          value: region.name
        }))
      return  mappedRegions?.concat(initialArray[0])
        ?? initialArray
    }
    return initialArray
  }, [activeCountry, lang])
  
  const handleChangeRegion = (handleChange: FormikHandlers['handleChange']) =>
    (event: SelectChangeEvent) => {
      setActiveRegion(event.target.value)
      return handleChange(event)
    }
  
  const processedCountries = countries?.data?.map(country => ({
    id: country.id,
    name: intl.formatMessage({id: country.name.toLowerCase()}),
    value: country.name
  })) ?? []
  
  const isHouseNumberAvailable = activeCountry && activeCity && activeStreet && activeHouseNumber
  const isStreetAvailable = activeCountry && activeCity && activeStreet
  const isCityAvailable = activeCountry && activeCity

  const mapZoom = useMemo(() => {
    if(initialValue?.latitude && initialValue?.longitude){
      return MapZoomEnum.HIGH
    }
    if(isHouseNumberAvailable){
      return MapZoomEnum.MAX
    }
    if(isStreetAvailable){
      return MapZoomEnum.HIGH
    }
    if(isCityAvailable){
      return MapZoomEnum.NORMAL
    }
    return MapZoomEnum.MIN
  }, [activeCountry, activeCity, activeStreet, activeHouseNumber])

  useEffect(() => {
    const mapApi = getMapBaseAPI()
    if(initialValue?.latitude && initialValue?.longitude){
      setLatLng({lat: initialValue.latitude, lng: initialValue.longitude});
      setMark({lat: initialValue.latitude, lng: initialValue.longitude})
      return 
    }
    if(activeCountry && mapApi){
      Geocode.setApiKey(mapApi)
  
      Geocode.setLanguage("en");
      Geocode.fromAddress(`${activeCountry}, ${activeCity}, ${activeStreet} ${activeHouseNumber}`).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;          
          setLatLng({lat, lng});
          setMark({lat, lng})
        },
        (error) => {
          console.error(error);
        }
      )
      return
    }
  }, [activeCountry, activeCity, activeStreet, activeHouseNumber])
  
  const handleFile = (dragedFile: File[] | null) => {
    handleAddFile(dragedFile)
  };

  const getInitialValues = useMemo(() => {
    if(!initialValue) {
      return locationInitialValues
    }
    setActiveCountry(initialValue.address.country)
    return {
      name: initialValue.name,
      region: initialValue.address?.region ?? '',
      country: initialValue.address.country,
      city: initialValue.address.city,
      zipCode: initialValue.address.zipCode,
      street: initialValue.address.street,
      houseNumber: initialValue.address.houseNumber,
      parkingLots: initialValue.parkingLots,
      availableAmperes: initialValue.availableAmperes
    }
  }, [initialValue, locationInitialValues])
  
  const handleChangeCountry = (
    handleChange: FormikHandlers["handleChange"],
    setFieldValue: FormikProps<TariffType>['setFieldValue']
  ) => (event: SelectChangeEvent) => {
    setActiveCountry(event.target.value);
    setFieldValue('region', '')
    return handleChange(event);
  };
  
  const handleChangeCity = (
    handleChange: FormikHandlers["handleChange"],
    event: ChangeEvent<any>
  ) => {
    setActiveCity(event.target.value);
    return handleChange(event);
  };

  const handleChangeStreet = (
    handleChange: FormikHandlers["handleChange"],
    event: ChangeEvent<any>
  ) => {
    setActiveStreet(event.target.value);
    return handleChange(event);
  };
  
  const handleChangeHouseNumber = (
    handleChange: FormikHandlers["handleChange"],
    event: ChangeEvent<any>
  ) => {
    setActiveHouseNumber(event.target.value);
    return handleChange(event);
  };

  return (
    <Formik
      initialValues={getInitialValues}
      validationSchema={locationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }: FormikProps<LocationType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box
            sx={formSx}
          >
            <Box>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{xs: 0, lg: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    value={values['name'] as string}
                    type={'name'}
                    label={'name'}
                    input={'name'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['name'] && Boolean(errors['name'])}
                    helperText={getHelperText('name', touched, errors, intl)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="locationAddress" />
              </Typography>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{xs: 0, lg: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPFormSelect
                    data={processedCountries}
                    value={values['country'] as string}
                    label={'country'}
                    input={'country'}
                    onChange={handleChangeCountry(handleChange, setFieldValue)}
                    onBlur={handleBlur}
                    hasError={touched['country'] && Boolean(errors['country'])}
                    helperText={getHelperText('country', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPFormSelect
                    data={processedRegions}
                    value={values['region'] as string}
                    label={'region'}
                    input={'region'}
                    disabled={!activeCountry.length}
                    onChange={handleChangeRegion(handleChange)
                    }
                    onBlur={handleBlur}
                    hasError={touched['region'] && Boolean(errors['region'])}
                    helperText={getHelperText('region', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    type="text"
                    value={values['city']}
                    label={'city'}
                    input={'city'}
                    disabled={Boolean(!activeRegion)}
                    onChange={(e: ChangeEvent<any>) =>
                      handleChangeCity(handleChange, e)
                    }
                    onBlur={handleBlur}
                    hasError={touched['city'] && Boolean(errors['city'])}
                    helperText={getHelperText('city', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    value={values['street'] as string}
                    type={'street'}
                    label={'street'}
                    input={'street'}
                    onChange={(e: ChangeEvent<any>) =>
                      handleChangeStreet(handleChange, e)
                    }
                    onBlur={handleBlur}
                    hasError={touched['street'] && Boolean(errors['street'])}
                    helperText={getHelperText('street', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    value={values['houseNumber'] as string}
                    type={'houseNumber'}
                    label={'building'}
                    input={'houseNumber'}
                    onChange={(e: ChangeEvent<any>) =>
                      handleChangeHouseNumber(handleChange, e)
                    }
                    onBlur={handleBlur}
                    hasError={touched['houseNumber'] && Boolean(errors['houseNumber'])}
                    helperText={getHelperText('houseNumber', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <AMPInput
                    value={values['zipCode'] as string}
                    type={'zipCode'}
                    label={'zipCode'}
                    input={'zipCode'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['zipCode'] && Boolean(errors['zipCode'])}
                    helperText={getHelperText('zipCode', touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Box sx={mapWrapperSx}>
                <FormLabel>
                  <Typography variant="caption">
                    <FormattedMessage id="pinOnMap" />
                  </Typography>
                </FormLabel>
                <MapWrapper
                  mapCenter={latLng}
                  zoom={mapZoom}
                />
              </Box>
            </Box>
            <Box>
              <TypographyWithCaption
                title="other"
                caption="optionalField"
              />
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{xs: 0, lg: 2}}
              >
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <AMPInput
                    value={values['parkingLots'] as number}
                    label={'parkingLots'}
                    input={'parkingLots'}
                    type={'number'}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <AMPInput
                    value={values['availableAmperes'] as number}
                    label={'availableAmperes'}
                    input={'availableAmperes'}
                    type={'number'}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <DropZone 
              handleFile={handleFile}
            />
          </Box>
          <Box sx={actionsWrapperSx}>
            <CancelButton
              text="cancel"
              handler={handleClose}
            />
            <SubmitButton
              text={"submit"}
              isSubmitting={isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
