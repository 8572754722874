import { SxProps, Theme, useTheme } from "@mui/material";
import { useCallback } from "react";
import { ContractStatusEnum, RoleEnum } from "src/enums";

export const useSxValue = (label: string): SxProps<Theme> => {
  const theme = useTheme()
  const sxValueCallback = useCallback((theme: Theme): SxProps<Theme>  => {
    switch(label){
      case RoleEnum.BUSINESS: 
        return {
          background: theme.palette.warning.light,
          color: theme.palette.warning.dark,
        }
      case RoleEnum.DRIVER:
        return {
          background: theme.palette.success.light,
          color: theme.palette.success.dark,
        }
      case RoleEnum.SUPER_ADMIN:
        return {
          background: theme.palette.brand.light,
          color: theme.palette.brand.dark,
        }
      case RoleEnum.TECHNICAL_MANAGER:
        return {
          background: theme.palette.alternative[50],
          color: theme.palette.alternative[700],
        }
      case RoleEnum.SALES_MANAGER:
        return {
          background: theme.palette.secondaryColor[50],
          color: theme.palette.secondaryColor[700],
        }
      case RoleEnum.CLIENT_MANAGER:
        return {
          background: theme.palette.primaryColor[50],
          color: theme.palette.primaryColor[700],
        }
      case ContractStatusEnum.OPERATION:
        return {
          background: theme.palette.primaryColor[100],
          color: theme.palette.primary.dark,
        }
      case ContractStatusEnum.INSTALLATION:
        return {
          background: theme.palette.success.light,
          color: theme.palette.success.dark,
        }
      case ContractStatusEnum.WAIT_FOR_SIGNATURE:
        return {
          background: theme.palette.warning.light,
          color: theme.palette.warning.dark,
        }
      default: 
        return {
          background: theme.palette.neutral[300],
          color: theme.palette.neutral[700],
        }

    }
  }, [label])
  return sxValueCallback(theme)
} 
