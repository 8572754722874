import { FC, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "src/app/hooks";
import { changeLang } from "src/stores/common/commonSlice";
import { AppRouteSpace, AppRouteEnum } from "src/utils/helper";
import { useLocation } from "react-router-dom";
import { Policy } from "../../components/termsAndPolicy/Policy";

export const PrivacyPolicy: FC = () => {
  const { lang } = useAppSelector(state => state.common)
  const dispatch = useAppDispatch()
  const location = useLocation()
  useEffect(() => {
    if(location.pathname !== AppRouteSpace([AppRouteEnum.support, lang])){
      dispatch(changeLang(location.pathname.replace(/\/(.+)\/(\w+)/, '$2')))
    }
  }, [lang])
  
  return (
    <Policy/>
  )
}