import theme from "../../theme";

export const chargerInfoSx = {
  activeChargingResponsiveLgSx: {
    display: "block",
    [theme.breakpoints.down('lg')]: {
      display: "none"
    }
  },
  activeChargingResponsiveSmSx: {
    display: "none",
    [theme.breakpoints.down('lg')]: {
      display: "block"
    }
  }
}
