import theme from "../../../theme";

export const uploadContractSx = {
  root: {
    p: 3,
    [theme.breakpoints.down('lg')]: {
      p: '1rem'
    }
  },
  subtitleWrapper: {
    mt: 2,
    mb: 4
  },
  stepRoot: {
    gap: 1,
    "svg circle": {
      r: 11
    },
    '& .MuiStepLabel-iconContainer': {
      [theme.breakpoints.down('lg')]: {
        pr: 0
      }
    },
    [theme.breakpoints.down('lg')]: {
      pr: 0
    }
  },
  stepItem: {
    [theme.breakpoints.down('lg')]: {
      flexDirection: "column",
    }
  },
  stepTitle: {
    [theme.breakpoints.down('lg')]: {
      alignItems: "center",
      textAlign: "center",
      pt: 1,
      fontSize: theme.spacing(12/8)
    }
  },
  stepDescription: {
    [theme.breakpoints.down('lg')]: {
      display: "none"
    }
  },
  actions: {
    padding: theme.spacing(20/8, 24/8),
    borderTop: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`,
    background: theme.palette.neutral[50],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(20/8),
    [theme.breakpoints.down('lg')]: {
      p: '1rem'
    }
  },
  activeActions: {
    display: 'flex',
    gap: theme.spacing(10/8),
    alignItems: 'center'
  }
}
