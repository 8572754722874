import { Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { EmptyTableWrapper } from "src/components/tables/EmptyTableWrapper";

export const NoClients: FC = () => (
  <EmptyTableWrapper>
    <Typography variant="subtitle1">
      <FormattedMessage id="noClients" />
    </Typography>
  </EmptyTableWrapper>
)
