import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';


export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5
  },
  caption: {
    color: theme.palette.neutral[400]
  }
}));
