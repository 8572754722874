import { SxProps, Theme } from "@mui/material";
import theme from "../../../../theme";

export const linkWrapperSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(5/8),
  color: theme.palette.text.primary
}

export const linkSx: SxProps<Theme> = {
  width: "100%",
  display: 'flex',
  justifyContent: "space-between",
  alignItems: 'center',
  color: theme.palette.text.primary
}
