import { SxProps, Theme } from "@mui/material";

export const textFieldSx: SxProps<Theme> = {
  minHeight: '2rem',
  height: '2rem',
  m: 0,
  '.MuiSelect-select': {
    display: "flex",
    alignItems: "center",
    m: 0
  }
}
