import { FC } from "react";

export const DurationIcon: FC = () => (
  <svg 
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="46"
      height="46"
      rx="8"
      fill="#DEF0FC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 13.001C17.4768 13.001 13 17.4778 13 23.001C13 28.5242 17.4768 33.001 23 33.001C28.5232 33.001 33 28.5242 33 23.001C33 17.4778 28.5232 13.001 23 13.001ZM23 14.501C27.6948 14.501 31.5 18.3062 31.5 23.001C31.5 27.6958 27.6948 31.501 23 31.501C18.3052 31.501 14.5 27.6958 14.5 23.001C14.5 18.3062 18.3052 14.501 23 14.501ZM23.4048 18.7456C23.3551 18.3795 23.0413 18.0974 22.6616 18.0974C22.2474 18.0974 21.9116 18.4332 21.9116 18.8474V23.6944L21.92 23.8061C21.9531 24.0262 22.0829 24.2225 22.2774 24.3385L26.0474 26.5875L26.1383 26.6337C26.4781 26.7786 26.8812 26.6537 27.0757 26.3276L27.122 26.2367C27.2669 25.8969 27.1419 25.4938 26.8158 25.2993L23.4112 23.2675L23.4116 18.8474L23.4048 18.7456Z"
      fill="#007FDC"
    />
  </svg>

);
