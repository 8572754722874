import { createSvgIcon } from "@mui/material";

export const LocationsIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 19.7731L15.3033 14.4698C16.3522 13.4209 17.0665 12.0845 17.3559 10.6296C17.6453 9.17479 17.4968 7.6668 16.9291 6.29636C16.3614 4.92592 15.4001 3.75458 14.1668 2.93048C12.9334 2.10637 11.4834 1.6665 10 1.6665C8.51665 1.6665 7.0666 2.10637 5.83324 2.93048C4.59987 3.75458 3.63858 4.92592 3.07092 6.29636C2.50326 7.6668 2.35473 9.17479 2.64411 10.6296C2.93349 12.0845 3.64779 13.4209 4.69667 14.4698L10 19.7731ZM10.2564 4.1665V8.93923L13.3333 8.93923L9.74359 15.8332L9.74359 11.0604H6.66667L10.2564 4.1665Z"
  />,
  'Locations'
)

export const ChargersIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M17.6064 5.1718L17.5963 5.18191C18.0508 5.63645 18.3337 6.27281 18.3337 6.96978V16.5657C18.3337 17.9597 17.2023 19.091 15.8084 19.091C14.4145 19.091 13.2832 17.9597 13.2832 16.5657V11.5152H11.768V19.091H1.66699V2.92938C1.66699 1.81827 2.57608 0.90918 3.68719 0.90918H9.7478C10.8589 0.90918 11.768 1.81827 11.768 2.92938V10.0001H12.7781C13.8892 10.0001 14.7983 10.9092 14.7983 12.0203V16.5657C14.7983 17.1213 15.2529 17.5758 15.8084 17.5758C16.364 17.5758 16.8185 17.1213 16.8185 16.5657V9.28292C16.5054 9.41423 16.172 9.49504 15.8084 9.49504C14.4145 9.49504 13.2832 8.36372 13.2832 6.96978C13.2832 5.88898 13.9599 4.97989 14.9094 4.61625L12.7781 2.48494L13.8488 1.41423L17.6064 5.1718ZM14.7983 6.96978C14.7983 7.52534 15.2529 7.97989 15.8084 7.97989C16.364 7.97989 16.8185 7.52534 16.8185 6.96978C16.8185 6.41423 16.364 5.95968 15.8084 5.95968C15.2529 5.95968 14.7983 6.41423 14.7983 6.96978ZM6.90101 4.9493V9.08153L9.65583 9.08153L6.44187 15.0503V10.9181H3.68705L6.90101 4.9493Z"
  />,
  'Chargers'
)

export const UsersIcon = createSvgIcon(
  <>
    <path
      d="M13.334 10.9522V11.6665H19.1673V10.9522C19.1673 10.4786 18.9753 10.0244 18.6334 9.68953C18.2915 9.35464 17.8279 9.1665 17.3444 9.1665H15.1569C14.6734 9.1665 14.2098 9.35464 13.8679 9.68953C13.526 10.0244 13.334 10.4786 13.334 10.9522Z"
    />
    <path
      d="M15.4135 8.2891C15.6789 8.39679 15.9634 8.45222 16.2507 8.45222C16.8308 8.45222 17.3872 8.22645 17.7974 7.82459C18.2077 7.42273 18.4381 6.87768 18.4381 6.30936C18.4381 5.74104 18.2077 5.196 17.7974 4.79413C17.3872 4.39227 16.8308 4.1665 16.2507 4.1665C15.9634 4.1665 15.6789 4.22193 15.4135 4.32962C15.1481 4.43731 14.907 4.59515 14.7039 4.79413C14.5007 4.99311 14.3396 5.22934 14.2297 5.48932C14.1197 5.74931 14.0632 6.02796 14.0632 6.30936C14.0632 6.59076 14.1197 6.86941 14.2297 7.1294C14.3396 7.38938 14.5007 7.62561 14.7039 7.82459C14.907 8.02357 15.1481 8.18141 15.4135 8.2891Z"
    />
    <path
      d="M0.833984 15.9919V17.4998H13.334V15.9919C13.334 14.9921 12.9224 14.0332 12.1899 13.3262C11.4573 12.6192 10.4637 12.2221 9.42773 12.2221H4.74023C3.70423 12.2221 2.71066 12.6192 1.9781 13.3262C1.24553 14.0332 0.833984 14.9921 0.833984 15.9919Z"
    />
    <path
      d="M5.29016 10.3698C5.85887 10.5971 6.46841 10.7141 7.08398 10.7141C8.32719 10.7141 9.51947 10.2375 10.3985 9.38913C11.2776 8.54075 11.7715 7.3901 11.7715 6.19031C11.7715 4.99052 11.2776 3.83988 10.3985 2.9915C9.51947 2.14312 8.32719 1.6665 7.08398 1.6665C6.46841 1.6665 5.85887 1.78352 5.29016 2.01086C4.72144 2.2382 4.2047 2.57142 3.76942 2.9915C3.33415 3.41157 2.98887 3.91027 2.7533 4.45913C2.51773 5.00798 2.39648 5.59624 2.39648 6.19031C2.39648 6.78439 2.51773 7.37265 2.7533 7.9215C2.98887 8.47035 3.33415 8.96906 3.76942 9.38913C4.2047 9.8092 4.72144 10.1424 5.29016 10.3698Z"
    />
  </>,
  'Users'
)

export const ContractsIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16.7356 8.74985L17.7372 9.35069C17.799 9.38768 17.8502 9.44006 17.8857 9.50272C17.9212 9.56537 17.9399 9.63617 17.9399 9.70819C17.9399 9.78021 17.9212 9.851 17.8857 9.91366C17.8502 9.97631 17.799 10.0287 17.7372 10.0657L9.99972 14.7082L2.26222 10.0657C2.20042 10.0287 2.14927 9.97631 2.11375 9.91366C2.07824 9.851 2.05957 9.78021 2.05957 9.70819C2.05957 9.63617 2.07824 9.56537 2.11375 9.50272C2.14927 9.44006 2.20042 9.38768 2.26222 9.35069L3.26388 8.74985L9.99972 12.7915L16.7356 8.74985ZM16.7356 12.6665L17.7372 13.2674C17.799 13.3043 17.8502 13.3567 17.8857 13.4194C17.9212 13.482 17.9399 13.5528 17.9399 13.6249C17.9399 13.6969 17.9212 13.7677 17.8857 13.8303C17.8502 13.893 17.799 13.9454 17.7372 13.9824L10.4289 18.3674C10.2993 18.4452 10.1509 18.4864 9.99972 18.4864C9.84851 18.4864 9.70016 18.4452 9.57055 18.3674L2.26222 13.9824C2.20042 13.9454 2.14927 13.893 2.11375 13.8303C2.07824 13.7677 2.05957 13.6969 2.05957 13.6249C2.05957 13.5528 2.07824 13.482 2.11375 13.4194C2.14927 13.3567 2.20042 13.3043 2.26222 13.2674L3.26388 12.6665L9.99972 16.7082L16.7356 12.6665ZM10.4281 1.09069L17.7372 5.47569C17.799 5.51268 17.8502 5.56506 17.8857 5.62772C17.9212 5.69037 17.9399 5.76117 17.9399 5.83319C17.9399 5.90521 17.9212 5.976 17.8857 6.03866C17.8502 6.10131 17.799 6.15369 17.7372 6.19069L9.99972 10.8332L2.26222 6.19069C2.20042 6.15369 2.14927 6.10131 2.11375 6.03866C2.07824 5.976 2.05957 5.90521 2.05957 5.83319C2.05957 5.76117 2.07824 5.69037 2.11375 5.62772C2.14927 5.56506 2.20042 5.51268 2.26222 5.47569L9.57055 1.09069C9.70016 1.01282 9.84851 0.97168 9.99972 0.97168C10.1509 0.97168 10.2993 1.01282 10.4289 1.09069H10.4281Z"
  />,
  'Contracts'
)

export const TariffsIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.0003 1.6665C5.40033 1.6665 1.66699 5.39984 1.66699 9.99984C1.66699 14.5998 5.40033 18.3332 10.0003 18.3332C14.6003 18.3332 18.3337 14.5998 18.3337 9.99984C18.3337 5.39984 14.6003 1.6665 10.0003 1.6665ZM10.7337 14.7998V15.8332H9.27533V14.7582C8.65866 14.6082 7.28366 14.1165 6.75866 12.2915L8.13366 11.7332C8.18366 11.9165 8.61699 13.4748 10.1337 13.4748C10.9087 13.4748 11.7837 13.0748 11.7837 12.1332C11.7837 11.3332 11.2003 10.9165 9.88366 10.4415C8.96699 10.1165 7.09199 9.58317 7.09199 7.68317C7.09199 7.59984 7.10033 5.68317 9.27533 5.2165V4.1665H10.7337V5.19984C12.267 5.4665 12.8253 6.6915 12.9503 7.05817L11.6337 7.6165C11.542 7.32484 11.142 6.49984 10.0503 6.49984C9.46699 6.49984 8.54199 6.80817 8.54199 7.65817C8.54199 8.44984 9.25866 8.74984 10.742 9.2415C12.742 9.93317 13.2503 10.9498 13.2503 12.1165C13.2503 14.3082 11.167 14.7248 10.7337 14.7998Z"
  />,
  'Tariffs'
)
export const NotificationsIcon = createSvgIcon(
  <path d="M9.17969 20.9561C9.79899 20.9568 10.4031 20.7646 10.9082 20.4062C11.4132 20.0478 11.794 19.5409 11.9977 18.9561H6.36169C6.56535 19.5409 6.94617 20.0478 7.4512 20.4062C7.95623 20.7646 8.56039 20.9568 9.17969 20.9561ZM16.1797 13.5421V8.95605C16.1797 5.73905 13.9947 3.02905 11.0347 2.21405C10.7417 1.47605 10.0257 0.956055 9.17969 0.956055C8.33369 0.956055 7.61769 1.47605 7.32469 2.21405C4.36469 3.03005 2.17969 5.73905 2.17969 8.95605V13.5421L0.472689 15.2491C0.379646 15.3418 0.305858 15.452 0.255573 15.5733C0.205288 15.6946 0.179498 15.8247 0.179689 15.9561V16.9561C0.179689 17.2213 0.285045 17.4756 0.472582 17.6632C0.660118 17.8507 0.914472 17.9561 1.17969 17.9561H17.1797C17.4449 17.9561 17.6993 17.8507 17.8868 17.6632C18.0743 17.4756 18.1797 17.2213 18.1797 16.9561V15.9561C18.1799 15.8247 18.1541 15.6946 18.1038 15.5733C18.0535 15.452 17.9797 15.3418 17.8867 15.2491L16.1797 13.5421Z"
  />,
  'Notifications'

)
export const ReportsIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9.16699 5.83372H1.66699V7.50039H9.16699V5.83372ZM9.16699 12.5004H1.66699V14.1671H9.16699V12.5004ZM13.617 9.16706L10.667 6.21706L11.842 5.04206L13.6087 6.80872L17.142 3.27539L18.3337 4.45039L13.617 9.16706ZM13.617 15.8337L10.667 12.8837L11.842 11.7087L13.6087 13.4754L17.142 9.94206L18.3337 11.1171L13.617 15.8337Z"
  />,
  'Reports'
)
export const InvoicesIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M15 14.1665H5V12.4998H15V14.1665ZM15 10.8332H5V9.1665H15V10.8332ZM15 7.49984H5V5.83317H15V7.49984ZM2.5 18.3332L3.75 17.0832L5 18.3332L6.25 17.0832L7.5 18.3332L8.75 17.0832L10 18.3332L11.25 17.0832L12.5 18.3332L13.75 17.0832L15 18.3332L16.25 17.0832L17.5 18.3332V1.6665L16.25 2.9165L15 1.6665L13.75 2.9165L12.5 1.6665L11.25 2.9165L10 1.6665L8.75 2.9165L7.5 1.6665L6.25 2.9165L5 1.6665L3.75 2.9165L2.5 1.6665V18.3332Z"
  />,
  'Invoices'
)

export const DashboardIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1.99963 6.56916C1.99963 3.93549 4.23044 2 6.68077 2C9.1673 2 11.2499 4.08263 11.2499 6.56916C11.2499 9.15571 9.17914 11.2503 6.68077 11.2503C4.20597 11.2503 1.99963 9.17928 1.99963 6.56916ZM12.8613 4.77754C12.8613 3.32128 14.0261 2 15.6389 2H19.3341C20.7903 2 22.1116 3.16475 22.1116 4.77754V19.2225C22.1116 20.7012 20.8128 22 19.3341 22H15.6389C14.1826 22 12.8613 20.8353 12.8613 19.2225V4.77754ZM1.8877 15.6391C1.8877 14.0054 3.23102 12.8616 4.77722 12.8616H8.47243C9.92869 12.8616 11.25 14.0263 11.25 15.6391V19.3343C11.25 20.0514 10.8972 20.7155 10.4073 21.1879C9.9143 21.6633 9.22656 21.9999 8.47243 21.9999H4.77722C4.05011 21.9999 3.34147 21.7122 2.81184 21.2532C2.28037 20.7926 1.8877 20.1201 1.8877 19.3343V15.6391Z" 
  />,
  'Dashboard'
)

export const TechnicalTeamIcon = createSvgIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.668 5.83366C11.668 8.1467 9.81435 10.0003 7.5013 10.0003C5.1891 10.0003 3.33464 8.1467 3.33464 5.83366C3.33464 3.52061 5.1891 1.66699 7.5013 1.66699C9.81435 1.66699 11.668 3.52061 11.668 5.83366ZM1.66797 14.1576C1.66797 12.1654 4.1632 11.667 7.08463 11.667C8.54728 11.667 9.89637 11.7948 10.8823 12.1694L9.20916 13.1381V16.5524C8.55202 16.6343 7.83228 16.667 7.08463 16.667C4.14733 16.667 1.66797 16.1514 1.66797 14.1576ZM16.9133 12.2821C15.7029 11.1269 13.729 10.3045 11.4866 9.68555C12.048 9.61209 12.6773 9.58366 13.3346 9.58366C15.5941 9.58366 17.5013 9.92739 17.5013 11.2566C17.5013 11.7021 17.2853 12.0356 16.9133 12.2821ZM12.2616 8.16644C12.7945 7.3985 13.0984 6.47401 13.0984 5.48323C13.0984 4.44803 12.7671 3.48405 12.1904 2.69213C12.1716 2.66745 12.158 2.63008 12.1767 2.60117C12.1911 2.5786 12.2199 2.56591 12.2465 2.56027C12.4409 2.52148 12.6404 2.50033 12.8485 2.50033C14.7675 2.50033 16.2739 4.26751 15.7605 6.23566C15.4141 7.5621 14.1605 8.44287 12.7635 8.40691C12.6231 8.40409 12.4856 8.39069 12.3517 8.36883C12.2552 8.3519 12.2062 8.24542 12.2616 8.16644ZM13.7067 20.0003L10.0272 17.8701V13.6095L13.7067 11.4792L17.3863 13.6095V17.8701L13.7067 20.0003ZM15.6435 15.7398C15.6435 16.8093 14.7765 17.6764 13.7069 17.6764C12.6373 17.6764 11.7703 16.8093 11.7703 15.7398C11.7703 14.6702 12.6373 13.8031 13.7069 13.8031C14.7765 13.8031 15.6435 14.6702 15.6435 15.7398Z"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4355_76970"
        x1="9.58463"
        y1="1.66699"
        x2="9.58463"
        y2="20.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor="#D62156"
        />
        <stop
          offset="0.0001"
          stopColor="#FF6B97"
        />
        <stop
          offset="1"
          stopColor="#E8285F"
        />
      </linearGradient>
    </defs>
  </g>,
  'TechnicalTeam'
)