import { Box, Button, Divider, SelectChangeEvent, Typography } from "@mui/material";
import { FC, FormEvent, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SubmitButton } from "src/components/common/SubmitButton";
import { LocationFilterFormType } from "./LocationFilterForm.types";
import { fieldsWrapperSx, actionsWrapperSx, buttonSx, textFieldSx, buttonSizeSx } from "./LocationFilterForm.sx";
import { AMPFormSelect } from "src/components/common/AMPFormSelect";
import { AMPInput } from "src/components/common/AMPInput";
import { PasswordInputProps } from "src/components/common/AMPInput/AMPInput.types";
import { systemApi } from "../../../api/system";
import { useAppDispatch } from "../../../app/hooks";
import { handleGetCountries } from "../../../hooks/useGetCountries";

export const LocationFilterForm: FC<LocationFilterFormType> = ({
  handleFilter,
  isFilterActive,
  handleClose,
  activeCity,
  activeCountry,
  setActiveCountry,
  setActiveCity,
  activeBusiness,
  setActiveBusiness,
  businesses,
}) => {
  const intl = useIntl()
  const [getCountries, countries] = systemApi.useLazyGetCountriesQuery()
  const dispatch = useAppDispatch()
  
  useEffect(() => {
    handleGetCountries(getCountries, dispatch)
  }, [])
  
  const processedCountries = countries?.data?.map(country => ({
    id: country.id,
    name: intl.formatMessage({id: country.name.toLowerCase()}),
    value: country.name
  })) ?? []

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleFilter({ country: activeCountry, city: activeCity, businessId: activeBusiness })
    isFilterActive(true)
    handleClose()
  }

  const handleClear = () => {
    setActiveCountry('')
    setActiveCity('')
    setActiveBusiness(0)
    handleFilter({ country: '', city: '', businessId: 0 })
    isFilterActive(false)
  }

  const handleChangeCountry = (event: SelectChangeEvent<string>) => {
    setActiveCountry(event.target.value)
    setActiveCity('')
  }
  const handleChangeCity: PasswordInputProps['onChange'] = (e) => {
    setActiveCity(e.target.value)
  }
  return (

    <form
      onSubmit={handleSubmit}
      noValidate
    >
      <Box sx={fieldsWrapperSx}>
        {Boolean(businesses.length) && <AMPFormSelect
          data={businesses}
          value={activeBusiness}
          label={"business"}
          input={"business"}
          onChange={(e: SelectChangeEvent<number>) => setActiveBusiness(+e.target.value)}
          sx={textFieldSx}
        />}
        <AMPFormSelect
          data={processedCountries}
          value={activeCountry}
          label={'country'}
          input={'country'}
          onChange={handleChangeCountry}
          sx={textFieldSx}
        />
        <AMPInput
          type='text'
          value={activeCity}
          label={'city'}
          disabled={Boolean(!activeCountry)}
          input={'city'}
          onChange={handleChangeCity}
          sx={textFieldSx}
        />
      </Box>
      <Divider />
      <Box sx={actionsWrapperSx}>
        <Button
          variant="outlined"
          sx={buttonSx}
          onClick={handleClear}
        >
          <Typography
            variant="button"
            color="neutral.700"
          >
            <FormattedMessage id="clear" />
          </Typography>
        </Button>
        <SubmitButton
          text="apply"
          style={buttonSizeSx}
        />
      </Box>
    </form>
  )
}
