import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";
import { CSSProperties } from "react";

export const btnSx: CSSProperties = {
  padding: `0 ${theme.spacing(8/8)}`,
  width: 'fit-content'
}
export const wrapperSx: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: theme.spacing(4/8)
}