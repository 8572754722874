import { FC, SyntheticEvent, useState } from "react";
import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch } from "../../../app/hooks";
import { businessesApi } from "../../../api";
import { CurrencyEnum, LanguageEnum } from "../../../enums";
import { Can } from "../../../permissions/Can";
import { EditIcon } from "../../icons/EditIcon";
import { TabPanel } from "../../common/TabPanel";
import AMPModal from "../../common/AMPModal";
import { AppDispatch } from "../../../types";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { isErrorObject } from "../../../utils/helper";
import { NotifyError } from "../../../utils/notification";
import { RfidTab } from "./RfidTab";
import { RfidTermsForm } from "../../forms/RfidTermsForm";
import { RfidTermsFormType } from "../../forms/formData/rfidTermsForm";
import { BusinessTermsRfidProps } from "./BusinessTermsRfid.types";
import { editBtnSx, rootSx, tabsSx, tabSx, titleSx } from "./BusinessTermsRfid.sx";

type RfidTermsMutation = ReturnType<typeof businessesApi.useSetRfidTermsMutation>[0]
type RfidPriceMutation = ReturnType<typeof businessesApi.useUpdateRfidCostMutation>[0]

const submitTerms = (
  triggerTerms: RfidTermsMutation,
  triggerPrice: RfidPriceMutation,
  dispatch: AppDispatch,
  businessId: number,
  onClose: () => void
) =>
  async (values: RfidTermsFormType) => {
    dispatch(preloaderChangeStatus(true))
    try {
      await triggerTerms({
        businessId,
        params: {
          rfidTerms: values.rfidTerms,
          language: values.language
        }
      })
      await triggerPrice({
        businessId,
        data: {
          rfidCost: values.price * 100,
          rfidCostCurrency: values.currency
        }
      })
    } catch (error) {
      if(isErrorObject(error)){
        NotifyError(error.data?.message ?? error.data?.error)
      }
    } finally {
      onClose()
      dispatch(preloaderChangeStatus(false))
    }
  }

export const BusinessTermsRfid:FC<BusinessTermsRfidProps> = ({
  data
}) => {
  const [tab, setTab] = useState(0)
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [setTerms] = businessesApi.useSetRfidTermsMutation()
  const [setPrice] = businessesApi.useUpdateRfidCostMutation()
  
  const englishDocument = businessesApi.useGetRfidTermsQuery({
    businessId: data?.id,
    language: LanguageEnum.ENGLISH
  })
  const frenchDocument = businessesApi.useGetRfidTermsQuery({
    businessId: data?.id,
    language: LanguageEnum.FRENCH
  })
  const germanDocument = businessesApi.useGetRfidTermsQuery({
    businessId: data?.id,
    language: LanguageEnum.DEUTSCH
  })
  
  const handleTab = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }
  
  const handleShowTermsModal = (status: boolean) => () => {
    setShowTermsModal(status)
  }
  
  return (
    <Box sx={rootSx}>
      <Can
        I="manage"
        a="contract"
      >
        <IconButton
          sx={editBtnSx}
          onClick={handleShowTermsModal(true)}
        >
          <EditIcon/>
        </IconButton>
      </Can>
      <Box sx={titleSx}>
        <Typography variant="subtitle1">
          <FormattedMessage id="termsOfRfid" />
        </Typography>
      </Box>
      <Box>
        <Tabs
          value={tab}
          onChange={handleTab}
          sx={tabsSx}
        >
          <Tab
            sx={tabSx}
            label={intl.formatMessage({id: 'english'})}
          />
          <Tab
            sx={tabSx}
            label={intl.formatMessage({id: 'french'})}
          />
          <Tab
            sx={tabSx}
            label={intl.formatMessage({id: 'german'})}
          />
        </Tabs>
      </Box>
      <TabPanel
        value={tab}
        index={0}
      >
        <RfidTab
          businessId={data?.id}
          language={LanguageEnum.ENGLISH}
          currency={data?.rfidCostCurrency ?? CurrencyEnum.EUR}
          price={data?.rfidCost ?? 0}
        />
      </TabPanel>
      <TabPanel
        value={tab}
        index={1}
      >
        <RfidTab
          businessId={data?.id}
          language={LanguageEnum.FRENCH}
          currency={data?.rfidCostCurrency ?? CurrencyEnum.EUR}
          price={data?.rfidCost ?? 0}
        />
      </TabPanel>
      <TabPanel
        value={tab}
        index={2}
      >
        <RfidTab
          businessId={data?.id}
          language={LanguageEnum.DEUTSCH}
          currency={data?.rfidCostCurrency ?? CurrencyEnum.EUR}
          price={data?.rfidCost ?? 0}
        />
      </TabPanel>
      <AMPModal
        handleClose={handleShowTermsModal(false)}
        open={showTermsModal}
        title={intl.formatMessage({ id: "termsOfRfid" })}
        type="md"
        fullWidth
        withoutPaddings
      >
        <RfidTermsForm
          handleClose={handleShowTermsModal(false)}
          frenchInit={{
            rfidTerms: frenchDocument?.data?.rfidTerms ?? "",
            language: frenchDocument?.data?.language ?? LanguageEnum.FRENCH,
            currency: data?.rfidCostCurrency ?? CurrencyEnum.EUR,
            price: data?.rfidCost ? data?.rfidCost / 100 : 0
          }}
          englishInit={{
            rfidTerms: englishDocument?.data?.rfidTerms ?? "",
            language: englishDocument?.data?.language ?? LanguageEnum.ENGLISH,
            currency: data?.rfidCostCurrency ?? CurrencyEnum.EUR,
            price: data?.rfidCost ? data?.rfidCost / 100 : 0
          }}
          germanInit={{
            rfidTerms: germanDocument?.data?.rfidTerms ?? "",
            language: germanDocument?.data?.language ?? LanguageEnum.DEUTSCH,
            currency: data?.rfidCostCurrency ?? CurrencyEnum.EUR,
            price: data?.rfidCost ? data?.rfidCost / 100 : 0
          }}
          submit={
            submitTerms(
              setTerms,
              setPrice,
              dispatch,
              data?.id,
              handleShowTermsModal(false)
            )}
        />
      </AMPModal>
    </Box>
  )
}
