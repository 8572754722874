import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const counterWrapperSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(10/8),
  [theme.breakpoints.down('lg')]: {
    display: "none"
  }
}

export const inputSx: SxProps<Theme> = {
  background: '#fff',
  outline: 'none',
  padding: theme.spacing(5/8),
  width: theme.spacing(60/8),
  boxSizing: 'border-box',
  height: theme.spacing(40/8),
  borderRadius: theme.spacing(5/8),
  '& input': {
    padding: theme.spacing(0, 5/8)
  }
}
