import theme from "../../../theme";

export const tableFilterSx = {
  actions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 1,
    p: 1,
    borderTop: `${theme.spacing(1/8)} solid ${theme.palette.neutral[400]}`
  },
  formSx: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gap: theme.spacing(20/8)
  },
  formContentSx: {
    px: 3,
    py: 2
  }
}
