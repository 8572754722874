import { FC, useState } from "react";
import { longestChartNaming } from "../../../../utils/consts";
import { CustomizedAxisTickTypes } from "./CustomizedAxisTick.types";

export  const CustomizedAxisTick:FC<CustomizedAxisTickTypes> = ({responsive, x, y, stroke, payload}) => {
  const item = String(payload?.value)
  const [showAll, setShowAll] = useState(false)
  
  const handleShow = (status: boolean) => () => {
    !responsive &&  setShowAll(status)
  }
  
  return (
    <g
      transform={`translate(${x},${y})`}
      onMouseEnter={handleShow(true)}
      onMouseLeave={handleShow(false)}
    >
      <defs>
        <filter
          x="0"
          y="0"
          width="1"
          height="1"
          id="solid">
          <feFlood
            floodColor="white"
            result="bg"
          />
          <feMerge>
            <feMergeNode in="bg"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <text
        filter={"url(#solid)"}
        transform={responsive ? "rotate(90)" : "rotate(0)"}
        x={0}
        y={0}
        dy={16}
        textAnchor={responsive ? "start" : "end"}
        dominantBaseline='ideographic'
        fontWeight={showAll ? 800 : 500}
      >
        {(item.length < longestChartNaming || showAll) && item}
        {item.length >= longestChartNaming && !showAll && item.slice(0, longestChartNaming) + '...'}
      </text>
    </g>
  );
}