import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const rootSx: SxProps<Theme> = {
  position: "relative"
}

export const titleSx: SxProps<Theme> = {
  marginBottom: theme.spacing(20/8),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}

export const editBtnSx: SxProps<Theme> = {
  position: "absolute",
  top: 3,
  right: 0,
  background: "transparent"
}

export const canvasWrapperSx: SxProps = {
  '& img': {
    background: theme.palette.neutral[50],
    width: '100%',
    height: 'auto',
  }
}
