import { FC, MouseEvent, useState } from "react";
import { Box, Button, Popover, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AppRouteEnum, AppRouteSpace, isErrorObject } from "../../../utils/helper";
import { UserIcon } from "../../icons/UserIcon";
import { TariffDTO } from "../../../types";
import { tariffsApi } from "../../../api";
import { useAppDispatch } from "../../../app/hooks";
import { TariffStatusButton } from "../TariffStatusButton";
import { AppDispatch, store } from "../../../app/store";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { useGetUserLanguage } from "../../../hooks/useGetUserLanguage";
import { LanguageEnum } from "../../../enums";
import { TableCard } from "../../tables/respnsiveTable/TableCard";
import { MenuButton } from "../../tables/respnsiveTable/MenuButton";
import { MenuWrapper } from "src/components/tables/respnsiveTable/MenuWrapper";
import { TableRow } from "../../tables/respnsiveTable/TableRow";
import { tariffTableCardSx } from "./TariffTableCard.sx";
import { NotifyError } from "src/utils/notification";
import { fixedDigits } from "src/utils/fixedDigits";

type DeleteTariffMutationTrigger = ReturnType<typeof tariffsApi.useDeleteTariffMutation>[0]

const handleDeleteTariff = (trigger: DeleteTariffMutationTrigger, dispatch: AppDispatch, businessId: number, tariffId: number, navigate: NavigateFunction) => async () => {
  try{
    dispatch(preloaderChangeStatus(true))
    await trigger({ businessId, tariffId }).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
}

export const TariffTableCard:FC<TariffDTO> = (tariff) => {
  const {
    id,
    name,
    nameFrench,
    nameDeutsch,
    business,
    contractsSize,
    pricePerKWh,
    annualWhLimit,
    monthlyFee,
    country,
    region
  } = tariff
  const intl = useIntl();
  const [deleteTrigger] = tariffsApi.useDeleteTariffMutation()
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [currentLanguage] = useGetUserLanguage()
  
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const nameMap: Record<string, string> = {
    [LanguageEnum.ENGLISH]: name,
    [LanguageEnum.FRENCH]: nameFrench,
    [LanguageEnum.DEUTSCH]: nameDeutsch,
  }
  
  return (
    <TableCard>
      <MenuWrapper>
        <MenuButton handleClick={handleClick}/>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={tariffTableCardSx.popover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Button
            sx={tariffTableCardSx.menuBtn}
            onClick={handleDeleteTariff(deleteTrigger, dispatch, business.id, id, navigate)}
          >
            <Typography sx={tariffTableCardSx.menuBtnText}>
              {intl.formatMessage({id: "delete"})}
            </Typography>
          </Button>
        </Popover>
      </MenuWrapper>
      <TableRow
        titleId='tariffNameColumnHeader'
        isColumn
        link={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.tariffs, String(id)])}
        value={
          nameMap[currentLanguage]
        }
      />
      <TableRow
        titleId='business'
        value={business?.name}
      />
      <TableRow
        titleId='countryColumnHeader'
        value={country ?? '-'}
      />
      <TableRow
        titleId='regionColumnHeader'
        value={region ?? '-'}
      />
      <TableRow
        titleId='activeClientsColumnHeader'
        value={contractsSize ?? "-"}
        icon={<UserIcon />}
      />
      <TableRow
        titleId='annualLimitColumnHeader'
        value={annualWhLimit ? annualWhLimit / 1000 : 0}
      />
      <TableRow
        titleId='pricePerColumnHeader'
        value={pricePerKWh ? fixedDigits(pricePerKWh, 10000) : 0}
      />
      <TableRow
        titleId='pricePerMonthColumnHeader'
        value={monthlyFee ? monthlyFee / 100 : 0}
      />
      <Box sx={tariffTableCardSx.statusBtn}>
        <TariffStatusButton tariff={tariff} />
      </Box>
    </TableCard>
  )
}
