import theme from "../../../theme";

export const policyDeutschSx = {
  paragraph: {
    my: 1,
    fontSize: theme.spacing(14/8),
    lineHeight: 1.5
  },
  header: {
    lineHeight: 1.5,
    my: 4
  },
  root: {
    '& .MuiTypography-h2': {
      lineHeight: 1.5
    },
    '& .MuiTypography-h3': {
      lineHeight: 1.5,
      fontSize: theme.spacing(24/8)
    },
    '& ol li': {
      listStyle: "auto"
    },
    '& ul li': {
      listStyle: 'disc'
    }
  },
  link: {
    color: theme.palette.primary.main
  }
}
