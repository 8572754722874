import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'src/axiosBaseQuery'
import {
  InvoicePdfRequest,
  InvoiceSearchRequest,
  PageInvoiceDTO,
  PageRevenueInvoiceDTO,
  RevenueInvoicePdfRequest,
  RevenueInvoiceSearchRequest
} from 'src/types'
import { getBaseURL } from 'src/utils/helper'

export const invoicesApi = createApi({
  reducerPath: 'invoicesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL()),
  }),
  endpoints: (build) => ({
    getInvoices: build.query<PageInvoiceDTO, InvoiceSearchRequest>({
      query: ({ data, params }) => ({ url: '/invoices/search', method: 'post', data, params }),
    }),
    getInvoicesByBusinesses: build.query<PageRevenueInvoiceDTO, RevenueInvoiceSearchRequest>({
      query: ({ data, params }) => ({ url: '/revenue_invoices/search', method: 'post', data, params }),
    }),
    getInvoicePdf: build.query<string, InvoicePdfRequest>({
      query: ({businessId, invoiceId, userId }) => ({
        url: `/invoices/${invoiceId}/pdf`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob',
        params: {businessId, userId}
      }),
      transformResponse(baseQueryData) {
        if(baseQueryData instanceof Blob){
          return URL.createObjectURL(baseQueryData)
        }
        return ''
      }
    }),
    revenueInvoicePdf: build.query<string, RevenueInvoicePdfRequest>({
      query: ({businessId, invoiceId}) => ({
        url: `/revenue_invoices/${invoiceId}/pdf`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob',
        params: {businessId}
      }),
      transformResponse(baseQueryData) {
        if(baseQueryData instanceof Blob){
          return URL.createObjectURL(baseQueryData)
        }
        return ''
      }
    }),
  }),
})
