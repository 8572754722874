import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
  Typography
} from '@mui/material';
import {
  FC,
  FocusEvent,
  useState
} from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { SelectValue } from 'src/types';
import { ColoredStatus } from '../ColoredStatus';
import { ColorField } from '../ColorField';
import { CustomFormLabel } from '../CustomFormLabel';
import { FormHelper } from '../FormHelper';
import { containerSx, descriptionSx, placeholderSx, placeholderWrapperSx } from './AMPFormSelect.sx';

interface SelectInputProps {
  label?: string,
  input: string,
  disabled?: boolean,
  value: string | number;
  onBlur?: (e: FocusEvent<any>) => void;
  onChange?: (e: SelectChangeEvent<any>) => void;
  onClick?: (value: string | number) => void
  hasError?: boolean | string;
  helperText?: string;
  className?: string;
  required?: boolean;
  placeholder?: string,
  description?: string,
  sx?: SxProps<Theme>,
  select?: boolean,
  data: SelectValue[],
  defaultValue?: boolean
}

export const AMPFormSelect: FC<SelectInputProps> = ({
  input,
  hasError,
  helperText,
  data,
  label,
  onChange,
  onClick,
  description,
  disabled,
  value,
  ...rest
}) => {
  const intl = useIntl();
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true)
  const handleFocusField = () => {
    setShowPlaceholder(false)
  } 
  const handleBlurField = () => {
    rest.placeholder && setShowPlaceholder(true)
  }
  
  const handleClick = (value: string | number):MenuItemProps['onClick'] => (e) => {
    onClick && onClick(value)
  } 

  const inputIsStatus = input === 'status'
  const inputIsRole = input === 'role'
  const optionAsText = !(inputIsStatus || inputIsRole)
  
  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={!!hasError}
      margin="normal"
      sx={containerSx}
    >
      {label && (
        <CustomFormLabel
          label={intl.formatMessage({ id: label })}
          required={rest.required}
        />
      )}
      <Box sx={placeholderWrapperSx}>
        {!value && (
          <Typography
            sx={placeholderSx}
            variant="h5"
          >
            <FormattedMessage id="select" />
          </Typography>
        )}
        <Select
          labelId={input}
          id={input}
          name={input}
          IconComponent={KeyboardArrowDown}
          onChange={onChange}
          onFocus={handleFocusField}
          onBlur={handleBlurField}
          defaultValue={data.length ? data[0].value : ''}
          value={data.length ? value : ''}
          disabled={!data.length || disabled}
          sx={rest.sx}
        >
          {data?.length && data?.map((
            item: SelectValue
          ) => {
            return (
              <MenuItem
                key={item.id}
                value={item.value}
                onClick={handleClick(item.value)}
              >
                {inputIsStatus && (
                  <ColoredStatus 
                    label={item.name}
                  />
                )}
                {inputIsRole && (
                  <ColorField
                    label={item.name}
                  />
                )}
                {optionAsText && item.name}
              </MenuItem>
            )})}
        </Select>
      </Box>
      {description && (
        <Typography sx={descriptionSx}>
          {intl.formatMessage({ id: description })}
        </Typography>
      )}
      {helperText && (
        <FormHelper helperText={helperText} />
      )}
    </FormControl>
  );
};
