import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { AMPAutocompleteProps } from "src/components/common/AMPAutocomplete/AMPAutocomplete.types";
import { findAutocompleteValueById } from "src/utils/helper";
import { MultipleBarChart } from "../../MultipleBarChart";
import { AMPAutocomplete } from "../../../common/AMPAutocomplete";
import ability from "../../../../permissions/ability";
import { UsersChartProps } from "./UsersChart.types";
import { usersChartSx } from "./UsersChart.sx";

export const UsersChart:FC<UsersChartProps> = ({
  data,
  dataKeyLast,
  dataKeyCurrent,
  businessesData,
  currentBusiness,
  handleBusinessChange
}) => {
  const intl = useIntl()
  const canReadBusinessReports = ability.can('read', 'businessReports')
  
  const handleChangeBusinessValue: AMPAutocompleteProps['onChange'] = (e, newValue) => {
    if(newValue){
      handleBusinessChange(newValue.id)
    }
  }

  return (
    <Box sx={usersChartSx.root}>
      <Stack sx={usersChartSx.top}>
        <Typography variant="subtitle1">
          {intl.formatMessage({ id: "comparisonUsers" })}
        </Typography>
        {canReadBusinessReports &&
          <Box sx={usersChartSx.select}>
            <AMPAutocomplete
              options={businessesData}
              value={findAutocompleteValueById(businessesData, currentBusiness)}
              label={'business'}
              input={'businessId'}
              showAvatar
              onChange={handleChangeBusinessValue}
            />
          </Box>}
      </Stack>
      <MultipleBarChart
        data={data}
        dataKeyFirst={dataKeyLast}
        dataKeySecond={dataKeyCurrent}
      />
    </Box>
  )
}
