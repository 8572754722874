import { FC, useEffect, useMemo, useState } from "react";
import { Form, Formik, FormikHandlers, FormikProps } from "formik";
import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { actionsWrapperSx, formContentSx, formSx } from "../EditUserForm/EditUserForm.sx";
import { FormattedMessage, useIntl } from "react-intl";
import { AMPFormSelect } from "../../common/AMPFormSelect";
import { currencies, titles } from "../formData/common/initialData";
import { AMPInput } from "../../common/AMPInput";
import { getHelperText } from "../../../utils/forms";
import { CancelButton } from "../../common/CancelButton";
import { SubmitButton } from "../../common/SubmitButton";
import { EditBusinessFormProps } from "./EditBusinessForm.types";
import { EditBusinessFormType, editUserNameFormSchema } from "../formData/editBusiness";
import { RoleEnum } from "../../../enums";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { businessesApi } from "../../../api";
import { store } from "../../../app/store";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { NotifyError } from "../../../utils/notification";
import { BusinessCostUpdateRequest, BusinessUpdateRequest, SelectValue } from "../../../types";
import { AMPPhoneNumber } from "../../common/AMPPhoneNumber";
import { TariffType } from "../formData/tariff";
import { systemApi } from "../../../api/system";
import { handleGetCountries } from "../../../hooks/useGetCountries";
import { isErrorObject, processedLanguages, valuesTrimmer } from "../../../utils/helper";
import { allRegionsValue } from "../../../utils/consts";

type UpdateBusinessTrigger = ReturnType<typeof businessesApi.useUpdateBusinessMutation>[0];
type UpdateBusinessCostTrigger = ReturnType<typeof businessesApi.useUpdateBusinessCostMutation>[0];

const updateBusiness = (trigger: UpdateBusinessTrigger, businessId: number, handleCloseModal: () => void, isSuperAdmin: boolean) =>
  async (values: EditBusinessFormType) => {
    try {
      const data = valuesTrimmer(values)
      store.dispatch(preloaderChangeStatus(true))
      const newData: BusinessUpdateRequest = {
        businessId,
        data: {
          name: data.name,
          address: {
            region: data?.region === allRegionsValue ? undefined : data?.region,
            country: data.country,
            city: data.city,
            street: data.street,
            houseNumber: data.houseNumber,
            zipCode: data.zipCode
          },
          userInfo: {
            title: data.title,
            firstName: data.firstName,
            lastName: data.lastName,
            language: data.language,
            phoneNumber: data.phoneNumber
          }
        }
      }
      return await  trigger(newData)
    } catch(error) {
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    } finally {
      if (!isSuperAdmin) {
        handleCloseModal()
      }
      store.dispatch(preloaderChangeStatus(false))
    }
  }

const updateBusinessCost = (trigger: UpdateBusinessCostTrigger, businessId: number, handleCloseModal: () => void) =>
  async (data: EditBusinessFormType) => {
    try {
      store.dispatch(preloaderChangeStatus(true))
      const newData: BusinessCostUpdateRequest = {
        businessId,
        data: {
          userPerMonthCost: data.userPerMonthCost,
          userPerMonthCurrency: data.userPerMonthCurrency
        }
      }
      return await  trigger(newData)
    } catch(error) {
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    } finally {
      handleCloseModal()
      store.dispatch(preloaderChangeStatus(false))
    }
  }

export const EditBusinessForm:FC<EditBusinessFormProps> = ({
  businessData,
  handleShowModal
}) => {
  const intl = useIntl()
  const { lang } = useAppSelector((state) => state.common)
  
  const { authInfo } = useAppSelector(state => state.common)
  const isSuperAdmin = authInfo?.roles?.[0]?.role === RoleEnum.SUPER_ADMIN
  const [activeCountry, setActiveCountry] = useState<string>(businessData?.address?.country)
  const [editBusinessTrigger] = businessesApi.useUpdateBusinessMutation()
  const [editBusinessCostTrigger] = businessesApi.useUpdateBusinessCostMutation()
  const [getCountries, countries] = systemApi.useLazyGetCountriesQuery()
  const dispatch = useAppDispatch()
  
  useEffect(() => {
    handleGetCountries(getCountries, dispatch)
  }, [])
  
  const processedRegions = useMemo(() => {
    const initialArray: SelectValue[] = [{
      id: 0,
      name: activeCountry.length ? intl.formatMessage({id: activeCountry.toLowerCase()}) : '',
      value: allRegionsValue
    }]
    if (activeCountry.length) {
      const mappedRegions: SelectValue[] | undefined = countries.data?.find(country => country.name === activeCountry)?.
        regions?.map(region => ({
          id: region.id,
          name: intl.formatMessage({id: region.name}),
          value: region.name
        }))
      return  mappedRegions?.concat(initialArray[0])
        ?? initialArray
    }
    return initialArray
  }, [activeCountry, lang, countries?.data])

  const handleChangeCountry = (
    handleChange: FormikHandlers["handleChange"],
    setFieldValue: FormikProps<TariffType>['setFieldValue']
  ) => (event: SelectChangeEvent) => {
    setActiveCountry(event.target.value);
    setFieldValue('region', '')
    return handleChange(event);
  };
  
  const processedCountries = countries?.data?.map(country => ({
    id: country.id,
    name: intl.formatMessage({id: country.name.toLowerCase()}),
    value: country.name
  })) ?? []
  
  const initialValues: EditBusinessFormType = {
    name: businessData?.name,
    country: businessData?.address?.country,
    region: businessData?.address?.region ?? "",
    city: businessData?.address?.city,
    street: businessData?.address?.street,
    houseNumber: businessData?.address?.houseNumber,
    zipCode: businessData?.address?.zipCode,
    phoneNumber: businessData?.businessOwnerInfo?.phoneNumber,
    language: businessData?.businessOwnerInfo?.language,
    title: businessData.businessOwnerInfo?.title,
    firstName: businessData?.businessOwnerInfo?.firstName,
    lastName: businessData?.businessOwnerInfo?.lastName,
    userPerMonthCost: businessData?.userPerMonthCost,
    userPerMonthCurrency: businessData?.userPerMonthCurrency
  }
  
  const onSubmit = (data: EditBusinessFormType) => {
    updateBusiness(editBusinessTrigger, businessData.id, handleShowModal(false), isSuperAdmin)(data)
    isSuperAdmin &&
      updateBusinessCost(editBusinessCostTrigger, businessData.id, handleShowModal(false))(data)
  }
  
  const handleChangePhone = (setFieldValue: FormikProps<TariffType>['setFieldValue'], fieldName: string) =>
    (newValue: string | number | null | undefined) => {
      if(newValue){
        setFieldValue(fieldName, `+${newValue}`)
      }
    }
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editUserNameFormSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }: FormikProps<EditBusinessFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="generalInfo" />
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["name"] as string}
                    type="name"
                    label="business"
                    input="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["name"] && Boolean(errors["name"])}
                    helperText={getHelperText("name", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={2}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={titles(intl)}
                    value={values["title"] as string}
                    label={"title"}
                    input={"title"}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  lg={5}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["firstName"] as string}
                    type="firstName"
                    label="firstName"
                    input="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["firstName"] && Boolean(errors["firstName"])}
                    helperText={getHelperText("firstName", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={5}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["lastName"] as string}
                    type="lastName"
                    label="lastName"
                    input="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["lastName"] && Boolean(errors["lastName"])}
                    helperText={getHelperText("lastName", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={processedLanguages(intl)}
                    value={values["language"] as string}
                    label={"language"}
                    input={"language"}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPPhoneNumber
                    value={values["phoneNumber"]}
                    onChange={handleChangePhone(setFieldValue, "phoneNumber")}
                    label={"phoneNumber"}
                    onBlur={handleBlur}
                    hasError={
                      touched["phoneNumber"] && Boolean(errors["phoneNumber"])
                    }
                    helperText={getHelperText("phoneNumber", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  item
                >
                  <Typography
                    sx={{pt: 2}}
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    <FormattedMessage id="address" />
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={processedCountries}
                    value={values["country"] as string}
                    label={"country"}
                    input={"country"}
                    onChange={handleChangeCountry(handleChange, setFieldValue)}
                    onBlur={handleBlur}
                    hasError={touched["country"] && Boolean(errors["country"])}
                    helperText={getHelperText("country", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={processedRegions}
                    value={values["region"] as string}
                    label={"region"}
                    input={"region"}
                    disabled={!activeCountry.length}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["region"] && Boolean(errors["region"])}
                    helperText={getHelperText("region", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    type='text'
                    disabled={Boolean(!activeCountry)}
                    value={values['city']}
                    label={'city'}
                    input={'city'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['city'] && Boolean(errors['city'])}
                    helperText={getHelperText('city', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["street"] as string}
                    type={"street"}
                    label={"street"}
                    input={"street"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["street"] && Boolean(errors["street"])}
                    helperText={getHelperText("street", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["houseNumber"] as string}
                    type={"text"}
                    label={"houseNumber"}
                    input={"houseNumber"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["houseNumber"] && Boolean(errors["houseNumber"])
                    }
                    helperText={getHelperText(
                      "houseNumber",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["zipCode"] as string}
                    type={"number"}
                    label={"zipCode"}
                    input={"zipCode"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["zipCode"] && Boolean(errors["zipCode"])}
                    helperText={getHelperText("zipCode", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  visibility: isSuperAdmin ? "visible" : "hidden",
                  height: isSuperAdmin ? "auto" : 0
                }}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["userPerMonthCost"]}
                    type={"number"}
                    label={"userPerMonthCost"}
                    input={"userPerMonthCost"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["userPerMonthCost"] && Boolean(errors["userPerMonthCost"])}
                    helperText={getHelperText("userPerMonthCost", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={currencies(intl)}
                    value={values["userPerMonthCurrency"] as string}
                    label={"userPerMonthCurrency"}
                    input={"userPerMonthCurrency"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["userPerMonthCurrency"] && Boolean(errors["userPerMonthCurrency"])}
                    helperText={getHelperText("userPerMonthCurrency", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={handleShowModal(false)}
              />
              <SubmitButton
                text="save"
                color="primary"
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
