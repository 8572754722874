import { FC } from "react";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { statusTagSx } from "./StatusTag.sx";

export const StatusTag:FC<{isActive: boolean}> = ({isActive}) => {
  const intl = useIntl()
  
  return (
    isActive
      ? <Typography sx={[statusTagSx.status, statusTagSx.statusActive]}>
        {intl.formatMessage({ id: 'active' })}
      </Typography>
      : <Typography sx={[statusTagSx.status, statusTagSx.statusCompleted]}>
        {intl.formatMessage({ id: 'completed' })}
      </Typography>
  )
}
