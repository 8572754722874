import { SxProps, Theme } from "@mui/material";
import { ConnectorStatusEnum } from "src/enums";
import theme from "src/theme";

export const wrapperSx: SxProps<Theme> = {
  borderRadius: '8px 8px 0 0',
  background: theme.palette.background.default,
  marginTop: theme.spacing(20/8),
  overflow: 'hidden',
}

export const titleSx: SxProps<Theme> = {
  padding: theme.spacing(25/8, 20/8),
  marginBottom: theme.spacing(10/8),
}

export const buttonSx: SxProps<Theme> = {
  borderWidth: theme.spacing(2/8),
  borderStyle: 'solid',
  borderColor: theme.palette.primary.main,
  background: theme.palette.primary.main,
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main,
  }
}


export const emptyWrapperSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(20/8),
  padding: theme.spacing(5/8, 10/8, 25/8),
}

export const emptyTitleWrapperSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(10/8)
}

export const actionButtonWrapperSx: SxProps<Theme> = {
  width: '100%',
  background: theme.palette.neutral[100],
  borderRadius: '0 0 8px 8px',
  '& button': {
    display: 'flex',
    gap: theme.spacing(5/8),
    color: theme.palette.text.primary,
  }
}
