import * as Yup from 'yup';

export interface LocationType extends Record<string, unknown>{
  name: string,
  region: string,
  country: string,
  city: string,
  zipCode: string,
  street: string,
  houseNumber: string,
  parkingLots: number,
  availableAmperes: number
}

export const locationSchema = Yup.object().shape({
  name: Yup.string()
    .required('schemaRequiredLocationName')
    .max(255),
  country: Yup.string()
    .required('schemaRequiredCountry')
    .max(255),
  region: Yup.string()
    .required('schemaRequiredRegion'),
  street: Yup.string()
    .required('schemaRequiredStreet')
    .max(255),
  zipCode: Yup.string()
    .required('schemaRequiredZip')
    .max(255),
  houseNumber: Yup.string()
    .required('schemaRequiredBuildingNumber')
    .max(255),
  city: Yup.string()
    .required('schemaRequiredCity')
    .max(255),
});
