import { Button, Divider, Typography, Box, SelectChangeEvent } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { AMPFormSelect } from "src/components/common/AMPFormSelect";
import { SubmitButton } from "src/components/common/SubmitButton";
import { ContractStatusEnum } from "src/enums";
import { fieldsWrapperSx, actionsWrapperSx, buttonSx, formItemSx } from "./ContractsFilterForm.sx";
import { ContractsFilterFormType } from "./ContractsFilterForm.types";
import { findAutocompleteValueById } from "../../../utils/helper";
import { AMPAutocomplete } from "../../common/AMPAutocomplete";
import { Form, Formik, FormikProps } from "formik";
import { ContractFilterValue } from "../../../types";

export const ContractsFilterForm: FC<ContractsFilterFormType> = ({
  handleFilter,
  businessId,
  isFilterActive,
  handleClose,
  locations,
  businesses,
  setActiveStatus,
  activeLocation,
  activeStatus,
  activeBusiness,
  handleScrollBusiness,
  handleScrollLocations,
  handleInputLocations,
  handleInputBusiness,
  handleChangeAutocompleteValue,
  handleClear
}) => {

  const processedContractStatus = () => {
    return Object.values(ContractStatusEnum).map((status, index) => ({
      id: index,
      name: status,
      value: status
    }))
  }
  
  const handleSubmit = () => {
    handleFilter({
      businessId: activeBusiness,
      locationId: activeLocation,
      status: activeStatus,
    })
    isFilterActive(true)
    handleClose()
  }
  
  const onClear = (setFieldValue: FormikProps<ContractFilterValue>['setFieldValue']) => () => {
    handleClear()
    setFieldValue('locationId', undefined)
    setFieldValue('status', undefined)
    !businessId && setFieldValue('businessId', undefined)
  }
  
  const handleStatusChange = (setFieldValue: FormikProps<ContractFilterValue>['setFieldValue']) => (e: SelectChangeEvent) => {
    const value = e.target.value
    setFieldValue('status', value)
    setActiveStatus(value as ContractStatusEnum)
  }
  
  return (
    <Formik
      initialValues={{
        businessId: activeBusiness,
        locationId: activeLocation,
        status: activeStatus
      }}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        setFieldValue,
        values,
      }: FormikProps<any>) => (
        <Form
          onSubmit={handleSubmit}
        >
          <Box sx={fieldsWrapperSx}>
            {Boolean(businesses.length) &&
          <AMPAutocomplete
            options={businessId
              ? [{
                id: businessId,
                label: ''
              }]
              : businesses}
            value={findAutocompleteValueById(businesses, values['businessId'])}
            label={'business'}
            input={'businessId'}
            handleScroll={handleScrollBusiness}
            onInputChange={handleInputBusiness}
            showAvatar
            sx={formItemSx}
            onChange={handleChangeAutocompleteValue(setFieldValue, 'businessId')}
          />
            }
            <AMPFormSelect
              data={processedContractStatus()}
              value={values['status'] ?? 0}
              label={"contractStatus"}
              input={"role"}
              onChange={(handleStatusChange(setFieldValue))}
            />
            <AMPAutocomplete
              options={locations}
              value={findAutocompleteValueById(locations, values['locationId'])}
              disabled={!activeBusiness}
              handleScroll={handleScrollLocations}
              onInputChange={handleInputLocations}
              label={'location'}
              input={'locationId'}
              onChange={handleChangeAutocompleteValue(setFieldValue, 'locationId')}
            />
          </Box>
          <Divider />
          <Box sx={actionsWrapperSx}>
            <Button
              variant="outlined"
              sx={buttonSx}
              onClick={onClear(setFieldValue)}
            >
              <Typography
                variant="button"
                color="neutral.700"
              >
                <FormattedMessage id="clear" />
              </Typography>
            </Button>
            <SubmitButton
              text="apply"
              style={formItemSx}
            />
          </Box>
        </Form>)}
    </Formik>
  )
}
