import { alpha, SxProps, Theme } from "@mui/material"
import theme from "src/theme"

export const wrapperSx: SxProps<Theme> = {
  borderRadius: theme.spacing(4/8),
  background: theme.palette.background.default,
  overflow: 'hidden',
  padding: theme.spacing(24/8),
  '& .react-pdf__Document': {
    width: '100%',
    padding: theme.spacing(60/8)
  },
  '& .pdfPage .react-pdf__Page__canvas': {
    width: '100% !important',
    height: 'auto !important'
  }
}
export const titleSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(8/8)
}

export const documentWrapperSx: SxProps<Theme> = {
  position: 'relative'
}

export const documentButtonsSx: SxProps<Theme> = {
  opacity: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  gap: theme.spacing(10/8),
  top: 0,
  left: 0,
  zIndex: 2,
  padding: theme.spacing(20/8),
  background: alpha(theme.palette.bg.dark, 0.3),
  transition: '0.3s ease',
  '& .MuiButtonBase-root': {
    background: theme.palette.neutral[50],
    borderRadius: theme.spacing(5/8),
    '&.Mui-disabled': {
      background: theme.palette.neutral[300]
    }
  },
  '&:hover': {
    opacity: 1,
  }
}

export const SubmitBtnSx: SxProps = {
  width: "100%",
  alignItems: "end"
}
