import { Box } from "@mui/material";
import { FC } from "react";
import { styles } from "./AbsoluteImageTemplate.sx";
import { AbsoluteImageTemplateProps } from "./AbsoluteImageTemplate.types";


export const AbsoluteImageTemplate: FC<AbsoluteImageTemplateProps> = ({ children }) =>  (
  <Box sx={styles}>
    {children}
  </Box>
)
