import {
  useContext,
  useEffect,
  createContext,
  ReactNode,
  FC,
} from 'react';
import { ConnectionChanel } from '../serviсes';

export const ConnectionContext = createContext<{connect: ConnectionChanel}>({connect: new ConnectionChanel()});

export const useConnection = () => useContext(ConnectionContext);

interface ConnectionProviderProps {
  children: ReactNode,
  connect: ConnectionChanel
}

const ConnectionProvider: FC<ConnectionProviderProps> = ({ children, connect }) => {
  useEffect(() => {
    connect.activateChannel();
    return () => connect.onDeactivate();
  }, []);

  const value = {
    connect,
  };
  return (
    <ConnectionContext.Provider value={value}>
      {children}
    </ConnectionContext.Provider>
  );
};

export default ConnectionProvider;
