import { SxProps, Theme } from "@mui/material";

export const documentWrapperSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  '& .react-pdf__Document': {
    width: '100%'
  },
  '& .pdfPage .react-pdf__Page__svg, & .pdfPage .react-pdf__Page__svg svg': {
    width: '100% !important',
    height: 'auto !important'
  }
}