import { FC, useContext } from "react";
import { ResponsiveTableProps } from "./ResponsiveTable.types";
import DashboardLayoutContext from "../../../layouts/DashboardLayout/DashboardLayout.context";

export const ResponsiveTable:FC<ResponsiveTableProps> = ({
  responsiveElement,
  desktopElement
}) => {
  const responsive = useContext(DashboardLayoutContext)
  
  return (
    <>
      {responsive ? responsiveElement : desktopElement}
    </>
  )
}