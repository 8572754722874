import { ChangeEvent, FC, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { wrapperSx } from "./Notifications.sx";
import { EmptyStatus } from "../../components/notifications/EmptyStatus";
import { NotificationCard } from "../../components/notifications/NotificationCard";
import { RoleEnum } from "../../enums";
import { notificationApi } from "../../api/notifications";
import { NotificationsSortEnum, NotificationTypeEnum, ReactLocationStateType } from "../../types";
import { isErrorObject } from "../../utils/helper";
import { NotifyError } from "../../utils/notification";
import { store } from "../../app/store";
import { preloaderChangeStatus } from "../../stores/common/commonSlice";
import { useAppSelector } from "../../app/hooks";
import { roleStatusRequest } from "../../utils/consts";
import { tableFooterSx } from "../../components/locations/LocationTable/LocationTable.sx";
import { TableRowCounter } from "../../components/tables/TableRowCounter";
import { TablePagination } from "../../components/tables/TablePagination";
import { useSearchParams, useLocation } from "react-router-dom";

type GetNotificationTriggerType = ReturnType<typeof notificationApi.useLazyGetNotificationsQuery>[0];

const handleGetNotifications = (
  trigger: GetNotificationTriggerType,
  businessId: number,
  notificationTypes: NotificationTypeEnum[],
  pageSize: number,
  page: number
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    trigger({
      params: {
        size: pageSize,
        page
      },
      data: {
        businessId,
        notificationTypes,
        sortField: NotificationsSortEnum.CREATED
      }
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
}

export const Notifications:FC = () => {
  const [getNotifications, notificationsData] = notificationApi.useLazyGetNotificationsQuery()
  const { authInfo } = useAppSelector((state) => state.common);
  const role = authInfo?.roles?.[0]?.role as RoleEnum
  const [perPage, setPerPage] = useState(50)
  const [currentPage, setCurrentPage] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  
  useEffect(() => {
    const currentState = location.state as ReactLocationStateType | undefined;
    const currentSearch = location.search
    const page = currentState
      ? +currentState?.page
      : +currentSearch?.replace(/(\D+)(\d+)$/, "$2") ?? currentPage;
    handleGetNotifications(
      getNotifications,
      authInfo?.business?.id,
      roleStatusRequest[role],
      perPage,
      page
    )
  }, [perPage, currentPage])
  
  const handlePerPageChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = +e.target.value
    if (typeof val === 'number') {
      setPerPage(val)
      setCurrentPage(0)
    }
  }
  
  const handleChangePage = (e: number) => {
    setCurrentPage(e)
    setSearchParams({
      page: String(e)
    })
  }
  
  return (
    <Box>
      <Typography
        variant="h2"
      >
        <FormattedMessage id="notifications"/>
      </Typography>
      <Stack sx={wrapperSx}>
        {!notificationsData?.data?.content?.length
          && <EmptyStatus/>
        }
        {!!notificationsData?.data?.content?.length
          && notificationsData?.data?.content.map(item => (
            <NotificationCard
              page={currentPage}
              key={item.id}
              {...item}
            />
          ))}
      </Stack>
      {!!notificationsData?.data?.content?.length &&
        <Box sx={tableFooterSx}>
          <TableRowCounter
            defaultValue={perPage}
            handleChangeRowsNumber={handlePerPageChange}
          />
          <TablePagination
            totalPages={notificationsData.data.totalPages}
            page={notificationsData.data.number}
            handleChangePage={handleChangePage}
          />
        </Box>
      }
    </Box>
  )
}
