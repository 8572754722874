import { SxProps, Theme, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import theme from "src/theme";
import { useSxValue } from "./ColoredStatus.hook";
import { ColoredStatusType } from "./ColoredStatus.types";

export const ColoredStatus: FC<ColoredStatusType> = ({label, fontSize = 14}) => {
  const sxValue: SxProps<Theme> = Object.assign({}, useSxValue(label), {
    padding: theme.spacing(3/8, 6/8),
    borderRadius: theme.spacing(4/8),
    fontWeight: 500,
    fontSize,
    width: 'max-content'
  })
  
  return ( 
    <Typography
      sx={sxValue}
    >
      <FormattedMessage id={label} />
    </Typography>
  )
}
