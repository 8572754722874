import { FC } from "react";
import { BaseUserInfo } from "../../../types";
import { UserCell } from "../../users/UserCell";
import { TableCard } from "../../tables/respnsiveTable/TableCard";
import { TableRow } from "../../tables/respnsiveTable/TableRow";

export const ClientTableCard:FC<BaseUserInfo> = (userItem) => {
  const {
    chargePointId,
  } = userItem
  
  return (
    <TableCard>
      <TableRow
        titleId='client'
        isColumn
        customValue={<UserCell data={userItem} />}
      />
      <TableRow
        titleId='chargePointIdColumnHeader'
        value={chargePointId}
      />
    </TableCard>
  )
}
