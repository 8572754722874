import { Grid, Typography, Box, Button } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ocppApi } from "src/api";
import { ProgressActiveChargingIcon } from "src/components/icons/ProgressActiveChargingIcon";
import { ActiveChargingType } from "./ActiveCharging.types";
import { wrapperSx, progressBarWrapperSx, statusButtonSx, infoWrapperSx, connectorsWrapperSx } from "./ActiveCharging.sx";
import { AmpersIcon } from "src/components/icons/AmpersIcon";
import { convertTime, isErrorObject } from "src/utils/helper";
import { DurationIcon } from "src/components/icons/DurationIcon";
import { ConnectorActionCard } from "src/components/connectors/ConnectorActionCard";
import { useAppSelector } from "src/app/hooks";
import { ChargePointStatus, ConnectorStatusEnum } from "src/enums";
import ability from "src/permissions/ability";
import { NotifyError, NotifyWarning } from "src/utils/notification";
import { CHARGE_POINT_STATUS_TYPE, CHARGING_STATUS_TYPE, CONNECTOR_STATUS_TYPE } from "src/types";

type ActiveChargingMutationTrigger = ReturnType<typeof ocppApi.useLazyGetActiveTransactionQuery>[0]

const handleGetActiveCharging = (
  trigger: ActiveChargingMutationTrigger,
  businessId: number,
  chargerId: number,
  connectorId: number
) => async () => {
  try {
    await trigger({ businessId, chargerId, connectorId })
  } catch (error){
    if(isErrorObject(error)){
      NotifyWarning(error.data.message)
    }
  } 
}

export const ActiveCharging: FC<ActiveChargingType> = ({ data }) => {
  const intl = useIntl()
  const [getActiveTransaction, activeTransaction] = ocppApi.useLazyGetActiveTransactionQuery()
  const { CONNECTOR_STATUS, CHARGING_STATUS }: {CONNECTOR_STATUS: CONNECTOR_STATUS_TYPE[], CHARGING_STATUS: CHARGING_STATUS_TYPE[]} = useAppSelector(state => state.common)
  const [connectorStatus, setConnectorStatus] = useState<string>("")
  const [wattsConsumed, setWattsConsumed] = useState<number>(0)
  const [amperage, setAmperage] = useState<string>("")
  const [chargingTime, setChargingTime] = useState<number>(0)
  
  useEffect(() => {
    if(data.connectors.length){
      setConnectorStatus(data.connectors[0].currentStatus)
      data.connectors[0].currentStatus && handleGetActiveCharging(getActiveTransaction, data.business.id, data.id, data.connectors[0].id)()
    }
  }, [data])

  useEffect(() => {
    if(activeTransaction.data){
      setConnectorStatus(data.connectors[0].currentStatus)
      setWattsConsumed(activeTransaction.data.wattsConsumed)
      setAmperage(activeTransaction.data.amperage)
      setChargingTime(activeTransaction.data.chargingTimeMinutes)
    }
  }, [activeTransaction])

  useEffect(() => {
    const currentChargePoint = CHARGING_STATUS?.filter(item => item.chargePointId === data.chargePointId)[0]
    if(currentChargePoint){ 
      setWattsConsumed(currentChargePoint.wattsConsumed)
      setAmperage(currentChargePoint.amperage)
      setChargingTime(currentChargePoint.chargingTimeMinutes)
    }
  }, [CHARGING_STATUS])

  useEffect(() => {
    const currentConnectorStatus = CONNECTOR_STATUS?.filter(item => item.chargerId === data.id)[0]
    if(currentConnectorStatus){ 
      setConnectorStatus(currentConnectorStatus.status)
    }
  }, [CONNECTOR_STATUS])

  const [startCharging] = ocppApi.useStartChargingMutation()
  const [stopCharging] = ocppApi.useStopChargingMutation()

  const handleStartStopCharging = async () => {
    
    try{
      connectorStatus !== ConnectorStatusEnum.CHARGING
        ? await startCharging({
          rfId: data.defaultOcppTag.idTag,
          chargerId: data.id,
          connectorId: data.connectors[0].connectorId,
          businessId: data.business.id
        })
        : await stopCharging({
          chargerId: data.id,
          connectorId: data.connectors[0].connectorId,
          businessId: data.business.id
        })
        
    } catch (error){
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    }
  }
  
  const isConnectorCharging = connectorStatus === ConnectorStatusEnum.CHARGING
  const isConnectorPreparing = connectorStatus === ConnectorStatusEnum.PREPARING
  const isConnectorStatusNotFulfilling = isConnectorCharging || isConnectorPreparing
  const isChargerOffline = data.currentStatus === ChargePointStatus.OFFLINE
  const canManageCharging = ability.can('manage', 'activeCharging')

  return (
    <Box
      sx={wrapperSx}
    >
      <Grid 
        container
        spacing={0}
      >
        <Grid
          item
          xs={6}
          sx={progressBarWrapperSx}
        >
          <Box>
            {Boolean(wattsConsumed) && isConnectorCharging && 
            <>
              <Typography variant="body1">
                <FormattedMessage id="delivered" />
              </Typography>
              <Typography variant="h4">
                {(wattsConsumed / 1000).toFixed(2)}
                <FormattedMessage id="kwh" />
              </Typography>
            </>
            }
          </Box>
          <ProgressActiveChargingIcon percent={wattsConsumed ? wattsConsumed / 1000 : 0}/>
          <Button
            type="submit"
            variant="contained"
            sx={statusButtonSx(data.connectors[0].currentStatus)}
            disabled={isChargerOffline || !canManageCharging || !isConnectorStatusNotFulfilling}
            onClick={handleStartStopCharging}
          >
            <Typography
              variant="button"
              color="textSecondary"
            >
              <FormattedMessage id={connectorStatus === ConnectorStatusEnum.CHARGING ? 'stop' : 'start'} />
            </Typography>
          </Button>
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={0}
        >
          <Grid
            item
            xs={12}
            sx={infoWrapperSx}
          >
            <AmpersIcon />
            <Box>
              <Typography
                variant="body1"
                color="neutral"
              >
                <FormattedMessage id="amperage" />
              </Typography>
              <Typography
                variant="h5"
                lineHeight='1.4'
              >
                {amperage && isConnectorCharging ? (+amperage).toFixed(2) : intl.formatMessage({id: 'waitingFoData'})}
                {amperage && <FormattedMessage id='a' />}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={infoWrapperSx}
          >
            <DurationIcon />
            <Box>
              <Typography
                variant="body1"
                color="neutral"
              >
                <FormattedMessage id="duration" />
              </Typography>
              <Typography
                variant="h5"
                lineHeight='1.4'
              >
                {chargingTime && isConnectorCharging ? convertTime(chargingTime) : '00h:00m'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={connectorsWrapperSx}
        >
          {data.connectors.map((connector, index) => (
            <ConnectorActionCard
              key={index}
              chargerId={data.id}
              rfId={data.defaultOcppTag.idTag}
              connector={connector}
              chargerStatus={data.currentStatus}
              connectorDescription={data.model.connectorDescriptions[index]}
              status={connectorStatus}
              businessId={data.business.id}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}
