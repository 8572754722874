import { FC, useEffect } from "react";
import { Box, Divider, Link, Typography } from "@mui/material";
import { supportSx } from "./Support.sx";
import { FormattedMessage } from "react-intl";
import { supportLinks } from "src/utils/consts";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { AppRouteSpace, AppRouteEnum } from "src/utils/helper";
import { changeLang } from "src/stores/common/commonSlice";
import { useLocation } from "react-router-dom";

export const SupportDe:FC = () => {
  const { lang } = useAppSelector(state => state.common)
  const dispatch = useAppDispatch()
  const location = useLocation()
  useEffect(() => {
    if(location.pathname !== AppRouteSpace([AppRouteEnum.support, lang])){
      dispatch(changeLang(location.pathname.replace(/\/(.+)\/(\w+)/, '$2')))
    }
  }, [lang])
  return (
    <Box>
      <Box sx={supportSx.root}>
        <Typography
          variant='h3'
          sx={supportSx.paragraph}
        >
          <FormattedMessage id="helpContact" />
        </Typography>
        <Typography
          variant='h2'
          sx={supportSx.header}
        >
          <FormattedMessage id="tenants" />
        </Typography>
        <Typography
          variant="h5"
          sx={supportSx.header}
          fontWeight="800"
        >
          <FormattedMessage id="helpline" />
        </Typography>
        <Typography
          variant="subtitle1"
          sx={supportSx.link}
          fontWeight="800"
        >
          <Link href={`tel:${supportLinks.helpPhoneNumber}`}>
            {supportLinks.helpPhoneNumber}
          </Link>
        </Typography>
        <Typography
          variant="h5"
          sx={supportSx.header}
          fontWeight="800"
        >
          <FormattedMessage id="writeUs" />
        </Typography>
        <Typography
          variant="subtitle1"
          sx={supportSx.link}
          fontWeight="800"
        >
          <Link href={`mailto:${supportLinks.helpEmail}`}>
            {supportLinks.helpEmail}
          </Link>
        </Typography>
        <Divider
          light
          sx={supportSx.divider}
        />
        <Typography
          variant='h2'
          sx={supportSx.header}
        >
          <FormattedMessage id="companiesOwners" />
        </Typography>
        <Typography
          variant="h5"
          sx={supportSx.header}
          fontWeight="800"
        >
          <FormattedMessage id="officeWorkTime" />
        </Typography>
        <Typography
          variant="subtitle1"
          sx={supportSx.link}
          fontWeight="800"
        >
          <Link href={`tel:${supportLinks.companyPhoneNumber}`}>
            {supportLinks.companyPhoneNumber}
          </Link>
        </Typography>
        <Typography
          variant="h5"
          sx={supportSx.header}
          fontWeight="800"
        >
          <FormattedMessage id="writeUs" />
        </Typography>
        <Typography
          variant="subtitle1"
          sx={supportSx.link}
          fontWeight="800"
        >
          <Link href={`mailto:${supportLinks.companyEmail}`}>
            {supportLinks.companyEmail}
          </Link>
        </Typography>
      </Box>
    </Box>
  )
}