import { SxProps, Theme } from "@mui/material";
import theme from "../../../theme";

export const btnWrapperSx = {
  pt: 1,
  height: theme.spacing(30/8)
}
export const unreadSx = {
  p: 0
}
export const cardSx = (read: boolean):SxProps<Theme> => ({
  backgroundColor: read ? 'transparent' : theme.palette.background.paper,
  borderRadius: theme.spacing(12/8),
  py: theme.spacing(12/8),
  px: theme.spacing(32/8),
  mb: 2,
  display: 'flex',
  mt: 2,
  color: theme.palette.text.primary,
  "&:hover": {
    transition: "box-shadow 0.2s ease-in-out",
    boxShadow: `0
     ${theme.spacing(-1.75/8)}
     ${theme.spacing(4/8)}
     rgba(33, 33, 52, 0.039),
     0
     ${theme.spacing(5.75/8)}
     ${theme.spacing(10/8)}
     rgba(33, 33, 52, 0.12)`
  },
  "& .MuiButton-root": {
    display: "none"
  },
  "&:hover .MuiButton-root": {
    display: read ? "block" : "none"
  },
  [theme.breakpoints.down('md')]: {
    px: theme.spacing(16/8),
  }
})
export const dateBoxLgSx:SxProps<Theme> = {
  display: "block",
  [theme.breakpoints.down('lg')]: {
    display: "none"
  }
}
export const dateBoxSmSx:SxProps<Theme> = {
  display: "none",
  [theme.breakpoints.down('lg')]: {
    display: "block"
  }
}
export const detailsBoxSx:SxProps<Theme> = {
  alignItems: "flex-end",
  justifyContent: "space-between",
  [theme.breakpoints.down('lg')]: {
    pt: 2,
    alignItems: "flex-start"
  }
}
export const readBoxSx:SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: 1
}
export const visibilitySx:SxProps<Theme> = {
  color: theme.palette.neutral[400],
  width: theme.spacing(20/8)
}
export const dateSx:SxProps<Theme> = {
  fontSize: theme.spacing(12/8),
  fontWeight: 400,
  color: theme.palette.neutral[400],
  textAlign: 'right',
  [theme.breakpoints.down('lg')]: {
    textAlign: 'left'
  }
}
export const cardTextSx:SxProps<Theme> = {
  fontSize: theme.spacing(12/8),
  fontWeight: 500
}
export const titleTextSx:SxProps<Theme> = {
  color: theme.palette.neutral[400],
  fontSize: theme.spacing(10/8),
  mb: 1
}
export const roleTextSx:SxProps<Theme> = {
  fontSize: theme.spacing(10/8),
  fontWeight: 500,
  width: "fit-content",
  textTransform: "uppercase",
  color: theme.palette.primary.dark,
  backgroundColor: theme.palette.primaryColor[100],
  px: 1,
  py: 0.2,
  my: 1,
  borderRadius: theme.spacing(4/8)
}
export const colSx:SxProps<Theme> = {
  display: "flex",
  flexDirection: "column"
}
export const textBoxSx:SxProps<Theme> = {
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.down('md')]: {
    flexDirection: "column"
  }
}
export const newMarkSx:SxProps<Theme> = {
  position: "absolute",
  left: theme.spacing(-10/8),
  top: 0,
  borderRadius: "100%",
  backgroundColor: theme.palette.error.dark,
  width: theme.spacing(10/8),
  height: theme.spacing(10/8)
}
export const avatarBoxSx:SxProps<Theme> = {
  pr: 1,
  position: "relative"
}
export const avatarSx:SxProps<Theme> = {
  width: theme.spacing(48/8),
  height: theme.spacing(48/8)
}
