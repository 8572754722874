import { SxProps, Theme } from "@mui/material";
import theme from "../../../../theme";

export const boxSx:SxProps<Theme> = {
  py: 2,
  gap: 1,
  alignItems: "center",
  maxWidth: theme.spacing(300/8)
}

export const textSx:SxProps<Theme> = {
  textAlign: 'center',
  mb: 2
}

export const btnSx:SxProps<Theme> = {
  mb: 4,
  backgroundColor: theme.palette.primaryColor[50]
}
