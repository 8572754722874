import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const tableLgSx: SxProps<Theme> = {
  display: "block",
  [theme.breakpoints.down('lg')]: {
    display: "none"
  }
}

export const tableSmSx: SxProps<Theme> = {
  display: "none",
  [theme.breakpoints.down('lg')]: {
    display: "block"
  }
}

export const tableFooterSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(10/8, 0),
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
  }
}
