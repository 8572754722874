import { FC } from "react";
import { Form, Formik, FormikProps } from "formik";
import { Box, Grid, Typography } from "@mui/material";
import { actionsWrapperSx, formContentSx, formSx } from "../CreateRfidForm/CreateRfidForm.sx";
import { AMPInput } from "../../common/AMPInput";
import { getHelperText } from "../../../utils/forms";
import { CancelButton } from "../../common/CancelButton";
import { SubmitButton } from "../../common/SubmitButton";
import { CurrencyEnum } from "../../../enums";
import dayjs from "dayjs";
import { AMPDatePicker } from "../../common/AMPDatePicker";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { AMPSwitcher } from "../../common/AMPSwitcher";
import { adornmentSx } from "./CreateLocationCostForm.sx";
import { CreateLocationCostFormProps } from "./CreateLocationCostForm.types";
import { createLocationCostSchema, CreateLocationCostType } from "../formData/CreateLocationCost";
import { locationsApi } from "../../../api";
import { AppDispatch } from "../../../types";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { isErrorObject } from "../../../utils/helper";
import { NotifyError, NotifySuccess } from "../../../utils/notification";
import { useAppDispatch } from "../../../app/hooks";
import { Can } from "src/permissions/Can";

type CreateCostRequestType = ReturnType<
  typeof locationsApi.useCreateLocationCostMutation
  >[0];

const handleCreateCost = (
  intl: IntlShape,
  createTrigger: CreateCostRequestType,
  dispatch: AppDispatch,
  closeModal: () => void,
  businessId: number,
  locationId: number
) => async (values: CreateLocationCostType) => {
  try {
    dispatch(preloaderChangeStatus(true))
    const result = await createTrigger({
      locationId,
      data: {
        businessId,
        currency: values.currency,
        name: values.item,
        installationDate: values.date,
        serviceLife: values.serviceLife,
        initCost: values.cost
      }
    }).unwrap()
    NotifySuccess(intl.formatMessage({id: 'createdSuccessfully'}))
    return  result
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data?.message ?? error.data?.error)
    }
  } finally {
    dispatch(preloaderChangeStatus(false))
    closeModal()
  }
}

export const CreateLocationCostForm:FC<CreateLocationCostFormProps> = ({
  onClose,
  currentCurrency,
  locationId,
  businessId
}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [createCost] = locationsApi.useCreateLocationCostMutation()
  const initialValue: CreateLocationCostType = {
    currency: currentCurrency ?? CurrencyEnum.EUR,
    item: '',
    cost: 0,
    date: dayjs().valueOf(),
    serviceLife: 0
  }
  
  const processedCurrencies = () => {
    return Object.values(CurrencyEnum).map((value, index) => ({
      id: index,
      name: intl.formatMessage({id: value}),
      value: value
    }))
  }
  
  return (
    <Formik
      initialValues={initialValue}
      validationSchema={createLocationCostSchema}
      onSubmit={handleCreateCost(
        intl,
        createCost,
        dispatch,
        onClose,
        businessId,
        locationId
      )}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        setFieldValue,
        values,
      }: FormikProps<CreateLocationCostType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <Grid
                container
                rowSpacing={{xs: 2}}
                columnSpacing={{xs: 0, md: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPSwitcher
                    value={values["currency"]}
                    input={"currency"}
                    label={"currency"}
                    disabled={!!currentCurrency}
                    data={processedCurrencies()}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={{xs: 2}}
                columnSpacing={{xs: 0, md: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    type='text'
                    value={values['item']}
                    label={'item'}
                    input={'item'}
                    placeholder='item'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['item'] && Boolean(errors['item'])}
                    helperText={getHelperText('item', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPDatePicker
                    label="dateOfInstallation"
                    value={dayjs( values["date"]).valueOf()}
                    onChange={(val) => setFieldValue("date", dayjs( val).valueOf())}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={{xs: 2}}
                columnSpacing={{xs: 0, md: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    type='number'
                    value={values['cost']}
                    label={'cost'}
                    input={'cost'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['cost'] && Boolean(errors['cost'])}
                    helperText={getHelperText('cost', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    type='number'
                    value={values['serviceLife']}
                    label={'serviceLife'}
                    input={'serviceLife'}
                    endAdornment={
                      <Typography sx={adornmentSx}>
                        <FormattedMessage id="years" />
                      </Typography>
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['serviceLife'] && Boolean(errors['serviceLife'])}
                    helperText={getHelperText('serviceLife', touched, errors, intl)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={onClose}
              />
              <Can
                I="manage"
                a="costLocation"
              >
                <SubmitButton
                  text="save"
                  color="primary"
                  isSubmitting={isSubmitting}
                />
              </Can>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
