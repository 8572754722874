import { FC, MouseEvent, useState } from "react";
import { Popover } from "@mui/material";
import { invoiceTableCardSx } from "./InvoiceTableCard.sx";
import { ColoredStatus } from "../../../common/ColoredStatus";
import { DownloadInvoicePdf } from "../DownloadInvoicePdf";
import { InvoicePreview } from "../../InvoicePreview";
import { InvoiceTableCardTypes } from "./InvoiceTableCard.types";
import { RoleEnum } from "../../../../enums";
import { RevenueInvoicePreview } from "../../RevenueInvoicePreview";
import { TableCard } from "../../../tables/respnsiveTable/TableCard";
import { MenuButton } from "../../../tables/respnsiveTable/MenuButton";
import { MenuWrapper } from "src/components/tables/respnsiveTable/MenuWrapper";
import { TableRow } from "../../../tables/respnsiveTable/TableRow";

export const InvoiceTableCard:FC<InvoiceTableCardTypes> = ({
  adminData,
  businessData,
  role
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const data = adminData ?? businessData
  const showRevenue = role === RoleEnum.SUPER_ADMIN && adminData
  const showInvoice = role !== RoleEnum.SUPER_ADMIN && businessData
  
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <TableCard>
      {data?.bexioId &&
        <MenuWrapper>
          <MenuButton handleClick={handleClick}/>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={invoiceTableCardSx.popover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <DownloadInvoicePdf
              invoiceId={data?.id}
              name={data?.invoiceNumber}
              businessId={role === RoleEnum.SUPER_ADMIN
                ? adminData?.business?.id
                : undefined}
              isIcon={false}
            />
          </Popover>
        </MenuWrapper>
      }
      <TableRow
        titleId='client'
        customValue={
          <>
            {showRevenue && <RevenueInvoicePreview data={adminData} />}
            {showInvoice && <InvoicePreview data={businessData} />}
          </>
        }
      />
      <TableRow
        titleId='invoiceNumberColumnHeader'
        value={data?.invoiceNumber}
      />
      <TableRow
        titleId='invoiceDateColumnHeader'
        value={data?.created ? new Date(data.created).toLocaleString('fr-CH'): "-"}
      />
      <TableRow
        titleId='invoiceAmountColumnHeader'
        value={data?.amount ? `${data?.amount / 100} ${data?.currency && data?.currency}` : 0}
      />
      <TableRow
        titleId='invoiceAmountColumnHeader'
        customValue={data?.status && <ColoredStatus label={data?.status} />}
      />
    </TableCard>
  )
}
