import { FC } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { ContentCopyOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import copy from "copy-to-clipboard";
import { AppRouteEnum, AppRouteSpace } from "../../../utils/helper";
import { LightningIcon } from "../../icons/LightningIcon";
import { ContractDTO } from "../../../types";
import { NotifySuccess } from "../../../utils/notification";
import { ColorField } from "../../common/ColorField";
import { contractItemSx } from "./ContractItem.sx";
import { TableCard } from "../../tables/respnsiveTable/TableCard";
import { TableRow } from "../../tables/respnsiveTable/TableRow";

export const ContractItemCard:FC<ContractDTO> = ({
  id,
  name,
  user,
  created,
  status,
  location,
  business,
}) => {
  const intl = useIntl()
  const copyToClipboard = () => {
    copy(String(id), { debug: true })
    NotifySuccess(intl.formatMessage({id: 'copiedSuccesfully'}))
  }
  
  return (
    <TableCard>
      <TableRow
        titleId='contract'
        customValue={
          <Stack sx={contractItemSx.withIcon}>
            <Link to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.contracts, String(id)])}>
              <Typography sx={[contractItemSx.dataText, contractItemSx.idText]}>
                {name}
              </Typography>
            </Link>
            <IconButton
              onClick={copyToClipboard}
              sx={[contractItemSx.iconBtn, {ml: 2}]}
            >
              <ContentCopyOutlined sx={{width: '0.75rem'}} />
            </IconButton>
          </Stack>
        }
      />
      <TableRow
        titleId='client'
        value={`${user?.firstName} ${user?.lastName}`}
      />
      <TableRow
        titleId='email'
        value={user?.emailAddress}
      />
      <TableRow
        titleId='createdOnColumnHeader'
        value={(new Date(created)).toLocaleDateString('fr-CH')}
      />
      <TableRow
        titleId='statusColumnHeader'
        customValue={<ColorField label={status} />}
      />
      <TableRow
        titleId='locationColumnHeader'
        value={location?.name ?? '-'}
      />
      <TableRow
        titleId='businessColumnHeader'
        icon={<LightningIcon />}
        value={business?.name}
      />
    </TableCard>
  )
}
