import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { CancelButton } from "../CancelButton";
import { SubmitButton } from "../SubmitButton";
import { actionsWrapperSx, wrapperSx } from "./ConformationModal.sx";
import { ConformationModalType } from "./ConformationModal.types";

export const ConformationModal: FC<ConformationModalType> = ({ text, caption, handleSubmit, handleClose}) => (
  <Box>
    <Box sx={wrapperSx}>
      <Typography variant="subtitle1">
        {caption 
          ? <FormattedMessage
            id={text}
            values={{
              name: caption
            }}
          />
          : <FormattedMessage id={text} />
        }
      </Typography>
    </Box>
    <Box sx={actionsWrapperSx}>
      <CancelButton
        text="cancel"
        handler={handleClose}
      />
      <SubmitButton
        text={"delete"}
        handler={handleSubmit}
      />
    </Box>
  </Box>
)
