import * as Yup from "yup";

export interface CreateRfidFormType {
		rfidTag: string
}

export interface UpdateRfidFormType {
		blocked: string,
		expiry: number,
		idTag: string
}

export const createRfidFormSchema = Yup.object().shape({
  rfidTag: Yup.string()
    .max(255)
    .required('schemaRequiredRfid'),
});

export const updateRfidFormSchema = Yup.object().shape({
  blocked: Yup.string(),
  expiry: Yup.number(),
  idTag: Yup.string().required('schemaRequiredRfid')
});
