import theme from "../../../theme";

export const excelDocumentSx = {
  button: {
    border: 'none',
    backgroundColor: 'transparent',
    p: 0,
    width: '100%',
    justifyContent: 'flex-start',
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: 'transparent',
    }
  }
}
