import { alpha } from "@mui/material";
import theme from "../../../../theme";

export const statusTagSx = {
  status: {
    px: 1,
    py: 0.5,
    fontWeight: 500,
    fontSize: '0.625em',
    borderRadius: theme.spacing(4/8),
  },
  statusActive: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primaryColor[50]
  },
  statusCompleted: {
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.1)
  }
}
