import { Box, Typography } from "@mui/material"
import { GridColumns } from "@mui/x-data-grid"
import dayjs from "dayjs"
import { FormattedMessage, IntlShape } from "react-intl"
import { convertMinutes } from "src/utils/helper"
import { StatusTag } from "./StatusTag";
import { colTitleSx, tableCellSx } from "./ChargingReportTable.sx"

export const chargingReportTableColumns = (businessArr: GridColumns, intl: IntlShape): GridColumns => ([
  ...businessArr,
  {
    field: intl.formatMessage({id: 'status'}),
    renderHeader: () => <Box sx={colTitleSx}>
      <FormattedMessage id={"status"} />
    </Box>,
    flex: 1,
    minWidth: 50,
    valueGetter: (params) => params?.row?.active,
    renderCell: params => <StatusTag isActive={params?.row?.active}/>
  },
  {
    field: intl.formatMessage({id: 'locationNameColumnHeader'}),
    renderHeader: () => <Box sx={colTitleSx}>
      <FormattedMessage id='locationNameColumnHeader' />
    </Box>,
    flex: 1,
    minWidth: 50,
    renderCell: params =>
      <Typography
        variant='caption'
        sx={tableCellSx}
      >
        {params?.row?.location?.name}
      </Typography>
  },
  {
    field: intl.formatMessage({id: 'evDriverName'}),
    renderHeader: () => <Box sx={colTitleSx}>
      <FormattedMessage id='evDriverName' />
    </Box>,
    flex: 1,
    minWidth: 50,
    renderCell: params =>
      <Typography
        variant='caption'
        sx={tableCellSx}
      >
        {`${params?.row?.user?.firstName} ${params?.row?.user?.lastName}`}
      </Typography>
  },
  {
    field: intl.formatMessage({ id: 'idTag' }),
    renderHeader: () => <Box sx={colTitleSx}>
      <FormattedMessage id='rfid' />
    </Box>,
    renderCell: params =>
      <Typography
        variant='caption'
      >
        {params?.row?.idTag?.idTag}
      </Typography>
  },
  {
    field: intl.formatMessage({ id: 'transactionStart' }),
    renderHeader: () => <Box sx={colTitleSx}>
      <FormattedMessage id='transactionStart' />
    </Box>,
    flex: 1,
    minWidth: 50,
    renderCell: params =>
      <Typography
        variant='caption'
        sx={tableCellSx}
      >
        {dayjs(params?.row?.startTimestamp).format('DD MMM YYYY HH:mm:ss')}
      </Typography>
  },
  {
    field: intl.formatMessage({ id: 'transactionEnd' }),
    renderHeader: () => <Box sx={colTitleSx}>
      <FormattedMessage id='transactionEnd' />
    </Box>,
    flex: 1,
    minWidth: 50,
    renderCell: params =>
      <Typography
        variant='caption'
        sx={tableCellSx}
      >
        {params?.row?.stopTimestamp ? dayjs(params?.row?.stopTimestamp).format('DD MMM YYYY HH:mm:ss') : '-'}
      </Typography>
  },
  {
    field: intl.formatMessage({id: 'chargingTime'}),
    renderHeader: () => <Box sx={colTitleSx}>
      <FormattedMessage id='chargingTime' />
    </Box>,
    flex: 1,
    minWidth: 50,
    renderCell: params =>
      <Typography
        variant='caption'
        sx={tableCellSx}
      >
        {convertMinutes(params.row.chargingTimeMinutes)}
      </Typography>
  },
  {
    field: intl.formatMessage({id: 'powerConsumed'}),
    renderHeader: () => <Box sx={colTitleSx}>
      <FormattedMessage id='powerConsumed' />
    </Box>,
    flex: 1,
    minWidth: 50,
    renderCell: params =>
      <Typography
        variant='caption'
        sx={tableCellSx}
      >
        {`${(params?.row?.wattsConsumed / 1000).toFixed(2)} ${intl.formatMessage({ id: 'kWh' })}`}
      </Typography>
  }
])
