import { FC, useMemo, useState } from "react";
import { GridColumns, GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import { Table } from "../../tables/Table";
import AMPModal from "../AMPModal";
import { CreateRfidForm } from "../../forms/CreateRfidForm";
import { RfidActions } from "./RfidActions";
import { BlockStatusEnum, RfidTableProps, ValidityEnum } from "./RfidTable.types";
import { getObjectFieldAsString } from "../../../utils/helper";
import { rfidTableSx } from "./RfidTable.sx";
import { useAppSelector } from "src/app/hooks";
import { RoleEnum } from "src/enums";
import { RfIdItemCard } from "./RfidItemCard";
import { ResponsiveTable } from "../ResponsiveTable";
import { RequestRfid } from "../RequestRfid";
import { EmptyRfid } from "./EmptyRfid";

export const RfidTable:FC<RfidTableProps> = ({ data }) => {
  const intl = useIntl();
  const [sortValue, setSortValue] = useState<GridSortItem>({ field: '', sort: 'desc' });
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showRequestModal, setShowRequestModal] = useState(false)

  const { authInfo } = useAppSelector(state => state.common)

  const actionsCol:GridColumns = authInfo?.roles?.[0]?.role === RoleEnum.DRIVER ? [] : [
    {
      field: "actions",
      headerName: '',
      minWidth: 50,
      flex: 1,
      sortable: false,
      cellClassName: 'ampTableCell hoverableCell',
      renderCell: (params) => {
        return (<>
          <RfidActions
            userId={data?.id}
            businessId={data?.business?.id}
            blocked={params?.row?.blocked}
            expiry={params?.row?.expiry}
            ocppTagId={params?.row?.id}
            idTag={params?.row?.idTag} />
        </>)
      }
    }
  ]
  
  const columns: GridColumns = [
    {
      field: "idTag",
      renderHeader: () =>
        <Typography sx={rfidTableSx.colTitle}>
          <FormattedMessage id={"rfidTag"} />
        </Typography>,
      minWidth: 50,
      flex: 1,
      sortable: true,
      renderCell: (params) => <Typography color="primary.dark">{params?.row?.idTag ?? ''}</Typography>
    },
    {
      field: "blocked",
      renderHeader: () =>
        <Typography sx={rfidTableSx.colTitle}>
          <FormattedMessage id={"statusColumnHeader"} />
        </Typography>,
      minWidth: 50,
      flex: 1,
      sortable: true,
      renderCell: (params) =>
        <Box
          sx={[
            rfidTableSx.tag,
            !params?.row?.blocked && rfidTableSx.tagActive,
            params?.row?.blocked &&  rfidTableSx.tagBlocked
          ]}>
          <FormattedMessage
            id={params.row?.blocked
              ? BlockStatusEnum.blocked
              : BlockStatusEnum.accepted}
          />
        </Box>
    },
    {
      field: "validity",
      renderHeader: () =>
        <Typography sx={rfidTableSx.colTitle}>
          <FormattedMessage id={"validity"} />
        </Typography>,
      minWidth: 50,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        const expire = dayjs(params?.row?.expiry)
        const creation = dayjs(params?.row?.created)
        const diff = expire.diff(creation)

        return (
          <Typography color={diff >= 0 ? "primary.dark" : "error.dark"}>
            {
              <FormattedMessage
                id={
                  diff >= 0
                    ? ValidityEnum.actual
                    : ValidityEnum.expired
                }
              />
            }
          </Typography>
        )
      }
    },
    {
      field: "expiry",
      renderHeader: () =>
        <Typography sx={rfidTableSx.colTitle}>
          <FormattedMessage id={"expirationDate"} />
        </Typography>,
      minWidth: 50,
      flex: 1,
      sortable: true,
      renderCell: (params) =>
        <Typography>
          {dayjs(params?.row?.expiry).format("DD/MM/YYYY")}
        </Typography>
    },
    {
      field: "created",
      renderHeader: () =>
        <Typography sx={rfidTableSx.colTitle}>
          <FormattedMessage id={"creationDate"} />
        </Typography>,
      minWidth: 50,
      flex: 1,
      sortable: true,
      renderCell: (params) =>
        <Typography>
          {params?.row?.created && dayjs(params?.row?.created).format("DD/MM/YYYY")}
        </Typography>
    },
    ...actionsCol
  ]

  const handleShowCreateModal = (status: boolean) => () => {
    setShowCreateModal(status);
  }
  
  const handleShowRequestModal = (status: boolean) => () => {
    setShowRequestModal(status);
  }
		
  const handleSortValue = (value: GridSortModel ) => setSortValue(value[0])
	
  const sortedData = useMemo(() => {
		
    const field = sortValue?.field;
    if (typeof field === "undefined") {
      return data?.ocppTags;
    }
		
    const isDataPresent = data
			&& Array.isArray(data.ocppTags)
			&& data.ocppTags.length > 0
		
    if (!isDataPresent) {
      return data?.ocppTags;
    }
		
    return data.ocppTags.slice().sort((a, b) => {
      const af = getObjectFieldAsString(a, field)
      const bf = getObjectFieldAsString(b, field)
      const isValueValid = (undefined !== af && undefined !== bf)
      if (isValueValid && sortValue?.sort === "desc") {
        return af.localeCompare(bf)
      }
      if (isValueValid) {
        return bf?.localeCompare(af)
      }
      return 0
    })
		
  }, [sortValue, data?.ocppTags])
  
  return (
    <Box sx={rfidTableSx.root}>
      <Typography sx={rfidTableSx.title}>
        {intl.formatMessage({ id: "rfid" })}
      </Typography>
      {!sortedData?.length &&
        <EmptyRfid
          role={authInfo.roles[0].role}
          handleClick={
            authInfo.roles[0].role === RoleEnum.DRIVER ?
              handleShowRequestModal(true) :
              handleShowCreateModal(true)
          }
        />
      }
      {!!sortedData?.length &&
        <ResponsiveTable
          desktopElement={
            <Table
              sx={rfidTableSx.table}
              rows={sortedData ?? []}
              hideFooter
              autoHeight
              columns={columns}
              handleSort={handleSortValue}
            />
          }
          responsiveElement={
            <Box>
              {data?.ocppTags.map(item => <RfIdItemCard
                key={item?.id}
                data={item}
                userId={data.id}
                businessId={data.business.id}
              />
              )}
            </Box>
          }
        />
      }
      {
        !!sortedData?.length &&
        <Stack sx={rfidTableSx.addBox}>
          <IconButton
            sx={rfidTableSx.addBtn}
            onClick={
              authInfo.roles[0].role !== RoleEnum.DRIVER ?
                handleShowCreateModal(true) :
                handleShowRequestModal(true)
            }
          >
            +
          </IconButton>
          <Typography sx={rfidTableSx.addText}>
            {
              authInfo.roles[0].role === RoleEnum.DRIVER ?
                intl.formatMessage({id: "requestRfid" } ) :
                intl.formatMessage({id: "addRfid" } )
            }
          </Typography>
        </Stack>
      }
      <AMPModal
        handleClose={handleShowRequestModal(false)}
        open={showRequestModal}
        title={intl.formatMessage({id: "requestRfid"})}
        type="md"
        fullWidth
        withoutPaddings
      >
        <RequestRfid
          closeModal={handleShowRequestModal(false)}
        />
      </AMPModal>
      <AMPModal
        handleClose={handleShowCreateModal(false)}
        open={showCreateModal}
        title={intl.formatMessage({id: "addRfid"})}
        type="md"
        fullWidth
        withoutPaddings
      >
        <CreateRfidForm
          handleClose={handleShowCreateModal(false)}
          userData={data}
        />
      </AMPModal>
    </Box>
  )
}
