import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import { store } from "../../app/store";
import { preloaderChangeStatus } from "../../stores/common/commonSlice";
import {
  ActiveSessions,
  Sessions,
  TotalConsumption,
  TotalRevenue,
  UsersIcon
} from "../../components/icons/ReportCardIcons";
import { ReportCard } from "../../components/reports/ReportCard";
import { businessesApi, reportsApi } from "../../api";
import { useAppSelector } from "../../app/hooks";
import { AMPAutocomplete } from "../../components/common/AMPAutocomplete";
import { Can } from "../../permissions/Can";
import ability from "../../permissions/ability";
import { ReportParamType } from "../../components/reports/ReportCard/ReportCard.types";
import { AutocompleteValue } from "../../types";
import { DatesType } from "./Reports.types";
import { reportsSx } from "./Reports.sx";
import { findAutocompleteValueById } from "src/utils/helper";
import { AMPAutocompleteProps } from "src/components/common/AMPAutocomplete/AMPAutocomplete.types";

type UsersReportQueryTrigger = ReturnType<typeof reportsApi.useLazyGetUsersReportQuery>[0];
type RevenueReportQueryTrigger = ReturnType<typeof reportsApi.useLazyGetRevenueReportQuery>[0];
type BusinessRevenueReportQueryTrigger = ReturnType<typeof reportsApi.useLazyGetBusinessRevenueQuery>[0];
type ConsumedPowerReportQueryTrigger = ReturnType<typeof reportsApi.useLazyGetConsumedPowerReportQuery>[0];
type ChargingSessionsReportQueryTrigger = ReturnType<typeof reportsApi.useLazyGetChargingSessionsReportQuery>[0];
type BusinessesQueryTrigger = ReturnType<typeof businessesApi.useLazyGetBusinessesQuery>[0];

const getUsersData = async (
  getUsersReports: UsersReportQueryTrigger,
  businessId: number,
  registeredFrom: number | undefined,
  registeredTo: number | undefined
) => {
  store.dispatch(preloaderChangeStatus(true))
  try {
    return getUsersReports({
      businessId,
      registeredFrom,
      registeredTo
    })
  } catch (error) {
    console.error(error)
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

const getBusinessRevenueData = async (
  getRevenueReports: BusinessRevenueReportQueryTrigger,
  businessId: number,
  dateFrom: number | undefined,
  dateTo: number | undefined
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    return getRevenueReports({
      businessId,
      dateFrom,
      dateTo
    })
  } catch (error) {
    console.error(error)
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

const getRevenueData = async (
  getRevenueReports: RevenueReportQueryTrigger,
  businessId: number,
  dateFrom: number | undefined,
  dateTo: number | undefined
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    return getRevenueReports({
      businessId,
      dateFrom,
      dateTo
    })
  } catch (error) {
    console.error(error)
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

const getConsumedPowerData = async (
  getConsumedPowerReports: ConsumedPowerReportQueryTrigger,
  businessId: number,
  dateFrom: number | undefined,
  dateTo: number | undefined
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    return getConsumedPowerReports({
      businessId,
      dateFrom,
      dateTo
    })
  } catch (error) {
    console.error(error)
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

const getChargingSessionsData = async (
  getChargingSessionsReports: ChargingSessionsReportQueryTrigger,
  businessId: number,
  dateFrom: number | undefined,
  dateTo: number | undefined,
  active: boolean | undefined
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    return getChargingSessionsReports({
      data: {
        businessId,
        dateFrom,
        dateTo,
        active
      }
    })
  } catch (error) {
    console.error(error)
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

const getReportsData = (
  getUsersReports: UsersReportQueryTrigger,
  getRevenueReports: RevenueReportQueryTrigger,
  getBusinessRevenueReports: BusinessRevenueReportQueryTrigger,
  getConsumedPowerReports: ConsumedPowerReportQueryTrigger,
  getChargingSessionsReports: ChargingSessionsReportQueryTrigger,
  getChargingSessionsReportsActive: ChargingSessionsReportQueryTrigger,
  businessId: number,
  userId: number,
  locationId: number,
  active: boolean,
  dateObject: Record<string, {from?: number, to?: number}>,
  isSuperAdmin: boolean
) => {
  getUsersData(getUsersReports, businessId, dateObject?.users?.from, dateObject?.users?.to)
  !isSuperAdmin && getRevenueData(getRevenueReports, businessId, dateObject?.revenue?.from, dateObject?.revenue?.to)
  isSuperAdmin && getBusinessRevenueData(getBusinessRevenueReports, businessId, dateObject?.revenue?.from, dateObject?.revenue?.to)
  getConsumedPowerData(getConsumedPowerReports, businessId, dateObject?.consumption?.from, dateObject?.consumption?.to)
  getChargingSessionsData(getChargingSessionsReports, businessId, dateObject?.allSessions?.from, dateObject?.allSessions?.to, false)
  getChargingSessionsData(getChargingSessionsReportsActive, businessId, dateObject?.allSessions?.from, dateObject?.allSessions?.to, true)
}

const getBusinessesData = async (trigger: BusinessesQueryTrigger) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    await trigger({
      params: {
        size: 2000
      }
    })
  } catch (e) {
    console.error(e)
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

export const Reports:FC = () => {
  const intl = useIntl()
  const { authInfo } = useAppSelector((state) => state.common);
  const isSuperAdmin = ability.can('read', 'businessReports')
  const [currentBusiness, setCurrentBusiness] = useState<number | undefined>(undefined)
  const [getBusinesses, businesses] = businessesApi.useLazyGetBusinessesQuery()
  const [getChargingSessionsReports, chargingSessionsReports] = reportsApi.useLazyGetChargingSessionsReportQuery()
  const [getChargingSessionsReportsActive, chargingSessionsReportsActive] = reportsApi.useLazyGetChargingSessionsReportQuery()
  const [getConsumedPowerReports, consumedPowerReports] = reportsApi.useLazyGetConsumedPowerReportQuery()
  const [getRevenueReports, revenueReports] = reportsApi.useLazyGetRevenueReportQuery()
  const [getBusinessRevenueReports, businessRevenueReports] = reportsApi.useLazyGetBusinessRevenueQuery()
  const [getUsersReport, usersReports] = reportsApi.useLazyGetUsersReportQuery()

  const [dates, setDates] = useState<DatesType>({
    allSessions: {
      from: undefined,
      to: dayjs().valueOf()
    },
    consumption: {
      from: undefined,
      to: dayjs().valueOf()
    }
  })

  const handleChangeDates = (
    newFrom: number,
    newTo: number,
    field: string
  ) => {
    return () => {
      setDates({
        ...dates,
        [field]: {
          from: newFrom,
          to: newTo
        }
      })
    }
  }
  
  const processedBusinesses  = ():AutocompleteValue[] => {
    const allBusinesses = {
      id: -1,
      label: intl.formatMessage({ id: "allBusinesses" }),
      image: "undefined"
    }
    if (businesses?.data?.content) {
      return [allBusinesses].concat(businesses?.data?.content?.map(item => ({
        id: item?.id,
        label: item?.name,
        image: item?.logo?.url ?? ""
      })))
    }
    return []
  }
  
  const handleBusinessChange: AMPAutocompleteProps['onChange'] = (e, newValue) => {
    setCurrentBusiness(newValue?.id || undefined)
  }
  
  useEffect(() => {
    if (isSuperAdmin) {
      getBusinessesData(getBusinesses)
    }
  }, []);
  
  useEffect(() => {
    if (authInfo) {
      const formattedBusinessId = currentBusiness === -1 ? undefined : currentBusiness
      getReportsData(
        getUsersReport,
        getRevenueReports,
        getBusinessRevenueReports,
        getConsumedPowerReports,
        getChargingSessionsReports,
        getChargingSessionsReportsActive,
        authInfo?.business?.id ?? formattedBusinessId,
        authInfo?.user?.id,
        authInfo?.location?.id,
        true,
        dates,
        isSuperAdmin
      )
    }
  }, [authInfo, currentBusiness, dates])
  
  const revenueAmounts = useMemo(() => {
    const revenueAmounts: Array<ReportParamType> = []
    const revenue = isSuperAdmin ? businessRevenueReports : revenueReports
    revenue?.data?.revenueStatisticByCurrencies?.forEach(item => {
      revenueAmounts.push({
        amount: item?.totalRevenueAmount
          ? item?.totalRevenueAmount / 100
          : 0,
        units: item?.currency ?? ''
      })
    })
    return revenueAmounts
  }, [businessRevenueReports, revenueReports, authInfo])
  
  const reportsList =  [
    {
      title: intl.formatMessage({ id: "activeChargingSessions" }),
      icon: <ActiveSessions/>,
      link: '/app/reports/active-charging-sessions',
      params: [
        {
          amount: chargingSessionsReportsActive?.data?.totalElements ?? 0,
          description: intl.formatMessage({ id: "numberOfActiveChargingSessions" })
        }
      ]
    },
    {
      title: intl.formatMessage({ id: "chargingSessions" }),
      key: 'allSessions',
      icon: <Sessions/>,
      link: '/app/reports/charging-sessions',
      params: [
        {
          amount: chargingSessionsReports?.data?.totalElements ?? 0,
          description: intl.formatMessage({ id: "numberOfChargingSessions" })
        }
      ]
    },
    {
      title: intl.formatMessage({ id: "totalEnergyConsumption" }),
      key: 'consumption',
      icon: <TotalConsumption/>,
      link: '/app/reports/energy-consumption',
      params: [
        {
          amount: consumedPowerReports?.data ? (consumedPowerReports.data.totalConsumedPower / 1000).toFixed(2) : 0,
          description: intl.formatMessage({ id: "totalEnergyConsumption" }),
          units: intl.formatMessage({ id: 'kWh' })
        }
      ]
    },
    {
      title: intl.formatMessage({ id: "totalRevenue" }),
      icon: <TotalRevenue/>,
      link: '/app/reports/revenue',
      params: [
        {
          amount: revenueReports?.data?.totalInvoiceSize
            ?? businessRevenueReports?.data?.totalInvoiceSize
            ?? 0,
          description: intl.formatMessage({ id: "paidInvoices" })
        },
        ...revenueAmounts,
      ]
    },
    {
      title: intl.formatMessage({ id: "users" }),
      icon: <UsersIcon/>,
      link: '/app/reports/users',
      params: [{
        amount: usersReports?.data?.totalRegisteredUsersCount ?? 0,
        description: "registered users"
      }]
    }
  ]
  
  return (
    <Box>
      <Stack sx={reportsSx.header}>
        <Typography
          variant="h2"
        >
          {intl.formatMessage({ id: 'reports' })}
        </Typography>
        <Can
          I="read"
          a="businessReports"
        >
          <Box sx={reportsSx.selectBox}>
            <AMPAutocomplete
              options={processedBusinesses()}
              value={findAutocompleteValueById(processedBusinesses(), currentBusiness)}
              label={'business'}
              input={'businessId'}
              showAvatar
              onChange={handleBusinessChange}
            />
          </Box>
        </Can>
      </Stack>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{xs: 0, md: 2}}
      >
        {reportsList.map(report => (
          <Grid
            key={report.title}
            item
            xs={12}
            lg={6}
          >
            <ReportCard
              icon={report.icon}
              title={report.title}
              params={report.params}
              link={report.link}
              field={report?.key}
              handleTimeChange={handleChangeDates}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
