import { FC, useContext, useState } from "react";
import { Box, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { Bar, CartesianGrid, Legend, ResponsiveContainer, BarChart, Tooltip, XAxis, YAxis } from "recharts";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import theme from "../../../theme";
import { TimeSelect } from "../../common/TimeSelect";
import { timeMap, TimeMapEnum } from "src/components/forms/formData/common/initialData";
import { PickCustomPeriodFormReturnType } from "../../forms/PickCustomPeriodForm/PickCustomPeriodForm.types";
import { getNumberFromPx } from "../../../utils/helper";
import { MultipleBarChartProps } from "./MultipleBarChart.types";
import { multipleBarChartSx } from "./MultipleBarChart.sx";
import DashboardLayoutContext from "../../../layouts/DashboardLayout/DashboardLayout.context";
import { CustomizedAxisTick } from "./CustomizedAxisTick";

export const MultipleBarChart:FC<MultipleBarChartProps> = ({
  data,
  title,
  dataKeyFirst,
  dataKeySecond,
  handleChangeDates,
  field
}) => {
  const intl = useIntl()
  const [currentTime, setCurrentTime] = useState(TimeMapEnum.ALL)
  const [showCalendar, setShowCalendar] = useState(false)
  const responsive = useContext(DashboardLayoutContext)
  
  const handleShowCalendar = () => {
    setShowCalendar(true)
  }
  const handleHideCalendar = () => {
    setShowCalendar(false)
  }
  
  const handleCustomClick = () => {
    handleShowCalendar()
  }
  
  const onSelectDate = (e: SelectChangeEvent) => {
    const value = Number(e.target.value)
    setCurrentTime(value)
    if (value !== TimeMapEnum.CUSTOM && field) {
      handleChangeDates
      && handleChangeDates(timeMap(intl, handleCustomClick)[value]?.dateFrom, dayjs().valueOf(), field)()
    }
    if (value === TimeMapEnum.CUSTOM) {
      handleShowCalendar()
    }
  }
  
  const onSubmitRange = () => {
    return (values: PickCustomPeriodFormReturnType) => {
      handleChangeDates && field &&
      handleChangeDates(
        dayjs(values.range.startDate).valueOf(),
        dayjs(values.range.endDate).valueOf(),
        field
      )()
      handleHideCalendar()
    }
  }
  
  return (
    <Box sx={title ? multipleBarChartSx.root : multipleBarChartSx.emptyTitle}>
      <Stack sx={multipleBarChartSx.top}>
        {title &&
          <Typography variant="subtitle1">
            {title}
          </Typography>}
        {handleChangeDates && <Box sx={multipleBarChartSx.select}>
          <TimeSelect
            currentTime={currentTime}
            onSubmitRange={onSubmitRange}
            onChange={onSelectDate}
            handleCustomClick={handleCustomClick}
            showCalendar={showCalendar}
            handleShowCalendar={handleShowCalendar}
            handleHideCalendar={handleHideCalendar}
          />
        </Box>}
      </Stack>
      <ResponsiveContainer
        width="100%"
        aspect={responsive ? 1 : 3}
      >
        <BarChart
          data={data}
          margin={{
            top: getNumberFromPx(5),
            right: getNumberFromPx(0),
            left: getNumberFromPx(0),
            bottom: getNumberFromPx(15),
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            height={responsive
              ? getNumberFromPx(100)
              : getNumberFromPx(50)
            }
            fontSize={12}
            dataKey="name"
            tick={<CustomizedAxisTick responsive={responsive} />}
          />
          <YAxis
            fontSize={theme.spacing(12/8)}
          />
          <Tooltip contentStyle={{fontSize: responsive
            ? theme.spacing(10/8)
            : theme.spacing(14/8)}}
          />
          {!responsive && <Legend/>}
          <Bar
            maxBarSize={responsive
              ? getNumberFromPx(12)
              : getNumberFromPx(28)
            }
            radius={[getNumberFromPx(10), getNumberFromPx(10), 0, 0]}
            dataKey={dataKeyFirst}
            style={{fontSize: 10}}
            fill={theme.palette.warning.main}
          />
          {dataKeySecond &&
            <Bar
              maxBarSize={responsive
                ? getNumberFromPx(12)
                : getNumberFromPx(28)
              }
              radius={[getNumberFromPx(10), getNumberFromPx(10), 0, 0]}
              dataKey={dataKeySecond}
              fill={theme.palette.primary.main}
            />
          }
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
