import * as Yup from "yup";

export type UploadConsumptionHistoryFormType = {
  lastInvoicedAmount: number,
  lastInvoicedWattsConsumed: number,
  totalTariffPeriodWattsConsumed: number
}

export const uploadConsumptionHistoryFormSchema = Yup.object().shape({
  lastInvoicedAmount: Yup.number().required('schemaRequiredLastAmount').min(0, 'schemaGreaterLastAmount'),
  lastInvoicedWattsConsumed: Yup.number().required('schemaRequiredPowerConsumedLastInvoice').min(0, "schemaGreaterPowerConsumedLastInvoice"),
  totalTariffPeriodWattsConsumed: Yup.number().required('schemaRequiredTotalAmountOfKw').min(0, "schemaGreaterTotalAmountOfKw"),
});
