import { FC } from "react";
import { Button } from "@mui/material";
import { utils, writeFile } from "xlsx";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import { convertMinutes } from "../../../utils/helper";
import ability from "../../../permissions/ability";
import { ExcelDocumentProps } from "./ExcelDocument.types";
import { excelDocumentSx } from "./ExcelDocument.sx";

export const ExcelDocument:FC<ExcelDocumentProps> = ({
  data,
  name
}) => {
  const intl = useIntl()
  const isSuperAdmin = ability?.can('read', 'businessReports')
  
  const formattedData = data.map(item => {
    const business: Record<string, string> = {}
    if (isSuperAdmin) {
      business[intl.formatMessage({ id: 'business' })] = item?.business?.name ?? ''
    }
    return ({
      ...business,
      [intl.formatMessage({ id: 'status' })]: item?.active
        ? intl.formatMessage({id: 'active'})
        : intl.formatMessage({id: 'completed'}),
      [intl.formatMessage({id: 'location'})]: item?.location?.name,
      [intl.formatMessage({id: 'evDriverName'})]: `${item?.user?.firstName} ${item?.user?.lastName}`,
      [intl.formatMessage({id: 'rfid'})]: `${item?.idTag?.idTag}`,
      [intl.formatMessage({ id: 'transactionStart' })]: dayjs(item?.startTimestamp).format('DD MMM YYYY HH:mm:ss'),
      [intl.formatMessage({ id: 'transactionEnd'})]: item?.stopTimestamp
        ? dayjs(item?.stopTimestamp).format('DD MMM YYYY HH:mm:ss')
        : '-',
      [intl.formatMessage({id: 'chargingTime'})]: convertMinutes(item?.chargingTimeMinutes),
      [intl.formatMessage({id: 'powerConsumed'})]: `${item?.wattsConsumed}`
    })
  })
  
  const handleOnExport = () => {
    const wb = utils.book_new()
    const ws = utils.json_to_sheet(formattedData)
    utils.book_append_sheet(wb, ws, 'myFile')
    writeFile(wb, `${name}.xlsx`)
  }
  
  return (
    <Button
      sx={excelDocumentSx.button}
      variant="text"
      onClick={handleOnExport}
    >
      {intl.formatMessage({ id: "excel" })}
    </Button>
  )
}
