import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'src/axiosBaseQuery'
import { CreateTariffRequest, DefaultResponse, PageTariffDTO, TariffByIdRequest, TariffDTO, TariffPointSearchRequest, ToogleStatusRequest, UpdateTariffRequest } from 'src/types'
import { getBaseURL } from 'src/utils/helper'

// initialize an empty api service that we'll inject endpoints into later as needed
export const tariffsApi = createApi({
  reducerPath: 'tariffsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL() + '/tariffs'),
  }),
  tagTypes: ['Tariffs', 'Tariff'],
  endpoints: (build) => ({
    getTariffs: build.query<PageTariffDTO, TariffPointSearchRequest>({
      query: ({ data, params }) => ({ url: '/search', method: 'post', data, params }),
      providesTags: (result) => {
        if (result?.content) {
          return [
            { type: 'Tariffs' as const, id: 'LIST' },
            ...result.content.map(tariff => ({
              type: 'Tariffs' as const,
              id: tariff?.id
            }))
          ]
        }
        return [
          { type: 'Tariffs' as const, id: 'LIST' }, 'Tariffs' as const
        ]
      }
    }),
    addTariff: build.mutation<TariffDTO, CreateTariffRequest>({
      query:(data) => ({ url: '', method: 'post', data }),
      invalidatesTags: ['Tariffs']
    }),
    toggleStatus: build.mutation<TariffDTO, ToogleStatusRequest>({
      query:({params, tariffId}) => ({ url: `/${tariffId}/toggleStatus`, method: 'post', params }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Tariffs' as const, id: arg.tariffId},
        { type: 'Tariffs' as const, id: 'LIST' }
      ]
    }),
    updateTariff: build.mutation<DefaultResponse, UpdateTariffRequest>({
      query:({data, tariffId}) => ({ url: `/${tariffId}`, method: 'put', data }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Tariffs' as const, id: arg.tariffId},
        { type: 'Tariffs' as const, id: 'LIST' }
      ]
    }),
    deleteTariff: build.mutation<DefaultResponse, TariffByIdRequest>({
      query:({businessId, tariffId}) => ({ url: `/${tariffId}`, method: 'delete', params: { businessId } }),
      invalidatesTags: [{ type: 'Tariffs' as const, id: 'LIST' }]
    }),
    getTariffById: build.query<TariffDTO, TariffByIdRequest>({
      query:({ businessId, tariffId }) => ({ url: `/${tariffId}`, method: 'get', params: { businessId } }),
      providesTags: (result, error, arg) =>
        [{ type: 'Tariffs' as const, id: arg.tariffId }]
    })
  }),
})
