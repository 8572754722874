import theme from "../../../theme";

export const ampMarkdownEditorSx = {
  editor: {
    width: "100%",
    '& ol li': {
      listStyle: "auto"
    },
    '& ul li': {
      listStyle: 'disc'
    },
    [theme.breakpoints.down('lg')]: {
      '& .editor-container': {
        flexDirection: 'column',
        height: theme.spacing(900/8)
      },
      '& .sec-md': {
        borderBottom: `${theme.spacing(1/8)} solid ${theme.palette.neutral['300']}`
      }
    }
  }
}
