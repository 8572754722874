import { FC } from "react";
import { Form, Formik, FormikProps } from "formik";
import { Box, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import { AMPInput } from "../../common/AMPInput";
import { getHelperText } from "../../../utils/forms";
import { CancelButton } from "../../common/CancelButton";
import { SubmitButton } from "../../common/SubmitButton";
import {
  updateRfidFormSchema,
  UpdateRfidFormType
} from "../formData/rfid/rfid";
import { AMPDatePicker } from "../../common/AMPDatePicker";
import { rfidStatuses } from "../formData/common/initialData";
import { UpdateRfidDTO } from "../../../types";
import { UpdateRfidFormProps } from "./UpdateRfidForm.types";
import { AMPFormSelect } from "../../common/AMPFormSelect";
import { BlockStatusEnum } from "../../common/RfidTable/RfidTable.types";
import { actionsWrapperSx, formContentSx, formSx } from "./UpdateRfidForm.sx";

export const UpdateRfidForm:FC<UpdateRfidFormProps> = ({ handleClose, handleSubmit, initialValues }) => {
  const intl = useIntl();

  const onSubmit = () => {
    return (values: UpdateRfidFormType) => {
      const res: UpdateRfidDTO = {
        blocked: values.blocked !== BlockStatusEnum.accepted,
        expiry: dayjs(values.expiry).valueOf(),
        idTag: values.idTag
      }
      handleSubmit(res)
      handleClose()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={updateRfidFormSchema}
      onSubmit={onSubmit()}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }: FormikProps<UpdateRfidFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <Grid
                container
              >
                <Grid
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["idTag"]}
                    type={"idTag"}
                    label={"rfidTag"}
                    input={"idTag"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["idTag"] && Boolean(errors["idTag"])
                    }
                    helperText={getHelperText("idTag", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
              >
                <Grid
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={rfidStatuses(intl)}
                    value={values["blocked"]}
                    label={"status"}
                    input={"blocked"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["blocked"] && Boolean(errors["blocked"])
                    }
                    helperText={getHelperText("blocked", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
              >
                <Grid
                  xs={12}
                  item
                >
                  <AMPDatePicker
                    label="expirationDate"
                    value={dayjs( values["expiry"]).valueOf()}
                    onChange={(val) => setFieldValue("expiry", dayjs( val).valueOf())}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={handleClose}
              />
              <SubmitButton
                text="save"
                color="primary"
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
