import * as Yup from 'yup';

export interface ResetPasswordType extends Record<string, unknown>{
  password: string,
  confirmPassword: string
}

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6)
    .required('schemaRequiredPassword'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'schemaMatchPassword'),
})
