import { SxProps, Theme } from "@mui/material"
import theme from "src/theme"

export const wrapperSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
}
export const welcomeBlockSx: SxProps<Theme> = {
  marginBottom: theme.spacing(90/8)
}
export const completeDescriptionSx: SxProps<Theme> = {
  marginTop: theme.spacing(20/8),
  padding: theme.spacing(16/8),
  background: theme.palette.primaryColor[100],
  borderRadius: 4,
  lineHeight: "1.2"
}
