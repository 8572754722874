import { RoleEnum } from 'src/enums';
import * as Yup from 'yup';

export interface ManagerFormProps {
  role: RoleEnum | string,
  email: string,
  firstName: string,
  lastName: string,
  language: string,
}

export const managersFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(255)
    .required('schemaRequiredFirstName'),
  lastName: Yup.string()
    .max(255)
    .required('schemaRequiredLastName'),
  email: Yup.string()
    .email('schemaValidEmail')
    .max(255)
    .required('schemaRequiredEmail'),
  language: Yup.string()
    .required('schemaLanguageRequired'),
  role: Yup.string()
    .required('schemaRoleRequired')
});
