import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const innerSx: SxProps<Theme> = {
  borderRadius: theme.spacing(8/8),
  maxWidth: theme.spacing(500/8),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(50/8),
  boxShadow: '0px 4px 12px -4px rgba(33, 33, 52, 0.12), 0px 16px 32px rgba(33, 33, 52, 0.12)',
  background: theme.palette.background.default,
  padding: theme.spacing(40/8),
  [theme.breakpoints.down('md')]:{
    padding: 0,
    boxShadow: 'none'
  }
}

export const textWrapperSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(10/8),
  textAlign: 'center'
}
