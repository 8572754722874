import { FC } from "react";
import { useIntl } from "react-intl";
import { Range } from "react-date-range";
import { AMPFormSelect } from "../AMPFormSelect";
import { TimeSelectProps } from "./TimeSelect.types";
import { SelectValue } from "../../../types";
import { PickCustomPeriodForm } from "../../forms/PickCustomPeriodForm";
import AMPModal from "../AMPModal";
import { timeMap, TimeMapEnum } from "../../forms/formData/common/initialData";
import { textFieldSx } from "./TimeSelect.sx";

export const TimeSelect: FC<TimeSelectProps> = ({
  currentTime,
  onChange,
  onSubmitRange,
  showCalendar,
  handleCustomClick,
  handleShowCalendar,
  handleHideCalendar,
  handleHideOverlimit,
  handleShowOverlimit,
  contractLimit,
  disabled
}) => {
  const intl = useIntl()

  const processedTime = (): SelectValue[] => {
    const data = Object.entries(timeMap(intl, handleCustomClick)).map(item => ({
      id: +item[0],
      value: +item[0],
      name: item[1].title,
      onClick: item[1]?.onClick
    }))
    if (contractLimit) {
      return [
        ...data,
        {
          id: +TimeMapEnum.CONTRACT,
          value: +TimeMapEnum.CONTRACT,
          name: intl.formatMessage({ id: 'contract' }),
        }
      ]
    }
    return data
  }

  const handleClick = (value: string | number) => {
    if (value === 6 && handleShowOverlimit) {
      handleShowOverlimit()
      return
    }
    if (handleHideOverlimit) {
      handleHideOverlimit()
    }
    value === 5 && handleShowCalendar()
  }

  const dateRange: Range = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }

  return (
    <>
      <AMPFormSelect
        data={processedTime()}
        value={currentTime}
        input={'dates'}
        onChange={onChange}
        onClick={handleClick}
        sx={textFieldSx}
        disabled={disabled}
      />
      <AMPModal
        handleClose={handleHideCalendar}
        open={showCalendar}
        title={intl.formatMessage({ id: "customPeriod" })}
        type="md"
        fullWidth
        withoutPaddings
      >
        <PickCustomPeriodForm
          handleClose={handleHideCalendar}
          handleSubmit={onSubmitRange()}
          initialValues={{
            range: dateRange
          }}
        />
      </AMPModal>
    </>
  )
}
