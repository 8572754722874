import * as Yup from 'yup';
import { ContractStatusManualEnum } from "../../../../enums";

export interface ContractManualFormType {
  contractCreatedAt: number,
  contractId: string,
  contractStatus: ContractStatusManualEnum,
  userId: string,
  operationalAt?: number
}

export const contractManualSchema = Yup.object().shape({
  contractCreatedAt: Yup.number().required('schemaRequiredDate'),
  operationalAt: Yup.number().when('contractStatus', {
    is: ContractStatusManualEnum.INSTALLATION,
    then: Yup.number().required('schemaRequiredDate')
  }),
  contractStatus: Yup.string(),
  contractId: Yup.string().required('schemaRequiredContractId'),
  userId: Yup.string().required('schemaRequiredUser'),
})
