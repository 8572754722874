import { FC } from "react";

export const DownloadIcon:FC = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.6901 10.666L11.3568 7.33268H9.35677V2.66602H8.02344V7.33268H6.02344L8.6901 10.666Z"
        fill="currentColor"
      />
      <path
        d="M14.0234 12.0007H3.35677V7.33398H2.02344V12.0007C2.02344 12.736 2.62144 13.334 3.35677 13.334H14.0234C14.7588 13.334 15.3568 12.736 15.3568 12.0007V7.33398H14.0234V12.0007Z"
        fill="currentColor"
      />
    </svg>
  )
}
