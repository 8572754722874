import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const contentItemSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  '&.MuiGrid-item.MuiGrid-root' : {
    paddingLeft: 0,
  },
  '& img': {
    marginBottom: theme.spacing(20/8)
  }
}

export const innerItemSx: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(10/8)
}