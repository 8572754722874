import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const rootSx: SxProps<Theme> = {
  width: '100%',
  padding: theme.spacing(24/8),
  background: theme.palette.text.secondary,
  borderRadius: `${theme.spacing(8/8)} ${theme.spacing(8/8)} 0 0`,
  overflow: 'hidden',
  minHeight: theme.spacing(500/8),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(10/8, 0),
  }
}

export const tabSx: SxProps<Theme> = {
  color: theme.palette.neutral[500]
}

export const tableFooterSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(10/8, 0),
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
  }
}
