import { Button } from "@mui/material";
import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { tariffsApi } from "src/api";
import { store } from "src/app/store";
import { TariffsStatusEnum } from "src/enums";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import theme from "src/theme";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { TariffStatusButtonType } from "./TariffStatusButton.types";

type TariffsStatusMutationTrigger = ReturnType<typeof tariffsApi.useToggleStatusMutation>[0]

const handleChangeStatus = (trigger: TariffsStatusMutationTrigger, businessId: number, tariffId: number) => async (value: boolean) => {
  const params = {
    businessId,
    active: value
  }
  store.dispatch(preloaderChangeStatus(true));

  try{
    const response = await trigger({tariffId, params}).unwrap()
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
}

export const TariffStatusButton: FC<TariffStatusButtonType> = ({tariff}) => {

  const sxValue = useCallback(() => {
    switch(tariff.status){
      case TariffsStatusEnum.ACTIVE: 
        return {
          maxWidth: theme.spacing(120/8),
          width: '100%',
          borderColor: theme.palette.neutral[200],
          padding: theme.spacing(8/8, 15/8),
          borderRadius: theme.spacing(4/8),
          borderWidth: theme.spacing(1/8),
          borderStyle: 'solid',
          color: theme.palette.neutral[700],
          fontWeight: 600,
          [theme.breakpoints.down('lg')]: {
            maxWidth: "100%"
          }
        }
      case TariffsStatusEnum.DEACTIVATED:
        return {
          maxWidth: theme.spacing(120/8),
          width: '100%',
          borderColor: theme.palette.primaryColor[100],
          background: theme.palette.primaryColor.A50,
          padding: theme.spacing(8/8, 15/8),
          borderRadius: theme.spacing(4/8),
          borderWidth: theme.spacing(1/8),
          borderStyle: 'solid',
          color: theme.palette.primary.main,
          fontWeight: 600,
          [theme.breakpoints.down('lg')]: {
            maxWidth: "100%"
          }
        }
    }
  }, [tariff])
  const [trigger, response] = tariffsApi.useToggleStatusMutation()
  const handleClick = () => {
    handleChangeStatus(trigger, tariff.business.id, tariff.id)(tariff.status === TariffsStatusEnum.ACTIVE ? false : true)
  }
  return (
    <Button
      sx={sxValue()}
      onClick={handleClick}
    >
      <FormattedMessage id={tariff.status === TariffsStatusEnum.ACTIVE ? 'deactivate' : 'activate'} />
    </Button>
  )
}
