import { FC, useState, MouseEvent } from "react";
import { Link } from "react-router-dom";
import { ContentCopyOutlined } from "@mui/icons-material";
import { Button, IconButton, Popover, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import copy from "copy-to-clipboard";
import { UserIcon } from "../../../icons/UserIcon";
import { LightningIcon } from "../../../icons/LightningIcon";
import { NotifyError, NotifySuccess } from "../../../../utils/notification";
import { locationsApi } from "../../../../api";
import { useAppDispatch } from "../../../../app/hooks";
import { preloaderChangeStatus } from "../../../../stores/common/commonSlice";
import { AppDispatch } from "../../../../types";
import { AppRouteEnum, AppRouteSpace } from "../../../../utils/helper";
import { locationItemCardSx } from "./LocationItemCard.sx";
import { MenuButton } from "../../../tables/respnsiveTable/MenuButton";
import { TableCard } from "../../../tables/respnsiveTable/TableCard";
import { MenuWrapper } from "../../../tables/respnsiveTable/MenuWrapper";
import { TableRow } from "../../../tables/respnsiveTable/TableRow";
import { LocationItemCardProps } from "./LocationItemCard.types";

type DeleteLocationMutationTrigger = ReturnType<typeof locationsApi.useDeleteLocationMutation>[0]

const handleDeleteLocation = (trigger: DeleteLocationMutationTrigger, dispatch: AppDispatch, locationId: number, businessId: number) => async () => {
  dispatch(preloaderChangeStatus(true))
  try{
    await trigger({ locationId, businessId }).unwrap()
  } catch (error: any) {
    NotifyError(error.data.message);
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
}

export const LocationItemCard:FC<LocationItemCardProps> = ({
  id,
  name,
  address,
  clientsCount,
  chargePointsCount,
  business,
  canCreateDeleteLocation= true
}) => {
  const intl = useIntl();
  const [trigger] = locationsApi.useDeleteLocationMutation();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const copyToClipboard = () => {
    copy(String(id), { debug: true })
    NotifySuccess(intl.formatMessage({id: 'copiedSuccesfully'}))
  }
  
  return (
    <TableCard>
      <MenuWrapper>
        {canCreateDeleteLocation && <MenuButton handleClick={handleClick}/>}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={locationItemCardSx.popover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Button
            sx={locationItemCardSx.menuBtn}
            onClick={handleDeleteLocation(trigger, dispatch, +id, +business.id)}
          >
            <Typography sx={locationItemCardSx.menuBtnText}>
              {intl.formatMessage({id: "delete"})}
            </Typography>
          </Button>
        </Popover>
      </MenuWrapper>
      <TableRow
        titleId='locationId'
        isColumn
        customValue={
          <Stack sx={locationItemCardSx.withIcon}>
            <Typography sx={[locationItemCardSx.dataText, locationItemCardSx.idText]}>
              {id}
            </Typography>
            <IconButton
              onClick={copyToClipboard}
              sx={[locationItemCardSx.iconBtn, {ml: 2}]}
            >
              <ContentCopyOutlined sx={{width: '0.75rem'}} />
            </IconButton>
          </Stack>
        }
      />
      <TableRow
        titleId='locationNameColumnHeader'
        value={name}
        link={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.locations, String(id)])}
      />
      <TableRow
        titleId='country'
        value={address?.country}
      />
      <TableRow
        titleId='city'
        value={address?.city}
      />
      <TableRow
        titleId='addressColumnHeader'
        value={`${address.street} ${address.houseNumber}`}
      />
      <TableRow
        titleId='clientsColumnHeader'
        value={clientsCount}
        icon={<UserIcon />}
      />
      <TableRow
        titleId='chargersColumnHeader'
        value={chargePointsCount}
        icon={<LightningIcon />}
      />
    </TableCard>
  )
}
