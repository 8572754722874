import { FC } from "react";

export const LogoBlack: FC = () => (
  <svg
    width="122"
    height="30"
    viewBox="0 0 122 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.6847 6.13891C52.5105 5.71828 52.0988 5.44336 51.6436 5.44336C51.1884 5.44336 50.7768 5.71828 50.6026 6.13891L43.2339 23.8988C42.995 24.4742 43.2684 25.1322 43.8423 25.3711C44.4162 25.6084 45.0757 25.3365 45.3145 24.7626L47.7512 18.8902C47.7467 18.9338 47.7422 18.9774 47.7422 19.0224C47.7422 19.6444 48.247 20.1491 48.8689 20.1491H54.4153C55.0373 20.1491 55.542 19.6444 55.542 19.0224C55.542 18.9774 55.539 18.9338 55.533 18.8902L57.9697 24.7626C58.15 25.1968 58.5691 25.4582 59.0108 25.4582C59.155 25.4582 59.3007 25.4312 59.442 25.3726C60.0173 25.1337 60.2892 24.4742 60.0504 23.9003L52.6817 6.14042L52.6847 6.13891ZM54.4168 17.8957H48.8704C48.5114 17.8957 48.1929 18.064 47.9871 18.3239L51.6436 9.51153L55.3002 18.3239C55.0944 18.0625 54.7759 17.8957 54.4168 17.8957V17.8957Z"
      fill="#1C1C3B"
    />
    <path
      d="M77.5668 5.48995C77.0891 5.34874 76.5738 5.53802 76.3019 5.95566L69.8782 15.8436L63.4545 5.95566C63.1825 5.53652 62.6673 5.34723 62.1895 5.48995C61.7118 5.63116 61.3828 6.07133 61.3828 6.57009V24.33C61.3828 24.9519 61.8876 25.4567 62.5095 25.4567C63.1315 25.4567 63.6362 24.9519 63.6362 24.33V10.3723L68.9332 18.5252C69.1406 18.8452 69.4966 19.0375 69.8782 19.0375C70.2598 19.0375 70.6158 18.8437 70.8231 18.5252L76.1201 10.3723V24.33C76.1201 24.9519 76.6249 25.4567 77.2468 25.4567C77.8688 25.4567 78.3736 24.9519 78.3736 24.33V6.57159C78.3736 6.07283 78.0461 5.63267 77.5668 5.49145V5.48995Z"
      fill="#1C1C3B"
    />
    <path
      d="M88.6463 5.44482H82.1384C81.5165 5.44482 81.0117 5.94959 81.0117 6.57153V24.3314C81.0117 24.9534 81.5165 25.4582 82.1384 25.4582C82.7604 25.4582 83.2651 24.9534 83.2651 24.3314V19.4821H85.2857C85.9076 19.4821 86.4124 18.9773 86.4124 18.3554C86.4124 17.7334 85.9076 17.2287 85.2857 17.2287H83.2651V7.69824H88.6463C91.083 7.69824 93.066 9.68125 93.066 12.1179V12.809C93.066 15.2457 91.083 17.2287 88.6463 17.2287H87.8561C87.2342 17.2287 86.7294 17.7334 86.7294 18.3554C86.7294 18.9773 87.2342 19.4821 87.8561 19.4821H88.6463C92.3254 19.4821 95.3194 16.4881 95.3194 12.809V12.1179C95.3194 8.43886 92.3254 5.44482 88.6463 5.44482V5.44482Z"
      fill="#1C1C3B"
    />
    <path
      d="M103.294 5.44482C102.672 5.44482 102.167 5.94959 102.167 6.57153V24.3315C102.167 24.9534 102.672 25.4582 103.294 25.4582C103.916 25.4582 104.42 24.9534 104.42 24.3315V6.57153C104.42 5.94959 103.916 5.44482 103.294 5.44482Z"
      fill="#1C1C3B"
    />
    <path
      d="M114.057 8.0752C113.435 8.0752 112.931 8.57996 112.931 9.2019V24.3298C112.931 24.9518 113.435 25.4565 114.057 25.4565C114.679 25.4565 115.184 24.9518 115.184 24.3298V9.2019C115.184 8.57996 114.679 8.0752 114.057 8.0752Z"
      fill="#1C1C3B"
    />
    <path
      d="M120.273 5.44482H107.844C107.223 5.44482 106.718 5.94959 106.718 6.57153C106.718 7.19347 107.223 7.69824 107.844 7.69824H120.273C120.895 7.69824 121.4 7.19347 121.4 6.57153C121.4 5.94959 120.895 5.44482 120.273 5.44482Z"
      fill="#1C1C3B"
    />
    <path
      d="M15.9986 0C8.05867 0 1.57087 6.32629 1.30722 14.2027H0V15.307H1.31136C1.63023 23.1338 8.09594 29.4021 16 29.4021C23.9041 29.4021 30.3698 23.1324 30.6886 15.307H32V14.2027H30.6928C30.4277 6.32629 23.9386 0 15.9986 0ZM15.9986 28.2992C8.50039 28.2992 2.40186 22.1993 2.40186 14.7024C2.40186 7.20559 8.50177 1.10431 15.9986 1.10431C23.4955 1.10431 29.5954 7.20421 29.5954 14.7011C29.5954 22.1979 23.4955 28.2978 15.9986 28.2978V28.2992Z"
      fill="#1C1C3B"
    />
    <path
      d="M20.6849 18.9209C19.3308 18.9209 18.3617 17.4687 16.6059 14.8336L15.3373 12.9369V12.9425C13.8534 10.7642 12.7684 9.48736 11.094 9.48736C8.79707 9.48736 7.11714 12.2329 6.11223 14.5368L7.12405 14.9785C8.34016 12.1915 9.7868 10.593 11.094 10.593C12.4012 10.593 13.4158 12.0452 15.173 14.6804L16.4416 16.577V16.5715C17.9255 18.7497 19.0105 20.0266 20.6849 20.0266C22.9819 20.0266 24.6618 17.281 25.6667 14.9771L24.6549 14.5354C23.4388 17.3224 21.9921 18.9209 20.6849 18.9209Z"
      fill="#1C1C3B"
    />
  </svg>
)
