import { Box, Button, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import AMPModal from "src/components/common/AMPModal";
import { AppDispatch, InvoiceDTO, RevenueInvoiceDTO } from "src/types";
import {Document, Page, pdfjs} from 'react-pdf/dist/umd/entry.webpack';
import { invoicesApi } from "src/api";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { documentWrapperSx } from "./InvoicePreview.sx";
import { NotifyError, NotifyWarning } from "src/utils/notification";
import { isErrorObject } from "src/utils/helper";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type InvoicePdfTriggerQuery = ReturnType<typeof invoicesApi.useLazyGetInvoicePdfQuery>[0]

const handleGetInvoicePdf = (trigger: InvoicePdfTriggerQuery, dispatch: AppDispatch, businessId: number, userId: number, invoiceId: number, setOpen: Dispatch<SetStateAction<boolean>>) => async () => {
  try{
    dispatch(preloaderChangeStatus(true))
    const result = await trigger({businessId, userId, invoiceId})
  } catch(error){
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false))
    setOpen(true)
  }
}

export const InvoicePreview: FC<{data: InvoiceDTO | RevenueInvoiceDTO }> = ({data}) => {
  const intl = useIntl()
  const [open, setOpen] = useState<boolean>(false)
  const [file, setFile] = useState<File[] | null>(null)
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [trigger, response] = invoicesApi.useLazyGetInvoicePdfQuery()
  const dispatch = useAppDispatch()
  const { business } = useAppSelector(state => state.common.authInfo)

  const onDocumentLoadSuccess = ({ numPages }: {numPages : number}) => {
    setNumPages(numPages);
  }
  const dialogTitle = intl.formatMessage({id: 'invoicePreview'})

  const handleOpenModal = () => {
    if(!data.amount){
      NotifyWarning(intl.formatMessage({id: 'noInvoiceDocument'}))
      return 
    }
    handleGetInvoicePdf(trigger, dispatch, +business.id, data.user.id, data.id, setOpen)()
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  return (
    <Box>
      <Button
        onClick={handleOpenModal}
        sx={{p: 0}}
      >
        <Typography
          color="primary"
        >
          { `${data.user.firstName} ${data.user.lastName}` }
        </Typography>
      </Button>
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
      >
        <Box sx={documentWrapperSx}>
          <Document
            file={response.data}
            renderMode="svg"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={pageNumber}
              className="pdfPage"
              renderAnnotationLayer={false}
            />
          </Document>
        </Box>
      </AMPModal>
    </Box>
  )
}