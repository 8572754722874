import { FC } from "react"
import { Box, Typography } from "@mui/material"
import { Formik, FormikConfig, FormikProps } from "formik"
import { FormattedMessage, useIntl } from "react-intl"
import { authApi } from "src/api"
import { forgotPasswordFormInitialValues } from "../formData/common/initialData"
import { LoginType } from "../formData/login"
import { AMPInput } from "src/components/common/AMPInput"
import { getHelperText } from "src/utils/forms"
import { useAppDispatch } from "src/app/hooks"
import { Link, NavigateFunction, useNavigate } from "react-router-dom"
import { AppDispatch } from "src/types"
import { SubmitButton } from "src/components/common/SubmitButton"
import { preloaderChangeStatus } from "src/stores/common/commonSlice"
import { NotifyError, NotifySuccess } from "src/utils/notification"
import { forgotPasswordSchema, ForgotPasswordType } from "../formData/forgotPassword"
import { loginFormSx, actionsWrapperSx } from "./ForgotPasswordForm.sx"


type ForgetQueryTrigger = ReturnType<typeof authApi.useLazyForgotPasswordQuery>[0]

const handleSubmit = (trigger: ForgetQueryTrigger, dispatch: AppDispatch, navigate: NavigateFunction, successMessage: string): FormikConfig<ForgotPasswordType>['onSubmit'] => (async ({ emailAddress }) => {
  try {
    dispatch(preloaderChangeStatus(true))
    await trigger({
      emailAddress
    }).unwrap().finally(() => {
      dispatch(preloaderChangeStatus(false));
    });
    NotifySuccess(successMessage)
    navigate('/');

  } catch (error: any) {
    NotifyError(error.data.message)
  }
});

export const ForgotPasswordForm: FC = () => {
  const intl = useIntl();
  const [trigger, response] = authApi.useLazyForgotPasswordQuery()
  const successMessage = intl.formatMessage({ id: 'emailWithInstructions' })
  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  return (
    <Formik
      initialValues={forgotPasswordFormInitialValues}
      validationSchema={forgotPasswordSchema}
      onSubmit={handleSubmit(trigger, dispatch, navigate, successMessage)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }: FormikProps<ForgotPasswordType>) => (
        <form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box
            sx={loginFormSx}
          >
            <AMPInput
              value={values['emailAddress']}
              type={'emailAddress'}
              label={'emailAddress'}
              input={'emailAddress'}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched['emailAddress'] && Boolean(errors['emailAddress'])}
              helperText={getHelperText('emailAddress', touched, errors, intl)}
              placeholder='emailPlaceholder'
            />
            <Box sx={actionsWrapperSx}>
              <Link
                to="/login"
              >
                <Typography
                  variant="body1"
                  color="primary"
                >
                  <FormattedMessage id="iRememberPassword" />
                </Typography>
              </Link>
              <SubmitButton
                text="submit"
                isSubmitting={isSubmitting}
                rounding
                color="brand"
              />
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
}
