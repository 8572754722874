import { Box, Grid } from "@mui/material";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tariffsApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { store } from "src/app/store";
import { ClientListInTariffsInfo } from "src/components/tariffs/ClientListInTariffsInfo";
import { TariffCard } from "src/components/tariffs/TariffCard";
import { TariffDescription } from "src/components/tariffs/TariffDescription";
import { TariffPageHeader } from "src/components/tariffs/TariffPageHeader";
import ability from "src/permissions/ability";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { TariffByIdRequest } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { useGetUserLanguage } from "../../hooks/useGetUserLanguage";

type TariffByIdQueryTrigger = ReturnType<
  typeof tariffsApi.useLazyGetTariffByIdQuery
>[0];

const handleGetTariffInfo =
  (trigger: TariffByIdQueryTrigger) => async (data: TariffByIdRequest) => {
    store.dispatch(preloaderChangeStatus(true));

    try {
      const response = await trigger(data).unwrap();
      
      return response;
    } catch (error) {
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    } finally {
      store.dispatch(preloaderChangeStatus(false));
    }
  };

export const TariffInfo: FC = () => {
  const params = useParams();
  const { authInfo, activeBusiness } = useAppSelector((state) => state.common);
  const [trigger, response] = tariffsApi.useLazyGetTariffByIdQuery();

  const permissions = ability.can('read', 'singlePages')
  const navigate = useNavigate()
  const [currentLanguage] = useGetUserLanguage()

  useEffect(() => {
    if(!permissions){
      return navigate(`/404`);
    }
    Object.keys(authInfo).length &&
      params.id &&
      handleGetTariffInfo(trigger)({
        tariffId: +params.id,
        businessId: activeBusiness,
      });
  }, []);
  
  return (
    <>
      {response.data && (
        <Box>
          <TariffPageHeader
            language={currentLanguage}
            data={response.data}
          />
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              lg={4}
            >
              <TariffCard
                language={currentLanguage}
                data={response.data}
                selected={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={8}
            >
              <TariffDescription
                data={response.data}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <ClientListInTariffsInfo
                tariffId={response.data.id}
                businessId={response.data.business.id}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
