import {
  FC
} from 'react';
import { useIntl } from 'react-intl';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { CustomFormLabel } from '../CustomFormLabel';
import { InputProps } from './AMPSwitcher.types';
import { radioGroupSx } from './AMPSwitcher.sx';
import { SelectValue } from 'src/types';

export const AMPSwitcher: FC<InputProps>  = ({
  input,
  label,
  data,
  ...rest
}) => {
  const intl = useIntl();
  
  return (
    <FormControl
      fullWidth
      disabled={rest?.disabled}
      variant="outlined"
      margin="normal"
    >
      <CustomFormLabel
        label={intl.formatMessage({ id: label })}
        required={rest.required}
      />
      <RadioGroup
        defaultValue={rest.value}
        name={input}
        sx={radioGroupSx}
        {...rest}
      >
        { data.map((item: SelectValue) => (
          <FormControlLabel
            key={item.id}
            value={item.value}
            control={<Radio />}
            label={item.name}
          />
        ))
        }
      </RadioGroup>
    </FormControl>
  );
};

