import { alpha, SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const detailsWrapperSx: SxProps<Theme> = {
  borderRadius: theme.spacing(8/8),
  background: theme.palette.background.default,
  marginTop: theme.spacing(20/8),
  overflow: 'hidden'
}
export const detailsTitleSx: SxProps<Theme> ={
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(8/8)
}
export const detailItemSx: SxProps<Theme> = {
  '&.MuiGrid-root': {
    marginTop: theme.spacing(16/8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(16/8)
  }
}
export const captionSx: SxProps<Theme> = {
  color: theme.palette.neutral[400]
}
export const imageWrapperSx: SxProps<Theme> = {
  maxHeight: theme.spacing(220/8),
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  justifyContent: 'center',
  alignSelf: 'center',
  [theme.breakpoints.down('lg')]: {
    minHeight: theme.spacing(215/8)
  }
}
export const imageInnerSx: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  position: 'relative',
  maxWidth: '300px',
  paddingBottom: '100%',
  '& img':{
    objectFit: 'contain',
    position: 'absolute',
    inset: 0,
    zIndex: 0,
    width: '100%',
    height: '100%'
  }
}
export const buttonImageWrapperSx: SxProps<Theme> = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  background: alpha(theme.palette.bg.dark, 0.2),
  transition: '0.3s ease',
  zIndex: 1,
  '&:hover': {
    opacity: 1
  }
}
export const imageButtonSx: SxProps<Theme> = {
  '&:hover': {
    background: theme.palette.neutral[400]
  }
}

export const editButtonSx: SxProps<Theme> = {
  background: 'transparent'
}
export const editCostBtnSx: SxProps<Theme> = {
  width: theme.spacing(20/8),
  height: theme.spacing(20/8)
}
export const editCostIconSx: SxProps<Theme> = {
  width: theme.spacing(16/8),
  height: theme.spacing(16/8)
}
export const costBoxSx: SxProps<Theme> = {
  display: "flex",
  gap: 1
}
