import { Box } from "@mui/material";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { TablePagination } from "src/components/tables/TablePagination";
import { TableRowCounter } from "src/components/tables/TableRowCounter";
import { Table } from "../../tables/Table";
import { TariffTableProps } from "./TariffsTable.types";
import { columnConfigTariffs } from "./TariffsTable.tableConfig";
import { TariffSearchRequest } from "src/types";
import { TariffsFilterValue } from "../TariffsTableActions/TariffsTableActions.types";
import { TariffsTableActions } from "../TariffsTableActions";
import { TariffTableCard } from "../TariffTableCard";
import { useGetUserLanguage } from "../../../hooks/useGetUserLanguage";
import { tableFooterSx } from "./TariffsTable.sx";
import { ResponsiveTable } from "../../common/ResponsiveTable";

export const TariffsTable: FC<TariffTableProps> = ({ data, size, pageNumber, handleUpdateTableData, handleChangePage, handleChangeRowsNumber }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<TariffsFilterValue>({});
  const [sortValue, setSortValue] = useState<GridSortItem>({ field: '', sort: 'asc' });
  const [currentLanguage] = useGetUserLanguage()

  const handleSearch = ( value: string ) => {
    setSearchValue(value)
    handleUpdateTableData(updateData(value, sortValue, filterValue))
  }

  const handleFilter = ( value: TariffsFilterValue ) => {
    setFilterValue(value)
    handleUpdateTableData(updateData(searchValue, sortValue, value))
  }

  const handleSort = ( value: GridSortModel ) => {
    setSortValue(value[0])
    handleUpdateTableData(updateData(searchValue, value[0], filterValue))
  }
  
  const updateData = (search: string, sort: GridSortItem, filter: TariffsFilterValue) => {
    return {
      businessId: filter.businessId,
      country: filter.country,
      region: filter.region,
      keyword: search,
      sortAsc: sort ? sort.sort === 'asc' : true,
      sortField: sort && sort.field
    }
  }
  
  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <TariffsTableActions
        handleSearch={handleSearch}
        handleFilter={handleFilter}
      />
      <ResponsiveTable
        desktopElement={
          <Table
            rows={data.content}
            hideFooter
            checkboxSelection={false}
            columns={columnConfigTariffs(currentLanguage)()}
            handleSort={handleSort}
          />
        }
        responsiveElement={
          <Box>
            {data?.content?.map(item => (
              <TariffTableCard
                key={item?.id}
                {...item}
              />
            ))}
          </Box>
        }
      />
      <Box sx={tableFooterSx}>
        <TableRowCounter
          defaultValue={size}
          handleChangeRowsNumber={handleChangeRowsNumber}
        />
        <TablePagination
          totalPages={data.totalPages}
          page={pageNumber && pageNumber === 0 ? pageNumber : data.number}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Box>
  )
}
