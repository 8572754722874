import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'src/axiosBaseQuery'
import { ActiveTransactionDTO, ActiveTransactionRequest, ChartsSearchRequestData, ConsumedPower, ConsumedPowerPoint, ConsumedPowerRequest, PageTransactionDTO, TransactionSearchRequest } from 'src/types'
import { getBaseURL } from 'src/utils/helper'

// initialize an empty api service that we'll inject endpoints into later as needed
export const transactionsApi = createApi({
  reducerPath: 'transactionsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL() + '/transactions'),
  }),
  endpoints: (build) => ({
    getTransactions: build.query<PageTransactionDTO, TransactionSearchRequest>({
      query: ({ data, params }) => ({ url: '/search', method: 'post', data, params }),
    }),
    getConsumedPower: build.query<ConsumedPower, ConsumedPowerRequest>({
      query: (data) => ({url: '/consumedPower', method: 'post', data})
    }),
    getChartData: build.query<ConsumedPowerPoint[], ChartsSearchRequestData>({
      query: (data) => ({ url: '/consumedPower/chart', method: 'post', data }),
    }),
  }),
})
