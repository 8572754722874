import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AbsoluteImageTemplate } from "../AbsoluteImageTemplate";
import { LogoWhite } from "src/components/icons/LogoWhite";
import { Link, NavLink } from "react-router-dom";
import {
  containerSx,
  drawerActionsSx,
  drawerContentSx,
  drawerInnerSx,
  headerSx,
  langSelectWrapperSx,
  linksBoxSx, linksSmSx, linksSx, secondaryTextSx,
  titleSx,
  wrapperSx
} from "./LoginDrawer.sx";
import { AppRouteEnum, AppRouteSpace } from "src/utils/helper";
import { LangSelect } from "../LangSelect";
import theme from "src/theme";
import { useAppSelector } from "src/app/hooks";
import { AppleStore } from "../../icons/AppleStore";
import { GooglePlay } from "../../icons/GooglePlay";
import { Apple } from "../../icons/Apple";
import { Google } from "../../icons/Google";
import { appleStoreLink, googlePlayLink } from "../../../utils/consts";

export const LoginDrawer: FC = () => {
  const intl = useIntl();
  const { lang } = useAppSelector(state => state.common)

  return(
    <Box sx={wrapperSx}>
      <AbsoluteImageTemplate>
        <img
          alt="Login Background"
          src="/static/images/login-bg.jpg"
        />
      </AbsoluteImageTemplate>
      <Box sx={containerSx}>
        <Box sx={headerSx}>
          <NavLink
            to={AppRouteSpace([AppRouteEnum.app])}
          >
            <LogoWhite height={"56"} />
          </NavLink>
          <Box sx={langSelectWrapperSx}>
            <LangSelect
              white={window.innerWidth < theme.breakpoints.values['md']}
            />
          </Box>
        </Box>
        <Box sx={linksSmSx}>
          <Typography
            variant="body1"
            sx={secondaryTextSx}
          >
            <FormattedMessage id="downloadApp" />
          </Typography>
          <Box sx={linksBoxSx}>
            <a
              href={appleStoreLink}
              target="_blank"
              rel="noreferrer"
            >
              <Apple/>
            </a>
            <a
              href={googlePlayLink}
              target="_blank"
              rel="noreferrer"
            >
              <Google/>
            </a>
          </Box>
        </Box>
        <Box sx={drawerContentSx}>
          <Box sx={drawerInnerSx}>
            <Typography
              variant="h2"
              color="textSecondary"
              sx={titleSx}
            >
              <FormattedMessage id="loginDrawerTitle" />
            </Typography>
            <Typography
              sx={secondaryTextSx}
              variant="body1"
            >
              <FormattedMessage id="loginDrawerDescription" />
            </Typography>
          </Box>
          <Box>
            <Box sx={linksSx}>
              <Typography
                variant="subtitle1"
                sx={secondaryTextSx}
              >
                <FormattedMessage id="downloadApp" />
              </Typography>
              <Box sx={linksBoxSx}>
                <a
                  href={appleStoreLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <AppleStore/>
                </a>
                <a
                  href={googlePlayLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <GooglePlay/>
                </a>
              </Box>
            </Box>
            <Box sx={drawerActionsSx}>
              <Link to={AppRouteSpace([AppRouteEnum.termsOfUse, lang])}>
                <Typography
                  variant="body1"
                  sx={secondaryTextSx}
                >
                  <FormattedMessage id={'termsOfService'} />
                </Typography>
              </Link>
              <Typography variant="caption"
                sx={secondaryTextSx}
              >
              |
              </Typography>
              <Link to={AppRouteSpace([AppRouteEnum.privacyPolicy, lang])}>
                <Typography
                  variant="body1"
                  sx={secondaryTextSx}
                >
                  <FormattedMessage id={'privacyPolicy'} />
                </Typography>
              </Link>
              <Typography
                variant="caption"
                sx={secondaryTextSx}>
              |
              </Typography>
              <Link to={AppRouteSpace([AppRouteEnum.support, lang])}>
                <Typography
                  variant="body1"
                  sx={secondaryTextSx}
                >
                  <FormattedMessage id={'support'} />
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
