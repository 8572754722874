import { FC } from "react";

export const  EmptyIcon:FC = () => {
  return (
    <svg width="141"
      height="140"
      viewBox="0 0 141 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M69.7707 118.063C95.2253 118.063 115.862 97.4266 115.862 71.8811C115.862 46.3356 95.1344 25.6992 69.7707 25.6992C44.3161 25.6992 23.6797 46.3356 23.6797 71.8811C23.6797 97.4266 44.3161 118.063 69.7707 118.063Z"
        fill="#F5F5F7"
        stroke="#DBDBE6"
        strokeWidth="2"
        strokeMiterlimit="10"/>
      <path d="M112.501 41.6967C114.559 41.6967 116.228 40.028 116.228 37.9695C116.228 35.9109 114.559 34.2422 112.501 34.2422C110.442 34.2422 108.773 35.9109 108.773 37.9695C108.773 40.028 110.442 41.6967 112.501 41.6967Z"
        fill="#F5F5F7"/>
      <path d="M117.952 27.1534C119.358 27.1534 120.497 26.0138 120.497 24.608C120.497 23.2021 119.358 22.0625 117.952 22.0625C116.546 22.0625 115.406 23.2021 115.406 24.608C115.406 26.0138 116.546 27.1534 117.952 27.1534Z"
        fill="#F5F5F7"/>
      <path d="M25.772 41.6065C27.1778 41.6065 28.3175 40.4669 28.3175 39.0611C28.3175 37.6553 27.1778 36.5156 25.772 36.5156C24.3662 36.5156 23.2266 37.6553 23.2266 39.0611C23.2266 40.4669 24.3662 41.6065 25.772 41.6065Z"
        fill="#F5F5F7"/>
      <path d="M10.2273 90.7007C12.8381 90.7007 14.9546 88.5842 14.9546 85.9734C14.9546 83.3626 12.8381 81.2461 10.2273 81.2461C7.61648 81.2461 5.5 83.3626 5.5 85.9734C5.5 88.5842 7.61648 90.7007 10.2273 90.7007Z"
        fill="#F5F5F7"/>
      <path d="M103.669 103.196C95.2466 112.342 83.1798 118.063 69.7771 118.063C59.0807 118.063 49.2351 114.419 41.4141 108.299V32.9262C41.4141 30.4329 43.4216 28.3984 45.9261 28.3984H90.689L103.669 41.4232V103.196Z"
        fill="white"
        stroke="#DBDBE6"
        strokeWidth="2"
        strokeMiterlimit="10"/>
      <path d="M59.9876 102.476H53.4691C53.2258 102.476 53.0312 101.846 53.0312 101.09C53.0312 100.333 53.2258 99.7031 53.4691 99.7031H59.9876C60.2309 99.7031 60.4255 100.333 60.4255 101.09C60.4255 101.972 60.2309 102.476 59.9876 102.476Z"
        fill="#DFDFE8"/>
      <path d="M74.332 44.2455H53.9131C53.4383 44.2455 53.0312 43.6056 53.0312 42.8591C53.0312 42.1125 53.4383 41.4727 53.9131 41.4727H74.332C74.8068 41.4727 75.2139 42.1125 75.2139 42.8591C75.2139 43.6056 74.8068 44.2455 74.332 44.2455Z"
        fill="#DBDBE6"/>
      <path d="M58.9505 50.7142H53.6278C53.3066 50.7142 53.0312 50.0744 53.0312 49.3278C53.0312 48.5813 53.3066 47.9414 53.6278 47.9414H58.9047C59.2259 47.9414 59.5012 48.5813 59.5012 49.3278C59.5012 50.0744 59.2259 50.7142 58.9505 50.7142Z"
        fill="#DBDBE6"/>
      <path d="M91.1681 86.7617H65.8592H63.0698H54.6335C54.2933 86.7617 53.9531 87.3394 53.9531 88.1481C53.9531 88.8413 54.2253 89.5345 54.6335 89.5345H63.0698H65.8592H91.1681C91.5083 89.5345 91.8484 88.9569 91.8484 88.1481C91.7804 87.3394 91.5083 86.7617 91.1681 86.7617Z"
        fill="#DBDBE6"/>
      <path d="M91.1669 79.3672H81.7612H78.5578H54.6347C54.2939 79.3672 53.9531 79.9449 53.9531 80.7536C53.9531 81.4468 54.2258 82.14 54.6347 82.14H78.5578H81.7612H91.1669C91.5076 82.14 91.8484 81.5623 91.8484 80.7536C91.7803 79.9449 91.5076 79.3672 91.1669 79.3672Z"
        fill="#DBDBE6"/>
      <path d="M92.1378 72.8984H89.6339H87.13H54.6487C54.3009 72.8984 53.9531 73.4761 53.9531 74.2848C53.9531 74.9781 54.2313 75.6713 54.6487 75.6713H87.13H89.9121H92.0682C92.416 75.6713 92.7638 75.0936 92.7638 74.2848C92.8333 73.5916 92.4855 72.8984 92.1378 72.8984Z"
        fill="#DBDBE6"/>
      <path d="M92.0832 65.5039H84.0159H82.0163H54.6426C54.2979 65.5039 53.9531 66.0816 53.9531 66.8903C53.9531 67.5835 54.2289 68.2767 54.6426 68.2767H82.0163H84.0159H92.0142C92.4969 68.2767 92.7727 67.6991 92.7727 66.8903C92.7727 66.1971 92.4969 65.5039 92.0832 65.5039Z"
        fill="#DBDBE6"/>
      <path d="M90.6875 28.9414V35.9662C90.6875 39.2799 93.3738 41.9662 96.6875 41.9662H103.667"
        fill="#F5F5F7"/>
      <path d="M89.6875 28.9414V35.9662H91.6875V28.9414H89.6875ZM96.6875 42.9662H103.667V40.9662H96.6875V42.9662ZM89.6875 35.9662C89.6875 39.8322 92.8215 42.9662 96.6875 42.9662V40.9662C93.9261 40.9662 91.6875 38.7276 91.6875 35.9662H89.6875Z"
        fill="#DBDBE6"/>
      <path d="M46.2618 94.175C46.2618 94.3404 46.2207 94.5033 46.1424 94.6489L42.3123 101.766C41.9348 102.468 40.9286 102.468 40.5511 101.766L36.721 94.6489C36.6426 94.5033 36.6016 94.3404 36.6016 94.175V51.3036V45.0605C36.6016 44.1829 37.3171 43.4648 38.1917 43.4648H44.6716C45.5462 43.4648 46.2618 44.1829 46.2618 45.0605V94.175Z"
        fill="#DBDBE6"
        stroke="#B3B3C9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"/>
      <path d="M46.2618 50.8828H36.6016"
        stroke="#B3B3C9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"/>
      <path d="M46.2618 94.4258H36.6016"
        stroke="#B3B3C9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"/>
    </svg>
  )
}
