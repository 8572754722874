import { GridColumns } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { RemoveUserFormLocationButton } from '../RemoveUserFormLocationButton';
import { AppRouteEnum, AppRouteSpace } from "../../../utils/helper";

const deleteCol = (canDelete: boolean):GridColumns => {
  return canDelete ? [{
    field: 'remove',
    headerName: '',
    flex: 1,
    cellClassName: 'ampTableCell hoverableCell',
    renderCell: (params) => (
      <RemoveUserFormLocationButton
        userId={params.row.id}
      />
    )
  }] : []
}

export const columnConfig = (canCreateDeleteUsers = true): GridColumns => ([
  {
    field: 'id',
    renderHeader: (params) => <FormattedMessage id={"idColumnHeader"} />,
    flex: 1,
    cellClassName: 'ampTableCell',
  },
  {
    field: 'parkingPlace',
    renderHeader: (params) => <FormattedMessage id={"parkingPlaceColumnHeader"} />,
    flex: 3,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.parkingAddress ? params.row.parkingAddress.parkingPlace : <FormattedMessage id="na" />
  },
  {
    field: 'firstName',
    renderHeader: (params) => <FormattedMessage id={"clientColumnHeader"} />,
    flex: 3,
    cellClassName: 'ampTableCell',
    renderCell: (params) => (
      <Link
        to={`/app/users/${params.row.id}`}
      >
        {`${params.row.firstName} ${params.row.lastName}`}
      </Link>
    )
  },
  {
    field: 'chargePointId',
    renderHeader: (params) => <FormattedMessage id={"chargePointIdColumnHeader"} />,
    flex: 3,
    cellClassName: 'ampTableCell',
    renderCell: (params) => (
      <>
        {params.row.chargePoint ? (
          <Link to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.chargers, String(params.row.chargePoint?.id)])}>
            {`${params.row.chargePoint?.chargePointId}`}
          </Link>
        ) : "-"}
      </>
    )
  },
  ...deleteCol(canCreateDeleteUsers)
])
