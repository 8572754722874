import { FormLabel, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { useStyles } from "./CustomFormLabel.sx";
import { CustomFormLabelType } from "./CustomFormLabel.types";

export const CustomFormLabel: FC<CustomFormLabelType> = ({ required, label }) => {
  const theme = useTheme()
  const styles = useStyles(theme)
  return (
    <FormLabel sx={styles.formLabel}>
      <Typography variant="caption">
        {label}
        {required && (
          <span>*</span> 
        )}
      </Typography>
    </FormLabel>
  )}
