import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';



export const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    '&.MuiButton-root': {
      borderColor: theme.palette.neutral[200],
      '& span': {
        textTransform: 'none'
      }
    }
  }
}));
