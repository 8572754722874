import { FC } from "react";
import { Form, Formik, FormikProps } from "formik";
import { Box, Button, Grid, Typography } from "@mui/material";
import { actionsWrapperSx, formContentSx, formSx } from "../CreateRfidForm/CreateRfidForm.sx";
import { AMPInput } from "../../common/AMPInput";
import { getHelperText } from "../../../utils/forms";
import { CancelButton } from "../../common/CancelButton";
import { SubmitButton } from "../../common/SubmitButton";
import { EditChargerCostFormType, editChargerCostSchema } from "../formData/editChargerCost";
import { CurrencyEnum } from "../../../enums";
import dayjs from "dayjs";
import { AMPDatePicker } from "../../common/AMPDatePicker";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { EditChargerCostFormProps } from "./EditChargerCostForm.types";
import { AMPSwitcher } from "../../common/AMPSwitcher";
import { adornmentSx } from "../EditLocationCostForm/EditLocationCostForm.sx";
import { chargersApi } from "../../../api";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { AppDispatch } from "../../../types";
import { isErrorObject } from "../../../utils/helper";
import { NotifyError, NotifySuccess } from "../../../utils/notification";
import { useAppDispatch } from "../../../app/hooks";
import { Can } from "../../../permissions/Can";
import ability from "../../../permissions/ability";

type EditCostRequestType = ReturnType<
  typeof chargersApi.useUpdateChargerCostMutation
  >[0];
type CreateCostRequestType = ReturnType<
  typeof chargersApi.useCreateChargerCostMutation
  >[0];
type DeleteCostRequestType = ReturnType<
  typeof chargersApi.useDeleteChargerCostMutation
  >[0];

const handleCostSubmit = (
  intl: IntlShape,
  editTrigger: EditCostRequestType,
  createTrigger: CreateCostRequestType,
  dispatch: AppDispatch,
  closeModal: () => void,
  businessId: number,
  chargerId: number,
  itemId?: number
) => async (values: EditChargerCostFormType) => {
  try {
    dispatch(preloaderChangeStatus(true))
    if (itemId) {
      const result = await editTrigger({
        data: {
          businessId,
          currency: values.currency,
          currentCost: values.cost,
          installationDate: values.date,
          serviceLife: values.serviceLife,
          name: String(itemId)
        },
        params: {
          chargerId,
          itemId
        }
      }).unwrap()
      NotifySuccess(intl.formatMessage({id: 'updatedSuccessfully'}))
      return result
    } else {
      const result = await createTrigger({
        chargerId,
        data: {
          businessId,
          currency: values.currency,
          initCost: values.cost,
          installationDate: values.date,
          serviceLife: values.serviceLife
        }
      }).unwrap()
      NotifySuccess(intl.formatMessage({id: 'createdSuccessfully'}))
      return result
    }
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data?.message ?? error.data?.error)
    }
  } finally {
    dispatch(preloaderChangeStatus(false))
    closeModal()
  }
}

const handleDeleteCost = (
  intl: IntlShape,
  deleteTrigger: DeleteCostRequestType,
  businessId: number,
  chargerId: number,
  itemId: number,
  closeModal: () => void,
  dispatch: AppDispatch
) => async () => {
  try {
    dispatch(preloaderChangeStatus(true))
    const result = await deleteTrigger({
      businessId,
      params: {
        itemId,
        chargerId
      }
    }).unwrap()
    NotifySuccess(intl.formatMessage({id: 'deletedSuccessfully'}))
    return result
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data?.message ?? error.data?.error)
    }
  } finally {
    dispatch(preloaderChangeStatus(false))
    closeModal()
  }
}

export const EditChargerCostForm:FC<EditChargerCostFormProps> = ({
  onClose,
  init,
  chargerId,
  itemId,
  businessId,
  closeModal
}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [editCost] = chargersApi.useUpdateChargerCostMutation()
  const [createCost] = chargersApi.useCreateChargerCostMutation()
  const [deleteCost] = chargersApi.useDeleteChargerCostMutation()
  const canManageCost = ability.can('manage', 'costCharger')
  
  const processedCurrencies = () => {
    return Object.values(CurrencyEnum).map((value, index) => ({
      id: index,
      name: intl.formatMessage({id: value}),
      value: value
    }))
  }
  
  return (
    <Formik
      initialValues={init}
      validationSchema={editChargerCostSchema}
      onSubmit={handleCostSubmit(
        intl,
        editCost,
        createCost,
        dispatch,
        closeModal,
        businessId,
        chargerId,
        itemId
      )}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        setFieldValue,
        values,
      }: FormikProps<EditChargerCostFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <Grid
                container
                rowSpacing={{xs: 2}}
                columnSpacing={{xs: 0, md: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPSwitcher
                    value={values["currency"]}
                    input={"currency"}
                    label={"currency"}
                    data={processedCurrencies()}
                    onChange={handleChange}
                    disabled={!canManageCost}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={{xs: 2}}
                columnSpacing={{xs: 0, md: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPDatePicker
                    label="dateOfInstallation"
                    value={dayjs( values["date"]).valueOf()}
                    disabled={!canManageCost}
                    onChange={(val) => setFieldValue("date", dayjs( val).valueOf())}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={{xs: 2}}
                columnSpacing={{xs: 0, md: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    type='number'
                    value={values['cost']}
                    label={'cost'}
                    input={'cost'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!canManageCost}
                    hasError={touched['cost'] && Boolean(errors['cost'])}
                    helperText={getHelperText('cost', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    type='number'
                    value={values['serviceLife']}
                    label={'serviceLife'}
                    input={'serviceLife'}
                    endAdornment={
                      <Typography sx={adornmentSx}>
                        <FormattedMessage id="years" />
                      </Typography>
                    }
                    onChange={handleChange}
                    disabled={!canManageCost}
                    onBlur={handleBlur}
                    hasError={touched['serviceLife'] && Boolean(errors['serviceLife'])}
                    helperText={getHelperText('serviceLife', touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Can
                I="manage"
                a="costCharger"
              >
                {
                  itemId &&
                  <Button
                    onClick={handleDeleteCost(
                      intl,
                      deleteCost,
                      businessId,
                      chargerId,
                      itemId,
                      closeModal,
                      dispatch
                    )}
                    sx={{mt: 2}}
                    color="error"
                    variant="outlined"
                  >
                    <FormattedMessage id="delete"/>
                  </Button>
                }
              </Can>
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={onClose}
              />
              <Can
                I="manage"
                a="costCharger"
              >
                <SubmitButton
                  text="save"
                  color="primary"
                  isSubmitting={isSubmitting}
                />
              </Can>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
