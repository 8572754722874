import { ChangeEvent, FC, useEffect, useMemo, useState } from "react"
import { Box, Checkbox, FormControlLabel, Grid, SelectChangeEvent, Typography } from "@mui/material"
import { Form, Formik, FormikHandlers, FormikProps } from "formik"
import { FormattedMessage, useIntl } from "react-intl"
import { formSx, actionsWrapperSx, actionsActiveButtonsSx } from "./ContractForm.sx"
import { contractInitialValues } from "../formData/common/initialData"
import { AMPInput } from "src/components/common/AMPInput"
import { getHelperText } from "src/utils/forms"
import { SubmitButton } from "src/components/common/SubmitButton"
import { ContractFormType } from "./ContractForm.types"
import { AMPFormSelect } from "src/components/common/AMPFormSelect"
import { contractSchema, ContractType } from "../formData/contract"
import { CancelButton } from "src/components/common/CancelButton"
import { systemApi } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { handleGetCountries } from "../../../hooks/useGetCountries";
import { SelectValue } from "../../../types";
import { TariffType } from "../formData/tariff";
import { allRegionsValue } from "../../../utils/consts";
import { AMPPhoneNumber } from "../../common/AMPPhoneNumber";

export const ContractForm: FC<ContractFormType> = ({ handleClose, authInfo, changeStep, handleSubmit }) => {
  const intl = useIntl();
  const { lang } = useAppSelector((state) => state.common)
  const [addressBlock, setAddressBlock] = useState<boolean>(false)
  const [getCountries, countries] = systemApi.useLazyGetCountriesQuery()
  const dispatch = useAppDispatch()
  const [activeCountry, setActiveCountry] = useState<string>('')
  
  useEffect(() => {
    handleGetCountries(getCountries, dispatch)
  }, [])
  
  const processedRegions = useMemo(() => {
    const initialArray: SelectValue[] = [{
      id: 0,
      name: activeCountry.length ? intl.formatMessage({id: activeCountry.toLowerCase()}) : '',
      value: allRegionsValue
    }]
    if (activeCountry.length) {
      const mappedRegions: SelectValue[] | undefined = countries.data?.find(country => country.name === activeCountry)?.
        regions?.map(region => ({
          id: region.id,
          name: intl.formatMessage({id: region.name}),
          value: region.name
        }))
      return  mappedRegions?.concat(initialArray[0])
        ?? initialArray
    }
    return initialArray
  }, [activeCountry, lang])
  
  const handleChangeCountry = (
    handleChange: FormikHandlers["handleChange"],
    setFieldValue: FormikProps<TariffType>['setFieldValue']
  ) => (event: SelectChangeEvent) => {
    setActiveCountry(event.target.value);
    setFieldValue('region', '')
    return handleChange(event);
  };

  const processedCountries = countries?.data?.map(country => ({
    id: country.id,
    name: intl.formatMessage({id: country.name.toLowerCase()}),
    value: country.name
  })) ?? []
  
  const toggleAddressBlock = (
    checked: boolean,
    handleChange: FormikHandlers["handleChange"],
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikProps<TariffType>['setFieldValue']
  ) => {
    setAddressBlock(checked)
    handleChange(event)
    if (checked) {
      setActiveCountry(authInfo.parkingAddress.country)
      setFieldValue('country', authInfo.parkingAddress.country)
      setFieldValue('region', authInfo.parkingAddress.region ?? allRegionsValue)
      setFieldValue('city', authInfo.parkingAddress.city)
      setFieldValue('street', authInfo.parkingAddress.street)
      setFieldValue('houseNumber', authInfo.parkingAddress.houseNumber)
      setFieldValue('zipCode', authInfo.parkingAddress.zipCode)
    }
  }
  
  const handleChangePhone = (setFieldValue: FormikProps<TariffType>['setFieldValue'], fieldName: string) =>
    (newValue: string | number | null | undefined) => {
      if(newValue){
        setFieldValue(fieldName, `+${newValue}`)
      }
    }

  return (
    <Formik
      initialValues={contractInitialValues}
      validationSchema={contractSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }: FormikProps<ContractType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box
            sx={formSx}
          >
            <Box>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="generalInfo" />
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPPhoneNumber
                    value={values["phoneNumber"]}
                    onChange={handleChangePhone(setFieldValue, "phoneNumber")}
                    label={"phoneNumber"}
                    onBlur={handleBlur}
                    hasError={
                      touched["phoneNumber"] && Boolean(errors["phoneNumber"])
                    }
                    helperText={getHelperText("phoneNumber", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="homeAddress" />
              </Typography>
              <FormControlLabel
                label={intl.formatMessage({id: "sameAddress"})}
                control={
                  <Checkbox
                    color="secondary"
                    name="useParkingAddress" 
                    value={values['useParkingAddress']}
                    onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                      toggleAddressBlock(
                        checked,
                        handleChange,
                        event,
                        setFieldValue
                      )}
                  />
                }
              />
              {!addressBlock && (
                <>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      lg={6}
                      xs={12}
                      item
                    >
                      <AMPFormSelect
                        data={processedCountries}
                        value={values['country'] as string}
                        label={'country'}
                        input={'country'}
                        onChange={handleChangeCountry(handleChange, setFieldValue)}
                        onBlur={handleBlur}
                        hasError={touched['country'] && Boolean(errors['country'])}
                        helperText={getHelperText('country', touched, errors, intl)}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      xs={12}
                      item
                    >
                      <AMPFormSelect
                        data={processedRegions}
                        value={values['region'] as string}
                        label={'region'}
                        disabled={!activeCountry.length}
                        input={'region'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={touched['region'] && Boolean(errors['region'])}
                        helperText={getHelperText('region', touched, errors, intl)}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      xs={12}
                      item
                    >
                      <AMPInput
                        type='text'
                        disabled={Boolean(!activeCountry)}
                        value={values['city']}
                        label={'city'}
                        input={'city'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={touched['city'] && Boolean(errors['city'])}
                        helperText={getHelperText('city', touched, errors, intl)}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      xs={12}
                      item
                    >
                      <AMPInput
                        value={values['street'] as string}
                        type={'street'}
                        label={'street'}
                        input={'street'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={touched['street'] && Boolean(errors['street'])}
                        helperText={getHelperText('street', touched, errors, intl)}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      xs={12}
                      item
                    >
                      <AMPInput
                        value={values['houseNumber'] as string}
                        type={'houseNumber'}
                        label={'houseNumber'}
                        input={'houseNumber'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={touched['houseNumber'] && Boolean(errors['houseNumber'])}
                        helperText={getHelperText('houseNumber', touched, errors, intl)}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      xs={12}
                      item
                    >
                      <AMPInput
                        value={values['zipCode'] as string}
                        type={'zipCode'}
                        label={'zipCode'}
                        input={'zipCode'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={touched['zipCode'] && Boolean(errors['zipCode'])}
                        helperText={getHelperText('zipCode', touched, errors, intl)}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Box>
          <Box sx={actionsWrapperSx}>
            <CancelButton
              text="cancel"
              handler={handleClose}
            />
            <Box sx={actionsActiveButtonsSx}>
              <CancelButton
                text="back"
                color="primary"
                handler={changeStep}
              />
              <SubmitButton
                text="submit"
                color="primary"
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
