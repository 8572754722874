import { FC } from "react";
import { MoreVert } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import theme from "../../../../theme";
import { MenuButtonTypes } from "./MenuButton.types";
import { iconSx } from "./MenuButton.sx";

export const MenuButton:FC<MenuButtonTypes> = ({
  handleClick
}) => {
  return (
    <IconButton
      onClick={handleClick}
      sx={iconSx}>
      <MoreVert
        sx={{ height: '0.875rem' }}
        fill={theme.palette.neutral[400]}
      />
    </IconButton>
  )
}
