import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { FC, useEffect, useState } from "react";
import { LatLngType } from "src/types";
import { defaultMapCenter } from "src/utils/consts";
import { Map } from "../Map";
import { Marker } from "../Marker";
import { MapWrapperType } from "./MapWrapper.types";

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

export const MapWrapper: FC<MapWrapperType> = ({markers, zoom, mapCenter = defaultMapCenter, clusterization, handleClick}) => {
  const [marks, setMarks] = useState<LatLngType[]>()
  
  const onClick = (e: google.maps.MapMouseEvent) => {
    if(e.latLng && handleClick){
      const place = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
      handleClick(e)
      setMarks([place]);
    }
  };

  useEffect(() => {
    if(mapCenter.lat !== defaultMapCenter.lat || mapCenter.lng !== defaultMapCenter.lng){
      setMarks([mapCenter])
    }
  }, [mapCenter])

  useEffect(() => {
    setMarks(markers)
  }, [markers])
  
  return (
    <>
      {process.env.REACT_APP_GOOGLE_API_KEY && <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        render={render}
      >
        <Map 
          center={mapCenter}
          onClick={onClick}
          zoom={zoom}
          style={{ flexGrow: "1", height: "100%" }}
          fullscreenControl={false}
          panControl={false}
          zoomControl={false}
          mapTypeControl={false}
          streetViewControl={false}
          markers={marks}
          clusterization={clusterization}
        >
          {!clusterization && marks && marks.map((latLng, index) => (
            <Marker
              key={index}
              position={latLng}
              icon="/static/images/PinMap.png"
            />
          ))}
        </Map>
      </Wrapper>}
    </>
  )
}
