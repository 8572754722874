import { PlayArrow } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FC } from "react";
import { ocppApi } from "src/api";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { buttonWrapperSx } from "./ActivateButton.sx";
import { ActivateButtonType } from "./ActivateButton.types";

type StartChargingMutationTrigger = ReturnType<typeof ocppApi.useStartChargingMutation>[0]

const handleStartCharging = (trigger: StartChargingMutationTrigger, chargerId: number, connectorId: number, rfId: string, businessId: number) => async () => {
  try{
    await trigger({ chargerId, connectorId, rfId, businessId }).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  }
}

export const ActivateButton: FC<ActivateButtonType> = ({chargerId, connectorId, rfId, disabled, businessId}) => {
  const [startCharging, message] = ocppApi.useStartChargingMutation()

  return (
    <IconButton
      sx={buttonWrapperSx}
      onClick={handleStartCharging(startCharging, chargerId, connectorId, rfId, businessId)}
      disabled={disabled}
    >
      <PlayArrow fontSize="large"/>
    </IconButton>
  )
}
