import { FC, useState } from "react";
import { Edit } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { usersApi } from "src/api";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { AppDispatch } from "src/app/store";
import { Can } from "src/permissions/Can";
import { ColorField } from "src/components/common/ColorField";
import { DropZoneModal } from "src/components/common/DropZoneModal";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import {
  buttonImageWrapperSx,
  detailItemSx,
  detailsInnerSx,
  detailsTitleSx,
  detailsWrapperSx,
  imageButtonSx,
  imageInnerSx,
  imageWrapperSx
} from "./TechnicalTeamDetails.sx";
import { isErrorObject } from "src/utils/helper";
import ability from "src/permissions/ability";
import { blankPhoto } from "src/utils/consts";
import { NotifyError } from "src/utils/notification";
import { TechnicalTeamDetailsProps } from "./TechnicalTeamDetails.types";
import { UserResetWrapper } from "../../users/UserResetWrapper";

type AddUserAvatarMutationTrigger = ReturnType<
  typeof usersApi.useSetUserAvatarMutation
>[0];

const handleTrigger = async (
  file: File[],
  userId: number,
  trigger: AddUserAvatarMutationTrigger,
  dispatch: AppDispatch,
  handleCloseImageModal: () => void,
  handleBlockSubmit: (status: boolean) => void,
  businessId?: number,
) => {
  try {
    handleBlockSubmit(true)
    dispatch(preloaderChangeStatus(true));
    const formData = new FormData()
    formData.append('file', file[0])
    await trigger({ data: formData, userId }).unwrap()
    handleCloseImageModal()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
    handleBlockSubmit(false)
  }
}

export const TechnicalTeamDetails: FC<TechnicalTeamDetailsProps> = ({ data }) => {
  const intl = useIntl();
  const { authInfo } = useAppSelector((state) => state.common);
  const [openDropZone, setOpenDropZone] = useState<boolean>(false)
  const [userImage, setUserImage] = useState<File[]>()
  const canReadResetPassword = ability.can('read', 'resetPassword')

  const uploadDialogTitle = intl.formatMessage({id: 'addNewImage'})
	
  const dispatch = useAppDispatch();
  const [trigger] = usersApi.useSetUserAvatarMutation();

  const [blockSubmit,setBlockSubmit] = useState(false)
  
  const handleBlockSubmit = (status: boolean) => {
    setBlockSubmit(status)
  }

  const handleCloseImageModal = () => {
    setOpenDropZone(false)
  }

  const handleSetImage = (file: File[] | null) => {
    file && setUserImage(file)
  }

  const handleSubmit = () => {
    userImage &&
      handleTrigger(userImage, data.id, trigger, dispatch, handleCloseImageModal, handleBlockSubmit, data.business.id)
  }

  return (
    <>
      <Box sx={detailsWrapperSx}>
        <Grid
          container
          spacing={2}
          sx={detailsInnerSx}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={imageWrapperSx}
          >
            <Box sx={imageInnerSx}>
              <Box sx={buttonImageWrapperSx}>
                <IconButton
                  onClick={() => setOpenDropZone(true)}
                  sx={imageButtonSx}
                >
                  <Edit />
                </IconButton>
              </Box>
              <DropZoneModal
                submitIsBlocked={blockSubmit}
                handleClose={handleCloseImageModal}
                open={openDropZone}
                title={uploadDialogTitle}
                handleFile={handleSetImage}
                handleSubmit={handleSubmit}
                isImage
              />
              <img
                src={data?.avatar?.url ?? blankPhoto}
                alt={`${data?.firstName} ${data?.lastName}`}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
          >
            <Box sx={detailsTitleSx}>
              <Typography variant="subtitle1">
                <FormattedMessage id="managerDetails" />
              </Typography>
            </Box>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                md={12}
                sx={detailItemSx}
              >
                <Typography
                  variant="caption"
                >
                  <FormattedMessage id="email" />
                </Typography>
                <Typography
                  variant="subtitle2"
                >
                  {data.emailAddress && data.emailAddress}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={detailItemSx}
              >
                <Typography
                  variant="caption"
                >
                  <FormattedMessage id="userRole" />
                </Typography>
                <ColorField label={String(data.roles[0].role)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {(canReadResetPassword || data.id === authInfo.id) && <UserResetWrapper data={data} />}
      </Box>
    </>
  );
}
