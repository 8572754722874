import { FC, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { NotificationInfoSection } from "../../components/notifications/NotificationInfoSection";
import { Box } from "@mui/material";
import { wrapperSx } from "./NotificationInfo.sx";
import { locationsApi, notificationApi } from "../../api";
import { store } from "../../app/store";
import { preloaderChangeStatus } from "../../stores/common/commonSlice";
import { isErrorObject } from "../../utils/helper";
import { NotifyError } from "../../utils/notification";
import { GetLocationByIdType, NotificationTypeEnum } from "../../types";
import { useAppSelector } from "../../app/hooks";

type GetNotificationByIdType = ReturnType<typeof notificationApi.useLazyGetNotificationByIdQuery>[0];
type MarkAsReadType = ReturnType<typeof notificationApi.useMarkNotificationAsReadMutation>[0];

const handleGetNotification = (
  trigger: GetNotificationByIdType,
  notificationId: number,
  businessId: number,
  notificationType: NotificationTypeEnum
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    trigger({
      notificationId,
      data: {
        businessId,
        notificationType: notificationType
      }
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
}

const handleMarkAsRead = async (
  trigger: MarkAsReadType,
  notificationId: number,
  businessId: number,
  managerId: number,
  notificationType: NotificationTypeEnum
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    await trigger({
      notificationId,
      data: {
        businessId,
        managerId,
        notificationType: notificationType
      }
    }).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data?.message ?? error.data?.error)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
}

type LocationQueryTrigger = ReturnType<typeof locationsApi.useLazyGetLocationByIdQuery>[0];

const handleGetLocation = (trigger: LocationQueryTrigger) => (async (data: GetLocationByIdType) => {
  store.dispatch(preloaderChangeStatus(true))
  
  try {
    const response = await trigger(data).unwrap()
    store.dispatch(preloaderChangeStatus(false))
    
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
});

export const NotificationInfo:FC = () => {
  const {id} = useParams()
  const [searchParams] = useSearchParams();
  const [getLocation, location] = locationsApi.useLazyGetLocationByIdQuery()
  const [getNotification, notification] = notificationApi.useLazyGetNotificationByIdQuery()
  const [setAsRead] = notificationApi.useMarkNotificationAsReadMutation()
  const { authInfo } = useAppSelector((state) => state.common);
  const type = searchParams.get('type') as NotificationTypeEnum | null

  useEffect(() => {
    type && handleGetNotification(
      getNotification,
      Number(id),
      authInfo.business.id,
      type
    )
  }, [])
  
  useEffect(() => {
    const locationId = notification?.data?.user?.location?.id
    if (locationId
      && (
        notification?.data?.type === NotificationTypeEnum.NEW_CONTRACT_EXIST_LOCATION ||
        notification?.data?.type === NotificationTypeEnum.NEW_CONTRACT_NEW_LOCATION
      )
    ) {
      handleGetLocation(getLocation)({locationId, businessId: authInfo.business.id})
    }
  }, [notification?.data])
  
  useEffect(() => {
    if (notification?.data?.read === false && type) {
      handleMarkAsRead(
        setAsRead,
        Number(id),
        authInfo.business.id,
        authInfo.id,
        type
      )
    }
  }, [notification])
  
  return (
    <Box sx={wrapperSx}>
      {
        notification?.data &&
        <NotificationInfoSection
          data={notification?.data}
          location={location?.data}
        />
      }
    </Box>
  )
}
