import { Box, RadioGroupProps } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ocppApi } from "src/api";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError, NotifySuccess } from "src/utils/notification";
import { ChangeBooleanValue } from "../ChangeBooleanValue";
import { ChangeIntValue } from "../ChangeIntValue";
import { ChangeStringValue } from "../ChangeStringValue";
import { ConfigurationFormType } from "./ConfigurationForm.types";

type EditConfigurationMutationTrigger = ReturnType<typeof ocppApi.useChangeConfigurationsMutation>[0];

const handleSubmit =
  (
    trigger: EditConfigurationMutationTrigger,
    dispatch: AppDispatch,
    chargerId: number,
    businessId: number,
    value: string,
    confKey: string,
    handleClose: () => void
  ) =>  async () => {
    dispatch(preloaderChangeStatus(true));
    try {
    
      const result = await trigger({chargerId, businessId, value, confKey}).unwrap()
      NotifySuccess(result.message)
      handleClose()
    } catch (error) {
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    } finally {
      dispatch(preloaderChangeStatus(false));
    }
  };

export const ConfigurationForm: FC<ConfigurationFormType> = ({ configValue, name, handleClose, chargerId }) => {

  const [trigger, response] = ocppApi.useChangeConfigurationsMutation()
  const dispatch = useAppDispatch()
  const { activeBusiness } = useAppSelector(state => state.common)
  const [type, setType] = useState<string>('string')
  const handleChangeBooleanValue: RadioGroupProps['onChange'] = (e, value) => {
    handleSubmit(trigger, dispatch, chargerId, activeBusiness, value, name, handleClose)()
  } 

  const handleChangeIntValue = (value: number) => {
    handleSubmit(trigger, dispatch, chargerId, activeBusiness, String(value), name, handleClose)()
  } 

  const handleChangeStringValue = (value: string) => {
    handleSubmit(trigger, dispatch, chargerId, activeBusiness, value, name, handleClose)()
  } 
  useEffect(() => {
    if(configValue === 'true' || configValue === 'false' || configValue === 'True' || configValue === 'False'){
      setType('boolean')
      return
    }
    if(+configValue){
      setType('number')
      return
    }
    setType('string')
  }, [configValue])
  return (
    <Box>
      {type === 'boolean' && <ChangeBooleanValue
        onChange={handleChangeBooleanValue}
        value={String(configValue)}
        name={name}
      />}
      {type === 'number' && <ChangeIntValue 
        onChange={handleChangeIntValue}
        value={+configValue}
        name={name}
        handleClose={handleClose}
      />}
      {type === 'string' && <ChangeStringValue 
        onChange={handleChangeStringValue}
        value={String(configValue)}
        name={name}
        handleClose={handleClose}
      />}
    </Box>
  )
}
