import { SxProps, Theme } from "@mui/material"
import theme from "src/theme"

export const toolbarSx: SxProps<Theme> = {
  '&.MuiToolbar-root': {
    marginTop: theme.spacing(50/8),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(10/8),
    alignItems: 'flex-start',
    paddingLeft: 0,
    paddingRight: 0
  }
}
export const linkSx: SxProps<Theme> = {
  display: 'flex',
  width: '100%',
  padding: 0,
  '& a' : {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(10/8),
    color: theme.palette.neutral[500],
    fill: theme.palette.neutral[500],
    padding: theme.spacing(12/8, 14/8),
    width: '100%',
    borderRadius: theme.spacing(4/8),
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.24)'
    },
    '&.active': {
      color: theme.palette.brand.main,
      background: 'rgba(0, 0, 0, 0.24)'
    },
    '&.active svg': {
      fill: theme.palette.brand.main,
    }
  }
}
