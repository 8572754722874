import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const actionsWrapperSx: SxProps<Theme> = {
  padding: theme.spacing(20/8, 24/8),
  borderTop: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`,
  background: theme.palette.neutral[50],
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(20/8),
  gap: 1,
  [theme.breakpoints.down('lg')]: {
    px: theme.spacing( 8/8)
  }
}

export const wrapperSx: SxProps<Theme> = {
  marginTop: theme.spacing(20/8),
  padding: theme.spacing(0, 20/8),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(0, 8/8)
  }
}

export const showLgSx: SxProps<Theme> = {
  [theme.breakpoints.down('lg')]: {
    display: "none"
  }
}

export const actionsActiveButtonsSx: SxProps<Theme> = {
  display: 'flex',
  gap: theme.spacing(10/8),
  alignItems: 'center'
}
