import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { policyDeutschSx } from "./PolicyDeutsch.sx";

export const PolicyDeutsch:FC = () => {
  return (
    <Box>
      <Box sx={policyDeutschSx.root}>
        <Typography variant="h2">
        Datenschutzbestimmungen
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Zuletzt aktualisiert: 18. Oktober 2022 4:08 Uhr
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Ihre Privatsphäre ist uns sehr wichtig. Bitte lesen Sie diese Datenschutzrichtlinie
          sorgfältig durch, bevor Sie die von AMP IT SA, einer in der Schweiz gegründeten
          Gesellschaft mit beschränkter Haftung („uns“, „wir“, „unser“) betriebene AMP IT SA-
          Anwendung (die „Anwendung“) nutzen, da diese Datenschutzrichtlinie wichtige
          Informationen zu Ihrem Datenschutz und zur Verwendung der von uns über Sie
          erfassten Daten enthält.
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Ihr Zugriff auf die Anwendung und deren Nutzung setzt voraus, dass Sie diese
          Datenschutzrichtlinie akzeptieren und einhalten. Diese Datenschutzrichtlinie gilt für
          alle, einschließlich, aber nicht beschränkt auf: Besucher, Nutzer und andere, die auf
          die Anwendung zugreifen oder sie nutzen möchten.
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Indem Sie auf die Anwendung zugreifen oder sie nutzen, erklären Sie sich mit dieser
          Datenschutzrichtlinie einverstanden. Wenn Sie mit irgendeinem Teil der
          Datenschutzrichtlinie nicht einverstanden sind, dann haben Sie nicht unsere Erlaubnis,
          auf die Anwendung zuzugreifen oder sie zu nutzen.
        </Typography>
        <Typography
          variant="h3"
          sx={policyDeutschSx.header}
        >
          Welche Informationen wir sammeln, wie wir sie
          verwenden, was passiert, wenn wir sie nicht
          haben, und die Rechtsgrundlage für die
          Verarbeitung dieser Informationen
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Wir erfassen alle Informationen, die Sie in diese Anwendung eingeben. Wir sammeln
          die folgenden Informationen über Sie:
        </Typography>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
          fontWeight="800"
        >
          Identifizierungsinformation - Name
        </Typography>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Spezifische Information
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Name
        </Typography>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Rechtsgrundlage für die Verarbeitung dieser Informationen
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Der Nutzer hat seine Zustimmung zur Verarbeitung seiner Daten gegeben;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verarbeitung ist erforderlich, um einen Vertrag mit dem Nutzer zu erfüllen;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verarbeitung ist erforderlich, um Maßnahmen zu ergreifen, die der Nutzer vor dem Abschluss eines Vertrags beantragt hat;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Wie wir diese Informationen verwenden
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Durchsetzung unserer Nutzungsbedingungen;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Erbringung von Dienstleistungen;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Dienst am Kunden;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Bereitstellung von Finanzmitteln;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Beilegung von Streitigkeiten;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Überprüfung der Kundeninformationen.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Was passiert, wenn wir diese Informationen nicht haben?
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Nutzung Ihrer Daten zur Durchsetzung unserer Nutzungsbedingungen ist nicht möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Nutzung Ihrer Daten zur Erbringung von Dienstleistungen ist dann nicht mehr möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Nutzung Ihrer Daten für den Kundenservice ist dann nicht mehr möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Nutzung Ihrer Daten zur Finanzierung ist dann nicht möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verwendung Ihrer Daten zur Überprüfung von Kundeninformationen ist nicht möglich.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Notre utilisation de vos données pour vérifier les informations client ne sera pas possible.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
          fontWeight="800"
        >
          Identifizierungsinformationen - Telefonnummer
        </Typography>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Spezifische Information
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Rufnummer
        </Typography>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Rechtsgrundlage für die Verarbeitung dieser Informationen
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Der Nutzer hat seine Zustimmung zur Verarbeitung seiner Daten gegeben;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verarbeitung ist erforderlich, um einen Vertrag mit dem Nutzer zu erfüllen;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verarbeitung ist erforderlich, um Maßnahmen zu ergreifen, die der Nutzer vor dem Abschluss eines Vertrags beantragt hat;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Wie wir diese Informationen verwenden
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Durchsetzung unserer Nutzungsbedingungen;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Erbringung von Dienstleistungen;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Dienst am Kunden;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Bereitstellung von Finanzmitteln;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Beilegung von Streitigkeiten;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Überprüfung der Kundeninformationen.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Was passiert, wenn wir diese Informationen nicht haben?
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >

              Die Nutzung Ihrer Daten zur Durchsetzung unserer Nutzungsbedingungen ist nicht möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Nutzung Ihrer Daten zur Erbringung von Dienstleistungen ist dann nicht mehr möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Nutzung Ihrer Daten für den Kundenservice ist dann nicht mehr möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Nutzung Ihrer Daten zur Finanzierung ist dann nicht möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verwendung Ihrer Daten zur Beilegung von Streitigkeiten ist nicht möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verwendung Ihrer Daten zur Überprüfung von Kundeninformationen ist nicht möglich.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          fontWeight="800"
          sx={policyDeutschSx.header}
        >
          Identifizierungsinformationen - E-Mail-Adresse
        </Typography>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Spezifische Information
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          E-Mail-Adresse
        </Typography>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Rechtsgrundlage für die Verarbeitung dieser Informationen
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Der Nutzer hat seine Zustimmung zur Verarbeitung seiner Daten gegeben;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verarbeitung ist erforderlich, um Maßnahmen zu ergreifen, die der Nutzer vor dem Abschluss eines Vertrags beantragt hat;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Wie wir diese Informationen verwenden
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Erbringung von Dienstleistungen;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Dienst am Kunden;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Bereitstellung von Finanzmitteln;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Beilegung von Streitigkeiten;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Überprüfung der Kundeninformationen.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Was passiert, wenn wir diese Informationen nicht haben?
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Eine Nutzung Ihrer Daten zur Erbringung von Dienstleistungen ist dann nicht mehr möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Eine Nutzung Ihrer Daten für den Kundenservice ist dann nicht mehr möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Eine Nutzung Ihrer Daten zur Finanzierung ist dann nicht möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verwendung Ihrer Daten zur Beilegung von Streitigkeiten ist nicht möglich;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verwendung Ihrer Daten zur Überprüfung von Kundeninformationen ist nicht möglich.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
          fontWeight="800"
        >
          Identifizierungsinformationen - Physische Adresse
        </Typography>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Spezifische Information
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Physische Adresse
        </Typography>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Rechtsgrundlage für die Verarbeitung dieser Informationen
        </Typography>
        <ul>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Der Nutzer hat seine Zustimmung zur Verarbeitung seiner Daten gegeben;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verarbeitung ist erforderlich, um einen Vertrag mit dem Nutzer zu erfüllen;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verarbeitung ist erforderlich, um Maßnahmen zu ergreifen, die der Nutzer vor dem Abschluss eines Vertrags beantragt hat;
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich.
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
          fontWeight="800"
        >
          Identifizierungsinformationen - Fotos
        </Typography>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Spezifische Information
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Fotos
        </Typography>
        <Typography
          variant="h5"
          sx={policyDeutschSx.header}
        >
          Rechtsgrundlage für die Verarbeitung dieser Informationen
        </Typography>
        <Typography
          variant="body2"
          sx={policyDeutschSx.paragraph}
        >
          Der Nutzer hat seine Zustimmung zur Verarbeitung seiner Daten gegeben;
        </Typography>
        <Typography
          variant="body2"
          sx={policyDeutschSx.paragraph}
        >
          Es ist nur zur Verwendung in der Anwendung als Avatar gedacht.
        </Typography>
        <Typography
          variant="h3"
          sx={policyDeutschSx.header}
        >
          An wen wir Ihre persönlichen Daten weitergeben
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Wir geben Ihre persönlichen Daten nicht an Dritte weiter.
        </Typography>
        <Typography
          variant="h3"
          sx={policyDeutschSx.header}
        >
          Die Privatsphäre von Kindern
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Diese Anwendung ist für die Nutzung durch ein allgemeines Publikum gedacht und bietet keine Dienste für Kinder an. Sollte ein Kind, von dem wir wissen, dass es unter 18 Jahre alt ist, persönliche Informationen an uns senden, werden wir diese Informationen nur verwenden, um dem Kind zu antworten und es darüber zu informieren, dass es diese Anwendung nicht nutzen kann.
        </Typography>
        <Typography
          variant="h3"
          sx={policyDeutschSx.header}
        >
          Speicherung von Informationen
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Wir behalten alle Informationen, die wir sammeln, bis der Benutzer eine Kontolöschung beantragt.
        </Typography>
        <Typography
          variant="h3"
          sx={policyDeutschSx.header}
        >
          Die Rechte von in der Europäischen Union und/oder im Europäischen Wirtschaftsraum ansässigen Personen
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Einwohner der Europäischen Union und des Europäischen Wirtschaftsraums haben die folgenden Rechte in Bezug auf ihre persönlichen Daten:
        </Typography>
        <ol>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Das Recht auf Zugang zu Ihren Informationen. Sie haben das Recht, auf alle Informationen zuzugreifen, die wir über Sie gesammelt haben. Sie können Kopien der Informationen, die wir über Sie haben, in einem strukturierten, allgemein verwendeten, maschinenlesbaren Format anfordern.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Übermittlung von Informationen. Sie haben das Recht, von uns zu verlangen, dass wir Ihre persönlichen Daten, die wir über Sie gesammelt haben, an einen anderen Anbieter übermitteln, sofern dies technisch möglich ist.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Berichtigung von Informationen. Sie haben das Recht, uns aufzufordern, alle Informationen, die wir über Sie gesammelt haben, zu ändern.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Sie haben das Recht, Ihre Zustimmung zur Verarbeitung Ihrer Daten zu widerrufen.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Sie haben das Recht zu verlangen, dass wir die Verarbeitung Ihrer Daten einschränken.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Sie haben das Recht, uns aufzufordern, alle oder einige der von uns über Sie gesammelten Daten zu löschen.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              sx={policyDeutschSx.paragraph}
            >
              Sie haben das Recht, bei den zuständigen Behörden eine Beschwerde über die Erhebung, Weitergabe und Verarbeitung von Daten durch uns einzureichen.
            </Typography>
          </li>
        </ol>
        <Typography
          variant="h3"
          sx={policyDeutschSx.header}
        >
          Ausübung Ihrer Rechte
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Die Einwohner der Europäischen Union und des Europäischen Wirtschaftsraums können die oben genannten Rechte ausüben, indem sie einen Antrag an den Verbraucher stellen:
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          AMP IT SA
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          info@amp-it.ch
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          022 525 77 22
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          amp-it.de
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Rue des Sablieres 1-3, 1242 Satigny, Schweiz
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Wir müssen Ihre Identität überprüfen, bevor wir Ihre Anfrage bearbeiten können, und können Sie dazu auffordern, persönliche Daten anzugeben. Wir beantworten die meisten Anfragen von Verbrauchern innerhalb von 30 Tagen nach Eingang. Bei manchen Anfragen kann es jedoch länger dauern. Wir werden Sie schriftlich benachrichtigen, wenn wir mehr Zeit für die Beantwortung benötigen. Wir haben die Möglichkeit, Ihre Anfrage(n) abzulehnen, wenn bestimmte gesetzliche Ausnahmen zutreffen. Wenn wir Ihren Antrag ablehnen, werden wir Ihnen die Gründe für die Ablehnung mitteilen.
        </Typography>
        <Typography
          variant="h3"
          sx={policyDeutschSx.header}
        >
          Ort der Datenverarbeitung
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Alle von uns durchgeführten Datenverarbeitungsaktivitäten finden in der Schweiz statt.
        </Typography>
        <Typography
          variant="h3"
          sx={policyDeutschSx.header}
        >
          Fragen
        </Typography>
        <Typography
          variant='body2'
          sx={policyDeutschSx.paragraph}
        >
          Wenn Sie Fragen zu dieser Datenschutzerklärung haben, kontaktieren Sie uns bitte unter info@amp-it.ch.
        </Typography>
      </Box>
    </Box>
  )
}