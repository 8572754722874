import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FC, useContext } from "react";
import { useParams } from "react-router-dom";
import { locationsApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import LocationInfoContext from "src/pages/LocationInfo/LocationInfo.context";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { RemoveUserFormLocationButtonType } from "./RemoveUserFormLocationButton.types";

type DeleteUserFromLocationMutationTrigger = ReturnType<typeof locationsApi.useDeleteUserFromLocationMutation>[0]

const handleDelete = (trigger: DeleteUserFromLocationMutationTrigger, dispatch: AppDispatch, locationId: number, userId: number, context: any) => (async () => {
  const data = {
    businessId: context.businessId,
    locationId,
    userId
  }
  dispatch(preloaderChangeStatus(true))
  try {
    await trigger(data).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
});

export const RemoveUserFormLocationButton: FC<RemoveUserFormLocationButtonType> = ({userId}) => {
  const { id: locationId } = useParams()
  const [trigger, response] = locationsApi.useDeleteUserFromLocationMutation()
  const dispatch = useAppDispatch()
  const context = useContext(LocationInfoContext)
  return (
    <>
      {locationId && (
        <IconButton onClick={handleDelete(trigger, dispatch, +locationId, userId, context)}>
          <Delete />
        </IconButton>
      )}
    </>
  )
}
