import { Box } from "@mui/material";
import { Children, cloneElement, FC, isValidElement, useEffect, useRef, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { MapSx } from "./Map.sx";
import { MapType } from "./Map.types";
import { useDeepCompareEffectForMaps } from "src/app/hooks";
import { DefaultRenderer, MarkerClusterer, Renderer } from "@googlemaps/markerclusterer";
import { labels } from "src/utils/consts";
import { ClusterMark } from "../ClusterMark";

export const Map: FC<MapType> = ({
  onClick,
  children,
  style,
  markers,
  clusterization,
  ...options
}) => {

  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  const [cluster, setClusterer] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  const infoWindow = new google.maps.InfoWindow({
    content: "",
    disableAutoPan: true,
  });

  const marks = markers && markers.map((position, i) => {
    const label = labels[i % labels.length];
    const marker = new google.maps.Marker({
      position,
      clickable: false,
      icon: "/static/images/PinMap.png"
    });

    // markers can only be keyboard focusable when they have click listeners
    // open info window when marker is clicked
    marker.addListener("click", () => {
      infoWindow.setContent(label);
      infoWindow.open(map, marker);
    });

    return marker;
  });

  useEffect(() => {
    if (map) {
      ["click"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      clusterization && new MarkerClusterer({ markers: marks, map, renderer: {render: ClusterMark}});
    }
  }, [map, onClick]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  return (
    <>
      <Box
        ref={ref}
        sx={MapSx}
      />
      {
        Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, { map });
          }
        })}
    </>
  )
};
