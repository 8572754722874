import { FC } from "react"
import { Box} from "@mui/material"
import { Form, Formik, FormikProps } from "formik"
import { resetPasswordFormInitialValues } from "../formData/common/initialData"
import { AMPInput } from "src/components/common/AMPInput"
import { getHelperText } from "src/utils/forms"
import { SubmitButton } from "src/components/common/SubmitButton"
import { resetPasswordSchema, ResetPasswordType } from "../formData/resetPassword"
import { LoginFormSx, actionsWrapperSx } from "./ResetPasswordForm.sx"
import { ResetPasswordFormType } from "./ResetPasswordForm.types"
import { useIntl } from "react-intl"

export const ResetPasswordForm: FC<ResetPasswordFormType> = ({handleSubmit}) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={resetPasswordFormInitialValues}
      validationSchema={resetPasswordSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }: FormikProps<ResetPasswordType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box
            sx={LoginFormSx}
          >
            <AMPInput
              value={values['password'] as string}
              type={'password'}
              label={'password'}
              input={'password'}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched['password'] && Boolean(errors['password'])}
              helperText={getHelperText('password', touched, errors, intl)}
            />
            <AMPInput
                
              value={values['confirmPassword'] as string}
              type={'confirmPassword'}
              label={'confirmPassword'}
              input={'confirmPassword'}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched['confirmPassword'] && Boolean(errors['confirmPassword'])}
              helperText={getHelperText('confirmPassword', touched, errors, intl)}
            />
            <Box sx={actionsWrapperSx}>
              <SubmitButton
                text="submit"
                isSubmitting={isSubmitting}
                rounding
                color="brand"
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
