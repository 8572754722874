import { ChangeEvent, FocusEvent } from 'react';
import { store } from 'src/app/store';
import {
  ChargePointStatus,
  ChargingStatusEnum,
  ConfigurationStatusEnum,
  ConnectorStatusEnum,
  CurrencyEnum
} from 'src/enums';
import { AuthUserDTO } from '../auth';
import { SwaggerPageable } from '../locations';

export type AppDispatch = typeof store.dispatch;

export interface CommonStateType {
  lang: string
}

export interface isSubmittingProps {
  setSubmitting: (isSub: boolean) => void;
}
export interface FormKeyValue {
  [key:string]: string
}

export interface Page {
  empty: boolean,
  first: boolean,
  last: boolean,
  number: number,
  numberOfElements: number,
  pageable: SwaggerPageable,
  size: number,
  sort: Sort,
  totalElements: number,
  totalPages: number
}

export interface Sort {
  empty: boolean,
  sorted: boolean,
  unsorted: boolean
}

export interface ItemCostUpdate {
  businessId: number,
  currency: CurrencyEnum,
  currentCost: number,
  initCost?: number,
  installationDate: number,
  name?: string,
  serviceLife: number
}

export interface ItemCostCreate {
  businessId: number,
  currency: CurrencyEnum,
  initCost?: number,
  name?: string,
  installationDate: number,
  serviceLife: number
}

export interface ItemCost {
  chargePointId: number,
  currency: CurrencyEnum,
  currentCost: number,
  id: number,
  initCost: number,
  installationDate: number,
  locationId: number,
  name: string,
  serviceLife: number
}

export interface FormInterface {
  handleBlur: (e: FocusEvent<any>) => void,
  handleChange: (e: string | ChangeEvent<any>) => void,
  handleSubmit: () => void,
  isSubmitting: boolean,
  errors: FormKeyValue,
  values: FormKeyValue,
  touched: FormKeyValue,
  setFieldValue: any,
  setTouched: any
}

export interface SelectValue {
  value: string | number,
  id: number,
  name: string,
  onClick?: () => void
}

export interface AutocompleteValue {
  id: number,
  label: string,
  caption?: string,
  image?: string,
  index?: number
}

export interface DefaultAuthResponse {
  message: string,
  payload: AuthUserDTO,
  success: boolean
}

export interface DefaultResponse {
  message: string,
  payload: string,
  success: boolean,
  error?: string
}

export enum FeedbackTypeEnum {
  CHANGE_TARIFF = "CHANGE_TARIFF",
  HELP = "HELP",
  NEW_RFID = "NEW_RFID"
}

export interface DefaultError {
  error: ErrorObject
}

export interface ErrorObject extends Error {
  data: DefaultResponse,
  status: number
}

export interface OCPPTagDTO {
  blocked:	boolean,
  created:	string,
  expiry:	string,
  id:	number,
  idTag:	string
}

export interface SearchRequestParams {
  size?: number,
  page?: number
}

export interface LatLngType {
  lat: number,
  lng: number
}

export interface DataType {
  [index: string]: any;
}

export interface ReactLocationStateType {
  page: string
}

export interface ChartSimpleData {
  count: number,
  date: string
}
export interface CHARGE_POINT_STATUS_TYPE {
  chargerId: number,
  chargePointId: string,
  status: ChargePointStatus
}
export interface CHARGING_STATUS_TYPE {
  amperage: string,
  chargerId: number,
  chargePointId: string,
  status: ChargingStatusEnum,
  connectorId: number,
  transactionId: number,
  connectedTimeMinutes: number,
  chargingTimeMinutes: number,
  wattsConsumed: number
}
export interface CONNECTOR_STATUS_TYPE {
  chargerPointId: string;
  chargerId: number,
  chargePointId: string,
  connectorId: number,
  status: ConnectorStatusEnum
}
export interface CONFIGURATIONS_TYPE {
  chargerId: number,
  chargePointId: string,
  status: ConfigurationStatusEnum,
  configuration: FormKeyValue[]
}

export interface AMPERAGE_TYPE {
  transactionId:number,
  chargerId:number,
  chargePointId: string,
  amperage: number
}

export interface TableParamsType {
  page?: number,
  size?: number
}

export enum NotificationTypeEnum {
  CHANGE_PLAN_REQUEST = "CHANGE_PLAN_REQUEST",
  NEW_CONTRACT_EXIST_LOCATION = "NEW_CONTRACT_EXIST_LOCATION",
  NEW_CONTRACT_NEW_LOCATION = "NEW_CONTRACT_NEW_LOCATION",
  USER_FEEDBACK = "USER_FEEDBACK",
  NEW_USER_REGISTRATION = "NEW_USER_REGISTRATION",
  STATION_ASSOCIATION = "STATION_ASSOCIATION",
  NEW_RFID_REQUEST = "NEW_RFID_REQUEST",
  NEW_LOCATION = "NEW_LOCATION"
}

export interface ListParamsType {
  page: number,
  size: number
}
