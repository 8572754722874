import { FC } from "react";

export const AttentionIcon:FC = () => {
  return (
    <svg width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8.4974 0.666992C3.90244 0.666992 0.164062 4.40537 0.164062 9.00033C0.164062 13.5953 3.90244 17.3337 8.4974 17.3337C13.0923 17.3337 16.8307 13.5953 16.8307 9.00033C16.8307 4.40537 13.0923 0.666992 8.4974 0.666992ZM9.29868 13.4839H7.69611V11.8813H9.29868V13.4839ZM9.13842 10.9234H7.85637L7.61599 4.51315H9.37881L9.13842 10.9234Z"
        fill="#E62930"/>
    </svg>
  )
}
