import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const contentItemSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  '&.MuiGrid-item.MuiGrid-root' : {
    paddingLeft: 0,
  },
  '& img': {
    marginBottom: theme.spacing(20/8)
  }
}

export const stepperSx: SxProps<Theme> = {
  width: 'auto',
  justifyContent: 'center',
  gap: theme.spacing(20/8),
  padding: theme.spacing(20/8, 0),
  position: 'sticky',
  top: theme.spacing(0),
  zIndex: 4,
  background: theme.palette.background.default,
  borderBottom: `${theme.spacing(1/8)} solid ${theme.palette.neutral[400]}`,
  '& span': {
    borderLeftWidth: theme.spacing(2/8),
    color: theme.palette.text.primary
  },
  '&.Mui-completed span, &.Mui-active span':{
    borderColor: theme.palette.brand.main,
  }
}

export const separatorSx: SxProps<Theme> = {
  fill: theme.palette.neutral[400]
}

export const stepIconsSx: SxProps<Theme> = {
  width: theme.spacing(30/8),
  height: theme.spacing(30/8),
  fontSize: theme.typography.h5,
  '&.Mui-completed': {
    fill: theme.palette.brand.main
  },
  '&.Mui-active': {
    stroke: theme.palette.brand.main,
    fill: 'transparent'
  }
}
