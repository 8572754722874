import { FC } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { useIntl } from "react-intl";
import { usersApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { AppDispatch } from "src/app/store";
import { AMPInput } from "src/components/common/AMPInput";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { editBexioFormSchema, EditBexioFormType } from "../formData/bexioId";
import { actionsWrapperSx, formContentSx, formSx } from "./EditBexioIdForm.sx";
import { EditBexioIdFormTypes } from "./EditBexioIdForm.types";
import { getHelperText } from "src/utils/forms";

type EditBexioIdMutationTrigger = ReturnType<typeof usersApi.useEditBexioIdMutation>[0];

const handleSubmit =
  (
    trigger: EditBexioIdMutationTrigger,
    dispatch: AppDispatch,
    userId: number,
    businessId: number,
    handleClose: () => void
  ): FormikConfig<EditBexioFormType>["onSubmit"] => {
    return async (value) => {
      try {
        dispatch(preloaderChangeStatus(true));
        await trigger({businessId, bexioId: value.bexioId, userId}).unwrap()
        handleClose()
      } catch (error) {
        if(isErrorObject(error)){
          NotifyError(error.data.message);
        }
      } finally {
        dispatch(preloaderChangeStatus(false));
      }
    };
  }


export const EditBexioIdForm: FC<EditBexioIdFormTypes> = ({handleClose, bexioId, businessId, userId }) => {
  const intl = useIntl()

  const dispatch = useAppDispatch();
  const [trigger] = usersApi.useEditBexioIdMutation();
  
  const EditBexioInitialValue = {
    bexioId: bexioId ?? "",
  }
  
  return (
    <Formik
      initialValues={EditBexioInitialValue}
      validationSchema={editBexioFormSchema}
      onSubmit={handleSubmit(trigger, dispatch, userId, businessId, handleClose)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }: FormikProps<EditBexioFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  md={6}
                  item
                >
                  <AMPInput
                    value={values["bexioId"]}
                    type={"text"}
                    label={"bexioId"}
                    input={"bexioId"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["bexioId"] && Boolean(errors["bexioId"])
                    }
                    helperText={getHelperText(
                      "bexioId",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={handleClose}
              />
              <SubmitButton
                text="save"
                color="primary"
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
