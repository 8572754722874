import { FormikProps, FormikState, FormikValues } from "formik";
import { IntlShape } from "react-intl";

export const getHelperText = <Values extends FormikValues = FormikValues>(
  name: string,
  touched: FormikProps<Values>["touched"],
  errors: FormikProps<Values>["errors"],
  intl: IntlShape
) => {
  if(touched[name] && errors[name]) {
    const id = errors[name] as string;
    return intl.formatMessage({ id })
  }
}

export const getHelperTextWithArray = <
  Values extends FormikValues = FormikValues,
  T extends keyof Values = keyof Values
  >(
    arrayName: T,
    index: number,
    name: string,
    touched: FormikState<Values>['touched'],
    errors: FormikState<Values>['errors'],
    intl: IntlShape
  ) => {
  const hasArrayNameTouched = touched?.[arrayName]
  const fieldIsArrayTouched =  Array.isArray(hasArrayNameTouched) ? hasArrayNameTouched : []
  const fieldNameTouched = fieldIsArrayTouched[index]?.[name]
  
  const hasArrayNameError = errors?.[arrayName]
  const fieldIsArrayError =  Array.isArray(hasArrayNameError) ? hasArrayNameError : []
  const fieldNameError = fieldIsArrayError[index]?.[name]
  
  if (fieldNameTouched && fieldNameError) {
    const id = fieldNameError ?? ''
    return intl.formatMessage({ id })
  }
  return ''
}
