import { Box } from "@mui/material";
import { FC } from "react";
import { ScheduleItem } from "../ScheduleItem";
import { wrapperSx } from "./ScheduleList.sx";
import { ScheduleListType } from "./ScheduleList.types";

export const ScheduleList: FC<ScheduleListType> = ({data, businessId, chargerId}) => {
  return (
    <Box sx={wrapperSx}>
      {
        data.map((item, index) => (
          <ScheduleItem
            key={index}
            data={item}
            businessId={businessId}
            chargerId={chargerId}
          />
        ))
      }
    </Box>
  )
}
