import { AddCircle } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Table } from "src/components/tables/Table";
import { AddFileToLocation } from "../AddFileToLocation";
import { columnConfigLocationFiles, columnConfigLocationFilesSm } from "./LocationFiles.tableConfig";
import { LocationFilesType } from "./LocationFiles.types";
import { useAppSelector } from "src/app/hooks";
import { actionButtonWrapperSx, listWrapperSx, tableSx, titleSx } from "./LocationFiles.sx";
import { ResponsiveTable } from "../../common/ResponsiveTable";

export const LocationFiles: FC<LocationFilesType> = ({ data, title }) => {
  const [open, setOpen] = useState<boolean>(false)
  const {authInfo} = useAppSelector(state => state.common)
  
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return(
    <>
      <Box sx={listWrapperSx}>
        <Box sx={titleSx}>
          <Typography variant="subtitle1">
            {title}
          </Typography>
        </Box>
        <ResponsiveTable
          responsiveElement={
            <Table
              sx={tableSx}
              onCellClick={(params, events) =>
                (params.field === 'delete' || params.field === 'delete')
              && events.stopPropagation()}
              headerHeight={0}
              checkboxSelection={false}
              rows={data.files}
              columns={columnConfigLocationFilesSm(authInfo.roles[0].role)}
              hideFooter
            />
          }
          desktopElement={
            <Table
              sx={tableSx}
              onCellClick={(params, events) =>
                (params.field === 'delete' || params.field === 'delete')
                && events.stopPropagation()}
              headerHeight={0}
              checkboxSelection={false}
              rows={data.files}
              columns={columnConfigLocationFiles(authInfo.roles[0].role)}
              hideFooter
            />
          }
        />
      </Box>
      <Box sx={actionButtonWrapperSx}>
        <Button onClick={handleOpen}>
          <AddCircle />
          <FormattedMessage id="addFile" />
        </Button>
      </Box>
      <AddFileToLocation
        handleClose={handleClose}
        open={open}
        locationId={data.id}
        businessId={data.business.id}
      />
    </>
  )
} 
