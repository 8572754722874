import { Box, Button, Divider, FormControl, FormLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { FC, FormEvent, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AMPFormSelect } from "src/components/common/AMPFormSelect";
import { SubmitButton } from "src/components/common/SubmitButton";
import { InvoiceStatusEnum } from "src/enums";
import ability from "src/permissions/ability";
import { fieldsWrapperSx, formControlSx, actionsWrapperSx, buttonSx } from "./InvoicesFilterForm.sx";
import { InvoicesFilterFormType } from "./InvoicesFilterForm.types";

export const InvoicesFilterForm: FC<InvoicesFilterFormType> = ({
  handleFilter,
  isFilterActive,
  handleClose,
  activeStatus,
  setActiveStatus,
  activeBusiness,
  setActiveBusiness,
  businesses
}) => {

  const processedInvoiceStatus = useMemo(() => {
    return Object.values(InvoiceStatusEnum).map((status, index) => ({
      id: index,
      name: status,
      value: status
    }))
  }, [])


  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleFilter({
      status: activeStatus,
      businessId: activeBusiness})
    isFilterActive(true)
    handleClose()
  }

  const handleClear = () => {
    setActiveStatus('')
    handleFilter({
      status: '',
      businessId: 0
    })
    isFilterActive(false)
  }
  const handleChangeStatus = (event: SelectChangeEvent<string>) => {
    setActiveStatus(event.target.value)
  }
  return (

    <form
      onSubmit={handleSubmit}
      noValidate
    >
      <Box sx={fieldsWrapperSx} >
        {Boolean(businesses.length) && <AMPFormSelect
          data={businesses}
          value={activeBusiness}
          label={"business"}
          input={"business"}
          onChange={(e: SelectChangeEvent<number>) => setActiveBusiness(+e.target.value)}
        />}
        <AMPFormSelect
          label="status"
          input="status"
          onChange={handleChangeStatus}
          value={activeStatus}
          data={processedInvoiceStatus}
        />
      </Box>
      <Divider />
      <Box sx={actionsWrapperSx} >
        <Button
          variant="outlined"
          sx={buttonSx}
          onClick={handleClear}
        >
          <Typography
            variant="button"
            color="neutral.700"
          >
            <FormattedMessage id="clear" />
          </Typography>
        </Button>
        <SubmitButton
          text="apply"
        />
      </Box>
    </form>
  )
}
