import { SxProps, Theme } from "@mui/material"
import theme from "src/theme"

export const rootSx: SxProps<Theme> = {
  backgroundColor: theme.palette.common.white,
  p: 2,
  borderBottom: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`,
  position: "relative"
}
export const menuBtnSx: SxProps<Theme> = {
  width: "100%"
}
export const menuBtnTextSx: SxProps<Theme> = {
  width: "100%",
  textAlign: "start",
  fontSize: theme.spacing(14/8),
  fontWeight: 400,
  color: theme.palette.neutral[400],
}
export const iconBtnSx: SxProps<Theme> = {
  width: theme.spacing(20/8),
  height: theme.spacing(20/8),
  backgroundColor: "transparent"
}
export const popoverSx: SxProps<Theme> = {
  '& .MuiPaper-root': {
    maxWidth: theme.spacing(200/8),
    width: '100%',
    p: 1
  }
}
export const titleTextSx: SxProps<Theme> = {
  fontSize: theme.spacing(10/8),
  fontWeight: 500,
  color: theme.palette.neutral[400],
  textTransform: "uppercase",
  my: 1
}
export const dataTextSx: SxProps<Theme> = {
  fontSize: theme.spacing(12/8),
  fontWeight: 400,
  textAlign: "end"
}
export const idTextSx: SxProps<Theme> = {
  color: theme.palette.primary.main,
  fontSize: theme.spacing(12/8),
  fontWeight: 400,
  textAlign: "end"
}
export const rowSx: SxProps<Theme> = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: 'wrap'
}
export const withIconSx: SxProps<Theme> = {
  flexDirection: "row",
  alignItems: "center",
  gap: 0.5
}
export const menuSx: SxProps<Theme> = {
  position: "absolute",
  top: theme.spacing(16/8),
  right: theme.spacing(16/8)
}
