import { alpha, SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const detailsWrapperSx: SxProps<Theme> = {
  borderRadius: theme.spacing(8/8),
  background: theme.palette.background.default,
  marginTop: theme.spacing(20/8),
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    marginTop: 0
  }
}
export const detailsInnerSx: SxProps<Theme> = {
  padding: theme.spacing(24/8),
}
export const detailsTitleSx: SxProps<Theme> ={
  display: 'flex',
  justifyContent: 'flex-end',
  pb: theme.spacing(8/8)
}
export const detailItemSx: SxProps<Theme> = {
  '&.MuiGrid-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(8/8)
  }
}
export const imageWrapperSx: SxProps<Theme> = {
  maxWidth: theme.spacing(215/8),
  maxHeight: theme.spacing(220/8),
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    m: '0 auto'
  },
}
export const imageInnerSx: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  position: 'relative',
  maxWidth: '300px',
  '& img':{
    objectFit: 'contain',
    position: 'absolute',
    inset: 0,
    zIndex: 0,
    width: '100%',
    height: '100%'
  }
}
export const buttonImageWrapperSx: SxProps<Theme> = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  padding: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  background: alpha(theme.palette.bg.dark, 0.2),
  transition: '0.3s ease',
  zIndex: 1,
  '&:hover': {
    opacity: 1
  }
}
export const editCostBtnSx: SxProps<Theme> = {
  width: theme.spacing(20/8),
  height: theme.spacing(20/8)
}
export const editCostIconSx: SxProps<Theme> = {
  width: theme.spacing(16/8),
  height: theme.spacing(16/8)
}
export const costBoxSx: SxProps<Theme> = {
  display: "flex",
  gap: 1,
  alignItems: "center"
}
export const imageButtonSx: SxProps<Theme> = {
  '&:hover': {
    background: theme.palette.neutral[400]
  }
}

export const deleteButtonSx: SxProps<Theme> = {
  width: 24,
  height: 24,
  ml: 1,
  '&:hover': {
    background: theme.palette.neutral[200]
  },
  '& svg': {
    width: 16
  }
}

export const editButtonSx: SxProps<Theme> = {
  background: 'transparent'
}

export const modalBodySx: SxProps<Theme> = {
  px: 2,
  py:3,
  pt: 6,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '& button': {
    width: '30%',
    mt: 5
  },
  '& .MuiTypography-root': {
    fontSize: 16
  }
}

export const modalFooterSx: SxProps<Theme> = {
  pt: 2,
  display: 'flex',
  justifyContent: 'end'
}

export const userBoxSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center'
}