import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const statWrapperSx: SxProps<Theme> = {
  padding: theme.spacing(25/8),
  background: theme.palette.background.default,
  display: 'flex'
}

export const chartWrapperSx: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  alignItems: 'center'
}

