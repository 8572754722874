import {
  FC, Fragment,
} from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomFormLabel } from '../CustomFormLabel';
import { FormHelper } from '../FormHelper';
import { placeholderWrapperSx, autocompleteSx, descriptionSx, optionStack } from './AMPAutocomplete.sx';
import { AMPAutocompleteProps } from './AMPAutocomplete.types';
import { Waypoint } from "react-waypoint";

export const AMPAutocomplete: FC<AMPAutocompleteProps> = ({
  input,
  hasError,
  description,
  helperText,
  options,
  label,
  onInputChange,
  onChange,
  onBlur,
  showAvatar,
  value,
  disabled,
  handleScroll,
  ...rest
}) => {
  const intl = useIntl();
  
  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={!!hasError}
      margin="normal"
      id="scroll-box"
    >
      {label && (
        <CustomFormLabel
          label={intl.formatMessage({ id: label })}
          required={rest.required}
        />
      )}
      <Box sx={placeholderWrapperSx}>
        <Autocomplete
          sx={autocompleteSx}
          id={input}
          options={options}
          selectOnFocus={false}
          disabled={disabled}
          value={value ?? null}
          popupIcon={<KeyboardArrowDown />}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, currentValue) => option.id === currentValue.id}
          onChange={onChange}
          onBlur={onBlur}
          onInputChange={onInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(hasError)}
              fullWidth
              margin="normal"
              color="secondary"
              variant="outlined"
              placeholder={intl.formatMessage({ id: 'select' })}
            />
          )} 
          noOptionsText={(
            <Typography
              variant="body1"
              color="textPrimary"
            >
              <FormattedMessage id="noData" />
            </Typography>
          )}
          renderOption={(props, option) => {
            const index = (props as Record<string, unknown>)?.['data-option-index'];
            const infinityScrollApplicable = 'number' === typeof index
              && index === options.length - 1
              && handleScroll;
  
            return typeof option !== 'string' && (
              <Fragment key={option.id}>
                {infinityScrollApplicable && (
                  <Waypoint
                    onEnter={() => {
                      handleScroll()
                    }}
                  />
                )}
                <li
                  {...props}
                >
                  <Stack sx={optionStack}>
                    {showAvatar &&
                      <Avatar
                        src={option.image}
                        alt="avatar"
                      />
                    }
                    {option.label}
                  </Stack>
                </li>
              </Fragment>
            )
          }}
        />
      </Box>
      {description && (
        <Typography sx={descriptionSx}>
          {intl.formatMessage({ id: description })}
        </Typography>
      )}
      {helperText && (
        <FormHelper helperText={helperText} />
      )}
    </FormControl>
  );
};
