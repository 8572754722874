import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const imageWrapperSx: SxProps<Theme> = {
  width: theme.spacing(32/8),
  height: theme.spacing(32/8),
  overflow: 'hidden',
  borderRadius: '50%',
  mr: 1,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}

export const linkButtonSx: SxProps<Theme> = {
  padding: theme.spacing(5/8, 0),
  display: 'flex',
  gap: theme.spacing(5/8),
  alignItems: 'center'
}
