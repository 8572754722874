import { FC } from "react";
import { Box, OutlinedInput, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { TableRowCounterType } from "./TableRowCounter.types";
import { counterWrapperSx, inputSx } from "./TableRowCounter.sx";

export const TableRowCounter: FC<TableRowCounterType> = ({handleChangeRowsNumber, defaultValue}) => {
  return (
    <Box sx={counterWrapperSx}>
      <OutlinedInput
        sx={inputSx}
        defaultValue={defaultValue}
        type='number'
        onChange={handleChangeRowsNumber}
      />
      <Typography variant="caption">
        <FormattedMessage id="entriesPerPage" />
      </Typography>
    </Box>
  )
}
