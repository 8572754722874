export enum RegistrationStatusEnum {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
  PENDING_ADDRESS = "PENDING_ADDRESS",
  PENDING_HASH = "PENDING_HASH"
}

export enum UsersSortFieldEnum {
  EMAIL = "EMAIL",
  ROLE  = "ROLE",
  CREATED = "CREATED_AT"
}
