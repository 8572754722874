import { FC, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { DownloadIcon } from "../../icons/DownloadIcon";
import { DownloadButtonProps } from "./DownloadButton.types";
import { downloadButtonSx } from "./DownloadButton.sx";
import AMPModal from "../../common/AMPModal";
import { GenerateReportForm } from "../../forms/GenerateReportForm";
import { useAppSelector } from "../../../app/hooks";

export const DownloadButton:FC<DownloadButtonProps> = ({ isActive }) => {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  const { authInfo } = useAppSelector((state) => state.common)
  
  const handleShowModal = (status: boolean) => () => {
    setShowModal(status)
  }
  
  return (
    <Box sx={downloadButtonSx.root}>
      <Button
        onClick={handleShowModal(!showModal)}
        startIcon={<DownloadIcon/>}
        sx={downloadButtonSx.button}
      >
        <Typography
          variant='body1'
          sx={downloadButtonSx.btnText}
        >
          {intl.formatMessage({ id: 'export' })}
        </Typography>
      </Button>
      <AMPModal
        handleClose={handleShowModal(false)}
        open={showModal}
        title={intl.formatMessage({id: 'generateReport'})}
        type="md"
        fullWidth
        withoutPaddings
      >
        <GenerateReportForm
          initData={{
            user: 0,
            location:  0,
            businessId: authInfo?.business?.id ?? 0
          }}
          isActive={isActive}
          closeModal={handleShowModal(false)}
          handleSubmit={console.log}
          businessId={authInfo?.business?.id ?? 0}
        />
      </AMPModal>
    </Box>
  )
}
