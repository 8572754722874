import { useEffect } from "react";
import { usersApi } from "../api";
import { AppDispatch } from "../app/store";
import { preloaderChangeStatus } from "../stores/common/commonSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { LanguageEnum } from "../enums";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type GetUserQueryTrigger = ReturnType<
  typeof usersApi.useLazyGetUserByIdQuery
  >[0];

const handleGetUserData = async (dispatch: AppDispatch, getUserInfo: GetUserQueryTrigger, userId: number, businessId: number) => {
  try {
    dispatch(preloaderChangeStatus(true));
    await getUserInfo({
      userId,
      businessId
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
}

export const useGetUserLanguage = () => {
  const [getUserInfo, userInfo] = usersApi.useLazyGetUserByIdQuery()
  const { authInfo } = useAppSelector(state => state.common)
  const dispatch = useAppDispatch()

  useEffect(() => {
    handleGetUserData(
      dispatch,
      getUserInfo,
      authInfo?.id ?? 0,
      authInfo?.business?.id ?? 0
    )
  }, [])
  
  return [userInfo?.data?.language ?? authInfo?.language ?? LanguageEnum.ENGLISH]
}
