import { SxProps } from "@mui/material";
import theme from "src/theme";

export const radioGroupSx: SxProps = {
  display: 'flex',
  maxWidth: theme.spacing(200/8),
  flexDirection: 'row',
  flexWrap: 'nowrap',
  '& .MuiFormControlLabel-root': {
    margin: 0,
    '&:first-of-type .MuiTypography-root': {
      borderRadius: "4px 0 0 4px"
    },
    '&:last-of-type .MuiTypography-root': {
      borderRadius: " 0 4px 4px 0"
    }
  },
  '& .MuiRadio-root': {
    display: 'none'
  },
  '& .MuiTypography-root': {
    minWidth: theme.spacing(100/8),
    padding: theme.spacing(12/8, 20/8),
    borderWidth: theme.spacing(1/8),
    borderStyle: 'solid',
    borderColor: theme.palette.neutral[200],
    color: theme.palette.neutral[700],
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center'
  },
  '& .Mui-checked + .MuiTypography-root': {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primaryColor[100],
    background: theme.palette.primaryColor.A50
  }
}
