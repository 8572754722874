import ReactPDF from "@react-pdf/renderer";

const { StyleSheet } = ReactPDF

export const pdfDocumentSx = StyleSheet.create({
  table: {
    width: '100%',
    padding: 10
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 10
  },
  header: {
    borderTop: 'none',
  },
  bold: {
    fontWeight: 'bold',
  },
  row1: {
    width: '15%',
    fontSize: 10,
    padding: "0 10px"
  },
})
