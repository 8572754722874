import theme from "../../../theme";
import { alpha, SxProps, Theme } from "@mui/material";

export const downloadButtonSx = {
  root: {
    position: "relative"
  },
  button: {
    height: theme.spacing(30/8),
    backgroundColor: theme.palette.neutral[100],
    color: theme.palette.neutral[600],
    border: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`
  },
  btnText: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  }
}

export const menuSx = (show: boolean): SxProps<Theme> => ({
  position: "absolute",
  zIndex: 100,
  top: theme.spacing(40/8),
  right: 0,
  visibility: show ? "visible": "hidden",
  backgroundColor: theme.palette.neutral[100],
  borderRadius: theme.spacing(4/8),
  boxShadow: `0
  ${theme.spacing(4/8)}
  ${theme.spacing(12/8)}
  ${theme.spacing(-4/8)}
  ${alpha( theme.palette.common.black, 0.12)},
  0
  ${theme.spacing(16/8)}
  ${theme.spacing(32/8)}
  ${alpha( theme.palette.common.black, 0.12)}`,
})
