import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const headerWrapperSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}

export const headerActionsSx: SxProps<Theme> = {
  maxWidth: theme.spacing(200/8),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(10/8),
  marginLeft: 'auto'
}

export const textSx = {
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.85rem',
    lineHeight: 1.3
  }
}

export const breadcrumbsSx: SxProps<Theme> = {
  '& a':{
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(5/8),
    color: theme.palette.neutral[700],
    fontWeight: 400,
    '& svg': {
      fill: theme.palette.primary.main
    }
  }
}

export const breadcrumbsWrapperSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(10/8),
  [theme.breakpoints.down('md')]:{
    display: 'none'
  }
}

export const separateIconSx: SxProps<Theme> = {
  fill: theme.palette.neutral[500],
  width: theme.spacing(20/8)
}

export const currentPageSx = {
  color: theme.palette.neutral[500],
  fontWeight: 400,
}

export const buttonWrapperSx: SxProps<Theme> = {
  '& button': {
    marginLeft: 0
  },
  [theme.breakpoints.up('md')]:{
    display: 'none'
  }
} 