import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const wrapperSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(100/8),
  padding: theme.spacing(40/8),
  position: 'relative',
  [theme.breakpoints.down('md')]:{
    padding: theme.spacing(40/8, 10/8),
  }
}

export const backButtonSx: SxProps<Theme> = {
  position: 'absolute',
  left: theme.spacing(20/8),
  top: theme.spacing(20/8),
  background: 'transparent',
  '&:hover': {
    background: 'transparent',
  },
  '& .MuiSvgIcon-root':{
    fill: theme.palette.primary.main,
  }
}

export const innerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(8/8),
  boxShadow: '0px 4px 12px -4px rgba(33, 33, 52, 0.12), 0px 16px 32px rgba(33, 33, 52, 0.12)',
  background: theme.palette.background.default,
  padding: theme.spacing(40/8),
  [theme.breakpoints.down('md')]:{
    padding: 0,
    boxShadow: 'none'
  }
}

export const welcomeContentSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
export const modalButtonSx: SxProps<Theme> = {
  '&.MuiIconButton-root': {
    position: 'fixed',
  },
  bottom: '5%',
  right: '5%'
}
