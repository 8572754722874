import { FC, useState, MouseEvent, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FilterList, Search } from "@mui/icons-material";
import { GridColumns } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
  OutlinedInput,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { Table } from "../../tables/Table";
import { TableRowCounter } from "../../tables/TableRowCounter";
import { TablePagination } from "../../tables/TablePagination";
import { TableFilter } from "../TableFilter";
import ability from "../../../permissions/ability";
import { DownloadButton } from "../DownloadButton";
import { ChargingReportTableProps } from "./ChargingReportTable.types";
import {
  actionsWrapperSx,
  filterButtonSx,
  filterIconActiveSx,
  filterWrapperSx,
  formWrapperSx,
  searchInputSx,
  tableFooterSx,
  colTitleSx,
  btnText
} from "./ChargingReportTable.sx";
import { chargingReportTableColumns } from "./ChargingReportTable.tableConfig";
import { ChargingSession } from "../../../types/reports";
import { ChargingReportTableCard } from "./ChargingReportTableCard";
import { EmptyTable } from "../../tables/EmptyTable";
import { useAppSelector } from "../../../app/hooks";
import { RoleEnum } from "../../../enums";
import DashboardLayoutContext from "../../../layouts/DashboardLayout/DashboardLayout.context";

export const ChargingReportTable:FC<ChargingReportTableProps> = ({
  data,
  isActive,
  handleChangeRowsNumber,
  handleChangePage,
  page,
  totalPages,
  handleNameChange,
  searchValue,
  perPage,
  handleFilter,
  initFilter,
  clearFilter
}) => {
  const intl = useIntl()
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false)
  const { authInfo } = useAppSelector((state) => state.common)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
 
  const open = Boolean(anchorEl);
  const responsive = useContext(DashboardLayoutContext)
  const tableData: Array<ChargingSession & {id: number}> = data
    ? data.map(item => ({
      ...item,
      id: item?.transactionId
    }))
    : []
  
  const businessArr: GridColumns = []
  if (ability.can('read', 'businessReports')) {
    businessArr.push({
      field: intl.formatMessage({id: 'business'}),
      renderHeader: () => <Box sx={colTitleSx}>
        <FormattedMessage id={"business"} />
      </Box>,
      flex: 0,
      minWidth: 50,
      valueGetter: (params) => params?.row?.business?.name,
      renderCell: params => params?.row?.business?.name
    })
  }
  
  const filterStatus = (value: boolean) => {
    setIsFilterActive(value)
  }
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <>
      <Box sx={actionsWrapperSx}>
        <OutlinedInput
          value={searchValue}
          sx={searchInputSx}
          color="secondary"
          type="text"
          placeholder={intl.formatMessage({ id: 'searchByName' })}
          name="search"
          onChange={handleNameChange()}
          startAdornment={(<Search />)}
        />
        <Stack sx={filterWrapperSx}>
          <IconButton
            sx={filterButtonSx}
            onClick={handleClick}
          >
            {!isFilterActive && <FilterList /> }
            {isFilterActive && <FilterList sx={filterIconActiveSx} />}
            <Typography
              variant='body1'
              sx={btnText}
            >
              <FormattedMessage id='filter' />
            </Typography>
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={formWrapperSx}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <TableFilter
              businessId={authInfo?.business?.id}
              handleFilter={handleFilter}
              closePopup={handleClose}
              initData={initFilter}
              clearFilter={clearFilter}
              filterStatus={filterStatus}
            />
          </Popover>
          <DownloadButton isActive={isActive} />
        </Stack>
      </Box>
      {
        responsive
          ?
          <>
            {tableData?.map(item => <ChargingReportTableCard
              key={item.id}
              data={item}
              isSuperAdmin={authInfo?.roles?.[0]?.role === RoleEnum.SUPER_ADMIN}
            />)}
            {!tableData?.length && <EmptyTable/>}
          </>
          :
          <Table
            rows={tableData}
            hideFooter
            getRowId={(row) => row?.id}
            columns={chargingReportTableColumns(businessArr, intl)}
          />
      }
      <Box sx={tableFooterSx}>
        <TableRowCounter
          handleChangeRowsNumber={handleChangeRowsNumber}
          defaultValue={perPage}
        />
        <TablePagination
          totalPages={totalPages}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Box>
    </>
  )
}
