import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const wrapperSx: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  "img": {
    width: "100%",
    maxWidth: theme.spacing(600/8)
  }
}
