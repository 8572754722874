import { FC } from "react";
import { chargersApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { ScheduleFormType } from "src/components/forms/formData/schedule";
import { ScheduleForm } from "src/components/forms/ScheduleForm";
import { ScheduleTypeEnum } from "src/enums";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { ChargerScheduleCreateWrapperType } from "./ChargerScheduleCreateWrapper.types";

type AddScheduleMutationTrigger = ReturnType<
  typeof chargersApi.useAddScheduleMutation
>[0];

const handleSubmit = (trigger: AddScheduleMutationTrigger, dispatch: AppDispatch, businessId: number, chargerId: number, handleClose: () => void) => async (values: ScheduleFormType) => {
  const data = {
    ...values,
    businessId,
    type: values.type ? ScheduleTypeEnum.CYCLE : ScheduleTypeEnum.ONE_TIME,
    timezone: ((new Date()).getTimezoneOffset() / 60 * -1)
  }
  dispatch(preloaderChangeStatus(true))
  try{
    await trigger({ businessId, chargerId, data }).unwrap()
  } catch(error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    handleClose()
    dispatch(preloaderChangeStatus(false))
  }
}
export const ChargerScheduleCreateWrapper: FC<ChargerScheduleCreateWrapperType> = ({ businessId, handleClose, chargerId }) => {

  const [trigger] = chargersApi.useAddScheduleMutation()
  const dispatch = useAppDispatch()
 
  return (
    <ScheduleForm 
      handleClose={handleClose}
      handleSubmit={handleSubmit(trigger, dispatch, businessId, chargerId, handleClose)}
    />
  )
}
