import { SxProps, Theme } from "@mui/material"
import theme from "src/theme"

export const wrapperSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
}
export const welcomeBlockSx: SxProps<Theme> = {
  marginBottom: theme.spacing(90/8),
  [theme.breakpoints.down('md')]:{
    marginBottom: theme.spacing(50/8),
  }
}
export const resetPasswordDescriptionSx: SxProps<Theme> = {
  marginTop: theme.spacing(20/8),
  padding: theme.spacing(16/8),
  background: theme.palette.primaryColor[100],
  borderRadius: 4,
  lineHeight: "1.2"
}

export const langSelectWrapperSx: SxProps<Theme> = {
  [theme.breakpoints.down('md')]:{
    display: 'none'
  }
}

