import { FC } from "react";
import { Download } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { formatFileName, isErrorObject } from "src/utils/helper";
import { invoicesApi } from "../../../../api";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { AppDispatch } from "../../../../app/store";
import { preloaderChangeStatus } from "../../../../stores/common/commonSlice";
import { RoleEnum } from "../../../../enums";
import { DownloadInvoicePdfTypes } from "./DownloadInvoicePdf.types";
import theme from "../../../../theme";
import { NotifyError } from "src/utils/notification";

type DownloadInvoicePdfType = ReturnType<typeof invoicesApi.useLazyGetInvoicePdfQuery>[0];
type DownloadRevenueInvoicePdfType = ReturnType<typeof invoicesApi.useLazyRevenueInvoicePdfQuery>[0];

const handleDownloadFile = (
  trigger: DownloadInvoicePdfType,
  dispatch: AppDispatch,
  businessId: number,
  invoiceId: number,
  name: string,
  userId?: number
) => async () => {
  try {
    dispatch(preloaderChangeStatus(true))
    const params = {
      invoiceId,
      businessId,
      userId
    }
    const response = await trigger(
      params
    )
    const tempLink = document.createElement('a');
    tempLink.href = response?.data ?? '';
    tempLink.setAttribute('download', `${formatFileName(name)}`);
    tempLink.click();
    window.URL.revokeObjectURL(tempLink.href)
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
}

const handleDownloadRevenueFile = (
  trigger: DownloadRevenueInvoicePdfType,
  dispatch: AppDispatch,
  businessId: number,
  invoiceId: number,
  name: string,
) => async () => {
  try {
    dispatch(preloaderChangeStatus(true))
    const params = {
      invoiceId,
      businessId
    }
    const response = await trigger(
      params
    )
    const tempLink = document.createElement('a');
    tempLink.href = response?.data ?? '';
    tempLink.setAttribute('download', `${formatFileName(name)}`);
    tempLink.click();
    window.URL.revokeObjectURL(tempLink.href)
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
}

export const DownloadInvoicePdf:FC<DownloadInvoicePdfTypes> = ({
  invoiceId,
  name,
  businessId,
  isIcon
}) => {
  const intl = useIntl()
  const [getInvoicePdf] = invoicesApi.useLazyGetInvoicePdfQuery()
  const [getRevenueInvoicePdf] = invoicesApi.useLazyRevenueInvoicePdfQuery()
  const dispatch = useAppDispatch()
  const { authInfo } = useAppSelector((state) => state.common)
  const roleIsDriver = authInfo?.roles?.[0].role === RoleEnum.DRIVER
  const roleIsSuperAdmin = authInfo?.roles?.[0].role === RoleEnum.SUPER_ADMIN
  const userId = roleIsDriver ? authInfo?.id : undefined
  
  const handleClick = () => {
    roleIsSuperAdmin
    && businessId
    && handleDownloadRevenueFile(
      getRevenueInvoicePdf,
      dispatch,
      businessId,
      invoiceId,
      name
    )()
    !roleIsSuperAdmin
    && handleDownloadFile(
      getInvoicePdf,
      dispatch,
      authInfo?.business?.id,
      invoiceId,
      name,
      userId
    )()
  }
  
  return (
    <>
      {isIcon &&
        <IconButton onClick={handleClick}>
          <Download />
        </IconButton>
      }
      {!isIcon &&
        <Button
          fullWidth
          onClick={handleClick}
        >
          <Typography sx={{ width: "100%",
            textAlign: "start",
            fontSize: theme.spacing(14/8),
            fontWeight: 400,
            color: theme.palette.neutral[400],}}>
            {intl.formatMessage({id: 'download'})}
          </Typography>
        </Button>
      }
    </>
  )
}
