import theme from "../../../../theme";

export const usersChartSx = {
  root: {
    backgroundColor: theme.palette.common.white,
    border: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.spacing(4/8),
    px: 3,
    py: 2,
    overflow: 'hidden'
  },
  top: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down('lg')]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      mb: 3
    }
  },
  select: {
    width: theme.spacing(160/8),
    [theme.breakpoints.down('lg')]: {
      width: "100%"
    }
  }
}
