import { alpha, SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const actionsWrapperSx: SxProps<Theme> = {
  marginBottom: theme.spacing(15/8),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}
export const searchInputSx: SxProps<Theme> = {
  maxWidth: theme.spacing(250/8),
  width: '100%',
  background: theme.palette.background.default,
  '&.MuiOutlinedInput-root': {
    paddingLeft: theme.spacing(8/8),
  },
  '& svg': {
    fill: theme.palette.neutral[400]
  },
  '& .MuiInputBase-input': {
    fontSize: theme.spacing(12/8),
    padding: '8px',
    paddingLeft: 0,
    '&:placeholder': {
      color: theme.palette.neutral[400]
    }
  }
}
export const btnText: SxProps<Theme> = {
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}
export const filterWrapperSx: SxProps<Theme> = {
  position: 'relative',
  flexDirection: "row",
  alignItems: "cneter",
  gap: 1,
  pl: 1,
}
export const filterButtonSx: SxProps<Theme> = {
  height: theme.spacing(30/8),
  '&.MuiIconButton-root': {
    background: theme.palette.background.default,
    border: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.spacing(4/8)
  }
}
export const formWrapperSx: SxProps<Theme> = {
  '& .MuiPaper-root': {
    maxWidth: theme.spacing(300/8),
    width: '100%',
  }
}
export const filterIconActiveSx: SxProps<Theme> = {
  '&.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium': {
    fill: theme.palette.primary.main
  }
}

export const tableCellSx: SxProps<Theme> = {
  textAlign: 'center',
  width: "100%"
}

export const tableFooterSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(10/8, 0),
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
  }
}

export const colTitleSx:SxProps = {
  width: "100%",
  textAlign: "center",
  textTransform: "uppercase"
}

export const chargingSessionsSx = {
  status: {
    px: 1,
    py: 0.5,
    fontWeight: 500,
    fontSize: '0.625em',
    borderRadius: theme.spacing(4/8),
  },
  statusActive: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primaryColor[50]
  },
  statusCompleted: {
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.1)
  }
}
