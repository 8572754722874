import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const wrapperSx: SxProps<Theme> = {
  padding: theme.spacing(16/8, 20/8),
  borderWidth: theme.spacing(1/8),
  borderStyle: 'solid',
  borderColor: theme.palette.neutral[100],
  borderRadius: theme.spacing(8/8),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(5/8),
  flexWrap: 'wrap-reverse'
}

export const timeWrapperSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(5/8)
}

export const arrowSx: SxProps<Theme> = {
  fill: theme.palette.neutral[300],
  width: theme.spacing(22/8)
}

export const typographySx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  width: 'max-content'
}

export const actionsWrapperSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(10/8),
  width: '100%'
}

export const switchSx: SxProps<Theme> = {
  width: theme.spacing(36/8),
  height: theme.spacing(20/8),
  padding: theme.spacing(0/8),
  '& .MuiSwitch-switchBase': {
    padding: theme.spacing(2/8),
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.brand.main,
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: theme.spacing(16/8),
    height: theme.spacing(16/8),
  },
  '& .MuiSwitch-track': {
    borderRadius: theme.spacing(13/8),
    backgroundColor: theme.palette.neutral[200],
    opacity: theme.spacing(1/8),
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}

export const editButtonSx: SxProps<Theme> = {
  background: 'transparent',
}
