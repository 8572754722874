import {
  FC,
  useState,
  MouseEvent,
  useEffect,
  useMemo
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  OutlinedInput,
  FormControl,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ErrorOutline } from '@mui/icons-material';
import { FormHelper } from '../FormHelper';
import { CustomFormLabel } from '../CustomFormLabel';
import { PasswordInputProps } from './AMPInput.types';
import { placeholderSx, placeholderWrapperSx, descriptionSx } from './AMPInput.sx';

export const AMPInput: FC<PasswordInputProps>  = ({
  input,
  hasError,
  helperText,
  label,
  type,
  placeholder,
  labelCaption,
  description,
  endAdornment,
  ...rest
}) => {
  const intl = useIntl();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(false);

  useEffect(() => {
    (hasError && (type === 'password' || type === 'confirmPassword')) &&
    setShowPassword(true);
  }, [hasError, type])

  useEffect(() => {
    if(rest.value === 0 || rest.value){
      setShowPlaceholder(false)
    } else {
      setShowPlaceholder(true)
    }
  }, [rest.value])

  const getPasswordInputType = () => (
    showPassword ? 'text' : 'password'
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const caption = useMemo(() => {
    if(!labelCaption){
      return ''
    } 
    return intl.formatMessage({id: labelCaption})
  }, [labelCaption])
  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={!!hasError}
      margin="normal"
    >
      {label && !!label?.length && <CustomFormLabel
        label={intl.formatMessage({ id: label }, {caption})}
        required={rest.required}
      />}
      <Box sx={placeholderWrapperSx}>
        {showPlaceholder && (
          <Typography
            sx={placeholderSx}
            variant="h5"
          >
            <FormattedMessage id={placeholder || input} />
          </Typography>
        )}
        <OutlinedInput
          color="secondary"
          type={(type === 'password' || type === 'confirmPassword') ? getPasswordInputType() : type}
          id={input}
          name={input}
          endAdornment={
            <>
              {!hasError && endAdornment}
              {
                !hasError && (type === 'password' || type === 'confirmPassword') &&
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {!showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
              }
              {hasError && <InputAdornment position="end">
                <IconButton
                  aria-label="error"
                  edge="end"
                  color='error'
                  disableTouchRipple
                  disableFocusRipple
                  sx={{
                    background: 'transparent',
                    cursor: 'default',
                    '&:hover':{
                      background: 'transparent'
                    }
                  }}
                >
                  <ErrorOutline />
                </IconButton>
              </InputAdornment>}
            </>
          }
          {...rest}
        />
      </Box>
      {description && (
        <Typography sx={descriptionSx}>
          {intl.formatMessage({ id: description })}
        </Typography>
      )}
      {helperText && (
        <FormHelper
          helperText={helperText}
        />
      )}
    </FormControl>
  );
};

