import { Box } from "@mui/material";
import { FC } from "react";
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from "recharts";
import theme from "src/theme";
import { CustomChartType } from "./CustomChart.types";

export const CustomChart: FC<CustomChartType> = ({ data, color }) => {
  return (
    <Box sx={{height: '50px', width: '100%'}}>
      <ResponsiveContainer width="100%"
        height="100%">
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <Line type="monotone"
            dot={false}
            dataKey="count"
            stroke={color}
            strokeWidth={3} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  )
}
