import { FC, SyntheticEvent, useEffect, useState } from "react"
import { Box, Grid, Typography } from "@mui/material"
import dayjs from "dayjs";
import { Form, Formik, FormikProps } from "formik"
import { FormattedMessage, useIntl } from "react-intl"
import { AMPInput } from "src/components/common/AMPInput"
import { getHelperText } from "src/utils/forms"
import { SubmitButton } from "src/components/common/SubmitButton"
import { AMPFormSelect } from "src/components/common/AMPFormSelect"
import { ContractStatusManualEnum, RoleEnum } from "src/enums"
import { CancelButton } from "src/components/common/CancelButton"
import { AMPDatePicker } from "../../common/AMPDatePicker";
import { ContractManualFormType, contractManualSchema } from "../formData/createManualContract";
import { AMPAutocomplete } from "../../common/AMPAutocomplete";
import { AutocompleteValue, BaseUserInfo, BusinessDTO } from "../../../types";
import { CreateContractGeneralFormProps } from "./CreateContractGeneralForm.types";
import { formSx, actionsWrapperSx } from "./CreateContractGeneralForm.sx"
import { findAutocompleteValueById, handleScroll } from "src/utils/helper";
import { TableFilterFormTypes } from "src/components/reports/TableFilter/TableFilter.types";
import { AMPAutocompleteProps } from "src/components/common/AMPAutocomplete/AMPAutocomplete.types";
import { businessesApi, usersApi } from "../../../api";
import { getBusinessesData, getClientsData } from "../../../pages/Contracts";
import { useAppSelector } from "../../../app/hooks";

export const CreateContractGeneralForm: FC<CreateContractGeneralFormProps> = ({
  handleClose,
  handleSubmit,
  initialValues,
  businessId
}) => {
  const intl = useIntl()
  const { authInfo } = useAppSelector((state) => state.common);
  const isISuperAdmin = authInfo.roles[0].role === RoleEnum.SUPER_ADMIN
  const pageSizeBusiness = 10
  const pageSizeClient = 10
  const [pageBusiness, setPageBusiness] = useState(0)
  const [pageClients, setPageClients] = useState(0)
  const [businessData, setBusinessData] = useState<Array<BusinessDTO>>([])
  const [clientsData, setClientsData] = useState<Array<BaseUserInfo>>([])
  const [keyClient, setKeyClient] = useState('')
  const [getClients, clients] = usersApi.useLazyGetUsersQuery()
  const [getBusinesses, businesses] = businessesApi.useLazyGetBusinessesQuery()
  
  useEffect( () => {
    getClientsData(
      getClients,
      businessId,
      pageClients,
      pageSizeClient
    )
  }, [businessId])
  
  useEffect(() => {
    isISuperAdmin && getBusinessesData(getBusinesses, pageSizeBusiness, pageBusiness)
  }, []);
  
  const processedClients = ():AutocompleteValue[] => {
    return Object.values((clientsData).map((item) => ({
      id: item?.id,
      label: `${item?.firstName} ${item?.lastName}`,
      image: item?.avatar?.url ?? "",
    })))
  }
  
  const processedContractStatus = () => {
    return Object.values(ContractStatusManualEnum).map((item, index) => ({
      id: index,
      name: intl.formatMessage({ id: item }),
      value: item
    }))
  }
  
  const processedBusinesses = ():AutocompleteValue[] => {
    return businessData.map(item => ({
      id: item?.id,
      label: item?.name,
      image: item?.logo?.url ?? ""
    }))
    return []
  }
  
  useEffect(() => {
    const content = businesses?.data?.content ?? []
    const allData = [...businessData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setBusinessData(Array.from(Object.values(allData)))
  }, [businesses?.data?.content])
  
  useEffect(() => {
    const content = clients?.data?.content ?? []
    const allData = [...clientsData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setClientsData(Array.from(Object.values(allData)))
  }, [clients?.data?.content])
  
  useEffect(() => {
    getBusinessesData(getBusinesses, pageSizeBusiness, pageBusiness)
  }, [pageBusiness])
  
  useEffect(() => {
    getClientsData(
      getClients,
      authInfo?.business?.id,
      pageClients,
      pageSizeClient,
      keyClient
    )
  }, [pageClients])

  const handleChangeClientValue = (setFieldValue: FormikProps<TableFilterFormTypes>['setFieldValue'], fieldName: string): AMPAutocompleteProps['onChange'] =>
    (e, newValue) => {
      if(newValue){
        setFieldValue(fieldName, newValue.id)
      }
    }
  
  const handleScrollClients = () => {
    handleScroll(
      clients?.data?.number ?? 0,
      clients?.data?.totalPages ?? 0,
      pageClients,
      setPageClients
    )
  }
  
  const handleInputClients = () =>
    (event: SyntheticEvent, value: string) => {
      setPageClients(0)
      setKeyClient(value)
      getClientsData(
        getClients,
        authInfo?.business?.id,
        pageClients,
        pageSizeClient,
        value
      )
    }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contractManualSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }: FormikProps<ContractManualFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box
            sx={formSx}
          >
            <Box>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="clientInfo" />
              </Typography>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{xs: 0, md: 2}}
                sx={{
                  flexDirection:"row"
                }}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPAutocomplete
                    handleScroll={handleScrollClients}
                    onInputChange={handleInputClients()}
                    options={processedClients()}
                    value={findAutocompleteValueById(processedClients(), +values['userId'])}
                    label={'client'}
                    input={'userId'}
                    showAvatar
                    onChange={handleChangeClientValue(setFieldValue, 'userId')}
                    onBlur={handleBlur}
                    description='selectDriverDescription'
                    hasError={touched['userId'] && Boolean(errors['userId'])}
                    helperText={getHelperText('userId', touched, errors, intl)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="contractInfo" />
              </Typography>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{xs: 0, md: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    value={values['contractId']}
                    type={'text'}
                    label={'contractId'}
                    input={'contractId'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['contractId'] && Boolean(errors['contractId'])}
                    helperText={getHelperText('contractId', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPFormSelect
                    data={processedContractStatus() ?? []}
                    value={values['contractStatus']}
                    label={'contractStatus'}
                    input={'contractStatus'}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{xs: 0, md: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPDatePicker
                    label="contractCreationDate"
                    value={dayjs(values["contractCreatedAt"]).valueOf()}
                    onChange={(val) => setFieldValue("contractCreatedAt", dayjs(dayjs(val).format('YYYY-MM-DD')).valueOf())}
                  />
                </Grid>
                {values['contractStatus'] === ContractStatusManualEnum.OPERATION &&
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPDatePicker
                    label="contractOperationDate"
                    value={dayjs(values["operationalAt"]).valueOf()}
                    onChange={(val) => setFieldValue("operationalAt", dayjs(dayjs(val).format('YYYY-MM-DD')).valueOf())}
                  />
                </Grid>
                }
              </Grid>
            </Box>
          </Box>
          <Box sx={actionsWrapperSx}>
            <CancelButton
              text="cancel"
              handler={handleClose}
            />
            <SubmitButton
              text="next"
              color="primary"
              isSubmitting={isSubmitting}
            />
          </Box>
        </Form>
      )
      }
    </Formik>
  );
}
