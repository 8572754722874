import { FC } from "react";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import { convertMinutes } from "../../../../utils/helper";
import { StatusTag } from "../StatusTag";
import { ChargingReportTableCardProps } from "./ChargingReportTableCard.types";
import { TableCard } from "../../../tables/respnsiveTable/TableCard";
import { TableRow } from "../../../tables/respnsiveTable/TableRow";

export const ChargingReportTableCard:FC<ChargingReportTableCardProps> = ({data, isSuperAdmin}) => {
  const intl = useIntl();
  const {
    active,
    location,
    user,
    startTimestamp,
    stopTimestamp,
    chargingTimeMinutes,
    wattsConsumed,
    business,
    idTag
  } = data
  return (
    <TableCard>
      {isSuperAdmin &&
        <TableRow
          titleId='business'
          value={business?.name}
        />
      }
      <TableRow
        titleId='status'
        customValue={<StatusTag isActive={active}/>}
      />
      <TableRow
        titleId='locationNameColumnHeader'
        value={location?.name}
      />
      <TableRow
        titleId='evDriverName'
        value={`${user?.firstName} ${user?.lastName}`}
      />
      <TableRow
        titleId='rfid'
        value={`${idTag?.idTag}`}
      />
      <TableRow
        titleId='transactionStart'
        value={dayjs(startTimestamp).format('DD MMM YYYY HH:mm:ss')}
      />
      <TableRow
        titleId='transactionEnd'
        value={stopTimestamp ? dayjs(stopTimestamp).format('DD MMM YYYY HH:mm:ss') : '-'}
      />
      <TableRow
        titleId='chargingTime'
        value={convertMinutes(chargingTimeMinutes)}
      />
      <TableRow
        titleId='powerConsumed'
        value={`${(wattsConsumed / 1000).toFixed(2)} ${intl.formatMessage({ id: 'kWh' })}`}
      />
    </TableCard>
  )
}
