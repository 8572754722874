import { ChangeEvent, FC } from "react";
import { Box } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useIntl } from "react-intl";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { getHelperText } from "src/utils/forms";
import { AMPSwitcher } from "../../common/AMPSwitcher";
import { CurrencyEnum, LanguageEnum, NormalizeLanguageEnum } from "../../../enums";
import { AMPMarkdownEditor } from "../../common/AMPMarkdownEditor";
import { RfidTermsFormProps } from "./RfidTermsForm.types";
import { actionsWrapperSx, formContentSx, formSx } from "./RfidTermsForm.sx";
import { rfidTermsFormSchema, RfidTermsFormType } from "../formData/rfidTermsForm";
import { AMPInput } from "../../common/AMPInput";
import { ContractTermsFormType } from "../formData/ContractTermsForm";

const processedLanguages = () => {
  return Object.values(LanguageEnum).map((item, index) => ({
    id: index,
    name: NormalizeLanguageEnum[item],
    value: item
  }))
}

export const RfidTermsForm: FC<RfidTermsFormProps> = ({
  submit,
  englishInit,
  frenchInit,
  germanInit,
  handleClose
}) => {
  const intl = useIntl();
  
  const handleChangeSwitcher = (
    change: (e: ChangeEvent) => void,
    setFieldValue:  (field: string, value: any, shouldValidate?: (boolean | undefined)) => void) => {
    return (e: ChangeEvent) => {
      change(e)
      const value = (e?.target as HTMLInputElement).value
      const initsMap: Record<string, RfidTermsFormType> = {
        [LanguageEnum.ENGLISH]: englishInit,
        [LanguageEnum.FRENCH]: frenchInit,
        [LanguageEnum.DEUTSCH]: germanInit
      }
      const newInit = initsMap[value ?? LanguageEnum.ENGLISH]
      setFieldValue('rfidTerms', newInit.rfidTerms)
    }
  }
  
  const processedCurrencies = () => {
    return Object.values(CurrencyEnum).map((value, index) => ({
      id: index,
      name: intl.formatMessage({id: value}),
      value: value
    }))
  }
  
  return (
    <Formik
      initialValues={englishInit}
      validationSchema={rfidTermsFormSchema}
      onSubmit={submit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }: FormikProps<RfidTermsFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <AMPSwitcher
                value={values["language"]}
                input={"language"}
                label={"language"}
                data={processedLanguages()}
                onChange={handleChangeSwitcher(handleChange, setFieldValue)}
              />
              {values['language'] === LanguageEnum.ENGLISH &&
                <AMPMarkdownEditor
                  input="rfidTerms"
                  value={values["rfidTerms"]}
                  label="termsOfRfid"
                  setValue={setFieldValue}
                  onBlur={handleBlur}
                  hasError={
                    touched["rfidTerms"] && Boolean(errors["rfidTerms"])
                  }
                  helperText={getHelperText(
                    "rfidTerms",
                    touched,
                    errors,
                    intl
                  )}
                />
              }
              {values['language'] === LanguageEnum.FRENCH &&
                <AMPMarkdownEditor
                  input="rfidTerms"
                  value={values["rfidTerms"]}
                  label="termsOfRfid"
                  setValue={setFieldValue}
                  onBlur={handleBlur}
                  hasError={
                    touched["rfidTerms"] && Boolean(errors["rfidTerms"])
                  }
                  helperText={getHelperText(
                    "rfidTerms",
                    touched,
                    errors,
                    intl
                  )}
                />
              }
              {values['language'] === LanguageEnum.DEUTSCH &&
                <AMPMarkdownEditor
                  input="rfidTerms"
                  value={values["rfidTerms"]}
                  label="termsOfRfid"
                  setValue={setFieldValue}
                  onBlur={handleBlur}
                  hasError={
                    touched["rfidTerms"] && Boolean(errors["rfidTerms"])
                  }
                  helperText={getHelperText(
                    "rfidTerms",
                    touched,
                    errors,
                    intl
                  )}
                />
              }
              <AMPSwitcher
                value={values["currency"]}
                input={"currency"}
                label={"currency"}
                data={processedCurrencies()}
                onChange={handleChange}
              />
              <AMPInput
                value={values["price"]}
                type={"number"}
                label={"price"}
                input={"price"}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={
                  touched["price"] && Boolean(errors["price"])
                }
                helperText={getHelperText(
                  "price",
                  touched,
                  errors,
                  intl
                )}
              />
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={handleClose}
              />
              <SubmitButton
                text="ok"
                color="primary"
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
