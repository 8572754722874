import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { AppRouteSpace, AppRouteEnum } from "src/utils/helper";
import { changeLang } from "src/stores/common/commonSlice";
import { useLocation } from "react-router-dom";
import { PolicyFrench } from "../../components/termsAndPolicy/PolicyFrench";

export const PrivacyPolicyFrench:FC = () => {
  const { lang } = useAppSelector(state => state.common)
  const dispatch = useAppDispatch()
  const location = useLocation()
  useEffect(() => {
    if(location.pathname !== AppRouteSpace([AppRouteEnum.support, lang])){
      dispatch(changeLang(location.pathname.replace(/\/(.+)\/(\w+)/, '$2')))
    }
  }, [lang])
  
  return (
    <PolicyFrench/>
  )
}