import theme from "src/theme";

export const rfidActionsSx = {
  root: {
    flexDirection: "row",
  },
  button: {
    width: "28px",
    height: "28px",
    p: "2px",
    color: theme.palette.neutral[400],
    backgroundColor: "transparent",
    ".MuiSvgIcon-root": {
      fill: theme.palette.neutral[400]
    },
    "&:not(:last-child)": {
      mr: 1
    }
  },
  icon: {
    width: "20px"
  }
}
