import * as Yup from 'yup';
import { ScheduleTypeEnum, WeekEnum } from "../../../../enums";

export interface ScheduleFormType {
  startDay: string | WeekEnum,
  startHour: number | string,
  startMinute: number | string,
  stopDay: string | WeekEnum,
  stopHour: number | string,
  stopMinute: number | string,
  type: boolean
}

export const scheduleFormSchema = Yup.object().shape({
  startDay: Yup.string()
    .required('schemaRequiredStartDay'),
  startHour: Yup.string()
    .required('schemaRequiredStartHour'),
  startMinute: Yup.string()
    .required('schemaRequiredStartMinute'),
  stopDay: Yup.string()
    .required('schemaRequiredStopDay'),
  stopHour: Yup.string()
    .required('schemaRequiredStopHour'),
  stopMinute: Yup.string()
    .required('schemaRequiredStopMinute'),
});
