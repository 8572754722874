export enum TariffsStatusEnum {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED"
}

export enum CurrencyEnum {
  EUR = "EUR",
  CHF = "CHF"
}

export enum TariffsSortFieldEnum {
  NAME = "NAME",
  STATUS = "STATUS"
}

export enum TariffSortValuesEnum {
  COUNTRY = "COUNTRY",
  NAME = "NAME",
  REGION = "REGION",
  STATUS = "STATUS"
}