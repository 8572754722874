import { FC } from "react";
import ReactPDF from "@react-pdf/renderer";
import dayjs from "dayjs";
import { convertMinutes } from "../../../../utils/helper";
import ability from "../../../../permissions/ability";
import { PdfDocumentProps } from "./PdfDocument.types";
import { pdfDocumentSx } from "./PdfDocument.sx";

const { Document, Page, Text, View } = ReactPDF

export const PdfDocument:FC<PdfDocumentProps> = ({ data, intl }) => {
  const isSuperAdmin = ability.can('read', 'businessReports')

  return (
    <Document>
      <Page
        size='A4'
        orientation={'landscape'}
      >
        <View style={pdfDocumentSx.table}>
          <View style={[pdfDocumentSx.row, pdfDocumentSx.bold, pdfDocumentSx.header]}>
            {isSuperAdmin && <Text style={pdfDocumentSx.row1}>{intl.formatMessage({id: 'business'})}</Text>}
            <Text style={pdfDocumentSx.row1}>{intl.formatMessage({id: 'status'})}</Text>
            <Text style={pdfDocumentSx.row1}>{intl.formatMessage({id: 'location'})}</Text>
            <Text style={pdfDocumentSx.row1}>{intl.formatMessage({id: 'evDriverName'})}</Text>
            <Text style={pdfDocumentSx.row1}>{intl.formatMessage({id: 'rfid'})}</Text>
            <Text style={pdfDocumentSx.row1}>{intl.formatMessage({ id: 'transactionStart' })}</Text>
            <Text style={pdfDocumentSx.row1}>{intl.formatMessage({ id: 'transactionEnd' })}</Text>
            <Text style={pdfDocumentSx.row1}>{intl.formatMessage({id: 'chargingTime'})}</Text>
            <Text style={pdfDocumentSx.row1}>{intl.formatMessage({id: 'powerConsumed'})}</Text>
          </View>
          {data.map((row, i) => (
            <View
              key={i}
              style={pdfDocumentSx.row}
              wrap={false}
            >
              {isSuperAdmin &&
                <Text style={pdfDocumentSx.row1}>
                  {row?.business?.name}
                </Text>}
              <Text style={pdfDocumentSx.row1}>
                {row?.active
                  ? intl.formatMessage({id: 'active'})
                  : intl.formatMessage({id: 'completed'})}
              </Text>
              <Text style={pdfDocumentSx.row1}>
                {row?.location?.name}
              </Text>
              <Text style={pdfDocumentSx.row1}>{`${row?.user?.firstName} ${row?.user?.lastName}`}</Text>
              <Text style={pdfDocumentSx.row1}>{`${row?.idTag?.idTag}`}</Text>
              <Text style={pdfDocumentSx.row1}>{dayjs(row?.startTimestamp).format('DD MMM YYYY HH:mm:ss')}</Text>
              <Text style={pdfDocumentSx.row1}>
                {row?.stopTimestamp
                  ? dayjs(row?.stopTimestamp).format('DD MMM YYYY HH:mm:ss')
                  : '-'
                }
              </Text>
              <Text style={pdfDocumentSx.row1}>{convertMinutes(row?.chargingTimeMinutes)}</Text>
              <Text style={pdfDocumentSx.row1}>{`${row?.wattsConsumed} ${intl.formatMessage({ id: 'kw' })}`}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )}
