import * as Yup from 'yup';

export interface ChargerType extends Record<string, unknown>{
  chargePointId: string,
  locationId: string,
  modelId: string,
  userId: string,
}

export const chargerSchema = Yup.object().shape({
  chargePointId: Yup.string()
    .required('schemaRequiredChargePointId')
    .max(255),
  modelId: Yup.string()
    .required('schemaRequiredStationModel'),
});
