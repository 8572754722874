import * as Yup from "yup";
import { LanguageEnum } from "../../../../enums";

export interface ContractTermsFormType {
  contractTerms: string,
  language: LanguageEnum,
  termsLink: string
}

export const contractTermsFormSchema = Yup.object().shape({
  contractTerms: Yup.string()
    .required('schemaRequiredContractTerms'),
  language: Yup.string().required(),
  termsLink: Yup.string().max(255).required('schemaRequiredDocumentLink')
});
