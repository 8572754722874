import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'src/axiosBaseQuery'
import {
  ChargePointByIdRequest,
  ChargePointCreateRequest,
  ChargePointDTO,
  ChargePointSearchRequest,
  ChargerImageRequest, CreateChargerCostRequest,
  DefaultResponse, DeleteChargerCostRequest,
  DeleteScheduleRequest,
  DeleteUserRequest,
  PageChargePointDTO,
  PageModelDTO,
  ScheduleChargingDTO,
  ScheduleChargingRequest,
  UpdateChargePointRequest,
  UpdateChargerCostRequest,
  UpdateScheduleRequest,
  UpdateScheduleStatusRequest
} from 'src/types'
import { getBaseURL } from 'src/utils/helper'

// initialize an empty api service that we'll inject endpoints into later as needed
export const chargersApi = createApi({
  reducerPath: 'chargersApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL() + '/chargePoints'),
  }),
  tagTypes: ['Charger', 'Chargers', 'Schedule', 'Configurations'],
  endpoints: (build) => ({
    getChargers: build.query<PageChargePointDTO, ChargePointSearchRequest>({
      query: ({ data, params }) => ({ url: '/search', method: 'post', data, params }),
      providesTags: ['Chargers']
    }),
    addCharger: build.mutation<ChargePointDTO, ChargePointCreateRequest>({
      query: (data) => ({ url: '', method: 'post', data }),
      invalidatesTags: ['Chargers']
    }),
    getModels: build.query<PageModelDTO, number>({
      query: (businessId) => ({ url: '/models', method: 'get', params: { businessId } }),
    }),
    getChargePointById: build.query<ChargePointDTO, ChargePointByIdRequest>({
      query: (params) => ({ url: `/${params.chargerId}`, method: 'get', params }),
      providesTags: ['Charger']
    }),
    deleteChargePoint: build.mutation<DefaultResponse, { businessId: number, chargerId: number }>({
      query: ({ businessId, chargerId }) => ({ url: `/${chargerId}`, method: 'delete', params: { businessId } }),
      invalidatesTags: ['Chargers']
    }),
    updateChargerCost: build.mutation<ChargePointDTO, UpdateChargerCostRequest>({
      query: ({ data, params }) => ({ url: `/${params.chargerId}/cost/${params.itemId}`, method: 'put', data }),
      invalidatesTags: ['Charger']
    }),
    createChargerCost: build.mutation<ChargePointDTO, CreateChargerCostRequest>({
      query: ({ data, chargerId }) => ({ url: `/${chargerId}/cost`, method: 'post', data }),
      invalidatesTags: ['Charger']
    }),
    deleteChargerCost: build.mutation<ChargePointDTO, DeleteChargerCostRequest>({
      query: ({ businessId, params }) => ({
        url: `/${params.chargerId}/cost/${params.itemId}`,
        method: 'delete',
        params: { businessId }
      }),
      invalidatesTags: ['Charger']
    }),
    updateChargePoint: build.mutation<ChargePointDTO, UpdateChargePointRequest>({
      query: ({ data, chargerId }) => ({ url: `/${chargerId}`, method: 'put', data }),
      invalidatesTags: ['Charger']
    }),
    addChargerImage: build.mutation<ChargePointDTO, ChargerImageRequest>({
      query: ({ chargerId, data }) => ({ url: `/${chargerId}/image`, method: 'post', data }),
      invalidatesTags: ['Charger']
    }),
    getSchedule: build.query<ScheduleChargingDTO[], { chargerId: number, businessId: number }>({
      query: ({ chargerId, businessId }) => ({ url: `/${chargerId}/scheduleCharging`, method: 'get', params: { businessId } }),
      providesTags: ['Schedule']
    }),
    addSchedule: build.mutation<ScheduleChargingDTO, { chargerId: number, businessId: number, data: ScheduleChargingRequest }>({
      query: ({ chargerId, businessId, data }) => ({ url: `/${chargerId}/scheduleCharging`, method: 'post', params: { businessId }, data }),
      invalidatesTags: ['Schedule']
    }),
    updateScheduleStatus: build.mutation<ScheduleChargingDTO, UpdateScheduleStatusRequest>({
      query: ({ chargerId, businessId, active, scheduleChargingId }) => ({ url: `/${chargerId}/scheduleCharging/${scheduleChargingId}/toggle`, method: 'put', params: { businessId, active } }),
      invalidatesTags: ['Schedule']
    }),
    updateSchedule: build.mutation<ScheduleChargingDTO, UpdateScheduleRequest>({
      query: ({ chargerId, data, scheduleChargingId }) => ({ url: `/${chargerId}/scheduleCharging/${scheduleChargingId}`, method: 'put', data }),
      invalidatesTags: ['Schedule']
    }),
    deleteSchedule: build.mutation<DefaultResponse, DeleteScheduleRequest>({
      query: ({ scheduleChargingId, chargerId, businessId }) => ({ url: `/${chargerId}/scheduleCharging/${scheduleChargingId}`, method: 'delete', params: { businessId } }),
      invalidatesTags: ['Schedule']
    }),
    deleteUser: build.mutation<DefaultResponse, DeleteUserRequest>({
      query: ({ userId, chargerId, businessId }) => ({ url: `/${chargerId}/users`, method: 'delete', params: { businessId, userId } }),
      invalidatesTags: ['Charger']
    }),
  }),
})
