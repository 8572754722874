import { FC, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { useIntl } from "react-intl";
import MdEditor, { Plugins } from 'react-markdown-editor-lite';
import ReactMarkdown from "react-markdown";
import { CustomFormLabel } from "../CustomFormLabel";
import { FormHelper } from "../FormHelper";
import { DEFAULT_EDITOR_HEIGHT } from "../../../utils/consts";
import remarkGfm from 'remark-gfm'
import remarkDefinitionList from "remark-definition-list";
import rehypeRaw from "rehype-raw";
import theme from "../../../theme";
import { AMPMarkdownEditorProps, MarkdownDataType } from "./AMPMarkdownEditor.types";
import 'react-markdown-editor-lite/lib/index.css';
import { ampMarkdownEditorSx } from "./AMPMarkdownEditor.sx";

MdEditor.unuse(Plugins.FontUnderline)
MdEditor.unuse(Plugins.FontStrikethrough)
MdEditor.unuse(Plugins.Table)

export const AMPMarkdownEditor:FC<AMPMarkdownEditorProps> = ({
  height = DEFAULT_EDITOR_HEIGHT,
  hasError,
  input,
  setValue,
  label,
  required,
  onBlur,
  helperText,
  value
}) => {
  const intl = useIntl();
  const [val, setVal] = useState(value)
  
  const handleChange = (data: MarkdownDataType) => {
    setVal(data.text)
    setValue(input, data.text)
  }
  
  const screenWidth = window.innerWidth
  
  const editorHeight  = screenWidth < theme.breakpoints.values.lg ? height * 2 : height
  
  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={!!hasError}
      margin="normal"
    >
      <CustomFormLabel
        label={intl.formatMessage({ id: label })}
        required={required}
      />
      <Box sx={ampMarkdownEditorSx.editor}>
        <MdEditor
          id={input}
          name={input}
          value={val}
          style={{ height: editorHeight, width: "100%" }}
          renderHTML={text =>
            <ReactMarkdown
              remarkPlugins={[remarkGfm, remarkDefinitionList]}
              rehypePlugins={[rehypeRaw]}
            >
              {text}
            </ReactMarkdown>
          }
          onChange={handleChange}
          onBlur={onBlur}
        />
      </Box>
      {helperText && (
        <FormHelper helperText={helperText} />
      )}
    </FormControl>
  )
}
