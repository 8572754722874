import * as Yup from 'yup';
import { LanguageEnum } from "../../../../enums";

export interface UserFormType {
  bexioId: number,
  cityHome: string,
  countryHome: string,
  regionHome: string,
  houseNumberHome: string,
  streetHome: string,
  zipCodeHome: string
  cityParking: string,
  countryParking: string,
  regionParking: string,
  houseNumberParking: string,
  streetParking: string,
  zipCodeParking: string
  parkingLotParking: string,
  businessId: number,
  emailAddress: string,
  firstName: string,
  lastName: string,
  language: string,
  phoneNumber: string,
  title: string,
  useParkingAddress?: boolean
}

export const userFormSchema = Yup.object().shape({
  bexioId: Yup.number()
    .notOneOf([0]),
  businessId: Yup.number()
    .required()
    .notOneOf([0]),
  firstName: Yup.string()
    .max(255)
    .required('schemaRequiredFirstName'),
  lastName: Yup.string()
    .max(255)
    .required('schemaRequiredLastName'),
  emailAddress: Yup.string()
    .email('schemaValidEmail')
    .max(255)
    .required('schemaRequiredEmail'),
  houseNumberParking: Yup.string()
    .required('schemaRequiredHouseNumber'),
  streetParking: Yup.string()
    .max(255)
    .required('schemaRequiredStreet'),
  cityParking: Yup.string()
    .required('schemaRequiredCity'),
  zipCodeParking: Yup.string()
    .max(255)
    .required('schemaRequiredZip'),
  countryParking: Yup.string()
    .required('schemaRequiredCountry'),
  regionParking: Yup.string()
    .required('schemaRequiredRegion'),
  parkingLotParking: Yup.string()
    .required('schemaRequiredParkingLot'),
  houseNumberHome: Yup.string()
    .required('schemaRequiredHouseNumber'),
  streetHome: Yup.string()
    .max(255)
    .required('schemaRequiredStreet'),
  cityHome: Yup.string()
    .required('schemaRequiredCity'),
  zipCodeHome: Yup.string()
    .max(255)
    .required('schemaRequiredZip'),
  countryHome: Yup.string()
    .required('schemaRequiredCountry'),
  regionHome: Yup.string()
    .required('schemaRequiredRegion'),
  title: Yup.string()
    .required('schemaRequiredTitle'),
  phoneNumber: Yup.string()
    .required('schemaRequiredPhone')
    .min(8, 'schemaRequiredPhone'),
  language: Yup.string()
    .required('schemaLanguageRequired'),
  useParkingAddress: Yup.boolean()
});
