import { Button, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { submitButtonSx } from "./SubmitButton.sx";
import { SubmitButtonType } from "./SubmitButton.types";

export const SubmitButton: FC<SubmitButtonType> = ({ text, handler, isSubmitting = false, rounding = false, color = 'primary', align = 'flex-end', fullWidth = false, style={} }) => {
  const sx= {...submitButtonSx(rounding, align,fullWidth), ...style}
  
  return (
    <Button
      type="submit"
      color={color}
      variant="contained"
      disabled={isSubmitting}
      onClick={handler}
      fullWidth={fullWidth}
      sx={sx}
    >
      <Typography
        variant="button"
        color="textSecondary"
      >
        <FormattedMessage id={text} />
      </Typography>
    </Button>
  );
}
