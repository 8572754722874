import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const listWrapperSx: SxProps<Theme> = {
  width: '100%',
  padding: theme.spacing(24/8),
  background: theme.palette.text.secondary,
  borderRadius: theme.spacing(8/8, 8/8, 0, 0),
  overflow: 'hidden'
}

export const tableSx = {
  border: 'none',
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
}
  
export const titleSx: SxProps<Theme> = {
  marginBottom: theme.spacing(10/8)
}

export const actionButtonWrapperSx: SxProps<Theme> = {
  width: '100%',
  background: theme.palette.neutral[100],
  padding: theme.spacing(15/8, 12/8),
  borderRadius: '0 0 8px 8px',
  '& button': {
    display: 'flex',
    gap: theme.spacing(5/8),
    color: theme.palette.text.primary,
  }
}
