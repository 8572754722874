import { SxProps, Theme } from "@mui/material";
import theme from "../../../theme";

export const notificationsNumberSx: SxProps<Theme> = {
  backgroundColor: theme.palette.error.dark,
  borderRadius: '100%',
  width: theme.spacing(22/8),
  height: theme.spacing(22/8),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  '.MuiTypography-root': {
    fontSize: "0.7rem",
    color: theme.palette.common.white,
    fontWeight: 600
  }
}
