import { FC } from "react";
import { termsOfUseSx } from "./Terms.sx";
import { Box, Typography } from "@mui/material";

export const Terms:FC = () => {
  return (
    <Box sx={termsOfUseSx.root}>
      <Typography variant="h2">
        Terms and Conditions for AMP IT SA
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Introduction
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        These Website Standard Terms and Conditions written on this webpage shall manage your use of our website and mobile application, owned by AMP IT SA and accessible at amp-it.com.
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        These Terms will be applied fully and affect your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website&apos;s Standard Terms and Conditions.
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Minors or people below 18 years old are not allowed to use this Website.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Intellectual Property Rights
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Other than the content you own, under these Terms, AMP IT SA and/or its licensors own all the intellectual property rights and materials contained in this Website. Our Terms and Conditions have been generated at Terms And Conditions Template Generator.
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        You are granted a limited license only for the purposes of viewing the material contained on this Website.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Restrictions
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        You are specifically restricted from all of the following:
      </Typography>
      <ul>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            publishing any Website material in any other media;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            selling, sublicensing and/or otherwise commercializing any Website material;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            publicly performing and/or showing any Website material;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            using this Website in any way that is or may be damaging to this Website;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            using this Website in any way that impacts user access to this Website;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;
          </Typography>
        </li>
        <li>
          <Typography
            variant='body2'
            sx={termsOfUseSx.paragraph}
          >
            using this Website to engage in any advertising or marketing.
          </Typography>
        </li>
      </ul>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Certain areas of this Website are restricted from being accessed by you and AMP IT SA may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Your Content
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        In these Website Standard Terms and Conditions, &quot;Your Content&quot; shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Content, you grant AMP IT SA a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        Your Content must be your own and must not be invading any third-party’s rights. AMP IT SA reserves the right to remove any of Your Content from this Website at any time without notice.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        No warranties
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        This Website is provided &quot;as is,&quot; with all faults, and AMP IT SA express no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Limitation of liability
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        In no event shall AMP IT SA, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.  AMP IT SA, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Indemnification
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        You hereby indemnify to the fullest extent AMP IT SA from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Severability
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Variation of Terms
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        AMP IT SA is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Assignment
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        The AMP IT SA is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Entire Agreement
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        These Terms constitute the entire agreement between AMP IT SA and you in relation to your use of this Website, and supersede all prior agreements and understandings.
      </Typography>
      <Typography
        variant='h6'
        sx={termsOfUseSx.paragraph}
      >
        Governing Law & Jurisdiction
      </Typography>
      <Typography
        variant='body2'
        sx={termsOfUseSx.paragraph}
      >
        These Terms will be governed by and interpreted in accordance with the laws of applicable in Switzerland and you submit to the non-exclusive jurisdiction of federal and cantonal courts located in Geneva for the resolution of any disputes.
      </Typography>
    </Box>
  )
}