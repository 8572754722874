import { FC, useState, MouseEvent } from "react";
import { Box, Popover, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { rfidItemCardSx } from "./RfidItemCard.sx";
import { RfIdItemCardType } from "./RfIdItemCard.types";
import { RfidActions } from "../RfidActions";
import { rfidTableSx } from "../RfidTable.sx";
import { BlockStatusEnum, ValidityEnum } from "../RfidTable.types";
import dayjs from "dayjs";
import { TableCard } from "../../../tables/respnsiveTable/TableCard";
import { MenuButton } from "../../../tables/respnsiveTable/MenuButton";
import { MenuWrapper } from "../../../tables/respnsiveTable/MenuWrapper";
import { TableRow } from "../../../tables/respnsiveTable/TableRow";

export const RfIdItemCard:FC<RfIdItemCardType> = ({data, userId, businessId}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const expire = dayjs(data.expiry)
  const creation = dayjs(data.created)
  const diff = expire.diff(creation)
  
  return (
    <TableCard>
      <MenuWrapper>
        <MenuButton handleClick={handleClick}/>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={rfidItemCardSx.popover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <RfidActions
            userId={userId}
            businessId={businessId}
            blocked={data.blocked}
            expiry={+data.expiry}
            ocppTagId={data.id}
            idTag={data.idTag}
          />
        </Popover>
      </MenuWrapper>
      <TableRow
        titleId='rfidTag'
        isColumn
        value={data?.idTag ?? ''}
        isId
      />
      <TableRow
        titleId='statusColumnHeader'
        customValue={<Box
          sx={[
            rfidTableSx.tag,
            !data.blocked && rfidTableSx.tagActive,
            data.blocked && rfidTableSx.tagBlocked
          ]}>
          <FormattedMessage
            id={data.blocked
              ? BlockStatusEnum.blocked
              : BlockStatusEnum.accepted}
          />
        </Box>
        }
      />
      <TableRow
        titleId='validity'
        customValue={<Typography color={diff >= 0 ? "primary.dark" : "error.dark"}>
          {
            <FormattedMessage
              id={
                diff >= 0
                  ? ValidityEnum.actual
                  : ValidityEnum.expired
              }
            />
          }
        </Typography>
        }
      />
      <TableRow
        titleId='expirationDate'
        value={dayjs(data.expiry).format("DD/MM/YYYY")}
      />
      <TableRow
        titleId='creationDate'
        value={data.created && dayjs(data.created).format("DD/MM/YYYY")}
      />
    </TableCard>
  )
}
