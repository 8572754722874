import { Box, useTheme } from "@mui/material";
import { Formik, FormikConfig, FormikProps } from "formik";
import { FC, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { locationsApi, usersApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { AMPAutocomplete } from "src/components/common/AMPAutocomplete";
import { AMPAutocompleteProps } from "src/components/common/AMPAutocomplete/AMPAutocomplete.types";
import { AMPInput } from "src/components/common/AMPInput";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { TableFilterFormTypes } from "src/components/reports/TableFilter/TableFilter.types";
import { RoleEnum } from "src/enums";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch, BaseUserInfo } from "src/types";
import { getHelperText } from "src/utils/forms";
import { findAutocompleteValueById, isErrorObject, valuesTrimmer } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { addClientToLocationFormSchema, AddClientToLocationFormType } from "../../forms/formData/addClientToLocationForm";
import { addClientToLocationFormValues } from "../../forms/formData/common/initialData";
import { AddClientToLocationFormProps } from "./AddClientToLocationForm.types";

type AddUserToLocationMutationTrigger = ReturnType<typeof locationsApi.useAddUserToLocationMutation>[0]

const handleSubmit = (trigger: AddUserToLocationMutationTrigger, dispatch: AppDispatch, locationId: number, businessId: number, handleClose: () => void, users?: BaseUserInfo[]): FormikConfig<AddClientToLocationFormType>['onSubmit']  => (async (value) => {
  const values = valuesTrimmer(value)
  const parkingPlaceValue = () => {
    if(values.parkingPlace){
      return values.parkingPlace
    }
    const currentUser = users && users.find(user => user.id === +values.userId)
    return currentUser?.parkingAddress?.parkingPlace ?? 0
  }

  const data = {
    businessId,
    locationId,
    userId: +values.userId,
    parkingPlace: String(parkingPlaceValue())
  }
  try {
    dispatch(preloaderChangeStatus(true))
    await trigger(data).unwrap()
    handleClose()    
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
});

export const AddClientToLocationForm: FC<AddClientToLocationFormProps> = ({ existClients, businessId, locationId, handleClose }) => {
  const intl = useIntl()
  const theme = useTheme()
  const [trigger] = locationsApi.useAddUserToLocationMutation()
  const dispatch = useAppDispatch()
  const [getClients, clients] = usersApi.useLazyGetUsersQuery()

  const getClientsData = async () => {
    await getClients({data: {businessId, roles: [RoleEnum.DRIVER], locationExists: false}, params: { size: 2000 }})
  }

  useEffect(() => {
    getClientsData()
  }, [])

  const processedClients = useMemo(() => {
    if(!clients.data){
      return []
    }
    const data = clients.data?.content.filter(client => !client.chargePointId).filter(client => {
      return existClients.findIndex(item => client.id === item.id) === -1
    })
    return data.map((client) => ({
      id: client.id,
      label: `${client.firstName} ${client.lastName}`
    }))
  }, [clients])

  const handleChangeClientValue = (setFieldValue: FormikProps<TableFilterFormTypes>['setFieldValue'], fieldName: string, values: AddClientToLocationFormType): AMPAutocompleteProps['onChange'] =>
    (e, newValue) => {
      if(newValue){
        setFieldValue(fieldName, newValue.id)
        const client = clients?.data?.content.find(item => item.id === newValue.id)
        values['parkingPlace'] = client?.parkingAddress?.parkingPlace ?? ''
      }
    }

  return (
    <Formik
      initialValues={addClientToLocationFormValues}
      validationSchema={addClientToLocationFormSchema}
      onSubmit={handleSubmit(trigger, dispatch, locationId, businessId, handleClose, clients.data?.content)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values
      }: FormikProps<AddClientToLocationFormType>) => (
        <form
          onSubmit={handleSubmit}
          noValidate
        >
          <AMPAutocomplete
            options={processedClients}
            value={findAutocompleteValueById(processedClients, +values['userId'])}
            label={'client'}
            input={'userId'}
            showAvatar
            onChange={handleChangeClientValue(setFieldValue, 'userId', values)}
            onBlur={handleBlur}
            description='selectDriverDescription'
            hasError={touched['userId'] && Boolean(errors['userId'])}
            helperText={getHelperText('userId', touched, errors, intl)}
          />
          <AMPInput
            value={values['parkingPlace']}
            type={'text'}
            label={'parkingPlace'}
            input={'parkingPlace'}
            onChange={handleChange}
            onBlur={handleBlur}
            hasError={touched['parkingPlace'] && Boolean(errors['parkingPlace'])}
            helperText={getHelperText('parkingPlace', touched, errors, intl)}
          />
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: theme.spacing(20/8)
          }}>
            <CancelButton
              text="cancel"
              handler={handleClose}
            />

            <SubmitButton
              text="submit"
            />
          </Box>
        </form>)
      }
    </Formik>
  );
}
