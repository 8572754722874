import * as Yup from 'yup';

export interface TariffDescriptionType extends Record<string, unknown>{
  description: string,
  descriptionFrench: string,
  descriptionGerman: string,
  pricePerKWh: number | string
  monthlyFee: number | string,
  country?: string,
  region?: string,
}
export const tariffDescriptionSchema = Yup.object().shape({
  description: Yup.string()
    .required('schemaRequiredTariffDescription'),
  descriptionFrench: Yup.string()
    .required('schemaRequiredTariffDescription'),
  descriptionGerman: Yup.string()
    .required('schemaRequiredTariffDescription'),
  country: Yup.string(),
  region: Yup.string(),
  pricePerKWh: Yup.number().required(),
  monthlyFee: Yup.number().required()

});
