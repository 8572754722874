import { FC, MouseEvent, useState } from "react";
import { ContentCopyOutlined } from "@mui/icons-material";
import { Avatar, Button, IconButton, Popover, Stack, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import copy from "copy-to-clipboard";
import { useIntl } from "react-intl";
import { locationsApi } from "../../../../api";
import { useAppDispatch } from "../../../../app/hooks";
import { NotifyError, NotifySuccess } from "../../../../utils/notification";
import { AppDispatch } from "../../../../types";
import { preloaderChangeStatus } from "../../../../stores/common/commonSlice";
import { clientCardSx } from "./ClientCard.sx";
import { MenuButton } from "../../../tables/respnsiveTable/MenuButton";
import { TableCard } from "../../../tables/respnsiveTable/TableCard";
import { MenuWrapper } from "../../../tables/respnsiveTable/MenuWrapper";
import { TableRow } from "../../../tables/respnsiveTable/TableRow";
import { AppRouteEnum, AppRouteSpace, isErrorObject } from "src/utils/helper";
import { ClientCardProps } from "./ClientCard.types";

type DeleteUserFromLocationMutationTrigger = ReturnType<typeof locationsApi.useDeleteUserFromLocationMutation>[0]

const handleDeleteClient = (trigger: DeleteUserFromLocationMutationTrigger, dispatch: AppDispatch, locationId: number, userId: number, businessId: number) => (async () => {
  const data = {
    businessId,
    locationId,
    userId
  }
  dispatch(preloaderChangeStatus(true))
  try {
    await trigger(data).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
});

export const ClientCard:FC<ClientCardProps> = ({id, parkingAddress, businessId, firstName, lastName,  avatar, chargePoint, canCreateDeleteUsers= true}) => {
  const intl = useIntl();
  const { id: locationId } = useParams()
  const [trigger] = locationsApi.useDeleteUserFromLocationMutation()
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const copyToClipboard = () => {
    copy(String(chargePoint.chargePointId), { debug: true })
    NotifySuccess(intl.formatMessage({id: 'copiedSuccesfully'}))
  }
  
  return (
    <TableCard>
      <MenuWrapper>
        {
          canCreateDeleteUsers && <MenuButton handleClick={handleClick}/>
        }
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={clientCardSx.popover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Button
            sx={clientCardSx.menuBtn}
            onClick={handleDeleteClient(trigger, dispatch, locationId ? +locationId : 0, id, businessId  )}
          >
            <Typography sx={clientCardSx.menuBtnText}>
              {intl.formatMessage({id: "delete"})}
            </Typography>
          </Button>
        </Popover>
      </MenuWrapper>
      <TableRow
        titleId='client'
        isColumn
        customValue={
          <Link to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users, String(id)])}>
            <Stack sx={clientCardSx.withIcon}>
              <Avatar
                sx={clientCardSx.avatar}
                src={avatar?.url ?? ""}
              />
              <Typography sx={[clientCardSx.dataText, clientCardSx.dataTextName]}>
                {`${firstName} ${lastName}`}
              </Typography>
            </Stack>
          </Link>
        }
      />
      <TableRow
        titleId='parkingPlace'
        value={parkingAddress?.parkingPlace ?? "0"}
      />
      <TableRow
        titleId='chargePointId'
        customValue={
          <Stack sx={clientCardSx.withIcon}>
            {
              chargePoint?.chargePointId && (
                <Link to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.chargers, String(chargePoint.id)])}>
                  <Typography sx={clientCardSx.dataText}>
                    {chargePoint.chargePointId}
                  </Typography>
                </Link>
              )
            }
            {!chargePoint?.chargePointId &&
              <Typography sx={clientCardSx.dataText}>
                -
              </Typography>
            }
            {chargePoint?.chargePointId &&
            <IconButton
              onClick={copyToClipboard}
              sx={[clientCardSx.iconBtn, {ml: 2}]}
            >
              <ContentCopyOutlined sx={{width: '0.75rem'}} />
            </IconButton>
            }
          </Stack>
        }
      />
    </TableCard>
  )
}
