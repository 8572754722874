import { FC } from "react";

export const UsersIcon:FC = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="58"
        height="58"
        rx="8"
        fill="#E4E7F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2489 31.5199C23.2464 31.5199 22.2537 31.3293 21.3275 30.959C20.4013 30.5888 19.5598 30.0461 18.8509 29.362C18.142 28.6779 17.5797 27.8657 17.1961 26.9719C16.8124 26.078 16.615 25.12 16.615 24.1525C16.615 23.185 16.8124 22.227 17.1961 21.3331C17.5797 20.4393 18.142 19.6271 18.8509 18.943C19.5598 18.2589 20.4013 17.7162 21.3275 17.346C22.2537 16.9757 23.2464 16.7852 24.2489 16.7852C26.2735 16.7852 28.2152 17.5614 29.6469 18.943C31.0785 20.3246 31.8828 22.1986 31.8828 24.1525C31.8828 26.1064 31.0785 27.9804 29.6469 29.362C28.2152 30.7437 26.2735 31.5199 24.2489 31.5199ZM14.0703 42.5709V40.1151C14.0703 38.4868 14.7406 36.9252 15.9336 35.7738C17.1266 34.6225 18.7447 33.9756 20.4319 33.9756H28.0658C29.7531 33.9756 31.3712 34.6225 32.5642 35.7738C33.7572 36.9252 34.4275 38.4868 34.4275 40.1151V42.5709H14.0703ZM39.1775 27.8362C38.7096 27.8362 38.2464 27.7459 37.8141 27.5705C37.3819 27.3952 36.9892 27.1381 36.6584 26.814C36.3276 26.49 36.0652 26.1053 35.8861 25.6819C35.7071 25.2585 35.615 24.8047 35.615 24.3464C35.615 23.8881 35.7071 23.4343 35.8861 23.0109C36.0652 22.5875 36.3276 22.2028 36.6584 21.8787C36.9892 21.5547 37.3819 21.2976 37.8141 21.1222C38.2464 20.9469 38.7096 20.8566 39.1775 20.8566C40.1223 20.8566 41.0284 21.2243 41.6965 21.8787C42.3646 22.5332 42.74 23.4208 42.74 24.3464C42.74 25.2719 42.3646 26.1596 41.6965 26.814C41.0284 27.4685 40.1223 27.8362 39.1775 27.8362ZM34.4275 33.0709V31.9076C34.4275 31.1363 34.7402 30.3966 35.297 29.8512C35.8537 29.3058 36.6088 28.9994 37.3962 28.9994H40.9587C41.7461 28.9994 42.5012 29.3058 43.0579 29.8512C43.6147 30.3966 43.9275 31.1363 43.9275 31.9076V33.0709H34.4275Z"
        fill="#697CC0"/>
    </svg>
  )
}

export const ActiveSessions:FC = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3318_138947)">
        <rect
          width="58"
          height="58"
          rx="8"
          fill="#DEF0FC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4106 20.52L43.4283 20.5022L36.815 13.8889L34.9306 15.7733L38.6817 19.5244C37.0106 20.1644 35.8194 21.7644 35.8194 23.6667C35.8194 26.12 37.8106 28.1111 40.2639 28.1111C40.9039 28.1111 41.4906 27.9689 42.0417 27.7378V40.5556C42.0417 41.5333 41.2417 42.3333 40.2639 42.3333C39.2861 42.3333 38.4861 41.5333 38.4861 40.5556V32.5556C38.4861 30.6 36.8861 29 34.9306 29H33.1528V16.5556C33.1528 14.6 31.5528 13 29.5972 13H18.9306C16.975 13 15.375 14.6 15.375 16.5556V45H33.1528V31.6667H35.8194V40.5556C35.8194 43.0089 37.8106 45 40.2639 45C42.7172 45 44.7083 43.0089 44.7083 40.5556V23.6667C44.7083 22.44 44.2106 21.32 43.4106 20.52ZM40.2639 25.4444C39.2861 25.4444 38.4861 24.6444 38.4861 23.6667C38.4861 22.6889 39.2861 21.8889 40.2639 21.8889C41.2417 21.8889 42.0417 22.6889 42.0417 23.6667C42.0417 24.6444 41.2417 25.4444 40.2639 25.4444ZM30.6874 29.2016C30.6874 32.7678 27.7964 35.6589 24.2301 35.6589C20.6638 35.6589 17.7728 32.7678 17.7728 29.2016C17.7728 25.6353 20.6638 22.7443 24.2301 22.7443C27.7964 22.7443 30.6874 25.6353 30.6874 29.2016ZM21.975 27.1867C21.975 26.8627 22.2377 26.6 22.5617 26.6H22.855C23.179 26.6 23.4417 26.8627 23.4417 27.1867V31.88C23.4417 32.204 23.179 32.4667 22.855 32.4667H22.5617C22.2377 32.4667 21.975 32.204 21.975 31.88V27.1867ZM24.9083 27.1867C24.9083 26.8627 25.171 26.6 25.495 26.6H25.7883C26.1123 26.6 26.375 26.8627 26.375 27.1867V31.88C26.375 32.204 26.1123 32.4667 25.7883 32.4667H25.495C25.171 32.4667 24.9083 32.204 24.9083 31.88V27.1867Z"
          fill="#4CAEF1"
        />
      </g>
      <defs>
        <clipPath id="clip0_3318_138947">
          <rect
            width="58"
            height="58"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const TotalRevenue:FC = () => {
  return (
    <svg
      width="59"
      height="58"
      viewBox="0 0 59 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3318_139000)">
        <rect
          x="0.5"
          width="58"
          height="58"
          rx="8"
          fill="#FAECD9"
        />
        <path
          d="M38.2668 36.3078H20.7284V33.3848H38.2668V36.3078ZM38.2668 30.4617H20.7284V27.5386H38.2668V30.4617ZM38.2668 24.6155H20.7284V21.6925H38.2668V24.6155ZM16.3438 43.6155L18.5361 41.4232L20.7284 43.6155L22.9207 41.4232L25.113 43.6155L27.3053 41.4232L29.4976 43.6155L31.6899 41.4232L33.8822 43.6155L36.0745 41.4232L38.2668 43.6155L40.4591 41.4232L42.6514 43.6155V14.3848L40.4591 16.5771L38.2668 14.3848L36.0745 16.5771L33.8822 14.3848L31.6899 16.5771L29.4976 14.3848L27.3053 16.5771L25.113 14.3848L22.9207 16.5771L20.7284 14.3848L18.5361 16.5771L16.3438 14.3848V43.6155Z"
          fill="#FFAC4F"
        />
      </g>
      <defs>
        <clipPath id="clip0_3318_139000">
          <rect
            width="58"
            height="58"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const TotalConsumption:FC = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3318_138815)">
        <rect
          width="58"
          height="58"
          rx="8"
          fill="#FFE8EB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.9982 43.6155C37.0701 43.6155 43.6136 37.072 43.6136 29.0002C43.6136 20.9283 37.0701 14.3848 28.9982 14.3848C20.9263 14.3848 14.3828 20.9283 14.3828 29.0002C14.3828 37.072 20.9263 43.6155 28.9982 43.6155ZM29.4479 18.7694V27.14L34.8444 27.14L28.5485 39.2309V30.8603H23.152L29.4479 18.7694Z"
          fill="#F55B85"
        />
      </g>
      <defs>
        <clipPath id="clip0_3318_138815">
          <rect
            width="58"
            height="58"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Sessions:FC = () => {
  return (
    <svg
      width="59"
      height="58"
      viewBox="0 0 59 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#clip0_3318_138601)">
        <rect
          x="0.5"
          width="58"
          height="58"
          rx="8"
          fill="#E0F0E3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.5356 20.52L43.5533 20.5022L36.94 13.8889L35.0556 15.7733L38.8067 19.5244C37.1356 20.1644 35.9444 21.7644 35.9444 23.6667C35.9444 26.12 37.9356 28.1111 40.3889 28.1111C41.0289 28.1111 41.6156 27.9689 42.1667 27.7378V40.5556C42.1667 41.5333 41.3667 42.3333 40.3889 42.3333C39.4111 42.3333 38.6111 41.5333 38.6111 40.5556V32.5556C38.6111 30.6 37.0111 29 35.0556 29H33.2778V16.5556C33.2778 14.6 31.6778 13 29.7222 13H19.0556C17.1 13 15.5 14.6 15.5 16.5556V45H33.2778V31.6667H35.9444V40.5556C35.9444 43.0089 37.9356 45 40.3889 45C42.8422 45 44.8333 43.0089 44.8333 40.5556V23.6667C44.8333 22.44 44.3356 21.32 43.5356 20.52ZM40.3889 25.4444C39.4111 25.4444 38.6111 24.6444 38.6111 23.6667C38.6111 22.6889 39.4111 21.8889 40.3889 21.8889C41.3667 21.8889 42.1667 22.6889 42.1667 23.6667C42.1667 24.6444 41.3667 25.4444 40.3889 25.4444ZM30.9575 29.2016C30.9575 32.7678 28.0664 35.6589 24.5002 35.6589C20.9339 35.6589 18.0429 32.7678 18.0429 29.2016C18.0429 25.6353 20.9339 22.7443 24.5002 22.7443C28.0664 22.7443 30.9575 25.6353 30.9575 29.2016ZM27.5365 29.6283C27.8865 29.4127 27.8865 28.8738 27.5365 28.6582L23.2054 25.9905C22.8554 25.7749 22.4179 26.0443 22.4179 26.4755V31.811C22.4179 32.2421 22.8554 32.5116 23.2054 32.296L27.5365 29.6283Z"
          fill="#5EC46C"
        />
      </g>
      <defs>
        <clipPath id="clip0_3318_138601">
          <rect
            width="58"
            height="58"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
