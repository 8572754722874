import { FC, SyntheticEvent, useState } from "react";
import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { businessesApi } from "src/api";
import { Can } from "src/permissions/Can";
import { useAppDispatch } from "src/app/hooks";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { EditIcon } from "../../icons/EditIcon";
import AMPModal from "../../common/AMPModal";
import { ContractTermsForm } from "../../forms/ContractTermsForm";
import { LanguageEnum } from "../../../enums";
import { ContractTermsFormType } from "../../forms/formData/ContractTermsForm";
import { TabPanel } from "../../common/TabPanel";
import { TermsTab } from "./TermsTab";
import { BusinessTermsType } from "./BusinessTerms.types";
import { editBtnSx, rootSx, tabsSx, tabSx, titleSx } from "./BusinessTerms.sx";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type ContractTermsMutation = ReturnType<typeof businessesApi.useSetContractTermsMutation>[0]

const submitTerms = (
  trigger: ContractTermsMutation,
  dispatch: AppDispatch,
  businessId: number,
  onClose: () => void
) =>
  async (values: ContractTermsFormType) => {
    dispatch(preloaderChangeStatus(true))
    try {
      await trigger({
        businessId,
        params: values
      })
    } catch (error) {
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    } finally {
      onClose()
      dispatch(preloaderChangeStatus(false))
    }
  }

export const BusinessTerms: FC<BusinessTermsType> = ({ data }) => {
  const [tab, setTab] = useState(0)
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [setTerms] = businessesApi.useSetContractTermsMutation()
  const englishDocument = businessesApi.useGetContractTermsQuery({
    businessId: data?.id,
    language: LanguageEnum.ENGLISH
  })
  const frenchDocument = businessesApi.useGetContractTermsQuery({
    businessId: data?.id,
    language: LanguageEnum.FRENCH
  })
  const germanDocument = businessesApi.useGetContractTermsQuery({
    businessId: data?.id,
    language: LanguageEnum.DEUTSCH
  })
  
  const handleTab = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }
  
  const handleShowTermsModal = (status: boolean) => () => {
    setShowTermsModal(status)
  }
  
  return (
    <Box sx={rootSx}>
      <Can
        I="manage"
        a="contract"
      >
        <IconButton
          sx={editBtnSx}
          onClick={handleShowTermsModal(true)}
        >
          <EditIcon/>
        </IconButton>
      </Can>
      <Box sx={titleSx}>
        <Typography variant="subtitle1">
          <FormattedMessage id="termsOfContract" />
        </Typography>
      </Box>
      <Box>
        <Tabs
          value={tab}
          onChange={handleTab}
          sx={tabsSx}
        >
          <Tab
            sx={tabSx}
            label={intl.formatMessage({id: 'english'})}
          />
          <Tab
            sx={tabSx}
            label={intl.formatMessage({id: 'french'})}
          />
          <Tab
            sx={tabSx}
            label={intl.formatMessage({id: 'german'})}
          />
        </Tabs>
      </Box>
      <TabPanel
        value={tab}
        index={0}
      >
        <TermsTab
          businessId={data?.id}
          language={LanguageEnum.ENGLISH}
        />
      </TabPanel>
      <TabPanel
        value={tab}
        index={1}
      >
        <TermsTab
          businessId={data?.id}
          language={LanguageEnum.FRENCH}
        />
      </TabPanel>
      <TabPanel
        value={tab}
        index={2}
      >
        <TermsTab
          businessId={data?.id}
          language={LanguageEnum.DEUTSCH}
        />
      </TabPanel>
      <AMPModal
        handleClose={handleShowTermsModal(false)}
        open={showTermsModal}
        title={intl.formatMessage({ id: "termsOfContract" })}
        type="md"
        fullWidth
        withoutPaddings
      >
        <ContractTermsForm
          closeModal={handleShowTermsModal(false)}
          frenchInit={{
            contractTerms: frenchDocument?.data?.contractTerms ?? "",
            language: frenchDocument?.data?.language ?? LanguageEnum.FRENCH,
            termsLink: frenchDocument?.data?.termsLink ?? ""
          }}
          englishInit={{
            contractTerms: englishDocument?.data?.contractTerms ?? "",
            language: englishDocument?.data?.language ?? LanguageEnum.ENGLISH,
            termsLink: englishDocument?.data?.termsLink ?? ""
          }}
          germanInit={{
            contractTerms: germanDocument?.data?.contractTerms ?? "",
            language: germanDocument?.data?.language ?? LanguageEnum.DEUTSCH,
            termsLink: germanDocument?.data?.termsLink ?? ""
          }}
          submit={
            submitTerms(
              setTerms,
              dispatch,
              data?.id,
              handleShowTermsModal(false)
            )}
        />
      </AMPModal>
    </Box>
  )
}
