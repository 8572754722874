import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const actionsWrapperSx: SxProps<Theme> = {
  marginBottom: theme.spacing(15/8),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 1
}
export const searchInputSx: SxProps<Theme> = {
  maxWidth: theme.spacing(250/8),
  width: '100%',
  background: theme.palette.background.default,
  '&.MuiOutlinedInput-root': {
    paddingLeft: theme.spacing(8/8),
  },
  '& svg': {
    fill: theme.palette.neutral[400]
  },
  '& .MuiInputBase-input': {
    fontSize: theme.spacing(12/8),
    padding: '8px',
    paddingLeft: 0,
    '&:placeholder': {
      color: theme.palette.neutral[400]
    }
  }
}
export const filterWrapperSx: SxProps<Theme> = {
  position: 'relative',
}
export const filterButtonTextSx: SxProps<Theme> = {
  [theme.breakpoints.down('md')]: {
    display: "none"
  }
}
export const filterButtonSx: SxProps<Theme> = {
  '&.MuiIconButton-root': {
    background: theme.palette.background.default,
    border: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.spacing(4/8),
    [theme.breakpoints.down('lg')]: {
      height: '2rem'
    }
  }
}
export const formWrapperSx: SxProps<Theme> = {
  '& .MuiPaper-root': {
    maxWidth: theme.spacing(300/8),
    width: '100%',
  }
}
export const filterIconActiveSx: SxProps<Theme> = {
  '&.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium': {
    fill: theme.palette.primary.main
  }
}
