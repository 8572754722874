import { SxProps, Theme } from "@mui/material";
import theme from "../../../../theme";

export const MarkdownSx: SxProps<Theme> = {
  fontSize: theme.spacing(12/8),
  '& ol li': {
    listStyle: "auto"
  },
  '& ul li': {
    listStyle: 'disc'
  }
}
export const linkStackSx: SxProps<Theme> = {
  flexDirection: "row",
  alignItems: "flex-end",
  gap: 1,
  pt: 3
}
