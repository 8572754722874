import { SxProps, Theme } from "@mui/material"
import theme from "src/theme"

export const wrapperSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
}
export const signInSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  '& a': {
    marginLeft: theme.spacing(4/8)
  }
}
export const welcomeBlockSx: SxProps<Theme> = {
  marginBottom: theme.spacing(60/8),
  [theme.breakpoints.down('lg')]:{
    marginBottom: theme.spacing(45/8),
  },
  [theme.breakpoints.down('md')]:{
    marginTop: theme.spacing(30/8),
    marginBottom: theme.spacing(30/8),
  }
}

export const langSelectWrapperSx: SxProps<Theme> = {
  [theme.breakpoints.down('md')]:{
    display: 'none'
  }
}