import { SxProps, Theme } from "@mui/material";
import { ConnectorStatusEnum } from "src/enums";
import theme from "src/theme";

export const wrapperSx: SxProps<Theme> = {
  borderRadius: theme.spacing(8/8),
  background: theme.palette.background.default,
  marginTop: theme.spacing(20/8),
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    marginTop: 0
  }
}

export const progressBarWrapperSx: SxProps<Theme> = {
  padding: theme.spacing(25/8, 10/8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(20/8),
  textAlign: 'center',
  borderWidth: theme.spacing(1/8),
  borderColor: theme.palette.neutral[300],
  borderRightStyle: 'solid',
  borderBottomStyle: 'solid'

}

export const statusButtonSx = (status: string) => ({
  borderRadius: theme.spacing(50/8),
  background: status === ConnectorStatusEnum.CHARGING ? theme.palette.brand.light : theme.palette.neutral[300],
  '&:hover': {
    background: theme.palette.brand.main
  }
})

export const infoWrapperSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(25/8, 10/8),
  borderBottom: `${theme.spacing(1/8)} solid ${theme.palette.neutral[300]}`,
  textAlign: 'center',
  '& .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(5/8)
  }
}

export const connectorsWrapperSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(25/8, 10/8),
}
