import { Check, Info } from "@mui/icons-material";
import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import { FC, MouseEvent, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AvailableParam } from "src/components/common/AvailableParam";
import theme from "src/theme";
import { ProceedCurrenciesEnum } from "src/utils/consts";
import {
  cardButtonSx,
  cardWrapperSx,
  descriptionBoxSx,
  descriptionSx,
  monthlyFeeSx,
  popoverSx,
  tariffParamsSx,
  tariffTitleSx,
  totalSx
} from "./TariffCard.sx";
import { TariffCardType, TextMapType } from "./TariffCard.types";
import ReactMarkdown from "react-markdown";
import { useAppSelector } from "src/app/hooks";
import { LanguageShortEnum } from "../../../enums";
import { fixedDigits } from "src/utils/fixedDigits";

export const TariffCard: FC<TariffCardType> = ({ data, selected, radioButton, handleSelectCard, language }) => {
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl)
  const { lang } = useAppSelector(state => state.common)
  const handleSelect = () => {
    handleSelectCard && handleSelectCard(data.id)
  }
  
  const textMap:TextMapType = {
    [LanguageShortEnum.EN]: {
      title: data?.name,
      description: data?.description
    },
    [LanguageShortEnum.FR]: {
      title: data?.nameFrench,
      description: data?.descriptionFrench
    },
    [LanguageShortEnum.DE]: {
      title: data?.nameDeutsch,
      description: data?.descriptionDeutsch
    },
  }

  return (
    <>
      {data && (
        <Box sx={cardWrapperSx(selected || false)}>
          <Box>
            <Typography
              variant='h2'
              color="brand.main"
              lineHeight='1'
              textAlign='center'
              sx={tariffTitleSx}
            >
              {textMap[lang].title}
            </Typography>
            <Typography
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              color="neutral[600]"
              sx={descriptionSx}
            >
              <FormattedMessage id='tariffDescription' />
              <IconButton
                onMouseEnter={(e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
              >
                <Info />
              </IconButton>
            </Typography>
            <Popover
              id="mouse-over-popover"
              sx={popoverSx}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={() => setAnchorEl(null)}
              disableRestoreFocus
            >
              <Box
                component="div"
                sx={descriptionBoxSx}
              >
                <ReactMarkdown>
                  {textMap[lang].description}
                </ReactMarkdown>
              </Box>
            </Popover>
            <Box sx={monthlyFeeSx}>
              <Typography
                variant="caption"
                color="brand.main"
                fontWeight={600}
              >
                {ProceedCurrenciesEnum[data.currency]}
              </Typography>
              <Typography
                variant="h1"
                color="brand.main"
                lineHeight='1'
                sx={totalSx}
              >
                {data.monthlyFee / 100}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.neutral[600],
                  lineHeight: "1.5"
                }}
              >
                <FormattedMessage id="monthlyFeeDescription"
                  values={{ br: <br /> }} />
              </Typography>
            </Box>
          </Box>
          <Box sx={tariffParamsSx}>
            <AvailableParam
              label={intl.formatMessage({ id: 'annualWhLimit' })}
              value={`${(data.annualWhLimit / 1000).toFixed(0)} ${intl.formatMessage({ id: 'kwh' })}`}
            />
            <AvailableParam
              label={intl.formatMessage({ id: 'monthlyFeeWithoutCurrency' })}
              value={`${ProceedCurrenciesEnum[data.currency]} ${data.monthlyFee / 100}`}
            />
            <AvailableParam
              label={intl.formatMessage({ id: 'costPerKwh' })}
              value={`${ProceedCurrenciesEnum[data.currency]} ${fixedDigits(data.pricePerKWh, 10000)}`}
            />
            <AvailableParam
              label={intl.formatMessage({ id: 'minDuration' })}
              value={`${intl.formatMessage({ id: "monthsTariff" }, {count: data.contractPeriodLimit})}`}
            />
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.neutral[400]
              }}
            >
              <FormattedMessage id="exceedingTheAnnualLimit" />
            </Typography>
          </Box>
          {radioButton &&
            <Button
              sx={cardButtonSx(selected || false)}
              onClick={handleSelect}
            >
              {selected && <Check />}
              <Typography>
                <FormattedMessage id={selected ? 'selected' : 'select'} />
              </Typography>
            </Button>
          }
        </Box>
      )}
    </>
  );
};
