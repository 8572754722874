import { useEffect, ReactNode, FC } from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import dayjs from 'dayjs';
import messagesEn from '../../lang/en.json';
import messageDe from '../../lang/de.json';
import messagesFr from '../../lang/fr.json';
import { useAppSelector } from '../../app/hooks';

interface AppIntlProviderProps {
  children: ReactNode
}

interface LocaleProps {
  [key: string]: string
}

interface MessagesProps {
  [key: string]: LocaleProps
}

const messages: MessagesProps = {
  de: messageDe,
  fr: messagesFr,
  en: messagesEn
};

const AppIntlProvider: FC<AppIntlProviderProps> = ({
  children
}) => {
  // catch locale
  const { lang } = useAppSelector((state) => state.common);

  // dictionary keys with translation

  useEffect(() => {
    document.documentElement.lang = lang;
    
    dayjs.locale(lang);
  }, [lang]);

  return (
    <IntlProvider
      locale={lang}
      messages={messages[lang] ?? messages.en}
      defaultLocale="en"
    >
      {children}
    </IntlProvider>
  );
};

export default AppIntlProvider;
