import { alpha, SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const detailsWrapperSx: SxProps<Theme> = {
  position: "relative",
  borderRadius: theme.spacing(4/8),
  background: theme.palette.background.default,
  overflow: 'hidden',
  height: '100%'
}
export const detailsInnerSx: SxProps<Theme> = {
  padding: theme.spacing(24/8),
}
export const detailsTitleSx: SxProps<Theme> ={
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(8/8)
}
export const detailItemSx: SxProps<Theme> = {
  '&.MuiGrid-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(8/8)
  }
}
export const imageWrapperSx: SxProps<Theme> = {
  maxWidth: theme.spacing(215/8),
  maxHeight: theme.spacing(220/8),
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    minHeight: theme.spacing(215/8)
  }
}
export const imageInnerSx: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  position: 'relative',
  maxWidth: '300px',
  '& img':{
    objectFit: 'contain',
    position: 'absolute',
    inset: 0,
    zIndex: 0,
    width: '100%',
    height: '100%'
  }
}
export const buttonImageWrapperSx: SxProps<Theme> = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  padding: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 1,
  background: alpha(theme.palette.bg.dark, 0.2),
  transition: '0.3s ease'
}
export const imageButtonSx: SxProps<Theme> = {
  zIndex: 1,
  background: theme.palette.neutral[100],
  opacity: 0.3,
  '&:hover': {
    opacity: 1,
    background: theme.palette.neutral[100]
  }
}

export const buttonImageWrapper: SxProps<Theme> = {
  position: 'absolute',
  width: theme.spacing(20/8),
  height: theme.spacing(20/8),
  top: theme.spacing(10/8),
  right: theme.spacing(20/8),
  opacity: 0.7,
  transition: '0.3s ease',
  '&:hover': {
    opacity: 1
  }
}

export const imageButton: SxProps<Theme> = {
  background: "transparent",
}

export const deleteModalContentSx = {
  root: {
    alignItems: "center",
    py: 5,
    px: 5
  },
  text: {
    fontSize: '1em',
    textAlign: "center",
    pt: 3,
    maxWidth: "460px",
    width: "100%"
  },
  actions: {
    padding: theme.spacing(20/8, 24/8),
    borderTop: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`,
    background: theme.palette.neutral[50],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(20/8)
  },
}
export const bexioCell = {
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(10/8)
}
