import { Box } from "@mui/material";
import { FC } from "react";
import { ConfigurationsItem } from "../ConfigurationsItem";
import { wrapperSx } from "./ConfigurationsList.sx";
import { ConfigurationsListType } from "./ConfigurationsList.types";

export const ConfigurationsList: FC<ConfigurationsListType> = ({data}) => {
  return (
    <Box sx={wrapperSx}>
      {data.map(config => (
        <ConfigurationsItem
          key={config.key}
          data={config}
        />
      ))}
    </Box>
  )
}
