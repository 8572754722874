import { FilterList, Search } from "@mui/icons-material";
import {
  Box,
  debounce,
  IconButton,
  OutlinedInput,
  Popover,
  Typography,
  useTheme
} from "@mui/material";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { businessesApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { LocationFilterForm } from "src/components/locations/LocationFilterForm";
import { RoleEnum } from "src/enums";
import ability from "src/permissions/ability";
import { actionsWrapperSx, filterButtonSx, filterWrapperSx, formWrapperSx, searchInputSx, filterIconActiveSx } from "./LocationsTableActions.sx";
import { LocationsTableActionsType } from "./LocationsTableActions.types";

export const LocationsTableActions: FC<LocationsTableActionsType> = ({ handleSearch, handleFilter }) => {
  const intl = useIntl()
  const [activeCountry, setActiveCountry] = useState<string>('')
  const [activeCity, setActiveCity] = useState<string>('')
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const [activeBusiness, setActiveBusiness] = useState<number>(0)

  const { roles } = useAppSelector(state => state.common.authInfo)
  const isSuperAdmin = roles[0].role === RoleEnum.SUPER_ADMIN
  const [getBusinesses, businesses] = businessesApi.useLazyGetBusinessesQuery()

  const getData = async () => {
    await getBusinesses({})
  }
  
  useEffect(() => {
    isSuperAdmin && getData()
  }, [])

  const processedBusinesses = useMemo(() => {
    if(!isSuperAdmin){
      return []
    }
    return businesses.data?.content && businesses.data.content.map((business) => ({
      id: business.id,
      value: String(business.id),
      name: business.name
    }))
  }, [businesses])

  const filterStatus = (value: boolean) => {
    setIsFilterActive(value)
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };  
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSearchFieldChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    debounce(() => handleSearch(event.target.value), 1000)()
  }
  return (
    <Box
      sx={actionsWrapperSx}
    >
      <OutlinedInput
        sx={searchInputSx}
        color="secondary"
        type="text"
        placeholder={intl.formatMessage({ id: 'locationSearchFieldPlaceholder' })}
        name="search"
        onChange={handleSearchFieldChange}
        startAdornment={(<Search />)}
      />
      <Box
        sx={filterWrapperSx}
      >
        <IconButton
          sx={filterButtonSx}
          onClick={handleClick}
        >
          {!isFilterActive && <FilterList /> }
          {isFilterActive && <FilterList
            sx={filterIconActiveSx}
          />}
          <Typography variant='body1'>
            <FormattedMessage id='filter' />
          </Typography>
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={formWrapperSx}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <LocationFilterForm
            businesses={processedBusinesses ?? []}
            handleFilter={handleFilter}
            isFilterActive={filterStatus}
            handleClose={handleClose}
            activeCountry={activeCountry}
            activeCity={activeCity}
            setActiveCountry={(value: string) => setActiveCountry(value)}
            setActiveCity={(value: string) => setActiveCity(value)}
            activeBusiness={activeBusiness}
            setActiveBusiness={setActiveBusiness}
          />
        </Popover>
      </Box>
    </Box>
  )
}
