import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const rootSx = (modal:boolean): SxProps => ({
  paddingRight: 0,
  width: '100%',
  padding: !modal ? theme.spacing(24/8) : 0,
  background: theme.palette.text.secondary,
  borderRadius: `${theme.spacing(8/8)} ${theme.spacing(8/8)} 0 0`,
  overflow: 'hidden'
})
export const titleSx = (modal:boolean): SxProps => ({
  marginBottom: !modal ? theme.spacing(10/8) : 0
})

export const headerSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
}

export const canvasWrapperSx = (modal:boolean, canvasHeight?: number): SxProps => ({
  '& .sigCanvas': {
    background: theme.palette.neutral[50],
    border: `${theme.spacing(2/8)} dashed ${theme.palette.neutral[400]}`,
    width: '100%',
    // maxWidth: theme.spacing(700/8),
    height: canvasHeight ? theme.spacing(canvasHeight/8) : theme.spacing(400/8),
  }
})

export const actionsWrapperSx: SxProps<Theme> = {
  padding: theme.spacing(20/8, 24/8),
  borderTop: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`,
  background: theme.palette.neutral[50],
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(20/8)
}

export const actionsActiveButtonsSx: SxProps<Theme> = {
  display: 'flex',
  gap: theme.spacing(10/8),
  alignItems: 'center'
}
