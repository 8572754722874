import { Person } from "@mui/icons-material";
import { Box } from "@mui/material";
import { FC } from "react";
import theme from "src/theme";

export const ClientCell: FC<{text: string}> = ({text}) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(5/8)
  }}>
    <Person sx={{fill: theme.palette.neutral[400]}}/>
    {text}
  </Box>
)
