import { Box, Grid } from "@mui/material";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { usersApi } from "src/api";
import { useAppSelector, useAppDispatch } from "src/app/hooks";
import { PageHeader } from "src/components/common/PageHeader";
import { TechnicalTeamDetails } from "src/components/technicalTeam/TechnicalTeamDetails";
import { UserDetails } from "src/components/users/UserDetails";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch, UserByIdRequest } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { gridElementSx } from "../UserInfo/UserInfo.sx";

type UserQueryTrigger = ReturnType<typeof usersApi.useLazyGetUserByIdQuery>[0];

const handleGetUserInfo = (trigger: UserQueryTrigger, dispatch: AppDispatch) => (async (data: UserByIdRequest) => {
  dispatch(preloaderChangeStatus(true))
  try {
    const response = await trigger(data).unwrap()
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
});

export const TechnicalTeamInfo: FC = () => {
  const params = useParams();
  const { authInfo, activeBusiness } = useAppSelector((state) => state.common);
	
  const dispatch = useAppDispatch()
  const [trigger, response] = usersApi.useLazyGetUserByIdQuery()

  useEffect(() => {
    (Object.keys(authInfo).length && params.id) && handleGetUserInfo(trigger, dispatch)({ userId: +params.id, businessId: activeBusiness })
  }, [])

  return (
    <Box>
      <PageHeader
        title={`${response.data?.firstName} ${response.data?.lastName}` ?? ''}
        onlyTitle
      />
      {response.data && <Grid
        container
        rowSpacing={{xs: 2}}
        columnSpacing={{xs: 0, md: 2}}
      >
        <Grid
          item
          xs={12}
          sx={gridElementSx}
        >
          <TechnicalTeamDetails
            data={response.data}
          />
        </Grid>
      </Grid>}
    </Box>
  )
}