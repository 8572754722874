import { SxProps, Theme } from "@mui/material";
import theme from "../../../../theme";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";

export const iconStackSx:SxProps<Theme> = {
  flexDirection: "row",
  alignItems: "center",
  gap: 0.5,
  maxWidth: theme.spacing(150/8)
}
export const idTextSx:SystemStyleObject<Theme> = {
  color: theme.palette.primary.main,
  textAlign: "left"
}
export const valueSx:SystemStyleObject<Theme> = {
  fontSize: theme.spacing(12/8),
  fontWeight: 400,
  textAlign: "end"
}
export const titleSx:SxProps<Theme> = {
  fontSize: theme.spacing(10/8),
  fontWeight: 500,
  color: theme.palette.neutral[400],
  textTransform: "uppercase",
  my: 1
}
export const rowSx:SxProps<Theme> = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between"
}
export const colSx:SxProps<Theme> = {
  alignItems: "flex-start",
  justifyContent: "flex-start"
}
