import { FC } from "react";

export const Apple:FC = () => {
  return (
    <svg width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3396 0.345338C12.8986 0.532457 12.3431 0.828398 12.1052 1.00313C10.9495 1.85173 10.0819 3.41076 10.0273 4.73711L9.99472 5.52776L10.6152 5.48677C12.5673 5.35775 14.4789 3.18029 14.618 0.927192C14.6856 -0.168098 14.6203 -0.197884 13.3396 0.345338ZM12.9261 6.03206C12.4149 6.17848 11.5973 6.45584 11.1089 6.64842L10.2214 6.99862L8.57937 6.42709C7.12469 5.92073 6.82469 5.86381 5.95013 5.9284C4.67949 6.02203 3.57529 6.54033 2.58219 7.50881C1.64658 8.42126 1.13838 9.357 0.768455 10.8486C0.407538 12.3037 0.410398 13.6529 0.777893 15.5129C1.31712 18.241 2.55131 20.7877 4.23249 22.6409C5.60866 24.158 6.51582 24.342 8.49328 23.5052C9.85116 22.9306 10.911 22.9477 12.6317 23.572C13.6658 23.9473 14.1122 24.0405 14.5395 23.97C15.7977 23.7625 16.5752 23.0822 17.9431 20.9917C18.6439 19.9208 19.4908 18.1516 19.5 17.7395C19.5021 17.6436 19.3736 17.5226 19.2144 17.4704C19.0553 17.4183 18.5502 17.0178 18.0921 16.5805C16.9494 15.4896 16.4629 14.3724 16.4628 12.8383C16.4625 11.212 16.873 10.2056 18.0191 9.02287L18.9188 8.0942L18.4095 7.51279C17.8079 6.8261 16.4523 6.1049 15.3733 5.89714C14.3482 5.69984 14.0237 5.71783 12.9261 6.03206Z"
        fill="#DFDFE8"/>
    </svg>
  )
}
