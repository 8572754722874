import { Settings } from "@mui/icons-material";
import { Grid, Divider, Box, Button } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { ocppApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { ResetTypeEnum } from "src/enums";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch, ChargePointDTO } from "src/types";
import { AppRouteSpace, AppRouteEnum, isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import {
  configWrapperSx,
  resetButtonsWrapperSx,
  resetButtonSx,
  configButtonSx,
  btnTextSx
} from "./ChargerResetWrapper.sx";
import { ChargerResetWrapperType } from "./ChargerResetWrapper.types";

type ResetTypeMutation = ReturnType<typeof ocppApi.useResetChargerMutation>[0]

const handleReset =  (trigger: ResetTypeMutation, resetType: ResetTypeEnum, data: ChargePointDTO, dispatch: AppDispatch) => async () => {
  try{
    dispatch(preloaderChangeStatus(true))
    await trigger({ businessId: data.business.id, chargerId: data.id, resetType})
  } catch (error){
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false))
  }
}

export const ChargerResetWrapper: FC<ChargerResetWrapperType> = ({ data }) => {

  const [trigger] = ocppApi.useResetChargerMutation();
  const dispatch = useAppDispatch()

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Box sx={configWrapperSx}>
          <Box sx={resetButtonsWrapperSx}>
            <Button
              sx={resetButtonSx}
              onClick={handleReset(trigger, ResetTypeEnum.SOFT, data, dispatch)}
            >
              <FormattedMessage id="softReset"/>
            </Button>
            <Button
              sx={resetButtonSx}
              onClick={handleReset(trigger, ResetTypeEnum.HARD, data, dispatch)}
            >
              <FormattedMessage id="hardReset"/>
            </Button>
          </Box>
          <Button
            sx={configButtonSx}
          >
            <NavLink to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.chargers, String(data.id), AppRouteEnum.configurations])}>
              <Settings/>
              <Box
                component="span"
                sx={btnTextSx}
              >
                <FormattedMessage id="configurations" />
              </Box>
            </NavLink>
          </Button>
        </Box>
      </Grid>
    </>
  )
}
