import { FC} from "react";
import { Stack } from "@mui/material";
import ability from "../../permissions/ability";
import { energyConsumptionReportSx } from "./EnergyConsumptionReport.sx";
import { ConsumptionBusiness } from "../../components/reports/ConsumptionReports/ConsumptionBusiness";
import { ConsumptionCity } from "../../components/reports/ConsumptionReports/ConsumptionCity";
import { ConsumptionLocation } from "../../components/reports/ConsumptionReports/ConsumptionLocation";

export const EnergyConsumptionReport:FC = () => {
  const canReadBusinessReports = ability.can('read', 'businessReports')
 
  return (
    <Stack sx={energyConsumptionReportSx.root}>
      {canReadBusinessReports && (
        <ConsumptionBusiness/>
      )}
      <ConsumptionCity/>
      <ConsumptionLocation/>
    </Stack>
  )
}
