import theme from "../../../../theme";
  
export const fileMenuSx = {
  menuBtn: {
    width: "100%"
  },
  menuBtnText: {
    width: "100%",
    textAlign: "start",
    fontSize: theme.spacing(14/8),
    fontWeight: 400,
    color: theme.palette.neutral[400],
  },
  iconBtn: {
    width: theme.spacing(20/8),
    height: theme.spacing(20/8),
    backgroundColor: "transparent"
  },
  popover: {
    '& .MuiPaper-root': {
      maxWidth: theme.spacing(200/8),
      width: '100%',
      p: 1
    }
  }
}
  