import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import { FC } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { tariffsApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { AppDispatch, store } from "src/app/store";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { TariffDTO } from "src/types";
import { AppRouteEnum, AppRouteSpace, isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type DeleteTariffMutationTrigger = ReturnType<typeof tariffsApi.useDeleteTariffMutation>[0]

const handleDeleteTariff = (trigger: DeleteTariffMutationTrigger, dispatch: AppDispatch, businessId: number, tariffId: number, navigate: NavigateFunction) => async () => {
  try{
    dispatch(preloaderChangeStatus(true))
    await trigger({ businessId, tariffId }).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    navigate(AppRouteSpace([AppRouteEnum.app, AppRouteEnum.tariffs]))
    store.dispatch(preloaderChangeStatus(false));
  }
}

export const DeleteTariff: FC<{row: TariffDTO}> = ({row}) => {
  const [trigger, response] = tariffsApi.useDeleteTariffMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <>
      <IconButton onClick={handleDeleteTariff(trigger, dispatch, row.business.id, row.id, navigate)}>
        <Delete />
      </IconButton>
    </>
  )
}
