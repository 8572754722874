import { ContentCopyOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import copy from "copy-to-clipboard";
import { FC } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/app/hooks";
import { setActiveBusiness } from "src/stores/common/commonSlice";
import { NotifySuccess } from "src/utils/notification";
import { TableIdCellWrapperSx, copyButtonSx } from "./TableIdCell.sx";
import { TableIdCellType } from "./TableIdCell.types";

export const TableIdCell: FC<TableIdCellType> = ({ link, label, withoutCopy = false, shownButton = false, businessId }) => {
  const intl = useIntl()
  
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const copyToClipboard = () => {
    copy(String(label), { debug: true })
    NotifySuccess(intl.formatMessage({id: 'copiedSuccesfully'}))
  }

  const handleLocate = () => {
    dispatch(setActiveBusiness(businessId))
    link && navigate(link, {
      state: {
        page: location.search.replace(/(\D+)(\d+)$/, '$2')
      }
    })
  }

  return (
    <Box sx={TableIdCellWrapperSx(withoutCopy)}>
      {!withoutCopy && (
        <IconButton
          onClick={copyToClipboard}
          sx={copyButtonSx}
        >
          <ContentCopyOutlined fontSize="small" />
        </IconButton>
      )}
      {link && (<Button onClick={handleLocate}>
        <Typography
          variant="button"
          color="primary.main"
        >
          {label}
        </Typography>
      </Button>)}
      {!link && (
        <Typography
          variant="button"
          color="textPrimary"
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};
