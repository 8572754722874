import { Box, Grid } from "@mui/material";
import { FC, useEffect, useMemo } from "react";
import { LocationStatisticType } from "./LocationStatistic.types";
import { MapZoomEnum } from "src/utils/consts";
import theme from "src/theme";
import { LocationStats } from "../LocationStats";
import { MapWrapper } from "src/components/map/MapWrapper";
import { locationsApi } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { handleGetLocations } from "../../../pages/Locations";

export const LocationStatistic: FC<LocationStatisticType> = ({
  filterParams
}) => {
  const [trigger, response] = locationsApi.useLazyGetLocationsQuery()
  const { authInfo } = useAppSelector((state) => state.common);
  const data = response?.data?.content ?? []
  const total = response?.data?.totalElements ?? 0
  const callHandler = handleGetLocations(trigger, authInfo.business && +authInfo.business.id)
  
  useEffect(() => {
    Object.keys(authInfo) && callHandler(filterParams, {page: 0, size: 2000})
  }, []);
  
  const marks = useMemo(() => {
    return data.map(location => ({
      lat: location.latitude,
      lng: location.longitude
    }))
  }, [data])

  return (
    <Box sx={{marginBottom: theme.spacing(20/8)}}>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{xs: 0, md: 2}}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{minHeight: 280}}
        >
          <MapWrapper
            zoom={MapZoomEnum.MIN}
            markers={marks}
            clusterization
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
        >
          <LocationStats
            totalLocations={total}
            data={data}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
