import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./TypographyWithCaption.styles";
import { TypographyWithCaptionType } from "./TypographyWithCaption.types";

export const TypographyWithCaption: FC<TypographyWithCaptionType> = ({ title, caption }) => {
  const classes = useStyles()
  return (
    <Box className={classes.wrapper}>
      <Typography
        variant="subtitle1"
        color="textPrimary"
      >
        <FormattedMessage id={title} />
      </Typography>
      <Box className={classes.caption}>
        <Typography
          variant="body2"
        >
          <FormattedMessage id={caption} />
        </Typography>
      </Box>
    </Box>
  )
}
