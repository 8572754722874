import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { businessesApi, reportsApi } from "../../api";
import { store } from "../../app/store";
import { preloaderChangeStatus } from "../../stores/common/commonSlice";
import { useAppSelector } from "../../app/hooks";
import ability from "../../permissions/ability";
import { UsersChart } from "../../components/reports/UsersReports/UsersChart";
import { AutocompleteValue } from "../../types";
import { RegisteredUserCount } from "../../types/reports";

type UsersReportQueryTrigger = ReturnType<typeof reportsApi.useLazyGetUsersReportQuery>[0];
type BusinessesQueryTrigger = ReturnType<typeof businessesApi.useLazyGetBusinessesQuery>[0];

const getUsersData = async (
  getUsersReports: UsersReportQueryTrigger,
  business: number,
  registeredFrom: number | undefined,
  registeredTo: number | undefined
) => {
  store.dispatch(preloaderChangeStatus(true))
  try {
    const businessId = business === -1 ? undefined : business
    return await getUsersReports({
      businessId,
      registeredFrom,
      registeredTo
    })
  } catch (error) {
    console.error(error)
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

const getBusinessesData = async (trigger: BusinessesQueryTrigger) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    await trigger({
      params: {
        size: 2000
      }
    })
  } catch (e) {
    console.error(e)
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

export const UsersReport:FC = () => {
  const intl = useIntl()
  const { authInfo } = useAppSelector((state) => state.common);
  const isSuperAdmin = ability.can('read', 'businessReports')
  const [currentBusiness, setCurrentBusiness] = useState<number | undefined>(undefined)
  const [getBusinesses, businesses] = businessesApi.useLazyGetBusinessesQuery()
  const [getUsersReportCurrent] = reportsApi.useLazyGetUsersReportQuery()
  const [currentYearData, setCurrentYearData] = useState<RegisteredUserCount[] | undefined>([])
  const [lastYearData, setLastYearData] = useState<RegisteredUserCount[] | undefined>([])
  
  const getData = () => {
    getUsersData(
      getUsersReportCurrent,
      authInfo?.business?.id ?? currentBusiness,
      dayjs().subtract(2, 'years').valueOf(),
      dayjs().subtract(1, 'year').valueOf())
      .then(res =>  setLastYearData(res?.data?.registeredUserCounts))
    getUsersData(
      getUsersReportCurrent,
      authInfo?.business?.id ?? currentBusiness,
      dayjs().subtract(1, 'year').valueOf(),
      dayjs().valueOf())
      .then(res => setCurrentYearData(res?.data?.registeredUserCounts))
  }
  
  useEffect(() => {
    if (isSuperAdmin) {
      getBusinessesData(getBusinesses)
    }
    getData()
  }, []);
  
  useEffect(() => {
    getData()
  }, [currentBusiness])
  
  const lastYearTitle = `${intl.formatMessage({ id: "usersLast" }, { year: dayjs().subtract(1, 'year').format('YYYY') })}`
  const currentYearTitle = `${intl.formatMessage({ id: "usersCurrent" }, { year: dayjs().format('YYYY') })}`
  
  const chartData = currentYearData?.map((item, i) => ({
    name: intl.formatMessage({ id: item.month.toLowerCase() }),
    [currentYearTitle]: item.registeredUsersCount ?? 0,
    [lastYearTitle]: lastYearData?.[i]?.registeredUsersCount ?? 0
  })) ?? []
  
  const handleBusinessChange = (id: number) => {
    setCurrentBusiness(id)
  }
  
  const processedBusinesses  = ():AutocompleteValue[] => {
    const allBusinesses = {
      id: -1,
      label: intl.formatMessage({ id: "allBusinesses" }),
      image: "undefined"
    }
    if (businesses?.data?.content) {
      return [allBusinesses].concat(businesses?.data?.content?.map(item => ({
        id: item?.id,
        label: item?.name,
        image: "undefined"
      })))
    }
    return []
  }
  
  return (
    <Box sx={{ py: 3 }}>
      <UsersChart
        data={chartData}
        dataKeyLast={lastYearTitle}
        dataKeyCurrent={currentYearTitle}
        currentBusiness={currentBusiness}
        businessesData={processedBusinesses()}
        handleBusinessChange={handleBusinessChange}
      />
    </Box>
  )
}
