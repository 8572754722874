import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { AmpersIcon } from "src/components/icons/AmpersIcon";
import { rootSx, sectionSx, sectionTypographySx } from "./BusinessStatistics.sx";
import CountUp from 'react-countup';
import { BusinessStatisticsType } from "./BusinessStatistics.types";

export const BusinessStatistics: FC<BusinessStatisticsType> = ({locations, chargers, contracts, users}) => {

  return (
    <Box sx={rootSx}>
      <Grid
        container
        spacing={2}
      >
        <Grid 
          item
          xs={12}
          sm={6}
          md={3}
          sx={sectionSx}
        >
          <AmpersIcon />
          <Box
            sx={sectionTypographySx}
          >

            <Typography
              variant="h3"
              lineHeight="1.2"
            >
              <CountUp 
                start={0}
                end={locations}
                duration={1}
              />
            </Typography>
            <FormattedMessage id={'locations'} />
          </Box>
        </Grid>
        <Grid 
          item
          xs={12}
          sm={6}
          md={3}
          sx={sectionSx}
        >
          <AmpersIcon />
          <Box
            sx={sectionTypographySx}
          >

            <Typography
              variant="h3"
              lineHeight="1.2"
            >
              <CountUp 
                start={0}
                end={chargers}
                duration={1}
              />
            </Typography>
            <FormattedMessage id={'chargers'} />
          </Box>
        </Grid>
        <Grid 
          item
          xs={12}
          sm={6}
          md={3}
          sx={sectionSx}
        >
          <AmpersIcon />
          <Box
            sx={sectionTypographySx}
          >

            <Typography
              variant="h3"
              lineHeight="1.2"
            >
              <CountUp 
                start={0}
                end={contracts}
                duration={1}
              />
            </Typography>
            <FormattedMessage id={'contracts'} />
          </Box>
        </Grid>
        <Grid 
          item
          xs={12}
          sm={6}
          md={3}
          sx={sectionSx}
        >
          <AmpersIcon />
          <Box
            sx={sectionTypographySx}
          >

            <Typography
              variant="h3"
              lineHeight="1.2"
            >
              <CountUp 
                start={0}
                end={users}
                duration={1}
              />
            </Typography>
            <FormattedMessage id={'users'} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
