import { SxProps, Theme } from "@mui/material";

export const wrapperSx:SxProps<Theme> = {
  display: 'flex'
}

export const btnSx:SxProps<Theme> = {
  py: 0,
  px: 1
}
