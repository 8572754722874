import { FC, useEffect, useMemo, useState } from "react";
import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { Form, Formik, FormikConfig, FormikHandlers, FormikProps } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { tariffsApi } from "src/api";
import { tariffInitialValues } from "../formData/common/initialData";
import { AMPInput } from "src/components/common/AMPInput";
import { getHelperText } from "src/utils/forms";
import {
  useAppDispatch, useAppSelector,
} from "src/app/hooks";
import { SubmitButton } from "src/components/common/SubmitButton";
import { TariffFormType } from "./TariffForm.types";
import { AppDispatch, AutocompleteValue, SelectValue } from "src/types";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { tariffSchema, TariffType } from "../formData/tariff";
import { AMPSwitcher } from "src/components/common/AMPSwitcher";
import { CurrencyEnum, RoleEnum } from "src/enums";
import { CancelButton } from "src/components/common/CancelButton";
import { AMPMarkdownEditor } from "../../common/AMPMarkdownEditor";
import { actionsWrapperSx, formSx, sectionTitleSx, subtitleWrapperSx } from "./TariffForm.sx";
import { findAutocompleteValueById, isErrorObject } from "src/utils/helper";
import { AMPAutocomplete } from "src/components/common/AMPAutocomplete";
import { AMPAutocompleteProps } from "src/components/common/AMPAutocomplete/AMPAutocomplete.types";
import { NotifyError } from "src/utils/notification";
import { systemApi } from "../../../api";
import { handleGetCountries } from "../../../hooks/useGetCountries";
import { AMPFormSelect } from "../../common/AMPFormSelect";
import { allRegionsValue } from "../../../utils/consts";

const minimumLimitData = [
  {
    id: 0,
    name: "NO",
    value: ''
  },
  {
    id: 1,
    name: "YES",
    value: 'true'
  }
]

type AddTariffMutationTrigger = ReturnType<typeof tariffsApi.useAddTariffMutation>[0]

const handleSubmit = (trigger: AddTariffMutationTrigger, dispatch: AppDispatch, handleClose: () => void, businessId: number): FormikConfig<TariffType>['onSubmit'] => (async (values) => {
  const data = {
    ...values,
    country: values.country?.length ? values.country : undefined,
    region: (values.region?.length && values.region !== allRegionsValue) ? values.region : undefined,
    businessId: businessId,
    monthlyFee: values.monthlyFee * 100,
    pricePerKWh: Math.round(values.pricePerKWh * 10000),
    annualWhLimit: values.annualWhLimit * 1000
  }
  try {
    dispatch(preloaderChangeStatus(true))
    await trigger(data).unwrap()
    handleClose()
    
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
});

export const TariffForm: FC<TariffFormType> = ({
  handleClose,
  authInfo,
  businesses,
}) => {
  const intl = useIntl();

  const [trigger] = tariffsApi.useAddTariffMutation();
  const { lang } = useAppSelector((state) => state.common)

  const [minimumLimit, setMinimumLimit] = useState<string>('') 
  const isSuperAdmin = authInfo.roles[0].role === RoleEnum.SUPER_ADMIN
  const [getCountries, countries] = systemApi.useLazyGetCountriesQuery()
  const dispatch = useAppDispatch()
  const [activeCountry, setActiveCountry] = useState<string>('')
  
  useEffect(() => {
    handleGetCountries(getCountries, dispatch)
  }, [])
  
  const processedRegions = useMemo(() => {
    const initialArray: SelectValue[] = [{
      id: 0,
      name: activeCountry.length ? intl.formatMessage({id: activeCountry.toLowerCase()}) : '',
      value: allRegionsValue
    }]
    if (activeCountry.length) {
      const mappedRegions: SelectValue[] | undefined = countries.data?.find(country => country.name === activeCountry)?.
        regions?.map(region => ({
          id: region.id,
          name: intl.formatMessage({id: region.name}),
          value: region.name
        }))
      return  mappedRegions?.concat(initialArray[0])
        ?? initialArray
    }
    return initialArray
  }, [activeCountry, lang])
  
  const handleChangeCountry = (
    handleChange: FormikHandlers["handleChange"],
    setFieldValue: FormikProps<TariffType>['setFieldValue']
  ) => (event: SelectChangeEvent) => {
    setActiveCountry(event.target.value);
    setFieldValue('region', '')
    return handleChange(event);
  };
  
  const processedCountries = countries?.data?.map(country => ({
    id: country.id,
    name: intl.formatMessage({id: country.name.toLowerCase()}),
    value: country.name
  })) ?? []
  

  const processedCurrencies = () => {
    return Object.values(CurrencyEnum).map((value, index) => ({
      id: index,
      name: intl.formatMessage({id: value}),
      value: value
    }))
  }

  const handleChangeBusinessValue = (setFieldValue: FormikProps<TariffType>['setFieldValue'], fieldName: string): AMPAutocompleteProps['onChange'] =>
    (e, newValue) => {
      if(newValue){
        setFieldValue(fieldName, newValue.id)
      }
    }

  const processedBusinesses = ():AutocompleteValue[] => {
    if (businesses) {
      return businesses.map(item => ({
        id: item?.id,
        label: item?.name,
        image: item?.logo?.url ?? ""
      }))
    }
    return []
  }

  const getInitialsValue = () => {
    if(!isSuperAdmin){
      return {
        ...tariffInitialValues,
        businessId: authInfo.business.id
      } 
    }
    return tariffInitialValues
  }

  return (
    <Formik
      initialValues={getInitialsValue()}
      validationSchema={tariffSchema}
      onSubmit={handleSubmit(
        trigger,
        dispatch,
        handleClose,
        +authInfo.business?.id,
      )}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }: FormikProps<TariffType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box
            sx={formSx}
          >
            <Box>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="generalInfo" />
              </Typography>
              {isSuperAdmin && <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPAutocomplete
                    options={processedBusinesses()}
                    value={findAutocompleteValueById(processedBusinesses(), +values['businessId'])}
                    label={'business'}
                    input={'businessId'}
                    disabled={!isSuperAdmin}
                    showAvatar
                    onChange={handleChangeBusinessValue(setFieldValue, 'businessId')}
                    onBlur={handleBlur}
                    hasError={touched['businessId'] && Boolean(errors['businessId'])}
                    helperText={getHelperText('businessId', touched, errors, intl)}
                  />
                </Grid>
              </Grid>}
              <Grid
                container
                spacing={2}
              >
                <Grid
                  md={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={processedCountries}
                    value={values['country'] as string}
                    label={'country'}
                    input={'country'}
                    onChange={handleChangeCountry(handleChange, setFieldValue)}
                    onBlur={handleBlur}
                    hasError={touched['country'] && Boolean(errors['country'])}
                    helperText={getHelperText('country', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  md={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={processedRegions}
                    value={values['region'] as string}
                    label={'region'}
                    input={'region'}
                    disabled={!activeCountry.length}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['region'] && Boolean(errors['region'])}
                    helperText={getHelperText('region', touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <Typography sx={sectionTitleSx}>{intl.formatMessage({id: "englishVersion"})}</Typography>
                  <AMPInput
                    value={values["name"]}
                    type={"text"}
                    label={"name"}
                    input={"name"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["name"] && Boolean(errors["name"])
                    }
                    helperText={getHelperText(
                      "name",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
              </Grid>
              <AMPMarkdownEditor
                input="description"
                value={values["description"]}
                label="tariffDescription"
                height={200}
                setValue={setFieldValue}
                onBlur={handleBlur}
                hasError={
                  touched["description"] && Boolean(errors["description"])
                }
                helperText={getHelperText(
                  "description",
                  touched,
                  errors,
                  intl
                )}
              />
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <Typography
                    sx={sectionTitleSx}
                  >
                    {intl.formatMessage({id: "frenchVersion"})}
                  </Typography>
                  <AMPInput
                    value={values["nameFrench"]}
                    type={"text"}
                    label={"name"}
                    input={"nameFrench"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["nameFrench"] && Boolean(errors["nameFrench"])
                    }
                    helperText={getHelperText(
                      "nameFrench",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
              </Grid>
              <AMPMarkdownEditor
                input="descriptionFrench"
                value={values["descriptionFrench"]}
                label="tariffDescription"
                height={200}
                setValue={setFieldValue}
                onBlur={handleBlur}
                hasError={
                  touched["descriptionFrench"] && Boolean(errors["descriptionFrench"])
                }
                helperText={getHelperText(
                  "descriptionFrench",
                  touched,
                  errors,
                  intl
                )}
              />
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <Typography sx={sectionTitleSx}>{intl.formatMessage({id: "germanVersion"})}</Typography>
                  <AMPInput
                    value={values["nameDeutsch"]}
                    type={"text"}
                    label={"name"}
                    input={"nameDeutsch"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["nameDeutsch"] && Boolean(errors["nameDeutsch"])
                    }
                    helperText={getHelperText(
                      "nameDeutsch",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
              </Grid>
              <AMPMarkdownEditor
                input="descriptionDeutsch"
                value={values["descriptionDeutsch"]}
                label="tariffDescription"
                height={200}
                setValue={setFieldValue}
                onBlur={handleBlur}
                hasError={
                  touched["descriptionDeutsch"] && Boolean(errors["descriptionDeutsch"])
                }
                helperText={getHelperText(
                  "descriptionDeutsch",
                  touched,
                  errors,
                  intl
                )}
              />
            </Box>
            <Box>
              <Box sx={subtitleWrapperSx}>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                >
                  <FormattedMessage id="billing" />
                </Typography>
                <Typography
                  variant="caption"
                >
                  (<FormattedMessage id="vat" />)
                </Typography>
              </Box>
              <AMPSwitcher
                value={values["currency"]}
                input={"currency"}
                label={"currency"}
                data={processedCurrencies()}
                onChange={handleChange}  
              />
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    value={values["annualWhLimit"]}
                    type={"number"}
                    label={"annualWhLimit"}
                    input={"annualWhLimit"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["annualWhLimit"] && Boolean(errors["annualWhLimit"])
                    }
                    helperText={getHelperText(
                      "annualWhLimit",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    value={values["pricePerKWh"]}
                    labelCaption={values["currency"]}
                    type={"number"}
                    label={"pricePerKWh"}
                    input={"pricePerKWh"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["pricePerKWh"] && Boolean(errors["pricePerKWh"])
                    }
                    helperText={getHelperText(
                      "pricePerKWh",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    value={values["monthlyFee"]}
                    labelCaption={values["currency"]}
                    type={"number"}
                    label={"monthlyFee"}
                    input={"monthlyFee"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["monthlyFee"] && Boolean(errors["monthlyFee"])
                    }
                    helperText={getHelperText(
                      "monthlyFee",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
              </Grid>
              <AMPSwitcher
                value={minimumLimit}
                input={"doesHaveMinimumLimit"}
                label={"doesHaveMinimumLimit"}
                data={minimumLimitData}
                onChange={(e: React.ChangeEvent, value: string) => setMinimumLimit(value)}  
              />
              {Boolean(minimumLimit) && (
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    xs={12}
                    lg={6}
                    item
                  >
                    <AMPInput
                      value={values["contractPeriodLimit"]}
                      type={"number"}
                      label={"numberOfMonth"}
                      input={"contractPeriodLimit"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={
                        touched["contractPeriodLimit"] && Boolean(errors["contractPeriodLimit"])
                      }
                      helperText={getHelperText(
                        "contractPeriodLimit",
                        touched,
                        errors,
                        intl
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
          <Box sx={actionsWrapperSx}>
            <CancelButton
              text="cancel"
              handler={handleClose}
            />
            <SubmitButton
              text={"createTariff"}
              color="primary"
              isSubmitting={isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};
