import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FC } from "react";
import { chargersApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { AppDispatch } from "src/app/store";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { ChargePointDTO } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type DeleteChargerMutationTrigger = ReturnType<typeof chargersApi.useDeleteChargePointMutation>[0]

const handleDeleteCharger = (trigger: DeleteChargerMutationTrigger, dispatch: AppDispatch, businessId: number, chargerId: number) => async () => {
  dispatch(preloaderChangeStatus(true))
  try{
    await trigger({ businessId, chargerId }).unwrap()
    
  } catch(error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false))
  }
}

export const DeleteCharger: FC<{row: ChargePointDTO}> = ({row}) => {
  const [trigger, response] = chargersApi.useDeleteChargePointMutation()
  const dispatch = useAppDispatch()

  return (
    <>
      <IconButton onClick={handleDeleteCharger(trigger, dispatch, row.business.id, row.id)}>
        <Delete />
      </IconButton>
    </>
  )
}
