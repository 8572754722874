import { NotificationTypeEnum, Page, TableParamsType } from "../common";
import { UserDTO } from "../users";
import { LocationDTO } from "../locations";

export interface NotificationsRequest {
  businessId: number,
  createdFrom?: number,
  createdTo?: number,
  notificationTypes: NotificationTypeEnum[],
  read?: boolean,
  sortAsc?: boolean,
  sortField?: NotificationsSortEnum,
  userId?: number
}

export enum NotificationsSortEnum {
  CREATED = "CREATED",
  TYPE = "TYPE"
}

export interface NotificationsSearchRequest {
  params: TableParamsType,
  data: NotificationsRequest
}

export interface NotificationByIdRequest {
  notificationId: number,
  data: {
    businessId: number,
    notificationType: NotificationTypeEnum,
  }
}

export interface NotificationMarkAsReadRequest {
  notificationId: number,
  data: {
    managerId: number,
    businessId: number,
    notificationType: NotificationTypeEnum,
  }
}

export interface NotificationMarkAsUnreadRequest {
  notificationId: number,
  data: {
    businessId: number,
    notificationType: NotificationTypeEnum,
  }
}

export interface NotificationDTO {
  businessId: number,
  created: number,
  read: boolean,
  id: number,
  message: string,
  type: NotificationTypeEnum,
  location: LocationDTO,
  user: UserDTO,
  readAt: number,
  "readByManager": ManagerType
}

export interface ManagerType {
  emailAddress: string,
  firstName: string,
  id: 0,
  lastName: string,
}

export interface NotificationsPageDTO extends Page {
  content: NotificationDTO[]
}
