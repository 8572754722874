import { useCallback, FC, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { useStyles } from "./DropZone.sx";
import { Cancel, Check } from "@mui/icons-material";
import { NotifyError } from "../../../utils/notification";
import { validFileNameRegEx } from "../../../utils/consts";

interface DropZoneProps {
  handleFile: (file: File[] | null) => void;
  isImage?: boolean
}

const DropZone: FC<DropZoneProps> = ({ handleFile, isImage }) => {
  const theme = useTheme()
  const styles = useStyles(theme);
  const [file, setFile] = useState<Array<File> | null>(null);
  const intl = useIntl()
  const [title, setTitle] = useState<string>(intl.formatMessage({id: 'dragFilesTitle'}))

  useEffect(() => {
    if(isImage) {
      setTitle(intl.formatMessage({ id: 'dragImageTitle' }))
    }
  }, [isImage])
  
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      let nameValid = true
  
      if (acceptedFiles?.[0]?.name && !acceptedFiles?.[0]?.name?.match(validFileNameRegEx)) {
        nameValid = false
      }
      
      if(acceptedFiles && file && nameValid){
        let data = file.concat(acceptedFiles)
        acceptedFiles.map(item => {
          data = data.filter(currentFile => currentFile.name !== item.name)
        });
      }
      
      if (nameValid) {
        setFile(acceptedFiles);
      } else {
        NotifyError(intl.formatMessage({id: "wrongFileName"}), 5000)
      }
    },
    [file]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: !isImage,
    accept: isImage 
      ? {
        "image/x-citrix-jpeg": ['.jpeg', '.jpg'],
        "image/x-png": ['.png']
      }
      : {
        "image/x-citrix-jpeg": ['.jpeg', '.jpg'],
        "image/x-png": ['.png'],
        "application/pdf": [".pdf"],
        "text/plain": [".txt"],
        "application/msword": [".doc", ".docx"],
      },
  });


  const handleDelete = (index: number) => {
    const data = file?.filter((item, key) => key !== index)
    setFile(data || null);
    handleFile(data || null)
  };

  useEffect(() => {
    file && handleFile(file);
  }, [file]);
  
  return (
    <>
      <Box {...getRootProps()}
        sx={styles.root}>
        <input {...getInputProps()} />

        <img src="/static/images/drag_drop.svg"
          alt="Drag" />
        <Box sx={styles.dragText}>
          <Typography variant="body1">
            {title}
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="dragSubtitle" />
          </Typography>
        </Box>
      </Box>
      {file?.length
        ? file.map((currFile, index) => (
          <Grid
            key={index}
            container
            columnSpacing={2}
            rowSpacing={1}
          >
            <Grid item
              xs={8}
              sx={styles.gridItem}>
              <Typography variant="body1">{currFile.name}</Typography>
            </Grid>
            <Grid item
              xs={3}
              sx={styles.gridItem}>
              <Check color="success" />
              <Typography variant="subtitle2"
                color="success.main">
                <FormattedMessage id="uploaded" />
              </Typography>
            </Grid>
            <Grid item
              xs={1}>
              <IconButton onClick={() => handleDelete(index)}>
                <Cancel />
              </IconButton>
            </Grid>
          </Grid>
        ))
        : ''
      }
    </>
  );
};

export default DropZone;
