import { SxProps, Theme, useTheme } from "@mui/material";
import { useCallback } from "react";
import { ChargePointStatus, ConnectorStatusEnum, InvoiceStatusEnum } from "src/enums";

export const useSxValue = (label: string): SxProps<Theme> => {
  const theme = useTheme()
  const sxValueCallback = useCallback((theme: Theme): SxProps<Theme>  => {
    switch(label){
      case InvoiceStatusEnum.PENDING: 
        return {
          background: theme.palette.primaryColor[100],
          color: theme.palette.primary.dark,
        }
      case InvoiceStatusEnum.PAID:
        return {
          background: theme.palette.success.light,
          color: theme.palette.success.dark,
        }
      case InvoiceStatusEnum.UNPAID:
        return {
          background: theme.palette.brand.light,
          color: theme.palette.brand.dark,
        }
      case InvoiceStatusEnum.PARTIAL: 
        return {
          background: theme.palette.warning.light,
          color: theme.palette.warning.dark,
        }
      case ConnectorStatusEnum.PREPARING: 
        return {
          background: theme.palette.warning.light,
          color: theme.palette.warning.dark,
        }
      case ConnectorStatusEnum.FINISHING:
        return {
          background: theme.palette.success.light,
          color: theme.palette.success.dark,
        }
      case ConnectorStatusEnum.AVAILABLE:
        return {
          background: theme.palette.success.light,
          color: theme.palette.success.dark,
        }
      case ConnectorStatusEnum.CHARGING:
        return {
          background: theme.palette.brand.light,
          color: theme.palette.brand.dark,
        }
      case ChargePointStatus.ONLINE:
        return {
          background: theme.palette.success.light,
          color: theme.palette.success.dark,
        }
      default: 
        return {
          background: theme.palette.neutral[300],
          color: theme.palette.neutral[700],
        }

    }
  }, [label])
  return sxValueCallback(theme)
} 
