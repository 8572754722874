import { Box, MenuItem, Select, SelectProps } from "@mui/material";
import { FC, useState } from "react"
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { changeLang } from "src/stores/common/commonSlice";
import { langWrapperSx, langSelectSx } from "./LangSelect.sx";
import { LanguageShortEnum, NormalizeLanguageEnum } from "../../../enums";
const data = [
  {
    value: LanguageShortEnum.EN,
    option: NormalizeLanguageEnum.ENGLISH
  },
  {
    value: LanguageShortEnum.FR,
    option: NormalizeLanguageEnum.FRENCH
  },
  {
    value: LanguageShortEnum.DE,
    option: NormalizeLanguageEnum.DEUTSCH
  }
]

export const LangSelect: FC<{short?: boolean, white?: boolean}> = ({short = false, white = false}) => {  
  const { lang } = useAppSelector((state) => state.common)
  const [language, setLanguage] = useState<string>(lang);
  const dispatch = useAppDispatch();
  const handleChangeLang: SelectProps<string>['onChange'] = (e) => {
    setLanguage(e.target.value)
    dispatch(changeLang(e.target.value))
  }
  
  return (
    <Box sx={langWrapperSx}>
      <Select
        labelId="lang-select"
        id="lang-select"
        value={language}
        label="Language"
        onChange={handleChangeLang}
        sx={langSelectSx(white)}
        variant="standard"
        disableUnderline
      >
        {data.map((item, index) => (
          <MenuItem
            value={item.value}
            key={index}
          >
            {!short ? item.option : item.option.slice(0, 2)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
