import { Box, Typography } from "@mui/material";
import { FormikConfig } from "formik";
import { FC, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavigateFunction, useNavigate, useLocation } from "react-router-dom";
import { authApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { LangSelect } from "src/components/common/LangSelect";
import { ResetPasswordType } from "src/components/forms/formData/resetPassword";
import { ResetPasswordForm } from "src/components/forms/ResetPasswordForm";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { NotifyError, NotifySuccess } from "src/utils/notification";
import { langSelectWrapperSx, resetPasswordDescriptionSx, welcomeBlockSx, wrapperSx } from "./ResetPassword.sx";

type ResetMutationTrigger = ReturnType<typeof authApi.useResetPasswordMutation>[0]

const handleSubmit = (trigger: ResetMutationTrigger, dispatch: AppDispatch, navigate: NavigateFunction, successMessage: string, errorMessage:string, hash?: string): FormikConfig<ResetPasswordType>['onSubmit'] => (async ({ password }) => {
  if(!hash) {
    return NotifyError(errorMessage)
  }
  const data = {
    newPassword: password
  }
  const params = {
    hash
  }
  try {
    dispatch(preloaderChangeStatus(true))
    await trigger({
      data,
      params
    }).unwrap()
    NotifySuccess(successMessage)
    navigate('/');
  } catch(error: any) {
    NotifyError(error.data.message)
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
});

export const ResetPassword: FC = () => {
  const intl = useIntl();
  const { search } = useLocation()
  
  const hash = useMemo(() => {
    return search.replace(/(.+)=(.+)/, '$2')
  }, [search])
  
  const [ trigger, response ] = authApi.useResetPasswordMutation()
  const successMessage = intl.formatMessage({id: 'successfullyChanged'})
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const errorMessage = intl.formatMessage({id: 'somethingWrong'})

  useEffect(() => {
    !hash && navigate('/login')
  }, [hash])

  return (
    <Box sx={wrapperSx}>
      <Box sx={langSelectWrapperSx}>
        <LangSelect />
      </Box>
      <Box sx={welcomeBlockSx}>
        <Typography variant="h1"
          color="textPrimary"
        >
          <FormattedMessage id="resetPassword" />
        </Typography>

        <Typography 
          variant="body1"
          color="text.primary"
          sx={resetPasswordDescriptionSx}
        >
          <FormattedMessage id="resetPasswordDescription" />
        </Typography>
      </Box>
      <ResetPasswordForm
        handleSubmit={handleSubmit(trigger, dispatch, navigate, successMessage, errorMessage, hash)}
      />
    </Box>
  )
}
