import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { LangSelect } from "src/components/common/LangSelect";
import { ForgotPasswordForm } from "src/components/forms/ForgotPasswordForm";
import { langSelectWrapperSx, resetPasswordDescriptionSx, welcomeBlockSx, wrapperSx } from "./ForgotPassword.sx";

export const ForgotPassword: FC = () => {
  return (
    <Box sx={wrapperSx}>
      <Box sx={langSelectWrapperSx}>
        <LangSelect />
      </Box>
      <Box sx={welcomeBlockSx}>
        <Typography variant="h1"
          color="textPrimary"
        >
          <FormattedMessage id="resetPassword" />
        </Typography>
        <Box>
          <Typography variant="subtitle1"
            color="grey.500"
          >
            <FormattedMessage id="resetPasswordSubtitle" />
          </Typography>
        </Box>
        <Typography 
          variant="body1"
          color="text.primary"
          sx={resetPasswordDescriptionSx}
        >
          <FormattedMessage id="resetPasswordDescription" />
        </Typography>
      </Box>
      <ForgotPasswordForm />
    </Box>
  )
}
