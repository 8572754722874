import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { locationsApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { DropZoneModal } from "src/components/common/DropZoneModal";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError, NotifyWarning } from "src/utils/notification";
import { AddClientToLocationFormProps } from "./AddFileToLocation.types";

type AddFileToLocationMutationTrigger = ReturnType<typeof locationsApi.useAddFileToLocationMutation>[0]

const handleAddFile = (
  trigger: AddFileToLocationMutationTrigger,
  dispatch: AppDispatch,
  locationId: number,
  businessId: number,
  handleClose: () => void,
  warningMessage: string,
  handleBlockSubmit: (status: boolean) => void,
  files?: File[] | null
) => (async () => {
  if(!files){
    NotifyWarning(warningMessage)
    return
  }
  const status = files.map((file) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.set('businessId', String(businessId))
    return trigger({
      locationId: locationId,
      formData
    }).unwrap()
  })
  try {
    handleBlockSubmit(true)
    dispatch(preloaderChangeStatus(true))
    await Promise.allSettled(status)
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    handleBlockSubmit(false)
    handleClose()
    dispatch(preloaderChangeStatus(false));
  }
});

export const AddFileToLocation: FC<AddClientToLocationFormProps> = ({ businessId, locationId, handleClose, open }) => {
  const [trigger, response] = locationsApi.useAddFileToLocationMutation()
  const dispatch = useAppDispatch()
  const [blockSubmit,setBlockSubmit] = useState(false)
  
  const handleBlockSubmit = (status: boolean) => {
    setBlockSubmit(status)
  }

  const intl = useIntl()

  const warningMessage = intl.formatMessage({id: 'uploadAFile'})
  const [file, setFile] = useState<File[] | null>(null)
  const handleFile = (dragedFile: File[] | null) => {
    setFile(dragedFile)
  }

  const dialogTitle = intl.formatMessage({id: 'addFile'})
  
  return (
    <DropZoneModal
      submitIsBlocked={blockSubmit}
      handleClose={handleClose}
      open={open}
      title={dialogTitle}
      submitButtonText="addFile"
      handleFile={handleFile}
      handleSubmit={handleAddFile(trigger, dispatch, locationId, businessId, handleClose, warningMessage, handleBlockSubmit,file)}
    />
  );
}
