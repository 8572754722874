import { Box } from "@mui/material";
import React, { useState } from "react";
import { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { chargersApi, ocppApi } from "src/api";
import { PageHeader } from "src/components/common/PageHeader";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { AppDispatch } from "src/app/store";
import { ConfigurationsList } from "src/components/configurations/ConfigurationsList";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { ChargePointByIdRequest, ChargePointSettingDTO, CONFIGURATIONS_TYPE, FormKeyValue } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type ChargerConfigurationsQueryTrigger = ReturnType<typeof ocppApi.useLazyGetConfigurationsQuery>[0];

const handleGetChargerConfigurations = (trigger: ChargerConfigurationsQueryTrigger, dispatch: AppDispatch) => (async (data: ChargePointByIdRequest) => {
  dispatch(preloaderChangeStatus(true))

  try {
    const response = await trigger(data).unwrap()
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
});

export const ChargerConfigurations: FC = () => {
  const { id } = useParams();
  const { authInfo, activeBusiness } = useAppSelector((state) => state.common)

  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { CONFIGURATIONS }: {CONFIGURATIONS: CONFIGURATIONS_TYPE[]} = useAppSelector(state => state.common)

  const [trigger, response] = ocppApi.useLazyGetConfigurationsQuery()

  const [configurations, setConfigurations] = useState<ChargePointSettingDTO[]>()

  useEffect(() => {
    id && handleGetChargerConfigurations(trigger, dispatch)({ chargerId: +id, businessId: activeBusiness })
  }, [])

  useEffect(() => {
    if(configurations && id){
      let data = configurations
      data = data.map(item => {
        const newValue = CONFIGURATIONS.filter(item => item.chargerId === +id)[0].configuration?.find((conf: FormKeyValue) => conf.key === item.key) 
        if(newValue){
          return {
            ...item,
            value: newValue.value
          }
        }
        return item
      })
      setConfigurations(data)
    }
  }, [CONFIGURATIONS])

  useEffect(() => {
    if(response.data){
      setConfigurations(response.data)
    }
  }, [response])

  return (
    <Box>
      <PageHeader
        title={intl.formatMessage({id:'configurations'})}
        onlyTitle
      />
      {configurations && <ConfigurationsList data={configurations} />}
    </Box>
  )
} 
