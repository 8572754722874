import * as Yup from 'yup';
import { LanguageEnum } from "../../../../enums";

export interface SignUpType {
  city: string,
  country: string,
  houseNumber: string,
  street: string,
  zipCode: string
  businessId: number,
  emailAddress: string,
  password: string,
  confirmPassword: string,
  parkingLot: string,
  firstName: string,
  lastName: string,
  agreements: boolean,
  region: string,
  language: LanguageEnum
}

export const signUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(255)
    .required('schemaRequiredFirstName'),
  lastName: Yup.string()
    .max(255)
    .required('schemaRequiredLastName'),
  emailAddress: Yup.string()
    .email('schemaValidEmail')
    .max(255)
    .required('schemaRequiredEmail'),
  password: Yup.string()
    .min(6)
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/, 'schemaValidPassword')
    .required('schemaRequiredPassword'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'schemaMatchPassword'),
  houseNumber: Yup.string()
    .required('schemaRequiredHouseNumber'),
  street: Yup.string()
    .max(255)
    .required('schemaRequiredStreet'),
  city: Yup.string()
    .required('schemaRequiredCity'),
  zipCode: Yup.string()
    .max(255)
    .required('schemaRequiredZip'),
  country: Yup.string()
    .required('schemaRequiredCountry'),
  region: Yup.string()
    .required('schemaRequiredRegion'),
  language: Yup.string(),
  agreements: Yup.boolean()
    .required('schemaRequiredAgreementsWithTermsAndPrivacy')
    .oneOf([true], 'schemaRequiredAgreementsWithTermsAndPrivacy')
});
