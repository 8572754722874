import { Theme } from "@mui/material";

export const useStyles = ((theme: Theme) =>
  ({
    formLabel: {
      "& > span " :{
        fontWeight: 600
      },
      "& span span": {
        marginRight: 5,
        color: theme.palette.warning.main
      },
    },
  })
);
