import { Box } from "@mui/material";
import { FC } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { LoginDrawer } from "src/components/common/LoginDrawer";
import { layoutWrapperSx, pageContentSx, drawerWrapperSx, contentWrapperSx } from "./PageLayout.sx";

export const PageLayout: FC = () => {
  const location = useLocation()
  const showDrawer = location.pathname !== '/confirmRegistration' && location.pathname !== '/verifyEmail' 
  return (
    <Box sx={layoutWrapperSx}>
      {showDrawer && 
        <Box sx={drawerWrapperSx}>
          <LoginDrawer />
        </Box>
      }
      <Box sx={contentWrapperSx(showDrawer)}>
        <Box sx={pageContentSx}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
};
