import { Button, Divider, SelectChangeEvent, Typography, Box } from "@mui/material";
import { FC, FormEvent } from "react";
import { FormattedMessage } from "react-intl";
import { AMPFormSelect } from "src/components/common/AMPFormSelect";
import { SubmitButton } from "src/components/common/SubmitButton";
import { ChargePointStatus, ConnectorStatusEnum } from "src/enums";
import ability from "src/permissions/ability";
import { fieldsWrapperSx, actionsWrapperSx, buttonSx } from "./ChargerFilterForm.sx";
import { ChargerFilterFormType } from "./ChargerFilterForm.types";

export const ChargerFilterForm: FC<ChargerFilterFormType> = ({
  handleFilter,
  isFilterActive,
  handleClose,
  activeCity,
  activeCountry,
  setActiveCountry,
  setActiveCity,
  activeConnectorStatus,
  activeChargePointStatus,
  setActiveConnectorStatus,
  setActiveChargePointStatus,
  activeBusiness,
  setActiveBusiness,
  businesses
}) => {

  const processedConnectorStatus = () => {
    return Object.values(ConnectorStatusEnum).map((status, index) => ({
      id: index,
      name: status,
      value: status
    }))
  }
  const processedChargePointStatus = () => {
    return Object.values(ChargePointStatus).map((status, index) => ({
      id: index,
      name: status,
      value: status
    }))
  }
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleFilter({
      country: activeCountry,
      city: activeCity,
      chargePointStatus: activeChargePointStatus,
      connectorStatus: activeConnectorStatus,
      businessId: activeBusiness
    })
    isFilterActive(true)
    handleClose()
  }

  const handleClear = () => {
    setActiveCountry('')
    setActiveCity('')
    setActiveConnectorStatus('')
    setActiveChargePointStatus('')
    setActiveBusiness(0)
    handleFilter({
      country: '',
      city: '',
      chargePointStatus: '',
      connectorStatus: '',
      businessId: 0
    })
    isFilterActive(false)
  }
  return (

    <form
      onSubmit={handleSubmit}
      noValidate
    >
      <Box sx={fieldsWrapperSx}>
        {Boolean(businesses.length) && <AMPFormSelect
          data={businesses}
          value={activeBusiness}
          label={"business"}
          input={"business"}
          onChange={(e: SelectChangeEvent<number>) => setActiveBusiness(+e.target.value)}
        />}
        <AMPFormSelect
          data={processedChargePointStatus()}
          value={activeChargePointStatus}
          label={"stationStatus"}
          input={"status"}
          onChange={(e: SelectChangeEvent<string>) => setActiveChargePointStatus(e.target.value)}
        />
        <AMPFormSelect
          data={processedConnectorStatus()}
          value={activeConnectorStatus}
          label={"connectorStatus"}
          input={"status"}
          onChange={(e: SelectChangeEvent<string>) => setActiveConnectorStatus(e.target.value)}
        />
      </Box>
      <Divider />
      <Box sx={actionsWrapperSx}>
        <Button
          variant="outlined"
          sx={buttonSx}
          onClick={handleClear}
        >
          <Typography
            variant="button"
            color="neutral.700"
          >
            <FormattedMessage id="clear" />
          </Typography>
        </Button>
        <SubmitButton
          text="apply"
        />
      </Box>
    </form>
  )
}
