import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import { reportsApi } from "src/api";
import { MultipleBarChart } from "../../MultipleBarChart";
import { formatBusinessData, getBusinessRevenueData } from "../RevenueReports.helpers";

export const RevenueByBusinesses:FC = () => {
  const intl = useIntl()
  const revenue = intl.formatMessage({id: "revenue"})
  const [getRevenueReports, revenueReports] = reportsApi.useLazyGetBusinessRevenueQuery()
  const currencies: Array<string> = []
  const [dates, setDates] = useState<{from?: number; to?: number}>({
    from: undefined,
    to: dayjs().valueOf()
  })
  
  const handleChangeDates = (
    newFrom: number,
    newTo: number
  ) => {
    return () => {
      setDates({
        from: newFrom,
        to: newTo
      })
    }
  }
  
  useEffect(() => {
    getBusinessRevenueData(getRevenueReports, dates.from, dates.to)
  }, [dates]);
  
  const businessBarChartData = formatBusinessData(
    revenueReports?.data?.businessRevenueStatistics ?? [],
    currencies,
    intl
  );
  
  const dataKeyFirst = currencies?.[0] ? `${revenue} (${currencies?.[0]})` : revenue
  const dataKeySecond = currencies?.[1] ? `${revenue} (${currencies?.[1]})` : undefined
  
  return (
    <MultipleBarChart
      title={intl.formatMessage({id: "revenueBusinesses"})}
      data={businessBarChartData}
      dataKeyFirst={dataKeyFirst}
      dataKeySecond={dataKeySecond}
      handleChangeDates={handleChangeDates}
      field="businesses"
    />
  )
}
