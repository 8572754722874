import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const moveToOperationButtonSx: SxProps<Theme> = {
  width: 'max-content',
  borderRadius: theme.spacing(5/8),
  background: theme.palette.brand.main,
  '&:hover': {
    background: theme.palette.brand.dark
  },
  '& span': {
    textTransform: 'none'
  }
}