import { Box, Button, Typography } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AMPModal from "../AMPModal";
import { businessesApi } from "../../../api";
import { LanguageEnum, LanguageShortEnum } from "../../../enums";
import { useAppSelector } from "../../../app/hooks";
import ReactMarkdown from "react-markdown";
import { btnSx, wrapperSx } from "./TermsText.sx";

export const TermsText: FC = () => {
  const intl = useIntl();
  const { lang } = useAppSelector((state) => state.common)
  const [showTerms, setShowTerms] = useState(false)
  const { authInfo } = useAppSelector(state => state.common)
  
  const englishDocument = businessesApi.useGetContractTermsQuery({
    businessId: authInfo.business.id,
    language: LanguageEnum.ENGLISH
  })
  const frenchDocument = businessesApi.useGetContractTermsQuery({
    businessId: authInfo.business.id,
    language: LanguageEnum.FRENCH
  })
  const deutschDocument = businessesApi.useGetContractTermsQuery({
    businessId: authInfo.business.id,
    language: LanguageEnum.DEUTSCH
  })
  
  const englishDocumentLink = englishDocument?.data?.termsLink ?? ''
  const frenchDocumentLink = frenchDocument?.data?.termsLink ?? ''
  const deutschDocumentLink = deutschDocument?.data?.termsLink ?? ''
  
  const englishText = englishDocument?.data?.contractTerms ?? ''
  const frenchText = frenchDocument?.data?.contractTerms ?? ''
  const deutschText = deutschDocument?.data?.contractTerms ?? ''
  
  const handleShowTerms = (status: boolean) =>
    () => {
      setShowTerms(status)
    }
    
  const textMap:Record<string, {
    text: string,
    link: string
  }> = {
    [LanguageShortEnum.EN]: {
      text: englishText,
      link: englishDocumentLink
    },
    [LanguageShortEnum.FR]: {
      text: frenchText,
      link: frenchDocumentLink
    },
    [LanguageShortEnum.DE]: {
      text: deutschText,
      link: deutschDocumentLink
    },
  }
    
  return (
    <Box sx={wrapperSx}>
      <Typography
        variant="subtitle2"
      >
        <FormattedMessage id={'agreeToThe'} />
      </Typography>
      <Button
        sx={btnSx}
        onClick={handleShowTerms(true)}
      >
        <Typography
          variant="subtitle2"
          color="primary"
        >
          <FormattedMessage id={'termsOfContract'} />
        </Typography>
      </Button>
      <AMPModal
        handleClose={handleShowTerms(false)}
        open={showTerms}
        title={intl.formatMessage({id: 'termsOfContract'})}
        type="md"
        fullWidth
      >
        <ReactMarkdown>
          {textMap[lang].text}
        </ReactMarkdown>
        <a
          target="_blank"
          href={textMap[lang].link}
          rel="noreferrer"
        >
          <Typography
            pt={2}
            variant="body1"
            color="primary"
          >
            <FormattedMessage id="link"/>
          </Typography>
        </a>
      </AMPModal>
    </Box>
  )
}
