import { Close, Dehaze, Logout } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { LogoBlack } from "src/components/icons/LogoBlack";
import { LogoWhite } from "src/components/icons/LogoWhite";
import { RoleEnum } from "src/enums";
import ability from "src/permissions/ability";
import { clearAuthInfo } from "src/stores/common/commonSlice";
import theme from "src/theme";
import { AppRouteEnum, AppRouteSpace } from "src/utils/helper";
import { BusinessNavigation } from "../BusinessNavigation";
import { LangSelect } from "../LangSelect";
import { UserNavigation } from "../UserNavigation";
import {
  appBarSx,
  drawerSx,
  hideDrawerButtonSx,
  imageWrapperSx,
  logoWrapperSx,
  profileLogoSx,
  profileNameSx,
  profileWrapperSx,
  settingsButtonSx,
  logoWrapperMobileSx
} from "./AMPDrawer.sx";
import { AMPDrawerType } from "./AMPDrawer.types";

export const AMPDrawer: FC<AMPDrawerType> = ({handleCloseDrawer, open, drawerVariant}) => {
  const { authInfo } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch()

  const { pathname } = useLocation()

  const [currentPath, setCurrentPath] = useState<string>('');
  const [initials, setInitials] = useState<string>('');

  const permissions = ability.can('manage', 'all')
  const isSuperAdmin = authInfo.roles[0].role === RoleEnum.SUPER_ADMIN
  const isBusiness = authInfo.roles[0].role === RoleEnum.BUSINESS
  const isManager = authInfo.roles[0].role === RoleEnum.CLIENT_MANAGER 
  || authInfo.roles[0].role === RoleEnum.SALES_MANAGER
  || authInfo.roles[0].role === RoleEnum.TECHNICAL_MANAGER;

  const intl = useIntl()
  const link = useMemo(() => {
    if(authInfo?.roles?.length && isBusiness){
      return AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users, AppRouteEnum.business, authInfo.business.id])
    }
    if(authInfo?.roles?.length && isManager){
      return AppRouteSpace([AppRouteEnum.app, AppRouteEnum.technicalTeam, authInfo.id])
    }
    return AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users, authInfo.id])
  }, [])

  const userName = useCallback(() => {
    if(!Object.keys(authInfo).length){
      return ''
    }
    if(isBusiness){
      return authInfo.business.name
    }
    if(!isBusiness && !isSuperAdmin){
      return `${authInfo.firstName} ${authInfo.lastName}`
    }
    return intl.formatMessage({id: authInfo.roles[0].role})
  }, [authInfo])

  const handleLogOut = () => {
    localStorage.clear()
    dispatch(clearAuthInfo())
  }

  useEffect(() => {
    setCurrentPath(pathname)
    if (window.innerWidth < theme.breakpoints.values['md'] && currentPath !== pathname) {
      handleCloseDrawer()
    }
  }, [pathname])
  useEffect(() => {
    authInfo.firstName && authInfo.lastName && setInitials(`${authInfo.firstName[0]}${authInfo.lastName[0]}`)
  },[initials])

  return (
    <AppBar
      position="static"
      sx={appBarSx}
    >
      <Drawer
        variant={drawerVariant}
        open={open}
        anchor='left'
        sx={drawerSx}
        onClose={handleCloseDrawer}
        ModalProps={{
          keepMounted: true,
        }}
      >
        
        <Box>
          <Box
            sx={logoWrapperSx}
          >
            {window.innerWidth < theme.breakpoints.values['md'] && <Box>
              <IconButton
                onClick={handleCloseDrawer}
                sx={hideDrawerButtonSx}
              >
                <Close />
              </IconButton>
            </Box>}
            <NavLink
              to={AppRouteSpace([AppRouteEnum.app])}
            >
              <LogoWhite height={"32"}/>
            </NavLink>
            <LangSelect
              white
              short
            />
          </Box>
          <Box
            sx={logoWrapperMobileSx}
          >
            {window.innerWidth < theme.breakpoints.values['md'] && <Box>
              <IconButton
                onClick={handleCloseDrawer}
                sx={hideDrawerButtonSx}
              >
                <Close />
              </IconButton>
            </Box>}
            <NavLink
              to={AppRouteSpace([AppRouteEnum.app])}
            >
              <LogoBlack />
            </NavLink>
            <LangSelect
              short
            />
          </Box>
          {permissions &&
          <BusinessNavigation />
          }
          {!permissions && 
          <UserNavigation />
          }
        </Box>
        <Box>
          <Divider 
            light
          />
          <Box sx={profileWrapperSx}>
            {!isSuperAdmin
              ? <Link to={link}>
                <Box sx={profileNameSx}>
                  {authInfo.avatar
                
                    ? <Box sx={imageWrapperSx}>
                      <img
                        src={authInfo.avatar.url}
                        alt={`${authInfo.firstName} ${authInfo.lastName}`}
                      />
                    </Box>
                    : <Typography
                      variant="caption"
                      sx={profileLogoSx}
                    >
                      {initials}
                    </Typography>}
                  <Typography variant="subtitle1">
                    { userName() }
                  </Typography>
                </Box>
              </Link>
              : <Box sx={profileNameSx}>
                {authInfo.avatar
                  ? <Box sx={imageWrapperSx}>
                    <img
                      src={authInfo.avatar.url}
                      alt={`${authInfo.firstName} ${authInfo.lastName}`}
                    />
                  </Box>
                  : <Typography
                    variant="caption"
                    sx={profileLogoSx}
                  >
                    {initials}
                  </Typography>}
                <Typography variant="subtitle1">
                  { userName() }
                </Typography>
              </Box> 
            }
            <IconButton
              sx={settingsButtonSx}
              onClick={handleLogOut}  
            >
              <Logout />
            </IconButton>
          </Box>
        </Box>
      </Drawer>
    </AppBar>
  )
}
