import { SxProps, Theme } from "@mui/material"
import theme from "src/theme"

export const configWrapperSx: SxProps<Theme> = {
  padding: theme.spacing(16/8, 24/8),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(10/8)
}

export const btnTextSx: SxProps<Theme> = {
  display: "block",
  [theme.breakpoints.down('md')]: {
    display: "none"
  }
}

export const resetButtonsWrapperSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(10/8)
}

export const resetButtonSx: SxProps<Theme> = {
  borderWidth: theme.spacing(2/8),
  borderStyle: 'solid',
  borderColor: theme.palette.primaryColor[100],
  background: theme.palette.primaryColor[50],
  color: theme.palette.primary.main,
  maxHeight: theme.spacing(50/8),
}

export const configButtonSx: SxProps<Theme> = {
  borderWidth: theme.spacing(2/8),
  borderStyle: 'solid',
  borderColor: theme.palette.neutral[200],
  background: theme.palette.background.default,
  maxHeight: theme.spacing(50/8),
  color: theme.palette.neutral[700],
  display: 'flex',
  gap: theme.spacing(5/8),
  alignItems: 'center',
  padding: theme.spacing(15/8, 20/8)
}
