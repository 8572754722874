import { GridColumns } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { ColoredStatus } from 'src/components/common/ColoredStatus';
import { DownloadInvoicePdf } from "./DownloadInvoicePdf";
import { InvoiceSortEnum, RoleEnum } from 'src/enums';
import { InvoicePreview } from '../InvoicePreview';
import { RevenueInvoicePreview } from '../RevenueInvoicePreview';

export const columnConfigInvoices = (role: RoleEnum):GridColumns => ([
  {
    field: 'user',
    renderHeader: (params) => role === RoleEnum.SUPER_ADMIN ? <FormattedMessage id={"businessColumnHeader"} /> : <FormattedMessage id={"clientColumnHeader"} />,
    flex: 2,
    minWidth: 50,
    cellClassName: 'ampTableCell',
    renderCell: (params) => role === RoleEnum.SUPER_ADMIN ? <RevenueInvoicePreview data={params.row} /> : <InvoicePreview data={params.row} /> 
  },
  {
    field: InvoiceSortEnum.INVOICE_NUMBER,
    renderHeader: (params) => <FormattedMessage id={"invoiceNumberColumnHeader"} />,
    flex: 2,
    minWidth: 50,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.invoiceNumber
  },
  {
    field: InvoiceSortEnum.CREATED,
    renderHeader: (params) => <FormattedMessage id={"invoiceDateColumnHeader"} />,
    flex: 5,    
    minWidth: 160,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => new Date(params.row.created).toLocaleString('fr-CH')
  },
  {
    field: InvoiceSortEnum.AMOUNT,
    renderHeader: (params) => <FormattedMessage id={"invoiceAmountColumnHeader"} />,
    flex: 3,
    minWidth: 100,
    cellClassName: 'ampTableCell',
    renderCell: (params) => (
      <>
        {`${params.row.amount / 100} ${params.row.currency && params.row.currency}`}
      </>
    )
  },
  {
    field: InvoiceSortEnum.STATUS,
    renderHeader: (params) => <FormattedMessage id={"invoiceStatusColumnHeader"} />,
    flex: 2,
    minWidth: 100,
    cellClassName: 'ampTableCell',
    renderCell: (params) => (
      <ColoredStatus label={params.row.status} />
    ),
  },
  {
    field: 'download invoice',
    headerName: '',
    flex: 1,
    sortable: false,
    cellClassName: 'ampTableCell hoverableCell',
    renderCell: (params) =>
      params?.row?.bexioId &&
      <DownloadInvoicePdf
        name={params.row.invoiceNumber}
        invoiceId={params.row.id}
        businessId={role === RoleEnum.SUPER_ADMIN
          ? params?.row?.business?.id
          : undefined
        }
        isIcon
      />
  }
])
