import { FormHelperText, Typography } from "@mui/material";
import { FC } from "react";

export const FormHelper: FC<{helperText: string}> = ({ helperText }) => (
  <FormHelperText>
    <Typography variant="caption">
      {helperText}
    </Typography>
  </FormHelperText>
)
