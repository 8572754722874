import { FC, MouseEvent, useContext, useState } from "react";
import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import theme from "../../../../theme";
import { useAppDispatch } from "../../../../app/hooks";
import { locationsApi } from "../../../../api";
import { AppDispatch } from "../../../../app/store";
import { preloaderChangeStatus } from "../../../../stores/common/commonSlice";
import LocationInfoContext from "../../../../pages/LocationInfo/LocationInfo.context";
import { FileMenuProps } from "./FileMenu.types";
import { fileMenuSx } from "./FileMenu.sx";
import { formatFileName, isErrorObject } from "../../../../utils/helper";
import { NotifyError } from "src/utils/notification";

type DownloadLocationFileMutationTrigger = ReturnType<typeof locationsApi.useDownloadLocationFileQuery>[0];
type DeleteLocationFileMutationTrigger = ReturnType<typeof locationsApi.useDeleteLocationFileMutation>[0]

const handleDownloadFile = (trigger: DownloadLocationFileMutationTrigger, dispatch: AppDispatch, locationId: number, fileId: number, name: string, context: any) => async () => {
  try {
    dispatch(preloaderChangeStatus(true))
    const params = {
      fileId,
      businessId: context.businessId,
    }
    const response = await trigger({
      locationId,
      params
    })
    const tempLink = document.createElement('a');
    tempLink.href = response.data;
    tempLink.setAttribute('download', `${formatFileName(name)}`);
    tempLink.click();
    window.URL.revokeObjectURL(tempLink.href)
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
}

const handleDeleteFile = (trigger: DeleteLocationFileMutationTrigger, dispatch: AppDispatch, locationId: number, fileId: number, context: any) => async () => {
  const params = {
    fileId,
    businessId: context?.businessId
  }
  dispatch(preloaderChangeStatus(true))
  try{
    await trigger({ locationId, params }).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
}

export const FilesMenu:FC<FileMenuProps> = ({
  row,
  name,
  isSuperAdmin
}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { id: locationId } = useParams()
  const [deleteMutation] = locationsApi.useDeleteLocationFileMutation()
  const [downloadQuery] = locationsApi.useLazyDownloadLocationFileQuery()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const context = useContext(LocationInfoContext)
  
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <Box>
      <IconButton
        onClick={handleClick}
        sx={fileMenuSx.iconBtn}>
        <MoreVert
          sx={{ height: '0.875rem' }}
          fill={theme.palette.neutral[400]}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={fileMenuSx.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Button
          sx={fileMenuSx.menuBtn}
          onClick={handleDownloadFile(downloadQuery, dispatch, locationId ? +locationId : 0, +row?.id, name, context )}
        >
          <Typography sx={fileMenuSx.menuBtnText}>
            {intl.formatMessage({id: "download"})}
          </Typography>
        </Button>
        {!isSuperAdmin &&
          <Button
            sx={fileMenuSx.menuBtn}
            onClick={handleDeleteFile(deleteMutation, dispatch, locationId ? +locationId : 0, +row?.id, context )}
          >
            <Typography sx={fileMenuSx.menuBtnText}>
              {intl.formatMessage({id: "delete"})}
            </Typography>
          </Button>
        }
      </Popover>
    </Box>
  )
}
