import { GridColumns } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { TableIdCell } from 'src/components/common/TableIdCell';
import { LightningIcon } from 'src/components/icons/LightningIcon';
import { UserIcon } from 'src/components/icons/UserIcon';
import { LocationSortFieldEnum } from 'src/enums/locations';
import { AppRouteSpace, AppRouteEnum } from 'src/utils/helper';
import { DeleteLocation } from '../DeleteLocation';

const deleteCol = (canDeleteLocation: boolean):GridColumns => {
  return canDeleteLocation ? [{
    field: 'delete',
    flex: 1.5,
    headerName: '',
    sortable: false,
    cellClassName: 'ampTableCell hoverableCell',
    renderCell: (params) => (
      <DeleteLocation row={params.row}/>
    )
  }] : []
}

export const columnConfigLocations = (canDeleteLocation = true):GridColumns => ([
  {
    field: LocationSortFieldEnum.ID,
    renderHeader: (params) => <FormattedMessage id={"idColumnHeader"} />,
    flex: 1.5,
    minWidth: 60,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.id,
  },
  {
    field: LocationSortFieldEnum.NAME,
    renderHeader: (params) => <FormattedMessage id={"nameOfLocationColumnHeader"} />,
    flex: 9,    
    minWidth: 160,
    cellClassName: 'ampTableCell',
    renderCell: (params) => {
      return (
        <TableIdCell
          label={params.row.name}
          link={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.locations, params.row.id])}
          businessId={params.row.business.id}
        />
      )
    }
  },
  {
    field: LocationSortFieldEnum.COUNTRY,
    renderHeader: (params) => <FormattedMessage id={"countryColumnHeader"} />,
    flex: 3,
    minWidth: 100,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.address.country
  },
  {
    field: LocationSortFieldEnum.CITY,
    renderHeader: (params) => <FormattedMessage id={"cityColumnHeader"} />,
    flex: 2,
    minWidth: 100,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.address.city,
  },
  {
    field: LocationSortFieldEnum.STREET,
    renderHeader: (params) => <FormattedMessage id={"addressColumnHeader"} />,
    flex: 6,
    minWidth: 180,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => `${params.row.address.street} ${params.row.address.houseNumber}`
  },
  {
    field: LocationSortFieldEnum.USERS_SIZE,
    renderHeader: (params) => <FormattedMessage id={"clientsColumnHeader"} />,
    flex: 2,
    minWidth: 80,
    cellClassName: 'ampTableCell',
    renderCell: (params) => {
      return (
        <>
          <UserIcon />
          {params.row.clientsCount}
        </>
      )
    }
  },
  {
    field: LocationSortFieldEnum.CHARGER_SIZE,
    renderHeader: (params) => <FormattedMessage id={"chargersColumnHeader"} />,
    flex: 2,
    minWidth: 100,
    cellClassName: 'ampTableCell',
    renderCell: (params) => {
      return (
        <>
          <LightningIcon />
          {params.row.chargePointsCount}
        </>
      )
    }
  },
  ...deleteCol(canDeleteLocation)
])
