import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const pageContentSx: SxProps<Theme> = {
  padding: theme.spacing(50/8, 90/8),
  [theme.breakpoints.down('lg')]:{
    padding: theme.spacing(40/8, 60/8),
  },
  [theme.breakpoints.down('md')]:{
    padding: theme.spacing(0, 20/8),
    position: 'relative',
    background: theme.palette.background.default,
    height: '100%',
    borderTop: `${theme.spacing(1/8)} solid ${theme.palette.background.default}`,
    '&:after': {
      content: "''",
      position: 'absolute',
      left: 0,
      bottom: '100%',
      width: '100%',
      height: theme.spacing(30/8),
      background: theme.palette.background.default,
      borderRadius: theme.spacing(30/8, 30/8, 0, 0),
    }
  }
}
export const layoutWrapperSx: SxProps<Theme> = {
  display: 'flex',
  width: '100%',
  [theme.breakpoints.down('md')]:{
    flexDirection: 'column'
  }
}
export const drawerWrapperSx: SxProps<Theme> = {
  width: '42.5%',
  [theme.breakpoints.down('md')]:{
    width: '100%'
  }
}
export const contentWrapperSx = (showDrawer: boolean) => ({
  width: showDrawer ? '57.5%' : '100%',
  [theme.breakpoints.down('md')]:{
    height: "100%",
    width: '100%'
  }
})
