import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const fieldsWrapperSx: SxProps<Theme> = {
  padding: theme.spacing(0, 5/8),
}
export const formControlSx: SxProps<Theme> = {
  '&.MuiFormControl-root': {
    marginBottom: theme.spacing(5/8)
  }
}
export const actionsWrapperSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(5/8),
  padding: theme.spacing(8/8)
}

export const closeButtonSx: SxProps<Theme> = {
  width: '50%',
  '&.MuiButton-root': {
    borderColor: theme.palette.neutral[200],
    '& span': {
      textTransform: 'none'
    }
  }
}

export const buttonSx: SxProps<Theme> = {
  width: '50%',
  '&.MuiButton-root': {
    borderColor: theme.palette.neutral[200],
    '& span': {
      textTransform: 'none'
    }
  }
}
