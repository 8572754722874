import { Box, Typography } from "@mui/material";
import { FC, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { registerApi } from "src/api";
import { store } from "src/app/store";
import { SubmitButton } from "src/components/common/SubmitButton";
import { LogoBlack } from "src/components/icons/LogoBlack";
import { VerifiedEmailIcon } from "src/components/icons/VerifiedEmailIcon";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppRouteSpace, AppRouteEnum, isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { wrapperSx, innerSx, textWrapperSx } from "./ConfirmRegistartion.sx";

type ConfirmMutationTrigger = ReturnType<typeof registerApi.useConfirmMutation>[0] 

const handleConfirmRegisration = (trigger: ConfirmMutationTrigger, hash: string) => async () => {
  try {
    store.dispatch(preloaderChangeStatus(true));
    await trigger(hash).unwrap()
  } catch (error) {
    console.error(error) // TODO: change to Notifying
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
}

export const ConfirmRegistartion: FC = () => {
  const intl = useIntl()
  const [trigger, reponse] = registerApi.useConfirmMutation()
  const { search } = useLocation()
  const navigate = useNavigate()
  const hash = useMemo(() => {
    return search.replace(/(.+)=(.+)/, '$2')
  }, [search])
  
  const navigateToSignIn = () => {
    navigate(AppRouteSpace([AppRouteEnum.login]))
  }

  useEffect(() => {
    hash && handleConfirmRegisration(trigger, hash)()
  }, [])

  return (
    <Box sx={wrapperSx}>
      <LogoBlack />
      <Box sx={innerSx}>
        <VerifiedEmailIcon />
        <Box sx={textWrapperSx}>
          <Typography variant="subtitle1">
            <FormattedMessage id="verifiedSuccessfully" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="verifiedDescription" />
          </Typography>
        </Box>
        <SubmitButton 
          text={'signIn'}
          handler={navigateToSignIn}
          align={'center'}
        />
      </Box>
    </Box>
  )
}
