import { FC } from "react";
import {
  Grid,
  Typography,
  Box
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { contentItemSx, innerItemSx } from "./Installation.sx";

export const Installation: FC = () => (
  <>
    <Grid
      container
      spacing={5}
    >
      <Grid
        item
        xs={12}
        sx={contentItemSx}
      >
        <img src="/static/images/create-request.png" />
      </Grid>
      <Grid
        item
        xs={12}
        sx={contentItemSx}
      >
        <Box sx={innerItemSx}>
          <Typography variant="subtitle1">
            <FormattedMessage id="installationInProccess" />
          </Typography>
          <Typography
            variant="body1"
            color="neutral.500"
            fontSize="1rem"
          >
            <FormattedMessage id="installationContent" />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </>
);
