import theme from "../../../../theme";

export const invoiceTableCardSx = {
  root: {
    backgroundColor: theme.palette.common.white,
    p: 2,
    pt: 4,
    borderBottom: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`,
    position: "relative"
  },
  menuBtn: {
    width: "100%"
  },
  menuBtnText: {
    width: "100%",
    textAlign: "start",
    fontSize: theme.spacing(14/8),
    fontWeight: 400,
    color: theme.palette.neutral[400],
  },
  iconBtn: {
    width: theme.spacing(20/8),
    height: theme.spacing(20/8),
    backgroundColor: "transparent"
  },
  popover: {
    '& .MuiPaper-root': {
      maxWidth: theme.spacing(200/8),
      width: '100%',
      p: 1
    }
  },
  titleText: {
    fontSize: theme.spacing(10/8),
    fontWeight: 500,
    color: theme.palette.neutral[400],
    textTransform: "uppercase",
    my: 1
  },
  dataText: {
    fontSize: theme.spacing(12/8),
    fontWeight: 400,
    textAlign: "end"
  },
  idText: {
    color: theme.palette.primary.main
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  withIcon: {
    flexDirection: "row",
    alignItems: "center",
    gap: 0.5
  },
  menu: {
    position: "absolute",
    top: theme.spacing(16/8),
    right: theme.spacing(16/8)
  }
}
