import { useEffect } from "react"
import { useState } from "react"
import { FC } from "react"

export const ProgressActiveChargingIcon: FC<{percent: number}> = ({percent}) => {

  useEffect(()=> {
    const filter = document.querySelector('.svg-filter')
    filter?.setAttribute('id', 'filter')
    const g = document.querySelector('.g-block')
    g?.setAttribute('filter', 'url(#filter)')
  }, [])

  return (
    <svg width="94"
      height="279"
      viewBox="0 0 94 279"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <filter
        id={`fill`}
        className="svg-filter"
        x="0%"
        y="0%">
        <feFlood floodColor="#007FDC"/>
        <feOffset dy={percent >= 100 ? 0 : 279 - percent * 279 / 100}>
        </feOffset>
        <feComposite operator="in"
          in2="SourceGraphic" />
        <feComposite operator="over"
          in2="SourceGraphic" />
      </filter>
      <g filter={`url(#fill)`} id={"g-block"}>
        <path d="M6.5 10C6.5 7.79086 8.29086 6 10.5 6H83.5C85.7091 6 87.5 7.79086 87.5 10V11.9724H6.5V10Z"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="14.9722"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="23.9448"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="32.917"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="41.8892"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="50.8618"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="59.834"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="68.8066"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="77.7788"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="86.751"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="95.7236"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="104.696"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="113.668"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="122.641"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="131.613"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="140.585"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="149.558"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="158.53"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="167.502"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="176.475"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="185.447"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="194.419"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="203.392"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="212.364"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="221.336"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="230.309"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="239.281"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="248.254"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <rect x="6.5"
          y="257.226"
          width="81"
          height="5.97235"
          fill="#DEF0FC"/>
        <path d="M6.5 266.198H87.5V268.171C87.5 270.38 85.7091 272.171 83.5 272.171H10.5C8.29086 272.171 6.5 270.38 6.5 268.171V266.198Z"
          fill="#DEF0FC"/>
      </g>
      <rect x="1"
        y="0.5"
        width="92"
        height="277.171"
        rx="7.5"
        stroke="#DEF0FC"/>
    </svg>
  )
}

