import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const rootSx: SxProps<Theme> = {
  position: "relative"
}

export const editBtnSx: SxProps<Theme> = {
  position: "absolute",
  top: 3,
  right: 0,
  background: "transparent"
}

export const titleSx: SxProps<Theme> = {
  marginBottom: theme.spacing(20/8),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}

export const tabsSx: SxProps<Theme> = {
  ".MuiTabs-indicator": {
    display: "none"
  }
}

export const tabSx: SxProps<Theme> = {
  color: theme.palette.neutral[500],
  textTransform: "capitalize",
  alignItems: "flex-start",
  px: 0,
  minWidth: 0,
  "&:not(:last-child)": {
    mr: 3
  }
}
