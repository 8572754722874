import { FC, useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { LangSelect } from "../../components/common/LangSelect";
import { LoginForm } from "../../components/forms/LoginForm";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { langSelectWrapperSx, signUpSx, welcomeBlockSx, wrapperSx } from "./Login.sx";
import { useAppDispatch } from "src/app/hooks";
import { clearStore } from "src/stores/common/commonSlice";
import { AppRouteSpace, AppRouteEnum } from "src/utils/helper";

export const Login: FC = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(clearStore())
  }, [])
  return (
    <Box sx={wrapperSx}>
      <Box sx={langSelectWrapperSx}>
        <LangSelect />
      </Box>
      <Box sx={welcomeBlockSx}>
        <Typography variant="h1"
          color="textPrimary"
        >
          <FormattedMessage id="welcome" />
        </Typography>
        <Box sx={signUpSx}>
          <Typography variant="subtitle1"
            color="grey.500"
          >
            <FormattedMessage id="notAMember" />
          </Typography>
          <Link
            to={AppRouteSpace([AppRouteEnum.signUp])}
          >
            <Typography
              variant="subtitle1"
              color="primary"
            >
              <FormattedMessage id="signUp" />
            </Typography>
          </Link>
        </Box>
      </Box>
      <LoginForm />
    </Box>
  )
}
