import { SxProps, Theme } from "@mui/material"
import theme from "src/theme"


export const wrapperSx: SxProps<Theme> = {
  height: theme.spacing(600/8),
  width: '100%'
}
export const tableFooterSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: 0,
  py: 1
}
