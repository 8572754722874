import theme from "../../theme";

export const supportSx = {
  paragraph: {
    my: 1,
    fontSize: theme.spacing(14/8),
    lineHeight: 1.3
  },
  header: {
    lineHeight: 1.5,
    my: 4
  },
  root: {
    '& .MuiTypography-h2': {
      lineHeight: 1.5
    },
    '& .MuiTypography-h3': {
      lineHeight: 1.5,
      fontSize: theme.spacing(24/8)
    },
    '& .MuiTypography-h5': {
      marginBottom: theme.spacing(10/8)
    },
    '& ol li': {
      listStyle: "auto"
    },
    '& ul li': {
      listStyle: 'disc'
    }
  },
  link: {
    color: theme.palette.primary.main
  },
  divider: {
    margin: theme.spacing(20/8, 0)
  }
}
