import { alpha, SxProps, Theme } from "@mui/material";
import theme from "../../../theme";

export const buttonsSx:SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
}
export const adornmentSx:SxProps<Theme> = {
  color: theme.palette.neutral[400]
}
