import * as Yup from 'yup';

export interface EditBexioFormType extends Record<string, unknown>{
  bexioId: number
}

export const editBexioFormSchema = Yup.object().shape({
  bexioId: Yup.number()
    .required("schemaBexioIdRequired")
    .notOneOf([0]),
});
