import { SxProps, Theme } from "@mui/material";
import theme from "../../../theme";

export const rootSx: SxProps<Theme> = {
  p: 2
}

export const iconBtnSx: SxProps<Theme> = {
  width: theme.spacing(32/8),
  height: theme.spacing(32/8)
}

export const numbersBoxSx: SxProps<Theme> = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  mt: 2
}

export const numbersSx: SxProps<Theme> = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  p: 1,
  borderRadius: theme.spacing(50/8),
  border: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`,
  minWidth: theme.spacing(180/8),
  width: '40%'
}

export const submitButtonSx: SxProps<Theme> =  {
  maxWidth: theme.spacing(175/8),
  alignSelf: 'flex-end',
  '& span': {
    textTransform: 'none'
  }
}

export const actionsWrapperSx: SxProps<Theme> =  {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(20/8),
  backgroundColor: theme.palette.neutral[100],
  p: 2
}
