import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';


export const useStyles = makeStyles((theme: Theme) => createStyles({
  fieldsWrapper: {
    padding: 20,

  },
  formControl: {
    '&.MuiFormControl-root': {
      marginBottom: 5
    }
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 5,
    padding: '10px 20px'
  },
  button: {
    '&.MuiButton-root': {
      borderColor: theme.palette.neutral[200],
      '& span': {
        textTransform: 'none'
      }
    }
  }
}));
