import { Client, Message, StompSubscription } from '@stomp/stompjs';
import { isDevelopmentMode } from 'src/utils/helper';
/* eslint-disable no-restricted-syntax */

export class ConnectionChanel {
  parameters: {
    brokerURL: string;
    debug(str: string): void;
    reconnectDelay: number;
    heartbeatIncoming: number;
    heartbeatOutgoing: number;
  };

  listener: Client;

  subscription: StompSubscription | null;

  constructor() {
    this.parameters = {
      brokerURL: `${process.env.REACT_APP_WS_URL}`,
      debug(str) {
        isDevelopmentMode() && console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
    };
    this.subscription = null;

    this.listener = new Client(this.parameters);

    this.listener.onConnect = (frame) => {
      isDevelopmentMode() && console.log('onConnect: ', frame);

      // Do something, all subscribes must be done is this callback
      // This is needed because this will be executed after a (re)connect
    };

    this.listener.onStompError = (frame) => {
      // Will be invoked in case of error encountered at Broker
      // Bad login/passcode typically will cause an error
      // Complaint brokers will set `message` header with a brief message. Body may contain details.
      // Compliant brokers will terminate the connection after any error
      isDevelopmentMode() && console.log(`Broker reported error: ${frame.headers.message}`);
      isDevelopmentMode() && console.log(`Additional details: ${frame.body}`);
    };

    this.listener.onDisconnect = (frame) => {
      isDevelopmentMode() && console.log(`onDisconnect: ${frame}`);
    };
  }

  activateChannel() {
    if (this.listener.connected) {
      this.listener.deactivate();
      this.subscription = null;
    }
    this.listener?.activate();
  }

  onSubscribe(
    topic: string,
    callBack: (mes: string) => void
  ) {
    if (this.listener.connected && !this.subscription) {
      this.subscription = this.listener?.subscribe(
        `/topic/${topic}`,
        (message: Message) => {
          message.body && callBack(message.body);
        }
      );
    }
  }

  onUnSubscribe() {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }

  onDeactivate() {
    this.listener?.deactivate();
  }
}
