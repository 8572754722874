import { FC, SyntheticEvent, useState } from "react";
import { Edit } from "@mui/icons-material";
import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { FormattedMessage, useIntl } from "react-intl";
import AMPModal from "src/components/common/AMPModal";
import { TabPanel } from "../../common/TabPanel";
import { TariffDescriptionForm } from "src/components/forms/TariffDescriptionForm";
import { TariffDescriptionType } from "./TariffDescription.types";
import {
  detailsWrapperSx,
  detailsTitleSx,
  markdownSx,
  tabsSx,
  tabSx,
} from "./TariffDescription.sx";
import { Can } from "src/permissions/Can";

export const TariffDescription: FC<TariffDescriptionType> = ({ data }) => {
  const intl = useIntl();
  const editDescriptionTitle = intl.formatMessage({
    id: "editDescriptionTitle",
  });
  const [tab, setTab] = useState(0);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleTab = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Box sx={detailsWrapperSx}>
        <Box sx={detailsTitleSx}>
          <Typography variant="subtitle1">
            <FormattedMessage id="description" />
          </Typography>
          <Can
            I="manage"
            a="tariff"
          >
            <IconButton onClick={handleOpen}>
              <Edit />
            </IconButton>
          </Can>
        </Box>
        <Tabs value={tab}
          onChange={handleTab}
          sx={tabsSx}>
          <Tab sx={tabSx}
            label={intl.formatMessage({ id: "english" })} />
          <Tab sx={tabSx}
            label={intl.formatMessage({ id: "french" })} />
          <Tab sx={tabSx}
            label={intl.formatMessage({ id: "german" })} />
        </Tabs>
        <TabPanel value={tab}
          index={0}>
          <Typography variant="body1"
            component="div"
            sx={markdownSx}>
            <ReactMarkdown>{data?.description ?? ""}</ReactMarkdown>
          </Typography>
        </TabPanel>
        <TabPanel value={tab}
          index={1}>
          <Typography variant="body1"
            component="div"
            sx={markdownSx}>
            <ReactMarkdown>{data?.descriptionFrench ?? ""}</ReactMarkdown>
          </Typography>
        </TabPanel>
        <TabPanel value={tab}
          index={2}>
          <Typography variant="body1"
            component="div"
            sx={markdownSx}>
            <ReactMarkdown>{data?.descriptionDeutsch ?? ""}</ReactMarkdown>
          </Typography>
        </TabPanel>
      </Box>
      <AMPModal
        handleClose={handleClose}
        open={open}
        title={editDescriptionTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <TariffDescriptionForm
          tariffId={data.id}
          businessId={data.business.id}
          monthlyFee={data.monthlyFee}
          description={data.description}
          descriptionFrench={data.descriptionFrench}
          descriptionGerman={data.descriptionDeutsch}
          handleClose={handleClose}
          country={data.country}
          region={data.region}
          pricePerKWh={data.pricePerKWh}
        />
      </AMPModal>
    </>
  );
};
