import { FC } from "react";

export const LogoWhite: FC<{height: string}> = ({ height }) => (
  <svg
    height={height}
    width="auto"
    viewBox="0 0 164 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_25_2569)">
      <path d="M71.1729 9.64374C70.9374 9.07549 70.3814 8.7041 69.7664 8.7041C69.1515 8.7041 68.5955 9.07549 68.36 9.64374L58.4056 33.6359C58.0829 34.4132 58.4523 35.3021 59.2275 35.6248C60.0028 35.9454 60.8937 35.5781 61.2164 34.8028L64.5081 26.8697C64.5021 26.9286 64.496 26.9874 64.496 27.0483C64.496 27.8885 65.1779 28.5704 66.0181 28.5704H73.5108C74.351 28.5704 75.0329 27.8885 75.0329 27.0483C75.0329 26.9874 75.0288 26.9286 75.0207 26.8697L78.3125 34.8028C78.556 35.3894 79.1222 35.7425 79.7189 35.7425C79.9137 35.7425 80.1106 35.706 80.3013 35.6268C81.0786 35.3041 81.4459 34.4132 81.1233 33.6379L71.1688 9.64577L71.1729 9.64374ZM73.5128 25.5262H66.0201C65.535 25.5262 65.1048 25.7535 64.8268 26.1046L69.7664 14.1999L74.7061 26.1046C74.4281 25.7515 73.9979 25.5262 73.5128 25.5262Z"
        fill="white"/>
      <path d="M104.787 8.76721C104.141 8.57645 103.445 8.83216 103.078 9.39634L94.4 22.7542L85.722 9.39634C85.3547 8.83013 84.6586 8.57442 84.0132 8.76721C83.3679 8.95798 82.9234 9.55261 82.9234 10.2264V34.2186C82.9234 35.0588 83.6053 35.7407 84.4455 35.7407C85.2857 35.7407 85.9676 35.0588 85.9676 34.2186V15.3629L93.1234 26.3768C93.4035 26.809 93.8845 27.0688 94.4 27.0688C94.9154 27.0688 95.3964 26.807 95.6765 26.3768L102.832 15.3629V34.2186C102.832 35.0588 103.514 35.7407 104.354 35.7407C105.195 35.7407 105.877 35.0588 105.877 34.2186V10.2284C105.877 9.55464 105.434 8.96001 104.787 8.76924V8.76721Z"
        fill="white"/>
      <path d="M119.754 8.70654H110.962C110.122 8.70654 109.44 9.38844 109.44 10.2286V34.2208C109.44 35.061 110.122 35.7429 110.962 35.7429C111.803 35.7429 112.484 35.061 112.484 34.2208V27.6697H114.214C115.054 27.6697 115.736 26.9878 115.736 26.1477C115.736 25.3075 115.054 24.6256 114.214 24.6256H112.484V11.7507H119.754C123.046 11.7507 125.725 14.4296 125.725 17.7214V18.6549C125.725 21.9467 123.046 24.6256 119.754 24.6256H118.686C117.846 24.6256 117.164 25.3075 117.164 26.1477C117.164 26.9878 117.846 27.6697 118.686 27.6697H119.754C124.724 27.6697 128.769 23.625 128.769 18.6549V17.7214C128.769 12.7512 124.724 8.70654 119.754 8.70654Z"
        fill="white"/>
      <path d="M139.541 8.70654C138.701 8.70654 138.019 9.38844 138.019 10.2286V34.2208C138.019 35.061 138.701 35.7429 139.541 35.7429C140.381 35.7429 141.063 35.061 141.063 34.2208V10.2286C141.063 9.38844 140.381 8.70654 139.541 8.70654Z"
        fill="white"/>
      <path d="M154.082 12.2598C153.242 12.2598 152.56 12.9417 152.56 13.7819V34.2184C152.56 35.0586 153.242 35.7405 154.082 35.7405C154.922 35.7405 155.604 35.0586 155.604 34.2184V13.7819C155.604 12.9417 154.922 12.2598 154.082 12.2598Z"
        fill="white"/>
      <path d="M162.478 8.70654H145.688C144.848 8.70654 144.166 9.38844 144.166 10.2286C144.166 11.0688 144.848 11.7507 145.688 11.7507H162.478C163.318 11.7507 164 11.0688 164 10.2286C164 9.38844 163.318 8.70654 162.478 8.70654Z"
        fill="white"/>
      <path d="M23.5213 0C11.8479 0 2.30952 9.30098 1.92189 20.881H0V22.5046H1.92798C2.39678 34.0116 11.9027 43.2273 23.5234 43.2273C35.144 43.2273 44.65 34.0096 45.1188 22.5046H47.0468V20.881H45.1249C44.7352 9.30098 35.1948 0 23.5213 0ZM23.5213 41.6058C12.4974 41.6058 3.53125 32.6376 3.53125 21.6157C3.53125 10.5937 12.4994 1.62356 23.5213 1.62356C34.5433 1.62356 43.5114 10.5917 43.5114 21.6137C43.5114 32.6356 34.5433 41.6038 23.5213 41.6038V41.6058Z"
        fill="white"/>
      <path d="M30.4113 27.8175C28.4204 27.8175 26.9958 25.6825 24.4143 21.8083L22.5492 19.0198V19.028C20.3676 15.8255 18.7724 13.9482 16.3107 13.9482C12.9337 13.9482 10.4639 17.9848 8.98641 21.372L10.474 22.0214C12.2619 17.9239 14.3888 15.5738 16.3107 15.5738C18.2326 15.5738 19.7242 17.7088 22.3077 21.583L24.1728 24.3715V24.3634C26.3545 27.5659 27.9496 29.4431 30.4113 29.4431C33.7883 29.4431 36.2582 25.4065 37.7356 22.0194L36.248 21.3699C34.4601 25.4674 32.3332 27.8175 30.4113 27.8175Z"
        fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_25_2569">
        <rect width="164"
          height="43.2294"
          fill="white"/>
      </clipPath>
    </defs>
  </svg>
)
