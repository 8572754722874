import * as Yup from 'yup';
import { CurrencyEnum, LanguageEnum, TitleEnum } from "../../../../enums";

export interface EditBusinessFormType {
  city:	string,
  country:	string,
  region: string,
  houseNumber:	string,
  street:	string,
  zipCode:	string,
  name: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  language: LanguageEnum,
  title: TitleEnum,
  userPerMonthCost: number,
  userPerMonthCurrency: CurrencyEnum
}

export const editUserNameFormSchema = Yup.object().shape({
  name: Yup.string()
    .max(255)
    .required('schemaRequiredBusiness'),
  phoneNumber: Yup.string()
    .max(20)
    .required('schemaRequiredPhone'),
  city: Yup.string()
    .max(255)
    .required('schemaRequiredCity'),
  country: Yup.string()
    .max(255)
    .required('schemaRequiredCountry'),
  region: Yup.string()
    .max(255)
    .required('schemaRequiredRegion'),
  houseNumber: Yup.string()
    .max(255)
    .required('schemaRequiredHouseNumber'),
  street: Yup.string()
    .max(255)
    .required('schemaRequiredStreet'),
  zipCode: Yup.string()
    .max(255)
    .required('schemaRequiredZip'),
  firstName: Yup.string()
    .max(255)
    .required('schemaRequiredFirstName'),
  lastName: Yup.string()
    .max(255)
    .required('schemaRequiredLastName'),
  language: Yup.string()
    .max(255)
    .required('schemaRequireLanguage'),
  title: Yup.string()
    .max(255)
    .required('schemaRequiredTitle'),
  userPerMonthCost: Yup.number().required(),
  userPerMonthCurrency: Yup.string()
    .max(255).required(),
});
