import theme from "../../../theme";

export const reportCardSx = {
  root: {
    borderRadius: theme.spacing(8/8),
    backgroundColor: theme.palette.common.white,
  },
  headerWrapper: {
    flexDirection: "row",
    alignItems: "center",
    borderBottom: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`,
    px: 3,
    py: 2
  },
  header: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 2,
    pl: 1,
    [theme.breakpoints.down('lg')]: {
      gap: 0,
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start"
    }
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    pr: 4
  },
  params: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    px: 3,
    py: 2,
    [theme.breakpoints.down('sm')]: {
      gap: 2
    }
  },
  amountStack: {
    flexDirection: "row",
    gap: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },
  amountText: {
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(12/8)
    }
  },
  description: {
    fontSize: "0.625em",
    fontWeight: 500,
    color: theme.palette.neutral[400],
    textTransform: "uppercase",
    mt: 0.5
  },
  arrow: {
    color: theme.palette.neutral[400]
  }
}
