import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const langSelectSx = (white: boolean) => ({
  maxWidth: theme.spacing(100/8),
  '& .MuiSelect-select': {
    paddingLeft: theme.spacing(10/8),
    fontWeight: 600,
    color: !white ? theme.palette.text.primary : theme.palette.text.secondary,
  }
})
export const langWrapperSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'flex-end'
}
