import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const TableIdCellWrapperSx =(withoutCopy: boolean): SxProps => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  '&:hover button': {
    opacity: 1
  },
  '&:hover button:last-child, &:hover > span': {
    marginLeft: withoutCopy ? 0 : theme.spacing(32/8)
  },
  '& button, & span': {
    color: theme.palette.primary.main,
    textDecoration:'underline',
    marginRight: theme.spacing(4/8),
    position: 'relative',
    zIndex: 5,
    background: 'transparent',
    transition: '0.3s ease',
  },
})
export const copyButtonSx: SxProps = {
  '&.MuiIconButton-root': {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    opacity: 0,
    width: theme.spacing(36/8),
    height: theme.spacing(36/8)
  },
  '& svg': {
    width: theme.spacing(16/8)
  }
}
