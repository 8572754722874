import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const wrapperSx: SxProps<Theme> = {
  background: theme.palette.background.default,
  borderRadius: theme.spacing(8/8),
  padding: theme.spacing(10/8, 15/8),
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: theme.spacing(5/8)
}

export const titleSx: SxProps<Theme> = {
  [theme.breakpoints.down('lg')]: {
    pr: '2rem'
  }
}
export const headerSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // position: 'absolute',
    // right: '1rem',
    // top: '1rem'
  }
}

export const valueSx = (value: string) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: () => {
    switch (value) {
      case 'True':
        return theme.palette.primary.main
      case 'False': 
        return theme.palette.brand.main
      default:
        return theme.palette.neutral[900]
    }
  }
})

export const editButtonSx: SxProps<Theme> = {
  background: 'transparent',
  [theme.breakpoints.down('lg')]: {
    position: 'absolute',
    right: 0,
    top: 0
  }
}

export const actionsSx: SxProps<Theme> = {
  display:'flex',
  alignItems: 'center',
  gap: theme.spacing(5/8)
}

export const formWrapperSx: SxProps<Theme> = {
  '& .MuiPaper-root': {
    maxWidth: theme.spacing(200/8),
    width: '100%',
  }
}
