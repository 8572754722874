import { FC } from "react";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { Box, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { AMPInput } from "../../common/AMPInput";
import { getHelperText } from "../../../utils/forms";
import { CancelButton } from "../../common/CancelButton";
import { SubmitButton } from "../../common/SubmitButton";
import { createRfidFormSchema, CreateRfidFormType } from "../formData/rfid/rfid";
import { usersApi } from "../../../api";
import { AppDispatch } from "../../../app/store";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { CreateRfidDTO } from "../../../types";
import { useAppDispatch } from "../../../app/hooks";
import { CreateRfidFormProps } from "./CreateRfidForm.types";
import { actionsWrapperSx, formContentSx, formSx } from "./CreateRfidForm.sx";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

const initialValue = {
  rfidTag: ""
}

type AddRfidMutationTrigger = ReturnType<typeof usersApi.useCreateRfidMutation>[0];

const handleSubmit =
  (
    trigger: AddRfidMutationTrigger,
    dispatch: AppDispatch,
    userId: number | undefined,
    businessId: number | undefined,
    handleClose: () => void
  ): FormikConfig<CreateRfidFormType>["onSubmit"] => {
    return async (value) => {
      try {
        dispatch(preloaderChangeStatus(true));
        const {
          rfidTag: ocppTag
        } = value;
        const data: CreateRfidDTO = {
          ocppTag,
          businessId,
          userId
        };
        if (userId) {
          await trigger({data, userId}).unwrap()
        }
        handleClose()
      } catch (error) {
        if(isErrorObject(error)){
          NotifyError(error.data.message)
        }
      } finally {
        dispatch(preloaderChangeStatus(false));
      }
    };
  }

export const CreateRfidForm:FC<CreateRfidFormProps> = ({ handleClose, userData }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [trigger] = usersApi.useCreateRfidMutation();

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={createRfidFormSchema}
      onSubmit={handleSubmit(trigger, dispatch, userData?.id,  userData?.business?.id, handleClose)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }: FormikProps<CreateRfidFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <Grid
                container
              >
                <Grid
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["rfidTag"]}
                    type={"rfidTag"}
                    label={"rfidTag"}
                    input={"rfidTag"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["rfidTag"] && Boolean(errors["rfidTag"])
                    }
                    helperText={getHelperText("rfidTag", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={handleClose}
              />
              <SubmitButton
                text="save"
                color="primary"
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
