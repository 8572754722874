import { FC } from "react";

export const TimeIcon: FC = () => {
  return (
    <svg
      width="108"
      height="107"
      viewBox="0 0 108 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.5577 105.101C82.5844 105.101 106.115 81.7975 106.115 53.0507C106.115 24.3039 82.5844 1 53.5577 1C24.5309 1 1 24.3039 1 53.0507C1 81.7975 24.5309 105.101 53.5577 105.101Z"
        fill="#F1F3F9"
        stroke="#AAB2C5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M53.5563 97.3277C78.1964 97.3277 98.1711 77.5042 98.1711 53.0508C98.1711 28.5974 78.1964 8.77393 53.5563 8.77393C28.9162 8.77393 8.94141 28.5974 8.94141 53.0508C8.94141 77.5042 28.9162 97.3277 53.5563 97.3277Z"
        fill="white"
        stroke="#D6DCE8"
        strokeWidth="2"
        strokeMiterlimit="10"/>
      <path
        opacity="0.3"
        d="M98.3401 52.8818C98.3401 53.7268 98.3401 54.4028 98.1711 55.2477C96.9882 31.9263 77.5536 13.3368 53.7253 13.3368C29.8969 13.3368 10.4624 31.9263 9.1104 55.2477C9.1104 54.4028 8.94141 53.7268 8.94141 52.8818C8.94141 28.5464 29.0519 8.77393 53.5563 8.77393C78.3986 8.77393 98.3401 28.5464 98.3401 52.8818Z"
        fill="#D6DCE8"
        stroke="#D6DCE8"
        strokeWidth="2"
        strokeMiterlimit="10"/>
      <path
        d="M52.5451 50.8539L49.1652 54.2338L28.2097 33.6163C27.1957 32.6024 27.1957 31.0814 28.2097 30.2364C29.2237 29.2225 30.7446 29.2225 31.5896 30.2364L52.5451 50.8539Z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeWidth="1.9781"
        strokeMiterlimit="10"/>
      <path
        d="M57.7823 54.4028L54.4023 51.0229L69.9499 35.6443C70.9639 34.6303 72.4849 34.6303 73.3299 35.6443C74.3438 36.6582 74.3438 38.1792 73.3299 39.0242L57.7823 54.4028Z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeWidth="1.9781"
        strokeMiterlimit="10"/>
      <path
        d="M51.5279 52.3748C52.8798 51.0228 54.7388 51.0228 55.9217 52.3748C57.2737 53.7268 57.2737 55.5857 55.9217 56.5997C54.5698 57.6137 52.7108 57.9517 51.5279 56.5997C50.3449 55.4167 50.3449 53.5578 51.5279 52.3748Z"
        fill="#AAB2C5"/>
      <path
        d="M64.7096 88.3707C64.3716 88.3707 64.0336 88.2017 64.0336 87.8637L53.0489 54.7406C52.8799 54.4026 53.2179 53.8956 53.5558 53.7266C53.8938 53.5576 54.4008 53.8956 54.5698 54.2336L65.5545 87.3567C65.7235 87.6947 65.3855 88.2017 65.0475 88.3707C64.8786 88.3707 64.8786 88.3707 64.7096 88.3707Z"
        fill="#AAB2C5"/>
      <path
        d="M50.0107 50.8538C52.2077 48.8259 55.5876 48.8259 57.6156 50.8538C59.8125 52.8818 59.8125 56.2617 57.6156 58.4587C55.4186 60.6556 52.0387 60.4866 50.0107 58.4587C47.9828 56.4307 47.8138 52.8818 50.0107 50.8538Z"
        fill="white"
        stroke="#D6DCE8"
        strokeWidth="2"
        strokeMiterlimit="10"/>
      <path
        d="M55.2452 12.8296H52.5413C52.0343 12.8296 51.5273 13.3366 51.5273 13.8436V21.7864C51.5273 22.2933 52.0343 22.8003 52.5413 22.8003H55.2452C55.7522 22.8003 56.2592 22.2933 56.2592 21.7864V13.6746C56.0902 13.1676 55.7522 12.8296 55.2452 12.8296Z"
        fill="#D6DCE8"/>
      <path
        d="M55.2452 82.9629H52.5413C52.0343 82.9629 51.5273 83.4699 51.5273 83.9769V91.9197C51.5273 92.4266 52.0343 92.9336 52.5413 92.9336H55.2452C55.7522 92.9336 56.2592 92.4266 56.2592 91.9197V83.9769C56.0902 83.4699 55.7522 82.9629 55.2452 82.9629Z"
        fill="#D6DCE8"/>
      <path
        d="M22.2927 50.3467H14.3499C13.8429 50.3467 13.3359 50.8537 13.3359 51.3607V54.0646C13.3359 54.5716 13.8429 55.0786 14.3499 55.0786H22.2927C22.7997 55.0786 23.3067 54.5716 23.3067 54.0646V51.3607C23.3067 50.8537 22.7997 50.3467 22.2927 50.3467Z"
        fill="#D6DCE8"/>
      <path
        d="M93.2693 50.5156H85.3265C84.8195 50.5156 84.3125 51.0226 84.3125 51.5296V54.2335C84.3125 54.7405 84.8195 55.2475 85.3265 55.2475H93.2693C93.7763 55.2475 94.2832 54.7405 94.2832 54.2335V51.5296C94.1142 51.0226 93.7763 50.5156 93.2693 50.5156Z"
        fill="#D6DCE8"/>
    </svg>
  )
}
