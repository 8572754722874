import { SxProps } from "@mui/material";
import theme from "src/theme";

export const placeholderWrapperSx: SxProps = {
  position: 'relative',
  width: '100%'
}

export const autocompleteSx: SxProps = {
  '& .MuiFormControl-root': {
    margin: theme.spacing(0)
  },
}

export const descriptionSx: SxProps = {
  fontSize: '0.75rem',
  color: theme.palette.neutral[600],
  py: 1
}

export const optionStack: SxProps = {
  flexDirection: "row",
  alignItems: "center",
  gap: 2
}
