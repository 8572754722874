import { ChangeEvent, FC } from "react";
import { Box } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useIntl } from "react-intl";
import { AMPInput } from "src/components/common/AMPInput";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { getHelperText } from "src/utils/forms";
import { AMPSwitcher } from "../../common/AMPSwitcher";
import { LanguageEnum, NormalizeLanguageEnum } from "../../../enums";
import { AMPMarkdownEditor } from "../../common/AMPMarkdownEditor";
import { contractTermsFormSchema, ContractTermsFormType } from "../formData/ContractTermsForm";
import { ContractTermsFormProps } from "./ContractTermsForm.types";
import { actionsWrapperSx, formContentSx, formSx } from "./ContractTermsForm.sx";

const processedLanguages = () => {
  return Object.values(LanguageEnum).map((item, index) => ({
    id: index,
    name: NormalizeLanguageEnum[item],
    value: item
  }))
}

export const ContractTermsForm: FC<ContractTermsFormProps> = ({ 
  submit,
  englishInit,
  frenchInit,
  germanInit,
  closeModal
}) => {
  const intl = useIntl();
  
  const handleChangeSwitcher = (
    change: (e: ChangeEvent) => void,
    setFieldValue:  (field: string, value: string, shouldValidate?: (boolean | undefined)) => void) => {
    return (e: ChangeEvent) => {
      change(e)
      const value = (e?.target as HTMLInputElement).value 
      const initsMap: Record<string,  ContractTermsFormType> = {
        [LanguageEnum.ENGLISH]: englishInit,
        [LanguageEnum.FRENCH]: frenchInit,
        [LanguageEnum.DEUTSCH]: germanInit
      }
      const newInit = initsMap[value ?? englishInit]
      setFieldValue('contractTerms', newInit.contractTerms)
      setFieldValue('termsLink', newInit.termsLink)
    }
  }
  
  return (
    <Formik
      initialValues={englishInit}
      validationSchema={contractTermsFormSchema}
      onSubmit={submit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }: FormikProps<ContractTermsFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <AMPSwitcher
                value={values["language"]}
                input={"language"}
                label={"language"}
                data={processedLanguages()}
                onChange={handleChangeSwitcher(handleChange, setFieldValue)}
              />
              {values['language'] === LanguageEnum.ENGLISH &&
                <AMPMarkdownEditor
                  input="contractTerms"
                  value={values["contractTerms"]}
                  label="termsOfContract"
                  setValue={setFieldValue}
                  onBlur={handleBlur}
                  hasError={
                    touched["contractTerms"] && Boolean(errors["contractTerms"])
                  }
                  helperText={getHelperText(
                    "contractTerms",
                    touched,
                    errors,
                    intl
                  )}
                />
              }
              {values['language'] === LanguageEnum.FRENCH &&
                <AMPMarkdownEditor
                  input="contractTerms"
                  value={values["contractTerms"]}
                  label="termsOfContract"
                  setValue={setFieldValue}
                  onBlur={handleBlur}
                  hasError={
                    touched["contractTerms"] && Boolean(errors["contractTerms"])
                  }
                  helperText={getHelperText(
                    "contractTerms",
                    touched,
                    errors,
                    intl
                  )}
                />
              }
              {values['language'] === LanguageEnum.DEUTSCH &&
                <AMPMarkdownEditor
                  input="contractTerms"
                  value={values["contractTerms"]}
                  label="termsOfContract"
                  setValue={setFieldValue}
                  onBlur={handleBlur}
                  hasError={
                    touched["contractTerms"] && Boolean(errors["contractTerms"])
                  }
                  helperText={getHelperText(
                    "contractTerms",
                    touched,
                    errors,
                    intl
                  )}
                />
              }
              <AMPInput
                value={values["termsLink"]}
                placeholder={intl.formatMessage({ id: "exampleUrl" })}
                type={"text"}
                label={"linkToDocument"}
                input={"termsLink"}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={
                  touched["termsLink"] && Boolean(errors["termsLink"])
                }
                helperText={getHelperText("termsLink", touched, errors, intl)}
              />
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={closeModal}
              />
              <SubmitButton
                text="ok"
                color="primary"
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
