import { AppDispatch } from "../app/store";
import { preloaderChangeStatus } from "../stores/common/commonSlice";
import { isErrorObject } from "../utils/helper";
import { NotifyError } from "../utils/notification";
import { systemApi } from "../api/system";

type CountriesTrigger = ReturnType<typeof systemApi.useLazyGetCountriesQuery>[0]

export const handleGetCountries = (trigger: CountriesTrigger, dispatch: AppDispatch) => {
  try {
    dispatch(preloaderChangeStatus(true));
    trigger(null)
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
}
