import { FC } from "react";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useIntl } from "react-intl";
import { PageHeader } from "../../components/common/PageHeader";
import { useAppSelector } from "../../app/hooks";
import ability from "../../permissions/ability";
import { RevenueByCities } from "../../components/reports/RevenueReports/RevenueByCities";
import { RevenueByLocations } from "../../components/reports/RevenueReports/RevenueByLocations";
import { RevenueByBusinesses } from "../../components/reports/RevenueReports/RevenueByBusinesses";

export const RevenueReport:FC = () => {
  const intl = useIntl()
  const { authInfo } = useAppSelector((state) => state.common);
  const canReadBusinessReport = ability.can('read', 'businessReports')

  return (
    <Box>
      <PageHeader
        title={intl.formatMessage({ id: "totalRevenue" })}
        onlyTitle
      />
      {!canReadBusinessReport &&
        <Stack gap={2}>
          <RevenueByCities businessId={authInfo?.business?.id ?? 0} />
          <RevenueByLocations businessId={authInfo?.business?.id ?? 0} />
        </Stack>
      }
      {canReadBusinessReport &&
        <RevenueByBusinesses/>
      }
    </Box>
  )
}
