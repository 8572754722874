import { FC } from "react";

export const EditIcon:FC = () => {
  return (
    <svg width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.83333 12.1773L7.51083 12.1648L15.5375 4.21484C15.8525 3.89984 16.0258 3.4815 16.0258 3.0365C16.0258 2.5915 15.8525 2.17317 15.5375 1.85817L14.2158 0.536504C13.5858 -0.093496 12.4867 -0.0901628 11.8617 0.534004L3.83333 8.48567V12.1773ZM13.0375 1.71484L14.3617 3.034L13.0308 4.35234L11.7092 3.0315L13.0375 1.71484ZM5.5 9.18067L10.525 4.20317L11.8467 5.52484L6.8225 10.5007L5.5 10.5048V9.18067Z"
        fill="currentColor"/>
      <path d="M2.16667 15.4998H13.8333C14.7525 15.4998 15.5 14.7523 15.5 13.8332V6.60984L13.8333 8.2765V13.8332H4.79833C4.77667 13.8332 4.75417 13.8415 4.7325 13.8415C4.705 13.8415 4.6775 13.834 4.64917 13.8332H2.16667V2.1665H7.8725L9.53917 0.499837H2.16667C1.2475 0.499837 0.5 1.24734 0.5 2.1665V13.8332C0.5 14.7523 1.2475 15.4998 2.16667 15.4998Z"
        fill="currentColor"/>
    </svg>
  )
}
