import { FC } from "react";

export const AmpersIcon: FC = () => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="46"
      height="46"
      rx="8"
      fill="#DEF0FC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5584 23.77V27.3H26.0984V20.6901C26.0984 18.9725 24.706 17.5801 22.9884 17.5801C21.2708 17.5801 19.8784 18.9725 19.8784 20.6901V27.3H21.4184V23.77H24.5584ZM22.9884 19.1201C22.1214 19.1201 21.4184 19.823 21.4184 20.6901V22.23H24.5584V20.6901C24.5584 19.823 23.8555 19.1201 22.9884 19.1201Z"
      fill="#007FDC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9516 33.0999C28.2705 33.0999 32.6292 28.9883 33.0226 23.77H37.6016V22.23H33.0227C32.6293 17.0116 28.2706 12.8999 22.9516 12.8999C17.6325 12.8999 13.2738 17.0116 12.8805 22.23H8.39844V23.77H12.8805C13.2739 28.9883 17.6326 33.0999 22.9516 33.0999ZM22.9516 31.5599C27.6791 31.5599 31.5116 27.7275 31.5116 22.9999C31.5116 18.2723 27.6791 14.4399 22.9516 14.4399C18.224 14.4399 14.3916 18.2723 14.3916 22.9999C14.3916 27.7275 18.224 31.5599 22.9516 31.5599Z"
      fill="#007FDC"
    />
  </svg>
);
