import { FilterList, Search } from "@mui/icons-material";
import {
  Box,
  debounce,
  IconButton,
  OutlinedInput,
  Popover,
  Typography
} from "@mui/material";
import { ChangeEvent, FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { businessesApi, locationsApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { ContractStatusEnum, RoleEnum } from "src/enums";
import ability from "src/permissions/ability";
import { AutocompleteValue, BaseLocationInfo, BusinessDTO, ContractFilterValue } from "src/types";
import { ContractsFilterForm } from "../ContractsFilterForm";
import {
  actionsWrapperSx,
  filterButtonSx,
  filterIconActiveSx,
  filterTextSx,
  filterWrapperSx,
  formWrapperSx,
  searchInputSx
} from "./ContractsTableActions.sx";
import { ContractsTableActionsType } from "./ContractsTableActions.types";
import { store } from "../../../app/store";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { handleScroll, isErrorObject } from "../../../utils/helper";
import { NotifyError } from "../../../utils/notification";
import { FormikProps } from "formik";
import { AMPAutocompleteProps } from "../../common/AMPAutocomplete/AMPAutocomplete.types";

type BusinessesQueryTrigger = ReturnType<typeof businessesApi.useLazyGetBusinessesQuery>[0];
type LocationsQueryTrigger = ReturnType<typeof locationsApi.useLazyGetLocationsQuery>[0];

export const getBusinessesData = async (trigger: BusinessesQueryTrigger, page: number, size: number) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    await trigger({
      params: {
        size,
        page
      }
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message ?? error.data?.error)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

export const handleGetLocations = async (
  trigger: LocationsQueryTrigger,
  businessId: number,
  page: number,
  pageSize: number,
  keyword?: string
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    return await trigger({
      data: {
        businessId,
        keyword
      },
      params: {
        size: pageSize,
        page: page
      }}).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message ?? error.data?.error)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

export const ContractsTableActions: FC<ContractsTableActionsType> = ({ handleSearch, handleFilter, businessId }) => {
  const intl = useIntl()
  const pageSizeBusiness = 10
  const pageSizeLocations = 10
  const [pageBusiness, setPageBusiness] = useState(0)
  const [pageLocations, setPageLocations] = useState(0)
  const [businessData, setBusinessData] = useState<Array<BusinessDTO>>([])
  const [locationsData, setLocationsData] = useState<Array<BaseLocationInfo>>([])
  const [keyLocations, setKeyLocations] = useState('')
  const [activeLocation, setActiveLocation] = useState<number | undefined>(undefined)
  const [activeStatus, setActiveStatus] = useState<ContractStatusEnum | undefined>(undefined)
  const [activeBusiness, setActiveBusiness] = useState<number | undefined>(businessId)
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const { roles } = useAppSelector(state => state.common.authInfo)
  const isSuperAdmin = roles[0].role === RoleEnum.SUPER_ADMIN

  const [getLocations, locations] = locationsApi.useLazyGetLocationsQuery()
  const [getBusinesses, businesses] = businessesApi.useLazyGetBusinessesQuery()
  
  useEffect(() => {
    if (isSuperAdmin) {
      getBusinessesData(
        getBusinesses,
        pageBusiness,
        pageSizeBusiness
      )
    }
  }, [pageBusiness])
  
  useEffect(() => {
    if (activeBusiness) {
      handleGetLocations(
        getLocations,
        activeBusiness ?? businessId,
        pageLocations,
        pageSizeLocations,
        keyLocations
      )
    }
  }, [activeBusiness])
  
  const processedBusinesses  = useMemo(():AutocompleteValue[] => {
    if (businessData && ability.can('read', 'businessReports')) {
      return businessData?.map(item => ({
        id: item?.id,
        label: item?.name,
        image: item?.logo?.url ?? ""
      }))
    }
    return []
  }, [businessData])
  
  const processedLocations = useMemo(() => {
    if(!locationsData.length){
      return []
    }
    return locationsData.map((location: BaseLocationInfo) => ({
      id: location.id,
      label: location.name
    }))
  }, [locationsData])
  
  useEffect(() => {
    const content = businesses?.data?.content ?? []
    const allData = [...businessData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setBusinessData(Array.from(Object.values(allData)))
  }, [businesses?.data?.content])
  
  useEffect(() => {
    const content = locations?.data?.content ?? []
    const allData = [...locationsData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setLocationsData(Array.from(Object.values(allData)))
  }, [locations?.data?.content])
  
  useEffect(() => {
    if (activeBusiness) {
      handleGetLocations(
        getLocations,
        activeBusiness,
        pageLocations,
        pageSizeLocations,
        keyLocations
      )
    }
  }, [activeBusiness, pageLocations])
  
  const handleScrollBusiness = () => {
    handleScroll(
      businesses?.data?.number ?? 0,
      businesses?.data?.totalPages ?? 0,
      pageBusiness,
      setPageBusiness
    )
  }
  
  const handleScrollLocations = () => {
    handleScroll(
      locations?.data?.number ?? 0,
      locations?.data?.totalPages ?? 0,
      pageLocations,
      setPageLocations
    )
  }
  
  const handleInputBusiness = () =>
    (event: SyntheticEvent, value: string) => {
      setPageBusiness(0)
      setPageLocations(0)
      getBusinessesData(
        getBusinesses,
        pageBusiness,
        pageSizeBusiness
      )
    }
  
  const handleInputLocations = () =>
    (event: SyntheticEvent, value: string) => {
      setPageLocations(0)
      if (activeBusiness) {
        handleGetLocations(
          getLocations,
          activeBusiness,
          pageLocations,
          pageSizeLocations,
          keyLocations
        )
      }
      setKeyLocations(value)
    }

  const filterStatus = (value: boolean) => {
    setIsFilterActive(value)
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };  
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSearchFieldChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    debounce(() => handleSearch(event.target.value), 1000)()
  }
  
  const handleChangeAutocompleteValue = (setFieldValue: FormikProps<ContractFilterValue>['setFieldValue'], fieldName: string): AMPAutocompleteProps['onChange'] =>
    (e, newValue) => {
      setFieldValue(fieldName, newValue?.id ?? null)
      if (fieldName === 'businessId') {
        setActiveBusiness(newValue?.id)
        setActiveLocation(undefined)
        setKeyLocations('')
        setLocationsData([])
      }
      if (fieldName === 'locationId') {
        setActiveLocation(newValue?.id)
      }
    }
  
  const handleClear = () => {
    setActiveLocation(undefined)
    setActiveStatus(undefined)
    setActiveBusiness(businessId)
    setKeyLocations('')
    handleFilter({
      locationId: undefined,
      status: undefined,
      businessId: businessId
    })
    filterStatus(false)
  }
  
  return (
    <Box sx={actionsWrapperSx}>
      <OutlinedInput
        sx={searchInputSx}
        color="secondary"
        type="text"
        placeholder={intl.formatMessage({ id: 'search' })}
        name="search"
        onChange={handleSearchFieldChange}
        startAdornment={(<Search />)}
      />
      <Box sx={filterWrapperSx}>
        <IconButton
          sx={filterButtonSx}
          onClick={handleClick}
        >
          {!isFilterActive && <FilterList /> }
          {isFilterActive && <FilterList sx={filterIconActiveSx} />}
          <Typography variant='body1'
            sx={filterTextSx}>
            <FormattedMessage id='filter' />
          </Typography>
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={formWrapperSx}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ContractsFilterForm
            businessId={businessId}
            locations={processedLocations ?? []}
            businesses={processedBusinesses ?? []}
            handleScrollBusiness={handleScrollBusiness}
            handleScrollLocations={handleScrollLocations}
            handleInputLocations={handleInputLocations()}
            handleInputBusiness={handleInputBusiness()}
            handleChangeAutocompleteValue={handleChangeAutocompleteValue}
            handleFilter={handleFilter}
            isFilterActive={filterStatus}
            handleClose={handleClose}
            activeLocation={activeLocation}
            activeStatus={activeStatus}
            activeBusiness={activeBusiness}
            setActiveStatus={(value?: ContractStatusEnum) => setActiveStatus(value)}
            handleClear={handleClear}
          />
        </Popover>
      </Box>
    </Box>
  )
}
