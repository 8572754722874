import { CHARGE_POINT_STATUS_TYPE, CHARGING_STATUS_TYPE, CONNECTOR_STATUS_TYPE, CONFIGURATIONS_TYPE } from "src/types"

export enum LanguageEnum {
  ENGLISH = "ENGLISH",
  FRENCH = "FRENCH",
  DEUTSCH = "DEUTSCH"
}

export enum LanguageShortEnum {
  EN = "en",
  FR = "fr",
  DE = "de"
}

export enum NormalizeLanguageEnum {
  ENGLISH = "English",
  FRENCH = "French",
  DEUTSCH = "Deutsch"
}

export enum TitleEnum {
  MISS = "MISS",
  MR = "MR",
  MRS = "MRS",
  OTHER  = "OTHER"
}

export enum AmpWSMessageEnum {
  CHARGE_POINT_STATUS = "CHARGE_POINT_STATUS",
  CHARGING_STATUS = "CHARGING_STATUS",
  CONNECTOR_STATUS = "CONNECTOR_STATUS",
  CONFIGURATIONS = "CONFIGURATIONS",
}

export interface AmpWSMessageMap {
  [AmpWSMessageEnum.CHARGE_POINT_STATUS]: CHARGE_POINT_STATUS_TYPE[],
  [AmpWSMessageEnum.CHARGING_STATUS]: CHARGING_STATUS_TYPE[],
  [AmpWSMessageEnum.CONNECTOR_STATUS]: CONNECTOR_STATUS_TYPE[],
  [AmpWSMessageEnum.CONFIGURATIONS]: CONFIGURATIONS_TYPE[],
}

export const isWSMessageEnum = (input: unknown): input is AmpWSMessageEnum => {
  if(typeof input !== 'string'){
    return false
  }

  return Object.values(AmpWSMessageEnum).includes(input as AmpWSMessageEnum)
}
