import { SxProps, Theme, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import theme from "src/theme";
import { useSxValue } from "./ColorField.hook";
import { ColorFieldType } from "./ColorField.types";

export const ColorField: FC<ColorFieldType> = ({label = 'DRIVER'}) => {
  const sxValue: SxProps<Theme> = Object.assign({}, useSxValue(label), {
    padding: theme.spacing(3/8, 6/8),
    borderRadius: theme.spacing(4/8),
    fontWeight: 500,
    width: 'max-content',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(12/8) 
    }
  })
  return (
    <Typography
      sx={sxValue}
      component="span"
    >
      <FormattedMessage id={label} />
    </Typography>
  )
}
