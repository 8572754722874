import { FilterList, Search } from "@mui/icons-material";
import {
  Box,
  debounce,
  IconButton,
  OutlinedInput,
  Popover,
  Typography
} from "@mui/material";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { businessesApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { ChargePointStatus, ConnectorStatusEnum, RoleEnum } from "src/enums";
import ability from "src/permissions/ability";
import { ChargerFilterForm } from "../ChargerFilterForm";
import {
  actionsWrapperSx,
  filterButtonSx,
  filterButtonTextSx,
  filterIconActiveSx,
  filterWrapperSx,
  formWrapperSx,
  searchInputSx
} from "./ChargerTableActions.sx";
import { ChargerTableActionsType } from "./ChargerTableActions.types";

export const ChargerTableActions: FC<ChargerTableActionsType> = ({ handleSearch, handleFilter }) => {
  const intl = useIntl()

  const [activeCountry, setActiveCountry] = useState<string>('')
  const [activeCity, setActiveCity] = useState<string>('')
  const [activeConnectorStatus, setActiveConnectorStatus] = useState<string | ConnectorStatusEnum>('')
  const [activeChargePointStatus, setActiveChargePointStatus] = useState<string | ChargePointStatus>('')
  const [activeBusiness, setActiveBusiness] = useState<number>(0)
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  
  const { roles } = useAppSelector(state => state.common.authInfo)
  const isSuperAdmin = roles[0].role === RoleEnum.SUPER_ADMIN

  const [getBusinesses, businesses] = businessesApi.useLazyGetBusinessesQuery()

  const getData = async () => {
    await getBusinesses({})
  }
  useEffect(() => {
    isSuperAdmin && getData()
  }, [])

  const filterStatus = (value: boolean) => {
    setIsFilterActive(value)
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };  
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSearchFieldChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    debounce(() => handleSearch(event.target.value), 1000)()
  }

  const processedBusinesses = useMemo(() => {
    if(!isSuperAdmin){
      return []
    }
    return businesses.data?.content && businesses.data.content.map((business) => ({
      id: business.id,
      value: String(business.id),
      name: business.name
    }))
  }, [businesses])
  
  return (
    <Box sx={actionsWrapperSx}>
      <OutlinedInput
        sx={searchInputSx}
        color="secondary"
        type="text"
        placeholder={intl.formatMessage({ id: 'chargersSearchFieldPlaceholder' })}
        name="search"
        onChange={handleSearchFieldChange}
        startAdornment={(<Search />)}
      />
      <Box sx={filterWrapperSx}>
        <IconButton
          sx={filterButtonSx}
          onClick={handleClick}
        >
          {!isFilterActive && <FilterList /> }
          {isFilterActive && <FilterList sx={filterIconActiveSx} />}
          <Typography
            sx={filterButtonTextSx}
            variant='body1'
          >
            <FormattedMessage id='filter' />
          </Typography>
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={formWrapperSx}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ChargerFilterForm
            businesses={processedBusinesses ?? []}
            handleFilter={handleFilter}
            isFilterActive={filterStatus}
            handleClose={handleClose}
            activeCountry={activeCountry}
            activeCity={activeCity}
            activeBusiness={activeBusiness}
            setActiveBusiness={setActiveBusiness}
            activeConnectorStatus={activeConnectorStatus}
            setActiveConnectorStatus={setActiveConnectorStatus}
            activeChargePointStatus={activeChargePointStatus}
            setActiveChargePointStatus={setActiveChargePointStatus}
            setActiveCountry={(value: string) => setActiveCountry(value)}
            setActiveCity={(value: string) => setActiveCity(value)}
          />
        </Popover>
      </Box>
    </Box>
  )
}
