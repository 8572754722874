import { FC } from "react";

export const Google:FC = () => {
  return (
    <svg width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M0.356795 0.307516C0.130225 0.549986 0 0.92318 0 1.40812V18.6572C0 19.1422 0.130225 19.5154 0.365265 19.7494L0.426672 19.8011L10.1332 10.136V9.91882L0.418202 0.255859L0.356795 0.307516Z"
        fill="url(#paint0_linear_4328_392405)"/>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3625 13.3706L10.1249 10.1458V9.92017L13.3636 6.69531L13.4334 6.73854L17.264 8.90601C18.3608 9.52167 18.3608 10.5358 17.264 11.1599L13.4334 13.3274C13.4324 13.3274 13.3625 13.3706 13.3625 13.3706Z"
        fill="url(#paint1_linear_4328_392405)"/>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4323 13.3276L10.1248 10.0332L0.356888 19.7594C0.713683 20.141 1.31399 20.1843 1.98523 19.8111L13.4323 13.3276Z"
        fill="url(#paint2_linear_4328_392405)"/>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4323 6.73866L1.98523 0.263652C1.31505 -0.117975 0.713683 -0.0663182 0.356888 0.315309L10.1238 10.032L13.4323 6.73866Z"
        fill="url(#paint3_linear_4328_392405)"/>
      <path opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3625 13.2578L1.99373 19.6896C1.35848 20.0533 0.792059 20.028 0.426794 19.698L0.365387 19.7592L0.426794 19.8108C0.792059 20.1397 1.35848 20.1661 1.99373 19.8024L13.4408 13.3274L13.3625 13.2578Z"
        fill="#272747"/>
      <path opacity="0.12"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2627 11.0474L13.3539 13.2581L13.4238 13.3276L17.2543 11.1602C17.8027 10.8481 18.0727 10.4412 18.0727 10.0332C18.0377 10.4064 17.7593 10.7617 17.2627 11.0474Z"
        fill="#272747"/>
      <path opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.98514 0.377323L17.2627 9.0198C17.7593 9.29706 18.0377 9.66077 18.0812 10.034C18.0812 9.62703 17.8112 9.21905 17.2627 8.907L1.98514 0.264521C0.888282 -0.359576 0 0.160154 0 1.40835V1.52115C0 0.272955 0.888282 -0.238341 1.98514 0.377323Z"
        fill="white"/>
      <defs>
        <linearGradient id="paint0_linear_4328_392405"
          x1="9.26159"
          y1="1.22271"
          x2="-6.27772"
          y2="5.38093"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00A0FF"/>
          <stop offset="0.007"
            stopColor="#00A1FF"/>
          <stop offset="0.26"
            stopColor="#00BEFF"/>
          <stop offset="0.512"
            stopColor="#00D2FF"/>
          <stop offset="0.76"
            stopColor="#00DFFF"/>
          <stop offset="1"
            stopColor="#00E3FF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_4328_392405"
          x1="18.6913"
          y1="10.0334"
          x2="-0.266426"
          y2="10.0334"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE000"/>
          <stop offset="0.409"
            stopColor="#FFBD00"/>
          <stop offset="0.775"
            stopColor="#FFA500"/>
          <stop offset="1"
            stopColor="#FF9C00"/>
        </linearGradient>
        <linearGradient id="paint2_linear_4328_392405"
          x1="11.6338"
          y1="11.8254"
          x2="-0.769543"
          y2="32.7905"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF3A44"/>
          <stop offset="1"
            stopColor="#C31162"/>
        </linearGradient>
        <linearGradient id="paint3_linear_4328_392405"
          x1="-2.10194"
          y1="-5.43102"
          x2="3.43155"
          y2="3.93232"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#32A071"/>
          <stop offset="0.069"
            stopColor="#2DA771"/>
          <stop offset="0.476"
            stopColor="#15CF74"/>
          <stop offset="0.801"
            stopColor="#06E775"/>
          <stop offset="1"
            stopColor="#00F076"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
