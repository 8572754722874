import { ChangeEvent, FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { FormikValues } from "formik";
import { PageHeader } from "../../components/common/PageHeader";
import { reportsApi } from "../../api";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { preloaderChangeStatus } from "../../stores/common/commonSlice";
import { ChargingReportTable } from "../../components/reports/ChargingReportTable";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { RoleEnum } from "../../enums";

type ChargingSessionsReportQueryTrigger = ReturnType<typeof reportsApi.useLazyGetChargingSessionsReportQuery>[0];

export const getChargingSessionsData = async (
  getChargingSessionsReports: ChargingSessionsReportQueryTrigger,
  keyword: string,
  page: number,
  size: number,
  businessId?: number,
  active?: boolean,
  locationId?: number,
  userId?: number
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    const firstName = keyword.split(' ')?.[0]
    const lastName = keyword.split(' ')?.[1]
    return getChargingSessionsReports({
      data: {
        active,
        businessId: businessId ?? undefined,
        firstName: firstName?.length ? firstName : undefined,
        lastName: lastName?.length ? lastName : undefined,
        locationId: locationId ?? undefined,
        userId: userId ?? undefined
      },
      pageParams: {
        page,
        size
      }
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

export const ChargingSessions:FC = () => {
  const intl = useIntl()
  const [searchName, setSearchName] = useState('')
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const { authInfo } = useAppSelector((state) => state.common)
  const isActive = undefined
  const [getChargingSessionsReports, chargingSessionsReports] = reportsApi.useLazyGetChargingSessionsReportQuery()
  const activeSessions = chargingSessionsReports?.data?.activeSessions
  const finishedSessions = chargingSessionsReports?.data?.finishedSessions
  const tableData = (activeSessions && finishedSessions)
    ? [...activeSessions, ...finishedSessions]
    : []
  const [location, setLocation] = useState<number | undefined>(undefined)
  const [user, setUser] = useState<number | undefined>(undefined)
  const [business, setBusiness] = useState<number | undefined>(authInfo?.business?.id)
  
  const handleFilter = (values: FormikValues) => {
    setLocation(values['location'])
    setBusiness(values['businessId'])
    setUser(values['user'])
  }
  
  const handleNameChange = () => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchName(e.target.value)
  }
  
  const handlePerPageChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = +e.target.value
    if (typeof val === 'number') {
      setPerPage(val)
      setCurrentPage(0)
    }
  }
  
  const handleChangePage = (e: number) => {
    setCurrentPage(e)
  }
  
  useEffect(() => {
    if (authInfo) {
      getChargingSessionsData(
        getChargingSessionsReports,
        searchName,
        currentPage,
        perPage,
        business,
        isActive,
        location,
        user
      )
    }
  }, [authInfo, searchName, location, user, business, perPage, currentPage])
  
  useEffect(() => {
    setCurrentPage(0)
  }, [searchName, location])
  
  const clearFilter = () => {
    if (authInfo.roles[0].role === RoleEnum.SUPER_ADMIN) {
      setBusiness(undefined)
    }
    setLocation(undefined)
    setUser(undefined)
  }
  
  return (
    <Box>
      <PageHeader
        title={intl.formatMessage({ id: 'chargingSessions' })}
        onlyTitle
      />
      <ChargingReportTable
        data={tableData}
        isActive={isActive}
        page={currentPage}
        totalPages={chargingSessionsReports?.data?.totalPages ?? 0}
        searchValue={searchName}
        perPage={perPage}
        handleNameChange={handleNameChange}
        handleChangeRowsNumber={handlePerPageChange}
        handleChangePage={handleChangePage}
        handleFilter={handleFilter}
        clearFilter={clearFilter}
        initFilter={{
          location,
          businessId: business,
          user
        }}
      />
    </Box>
  )
}
