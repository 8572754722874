import { Download } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import { FC, useContext } from "react";
import { useParams } from "react-router-dom";
import { locationsApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { AppDispatch, store } from "src/app/store";
import LocationInfoContext from "src/pages/LocationInfo/LocationInfo.context";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { NotifyError } from "src/utils/notification";
import { formatFileName, isErrorObject } from "../../../utils/helper";

type DownloadLocationFileMutationTrigger = ReturnType<typeof locationsApi.useDownloadLocationFileQuery>[0];

const handleDownloadFile = (trigger: DownloadLocationFileMutationTrigger, dispatch: AppDispatch, locationId: number, fileId: number, name: string, context: any) => async () => {
  try {
    dispatch(preloaderChangeStatus(true))
    const params = {
      fileId,
      businessId: context.businessId,
    }
    const response = await trigger({
      locationId,
      params
    })
    const tempLink = document.createElement('a');
    tempLink.href = response.data;
    tempLink.setAttribute('download', `${formatFileName(name)}`);
    tempLink.click();
    window.URL.revokeObjectURL(tempLink.href)
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
}

export const DownloadFileFromLocationAction: FC<{row: GridRowParams, name: string}> = ({row, name}) => {
  const { id: locationId } = useParams()
  const [trigger] = locationsApi.useLazyDownloadLocationFileQuery()
  const dispatch = useAppDispatch()
  const context = useContext(LocationInfoContext)

  return (
    <>
      {locationId &&
          <IconButton onClick={handleDownloadFile(trigger, dispatch, +locationId, +row.id, name,  context)}>
            <Download />
          </IconButton>}
    </>
  )
}
