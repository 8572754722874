import { SxProps, Theme } from "@mui/material";

export const styles: SxProps<Theme> = {
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: -1,
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
};
