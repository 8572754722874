import { CurrencyEnum } from 'src/enums';
import * as Yup from 'yup';

export interface BusinessFormType {
  city: string,
  country: string,
  region: string,
  houseNumber: string,
  street: string,
  zipCode: string
  emailAddress: string,
  language: string,
  name: string,
  shareClients: boolean,
  userPerMonthCost: number,
  userPerMonthCurrency: CurrencyEnum
}

export const businessFormSchema = Yup.object().shape({
  name: Yup.string()
    .max(255)
    .required('schemaRequiredCompanyName'),
  emailAddress: Yup.string()
    .email('schemaValidEmail')
    .max(255)
    .required('schemaRequiredEmail'),
  houseNumber: Yup.string()
    .required('schemaRequiredHouseNumber'),
  street: Yup.string()
    .max(255)
    .required('schemaRequiredStreet'),
  city: Yup.string()
    .required('schemaRequiredCity'),
  zipCode: Yup.string()
    .max(255)
    .required('schemaRequiredZip'),
  country: Yup.string()
    .required('schemaRequiredCountry'),
  region: Yup.string()
    .required('schemaRequiredRegion'),
  language: Yup.string()
    .required('schemaLanguageRequired'),
  userPerMonthCost: Yup.string()
    .required('schemaRequiredUserPerMonthCost'),
  userPerMonthCurrency: Yup.string()
    .required('schemaRequiredUserPerMonthCurrency')
});
