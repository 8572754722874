import { FC, Suspense, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useStyles } from "../../common/CancelButton/CancelButton.styles";
import { businessesApi, locationsApi, reportsApi, usersApi } from "../../../api";
import {
  AutocompleteValue,
  BaseLocationInfo,
  BaseUserInfo,
  BusinessDTO,
  TableParamsType,
} from "../../../types";
import { AMPAutocomplete } from "../../common/AMPAutocomplete";
import { store } from "../../../app/store";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { Can } from "../../../permissions/Can";
import { TableFilterFormTypes, TableFilterProps } from "./GenerateReportForm.types";
import { generateReportFormSx } from "./GenerateReportForm.sx";
import ability from "src/permissions/ability";
import { AMPAutocompleteProps } from "src/components/common/AMPAutocomplete/AMPAutocomplete.types";
import { findAutocompleteValueById, handleScroll, isErrorObject } from "src/utils/helper";
import { useAppSelector } from "../../../app/hooks";
import { RoleEnum } from "../../../enums";
import { NotifyError } from "../../../utils/notification";
import DownloadPdf from "../../reports/DownloadPdf";
import { ExcelDocument } from "../../reports/ExcelDocument";
import { getChargingSessionsData } from "../../../pages/ChargingSessions";

type BusinessesQueryTrigger = ReturnType<typeof businessesApi.useLazyGetBusinessesQuery>[0];
type UsersQueryTrigger = ReturnType<typeof usersApi.useLazyGetUsersQuery>[0];
type LocationsQueryTrigger = ReturnType<typeof locationsApi.useLazyGetLocationsQuery>[0];

export const getBusinessesData = async (trigger: BusinessesQueryTrigger, page: number, size: number) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    await trigger({
      params: {
        size,
        page
      }
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message ?? error.data?.error)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

export const handleGetUsers = async (
  getUsers: UsersQueryTrigger,
  businessId: number,
  locationId: number,
  params: TableParamsType,
  keyword?: string
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    const data = {
      businessId,
      locationId,
      keyword
    }
    return await getUsers({data, params}).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message ?? error.data?.error)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

export const handleGetLocations = async (
  trigger: LocationsQueryTrigger,
  businessId: number,
  page: number,
  pageSize: number,
  keyword?: string
) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    return await trigger({
      data: {
        businessId,
        keyword
      },
      params: {
        size: pageSize,
        page: page
      }}).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message ?? error.data?.error)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

export const GenerateReportForm:FC<TableFilterProps> = ({
  businessId,
  handleSubmit,
  closeModal,
  initData,
  isActive
}) => {
  const classes = useStyles()
  const intl = useIntl()
  const pageSizeBusiness = 10
  const pageSizeLocations = 10
  const pageSizeClients = 10
  const [pageBusiness, setPageBusiness] = useState(0)
  const [pageLocations, setPageLocations] = useState(0)
  const [pageClients, setPageClients] = useState(0)
  const [businessData, setBusinessData] = useState<Array<BusinessDTO>>([])
  const [locationsData, setLocationsData] = useState<Array<BaseLocationInfo>>([])
  const [clientsData, setClientsData] = useState<Array<BaseUserInfo>>([])
  const [keyLocations, setKeyLocations] = useState('')
  const [keyClient, setKeyClient] = useState('')
  const { authInfo } = useAppSelector(state => state.common)
  const [getAllChargingSessionsReports, allChargingSessionsReports] = reportsApi.useLazyGetChargingSessionsReportQuery()
  const [documentsVisible, setDocumentsVisible] = useState(false)
  
  const activeSessionsAll = allChargingSessionsReports?.data?.activeSessions
  const finishedSessionsAll = allChargingSessionsReports?.data?.finishedSessions
  const allTableData = (activeSessionsAll && finishedSessionsAll)
    ? [...activeSessionsAll, ...finishedSessionsAll]
    : []
  const data = allTableData.map(item => ({...item, wattsConsumed: +(item.wattsConsumed / 1000).toFixed(2)}))
  
  const [getLocations, locations] = locationsApi.useLazyGetLocationsQuery()
  const [getBusinesses, businesses] = businessesApi.useLazyGetBusinessesQuery()
  const [getUsers, users] = usersApi.useLazyGetUsersQuery()
  const [activeBusiness, setActiveBusiness] = useState<number | null>(businessId ?? null)
  const [activeLocation, setActiveLocation] = useState<number | null>(null)
  const [activeUser, setActiveUser] = useState<number | null>(null)
  const [fileName, setFileName] = useState('')
  
  const generateDocuments = () => {
    setDocumentsVisible(true)
    getChargingSessionsData(
      getAllChargingSessionsReports,
      '',
      0,
      2000,
      activeBusiness ?? undefined,
      isActive,
      activeLocation ?? undefined,
      activeUser ?? undefined
    )
  }
  
  useEffect(() => {
    if (authInfo.roles[0].role === RoleEnum.SUPER_ADMIN) {
      getBusinessesData(
        getBusinesses,
        pageBusiness,
        pageSizeBusiness
      )
    }
  }, [])
  
  useEffect(() => {
    if (activeBusiness) {
      handleGetLocations(
        getLocations,
        activeBusiness,
        pageLocations,
        pageSizeLocations,
        keyLocations
      )
    }
  }, [activeBusiness])
  
  useEffect(() => {
    if (activeLocation && activeBusiness) {
      handleGetUsers(
        getUsers,
        activeBusiness,
        activeLocation,
        {
          page: pageClients,
          size: pageSizeClients
        },
        keyClient
      )
    }
  }, [activeLocation])
  
  const processedBusinesses  = useMemo(():AutocompleteValue[] => {
    if (businessData && ability.can('read', 'businessReports')) {
      return businessData?.map(item => ({
        id: item?.id,
        label: item?.name,
        image: item?.logo?.url ?? ""
      }))
    }
    return []
  }, [businessData])
  
  const processedLocations = useMemo(() => {
    if(!locationsData.length){
      return []
    }
    return locationsData.map((location: BaseLocationInfo) => ({
      id: location.id,
      label: location.name
    }))
  }, [locationsData])
  
  const processedUsers:AutocompleteValue[] = useMemo(() => {
    if(!clientsData.length){
      return [] as AutocompleteValue[]
    }
    return [
      {
        id: -1,
        label: intl.formatMessage({id: "all"})
      },
      ...clientsData.map((user: BaseUserInfo) => (
        {
          id: user.id,
          label: `${user.firstName} ${user.lastName}`
        }))]
  }, [clientsData])
  
  useEffect(() => {
    const content = businesses?.data?.content ?? []
    const allData = [...businessData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setBusinessData(Array.from(Object.values(allData)))
  }, [businesses?.data?.content])
  
  useEffect(() => {
    const content = locations?.data?.content ?? []
    const allData = [...locationsData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setLocationsData(Array.from(Object.values(allData)))
  }, [locations?.data?.content])
  
  useEffect(() => {
    const content = users?.data?.content ?? []
    const allData = [...clientsData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setClientsData(Array.from(Object.values(allData)))
  }, [users?.data?.content])
  
  useEffect(() => {
    getBusinessesData(
      getBusinesses,
      pageBusiness,
      pageSizeBusiness
    )
  }, [pageBusiness])
  
  useEffect(() => {
    if (activeBusiness) {
      handleGetLocations(
        getLocations,
        activeBusiness,
        pageLocations,
        pageSizeLocations,
        keyLocations
      )
    }
  }, [activeBusiness, pageLocations])
  
  useEffect(() => {
    if (activeLocation && activeBusiness) {
      handleGetUsers(
        getUsers,
        activeBusiness,
        activeLocation,
        {
          page: pageClients,
          size: pageSizeClients
        },
        keyClient
      )
    }
  }, [activeLocation, pageClients])

  const handleChangeAutocompleteValue = (
    setFieldValue: FormikProps<TableFilterFormTypes>['setFieldValue'], fieldName: string, values: TableFilterFormTypes): AMPAutocompleteProps['onChange'] =>
    (e, newValue) => {
      setFieldValue(fieldName, newValue?.id ?? null)
      setDocumentsVisible(false)
      if ((fieldName === 'businessId') || (!newValue?.label && fieldName === 'location')) {
        if (fieldName === 'businessId') {
          setActiveBusiness(newValue?.id ?? null)
        }
        setActiveLocation(null)
        setActiveUser(null)
        setKeyLocations('')
        setKeyClient('')
        if (fieldName === "businessId") {
          setLocationsData([])
        }
        setClientsData([])
        const name = `${intl.formatMessage({ id: 'chargingSessions' })}_${newValue?.label ??
        processedBusinesses.find(item => item.id === values.businessId)?.label}`
          .split(' ')
          .join('_')
        setFileName(name)
      } else if ((fieldName === 'location') || (!newValue?.label && fieldName === 'user')) {
        setActiveLocation(fieldName === 'location' ? (newValue?.id ?? null) : values.location)
        setActiveUser(null)
        setKeyClient('')
        if (fieldName === "location") {
          setClientsData([])
        }
        const name = `${intl.formatMessage({ id: 'chargingSessions' })}_${newValue?.label ??
          processedLocations.find(item => item.id === values.location)?.label}`
          .split(' ')
          .join('_')
        setFileName(name)
      } else if (fieldName === 'user' && newValue?.id) {
        setActiveUser(newValue.id !== -1 ? newValue.id : null)
        const name = newValue.id !== -1 ? `${intl.formatMessage({ id: 'chargingSessions' })}_${newValue?.label}`
          .split(' ')
          .join('_') :
          `${intl.formatMessage({ id: 'chargingSessions' })}_${
            processedLocations.find(item => item.id === values.location)?.label
          }`
            .split(' ')
            .join('_')
        setFileName(name)
      }
    }
  
  const onSubmit = (values: TableFilterFormTypes) => {
    handleSubmit(values)
    closeModal()
  }
  
  const handleScrollBusiness = () => {
    handleScroll(
      businesses?.data?.number ?? 0,
      businesses?.data?.totalPages ?? 0,
      pageBusiness,
      setPageBusiness
    )
  }
  
  const handleScrollLocations = () => {
    handleScroll(
      locations?.data?.number ?? 0,
      locations?.data?.totalPages ?? 0,
      pageLocations,
      setPageLocations
    )
  }
  
  const handleScrollClients = () => {
    handleScroll(
      users?.data?.number ?? 0,
      users?.data?.totalPages ?? 0,
      pageClients,
      setPageClients
    )
  }
  
  const handleInputBusiness = () =>
    (event: SyntheticEvent, value: string) => {
      setPageBusiness(0)
      setPageLocations(0)
      setPageClients(0)
      getBusinessesData(
        getBusinesses,
        pageBusiness,
        pageSizeBusiness
      )
    }
  
  const handleInputLocations = () =>
    (event: SyntheticEvent, value: string) => {
      setPageLocations(0)
      setPageClients(0)
      if (activeBusiness) {
        handleGetLocations(
          getLocations,
          activeBusiness,
          pageLocations,
          pageSizeLocations,
          keyLocations
        )
      }
      setKeyLocations(value)
    }
  
  const handleInputClients = () =>
    (event: SyntheticEvent, value: string) => {
      setPageClients(0)
      if (activeLocation && activeBusiness) {
        handleGetUsers(
          getUsers,
          activeBusiness,
          activeLocation,
          {
            page: pageClients,
            size: pageSizeClients
          },
          keyClient
        )
      }
      setKeyClient(value)
    }
  
  return (
    <Formik
      initialValues={initData}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        setFieldValue,
        values,
      }: FormikProps<TableFilterFormTypes>) => (
        <Form
          onSubmit={handleSubmit}
        >
          <Box sx={generateReportFormSx.formSx}>
            <Box sx={generateReportFormSx.formContentSx}>
              <Can
                I="read"
                a="businessReports"
              >
                <AMPAutocomplete
                  options={businessId
                    ? [{
                      id: businessId,
                      label: ''
                    }]
                    : processedBusinesses}
                  value={findAutocompleteValueById(processedBusinesses, values['businessId'])}
                  label={'business'}
                  input={'businessId'}
                  handleScroll={handleScrollBusiness}
                  onInputChange={handleInputBusiness()}
                  showAvatar
                  onChange={handleChangeAutocompleteValue(setFieldValue, 'businessId', values)}
                />
              </Can>
              <AMPAutocomplete
                options={processedLocations}
                value={findAutocompleteValueById(processedLocations, values['location'])}
                disabled={!activeBusiness}
                handleScroll={handleScrollLocations}
                onInputChange={handleInputLocations()}
                label={'location'}
                input={'location'}
                onChange={handleChangeAutocompleteValue(setFieldValue, 'location', values)}
              />
              <AMPAutocomplete
                options={processedUsers}
                value={findAutocompleteValueById(processedUsers, values['user'])}
                disabled={!activeLocation}
                handleScroll={handleScrollClients}
                onInputChange={handleInputClients()}
                label={'user'}
                input={'user'}
                onChange={handleChangeAutocompleteValue(setFieldValue, 'user', values)}
              />
            </Box>
            <Stack sx={generateReportFormSx.actions}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={closeModal}
              >
                <Typography
                  variant="button"
                  color="neutral.700"
                >
                  <FormattedMessage id="cancel" />
                </Typography>
              </Button>
              <Button
                variant="outlined"
                disabled={!activeBusiness || !activeLocation}
                className={classes.button}
                onClick={generateDocuments}
              >
                <Typography
                  variant="button"
                  color="neutral.700"
                >
                  <FormattedMessage id="generateReport" />
                </Typography>
              </Button>
            </Stack>
            {documentsVisible && (
              <Stack sx={generateReportFormSx.documents}>
                <Box sx={generateReportFormSx.document}>
                  <Suspense
                    key={'download-pdf'}
                  >
                    <DownloadPdf
                      key={'pdf'}
                      data={data}
                      name={fileName}
                    />
                  </Suspense>
                </Box>
                <Box sx={generateReportFormSx.document}>
                  <ExcelDocument
                    data={data}
                    name={fileName}
                  />
                </Box>
              </Stack>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  )
}
