import { Box } from "@mui/material";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { FC, useContext, useEffect, useState } from "react";
import { Table } from "../../tables/Table";
import { columnConfigUsers } from "./TechnicalTeamTable.tableConfig";
import { TechnicalTeamTableProps } from "./TechnicalTeamTable.types";
import { TableRowCounter } from "src/components/tables/TableRowCounter";
import { TablePagination } from "src/components/tables/TablePagination";
import { UsersSortFieldEnum } from "src/enums";
import { UsersSearchRequestData } from "src/types";
import { tableFooterSx, wrapperSx } from "./TechnicalTeamTable.sx";
import { TechnicalTeamItemCard } from "./TechnicalTeamItemCard";
import DashboardLayoutContext from "src/layouts/DashboardLayout/DashboardLayout.context";
import { UsersFilterValue } from "src/components/users/UsersTableAction/UsersTableActions.types";
import { UsersTableActions } from "src/components/users/UsersTableAction";

export const TechnicalTeamTable: FC<TechnicalTeamTableProps> = ({ data, size, pageNumber, handleUpdateTableData, handleChangePage, handleChangeRowsNumber }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortValue, setSortValue] = useState<GridSortItem>({ field: '', sort: 'asc' });
  const [filterValue, setFilterValue] = useState<UsersFilterValue>({ role: '', businessId: 0 })
  
  const handleSearch = ( value: string ) => {
    setSearchValue(value)
    handleUpdateTableData(updateData(value, sortValue, filterValue))
  }

  const handleFilter = ( value: UsersFilterValue ) => {
    setFilterValue(value)
    handleUpdateTableData(updateData(searchValue, sortValue, value))
  }

  const handleSort = ( value: GridSortModel ) => {
    setSortValue(value[0])
    handleUpdateTableData(updateData(searchValue, value[0], filterValue))
  }
  
  const updateData = (search: string, sort: GridSortItem, filter: UsersFilterValue) => {
    const data: UsersSearchRequestData = {
      keyword: search,
      businessId: filter.businessId,
      sortAsc: sort ? sort.sort === 'asc' : true,
      sortField: (sort && sort.field) || UsersSortFieldEnum.EMAIL
    }
    if(filterValue.role) {
      data["roles"] = [filterValue.role]
    }
    return data;
  }


  const responsive = useContext(DashboardLayoutContext)
  
  return (
    <Box sx={wrapperSx}>
      <UsersTableActions
        handleSearch={handleSearch}
        handleFilter={handleFilter}
        isManagersRoles
      />
      {responsive
        ? <>
          {data.content.map(item => <TechnicalTeamItemCard
            key={item?.id}
            {...item}
          />)}
        </>
        :
        <Table
          rows={data.content}
          hideFooter
          columns={columnConfigUsers}
          handleSort={handleSort}
        />
      }
      <Box sx={tableFooterSx}>
        <TableRowCounter
          handleChangeRowsNumber={handleChangeRowsNumber}
          defaultValue={size}
        />
        <TablePagination
          totalPages={data.totalPages}
          page={pageNumber && pageNumber === 0 ? pageNumber : data.number}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Box>
  )
}
