import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const pageHeaderWrapperSx: SxProps<Theme> = {
  marginTop: theme.spacing(20/8),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(10/8),
  [theme.breakpoints.down('lg')]: {
    flexDirection: "column",
    gap: 0,
    alignItems: "flex-start",
    mb: 2
  }
}
export const titleSx: SxProps<Theme> = {
  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem',
    lineHeight: 1.3,
    mb: 1
  }
}
export const actionButtonSx: SxProps<Theme> = {
  '&.MuiButton-root': {
    borderRadius: theme.spacing(4/8),
    height: theme.spacing(36/8)
  }
}
