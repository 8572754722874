import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { axiosBaseQuery } from "src/axiosBaseQuery"
import {
  AddHistoricalData,
  AddRfidRequest,
  CreateUserRequest,
  DefaultResponse,
  DeleteRfidRequest,
  EditBexioRequest,
  PageBaseUsersInfo,
  PostFeedbackRequest,
  UpdateRfidRequest,
  UserByIdRequest,
  UserDeleteRequest,
  UserDTO,
  UserImageRequest,
  UserNameUpdateRequest,
  UsersSearchRequest,
  UserUpdateRequest
} from "src/types"
import { getBaseURL } from "src/utils/helper"

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL() + '/users'),
  }),
  tagTypes: ['User', 'Team'],
  endpoints:(build) => {
    return {
      getUsers: build.query<PageBaseUsersInfo, UsersSearchRequest>({
        query: ({ data, params }) => ({ url: '/search', method: 'post', data, params }),
        providesTags: (result) => {
          if (result?.content) {
            return [
              { type: 'User' as const, id: 'LIST' },
              ...result.content.map(user => ({
                type: 'User' as const,
                id: user?.id
              }))
            ]
          }
          return [
            { type: 'User' as const, id: 'LIST' }, 'User' as const
          ]
        }
      }),
      getUserById: build.query<UserDTO, UserByIdRequest>({
        query: (params) => ({ url: `/${params.userId}`, method: 'get', params}),
        providesTags: (result, error, arg) => [{ type: 'User' as const, id: arg.userId }]
      }),
      setUserAvatar: build.mutation<UserDTO, UserImageRequest>({
        query: ({userId, data}) => ({ url: `/${userId}/avatar`, method: 'post', data }),
        invalidatesTags: (result, error, arg) => [{ type: 'User' as const, id: arg.userId }]
      }),
      updateUser: build.mutation<UserDTO, UserUpdateRequest>({
        query: ({userId, data}) => ({ url: `/${userId}`, method: 'put', data }),
        invalidatesTags: (result, error, arg) => [
          { type: 'User' as const, id: arg.userId},
          { type: 'User' as const, id: 'LIST' }
        ]
      }),
      updateUserName: build.mutation<UserDTO, UserNameUpdateRequest>({
        query: ({userId, data}) => ({ url: `/${userId}/name`, method: 'put', data }),
        invalidatesTags: (result, error, arg) => [
          { type: 'User' as const, id: arg.userId},
          { type: 'User' as const, id: 'LIST'}
        ]
      }),
      uploadConsumptionHistory: build.mutation<UserDTO, AddHistoricalData>({
        query: ({userId, data}) => ({ url: `/${userId}/historicalData`, method: 'post',  data}),
        invalidatesTags: (result, error, arg) => [{ type: 'User' as const, id: arg.userId}]
      }),
      deleteUserAccount:  build.mutation<UserDTO, UserDeleteRequest>({
        query: ({userId, data}) => ({ url: `/${userId}`, method: 'delete',  params: data}),
        invalidatesTags: (result, error, arg) => [
          { type: 'User' as const, id: arg.userId},
          { type: 'User' as const, id: 'LIST'}
        ]
      }),
      createRfid: build.mutation<UserDTO, AddRfidRequest>({
        query: ({userId, data}) => ({ url: `/${userId}/ocppTag`, method: 'post',  params: data}),
        invalidatesTags: (result, error, arg) => [{ type: 'User' as const, id: arg.userId}]
      }),
      updateRfid: build.mutation<UserDTO, UpdateRfidRequest>({
        query: ({ params, body, path}) => ({
          url: `/${path.userId}/ocppTag/${path.ocppTagId}`,
          method: 'put',
          params,
          data: body
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'User' as const, id: arg.path.userId}]
      }),
      deleteRfid: build.mutation<UserDTO, DeleteRfidRequest>({
        query: ({path, params}) => ({ url: `/${path.userId}/ocppTag/${path.ocppTagId}`, method: 'delete',  params}),
        invalidatesTags: (result, error, arg) => [{ type: 'User' as const, id: arg.path.userId}]
      }),
      deleteUser: build.mutation<DefaultResponse, {businessId: number, userId: number}>({
        query: ({businessId, userId}) => ({ url: `/${userId}`, method: 'delete', params: { businessId } }),
        invalidatesTags: [{ type: 'User' as const, id: 'LIST' }]
      }),
      postFeedback: build.mutation<DefaultResponse, PostFeedbackRequest>({
        query: ({ userId, data }) => ({ url: `/${userId}/feedback`, method: 'post', data }),
      }),
      createManager: build.mutation<UserDTO, CreateUserRequest>({
        query: ({data, businessId}) => ({ url: '/managers', method: 'post', data, params: {businessId} }),
        invalidatesTags: (result, error, arg) => [
          { type: 'User' as const, id: 'LIST' }
        ]
      }),
      editBexioId: build.mutation<UserDTO, EditBexioRequest>({
        query: ({bexioId, userId, businessId}) => ({url: `/${userId}/bexioId`, method: 'put', data: {bexioId, businessId}}),
        invalidatesTags: (result, error, arg) => [{ type: 'User' as const, id: arg.userId}]
      })
    }
  },
})
