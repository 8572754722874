import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const rootSx: SxProps<Theme> = {
  // height: theme.spacing(500/8),
  width: '100%',
  padding: theme.spacing(24/8),
  background: theme.palette.text.secondary,
  borderRadius: theme.spacing(8/8, 8/8, 0, 0),
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(15/8)
  }
}

export const selectWrapperSx: SxProps<Theme> = {
  maxWidth: theme.spacing(200/8)
}

export const titleSx: SxProps<Theme> = {
  marginBottom: theme.spacing(20/8),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const actionButtonWrapperSx: SxProps<Theme> = {
  width: '100%',
  background: theme.palette.neutral[100],
  padding: theme.spacing(15/8, 12/8),
  borderRadius: theme.spacing(0, 0, 8/8, 8/8),
  '& button': {
    display: 'flex',
    gap: theme.spacing(5/8),
    color: theme.palette.text.primary,
  }
}

export const sectionSx: SxProps<Theme> = {
  display: 'flex',
  gap: theme.spacing(12/8),
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  }
}

export const sectionTypographySx: SxProps<Theme> = {
  lineHeight: 1.2,
  width: 'max-content',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(5/8),
    alignItems: 'center',
    maxWidth: theme.spacing(100/8),
    width: '100%'
  }
}
