import { InsertDriveFileOutlined } from '@mui/icons-material';
import { GridColumns } from '@mui/x-data-grid';
import { PopoverTypography } from 'src/components/common/PopoverTypography';
import { RoleEnum } from 'src/enums';
import { DeleteFileFromLocationAction } from '../DeleteFileFromLocationAction';
import { DownloadFileFromLocationAction } from '../DownloadFileFromLocationAction';
import { FilesMenu } from "./FilesMenu";
import { formatFileName } from "../../../utils/helper";

export const columnConfigLocationFiles = (role: RoleEnum): GridColumns =>  ([
  {
    field: 'id',
    flex: 1,
    cellClassName: 'ampTableCell',
    renderCell: () => (
      <InsertDriveFileOutlined />
    )
  },
  {
    field: 'name',
    flex: 6,
    cellClassName: 'ampTableCell',
    renderCell: (params) => {
      const formattedName = formatFileName(params.row.name)
      return (
        <PopoverTypography
          text={decodeURI(formattedName)}
          popoverText={decodeURI(formattedName)}
        />
      )
    }
  },
  {
    field: 'download',
    flex: 1,
    cellClassName: 'ampTableCell hoverableCell',
    renderCell: (params) => (
      <DownloadFileFromLocationAction
        row={params.row}
        name={params.row.name}/>
    )
  },
  {
    field: 'delete',
    flex: 1,
    cellClassName: 'ampTableCell hoverableCell',
    hide: role === RoleEnum.SUPER_ADMIN,
    renderCell: (params) => (
      <DeleteFileFromLocationAction row={params.row}/>
    )
  }
])

export const columnConfigLocationFilesSm = (role: RoleEnum): GridColumns =>  ([
  {
    field: 'id',
    flex: 1,
    cellClassName: 'ampTableCell',
    renderCell: () => (
      <InsertDriveFileOutlined />
    )
  },
  {
    field: 'name',
    flex: 6,
    cellClassName: 'ampTableCell',
    renderCell: (params) => (
      <PopoverTypography
        text={decodeURI(formatFileName(params.row.name))}
        popoverText={decodeURI(formatFileName(params.row.name))}
      />
    )
  },
  {
    field: 'actions',
    flex: 1,
    cellClassName: 'ampTableCell hoverableCell',
    renderCell: (params) => (
      <FilesMenu
        row={params.row}
        name={params.row.name}
        isSuperAdmin={role === RoleEnum.SUPER_ADMIN}
      />
    )
  }
])
