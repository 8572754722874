import { Box } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { invoicesApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { store } from "src/app/store";
import { PageHeader } from "src/components/common/PageHeader";
import { InvoicesTable } from "src/components/invoices/InvoicesTable";
import { InvoiceSortEnum } from "src/enums";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { DataType, InvoiceSearchRequestData } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type RevenueInvoicesQueryTrigger = ReturnType<typeof invoicesApi.useLazyGetInvoicesByBusinessesQuery>[0];

const handleGetInvoices = (trigger: RevenueInvoicesQueryTrigger, businessId?: number, userId?: number, role?: string) => (async (tableData: InvoiceSearchRequestData, params: {page: number, size: number}) => {
  store.dispatch(preloaderChangeStatus(true))

  const newData: DataType = {
    ...tableData,
    businessId: tableData.businessId ? tableData.businessId : businessId
  }
  Object.keys(tableData).map((item: string) => {
    !newData[item] && delete newData[item]
  })

  const data: InvoiceSearchRequestData = {
    ...newData,
  }
  
  try {
    await trigger({ data, params }).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
});

export const RevenueInvoices: FC = () => {
  const intl = useIntl();

  const { authInfo } = useAppSelector((state) => state.common);
  const [trigger, response] = invoicesApi.useLazyGetInvoicesByBusinessesQuery()

  const [tableParams, setTableParams] = useState<{page: number, size: number}>({
    page: 0,
    size: 100
  })

  const [filterParams, setFilterParams] = useState<InvoiceSearchRequestData>({
    businessId: 0,
    status: '',
    keyword: '',
    sortAsc: false,
    sortField: InvoiceSortEnum.CREATED,
  })

  const handleChange = (value: InvoiceSearchRequestData) => {
    setFilterParams(value)
    Object.keys(authInfo) && callHandler(value, tableParams)
  }

  const handleChangePage = (page: number) => {
    Object.keys(authInfo) && callHandler(filterParams, {
      page,
      size: tableParams.size
    })
    setTableParams({
      page,
      size: tableParams.size
    })
  }

  const handleChangeRowsNumber = (e: ChangeEvent<HTMLInputElement>) => {
    Object.keys(authInfo) && callHandler(filterParams, {
      page: 0,
      size: +e.target.value || 100
    })
    setTableParams({
      page: 0,
      size: +e.target.value || 100
    })
  }

  const callHandler = handleGetInvoices(trigger, authInfo.business && +authInfo.business.id, +authInfo.id, authInfo.roles[0].role)

  useEffect(() => {
    Object.keys(authInfo) && callHandler(filterParams, tableParams)
  }, []);

  return (
    <Box>
      <PageHeader
        title={intl.formatMessage({ id: 'invoices' })}
        onlyTitle
      />
      {response.data && (
        <InvoicesTable
          data={response.data}
          size={tableParams.size}
          handleUpdateTableData={handleChange}
          handleChangePage={handleChangePage}
          handleChangeRowsNumber={handleChangeRowsNumber}
        />
      )}
    </Box>
  )
};
