import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { reportsApi } from "../../../../api";
import dayjs from "dayjs";
import { getConsumedPowerData } from "../ConsumptionReports.helpers";
import { ReportBarChart } from "../../ReportBarChart";
import { useAppSelector } from "../../../../app/hooks";

export const ConsumptionCity:FC = () => {
  const intl = useIntl()
  const [getConsumedPowerReportsCities, consumedPowerReportsCities] = reportsApi.useLazyGetConsumedPowerReportQuery()
  const { authInfo } = useAppSelector((state) => state.common)
  const [dates, setDates] = useState<{from?: number, to?: number}>({
    from: undefined,
    to: dayjs().valueOf()
  })
  
  const handleChangeDates = (
    newFrom: number,
    newTo: number,
  ) => {
    return () => {
      setDates({
        from: newFrom,
        to: newTo
      })
    }
  }
  
  useEffect(() => {
    getConsumedPowerData(
      getConsumedPowerReportsCities,
      authInfo?.business?.id,
      dates?.from,
      dates?.to
    )
  }, [dates]);
  
  const cityData = consumedPowerReportsCities?.data?.consumedPowerByCities?.map(item => ({
    name: item.city,
    [intl.formatMessage({id: "consumedPower"})]: item.consumedPower / 1000
  })) ?? []
  
  return (
    <ReportBarChart
      title={intl.formatMessage({ id: "powerConsumptionCity" })}
      data={cityData}
      dataKey={intl.formatMessage({id: "consumedPower"})}
      handleChangeDates={handleChangeDates}
      field="cities"
    />
  )
}