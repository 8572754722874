import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const buttonWrapperSx: SxProps<Theme> = {
  background: 'transparent',
  '&:hover': {
    background: 'transparent'
  },
  '& svg.MuiSvgIcon-root': {
    fill: theme.palette.neutral[700]
  }
}
