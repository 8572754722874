import theme from "../../../theme";

export const chargerListCardSx = {
  menuBtn: {
    width: "100%"
  },
  menuBtnText: {
    width: "100%",
    textAlign: "start",
    fontSize: theme.spacing(14/8),
    fontWeight: 400,
    color: theme.palette.neutral[400],
  },
  popover: {
    '& .MuiPaper-root': {
      maxWidth: theme.spacing(200/8),
      width: '100%',
      p: 1
    }
  }
}
