import { FC } from "react";
import { useIntl } from "react-intl";
import ReactPDF from "@react-pdf/renderer";
import theme from "../../../theme";
import { PdfDocument } from "./PdfDocument";
import { DownloadPdfProps } from "./DownloadPdf.types";

export const DownloadPdf:FC<DownloadPdfProps> = ({ data, name }) => {
  const intl = useIntl()
  
  return (
    <ReactPDF.PDFDownloadLink
      style={{width: "100%", color: theme.palette.text.primary}}
      key={'pdf'}
      document={
        <PdfDocument
          data={data}
          intl={intl}
        />
      }
      fileName={`${name}.pdf`}
    >
      {({ loading }) => (loading
        ? intl.formatMessage({id: "loadingDocument"})
        : intl.formatMessage({id: 'pdf'}))}
    </ReactPDF.PDFDownloadLink>
  )
}

export { DownloadPdf as default }
