import { alpha, SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const cardWrapperSx = (selected?: boolean): SxProps => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(36/8),
  border: `${selected ? theme.palette.brand.main : theme.palette.neutral[300]} ${theme.spacing(2/8)} solid`,
  borderRadius: theme.spacing(8/8),
  position: 'relative',
  background: theme.palette.background.default,
  [theme.breakpoints.down('lg')]: {
    p: 1
  }
})

export const descriptionBoxSx: SxProps<Theme> = {
  p: 1,
  width: theme.spacing(300/8)
}

export const popoverSx: SxProps<Theme> = {
  pointerEvents: "none",
  maxWidth: theme.spacing(330/8),
  '& ol': {
    pl: 2,
    '& li': {
      fontSize:  theme.spacing(12/8),
      listStyle: "auto"
    }
  },
  '& ul': {
    pl: 2,
    '& li': {
      fontSize:  theme.spacing(12/8),
      listStyle: "disc"
    }
  }
}
export const totalSx: SxProps<Theme> = {
  [theme.breakpoints.down('lg')]: {
    lineHeight: 1
  }
}

export const tariffTitleSx: SxProps<Theme> = {
  [theme.breakpoints.down('lg')]: {
    fontSize: theme.spacing(20/8),
    fontWeight: 700
  }
}

export const descriptionSx: SxProps<Theme> = {
  width: 'max-content',
  margin: '0 auto',
  '& button, & button:hover': {
    background: 'transparent'
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: theme.spacing(12/8)
  }
} 

export const monthlyFeeSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  marginTop: theme.spacing(15/8),
  gap: theme.spacing(5/8),
  mb: 1
}

export const tariffParamsSx: SxProps<Theme> = {
  marginTop: theme.spacing(20/8),
  width: '100%'
}

export const cardButtonSx = (selected?: boolean): SxProps => ({
  marginTop: theme.spacing(20/8),
  display: 'flex',
  gap: theme.spacing(5/8),
  alignItems: 'center',
  width: '100%',
  height: theme.spacing(52/8),
  borderRadius: theme.spacing(50/8),
  border: `${theme.spacing(1/8)} solid ${theme.palette.neutral[500]}`,
  background: selected ? theme.palette.brand.main : 'transparent',
  color: selected ? theme.palette.text.secondary : theme.palette.neutral[900],
  '&:hover': {
    background: theme.palette.brand.main,
    color: theme.palette.text.secondary,
    borderColor: theme.palette.brand.main
  }
})
