import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { connectorWrapperSx, numberOfConnectorSx, connectorInnerSx, dotSx, bodySx } from "./ConnectorCard.sx";
import { ConnectorCardType } from "./ConnectorCard.types";

export const ConnectorCard: FC<ConnectorCardType> = ({ connector, number }) => {
  return (
    <Box sx={connectorWrapperSx}>
      <Box
        sx={numberOfConnectorSx}
      >
        <Typography
          variant='subtitle1'
        >
          {number}
        </Typography>
      </Box>
      <Box sx={connectorInnerSx}>
        <img
          src={connector.imageUrl}
          alt={String(connector.connectorId)}
        />
        <Typography
          variant='subtitle1'
        >
          {connector.connectorType}
        </Typography>
        <Typography
          variant='caption'
          sx={dotSx}
        >
          <FormattedMessage id="dot" />
        </Typography>
        <Typography
          variant='body1'
          sx={bodySx}
        >
          {connector.maxPower}
          <FormattedMessage id="kw" />
        </Typography>
      </Box>
    </Box>
  )
}
