import { Button, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { contractsApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { NotifyError } from "src/utils/notification";
import { moveToOperationButtonSx } from "./MoveOperationButton.sx";
import { MoveOperationButtonType } from "./MoveOperationButton.types";

type ChangeContractStatusTrigger = ReturnType<typeof contractsApi.useToggleContractStatusMutation>[0]

const handleChangeContractStatus = (trigger: ChangeContractStatusTrigger, dispatch: AppDispatch, contractId: number, businessId: number, ) => async () => {
  dispatch(preloaderChangeStatus(true))
  const formData = new FormData()
  formData.set('businessId', String(businessId))
  try {
    await trigger({formData, contractId}).unwrap()
      .catch((error) => 
        NotifyError(error.data.message)
      );
  } catch(error: any){
    NotifyError(error.data.message)
  } finally {
    dispatch(preloaderChangeStatus(false))
  }
}

export const MoveOperationButton: FC<MoveOperationButtonType> = ({ contractId, businessId }) => {
  const [updateStatus] = contractsApi.useToggleContractStatusMutation()
  const dispatch = useAppDispatch()

  return (
    <Button 
      color="brand"
      onClick={handleChangeContractStatus(updateStatus, dispatch, contractId, businessId)}
      sx={moveToOperationButtonSx}
    >
      <Typography
        variant="button"
        color="textSecondary"
      >
        <FormattedMessage id="moveToOperation" />
      </Typography>
    </Button>
  )
}