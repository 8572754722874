import * as Yup from 'yup';

export interface AddClientToLocationFormType extends Record<string, unknown>{
  parkingPlace: string,
  userId: string,
}

export const addClientToLocationFormSchema = Yup.object().shape({
  parkingPlace: Yup.string()
    .max(255),
  userId: Yup.string()
    .required('schemaRequiredUserId'),
});
