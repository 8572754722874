import { alpha, SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const detailsWrapperSx: SxProps<Theme> = {
  borderRadius: theme.spacing(4/8),
  background: theme.palette.background.default,
  overflow: 'hidden',
  padding: theme.spacing(24/8),
  height: '100%'
}
export const markdownSx: SxProps<Theme> = {
  '& ol li': {
    listStyle: "auto"
  },
  '& ul li': {
    listStyle: 'disc'
  }
}
export const imageWrapperSx: SxProps<Theme> = {
  maxWidth: 215,
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  '& img':{
    width: '100%'
  }
}
export const detailsTitleSx: SxProps<Theme> ={
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(8/8)
}
export const detailItemSx: SxProps<Theme> = {
  '&.MuiGrid-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(8/8)
  }
}
export const buttonImageWrapperSx: SxProps<Theme> = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  padding: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  background: alpha(theme.palette.bg.dark, 0.2),
  transition: '0.3s ease',
  '&:hover': {
    opacity: 1
  }
}
export const imageButtonSx: SxProps<Theme> = {
  '&:hover': {
    background: theme.palette.neutral[400]
  }
}
export const tabsSx: SxProps<Theme> = {
  ".MuiTabs-indicator": {
    display: "none"
  }
}

export const tabSx: SxProps<Theme> = {
  color: theme.palette.neutral[500],
  textTransform: "capitalize",
  alignItems: "flex-start",
  px: 0,
  minWidth: 0,
  "&:not(:last-child)": {
    mr: 3
  }
}
