import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const connectorWrapperSx: SxProps<Theme> = {
  padding: theme.spacing(0),
  borderRadius: theme.spacing(8/8),
  borderWidth: theme.spacing(1/8),
  borderColor: theme.palette.neutral[100],
  borderStyle: 'solid',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxHeight: theme.spacing(72/8),
  height: '100%',
  marginTop: theme.spacing(10/8)
}

export const numberOfConnectorSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(6/8),
  background: theme.palette.neutral[100],
  maxWidth: theme.spacing(34/8),
  width: '100%',
  height: '100%'
}

export const connectorInnerSx: SxProps<Theme> = {
  width: '100%',
  padding: theme.spacing(10/8, 20/8),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(8/8)
}

export const dotSx: SxProps<Theme> = {
  color: theme.palette.neutral[100]
}

export const bodySx: SxProps<Theme> = {
  color: theme.palette.neutral[700]
}
