import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const formSx: SxProps<Theme> =  {
  display: 'grid',
  gridTemplateColumns: 'auto',
  gap: theme.spacing(5/8)
}
export const submitButtonSx: SxProps<Theme> =  {
  maxWidth: theme.spacing(175/8),
  alignSelf: 'flex-end',
  '& span': {
    textTransform: 'none'
  }
}
export const actionsWrapperSx: SxProps<Theme> =  {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(20/8)
}
export const formLabelSx: SxProps<Theme> =  {
  "& span span": {
    marginLeft: theme.spacing(5/8),
    color: theme.palette.warning.main
  },
}
