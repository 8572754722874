import theme from "../../../../theme";

export const clientCardSx = {
  menuBtn: {
    width: "100%"
  },
  avatar: {
    width: theme.spacing(20/8),
    height: theme.spacing(20/8),
  },
  menuBtnText: {
    width: "100%",
    textAlign: "start",
    fontSize: theme.spacing(14/8),
    fontWeight: 400,
    color: theme.palette.neutral[400],
  },
  iconBtn: {
    width: theme.spacing(20/8),
    height: theme.spacing(20/8),
    backgroundColor: "transparent"
  },
  popover: {
    '& .MuiPaper-root': {
      maxWidth: theme.spacing(200/8),
      width: '100%',
      p: 1
    }
  },
  dataText: {
    width: "min-content",
    fontSize: theme.spacing(12/8),
    fontWeight: 400,
    textAlign: "end"
  },
  dataTextName: {
    textAlign: "start",
    maxWidth: theme.spacing(100/8),
    width: "100%"
  },
  withIcon: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 0.5
  }
}
