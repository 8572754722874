import { FC, useState } from "react";
import { Box, SelectChangeEvent, Stack, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Range } from "react-date-range";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import AMPModal from "../../common/AMPModal";
import { timeMap } from "../../forms/formData/common/initialData";
import { TimeSelect } from "../../common/TimeSelect";
import { PickCustomPeriodForm } from "../../forms/PickCustomPeriodForm";
import { PickCustomPeriodFormReturnType } from "../../forms/PickCustomPeriodForm/PickCustomPeriodForm.types";
import { ReportCardProps } from "./ReportCard.types";
import { reportCardSx } from "./ReportCard.sx";

export const ReportCard:FC<ReportCardProps> = ({
  icon, title, params, link, field, handleTimeChange
}) => {
  const intl = useIntl()
  const [currentTime, setCurrentTime] = useState(4)
  const [showCalendar, setShowCalendar] = useState(false)
  
  const handleShowCalendar = () => {
    setShowCalendar(true)
  }
  const handleHideCalendar = () => {
    setShowCalendar(false)
  }
  
  const dateRange: Range = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }
  
  const handleCustomClick = () => {
    handleShowCalendar()
  }
  
  const onSelect = (e: SelectChangeEvent) => {
    const value = Number(e.target.value)
    setCurrentTime(value)
    if (value !== 5 && field) {
      handleTimeChange
        && handleTimeChange(
          timeMap(intl, handleCustomClick)[value]?.dateFrom,
          dayjs().valueOf(),
          field)()
    }
    if (value === 5) {
      handleShowCalendar()
    }
  }
  
  const onSubmitRange = () => {
    return (values: PickCustomPeriodFormReturnType) => {
      handleTimeChange && field &&
        handleTimeChange(
          dayjs(values.range.startDate).valueOf(),
          dayjs(values.range.endDate).valueOf(),
          field
        )()
      handleHideCalendar()
    }
  }
  
  return (
    <Box sx={reportCardSx.root}>
      <Stack sx={reportCardSx.headerWrapper}>
        {icon}
        <Stack sx={reportCardSx.header}>
          <Typography
            variant="h5"
            sx={{ fontWeight: 600 }}
          >
            {title}
          </Typography>
          {field && handleTimeChange &&
          <Box width={200}>
            <TimeSelect
              currentTime={currentTime}
              onChange={onSelect}
              onSubmitRange={onSubmitRange}
              showCalendar={showCalendar}
              handleCustomClick={handleCustomClick}
              handleShowCalendar={handleShowCalendar}
              handleHideCalendar={handleHideCalendar}
            />
          </Box>
          }
        </Stack>
      </Stack>
      <Stack sx={reportCardSx.footer}>
        <Stack sx={reportCardSx.params}>
          {params?.map((param, index) => (
            <Box key={index}>
              <Stack sx={reportCardSx.amountStack}>
                <Typography
                  variant="h5"
                  sx={reportCardSx.amountText}
                >
                  {param.amount}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 600 }}
                >
                  {param.units}
                </Typography>
              </Stack>
              <Typography sx={reportCardSx.description}>
                {param.description}
              </Typography>
            </Box>
          ))}
        </Stack>
        <Link to={link}>
          <ArrowForwardIcon sx={reportCardSx.arrow} />
        </Link>
      </Stack>
      <AMPModal
        handleClose={handleHideCalendar}
        open={showCalendar}
        title={intl.formatMessage({ id: "customPeriod" })}
        type="md"
        fullWidth
        withoutPaddings
      >
        <PickCustomPeriodForm
          handleClose={handleHideCalendar}
          handleSubmit={onSubmitRange()}
          initialValues={{
            range: dateRange
          }}
        />
      </AMPModal>
    </Box>
  )
}
