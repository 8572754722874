import { Box, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ChargePointStatus, ConnectorStatusEnum } from "src/enums";
import { Can } from "src/permissions/Can";
import { ColoredStatus } from "../../common/ColoredStatus";
import { ActivateButton } from "../ActivateButton";
import { DeactivateButton } from "../DeactivateButton";
import { connectorWrapperSx, connectorInnerSx, dotSx, bodySx, infoWrapperSx, connectorLineSx } from "./ConnectorActionCard.sx";
import { ConnectorActionCardType } from "./ConnectorActionCard.types";

export const ConnectorActionCard: FC<ConnectorActionCardType> = ({
  connector,
  status,
  chargerId,
  rfId,
  connectorDescription,
  chargerStatus,
  businessId
}) => {
  const [connectorStatus, setConnectorStatus] = useState<string>("")
  
  useEffect(() => {
    setConnectorStatus(status)
  }, [status])

  return (
    <Box sx={connectorWrapperSx}>
      <Box sx={connectorLineSx}>
        <img
          src={connectorDescription.imageUrl}
          alt={connectorDescription.connectorType}
        />
        <Box sx={connectorInnerSx}>
          {connectorStatus && <ColoredStatus
            label={connectorStatus}
            fontSize={12}
          />}
          <Box sx={infoWrapperSx}>
            <Typography
              variant='subtitle1'
            >
              {connectorDescription.connectorType}
            </Typography>
            <Typography
              variant='caption'
              sx={dotSx}
            >
              <FormattedMessage id="dot" />
            </Typography>
            <Typography
              variant='body1'
              sx={bodySx}
            >
              {connectorDescription.maxPower}
              <FormattedMessage id="kw" />
            </Typography>
          </Box>
        </Box>
      </Box>
      <Can
        I="manage"
        a="activeCharging"
      >
        {status !== ConnectorStatusEnum.CHARGING
          ? <ActivateButton 
            connectorId={connector.connectorId}
            chargerId={chargerId}
            rfId={rfId}
            disabled={connectorStatus !== ConnectorStatusEnum.PREPARING || chargerStatus === ChargePointStatus.OFFLINE}
            businessId={businessId}
          />
          : <DeactivateButton 
            connectorId={connector.connectorId}
            chargerId={chargerId}
            businessId={businessId}
          />
        }
      </Can>
    </Box>
  )
}
