import * as Yup from 'yup';

export interface ForgotPasswordType extends Record<string, unknown>{
  emailAddress: string,
}

export const forgotPasswordSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email('schemaValidEmail')
    .max(255)
    .required('schemaRequiredEmail')
})
