import { SxProps, Theme } from "@mui/material";
import theme from "../../../theme";

export const containerSx: SxProps<Theme> = {
  width: '100%',
  padding: theme.spacing(16/8, 0),
  [theme.breakpoints.down('lg')]: {
    p: 1
  }
}
export const nameSx: SxProps<Theme> = {
  color: theme.palette.neutral[600],
  [theme.breakpoints.down('lg')]: {
    fontSize: theme.spacing(12/8)
  }
}
export const unitSx: SxProps<Theme> = {
  color: theme.palette.neutral[800],
  [theme.breakpoints.down('lg')]: {
    fontSize: theme.spacing(12/8)
  }
}
export const columnSx: SxProps<Theme> = {
  display: 'flex',
  alignItems:'center'
}
