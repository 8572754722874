import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'src/axiosBaseQuery'
import { ActiveTransactionDTO, ActiveTransactionRequest, ChangeChargerConfigurationRequest, ChargePointSettingDTO, OcppCommandResponse, ResetChargerRequest, StartChargingRequest, StopChargingRequest } from 'src/types'
import { getBaseURL } from 'src/utils/helper'

// initialize an empty api service that we'll inject endpoints into later as needed
export const ocppApi = createApi({
  reducerPath: 'ocppApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL()),
  }),
  tagTypes: ['Configurations', 'ActiveTransaction'],
  endpoints: (build) => ({
    startCharging: build.mutation<OcppCommandResponse, StartChargingRequest>({
      query: (data) => ({ url: '/ocpp/operations/startCharging', method: 'post', data })
    }),
    stopCharging: build.mutation<OcppCommandResponse, StopChargingRequest>({
      query: (data) => ({ url: '/ocpp/operations/stopCharging', method: 'post', data })
    }),
    resetCharger: build.mutation<{message: string}, ResetChargerRequest>({
      query: (data) => ({ url: '/ocpp/operations/resetCharger', method: 'post', data })
    }),
    changeConfigurations: build.mutation<{message: string}, ChangeChargerConfigurationRequest>({
      query: (data) => ({ url: '/ocpp/operations/changeConfiguration', method: 'post', data }),
      invalidatesTags: ['Configurations']
    }),
    getConfigurations: build.query<ChargePointSettingDTO[], { chargerId: number, businessId: number }>({
      query: ({chargerId, businessId}) => ({ url: `/chargePoints/${chargerId}/configurations`, method: 'get', params: { businessId } }),
      providesTags: ['Configurations']
    }),
    getActiveTransaction: build.query<ActiveTransactionDTO, ActiveTransactionRequest>({
      query: (params) => ({url: '/transactions/active', method: 'get', params}),
      providesTags: ['ActiveTransaction']
    })
  }),
})
