import { FC, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import { Table } from "src/components/tables/Table";
import { ClientListInLocationDetailsProps } from "./ClientListInLocationDetails.types";
import { columnConfig } from "./ClientListInLocationDetails.tableConfig";
import AMPModal from "src/components/common/AMPModal";
import { AddClientToLocationForm } from "src/components/locations/AddClientToLocationForm";
import { NoClients } from "src/components/tables/NoClients";
import { ClientCard } from "./ClientCard";
import { actionButtonWrapperSx, rootSx, titleSx } from "./ClientListInLocationDetails.sx";
import { ResponsiveTable } from "../../common/ResponsiveTable";
import ability from "../../../permissions/ability";

export const ClientListInLocationDetails: FC<
  ClientListInLocationDetailsProps
> = ({ data, title, locationId, businessId }) => {
  const [open, setOpen] = useState<boolean>(false)
  const intl = useIntl()
  const dialogTitle = intl.formatMessage({id: 'addUser'})
  const canCreateDeleteUsers = ability.can('manage', 'users')
  
  return (
    <>
      <Box sx={rootSx}>
        <Box sx={titleSx}>
          <Typography variant="subtitle1">{title}</Typography>
        </Box>
        <ResponsiveTable
          desktopElement={
            <Table
              sx={{border: "none"}}
              rows={data}
              columns={columnConfig(canCreateDeleteUsers)}
              hideFooter
              components={{
                'NoRowsOverlay': NoClients
              }}
            />
          }
          responsiveElement={
            <>
              {data?.map(item => <ClientCard
                key={item?.id}
                canCreateDeleteUsers={canCreateDeleteUsers}
                businessId={businessId}
                {...item}
              />
              )}
            </>
          }
        />
      </Box>
      {
        canCreateDeleteUsers &&
        <Box sx={actionButtonWrapperSx}>
          <Button onClick={() => setOpen(true)}>
            <AddCircle />
            <FormattedMessage id="addClient" />
          </Button>
        </Box>
      }
      <AMPModal
        handleClose={() => setOpen(false)}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
      >
        <AddClientToLocationForm
          handleClose={() => setOpen(false)}
          locationId={locationId}
          businessId={businessId}
          existClients={data}
        />
      </AMPModal>
    </>
  );
};
