import { FC } from "react";
import { useIntl } from "react-intl";
import { TransactionDTO } from "../../../../types";
import { convertTime } from "../../../../utils/helper";
import { TableCard } from "../../../tables/respnsiveTable/TableCard";
import { TableRow } from "../../../tables/respnsiveTable/TableRow";

export const ChargerTransactionCard:FC<TransactionDTO> = ({
  startTimestamp,
  stopTimestamp,
  transactionSummary,
  idTag
}) => {
  const intl = useIntl();
  const formatPower = transactionSummary?.wattsConsumed
    ? `${(transactionSummary?.wattsConsumed / 1000)
      .toFixed(2)}${intl.formatMessage({id: "kWh"})}`
    : "-"
  
  return (
    <TableCard>
      <TableRow
        titleId='transactionStartColumnHeader'
        value={startTimestamp ? (new Date(startTimestamp)).toLocaleString() : "-"}
      />
      <TableRow
        titleId='transactionEndColumnHeader'
        value={stopTimestamp ? (new Date(stopTimestamp)).toLocaleString() : "-"}
      />
      <TableRow
        titleId='chargingTimeColumnHeader'
        value={transactionSummary?.chargingTimeMinutes ? convertTime(transactionSummary.chargingTimeMinutes) : "-"}
      />
      <TableRow
        titleId='powerConsumedColumnHeader'
        value={formatPower}
      />
      <TableRow
        titleId='rfid'
        value={idTag?.idTag ?? "-"}
      />
    </TableCard>
  )
}
