import { Cluster } from "@googlemaps/markerclusterer";
import theme from "src/theme";

const size = (count: number) => {
  if (count < 6) {
    return 50
  }
  if (count > 8) {
    return 80
  }
  return count * 10
}
export const ClusterMark = ({ position, count }: Cluster) => {
  const svg = window.btoa(`
  <svg fill="${theme.palette.brand.main}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity="1" r="${size(count)}" stroke-width="2" stroke="#fff" />
  </svg>`);

  return new google.maps.Marker({
    position,
    icon: {
      url: `data:image/svg+xml;base64,${svg}`,
      scaledSize: new google.maps.Size(size(count), size(count)),
    },
    label: {
      text: String(count),
      color: "rgba(255,255,255,0.9)",
      fontSize: "14px",
    },
    zIndex: 1000,
  });
}
