import { GridColumns } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { ColorField } from 'src/components/common/ColorField';
import { TableIdCell } from 'src/components/common/TableIdCell';
import { ContractSortFieldEnum, RoleEnum } from 'src/enums';
import { AppRouteEnum, AppRouteSpace } from 'src/utils/helper';

export const columnConfigContracts = (role: RoleEnum): GridColumns =>  ([
  {
    field: ContractSortFieldEnum.CONTRACT_NAME,
    renderHeader: (params) => <FormattedMessage id={"nameColumnHeader"} />,
    flex: 3,
    minWidth: 100,
    cellClassName: 'ampTableCell',
    renderCell: (params) => {
      return (
        <TableIdCell
          businessId={params.row.business.id}
          label={params.row.name}
          link={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.contracts, params.row.id])}
        />
      )
    }

  },
  {
    field: ContractSortFieldEnum.CLIENT_LAST_NAME,
    renderHeader: (params) => <FormattedMessage id={"clientColumnHeader"} />,
    flex: 3,
    minWidth: 150,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => `${params.row.user.firstName} ${params.row.user.lastName}`
  },
  {
    field: ContractSortFieldEnum.CLIENT_EMAIL,
    renderHeader: (params) => <FormattedMessage id={"emailColumnHeader"} />,
    flex: 6,
    minWidth: 210,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.user.emailAddress
  },
  {
    field: ContractSortFieldEnum.CREATED,
    renderHeader: (params) => <FormattedMessage id={"createdOnColumnHeader"} />,
    flex: 3,
    minWidth: 120,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => (new Date(params.row.created)).toLocaleDateString('fr-CH')
  },
  {
    field: ContractSortFieldEnum.STATUS,
    renderHeader: (params) => <FormattedMessage id={"statusColumnHeader"} />,
    flex: 3,
    minWidth: 110,
    cellClassName: 'ampTableCell',
    renderCell: (params) =>{
      return (
        <ColorField label={params.row.status} />
      )
    } 
  },
  {
    field: ContractSortFieldEnum.LOCATION_NAME,
    renderHeader: (params) => <FormattedMessage id={"locationColumnHeader"} />,
    flex: 7,
    minWidth: 160,
    cellClassName: 'ampTableCell',
    renderCell: (params) => params.row.location && 
    <TableIdCell
      businessId={params.row.business.id}
      label={params.row.location?.name}
      link={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.locations, params.row.location?.id])}
      withoutCopy />
    
  },
  {
    field: 'business',
    renderHeader: (params) => <FormattedMessage id={"businessColumnHeader"} />,
    flex: 2,
    minWidth: 130,
    sortable: false,
    hide: role !== RoleEnum.SUPER_ADMIN,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.business.name
  },
])
